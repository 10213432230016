import React, { useEffect, useRef, useState } from 'react'
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from '@mui/material/Select';
import './overview.css'
import { useLocation } from 'react-router';
import adminService from '../../../services/admin.service';
import * as $ from "jquery";
import ChartView from '../../charts/chartView';
import Wheel from '../home/wheel';
import AnalysisChart from './analysisChart';


const OverviewPage = (props) => {
    let location = useLocation();
    const metadataRef = useRef(null);
    // const [goalSDG, setGoalSDG] = useState(location?.state?.dataToDisplay[0]);
    const [goalSDG, setGoalSDG] = useState(location?.state?.goalname ? location?.state?.goalname : 'Goal 1:');
    const [activeN, setActiveN] = useState(0);
    // const goalSDG = location?.state?.goalname;
    const [allData, setAllData] = useState([]);
    const [SVG, setSVG] = useState(location?.state?.svg);
    const [goalData, setGoalData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [chartType, setChartType] = useState('bar');
    const [dataSet, setDataSet] = useState([]);
    const [activeIus, setActiveIus] = useState([]);
    const [sortedDData, setSortedDData] = useState([]);
    const [selectedIus, setSelectedIus] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [contentKey, setContentKey] = useState(0);

    const selectIUS = (iusName, _id, targetid, targetname, metadata, ius) => {
        console.log("this is ius consoled on metadata-", ius)
        setActiveIus({
            'iusName': iusName,
            'id': _id,
            'targetId': targetid,
            'targetName': targetname,
            'metaData': metadata,
            'ius': ius
        })
        setIsOpen(true);
    };

    const onClose = ()=> {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
          setContentKey(prevKey => prevKey + 1);
        }
      }, [isOpen]);

    useEffect(() => {
        let active = 0;
        switch (goalSDG) {
            case "Goal 1:":
                active = 0;
                break;
            case "Goal 2:":
                active = 1;
                break;
            case "Goal 3:":
                active = 2;
                break;
            case "Goal 4:":
                active = 3;
                break;
            case "Goal 5:":
                active = 4;
                break;
            case "Goal 6:":
                active = 5;
                break;
            case "Goal 7:":
                active = 6;
                break;
            case "Goal 8:":
                active = 7;
                break;
            case "Goal 9:":
                active = 8;
                break;
            case "Goal 10:":
                active = 9;
                break;
            case "Goal 11:":
                active = 10;
                break;
            case "Goal 12:":
                active = 11;
                break;
            case "Goal 13:":
                active = 12;
                break;
            case "Goal 14:":
                active = 13;
                break;
            case "Goal 15:":
                active = 14;
                break;
            case "Goal 16:":
                active = 15;
                break;
            case "Goal 17:":
                active = 16;
                break;
        }
        setActiveN(active);
    }, [goalSDG])




    const wheelCallback = (goal) => {
        if (goal) {
            let selectedGoalData = allData?.filter((x) => x?.goalname?.toLowerCase()?.includes(goal?.goal.toLowerCase()));
            setGoalData(selectedGoalData);

            setSVG(goal?.outerSVG)
        }


    }

    useEffect(() => {
        $("#closeButton").on("click", function () {
            $('#analysis-modal').removeClass('fullscreen');
            $('.collapse_btn').hide();
            $('.expand_btn').show();
        });
    }, []);

    const sendActiveIndex = (active) => {
        console.log('home', active)
        setActiveN(active);
        // this.setState({ activeColor: active })
    }

    const goalAPI = async () => {
        setLoading(true);
        await adminService.httpGet('/data-retrieval/generic/get-goal-ius').then((res) => {
            if (res) {
                let data = res?.data
                let displayData = data?.filter((x) => x?.goalname?.toLowerCase()?.includes(goalSDG.toLowerCase()));

                setAllData(data);
                setGoalData(displayData)
                // if(location.state == null){
                //   setGoalSDG(goal1Data[0])
                // }
            }

        })
        setLoading(false);
    }

    useEffect(() => {
        goalAPI();
        $('body').removeClass('sdg_list')
        $('body').removeClass('src_list');


    }, [location?.state])

    const handleSDGChange = (e) => {
        let value = e.target.value;

        let selectedGoalData = allData?.filter((x) => x?.goalname?.toLowerCase()?.includes(value.toLowerCase()));

        setGoalData(selectedGoalData);
        setGoalSDG(value);
    };

    const getOverviewChart = (goalId, iusId, targetId, ius, targetName) => {
        setLoading(true);
        setSelectedIus({ ius: ius, target: targetName });

        const dataToSend = {
            "goal_id": goalId,
            "target": targetId,
            "ius": iusId
        }

        adminService.httpPost('/data-retrieval/data/get-overview-chart-data', dataToSend).then((res) => {
            let iuData = [];
            let chartDataObj = {};

            if (res) {
                let data = res?.list;

                data?.forEach((item) => {
                    item?.ius?.forEach((iu) => {
                        const processedCombinations = new Set(); // Use a set to store unique combinations

                        iu?.subgroup?.forEach((subg) => {
                            if (iu._id === iusId) {
                                iu?.data?.forEach((data) => {
                                    if (subg?._id === data?.ius_id) {
                                        // Create a unique combination key
                                        const combinationKey = `${data?.time_period?.start_time_period}-${data?.source?.publisher}-${data?.area_code}-${data?.ius_id}`;
                                        // Check if the combination has already been processed
                                        let checkAreaValue = data?.area_code === "TTO" ? true : false;
                                        if (!processedCombinations.has(combinationKey) && checkAreaValue) {
                                            console.log("data check okaaay-", data)
                                            let obj = {
                                                area: data?.areaDetails[0]?.areaName,
                                                area_code: data?.area_code,
                                                area_level: 1,
                                                parent_id: null,
                                                indicator: data?.indicator?.en,
                                                unit: data?.unit?.en,
                                                subgroup: subg?._id === data?.ius_id ? subg?.name?.en : '',
                                                timeperiod: data?.time_period?.start_time_period,
                                                value: +data?.value,
                                                source: data?.source?.publisher,
                                                subgroup_order: subg?.subgroup_order
                                            };

                                            iuData.push(obj);

                                            // Add the combination key to the processed set
                                            processedCombinations.add(combinationKey);
                                        }
                                    }
                                });
                            }
                        });
                        chartDataObj[iu?._id] = iuData;
                    });
                });
            }

            setChartData(iuData);
            setDataSet(chartDataObj);

            setLoading(false);
        })
            .catch((err) => {
                setLoading(false);
                console.log("error--", err)
            })
    }


    const uniqueIusNames = new Set();
    // Initialize arrays to store numeric and alphabetic decimals
    // useEffect(()=>{
    //   const numericDecimals = [];
    //   const alphabeticDecimals = [];

    //   // Iterate over each item in goalData (if it exists)
    //   goalData?.forEach((item) => {
    //     // Iterate over each target in the item (if it exists)
    //     item?.target?.forEach((target) => {
    //       // Use regex to find a target number in the target name
    //       const matchResult = target?.targetname.match(/Target (\d+(\.\d+)?|[a-z])/);

    //       if (matchResult && matchResult[1]) {
    //         // Extract the target value and check if it's numeric or alphabetic decimal
    //         const targetValue = matchResult[1];
    //         const isNumericDecimal = /^\d+(\.\d+)?$/.test(targetValue);

    //         if (isNumericDecimal) {
    //           // For numeric decimals, format the number, sort the ius array, and add to numericDecimals array
    //           const targetNumber = parseFloat(targetValue);
    //           const sortedIus = target?.ius?.slice()?.sort((a, b) => a.name.localeCompare(b.name));
    //           numericDecimals.push({ targetid: target?.targetid, target: target?.targetname, sno: targetNumber, ius: sortedIus });
    //         } 
    //         else {
    //           // For alphabetic decimals, add to alphabeticDecimals array
    //           alphabeticDecimals.push({ targetid: target?.targetid, target: target?.targetname, sno: targetValue });
    //         }
    //       } else {
    //         console.log("Target number not found in the string.");
    //       }
    //     });
    //   });

    //   // Sort numericDecimals based on the sno field
    //   numericDecimals.sort((a, b) => a.target.localeCompare(b.target));

    //   // Sort alphabeticDecimals based on the sno field
    //   alphabeticDecimals.sort((a, b) => a.sno.localeCompare(b.sno));

    //   // Concatenate numericDecimals and alphabeticDecimals into the desired order
    //   const sortedData = [...numericDecimals];
    //   console.log("consoling sortedddAta-",  sortedData)
    //   setSortedDData(sortedData)
    // },[goalData])

    useEffect(() => {
        const numericDecimals = [];
        const alphabeticDecimals = [];

        // Iterate over each item in goalData (if it exists)
        goalData?.forEach((item) => {
            // Iterate over each target in the item (if it exists)
            item?.target?.forEach((target) => {
                // Use regex to find a target number in the target name
                const matchResult = target?.targetname.match(/Target (\d+(\.\d+)?|[a-z])/);

                if (matchResult && matchResult[1]) {
                    // Extract the target value and check if it's numeric or alphabetic decimal
                    const targetValue = matchResult[1];
                    const isNumericDecimal = /^\d+(\.\d+)?$/.test(targetValue);

                    if (isNumericDecimal) {
                        // For numeric decimals, format the number and add to numericDecimals array
                        const targetNumber = parseFloat(targetValue);
                        numericDecimals.push({
                            targetid: target?.targetid,
                            target: target?.targetname,
                            sno: targetNumber,
                            ius: target?.ius?.slice()?.sort((a, b) => a.name.localeCompare(b.name)),
                        });
                    } else {
                        // For alphabetic decimals, add to alphabeticDecimals array
                        alphabeticDecimals.push({
                            targetid: target?.targetid,
                            target: target?.targetname,
                            sno: targetValue,
                        });
                    }
                } else {
                    console.log("Target number not found in the string.");
                }
            });
        });

        // Use Intl.Collator for sorting
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

        // Sort numericDecimals based on the sno field
        numericDecimals.sort((a, b) => collator.compare(a.target, b.target));

        // Sort alphabeticDecimals based on the sno field
        alphabeticDecimals.sort((a, b) => collator.compare(a.sno, b.sno));

        // Concatenate numericDecimals and alphabeticDecimals into the desired order
        const sortedData = [...numericDecimals, ...alphabeticDecimals];
        console.log("consoling sortedData-", sortedData);
        setSortedDData(sortedData);
    }, [goalData]);


    return (
        <>
            <div className={loading ? 'loader text-center ' : 'loader text-center hide'}>
                <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
            </div>

            <div className="container-fluid dasboard_popup_pages over_view_area py-0">
                <div className="row h-100">
                    <div className="col-xl-3 col-lg-4 box-shadow">
                        {/* <div className="sdgs_icons_head"><img src="assets/lib/images/innerSDG2.svg" alt="" /></div> */}
                        {/* <div className="fixed_autocomplete">
              <FormControl>
                  <Select
                    value=''
                    onChange={this.handleGoalSDG}
                    id="combo-box-demo"
                    sx={{ width: 300 }}
                  >
                    <MenuItem value={'Goal 1:'}>No Poverty</MenuItem>
                    <MenuItem value={'Goal 2:'}>Zero Hunger</MenuItem>
                    <MenuItem value={'Goal 3:'}>Good Health and Well Being</MenuItem>
                    <MenuItem value={'Goal 4:'}>Quality Education</MenuItem>
                    <MenuItem value={'Goal 5:'}>Gender Equality</MenuItem>
                    <MenuItem value={'Goal 6:'}>Clean Water and Sanitation</MenuItem>
                    <MenuItem value={'Goal 7:'}>Affordable and Clean Energy</MenuItem>
                    <MenuItem value={'Goal 8:'}>Decent Work and Economic Growth</MenuItem>
                    <MenuItem value={'Goal 9:'}>Industry Innovation and Infrastructure</MenuItem>
                    <MenuItem value={'Goal 10:'}>Reduced Inequalities</MenuItem>
                    <MenuItem value={'Goal 11:'}>Sustainable Cities and Communities</MenuItem>
                    <MenuItem value={'Goal 12:'}>Responsible Consumption and Production</MenuItem>
                    <MenuItem value={'Goal 13:'}>Climate Action</MenuItem>
                    <MenuItem value={'Goal 14:'}>Life Below Water</MenuItem>
                    <MenuItem value={'Goal 15:'}>Life Of Land</MenuItem>
                    <MenuItem value={'Goal 16:'}>Peace, Justice and Strong Institution</MenuItem>
                    <MenuItem value={'Goal 17:'}>Partnerships for the Goals</MenuItem>
                  </Select>
                </FormControl>


              <FormControl fullWidth>
                <Select
                  className="sgd-dropdown"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={goalSDG}
                  // onMouseDown={()=> {this.classChange()}}
                  onChange={(e) => { handleSDGChange(e) }}
                  onOpen={(e) => selectOnClick()}
                >
                  <MenuItem value={'Goal 1:'}><img src="assets/lib/images/icons/no_Poverty.svg" /></MenuItem>
                  <MenuItem value={'Goal 2:'}><img src="assets/lib/images/icons/zero_hunger.svg" /></MenuItem>
                  <MenuItem value={'Goal 3:'}><img src="assets/lib/images/icons/good_health_and_well_being.svg" /></MenuItem>
                  <MenuItem value={'Goal 4:'}><img src="assets/lib/images/icons/quality_education.svg" /></MenuItem>
                  <MenuItem value={'Goal 5:'}><img src="assets/lib/images/icons/gender_equality.svg" /></MenuItem>
                  <MenuItem value={'Goal 6:'}><img src="assets/lib/images/icons/clean_water_and_senitation.svg" /></MenuItem>
                  <MenuItem value={'Goal 7:'}><img src="assets/lib/images/icons/affordable_and_clean_energy.svg" /></MenuItem>
                  <MenuItem value={'Goal 8:'}><img src="assets/lib/images/icons/decent_work_and_economic_growth.svg" /></MenuItem>
                  <MenuItem value={'Goal 9:'}><img src="assets/lib/images/icons/industry_innovation_and_infrastructure.svg" /></MenuItem>
                  <MenuItem value={'Goal 10:'}><img src="assets/lib/images/icons/reduced_inequalities.svg" /></MenuItem>
                  <MenuItem value={'Goal 11:'}><img src="assets/lib/images/icons/sustainable_consumption_and_production.svg" /></MenuItem>
                  <MenuItem value={'Goal 12:'}><img src="assets/lib/images/icons/responsible_consumption_and_production.svg" /></MenuItem>
                  <MenuItem value={'Goal 13:'}><img src="assets/lib/images/icons/climate_action.svg" /></MenuItem>
                  <MenuItem value={'Goal 14:'}><img src="assets/lib/images/icons/life_below_water.svg" /></MenuItem>
                  <MenuItem value={'Goal 15:'}><img src="assets/lib/images/icons/life_on_land.svg" /></MenuItem>
                  <MenuItem value={'Goal 16:'}><img src="assets/lib/images/icons/peace_justice_and_strong_institutions.svg" /></MenuItem>
                  <MenuItem value={'Goal 17:'}><img src="assets/lib/images/icons/partnerships_for_the_goals.svg" /></MenuItem>

                </Select>
              </FormControl>

            </div> */}
                        <div className="overviewSDGWheelBox">
                            <Wheel
                                init={true}
                                wheelCallback={wheelCallback}
                                sendActiveIndex={sendActiveIndex}
                                play={true}
                                isOverview={true}
                                activeN={activeN}
                            />
                        </div>
                        <div className="main_sdgs_area">
                            <div className="sdgs_series d-flex align-item-center">
                                <div className="series_content pt-2">
                                    <img src={SVG} />
                                    {/* <h3>{goalData[0]?.goalname}</h3> */}
                                    <p>{goalData[0]?.goaldescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8 target_box p-0">
                        <div className="targets_section">
                            {
                                sortedDData?.length > 0 ?
                            <div className="targets_box">
                                {console.log("This is data check for condition-", sortedDData)}
                                {sortedDData?.map((target) => {
                                    return (
                                        <div className='target_area' key={target.targetid}>
                                            <h2 className="target_head">{target.target}</h2>
                                            {target.ius?.map((ius, index) => { // Added 'index' as the second parameter
                                                if (!uniqueIusNames.has(ius.name)) {
                                                    uniqueIusNames.add(ius.name);
                                                    return (
                                                        <p key={ius._id} className={"target_dis position-relative goal" + (activeN + 1)}>{ius.name}
                                                            <span><a className='meta-text' data-toggle="modal" data-target="#analysis-modal" onClick={() => { getOverviewChart(goalData[0]?._id, ius?._id, target?.targetid, ius, target?.target) }}><img src="assets/lib/images/analysis.svg" />Analysis</a>
                                                                <a className='meta-text' data-toggle="modal" data-target="#metadata-modal2" onClick={() => { selectIUS(ius?.name, ius?._id, target?.targetid, target?.target, ius?.metadata?.[ius?.metadata?.length - 1], ius) }}><img src="assets/lib/images/metadata-info.svg" />Metadata</a></span></p>

                                                        // <div className="accordion" id={ `accordion_${target.targetid}` } key={ ius._id }>
                                                        //   <div className="card">
                                                        //     <div className="card-header" id={ `heading_${target.targetid}_${index}` }>
                                                        //       <h2 className="mb-0">
                                                        //         <button className="btn btn-link btn-block text-left p-0" type="button" data-toggle="collapse" data-target={ `#${collapseId}` } aria-expanded="false" aria-controls={ collapseId }>
                                                        //           { console.log("target and everything id-", target, "ius ki baari--", ius) }

                                                        //         </button>
                                                        //       </h2>
                                                        //     </div>

                                                        //     <div id={ collapseId } className="collapse" aria-labelledby={ `heading_${target.targetid}_${index}` } data-parent={ `#accordion_${target.targetid}` }>
                                                        //       <div className="card-body">

                                                        //         { console.log("chart data--", dataSet?.[ius?._id]) }

                                                        //         { dataSet?.[ius?._id] && <ChartView
                                                        //           chartData={ dataSet?.[ius?._id] }
                                                        //           type={ chartType }
                                                        //           height={ '300px' }
                                                        //           width={ '100%' }
                                                        //         /> }
                                                        //       </div>
                                                        //     </div>
                                                        //   </div>
                                                        // </div>
                                                    );
                                                } else {
                                                    return null; // Return null for duplicate ius.name to avoid duplicate entries in the accordion
                                                }
                                            })}
                                        </div>

                                    )
                                })}
                            </div>
                            :
                            <div className='no-data'>No Data</div>
                            }

                        </div>

                    </div>
                </div>
            </div>

            <div class="modal fade analysis-modal" id="analysis-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            {/* <h5 className='modal-title'>{goalData[0]?.goalname}</h5> */}
                            <h5 className='modal-title'>{selectedIus?.ius?.name}<span> | {selectedIus?.ius?.unit}</span></h5>
                            {console.log("this is selectedIUS-", selectedIus, "goaltarget--", goalData)}
                            <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close" id="closeButton">
                                <img src='assets/lib/images/close.svg' className='img-fluid' />
                            </button>
                        </div>
                        <div class="modal-body">

                            {/* <img src='assets/lib/images/meta-img.png' className='img-fluid'/> */}
                            {/* {
                goalData[0]?.target?.flatMap((target) => {
                  return(
                  target?.ius?.flatMap((ius, index) => {
                    {console.log("this is chartData--", dataSet?.[ius?._id])}
                    return (
                      dataSet?.[ius?._id] && <ChartView
                        chartData={dataSet?.[ius?._id]}
                        type={chartType}
                        height={'300px'}
                      />
                    )
                  }))
                })
              } */}
                            {chartData && <AnalysisChart
                                chartData={chartData}
                                activeN={activeN + 1}
                                iusData={selectedIus}
                                goalData={goalData}
                                type={chartType}
                                height={'430px'}
                            />}
                            {/* {
              dataSet[ius?._id] && <ChartView
                chartData={dataSet?.[ius?._id]}
                type={chartType}
                height={'300px'}
              />} */}


                        </div>

                    </div>
                </div>
            </div>

            <div ref={metadataRef} class="modal fade analysis-modal" id="metadata-modal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" key={contentKey}>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            {console.log("iusname metadata display0", activeIus?.ius?.name, activeIus?.ius?.subgroupname, activeIus?.ius?.unit)}
                            <h5 className='modal-title'>
                                {activeIus?.ius?.name}
                                <span className='subgroupname'> | {activeIus?.ius?.unit}</span>
                            </h5>
                            <button onClick={()=> {onClose()}} type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
                                <img src='assets/lib/images/close.svg' className='img-fluid' />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className="col-md-12">

                                    <div className="targets_box">

                                        {/* <h2 className="target_head">Indicator</h2>
                    <p className="target_dis">{activeIus?.iusName}</p> */}

                                        {/* <h2 className="target_head">Goal</h2>
                    <p className="target_dis">{goalData[0]?.goalname}</p>

                    <h2 className="target_head">Target</h2>
                    <p className="target_dis">{activeIus?.targetName}</p> */}

                                        <h2 className="target_head">Indicator Definition</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt1?.en ? activeIus?.metaData?.mt1?.en : "-"}</p>

                                        <h2 className="target_head">Method of Computation</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt2?.en ? activeIus?.metaData?.mt2?.en : "-"}</p>

                                        <h2 className="target_head">Overview</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt3?.en ? activeIus?.metaData?.mt3?.en : "-"}</p>

                                        <h2 className="target_head">Comments and Limitations</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt4?.en ? activeIus?.metaData?.mt4?.en : "-"}</p>

                                        <h2 className="target_head">Data Collection for Global Monitoring</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt5?.en ? activeIus?.metaData?.mt5?.en : "-"}</p>

                                        <h2 className="target_head">Obtaining Data</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt6?.en ? activeIus?.metaData?.mt6?.en : "-"}</p>

                                        <h2 className="target_head">Data Availability</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt7?.en ? activeIus?.metaData?.mt7?.en : "-"}</p>

                                        <h2 className="target_head">Treatment of Missing Values</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt8?.en ? activeIus?.metaData?.mt8?.en : "-"}</p>

                                        <h2 className="target_head">Regional and Global Estimates</h2>
                                        <p className="target_dis">{activeIus?.metaData?.mt9?.en ? activeIus?.metaData?.mt9?.en : "-"}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default OverviewPage
