import "./ExportArea.css";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from 'react-router-dom'
import * as constants from '../../../Config/app.constants';

let headers =[];
let fname="";
let ftitle="";
const ExportWithoutData = (props) => {
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(0);

 const today = new Date();
 const currentYear = today.getFullYear();
 const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
 const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
 const todaysDate = String(currentYear) + String(currentMonth) + String(currentDate);
// console.log("val--"+props.value)
const location = useLocation();
  const route = location.pathname;

const downloadFile = (e) => {

  console.log('withoutdatacalled')

  if(props.value==4){
    
    
    let fname = 'Area_template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'Area_template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }else if(props.value==5){


    let fname = 'Indicator_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'Indicator_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }
  else if(props.value==1){


    let fname = 'Data_File_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='Data_File_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }
  else if(props.value==6){


    let fname = 'Indicator_Metadata_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'Indicator_Metadata_Template_SDG_Data_Portal_T&T ' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==10){


    let fname = 'Ters_Classification_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'classification_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==11){


    let fname = 'Ters_Area_master_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Area_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==12){


    let fname = 'Ters_Indicator_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Indicator_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==13){


    let fname = 'Ters_Metadata_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Metadata_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==14){


    let fname = 'Ters_Subgroup_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Subgroup_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==15){


    let fname = 'Ters_Unit_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Unit_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }


  else if(props.value==16){


    let fname = 'Ters_Source_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Source_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==17){


    let fname = 'Ters_ResourceTranslation_translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Resource_translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==18){


    let fname = 'TERS_Disaster_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Disaster_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==19){


    let fname = 'TERS_Option_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Option_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==20){


    let fname = 'TERS_Section_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Section_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==21){


    let fname = 'TERS_Question_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Question_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==22){


    let fname = 'TERS_Form_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Form_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }

  else if(props.value==23){


    let fname = 'TERS_Sub-Section_Translate.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TERS_' + 'Sub-Section_Translate' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }
  else if(props.value==28){


    let fname = 'Department_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='Department_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  } else if(props.value==30){

    
    let fname = 'TimePeriod_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='TimePeriod_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }else if(props.value==31){

    
    let fname = 'Source_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='Source_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }else if(props.value==32){

    
    let fname = 'GoalTarget_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='GoalTarget_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }  else if(props.value==33){

    
    let fname = 'Sector-Subsector_data_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='Sector-Subsector_data_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }else if(props.value==50){
    
    let fname = 'ManageGid_Indicator_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Indicator_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);

  }else if(props.value==51){
    
    let fname = 'ManageGid_Unit_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Unit_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);

  }else if(props.value==52){
    
    let fname = 'ManageGid_Subgroup_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Subgroup_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }else if(props.value==53){
    
    let fname = 'SubgroupDimensionGid_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='SubgroupDimensionGid_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }else if(props.value==54){
    
    let fname = 'ManageGid_Area_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Area_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }else if(props.value==55){
    
    let fname = 'ManageGid_Source_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Source_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }else if(props.value==56){
    
    let fname = 'ManageGid_Timeperiod_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Timeperiod_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }else if(props.value==57){
    
    let fname = 'ManageGid_Metadata_Template_SDG_Data_Portal_T&T.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='ManageGid_Metadata_Template_SDG_Data_Portal_T&T' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);
              
  }

}

  
  console.log('exportwithoutdata',props.value)


  return (
        <div className="w-100">
         
            <button  class="btn btn_browse w-100 popup_btn" id="download_icius_master_template" onClick={downloadFile}> Empty </button>            
          
         
        </div>



  );
}


export default ExportWithoutData;