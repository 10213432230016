import React, {useEffect, useState } from "react"; 


import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import FileUploader from 'devextreme-react/file-uploader';
import axios from "axios";
import * as constant from '../../../Config/app.constants'
import AdminService from '../../../services/admin.service';
import ProgressBar from 'react-bootstrap/ProgressBar';

function getImportLog(fileName, status) {

  var elem = document.getElementById("myBar");
  var widthelem = document.getElementById("progress_bar");
  var width = 0;

  var elem1 = document.getElementById("myBar1");
  var widthelem1 = document.getElementById("progress_bar1");
  var width1 = 0;

  var elem2 = document.getElementById("myBar2");
  var widthelem2 = document.getElementById("progress_bar2");
  var width2 = 0;
  
  let obj = this;
  //fileName = 'http://localhost/unsdg/dataImport/processData/public/temp/'+fileName

  

    fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/' + fileName;
    // clearInterval(logInterval);
   
    $.ajax({
      url: fileName, type: "GET", success: function (response) {
        //console.log(response);
        var result = response.split('=');
        //Total Record
        var total = result[0].split(':');
        var totalData = total[1];
        // console.log("totaldata",totalData=='null', totalData)
        $(".total_record").html(totalData=='null'? '' : totalData);
        //import record count
        var importD = result[1].split(':');
        var importData = importD[1];
        var width = (importData * 100) / totalData;
        width = Math.round(width);
        /*widthelem.style.width = width+'%'; 
        elem.innerHTML = importData+ ' Record Imported('+width+'%)';*/
        //error count
        var errorD = result[2].split(':');
        var errorData = errorD[1];
        $(".error_imported").html(errorData=='null'? '' : errorData);
        var width1 = (errorData * 100) / totalData;
        width1 = Math.round(width1);
        // widthelem1.style.width = width1 + '%';
        // elem1.innerHTML = width1+'%';
        // elem1.innerHTML = errorData;
        
  
        //update count
        var updateD = result[3].split(':');
        var updateData = updateD[1];
        var width2 = (updateData * 100) / totalData;
        width2 = Math.round(width2);
        /*widthelem2.style.width = width2+'%'; 
        elem2.innerHTML = updateData+ ' Record Updated('+width2+'%)';*/
  
        width = width + width2;
        // widthelem.style.width = width + '%';
        // elem.innerHTML =width+'%';
        if(elem) {
          elem.innerHTML = String(Number(importData) + Number(updateData));
        }
        // elem.innerHTML = String(Number(importData) + Number(updateData));
        var dt = String(parseInt(importData))
        if (importData !== 'null') {
          $(".count_imported").html(dt=='null'? '' : dt);
        } if (importData == 'null') {
          $(".count_imported").html('');
        }
        if (updateData !== 'null') {
          $(".update_imported").html(parseInt(updateData))
          
        }
  
        // $(".count_imported").html(String(parseInt(importData) + parseInt(updateData)));
  
        // if(totalData<=parseInt(importData)+parseInt(errorData)+parseInt(updateData)){
        //       $("#statusComplete").html("Completed");
        //       clearInterval(logInterval);
        // }
  
        //import file status
        if (status == '0') {
          // $(".statusImport").show();
          $(".statusFailed").hide();
          $(".statusComplete").hide();
        } else if (status == '1') {
          // $(".statusImport").hide();
          $(".statusFailed").hide();
          $(".statusComplete").show();
        } else if (status == '2') {
          // $(".statusImport").hide();
          $(".statusFailed").show();
          $(".statusComplete").hide();
        }
  
      }
    });

  

  

}

 function ImportData({value}) {
     const [file1, setFile] = useState();
     const [importData, SetImportData] = useState();
     const [percent, setPercent] = useState();
     const [errorpercent, setErrorPercent] = useState();
     const [errorData, SetErrorData] = useState();
     const [statusData, setStatusData] = useState();
     const [complete, setComplete] = useState();
     const fileReader = new FileReader();
     const formdata = new FormData();
     
    console.log("type", value)
    const now = 0;
    useEffect(() => { setPercent(now); setErrorPercent(now); }, [])
     const handleOnChange = (e) => {
         setFile(e.target.files[0]);
         

         if(value==4){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#areaDataFileName").html(e?.target?.files[0]?.name);
         } else if(value==5){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#indicatorDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==6){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#metadataDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==1){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#dataentryDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==25){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#dataentryDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==32){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#goaltargetDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==33){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#sectionsubsectionDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==30){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#timeperiodDataFileName").html(e?.target?.files[0]?.name);
         }else if(value==31){
          // console.log("filenamedata",e?.target?.files[0]?.name)
          $("#datasourceDataFileName").html(e?.target?.files[0]?.name);
         }

        
         $("button").removeClass("disable_bg");
         
       
     };
    //  setPercent(0);

    useEffect(() => { 
      if(value==4 || value==30 || value==32 || value==33 || value==31 || value==5 || value==6 || value==1 || value==10 || value==11 || value==12 || value==13 || value==14 || value==15 || value==16 || value==17 || value==18 || value==19 || value==20 || value==21 || value==22 || value==23 || value == 28 || value == 50 || value == 51 || value == 52 || value == 53 || value == 54 || value == 55 || value == 56 || value == 57){
        let val = 0;
        if(value==4){
          $('#dataimport').attr('style', 'display:block')
            val =4;
        }else if(value==5){
          val =5;
        }else if(value==6){
          val =7;
        }
        else if(value==30){
          $('#dataimport').attr('style', 'display:block')
          val =30;
        }
        else if(value==33){
          $('#dataimport').attr('style', 'display:block')
          val =33;
        }
        else if(value==32){
          $('#dataimport').attr('style', 'display:block')
          val =32;
        }
        else if(value==31){
          $('#dataimport').attr('style', 'display:block')
          val =31;
        }
        else if(value==1){
          $('#dataimport').attr('style', 'display:block')
          val =1;
        }else if(value==10){
          val =16;
        }else if(value==11){
          val =9;
        }else if(value==12){
          val =10;
        }else if(value==13){
          val =14;
        }else if(value==14){
          val =12;
        }else if(value==15){
          val =11;
        }else if(value==16){
          val =13;
        }else if(value==17){
          val =17;
        }else if(value==18){
          val =19;
        }else if(value==19){
          val =20;
        }else if(value==20){
          val =21;
        }else if(value==21){
          val =23;
        }else if(value==22){
          val =22;
        }else if(value==23){
          val =24;
        }else if(value==28){
          val =28;
        }else if(value==50){
          val =50;
        }else if(value==51){
          val =51;
        }else if(value==52){
          val =52;
        }else if(value==53){
          val =53;
        }else if(value==54){
          val =54;
        }else if(value==55){
          val =55;
        }else if(value==56){
          val =56;
        }else if(value==57){
          val =57;
        }
  
         AdminService.httpGet('/data-import/uploadedFile/last-upload-file/'+val).then((res)=>{
          
            if(res){
                
                let d = res.data;
                
                if(d?.length>0){
                  setComplete("complete")
                  console.log("rishav123")
                }else{
                  setComplete("incomplete")
                  console.log("rishav123456")
                }
                
                  
                            
                    let filepath = d[0]?.file_name? d[0]?.file_name : '';
                    let createdate = d[0]?.createdAt ? d[0]?.createdAt : '';
                    let status = d[0]?.status? d[0]?.status : '';
  
                    
                    console.log("filepath:"+filepath);
                    $(".fileName").html(filepath);
                    $(".currentDate").html(createdate);
                    if(status=="1"){
                      $(".animate-text").html("Completed");
                      // $('.processDiv').css('display', 'none')
                    } 
                    // else if (status == "0") {
                    //   $('.processDiv').css('display', 'none')
          
                    // } else {
                    //   $('.processDiv').css('display', 'block')
          
                    // }
  
                    clearInterval(logInterval);
                    
                    // var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + d[0]?._id + '.txt';
  
                    var logInterval =  setInterval(async function () {
                      await $.ajax({
                        url: fileName, success: function (response) {
                          console.log("====>>>", response)
  
                          if (response) {
  
                            var result = response.split('=');
          
                            var total = result[0].split(':');
                            var totalData = total[1];
          
                            //import record count
                            var importD = result[1].split(':');
                            var importData = importD[1];
                            var width = (importData * 100) / totalData;
                            width = Math.round(width);
          
                            //error count
                            var errorD = result[2].split(':');
                            var errorData = errorD[1];
                            var width1 = (errorData * 100) / totalData;
                            width1 = Math.round(width1);
          
          
                            //update count
                            var updateD = result[3].split(':');
                            var updateData = updateD[1];
                            var width2 = (updateData * 100) / totalData;
                            width2 = Math.round(width2);
                            // $(".total_record").html(parseInt(totalData));
                            $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                            // $(".error_imported").html(parseInt(errorData));
          
                            var statusComplete = result[4].split(':');
                            var statusCompleteGet = statusComplete[1];
          
                            if (statusCompleteGet == 1) {
          
                              $(".statusImport").hide();
                              $(".statusFailed").hide();
                              $("#filestatus").html('completed').css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                              $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                              clearInterval(logInterval);
                              
                              getImportLog('progress_' + d[0]?._id + '.txt', 1);
                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                              //   location.reload();
                              // });
                            } else {
                              clearInterval(logInterval);
                              getImportLog('progress_' + d[0]?._id + '.txt', 0);
                            }      
          
          
                          }
                        }
                      })
                    }, 3000)
                    
            }
        
        }).catch((err)=>{
        
        console.log(err)
        })
  
       }
    
    
    }, [])

     

    


     
     const handleOnSubmit = (e) => {         e.preventDefault();     
     
        const myFile = $('input[type=file]')[0].files[0];
        
        console.log("reader--"+fileReader.readAsText(myFile))
        
        formdata.append('files', file1);
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        console.log("saved--"+savedItem)
        let id = savedItem.id;
        console.log("id--"+id)
        formdata.append('user_id', id);

        if(value==1){
           
         
            formdata.append('import_for', value);
            
        }else if(value==6){
            value=7;
            console.log("print--"+value);
            formdata.append('import_for', value);
        }else if(value==11){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 9);
        }
        else if(value==15){
            
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 11);
        }
        else if(value==16){

          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 13);
        }
        else if(value==10){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 16);
        }

        else if(value==14){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 12);
        }
        else if(value==13){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 14);
        }
        else if(value==17){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 17);
        }
        else if(value==18){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 19);
        }
        else if(value==19){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 20);
        }
        else if(value==21){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 23);
        }
        else if(value==22){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 22);
        }
        else if(value==23){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 24);
        }
        else if(value==20){
          
          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 21);
        }
        else if(value==12){

          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 10);
        }
        else if(value==58){

          console.log("print--"+value);
          formdata.append('translation_lang', 'all');
          formdata.append('import_for', 58);
        }
        else{
          formdata.append('import_for', value); 
        }
        
        console.log("myFile",file1)

        
        if(value==4){
          // $('#dataimport').attr('style', 'display:none')
        AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
            if(res){
              $('#dataimport').attr('style', 'display:block')
              $('#statusbar').attr('style', 'display:block')

              
                let d = res.data;
                $('.processDiv').css('display', 'block')
                $(".animate-text").html("Progress");
                console.log("data------"+res.import_id)
                $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                
                if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                  $('#importClose').trigger('click');
                  toast.error("Invalid file format");
                  
              }
              // else{
              //     toast.success("Failed to approve data");

              // }
                    
                          
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';

                      var  logInterval =  setInterval(async function () {

                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                              
                        await $.ajax({
                               url: fileName, success:  function (response) {

                                if (response) {
                                    // $(".animate-text").html("Completed");
                                    $(".fileName").html(res.name);
                                    $(".currentDate").html(res.currentDate);
                
                                  var result = response.split('=');
                
                                  var total = result[0].split(':');
                                  
                                  
                                  var totalData = total[1];
                                  $(".total_record").html(totalData);
                
                                  //import record count
                                  var importD = result[1].split(':');
                                  var importData = importD[1];
                                  
                                  
                                  var width = ((parseInt(importData)  * 100) / parseInt(totalData)) ;
                                  
                                  width = Math.round(width);
                                  
                                  
                                  //error count
                                  var errorD = result[2].split(':');
                                  var errorData = errorD[1];
                                  var width1 = (errorData * 100) / totalData;
                                  width1 = Math.round(width1);
                                  setErrorPercent(width1);
                
                                  //update count
                                  var updateD = result[3].split(':');
                                  var updateData = updateD[1];
                                  var width2 = (updateData * 100) / totalData;
                                  width2 = Math.round(width2);
                                  var width4 = parseInt(importData)  + parseInt(updateData) 
                                  var width5 = (width4 * 100) / totalData;
                                  width5 = Math.round(width5);
                                  setPercent(width5)

                                  $(".count_imported").html(parseInt(importData));
                                  $(".update_imported").html(parseInt(updateData));
                                  $(".error_imported").html(errorData);
                                  SetErrorData(errorData)
                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                 
                                  var statusComplete = result[4].split(':');
                                  var statusCompleteGet = statusComplete[1];
                                  $(".mt-1").show();
                                  $(".mt-2").show();
                                  setStatusData("completed");
                
                                  if (statusCompleteGet == 1) {
                
                                    // $(".statusImport").hide();
                                    // $(".statusFailed").hide();
                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                    clearInterval(logInterval);
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                    //   location.reload();
                                    // });
                                  } else {
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                  }
                                  if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                    $(".animate-text").html("Completed");
                                    console.log("done data", importData, updateData, errorData, totalData)
                                    
                                    // setTimeout(() => {
                                    //   window.location.reload(false);
                                    // }, 2000);
                                }
                
                                    
                                }
                              
                            // })

                        }
                    });
                  //   setTimeout(()=>{
                  //     // window.location.reload(false);
                  // }, 3000);
                          }, 3000);


                        
                      
                  
               

            }

})  
.catch((err)=>{
  if(  err?.message == 'Invalid file format'){
   
    toast.error("Invalid file format");
    
    }
    console.log(err)
});

        }




        //area translate
        if(value==11){

          AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
              if(res){
                $('#statusbar').attr('style', 'display:block')
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------"+res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                  
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                // else{
                //     toast.success("Failed to approve data");
  
                // }
                      
                            
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
  
                        var  logInterval =  setInterval(async function () {
  
                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                
                          await $.ajax({
                                 url: fileName, success:  function (response) {
  
                                  if (response) {
                                      // $(".animate-text").html("Completed");
                                      $(".fileName").html(res.name);
                                      $(".currentDate").html(res.currentDate);
                  
                                    var result = response.split('=');
                  
                                    var total = result[0].split(':');
                                    
                                    
                                    var totalData = total[1];
                                    $(".total_record").html(totalData);
                  
                                    //import record count
                                    var importD = result[1].split(':');
                                    var importData = importD[1];
                                    
                                    
                                    
                                    
                                    
                                    //error count
                                    var errorD = result[2].split(':');
                                    var errorData = errorD[1];
                                    var width1 = (errorData * 100) / totalData;
                                    width1 = Math.round(width1);
                                    setErrorPercent(width1);
                  
                                    //update count
                                    var updateD = result[3].split(':');
                                    var updateData = updateD[1];
                                    var width2 = (updateData * 100) / totalData;
                                    width2 = Math.round(width2);
                                    var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                    setPercent(width)
                                    width = Math.round(width);
                                    $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                    $(".update_imported").html(updateData);
                                    $(".error_imported").html(errorData);
                                    SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                    console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                   
                                    var statusComplete = result[4].split(':');
                                    var statusCompleteGet = statusComplete[1];
                                    $(".mt-1").show();
                                    $(".mt-2").show();
                                    setStatusData("completed");
                  
                                    if (statusCompleteGet == 1) {
                  
                                      // $(".statusImport").hide();
                                      // $(".statusFailed").hide();
                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                      clearInterval(logInterval);
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                      //   location.reload();
                                      // });
                                    } else {
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                    }
                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                      $(".animate-text").html("Completed");
                                      window.location.reload(false);
                                  }
                  
                                      
                                  }
                                
                              // })
  
                          }
                      });
                    //   setTimeout(()=>{
                    //     // window.location.reload(false);
                    // }, 3000);
                            }, 3000);
  
  
                          
                        
                    
                 
  
              }
  
  })  
  .catch((err)=>{
    if(  err?.message == 'Invalid file format'){
     
      toast.error("Invalid file format");
      
      }
      console.log(err)
  });
  
          }



  //metadata translate
          if(value==13){

            AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                if(res){
                  $('#statusbar').attr('style', 'display:block')
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------"+res.import_id)
                    $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
                    
                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
                      
                  }
                  // else{
                  //     toast.success("Failed to approve data");

                  // }
                        
                              
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';

                          var  logInterval =  setInterval(async function () {

                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                  
                            await $.ajax({
                                  url: fileName, success:  function (response) {

                                    if (response) {
                                        // $(".animate-text").html("Completed");
                                        $(".fileName").html(res.name);
                                        $(".currentDate").html(res.currentDate);
                    
                                      var result = response.split('=');
                    
                                      var total = result[0].split(':');
                                      
                                      
                                      var totalData = total[1];
                                      $(".total_record").html(totalData);
                    
                                      //import record count
                                      var importD = result[1].split(':');
                                      var importData = importD[1];
                                      
                                      
                                      
                                      
                                      
                                      //error count
                                      var errorD = result[2].split(':');
                                      var errorData = errorD[1];
                                      var width1 = (errorData * 100) / totalData;
                                      width1 = Math.round(width1);
                                      setErrorPercent(width1);
                    
                                      //update count
                                      var updateD = result[3].split(':');
                                      var updateData = updateD[1];
                                      var width2 = (updateData * 100) / totalData;
                                      width2 = Math.round(width2);
                                      var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                      setPercent(width)
                                      width = Math.round(width);
                                      $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                      $(".update_imported").html(updateData);
                                      $(".error_imported").html(errorData);
                                      SetErrorData(errorData)
                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                      console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                    
                                      var statusComplete = result[4].split(':');
                                      var statusCompleteGet = statusComplete[1];
                                      $(".mt-1").show();
                                      $(".mt-2").show();
                                      setStatusData("completed");
                    
                                      if (statusCompleteGet == 1) {
                    
                                        // $(".statusImport").hide();
                                        // $(".statusFailed").hide();
                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                        clearInterval(logInterval);
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                        //   location.reload();
                                        // });
                                      } else {
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                      }
                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                        console.log("done data")
                                        $(".animate-text").html("Completed");
                                        window.location.reload(false);
                                    }
                    
                                        
                                    }
                                  
                                // })

                            }
                        });
                      //   setTimeout(()=>{
                      //     // window.location.reload(false);
                      // }, 3000);
                              }, 3000);


                            
                          
                      
                  

                }

        })  
        .catch((err)=>{
        if(  err?.message == 'Invalid file format'){

        toast.error("Invalid file format");

        }
        console.log(err)
        });

            }


          //subgroup translate
        if(value==14){

          AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
              if(res){
                $('#statusbar').attr('style', 'display:block')
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------"+res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                  
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                // else{
                //     toast.success("Failed to approve data");
  
                // }
                      
                            
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
  
                        var  logInterval =  setInterval(async function () {
  
                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                
                          await $.ajax({
                                 url: fileName, success:  function (response) {
  
                                  if (response) {
                                      // $(".animate-text").html("Completed");
                                      $(".fileName").html(res.name);
                                      $(".currentDate").html(res.currentDate);
                  
                                    var result = response.split('=');
                  
                                    var total = result[0].split(':');
                                    
                                    
                                    var totalData = total[1];
                                    $(".total_record").html(totalData);
                  
                                    //import record count
                                    var importD = result[1].split(':');
                                    var importData = importD[1];
                                    
                                    
                                 
                                    //error count
                                    var errorD = result[2].split(':');
                                    var errorData = errorD[1];
                                    var width1 = (errorData * 100) / totalData;
                                    width1 = Math.round(width1);
                                    setErrorPercent(width1);
                  
                                    //update count
                                    var updateD = result[3].split(':');
                                    var updateData = updateD[1];
                                    var width2 = (updateData * 100) / totalData;
                                    width2 = Math.round(width2);

                                    var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                    
                                    width = Math.round(width);
                                    
                                    setPercent(width)

                                    $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                    $(".update_imported").html(updateData);
                                    $(".error_imported").html(errorData);
                                    SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                    console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                   
                                    var statusComplete = result[4].split(':');
                                    var statusCompleteGet = statusComplete[1];
                                    $(".mt-1").show();
                                    $(".mt-2").show();
                                    setStatusData("completed");
                  
                                    if (statusCompleteGet == 1) {
                  
                                      // $(".statusImport").hide();
                                      // $(".statusFailed").hide();
                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                      clearInterval(logInterval);
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                      //   location.reload();
                                      // });
                                    } else {
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                    }
                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                      console.log("done data")
                                      $(".animate-text").html("Completed");
                                      window.location.reload(false);
                                  }
                  
                                      
                                  }
                                
                              // })
  
                          }
                      });
                    //   setTimeout(()=>{
                    //     // window.location.reload(false);
                    // }, 3000);
                            }, 3000);
  
  
                          
                        
                    
                 
  
              }
  
  })  
  .catch((err)=>{
    if(  err?.message == 'Invalid file format'){
     
      toast.error("Invalid file format");
      
      }
      console.log(err)
  });
  
          }



          //source translate
        if(value==16){

          AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
              if(res){
                $('#statusbar').attr('style', 'display:block')
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------"+res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                  
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                // else{
                //     toast.success("Failed to approve data");
  
                // }
                      
                            
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
  
                        var  logInterval =  setInterval(async function () {
  
                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                
                          await $.ajax({
                                 url: fileName, success:  function (response) {
  
                                  if (response) {
                                      // $(".animate-text").html("Completed");
                                      $(".fileName").html(res.name);
                                      $(".currentDate").html(res.currentDate);
                  
                                    var result = response.split('=');
                  
                                    var total = result[0].split(':');
                                    
                                    
                                    var totalData = total[1];
                                    $(".total_record").html(totalData);
                  
                                    //import record count
                                    var importD = result[1].split(':');
                                    var importData = importD[1];
                                    
                                    
                                    
                                    //error count
                                    var errorD = result[2].split(':');
                                    var errorData = errorD[1];
                                    var width1 = (errorData * 100) / totalData;
                                    width1 = Math.round(width1);
                                    setErrorPercent(width1);
                  
                                    //update count
                                    var updateD = result[3].split(':');
                                    var updateData = updateD[1];
                                    var width2 = (updateData * 100) / totalData;
                                    width2 = Math.round(width2);

                                    var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                    
                                    width = Math.round(width);
                                    
                                    setPercent(width)

                                    $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                    $(".update_imported").html(updateData);
                                    $(".error_imported").html(errorData);
                                    SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                    console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                   
                                    var statusComplete = result[4].split(':');
                                    var statusCompleteGet = statusComplete[1];
                                    $(".mt-1").show();
                                    $(".mt-2").show();
                                    setStatusData("completed");
                  
                                    if (statusCompleteGet == 1) {
                  
                                      // $(".statusImport").hide();
                                      // $(".statusFailed").hide();
                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                      clearInterval(logInterval);
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                      //   location.reload();
                                      // });
                                    } else {
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                    }
                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                      console.log("done data")
                                      $(".animate-text").html("Completed");
                                      window.location.reload(false);
                                  }
                  
                                      
                                  }
                                
                              // })
  
                          }
                      });
                    //   setTimeout(()=>{
                    //     // window.location.reload(false);
                    // }, 3000);
                            }, 3000);
  
  
                          
                        
                    
                 
  
              }
  
  })  
  .catch((err)=>{
    if(  err?.message == 'Invalid file format'){
     
      toast.error("Invalid file format");
      
      }
      console.log(err)
  });
  
          }

 //resource translate
        if(value==17){

        AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
          if(res){
            $('#statusbar').attr('style', 'display:block')
              let d = res.data;
              $(".animate-text").html("Progress");
              console.log("data------"+res.import_id)
              $(".mt-1").hide();
              $(".mt-2").hide();
              setStatusData("progress");
              
              if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                $('#importClose').trigger('click');
                toast.error("Invalid file format");
                
            }
            // else{
            //     toast.success("Failed to approve data");

            // }
                  
                        
              var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';

                    var  logInterval =  setInterval(async function () {

                      // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                            
                      await $.ajax({
                              url: fileName, success:  function (response) {

                              if (response) {
                                  // $(".animate-text").html("Completed");
                                  $(".fileName").html(res.name);
                                  $(".currentDate").html(res.currentDate);
              
                                var result = response.split('=');
              
                                var total = result[0].split(':');
                                
                                
                                var totalData = total[1];
                                $(".total_record").html(totalData);
              
                                //import record count
                                var importD = result[1].split(':');
                                var importData = importD[1];
                                
                                
                               
                                //error count
                                var errorD = result[2].split(':');
                                var errorData = errorD[1];
                                var width1 = (errorData * 100) / totalData;
                                width1 = Math.round(width1);
                                setErrorPercent(width1);
              
                                //update count
                                var updateD = result[3].split(':');
                                var updateData = updateD[1];
                                var width2 = (updateData * 100) / totalData;
                                width2 = Math.round(width2);

                                var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                
                                width = Math.round(width);
                                
                                setPercent(width)

                                $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                $(".update_imported").html(updateData);
                                $(".error_imported").html(errorData);
                                SetErrorData(errorData)
                                SetImportData(parseInt(importData) + parseInt(updateData))
                                console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                
                                var statusComplete = result[4].split(':');
                                var statusCompleteGet = statusComplete[1];
                                $(".mt-1").show();
                                $(".mt-2").show();
                                setStatusData("completed");
              
                                if (statusCompleteGet == 1) {
              
                                  // $(".statusImport").hide();
                                  // $(".statusFailed").hide();
                                  // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                  // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                  clearInterval(logInterval);
                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                  // $(document).on('hidden.bs.modal', '#importModal', function () {
                                  //   location.reload();
                                  // });
                                } else {
                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                }
                                if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                  console.log("done data")
                                  $(".animate-text").html("Completed");
                                  window.location.reload(false);
                              }
              
                                  
                              }
                            
                          // })

                      }
                  });
                //   setTimeout(()=>{
                //     // window.location.reload(false);
                // }, 3000);
                        }, 3000);


                      
                    
                
              

          }

        })  
        .catch((err)=>{
        if(  err?.message == 'Invalid file format'){

        toast.error("Invalid file format");

        }
        console.log(err)
        });

        }



        //disaster translate
        if(value==18){

          AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
            if(res){
              $('#statusbar').attr('style', 'display:block')
                let d = res.data;
                $(".animate-text").html("Progress");
                console.log("data------"+res.import_id)
                $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                
                if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                  $('#importClose').trigger('click');
                  toast.error("Invalid file format");
                  
              }
              // else{
              //     toast.success("Failed to approve data");
  
              // }
                    
                          
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
  
                      var  logInterval =  setInterval(async function () {
  
                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                              
                        await $.ajax({
                                url: fileName, success:  function (response) {
  
                                if (response) {
                                    // $(".animate-text").html("Completed");
                                    $(".fileName").html(res.name);
                                    $(".currentDate").html(res.currentDate);
                
                                  var result = response.split('=');
                
                                  var total = result[0].split(':');
                                  
                                  
                                  var totalData = total[1];
                                  $(".total_record").html(totalData);
                
                                  //import record count
                                  var importD = result[1].split(':');
                                  var importData = importD[1];
                                  
                                  
                                 
                                  //error count
                                  var errorD = result[2].split(':');
                                  var errorData = errorD[1];
                                  var width1 = (errorData * 100) / totalData;
                                  width1 = Math.round(width1);
                                  setErrorPercent(width1);
                
                                  //update count
                                  var updateD = result[3].split(':');
                                  var updateData = updateD[1];
                                  var width2 = (updateData * 100) / totalData;
                                  width2 = Math.round(width2);
  
                                  var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                  
                                  width = Math.round(width);
                                  
                                  setPercent(width)
  
                                  $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                  $(".update_imported").html(updateData);
                                  $(".error_imported").html(errorData);
                                  SetErrorData(errorData)
                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                  console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                  
                                  var statusComplete = result[4].split(':');
                                  var statusCompleteGet = statusComplete[1];
                                  $(".mt-1").show();
                                  $(".mt-2").show();
                                  setStatusData("completed");
                
                                  if (statusCompleteGet == 1) {
                
                                    // $(".statusImport").hide();
                                    // $(".statusFailed").hide();
                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                    clearInterval(logInterval);
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                    //   location.reload();
                                    // });
                                  } else {
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                  }
                                  if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                    console.log("done data")
                                    $(".animate-text").html("Completed");
                                    window.location.reload(false);
                                }
                
                                    
                                }
                              
                            // })
  
                        }
                    });
                  //   setTimeout(()=>{
                  //     // window.location.reload(false);
                  // }, 3000);
                          }, 3000);
  
  
                        
                      
                  
                
  
            }
  
          })  
          .catch((err)=>{
          if(  err?.message == 'Invalid file format'){
  
          toast.error("Invalid file format");
  
          }
          console.log(err)
          });
  
          }
          // option translate
          if(value==19){

            AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
              if(res){
                $('#statusbar').attr('style', 'display:block')
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------"+res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                  
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                // else{
                //     toast.success("Failed to approve data");
    
                // }
                      
                            
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
    
                        var  logInterval =  setInterval(async function () {
    
                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                
                          await $.ajax({
                                  url: fileName, success:  function (response) {
    
                                  if (response) {
                                      // $(".animate-text").html("Completed");
                                      $(".fileName").html(res.name);
                                      $(".currentDate").html(res.currentDate);
                  
                                    var result = response.split('=');
                  
                                    var total = result[0].split(':');
                                    
                                    
                                    var totalData = total[1];
                                    $(".total_record").html(totalData);
                  
                                    //import record count
                                    var importD = result[1].split(':');
                                    var importData = importD[1];
                                    
                                    
                                   
                                    //error count
                                    var errorD = result[2].split(':');
                                    var errorData = errorD[1];
                                    var width1 = (errorData * 100) / totalData;
                                    width1 = Math.round(width1);
                                    setErrorPercent(width1);
                  
                                    //update count
                                    var updateD = result[3].split(':');
                                    var updateData = updateD[1];
                                    var width2 = (updateData * 100) / totalData;
                                    width2 = Math.round(width2);
    
                                    var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                    
                                    width = Math.round(width);
                                    
                                    setPercent(width)
    
                                    $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                    $(".update_imported").html(updateData);
                                    $(".error_imported").html(errorData);
                                    SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                    console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                    
                                    var statusComplete = result[4].split(':');
                                    var statusCompleteGet = statusComplete[1];
                                    $(".mt-1").show();
                                    $(".mt-2").show();
                                    setStatusData("completed");
                  
                                    if (statusCompleteGet == 1) {
                  
                                      // $(".statusImport").hide();
                                      // $(".statusFailed").hide();
                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                      clearInterval(logInterval);
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                      //   location.reload();
                                      // });
                                    } else {
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                    }
                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                      console.log("done data")
                                      $(".animate-text").html("Completed");
                                      window.location.reload(false);
                                  }
                  
                                      
                                  }
                                
                              // })
    
                          }
                      });
                    //   setTimeout(()=>{
                    //     // window.location.reload(false);
                    // }, 3000);
                            }, 3000);
    
    
                          
                        
                    
                  
    
              }
    
            })  
            .catch((err)=>{
            if(  err?.message == 'Invalid file format'){
    
            toast.error("Invalid file format");
    
            }
            console.log(err)
            });
    
            }
            // section translate
            if(value==20){

              AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                if(res){
                  $('#statusbar').attr('style', 'display:block')
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------"+res.import_id)
                    $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
                    
                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
                      
                  }
                  // else{
                  //     toast.success("Failed to approve data");
      
                  // }
                        
                              
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
      
                          var  logInterval =  setInterval(async function () {
      
                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                  
                            await $.ajax({
                                    url: fileName, success:  function (response) {
      
                                    if (response) {
                                        // $(".animate-text").html("Completed");
                                        $(".fileName").html(res.name);
                                        $(".currentDate").html(res.currentDate);
                    
                                      var result = response.split('=');
                    
                                      var total = result[0].split(':');
                                      
                                      
                                      var totalData = total[1];
                                      $(".total_record").html(totalData);
                    
                                      //import record count
                                      var importD = result[1].split(':');
                                      var importData = importD[1];
                                      
                                      
                                     
                                      //error count
                                      var errorD = result[2].split(':');
                                      var errorData = errorD[1];
                                      var width1 = (errorData * 100) / totalData;
                                      width1 = Math.round(width1);
                                      setErrorPercent(width1);
                    
                                      //update count
                                      var updateD = result[3].split(':');
                                      var updateData = updateD[1];
                                      var width2 = (updateData * 100) / totalData;
                                      width2 = Math.round(width2);
      
                                      var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                      
                                      width = Math.round(width);
                                      
                                      setPercent(width)
      
                                      $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                      $(".update_imported").html(updateData);
                                      $(".error_imported").html(errorData);
                                      SetErrorData(errorData)
                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                      console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                      
                                      var statusComplete = result[4].split(':');
                                      var statusCompleteGet = statusComplete[1];
                                      $(".mt-1").show();
                                      $(".mt-2").show();
                                      setStatusData("completed");
                    
                                      if (statusCompleteGet == 1) {
                    
                                        // $(".statusImport").hide();
                                        // $(".statusFailed").hide();
                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                        clearInterval(logInterval);
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                        //   location.reload();
                                        // });
                                      } else {
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                      }
                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                        console.log("done data")
                                        $(".animate-text").html("Completed");
                                        window.location.reload(false);
                                    }
                    
                                        
                                    }
                                  
                                // })
      
                            }
                        });
                      //   setTimeout(()=>{
                      //     // window.location.reload(false);
                      // }, 3000);
                              }, 3000);
      
      
                            
                          
                      
                    
      
                }
      
              })  
              .catch((err)=>{
              if(  err?.message == 'Invalid file format'){
      
              toast.error("Invalid file format");
      
              }
              console.log(err)
              });
      
              }













          //classification translate
          if(value==10){

            AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                if(res){
                  $('#statusbar').attr('style', 'display:block')
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------"+res.import_id)
                    $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
                    
                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
                      
                  }
                  // else{
                  //     toast.success("Failed to approve data");
    
                  // }
                        
                              
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
    
                          var  logInterval =  setInterval(async function () {
    
                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                  
                            await $.ajax({
                                   url: fileName, success:  function (response) {
    
                                    if (response) {
                                        // $(".animate-text").html("Completed");
                                        $(".fileName").html(res.name);
                                        $(".currentDate").html(res.currentDate);
                    
                                      var result = response.split('=');
                    
                                      var total = result[0].split(':');
                                      
                                      
                                      var totalData = total[1];
                                      $(".total_record").html(totalData);
                    
                                      //import record count
                                      var importD = result[1].split(':');
                                      var importData = importD[1];
                                      
                                      
                                    
                                      //error count
                                      var errorD = result[2].split(':');
                                      var errorData = errorD[1];
                                      var width1 = (errorData * 100) / totalData;
                                      width1 = Math.round(width1);
                                      setErrorPercent(width1);
                    
                                      //update count
                                      var updateD = result[3].split(':');
                                      var updateData = updateD[1];
                                      var width2 = (updateData * 100) / totalData;
                                      width2 = Math.round(width2);

                                      var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                      
                                      width = Math.round(width);
                                      
                                      setPercent(width)

                                      $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                      $(".update_imported").html(updateData);
                                      $(".error_imported").html(errorData);
                                      SetErrorData(errorData)
                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                      console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                     
                                      var statusComplete = result[4].split(':');
                                      var statusCompleteGet = statusComplete[1];
                                      $(".mt-1").show();
                                      $(".mt-2").show();
                                      setStatusData("completed");
                    
                                      if (statusCompleteGet == 1) {
                    
                                        // $(".statusImport").hide();
                                        // $(".statusFailed").hide();
                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                        clearInterval(logInterval);
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                        //   location.reload();
                                        // });
                                      } else {
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                      }
                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                        console.log("done data")
                                        $(".animate-text").html("Completed");
                                        window.location.reload(false);
                                    }
                    
                                        
                                    }
                                  
                                // })
    
                            }
                        });
                      //   setTimeout(()=>{
                      //     // window.location.reload(false);
                      // }, 3000);
                              }, 3000);
    
    
                            
                          
                      
                   
    
                }
    
    })  
    .catch((err)=>{
      if(  err?.message == 'Invalid file format'){
       
        toast.error("Invalid file format");
        
        }
        console.log(err)
    });
    
            }


          //unit translate
          if(value==15){

            AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                if(res){
                  $('#statusbar').attr('style', 'display:block')
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------"+res.import_id)
                    $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
                    
                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
                      
                  }
                  // else{
                  //     toast.success("Failed to approve data");
    
                  // }
                        
                              
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
    
                          var  logInterval =  setInterval(async function () {
    
                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                  
                            await $.ajax({
                                   url: fileName, success:  function (response) {
    
                                    if (response) {
                                        // $(".animate-text").html("Completed");
                                        $(".fileName").html(res.name);
                                        $(".currentDate").html(res.currentDate);
                    
                                      var result = response.split('=');
                    
                                      var total = result[0].split(':');
                                      
                                      
                                      var totalData = total[1];
                                      $(".total_record").html(totalData);
                    
                                      //import record count
                                      var importD = result[1].split(':');
                                      var importData = importD[1];
                                      
                                      
                                      
                                      //error count
                                      var errorD = result[2].split(':');
                                      var errorData = errorD[1];
                                      var width1 = (errorData * 100) / totalData;
                                      width1 = Math.round(width1);
                                      setErrorPercent(width1);
                    
                                      //update count
                                      var updateD = result[3].split(':');
                                      var updateData = updateD[1];
                                      var width2 = (updateData * 100) / totalData;
                                      width2 = Math.round(width2);
                                      var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                      
                                      width = Math.round(width);
                                      
                                      setPercent(width)
                                      $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                      $(".update_imported").html(updateData);
                                      $(".error_imported").html(errorData);
                                      SetErrorData(errorData)
                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                      console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                     
                                      var statusComplete = result[4].split(':');
                                      var statusCompleteGet = statusComplete[1];
                                      $(".mt-1").show();
                                      $(".mt-2").show();
                                      setStatusData("completed");
                    
                                      if (statusCompleteGet == 1) {
                    
                                        // $(".statusImport").hide();
                                        // $(".statusFailed").hide();
                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                        clearInterval(logInterval);
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                        //   location.reload();
                                        // });
                                      } else {
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                      }
                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                        console.log("done data")
                                        $(".animate-text").html("Completed");
                                        window.location.reload(false);
                                    }
                    
                                        
                                    }
                                  
                                // })
    
                            }
                        });
                      //   setTimeout(()=>{
                      //     // window.location.reload(false);
                      // }, 3000);
                              }, 3000);
    
    
                            
                          
                      
                   
    
                }
    
    })  
    .catch((err)=>{
      if(  err?.message == 'Invalid file format'){
       
        toast.error("Invalid file format");
        
        }
        console.log(err)
    });
    
            }
            //question
            if(value==21){

              AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                  if(res){
                    $('#statusbar').attr('style', 'display:block')
                      let d = res.data;
                      $(".animate-text").html("Progress");
                      console.log("data------"+res.import_id)
                      $(".mt-1").hide();
                      $(".mt-2").hide();
                      setStatusData("progress");
                      
                      if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                        $('#importClose').trigger('click');
                        toast.error("Invalid file format");
                        
                    }
                    // else{
                    //     toast.success("Failed to approve data");
      
                    // }
                          
                                
                      var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
      
                            var  logInterval =  setInterval(async function () {
      
                              // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                    
                              await $.ajax({
                                     url: fileName, success:  function (response) {
      
                                      if (response) {
                                          // $(".animate-text").html("Completed");
                                          $(".fileName").html(res.name);
                                          $(".currentDate").html(res.currentDate);
                      
                                        var result = response.split('=');
                      
                                        var total = result[0].split(':');
                                        
                                        
                                        var totalData = total[1];
                                        $(".total_record").html(totalData);
                      
                                        //import record count
                                        var importD = result[1].split(':');
                                        var importData = importD[1];
                                        
                                        
                                        
                                        //error count
                                        var errorD = result[2].split(':');
                                        var errorData = errorD[1];
                                        var width1 = (errorData * 100) / totalData;
                                        width1 = Math.round(width1);
                                        setErrorPercent(width1);
                      
                                        //update count
                                        var updateD = result[3].split(':');
                                        var updateData = updateD[1];
                                        var width2 = (updateData * 100) / totalData;
                                        width2 = Math.round(width2);
                                        var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                        
                                        width = Math.round(width);
                                        
                                        setPercent(width)
                                        $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                        $(".update_imported").html(updateData);
                                        $(".error_imported").html(errorData);
                                        SetErrorData(errorData)
                                        SetImportData(parseInt(importData) + parseInt(updateData))
                                        console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                       
                                        var statusComplete = result[4].split(':');
                                        var statusCompleteGet = statusComplete[1];
                                        $(".mt-1").show();
                                        $(".mt-2").show();
                                        setStatusData("completed");
                      
                                        if (statusCompleteGet == 1) {
                      
                                          // $(".statusImport").hide();
                                          // $(".statusFailed").hide();
                                          // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                          // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                          clearInterval(logInterval);
                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                          // $(document).on('hidden.bs.modal', '#importModal', function () {
                                          //   location.reload();
                                          // });
                                        } else {
                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                        }
                                        if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                          console.log("done data")
                                          $(".animate-text").html("Completed");
                                          window.location.reload(false);
                                      }
                      
                                          
                                      }
                                    
                                  // })
      
                              }
                          });
                        //   setTimeout(()=>{
                        //     // window.location.reload(false);
                        // }, 3000);
                                }, 3000);
      
      
                              
                            
                        
                     
      
                  }
      
      })  
      .catch((err)=>{
        if(  err?.message == 'Invalid file format'){
         
          toast.error("Invalid file format");
          
          }
          console.log(err)
      });
      
              }



              //form
              if(value==22){

                AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                    if(res){
                      $('#statusbar').attr('style', 'display:block')
                        let d = res.data;
                        $(".animate-text").html("Progress");
                        console.log("data------"+res.import_id)
                        $(".mt-1").hide();
                        $(".mt-2").hide();
                        setStatusData("progress");
                        
                        if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                          $('#importClose').trigger('click');
                          toast.error("Invalid file format");
                          
                      }
                      // else{
                      //     toast.success("Failed to approve data");
        
                      // }
                            
                                  
                        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
        
                              var  logInterval =  setInterval(async function () {
        
                                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                      
                                await $.ajax({
                                       url: fileName, success:  function (response) {
        
                                        if (response) {
                                            // $(".animate-text").html("Completed");
                                            $(".fileName").html(res.name);
                                            $(".currentDate").html(res.currentDate);
                        
                                          var result = response.split('=');
                        
                                          var total = result[0].split(':');
                                          
                                          
                                          var totalData = total[1];
                                          $(".total_record").html(totalData);
                        
                                          //import record count
                                          var importD = result[1].split(':');
                                          var importData = importD[1];
                                          
                                          
                                          
                                          //error count
                                          var errorD = result[2].split(':');
                                          var errorData = errorD[1];
                                          var width1 = (errorData * 100) / totalData;
                                          width1 = Math.round(width1);
                                          setErrorPercent(width1);
                        
                                          //update count
                                          var updateD = result[3].split(':');
                                          var updateData = updateD[1];
                                          var width2 = (updateData * 100) / totalData;
                                          width2 = Math.round(width2);
                                          var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                          
                                          width = Math.round(width);
                                          
                                          setPercent(width)
                                          $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                          $(".update_imported").html(updateData);
                                          $(".error_imported").html(errorData);
                                          SetErrorData(errorData)
                                          SetImportData(parseInt(importData) + parseInt(updateData))
                                          console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                         
                                          var statusComplete = result[4].split(':');
                                          var statusCompleteGet = statusComplete[1];
                                          $(".mt-1").show();
                                          $(".mt-2").show();
                                          setStatusData("completed");
                        
                                          if (statusCompleteGet == 1) {
                        
                                            // $(".statusImport").hide();
                                            // $(".statusFailed").hide();
                                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                            clearInterval(logInterval);
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                                            //   location.reload();
                                            // });
                                          } else {
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                          }
                                          if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                            console.log("done data")
                                            $(".animate-text").html("Completed");
                                            window.location.reload(false);
                                        }
                        
                                            
                                        }
                                      
                                    // })
        
                                }
                            });
                          //   setTimeout(()=>{
                          //     // window.location.reload(false);
                          // }, 3000);
                                  }, 3000);
        
        
                                
                              
                          
                       
        
                    }
        
        })  
        .catch((err)=>{
          if(  err?.message == 'Invalid file format'){
           
            toast.error("Invalid file format");
            
            }
            console.log(err)
        });
        
                }



                //sub-section
                if(value==23){

                  AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                      if(res){
                        $('#statusbar').attr('style', 'display:block')
                          let d = res.data;
                          $(".animate-text").html("Progress");
                          console.log("data------"+res.import_id)
                          $(".mt-1").hide();
                          $(".mt-2").hide();
                          setStatusData("progress");
                          
                          if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                            $('#importClose').trigger('click');
                            toast.error("Invalid file format");
                            
                        }
                        // else{
                        //     toast.success("Failed to approve data");
          
                        // }
                              
                                    
                          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
          
                                var  logInterval =  setInterval(async function () {
          
                                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                        
                                  await $.ajax({
                                         url: fileName, success:  function (response) {
          
                                          if (response) {
                                              // $(".animate-text").html("Completed");
                                              $(".fileName").html(res.name);
                                              $(".currentDate").html(res.currentDate);
                          
                                            var result = response.split('=');
                          
                                            var total = result[0].split(':');
                                            
                                            
                                            var totalData = total[1];
                                            $(".total_record").html(totalData);
                          
                                            //import record count
                                            var importD = result[1].split(':');
                                            var importData = importD[1];
                                            
                                            
                                            
                                            //error count
                                            var errorD = result[2].split(':');
                                            var errorData = errorD[1];
                                            var width1 = (errorData * 100) / totalData;
                                            width1 = Math.round(width1);
                                            setErrorPercent(width1);
                          
                                            //update count
                                            var updateD = result[3].split(':');
                                            var updateData = updateD[1];
                                            var width2 = (updateData * 100) / totalData;
                                            width2 = Math.round(width2);
                                            var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                            
                                            width = Math.round(width);
                                            
                                            setPercent(width)
                                            $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                            $(".update_imported").html(updateData);
                                            $(".error_imported").html(errorData);
                                            SetErrorData(errorData)
                                            SetImportData(parseInt(importData) + parseInt(updateData))
                                            console.log("total data----------",parseInt(importData) + parseInt(updateData))
                                           
                                            var statusComplete = result[4].split(':');
                                            var statusCompleteGet = statusComplete[1];
                                            $(".mt-1").show();
                                            $(".mt-2").show();
                                            setStatusData("completed");
                          
                                            if (statusCompleteGet == 1) {
                          
                                              // $(".statusImport").hide();
                                              // $(".statusFailed").hide();
                                              // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                              // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                              clearInterval(logInterval);
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                                              //   location.reload();
                                              // });
                                            } else {
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                            }
                                            if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                              console.log("done data")
                                              $(".animate-text").html("Completed");
                                              window.location.reload(false);
                                          }
                          
                                              
                                          }
                                        
                                      // })
          
                                  }
                              });
                            //   setTimeout(()=>{
                            //     // window.location.reload(false);
                            // }, 3000);
                                    }, 3000);
          
          
                                  
                                
                            
                         
          
                      }
          
          })  
          .catch((err)=>{
            if(  err?.message == 'Invalid file format'){
             
              toast.error("Invalid file format");
              
              }
              console.log(err)
          });
          
                  }


          //indicator translate
        if(value==12){

          AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
              if(res){
                $('#statusbar').attr('style', 'display:block')
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------"+res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                  
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                // else{
                //     toast.success("Failed to approve data");
  
                // }
                      
                            
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
  
                        var  logInterval =  setInterval(async function () {
  
                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                
                          await $.ajax({
                                 url: fileName, success:  function (response) {
  
                                  if (response) {
                                      // $(".animate-text").html("Completed");
                                      $(".fileName").html(res.name);
                                      $(".currentDate").html(res.currentDate);
                  
                                    var result = response.split('=');
                  
                                    var total = result[0].split(':');
                                    
                                    
                                    var totalData = total[1];
                                    $(".total_record").html(totalData);
                  
                                    //import record count
                                    var importD = result[1].split(':');
                                    var importData = importD[1];
                                    
                                    
                                 
                                    //error count
                                    var errorD = result[2].split(':');
                                    var errorData = errorD[1];
                                    var width1 = (errorData * 100) / totalData;
                                    width1 = Math.round(width1);
                                    setErrorPercent(width1);
                  
                                    //update count
                                    var updateD = result[3].split(':');
                                    var updateData = updateD[1];
                                    var width2 = (updateData * 100) / totalData;
                                    width2 = Math.round(width2);
                                    var width = (((parseInt(importData) + parseInt(updateData))  * 100) / parseInt(totalData)) ;
                                    
                                    width = Math.round(width);
                                    
                                    setPercent(width)
                                    $(".count_imported").html(parseInt(importData)+ parseInt(updateData));
                                    $(".update_imported").html(updateData);
                                    $(".error_imported").html(errorData);
                                    SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                   
                                    var statusComplete = result[4].split(':');
                                    var statusCompleteGet = statusComplete[1];
                                    $(".mt-1").show();
                                    $(".mt-2").show();
                                    setStatusData("completed");
                  
                                    if (statusCompleteGet == 1) {
                  
                                      // $(".statusImport").hide();
                                      // $(".statusFailed").hide();
                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                      clearInterval(logInterval);
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                      //   location.reload();
                                      // });
                                    } else {
                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                    }
                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                      console.log("done data")
                                      $(".animate-text").html("Completed");
                                      window.location.reload(false);
                                  }
                  
                                      
                                  }
                                
                              // })
  
                          }
                      });
                    //   setTimeout(()=>{
                    //     // window.location.reload(false);
                    // }, 3000);
                            }, 3000);
  
  
                          
                        
                    
                 
  
              }
  
  })  
  .catch((err)=>{
    if(  err?.message == 'Invalid file format'){
     
      toast.error("Invalid file format");
      
      }
      console.log(err)
  });
  
          }













       else if(value==5){
        // $('#dataimport').attr('style', 'display:block')
        AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
            if(res){
              $('#statusbar').attr('style', 'display:block')
                let d = res.data;
                $(".animate-text").html("Progress");
                console.log("data------"+res.import_id)
                $(".mt-1").hide();
            $(".mt-2").hide();
            setStatusData("progress");
                
            if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
              $('#importClose').trigger('click');
              toast.error("Invalid file format");
              
          }
                    
                          
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';

                      var  logInterval =  setInterval( function () {

                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                              
                        $.ajax({
                            url: fileName, success: function (response) {

                                if (response) {
                                    // $(".animate-text").html("Completed");
                                    $(".fileName").html(res.name);
                                    $(".currentDate").html(res.currentDate);
                
                                  var result = response.split('=');
                
                                  var total = result[0].split(':');
                                  
                                  
                                  var totalData = total[1];
                                  if(totalData=="null"){
                                    $(".total_record").html("0");
                                  }else{
                                    $(".total_record").html(totalData);
                                  }
                
                                  //import record count
                                  var importD = result[1].split(':');
                                  var importData = importD[1];
                                  var width = (importData * 100) / totalData;
                                  width = Math.round(width);
                                  setPercent(width)
                                  //error count
                                  var errorD = result[3].split(':');
                                  var errorData = errorD[1];
                                  var width1 = (errorData * 100) / totalData;
                                  width1 = Math.round(width1);
                                  setErrorPercent(width1);
                
                                  //update count
                                  var updateD = result[4].split(':');
                                  var updateData = updateD[1];
                                  var width2 = (updateData * 100) / totalData;
                                  width2 = Math.round(width2);

                                  var width3 = parseInt(updateData) + parseInt(importData)
                                  var width4 = (width3 * 100) / totalData;
                                  width4 = Math.round(width4);
                                  setPercent(width4)
                                  if(parseInt(importData) + parseInt(updateData)=="null"){
                                    $(".count_imported").html("0");
                                  }else{
                                    $(".count_imported").html(parseInt(importData) );
                                  }
                                  if(updateData=="null"){
                                    $(".update_imported").html("0");
                                  }else{
                                    $(".update_imported").html(updateData);
                                  }
                                  if(errorData=="null"){
                                    $(".error_imported").html("0");
                                  }else{
                                    $(".error_imported").html(errorData);
                                  }






                                  // $(".count_imported").html(parseInt(importData) );
                                  // $(".update_imported").html(updateData);
                                  // $(".error_imported").html(errorData);
                                  var statusComplete = result[5].split(':');
                                  var statusCompleteGet = statusComplete[1];
                                  SetErrorData(errorData)
                              SetImportData(parseInt(importData) + parseInt(updateData))
                                  $(".mt-1").show();
                                  $(".mt-2").show();
                                  setStatusData("completed");
                
                                  if (statusCompleteGet == 1) {
                
                                    // $(".statusImport").hide();
                                    // $(".statusFailed").hide();
                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                    clearInterval(logInterval);
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                    //   location.reload();
                                    // });
                                  } else {
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                  }
                
                                  if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                    console.log("done data")
                                    $(".animate-text").html("Completed");
                                    // window.location.reload(false);
                                }
                
                                }
                              
                            // })

                        }
                    });
                  //   setTimeout(()=>{
                  //     // window.location.reload(false);
                  // }, 3000);
                
                          }, 3000);


                        
                      
                  
               

            }

})  
.catch((err)=>{
    
    console.log(err)
});
    
            }

            else if(value==27){
        
              AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                  if(res){
                    $('#statusbar').attr('style', 'display:block')
                      let d = res.data;
                      $(".animate-text").html("Progress");
                      console.log("data------"+res.import_id)
                      $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
                      
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                          
                                
                      var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
      
                            var  logInterval =  setInterval( function () {
      
                              // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                    
                              $.ajax({
                                  url: fileName, success: function (response) {
      
                                      if (response) {
                                          // $(".animate-text").html("Completed");
                                          $(".fileName").html(res.name);
                                          $(".currentDate").html(res.currentDate);
                      
                                        var result = response.split('=');
                      
                                        var total = result[0].split(':');
                                        
                                        
                                        var totalData = total[1];
                                        $(".total_record").html(totalData);
                      
                                        //import record count
                                        var importD = result[1].split(':');
                                        var importData = importD[1];
                                        var width = (importData * 100) / totalData;
                                        width = Math.round(width);
                                        setPercent(width)
                                        //error count
                                        var errorD = result[3].split(':');
                                        var errorData = errorD[1];
                                        var width1 = (errorData * 100) / totalData;
                                        width1 = Math.round(width1);
                                        setErrorPercent(width1);
                      
                                        //update count
                                        var updateD = result[4].split(':');
                                        var updateData = updateD[1];
                                        var width2 = (updateData * 100) / totalData;
                                        width2 = Math.round(width2);
                                        $(".count_imported").html(parseInt(importData) );
                                        $(".update_imported").html(updateData);
                                        $(".error_imported").html(errorData);
                                        var statusComplete = result[5].split(':');
                                        var statusCompleteGet = statusComplete[1];
                                        SetErrorData(errorData)
                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                        $(".mt-1").show();
                                        $(".mt-2").show();
                                        setStatusData("completed");
                      
                                        if (statusCompleteGet == 1) {
                      
                                          // $(".statusImport").hide();
                                          // $(".statusFailed").hide();
                                          // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                          // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                          clearInterval(logInterval);
                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                          // $(document).on('hidden.bs.modal', '#importModal', function () {
                                          //   location.reload();
                                          // });
                                        } else {
                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                        }
                      
                                        if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                          console.log("done data")
                                          $(".animate-text").html("Completed");
                                          // window.location.reload(false);
                                      }
                      
                                      }
                                    
                                  // })
      
                              }
                          });
                        //   setTimeout(()=>{
                        //     // window.location.reload(false);
                        // }, 3000);
                      
                                }, 3000);
      
      
                              
                            
                        
                     
      
                  }
      
      })  
      .catch((err)=>{
          
          console.log(err)
      });
      
              }
              else if(value==28){
                $('#dataimport').attr('style', 'display:block')
                AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                    if(res){
                      $('#statusbar').attr('style', 'display:block')
                        let d = res.data;
                        $(".animate-text").html("Progress");
                        console.log("data------"+res.import_id)
                        $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
                        
                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
                      
                  }
                            
                                  
                        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
        
                              var  logInterval =  setInterval( function () {
        
                                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                      
                                $.ajax({
                                    url: fileName, success: function (response) {
        
                                        if (response) {
                                            // $(".animate-text").html("Completed");
                                            $(".fileName").html(res.name);
                                            $(".currentDate").html(res.currentDate);
                        
                                          var result = response.split('=');
                        
                                          var total = result[0].split(':');
                                          
                                          
                                          var totalData = total[1];
                                          if(totalData=="null"){
                                            $(".total_record").html("0");
                                          }else{
                                            $(".total_record").html(totalData);
                                          }
                        
                                          //import record count
                                          var importD = result[1].split(':');
                                          var importData = importD[1];
                                          var width = (importData * 100) / totalData;
                                          width = Math.round(width);
                                          setPercent(width)
                                          //error count
                                          var errorD = result[3].split(':');
                                          var errorData = errorD[1];
                                          var width1 = (errorData * 100) / totalData;
                                          width1 = Math.round(width1);
                                          setErrorPercent(width1);
                        
                                          //update count
                                          var updateD = result[4].split(':');
                                          var updateData = updateD[1];
                                          var width2 = (updateData * 100) / totalData;
                                          width2 = Math.round(width2);

                                          var width3 = parseInt(updateData) + parseInt(importData)
                                          var width4 = (width3 * 100) / totalData;
                                          width4 = Math.round(width4);
                                          setPercent(width4)
                                          if(parseInt(importData) + parseInt(updateData)=="null"){
                                            $(".count_imported").html("0");
                                          }else{
                                            $(".count_imported").html(parseInt(importData) );
                                          }
                                          if(updateData=="null"){
                                            $(".update_imported").html("0");
                                          }else{
                                            $(".update_imported").html(updateData);
                                          }
                                          if(errorData=="null"){
                                            $(".error_imported").html("0");
                                          }else{
                                            $(".error_imported").html(errorData);
                                          }






                                          // $(".count_imported").html(parseInt(importData) );
                                          // $(".update_imported").html(updateData);
                                          // $(".error_imported").html(errorData);
                                          var statusComplete = result[5].split(':');
                                          var statusCompleteGet = statusComplete[1];
                                          SetErrorData(errorData)
                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                          $(".mt-1").show();
                                          $(".mt-2").show();
                                          setStatusData("completed");
                        
                                          if (statusCompleteGet == 1) {
                        
                                            // $(".statusImport").hide();
                                            // $(".statusFailed").hide();
                                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                            clearInterval(logInterval);
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                                            //   location.reload();
                                            // });
                                          } else {
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                          }
                        
                                          if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                            console.log("done data")
                                            $(".animate-text").html("Completed");
                                            // window.location.reload(false);
                                        }
                        
                                        }
                                      
                                    // })
        
                                }
                            });
                          //   setTimeout(()=>{
                          //     // window.location.reload(false);
                          // }, 3000);
                        
                                  }, 3000);
        
        
                                
                              
                          
                       
        
                    }
        
        })  
        .catch((err)=>{
            
            console.log(err)
        });
        
                }
                else if(value==30){
        
                  AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                      if(res){
                        $('#dataimport').attr('style', 'display:block')
                        $('#statusbar').attr('style', 'display:block')
                          let d = res.data;
                          $(".animate-text").html("Progress");
                          console.log("data------"+res.import_id)
                          $(".mt-1").hide();
                      $(".mt-2").hide();
                      setStatusData("progress");
                          
                      if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                        $('#importClose').trigger('click');
                        toast.error("Invalid file format");
                        
                    }
                              
                                    
                          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
          
                                var  logInterval =  setInterval( function () {
          
                                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                        
                                  $.ajax({
                                      url: fileName, success: function (response) {
          
                                          if (response) {
                                              // $(".animate-text").html("Completed");
                                              $(".fileName").html(res.name);
                                              $(".currentDate").html(res.currentDate);
                          
                                            var result = response.split('=');
                          
                                            var total = result[0].split(':');
                                            
                                            
                                            var totalData = total[1];
                                            $(".total_record").html(totalData);
                          
                                            //import record count
                                            var importD = result[1].split(':');
                                            var importData = importD[1];
                                            var width = ((parseInt(importData)  * 100) / parseInt(totalData)) ;
                                  
                                            width = Math.round(width);
                                            // setPercent(width)
                                            //error count
                                            var errorD = result[3].split(':');
                                            var errorData = errorD[1];
                                            var width1 = (errorData * 100) / totalData;
                                            width1 = Math.round(width1);
                                            setErrorPercent(width1);
                          
                                            //update count
                                            var updateD = result[4].split(':');
                                            var updateData = updateD[1];
                                            var width2 = (updateData * 100) / totalData;
                                            width2 = Math.round(width2);
                                            var width4 = parseInt(importData)  + parseInt(updateData) 
                                  var width5 = (width4 * 100) / totalData;
                                  width5 = Math.round(width5);
                                  setPercent(width5)

                                            $(".count_imported").html(parseInt(importData) );
                                            $(".update_imported").html(updateData);
                                            $(".error_imported").html(errorData);
                                            var statusComplete = result[5].split(':');
                                            var statusCompleteGet = statusComplete[1];
                                            SetErrorData(errorData)
                                        SetImportData(parseInt(importData) + parseInt(updateData))

                                            $(".mt-1").show();
                                            $(".mt-2").show();
                                            setStatusData("completed");
                          
                                            if (statusCompleteGet == 1) {
                          
                                              // $(".statusImport").hide();
                                              // $(".statusFailed").hide();
                                              // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                              // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                              clearInterval(logInterval);
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                                              //   location.reload();
                                              // });
                                            } else {
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                            }
                          
                                            if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                              console.log("done data")
                                              $(".animate-text").html("Completed");
                                              // window.location.reload(false);
                                          }
                          
                                          }
                                        
                                      // })
          
                                  }
                              });
                            //   setTimeout(()=>{
                            //     // window.location.reload(false);
                            // }, 3000);
                          
                                    }, 3000);
          
          
                                  
                                
                            
                         
          
                      }
          
          })  
          .catch((err)=>{
              
              console.log(err)
          });
          
                  }
                  else if(value==31){
                    $('#dataimport').attr('style', 'display:block')
                    AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                        if(res){
                          $('#statusbar').attr('style', 'display:block')
                            let d = res.data;
                            $(".animate-text").html("Progress");
                            console.log("data------"+res.import_id)
                            $(".mt-1").hide();
                        $(".mt-2").hide();
                        setStatusData("progress");
                            
                        if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                          $('#importClose').trigger('click');
                          toast.error("Invalid file format");
                          
                      }
                                
                                      
                            var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
            
                                  var  logInterval =  setInterval( function () {
            
                                    // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                          
                                    $.ajax({
                                        url: fileName, success: function (response) {
            
                                            if (response) {
                                                // $(".animate-text").html("Completed");
                                                $(".fileName").html(res.name);
                                                $(".currentDate").html(res.currentDate);
                            
                                              var result = response.split('=');
                            
                                              var total = result[0].split(':');
                                              
                                              
                                              var totalData = total[1];
                                              if(totalData=="null"){
                                                $(".total_record").html("0");
                                              }else{
                                                $(".total_record").html(totalData);
                                              }
                            
                                              //import record count
                                              var importD = result[1].split(':');
                                              var importData = importD[1];
                                              var width = (importData * 100) / totalData;
                                              width = Math.round(width);
                                              setPercent(width)
                                              //error count
                                              var errorD = result[3].split(':');
                                              var errorData = errorD[1];
                                              var width1 = (errorData * 100) / totalData;
                                              width1 = Math.round(width1);
                                              setErrorPercent(width1);
                            
                                              //update count
                                              var updateD = result[4].split(':');
                                              var updateData = updateD[1];
                                              var width2 = (updateData * 100) / totalData;
                                              width2 = Math.round(width2);

                                              var width3 = parseInt(updateData) + parseInt(importData)
                                              var width4 = (width3 * 100) / totalData;
                                              width4 = Math.round(width4);
                                              setPercent(width4)
                                              if(parseInt(importData) + parseInt(updateData)=="null"){
                                                $(".count_imported").html("0");
                                              }else{
                                                $(".count_imported").html(parseInt(importData) );
                                              }
                                              if(updateData=="null"){
                                                $(".update_imported").html("0");
                                              }else{
                                                $(".update_imported").html(updateData);
                                              }
                                              if(errorData=="null"){
                                                $(".error_imported").html("0");
                                              }else{
                                                $(".error_imported").html(errorData);
                                              }






                                              // $(".count_imported").html(parseInt(importData) );
                                              // $(".update_imported").html(updateData);
                                              // $(".error_imported").html(errorData);
                                              var statusComplete = result[5].split(':');
                                              var statusCompleteGet = statusComplete[1];
                                              SetErrorData(errorData)
                                          SetImportData(parseInt(importData) + parseInt(updateData))
                                              $(".mt-1").show();
                                              $(".mt-2").show();
                                              setStatusData("completed");
                            
                                              if (statusCompleteGet == 1) {
                            
                                                // $(".statusImport").hide();
                                                // $(".statusFailed").hide();
                                                // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                clearInterval(logInterval);
                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                //   location.reload();
                                                // });
                                              } else {
                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                              }
                            
                                              if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                console.log("done data")
                                                $(".animate-text").html("Completed");
                                                // window.location.reload(false);
                                            }
                            
                                            }
                                          
                                        // })
            
                                    }
                                });
                              //   setTimeout(()=>{
                              //     // window.location.reload(false);
                              // }, 3000);
                            
                                      }, 3000);
            
            
                                    
                                  
                              
                           
            
                        }
            
            })  
            .catch((err)=>{
                
                console.log(err)
            });
            
                    }
                    else if(value==32){
        
                      AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                          if(res){
                            $('#dataimport').attr('style', 'display:block')
                            $('#statusbar').attr('style', 'display:block')
                              let d = res.data;
                              $(".animate-text").html("Progress");
                              console.log("data------"+res.import_id)
                              $(".mt-1").hide();
                          $(".mt-2").hide();
                          setStatusData("progress");
                              
                          if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                            $('#importClose').trigger('click');
                            toast.error("Invalid file format");
                            
                        }
                                  
                                        
                              var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
              
                                    var  logInterval =  setInterval( function () {
              
                                      // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                            
                                      $.ajax({
                                          url: fileName, success: function (response) {
              
                                              if (response) {
                                                  // $(".animate-text").html("Completed");
                                                  $(".fileName").html(res.name);
                                                  $(".currentDate").html(res.currentDate);
                              
                                                var result = response.split('=');
                              
                                                var total = result[0].split(':');
                                                
                                                
                                                var totalData = total[1];
                                                $(".total_record").html(totalData);
                              
                                                //import record count
                                                var importD = result[1].split(':');
                                                var importData = importD[1];
                                                var width = (importData * 100) / totalData;
                                                width = Math.round(width);
                                                
                                                //error count
                                                var errorD = result[3].split(':');
                                                var errorData = errorD[1];
                                                var width1 = (errorData * 100) / totalData;
                                                width1 = Math.round(width1);
                                                setErrorPercent(width1);
                              
                                                //update count
                                                var updateD = result[4].split(':');
                                                var updateData = updateD[1];
                                                var width2 = (updateData * 100) / totalData;
                                                width2 = Math.round(width2);

                                                var width4 = parseInt(importData)  + parseInt(updateData) 
                                                var width5 = (width4 * 100) / totalData;
                                                width5 = Math.round(width5);
                                                setPercent(width5)

                                                $(".count_imported").html(parseInt(importData) );
                                                $(".update_imported").html(updateData);
                                                $(".error_imported").html(errorData);
                                                var statusComplete = result[5].split(':');
                                                var statusCompleteGet = statusComplete[1];
                                                SetErrorData(errorData)
                                            SetImportData(parseInt(importData) + parseInt(updateData))
                                                $(".mt-1").show();
                                                $(".mt-2").show();
                                                setStatusData("completed");
                              
                                                if (statusCompleteGet == 1) {
                              
                                                  // $(".statusImport").hide();
                                                  // $(".statusFailed").hide();
                                                  // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                  // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                  clearInterval(logInterval);
                                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                  // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                  //   location.reload();
                                                  // });
                                                } else {
                                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                }
                              
                                                if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                  console.log("done data")
                                                  $(".animate-text").html("Completed");
                                                  // window.location.reload(false);
                                              }
                              
                                              }
                                            
                                          // })
              
                                      }
                                  });
                                //   setTimeout(()=>{
                                //     // window.location.reload(false);
                                // }, 3000);
                              
                                        }, 3000);
              
              
                                      
                                    
                                
                             
              
                          }
              
              })  
              .catch((err)=>{
                  
                  console.log(err)
              });
              
                      }
                      else if(value==33){
        
                        AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                            if(res){
                              $('#statusbar').attr('style', 'display:block')
                                let d = res.data;
                                $(".animate-text").html("Progress");
                                console.log("data------"+res.import_id)
                                $(".mt-1").hide();
                            $(".mt-2").hide();
                            setStatusData("progress");
                                
                            if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                              $('#importClose').trigger('click');
                              toast.error("Invalid file format");
                              
                          }
                                    
                                          
                                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                
                                      var  logInterval =  setInterval( function () {
                
                                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                              
                                        $.ajax({
                                            url: fileName, success: function (response) {
                
                                                if (response) {
                                                    // $(".animate-text").html("Completed");
                                                    $(".fileName").html(res.name);
                                                    $(".currentDate").html(res.currentDate);
                                
                                                  var result = response.split('=');
                                
                                                  var total = result[0].split(':');
                                                  
                                                  
                                                  var totalData = total[1];
                                                  $(".total_record").html(totalData);
                                
                                                  //import record count
                                                  var importD = result[1].split(':');
                                                  var importData = importD[1];
                                                  var width = (importData * 100) / totalData;
                                                  width = Math.round(width);
                                                  setPercent(width)
                                                  //error count
                                                  var errorD = result[3].split(':');
                                                  var errorData = errorD[1];
                                                  var width1 = (errorData * 100) / totalData;
                                                  width1 = Math.round(width1);
                                                  setErrorPercent(width1);
                                
                                                  //update count
                                                  var updateD = result[4].split(':');
                                                  var updateData = updateD[1];
                                                  var width2 = (updateData * 100) / totalData;
                                                  width2 = Math.round(width2);
                                                  $(".count_imported").html(parseInt(importData) );
                                                  $(".update_imported").html(updateData);
                                                  $(".error_imported").html(errorData);
                                                  var statusComplete = result[5].split(':');
                                                  var statusCompleteGet = statusComplete[1];
                                                  SetErrorData(errorData)
                                              SetImportData(parseInt(importData) + parseInt(updateData))
                                                  $(".mt-1").show();
                                                  $(".mt-2").show();
                                                  setStatusData("completed");
                                
                                                  if (statusCompleteGet == 1) {
                                
                                                    // $(".statusImport").hide();
                                                    // $(".statusFailed").hide();
                                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                    clearInterval(logInterval);
                                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                    //   location.reload();
                                                    // });
                                                  } else {
                                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                  }
                                
                                                  if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                    console.log("done data")
                                                    $(".animate-text").html("Completed");
                                                    // window.location.reload(false);
                                                }
                                
                                                }
                                              
                                            // })
                
                                        }
                                    });
                                  //   setTimeout(()=>{
                                  //     // window.location.reload(false);
                                  // }, 3000);
                                
                                          }, 3000);
                
                
                                        
                                      
                                  
                               
                
                            }
                
                })  
                .catch((err)=>{
                    
                    console.log(err)
                });
                
                        }

            else if(value==1){
              $('#dataimport').attr('style', 'display:block')
                AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                    if(res){
                      $('#statusbar').attr('style', 'display:block')
                        let d = res.data;
                        $(".animate-text").html("Progress");
                        console.log("data------"+res.import_id)
                        $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
        
                if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                  $('#importClose').trigger('click');
                  toast.error("Invalid file format");
                  
              }
                    
                            
                                  
                        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
        
                              var  logInterval =  setInterval(async function () {
        
                                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                      
                            await    $.ajax({
                                    url: fileName, success: function (response) {
        
                                        if (response) {
                                            // $(".animate-text").html("Completed");
                                            $(".fileName").html(res.name);
                                            $(".currentDate").html(res.currentDate);
                        
                                          var result = response.split('=');
                        
                                          var total = result[0].split(':');
                                          
                                          
                                          var totalData = total[1];
                                          if(totalData=="null"){
                                            $(".total_record").html("0");
                                          }else{
                                            $(".total_record").html(totalData);
                                          }
                                          
                        
                                          //import record count
                                          var importD = result[1].split(':');
                                          var importData = importD[1];
                                          var width = (importData * 100) / totalData;
                                          width = Math.round(width);
                                          setPercent(width)
                                          //error count
                                          var errorD = result[2].split(':');
                                          var errorData = errorD[1];
                                          var width1 = (errorData * 100) / totalData;
                                          width1 = Math.round(width1);
                                          setErrorPercent(width1);
                        
                                          //update count
                                          var updateD = result[3].split(':');
                                          var updateData = updateD[1];
                                          var width2 = (updateData * 100) / totalData;
                                          width2 = Math.round(width2);
                                          var width3 = parseInt(updateData) + parseInt(importData)
                                          var width4 = (width3 * 100) / totalData;
                                          width4 = Math.round(width4);
                                          setPercent(width4)
                                          if(parseInt(importData) + parseInt(updateData)=="null"){
                                            $(".count_imported").html("0");
                                          }else{
                                            $(".count_imported").html(parseInt(importData) );
                                          }
                                          if(updateData=="null"){
                                            $(".update_imported").html("0");
                                          }else{
                                            $(".update_imported").html(updateData);
                                          }
                                          if(errorData=="null"){
                                            $(".error_imported").html("0");
                                          }else{
                                            $(".error_imported").html(errorData);
                                          }
                                        //   $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                        //   $(".update_imported").html(updateData);
                                        //   $(".error_imported").html(errorData);
                                          var statusComplete = result[4].split(':');
                                          var statusCompleteGet = statusComplete[1];
                                          SetErrorData(errorData)
                                          SetImportData(parseInt(importData) + parseInt(updateData))
                                              $(".mt-1").show();
                                              $(".mt-2").show();
                                              setStatusData("completed");
                                          if (statusCompleteGet == 1) {
                        
                                            // $(".statusImport").hide();
                                            // $(".statusFailed").hide();
                                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                            clearInterval(logInterval);
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                                            //   location.reload();
                                            // });
                                          } else {
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                          }
                                          if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                              console.log("done data")
                                              $(".animate-text").html("Completed");
                                              // window.location.reload(false);
                                          }

                        
                                        }
                                      
                                    // })
        
                                }
                            });
                            // setTimeout(()=>{
                              // window.location.reload(false);
                          // }, 3000);
                                  }, 3000);
        
        
                                
                              
                          
                       
        
                    }
        
        })  
        .catch((err)=>{
            
            console.log(err)
        });
        
                }

                else if(value==7){
                  // $('#dataimport').attr('style', 'display:block')
                  AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                      if(res){
                        $('#statusbar').attr('style', 'display:block')
                          let d = res.data;
                          $(".animate-text").html("Progress");
                          console.log("data------"+res.import_id)
                          $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
          
                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
                    
                }
                      
                              
                                    
                          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
          
                                var  logInterval =  setInterval(async function () {
          
                                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                        
                              await    $.ajax({
                                      url: fileName, success: function (response) {
          
                                          if (response) {
                                              // $(".animate-text").html("Completed");
                                              $(".fileName").html(res.name);
                                              $(".currentDate").html(res.currentDate);
                          
                                            var result = response.split('=');
                          
                                            var total = result[0].split(':');
                                            
                                            
                                            var totalData = total[1];
                                            if(totalData=="null"){
                                              $(".total_record").html("0");
                                            }else{
                                              $(".total_record").html(totalData);
                                            }
                                            
                          
                                            //import record count
                                            var importD = result[1].split(':');
                                            var importData = importD[1];
                                            var width = (importData * 100) / totalData;
                                            width = Math.round(width);
                                            setPercent(width)
                                            //error count
                                            var errorD = result[2].split(':');
                                            var errorData = errorD[1];
                                            var width1 = (errorData * 100) / totalData;
                                            width1 = Math.round(width1);
                                            setErrorPercent(width1);
                          
                                            //update count
                                            var updateD = result[3].split(':');
                                            var updateData = updateD[1];
                                            var width2 = (updateData * 100) / totalData;
                                            width2 = Math.round(width2);
                                            var width3 = parseInt(updateData) + parseInt(importData)
                                            var width4 = (width3 * 100) / totalData;
                                            width4 = Math.round(width4);
                                            setPercent(width4)
                                            if(parseInt(importData) + parseInt(updateData)=="null"){
                                              $(".count_imported").html("0");
                                            }else{
                                              $(".count_imported").html(parseInt(importData) );
                                            }
                                            if(updateData=="null"){
                                              $(".update_imported").html("0");
                                            }else{
                                              $(".update_imported").html(updateData);
                                            }
                                            if(errorData=="null"){
                                              $(".error_imported").html("0");
                                            }else{
                                              $(".error_imported").html(errorData);
                                            }
                                          //   $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                          //   $(".update_imported").html(updateData);
                                          //   $(".error_imported").html(errorData);
                                            var statusComplete = result[4].split(':');
                                            var statusCompleteGet = statusComplete[1];
                                            SetErrorData(errorData)
                                            SetImportData(parseInt(importData) + parseInt(updateData))
                                                $(".mt-1").show();
                                                $(".mt-2").show();
                                                setStatusData("completed");
                                            if (statusCompleteGet == 1) {
                          
                                              // $(".statusImport").hide();
                                              // $(".statusFailed").hide();
                                              // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                              // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                              clearInterval(logInterval);
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                                              //   location.reload();
                                              // });
                                            } else {
                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                            }
                                            if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                console.log("done data")
                                                $(".animate-text").html("Completed");
                                                // window.location.reload(false);
                                            }
  
                          
                                          }
                                        
                                      // })
          
                                  }
                              });
                              // setTimeout(()=>{
                                // window.location.reload(false);
                            // }, 3000);
                                    }, 3000);
          
          
                                  
                                
                            
                         
          
                      }
          
          })  
          .catch((err)=>{
              
              console.log(err)
          });
                    }
                    else if(value==50){
                      $('#dataimport').attr('style', 'display:block')
                      AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                          if(res){
                            $('#statusbar').attr('style', 'display:block')
                              let d = res.data;
                              $(".animate-text").html("Progress");
                              console.log("data------"+res.import_id)
                              $(".mt-1").hide();
                          $(".mt-2").hide();
                          setStatusData("progress");
                              
                          if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                            $('#importClose').trigger('click');
                            toast.error("Invalid file format");
                            
                        }
                                  
                                        
                              var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
              
                                    var  logInterval =  setInterval( function () {
              
                                      // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                            
                                      $.ajax({
                                          url: fileName, success: function (response) {
              
                                              if (response) {
                                                  // $(".animate-text").html("Completed");
                                                  $(".fileName").html(res.name);
                                                  $(".currentDate").html(res.currentDate);
                              
                                                var result = response.split('=');
                              
                                                var total = result[0].split(':');
                                                
                                                
                                                var totalData = total[1];
                                                if(totalData=="null"){
                                                  $(".total_record").html("0");
                                                }else{
                                                  $(".total_record").html(totalData);
                                                }
                              
                                                //import record count
                                                var importD = result[1].split(':');
                                                var importData = importD[1];
                                                var width = (importData * 100) / totalData;
                                                width = Math.round(width);
                                                setPercent(width)
                                                //error count
                                                var errorD = result[3].split(':');
                                                var errorData = errorD[1];
                                                var width1 = (errorData * 100) / totalData;
                                                width1 = Math.round(width1);
                                                setErrorPercent(width1);
                              
                                                //update count
                                                var updateD = result[4].split(':');
                                                var updateData = updateD[1];
                                                var width2 = (updateData * 100) / totalData;
                                                width2 = Math.round(width2);
  
                                                var width3 = parseInt(updateData) + parseInt(importData)
                                                var width4 = (width3 * 100) / totalData;
                                                width4 = Math.round(width4);
                                                setPercent(width4)
                                                if(parseInt(importData) + parseInt(updateData)=="null"){
                                                  $(".count_imported").html("0");
                                                }else{
                                                  $(".count_imported").html(parseInt(importData) );
                                                }
                                                if(updateData=="null"){
                                                  $(".update_imported").html("0");
                                                }else{
                                                  $(".update_imported").html(updateData);
                                                }
                                                if(errorData=="null"){
                                                  $(".error_imported").html("0");
                                                }else{
                                                  $(".error_imported").html(errorData);
                                                }
  
  
  
  
  
  
                                                // $(".count_imported").html(parseInt(importData) );
                                                // $(".update_imported").html(updateData);
                                                // $(".error_imported").html(errorData);
                                                var statusComplete = result[5].split(':');
                                                var statusCompleteGet = statusComplete[1];
                                                SetErrorData(errorData)
                                            SetImportData(parseInt(importData) + parseInt(updateData))
                                                $(".mt-1").show();
                                                $(".mt-2").show();
                                                setStatusData("completed");
                              
                                                if (statusCompleteGet == 1) {
                              
                                                  // $(".statusImport").hide();
                                                  // $(".statusFailed").hide();
                                                  // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                  // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                  clearInterval(logInterval);
                                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                  // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                  //   location.reload();
                                                  // });
                                                } else {
                                                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                }
                              
                                                if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                  console.log("done data")
                                                  $(".animate-text").html("Completed");
                                                  // window.location.reload(false);
                                              }
                              
                                              }
                                            
                                          // })
              
                                      }
                                  });
                                //   setTimeout(()=>{
                                //     // window.location.reload(false);
                                // }, 3000);
                              
                                        }, 3000);
              
              
                                      
                                    
                                
                             
              
                          }
              
              })  
              .catch((err)=>{
                  
                  console.log(err)
              });
                     
              
                      }
                      else if(value==51){
                        $('#dataimport').attr('style', 'display:block')
                        AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                            if(res){
                              $('#statusbar').attr('style', 'display:block')
                                let d = res.data;
                                $(".animate-text").html("Progress");
                                console.log("data------"+res.import_id)
                                $(".mt-1").hide();
                            $(".mt-2").hide();
                            setStatusData("progress");
                                
                            if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                              $('#importClose').trigger('click');
                              toast.error("Invalid file format");
                              
                          }
                                    
                                          
                                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                
                                      var  logInterval =  setInterval( function () {
                
                                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                              
                                        $.ajax({
                                            url: fileName, success: function (response) {
                
                                                if (response) {
                                                    // $(".animate-text").html("Completed");
                                                    $(".fileName").html(res.name);
                                                    $(".currentDate").html(res.currentDate);
                                
                                                  var result = response.split('=');
                                
                                                  var total = result[0].split(':');
                                                  
                                                  
                                                  var totalData = total[1];
                                                  if(totalData=="null"){
                                                    $(".total_record").html("0");
                                                  }else{
                                                    $(".total_record").html(totalData);
                                                  }
                                
                                                  //import record count
                                                  var importD = result[1].split(':');
                                                  var importData = importD[1];
                                                  var width = (importData * 100) / totalData;
                                                  width = Math.round(width);
                                                  setPercent(width)
                                                  //error count
                                                  var errorD = result[3].split(':');
                                                  var errorData = errorD[1];
                                                  var width1 = (errorData * 100) / totalData;
                                                  width1 = Math.round(width1);
                                                  setErrorPercent(width1);
                                
                                                  //update count
                                                  var updateD = result[4].split(':');
                                                  var updateData = updateD[1];
                                                  var width2 = (updateData * 100) / totalData;
                                                  width2 = Math.round(width2);
    
                                                  var width3 = parseInt(updateData) + parseInt(importData)
                                                  var width4 = (width3 * 100) / totalData;
                                                  width4 = Math.round(width4);
                                                  setPercent(width4)
                                                  if(parseInt(importData) + parseInt(updateData)=="null"){
                                                    $(".count_imported").html("0");
                                                  }else{
                                                    $(".count_imported").html(parseInt(importData) );
                                                  }
                                                  if(updateData=="null"){
                                                    $(".update_imported").html("0");
                                                  }else{
                                                    $(".update_imported").html(updateData);
                                                  }
                                                  if(errorData=="null"){
                                                    $(".error_imported").html("0");
                                                  }else{
                                                    $(".error_imported").html(errorData);
                                                  }
    
    
    
    
    
    
                                                  // $(".count_imported").html(parseInt(importData) );
                                                  // $(".update_imported").html(updateData);
                                                  // $(".error_imported").html(errorData);
                                                  var statusComplete = result[5].split(':');
                                                  var statusCompleteGet = statusComplete[1];
                                                  SetErrorData(errorData)
                                              SetImportData(parseInt(importData) + parseInt(updateData))
                                                  $(".mt-1").show();
                                                  $(".mt-2").show();
                                                  setStatusData("completed");
                                
                                                  if (statusCompleteGet == 1) {
                                
                                                    // $(".statusImport").hide();
                                                    // $(".statusFailed").hide();
                                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                    clearInterval(logInterval);
                                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                    //   location.reload();
                                                    // });
                                                  } else {
                                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                  }
                                
                                                  if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                    console.log("done data")
                                                    $(".animate-text").html("Completed");
                                                    // window.location.reload(false);
                                                }
                                
                                                }
                                              
                                            // })
                
                                        }
                                    });
                                  //   setTimeout(()=>{
                                  //     // window.location.reload(false);
                                  // }, 3000);
                                
                                          }, 3000);
                
                
                                        
                                      
                                  
                               
                
                            }
                
                })  
                .catch((err)=>{
                    
                    console.log(err)
                });
                
                        }

                        else if(value==52){
                          $('#dataimport').attr('style', 'display:block')
                          AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                              if(res){
                                $('#statusbar').attr('style', 'display:block')
                                  let d = res.data;
                                  $(".animate-text").html("Progress");
                                  console.log("data------"+res.import_id)
                                  $(".mt-1").hide();
                              $(".mt-2").hide();
                              setStatusData("progress");
                                  
                              if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                $('#importClose').trigger('click');
                                toast.error("Invalid file format");
                                
                            }
                                      
                                            
                                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                  
                                        var  logInterval =  setInterval( function () {
                  
                                          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                
                                          $.ajax({
                                              url: fileName, success: function (response) {
                  
                                                  if (response) {
                                                      // $(".animate-text").html("Completed");
                                                      $(".fileName").html(res.name);
                                                      $(".currentDate").html(res.currentDate);
                                  
                                                    var result = response.split('=');
                                  
                                                    var total = result[0].split(':');
                                                    
                                                    
                                                    var totalData = total[1];
                                                    if(totalData=="null"){
                                                      $(".total_record").html("0");
                                                    }else{
                                                      $(".total_record").html(totalData);
                                                    }
                                  
                                                    //import record count
                                                    var importD = result[1].split(':');
                                                    var importData = importD[1];
                                                    var width = (importData * 100) / totalData;
                                                    width = Math.round(width);
                                                    setPercent(width)
                                                    //error count
                                                    var errorD = result[3].split(':');
                                                    var errorData = errorD[1];
                                                    var width1 = (errorData * 100) / totalData;
                                                    width1 = Math.round(width1);
                                                    setErrorPercent(width1);
                                  
                                                    //update count
                                                    var updateD = result[4].split(':');
                                                    var updateData = updateD[1];
                                                    var width2 = (updateData * 100) / totalData;
                                                    width2 = Math.round(width2);
      
                                                    var width3 = parseInt(updateData) + parseInt(importData)
                                                    var width4 = (width3 * 100) / totalData;
                                                    width4 = Math.round(width4);
                                                    setPercent(width4)
                                                    if(parseInt(importData) + parseInt(updateData)=="null"){
                                                      $(".count_imported").html("0");
                                                    }else{
                                                      $(".count_imported").html(parseInt(importData) );
                                                    }
                                                    if(updateData=="null"){
                                                      $(".update_imported").html("0");
                                                    }else{
                                                      $(".update_imported").html(updateData);
                                                    }
                                                    if(errorData=="null"){
                                                      $(".error_imported").html("0");
                                                    }else{
                                                      $(".error_imported").html(errorData);
                                                    }
      
      
      
      
      
      
                                                    // $(".count_imported").html(parseInt(importData) );
                                                    // $(".update_imported").html(updateData);
                                                    // $(".error_imported").html(errorData);
                                                    var statusComplete = result[5].split(':');
                                                    var statusCompleteGet = statusComplete[1];
                                                    SetErrorData(errorData)
                                                SetImportData(parseInt(importData) + parseInt(updateData))
                                                    $(".mt-1").show();
                                                    $(".mt-2").show();
                                                    setStatusData("completed");
                                  
                                                    if (statusCompleteGet == 1) {
                                  
                                                      // $(".statusImport").hide();
                                                      // $(".statusFailed").hide();
                                                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                      clearInterval(logInterval);
                                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                      //   location.reload();
                                                      // });
                                                    } else {
                                                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                    }
                                  
                                                    if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                      console.log("done data")
                                                      $(".animate-text").html("Completed");
                                                      // window.location.reload(false);
                                                  }
                                  
                                                  }
                                                
                                              // })
                  
                                          }
                                      });
                                    //   setTimeout(()=>{
                                    //     // window.location.reload(false);
                                    // }, 3000);
                                  
                                            }, 3000);
                  
                  
                                          
                                        
                                    
                                 
                  
                              }
                  
                  })  
                  .catch((err)=>{
                      
                      console.log(err)
                  });
                          }
                          else if(value==53){
                            $('#dataimport').attr('style', 'display:block')
                            AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                if(res){
                                  $('#statusbar').attr('style', 'display:block')
                                    let d = res.data;
                                    $(".animate-text").html("Progress");
                                    console.log("data------"+res.import_id)
                                    $(".mt-1").hide();
                                $(".mt-2").hide();
                                setStatusData("progress");
                                    
                                if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                  $('#importClose').trigger('click');
                                  toast.error("Invalid file format");
                                  
                              }
                                        
                                              
                                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                    
                                          var  logInterval =  setInterval( function () {
                    
                                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                  
                                            $.ajax({
                                                url: fileName, success: function (response) {
                    
                                                    if (response) {
                                                        // $(".animate-text").html("Completed");
                                                        $(".fileName").html(res.name);
                                                        $(".currentDate").html(res.currentDate);
                                    
                                                      var result = response.split('=');
                                    
                                                      var total = result[0].split(':');
                                                      
                                                      
                                                      var totalData = total[1];
                                                      if(totalData=="null"){
                                                        $(".total_record").html("0");
                                                      }else{
                                                        $(".total_record").html(totalData);
                                                      }
                                    
                                                      //import record count
                                                      var importD = result[1].split(':');
                                                      var importData = importD[1];
                                                      var width = (importData * 100) / totalData;
                                                      width = Math.round(width);
                                                      setPercent(width)
                                                      //error count
                                                      var errorD = result[3].split(':');
                                                      var errorData = errorD[1];
                                                      var width1 = (errorData * 100) / totalData;
                                                      width1 = Math.round(width1);
                                                      setErrorPercent(width1);
                                    
                                                      //update count
                                                      var updateD = result[4].split(':');
                                                      var updateData = updateD[1];
                                                      var width2 = (updateData * 100) / totalData;
                                                      width2 = Math.round(width2);
        
                                                      var width3 = parseInt(updateData) + parseInt(importData)
                                                      var width4 = (width3 * 100) / totalData;
                                                      width4 = Math.round(width4);
                                                      setPercent(width4)
                                                      if(parseInt(importData) + parseInt(updateData)=="null"){
                                                        $(".count_imported").html("0");
                                                      }else{
                                                        $(".count_imported").html(parseInt(importData) );
                                                      }
                                                      if(updateData=="null"){
                                                        $(".update_imported").html("0");
                                                      }else{
                                                        $(".update_imported").html(updateData);
                                                      }
                                                      if(errorData=="null"){
                                                        $(".error_imported").html("0");
                                                      }else{
                                                        $(".error_imported").html(errorData);
                                                      }
        
        
        
        
        
        
                                                      // $(".count_imported").html(parseInt(importData) );
                                                      // $(".update_imported").html(updateData);
                                                      // $(".error_imported").html(errorData);
                                                      var statusComplete = result[5].split(':');
                                                      var statusCompleteGet = statusComplete[1];
                                                      SetErrorData(errorData)
                                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                                      $(".mt-1").show();
                                                      $(".mt-2").show();
                                                      setStatusData("completed");
                                    
                                                      if (statusCompleteGet == 1) {
                                    
                                                        // $(".statusImport").hide();
                                                        // $(".statusFailed").hide();
                                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                        clearInterval(logInterval);
                                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                        //   location.reload();
                                                        // });
                                                      } else {
                                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                      }
                                    
                                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                        console.log("done data")
                                                        $(".animate-text").html("Completed");
                                                        // window.location.reload(false);
                                                    }
                                    
                                                    }
                                                  
                                                // })
                    
                                            }
                                        });
                                      //   setTimeout(()=>{
                                      //     // window.location.reload(false);
                                      // }, 3000);
                                    
                                              }, 3000);
                    
                    
                                            
                                          
                                      
                                   
                    
                                }
                    
                    })  
                    .catch((err)=>{
                        
                        console.log(err)
                    });
                            }
                          else if(value==54){
                            $('#dataimport').attr('style', 'display:block')
                            AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                if(res){
                                  $('#statusbar').attr('style', 'display:block')
                                    let d = res.data;
                                    $(".animate-text").html("Progress");
                                    console.log("data------"+res.import_id)
                                    $(".mt-1").hide();
                                $(".mt-2").hide();
                                setStatusData("progress");
                                    
                                if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                  $('#importClose').trigger('click');
                                  toast.error("Invalid file format");
                                  
                              }
                                        
                                              
                                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                    
                                          var  logInterval =  setInterval( function () {
                    
                                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                  
                                            $.ajax({
                                                url: fileName, success: function (response) {
                    
                                                    if (response) {
                                                        // $(".animate-text").html("Completed");
                                                        $(".fileName").html(res.name);
                                                        $(".currentDate").html(res.currentDate);
                                    
                                                      var result = response.split('=');
                                    
                                                      var total = result[0].split(':');
                                                      
                                                      
                                                      var totalData = total[1];
                                                      if(totalData=="null"){
                                                        $(".total_record").html("0");
                                                      }else{
                                                        $(".total_record").html(totalData);
                                                      }
                                    
                                                      //import record count
                                                      var importD = result[1].split(':');
                                                      var importData = importD[1];
                                                      var width = (importData * 100) / totalData;
                                                      width = Math.round(width);
                                                      setPercent(width)
                                                      //error count
                                                      var errorD = result[3].split(':');
                                                      var errorData = errorD[1];
                                                      var width1 = (errorData * 100) / totalData;
                                                      width1 = Math.round(width1);
                                                      setErrorPercent(width1);
                                    
                                                      //update count
                                                      var updateD = result[4].split(':');
                                                      var updateData = updateD[1];
                                                      var width2 = (updateData * 100) / totalData;
                                                      width2 = Math.round(width2);
        
                                                      var width3 = parseInt(updateData) + parseInt(importData)
                                                      var width4 = (width3 * 100) / totalData;
                                                      width4 = Math.round(width4);
                                                      setPercent(width4)
                                                      if(parseInt(importData) + parseInt(updateData)=="null"){
                                                        $(".count_imported").html("0");
                                                      }else{
                                                        $(".count_imported").html(parseInt(importData) );
                                                      }
                                                      if(updateData=="null"){
                                                        $(".update_imported").html("0");
                                                      }else{
                                                        $(".update_imported").html(updateData);
                                                      }
                                                      if(errorData=="null"){
                                                        $(".error_imported").html("0");
                                                      }else{
                                                        $(".error_imported").html(errorData);
                                                      }
        
        
        
        
        
        
                                                      // $(".count_imported").html(parseInt(importData) );
                                                      // $(".update_imported").html(updateData);
                                                      // $(".error_imported").html(errorData);
                                                      var statusComplete = result[5].split(':');
                                                      var statusCompleteGet = statusComplete[1];
                                                      SetErrorData(errorData)
                                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                                      $(".mt-1").show();
                                                      $(".mt-2").show();
                                                      setStatusData("completed");
                                    
                                                      if (statusCompleteGet == 1) {
                                    
                                                        // $(".statusImport").hide();
                                                        // $(".statusFailed").hide();
                                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                        clearInterval(logInterval);
                                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                        //   location.reload();
                                                        // });
                                                      } else {
                                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                      }
                                    
                                                      if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                        console.log("done data")
                                                        $(".animate-text").html("Completed");
                                                        // window.location.reload(false);
                                                    }
                                    
                                                    }
                                                  
                                                // })
                    
                                            }
                                        });
                                      //   setTimeout(()=>{
                                      //     // window.location.reload(false);
                                      // }, 3000);
                                    
                                              }, 3000);
                    
                    
                                            
                                          
                                      
                                   
                    
                                }
                    
                    })  
                    .catch((err)=>{
                        
                        console.log(err)
                    });
                            }
                            else if(value==55){
                              $('#dataimport').attr('style', 'display:block')
                              AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                  if(res){
                                    $('#statusbar').attr('style', 'display:block')
                                      let d = res.data;
                                      $(".animate-text").html("Progress");
                                      console.log("data------"+res.import_id)
                                      $(".mt-1").hide();
                                  $(".mt-2").hide();
                                  setStatusData("progress");
                                      
                                  if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                    $('#importClose').trigger('click');
                                    toast.error("Invalid file format");
                                    
                                }
                                          
                                                
                                      var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                      
                                            var  logInterval =  setInterval( function () {
                      
                                              // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                    
                                              $.ajax({
                                                  url: fileName, success: function (response) {
                      
                                                      if (response) {
                                                          // $(".animate-text").html("Completed");
                                                          $(".fileName").html(res.name);
                                                          $(".currentDate").html(res.currentDate);
                                      
                                                        var result = response.split('=');
                                      
                                                        var total = result[0].split(':');
                                                        
                                                        
                                                        var totalData = total[1];
                                                        if(totalData=="null"){
                                                          $(".total_record").html("0");
                                                        }else{
                                                          $(".total_record").html(totalData);
                                                        }
                                      
                                                        //import record count
                                                        var importD = result[1].split(':');
                                                        var importData = importD[1];
                                                        var width = (importData * 100) / totalData;
                                                        width = Math.round(width);
                                                        setPercent(width)
                                                        //error count
                                                        var errorD = result[3].split(':');
                                                        var errorData = errorD[1];
                                                        var width1 = (errorData * 100) / totalData;
                                                        width1 = Math.round(width1);
                                                        setErrorPercent(width1);
                                      
                                                        //update count
                                                        var updateD = result[4].split(':');
                                                        var updateData = updateD[1];
                                                        var width2 = (updateData * 100) / totalData;
                                                        width2 = Math.round(width2);
          
                                                        var width3 = parseInt(updateData) + parseInt(importData)
                                                        var width4 = (width3 * 100) / totalData;
                                                        width4 = Math.round(width4);
                                                        setPercent(width4)
                                                        if(parseInt(importData) + parseInt(updateData)=="null"){
                                                          $(".count_imported").html("0");
                                                        }else{
                                                          $(".count_imported").html(parseInt(importData) );
                                                        }
                                                        if(updateData=="null"){
                                                          $(".update_imported").html("0");
                                                        }else{
                                                          $(".update_imported").html(updateData);
                                                        }
                                                        if(errorData=="null"){
                                                          $(".error_imported").html("0");
                                                        }else{
                                                          $(".error_imported").html(errorData);
                                                        }
          
          
          
          
          
          
                                                        // $(".count_imported").html(parseInt(importData) );
                                                        // $(".update_imported").html(updateData);
                                                        // $(".error_imported").html(errorData);
                                                        var statusComplete = result[5].split(':');
                                                        var statusCompleteGet = statusComplete[1];
                                                        SetErrorData(errorData)
                                                    SetImportData(parseInt(importData) + parseInt(updateData))
                                                        $(".mt-1").show();
                                                        $(".mt-2").show();
                                                        setStatusData("completed");
                                      
                                                        if (statusCompleteGet == 1) {
                                      
                                                          // $(".statusImport").hide();
                                                          // $(".statusFailed").hide();
                                                          // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                          // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                          clearInterval(logInterval);
                                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                          // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                          //   location.reload();
                                                          // });
                                                        } else {
                                                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                        }
                                      
                                                        if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                          console.log("done data")
                                                          $(".animate-text").html("Completed");
                                                          // window.location.reload(false);
                                                      }
                                      
                                                      }
                                                    
                                                  // })
                      
                                              }
                                          });
                                        //   setTimeout(()=>{
                                        //     // window.location.reload(false);
                                        // }, 3000);
                                      
                                                }, 3000);
                      
                      
                                              
                                            
                                        
                                     
                      
                                  }
                      
                      })  
                      .catch((err)=>{
                          
                          console.log(err)
                      });
                              }
                              else if(value==56){
                                $('#dataimport').attr('style', 'display:block')
                                AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                    if(res){
                                      $('#statusbar').attr('style', 'display:block')
                                        let d = res.data;
                                        $(".animate-text").html("Progress");
                                        console.log("data------"+res.import_id)
                                        $(".mt-1").hide();
                                    $(".mt-2").hide();
                                    setStatusData("progress");
                                        
                                    if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                      $('#importClose').trigger('click');
                                      toast.error("Invalid file format");
                                      
                                  }
                                            
                                                  
                                        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                        
                                              var  logInterval =  setInterval( function () {
                        
                                                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                      
                                                $.ajax({
                                                    url: fileName, success: function (response) {
                        
                                                        if (response) {
                                                            // $(".animate-text").html("Completed");
                                                            $(".fileName").html(res.name);
                                                            $(".currentDate").html(res.currentDate);
                                        
                                                          var result = response.split('=');
                                        
                                                          var total = result[0].split(':');
                                                          
                                                          
                                                          var totalData = total[1];
                                                          if(totalData=="null"){
                                                            $(".total_record").html("0");
                                                          }else{
                                                            $(".total_record").html(totalData);
                                                          }
                                        
                                                          //import record count
                                                          var importD = result[1].split(':');
                                                          var importData = importD[1];
                                                          var width = (importData * 100) / totalData;
                                                          width = Math.round(width);
                                                          setPercent(width)
                                                          //error count
                                                          var errorD = result[3].split(':');
                                                          var errorData = errorD[1];
                                                          var width1 = (errorData * 100) / totalData;
                                                          width1 = Math.round(width1);
                                                          setErrorPercent(width1);
                                        
                                                          //update count
                                                          var updateD = result[4].split(':');
                                                          var updateData = updateD[1];
                                                          var width2 = (updateData * 100) / totalData;
                                                          width2 = Math.round(width2);
            
                                                          var width3 = parseInt(updateData) + parseInt(importData)
                                                          var width4 = (width3 * 100) / totalData;
                                                          width4 = Math.round(width4);
                                                          setPercent(width4)
                                                          if(parseInt(importData) + parseInt(updateData)=="null"){
                                                            $(".count_imported").html("0");
                                                          }else{
                                                            $(".count_imported").html(parseInt(importData) );
                                                          }
                                                          if(updateData=="null"){
                                                            $(".update_imported").html("0");
                                                          }else{
                                                            $(".update_imported").html(updateData);
                                                          }
                                                          if(errorData=="null"){
                                                            $(".error_imported").html("0");
                                                          }else{
                                                            $(".error_imported").html(errorData);
                                                          }
            
            
            
            
            
            
                                                          // $(".count_imported").html(parseInt(importData) );
                                                          // $(".update_imported").html(updateData);
                                                          // $(".error_imported").html(errorData);
                                                          var statusComplete = result[5].split(':');
                                                          var statusCompleteGet = statusComplete[1];
                                                          SetErrorData(errorData)
                                                      SetImportData(parseInt(importData) + parseInt(updateData))
                                                          $(".mt-1").show();
                                                          $(".mt-2").show();
                                                          setStatusData("completed");
                                        
                                                          if (statusCompleteGet == 1) {
                                        
                                                            // $(".statusImport").hide();
                                                            // $(".statusFailed").hide();
                                                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                            clearInterval(logInterval);
                                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                            //   location.reload();
                                                            // });
                                                          } else {
                                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                          }
                                        
                                                          if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                            console.log("done data")
                                                            $(".animate-text").html("Completed");
                                                            // window.location.reload(false);
                                                        }
                                        
                                                        }
                                                      
                                                    // })
                        
                                                }
                                            });
                                          //   setTimeout(()=>{
                                          //     // window.location.reload(false);
                                          // }, 3000);
                                        
                                                  }, 3000);
                        
                        
                                                
                                              
                                          
                                       
                        
                                    }
                        
                        })  
                        .catch((err)=>{
                            
                            console.log(err)
                        });
                        
                                }
                                else if(value==57){
                                  $('#dataimport').attr('style', 'display:block')
                                  AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                      if(res){
                                        $('#statusbar').attr('style', 'display:block')
                                          let d = res.data;
                                          $(".animate-text").html("Progress");
                                          console.log("data------"+res.import_id)
                                          $(".mt-1").hide();
                                      $(".mt-2").hide();
                                      setStatusData("progress");
                                          
                                      if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                        $('#importClose').trigger('click');
                                        toast.error("Invalid file format");
                                        
                                    }
                                              
                                                    
                                          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                          
                                                var  logInterval =  setInterval( function () {
                          
                                                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                        
                                                  $.ajax({
                                                      url: fileName, success: function (response) {
                          
                                                          if (response) {
                                                              // $(".animate-text").html("Completed");
                                                              $(".fileName").html(res.name);
                                                              $(".currentDate").html(res.currentDate);
                                          
                                                            var result = response.split('=');
                                          
                                                            var total = result[0].split(':');
                                                            
                                                            
                                                            var totalData = total[1];
                                                            if(totalData=="null"){
                                                              $(".total_record").html("0");
                                                            }else{
                                                              $(".total_record").html(totalData);
                                                            }
                                          
                                                            //import record count
                                                            var importD = result[1].split(':');
                                                            var importData = importD[1];
                                                            var width = (importData * 100) / totalData;
                                                            width = Math.round(width);
                                                            setPercent(width)
                                                            //error count
                                                            var errorD = result[3].split(':');
                                                            var errorData = errorD[1];
                                                            var width1 = (errorData * 100) / totalData;
                                                            width1 = Math.round(width1);
                                                            setErrorPercent(width1);
                                          
                                                            //update count
                                                            var updateD = result[4].split(':');
                                                            var updateData = updateD[1];
                                                            var width2 = (updateData * 100) / totalData;
                                                            width2 = Math.round(width2);
              
                                                            var width3 = parseInt(updateData) + parseInt(importData)
                                                            var width4 = (width3 * 100) / totalData;
                                                            width4 = Math.round(width4);
                                                            setPercent(width4)
                                                            if(parseInt(importData) + parseInt(updateData)=="null"){
                                                              $(".count_imported").html("0");
                                                            }else{
                                                              $(".count_imported").html(parseInt(importData) );
                                                            }
                                                            if(updateData=="null"){
                                                              $(".update_imported").html("0");
                                                            }else{
                                                              $(".update_imported").html(updateData);
                                                            }
                                                            if(errorData=="null"){
                                                              $(".error_imported").html("0");
                                                            }else{
                                                              $(".error_imported").html(errorData);
                                                            }
              
              
              
              
              
              
                                                            // $(".count_imported").html(parseInt(importData) );
                                                            // $(".update_imported").html(updateData);
                                                            // $(".error_imported").html(errorData);
                                                            var statusComplete = result[5].split(':');
                                                            var statusCompleteGet = statusComplete[1];
                                                            SetErrorData(errorData)
                                                        SetImportData(parseInt(importData) + parseInt(updateData))
                                                            $(".mt-1").show();
                                                            $(".mt-2").show();
                                                            setStatusData("completed");
                                          
                                                            if (statusCompleteGet == 1) {
                                          
                                                              // $(".statusImport").hide();
                                                              // $(".statusFailed").hide();
                                                              // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                              // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                              clearInterval(logInterval);
                                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                              //   location.reload();
                                                              // });
                                                            } else {
                                                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                            }
                                          
                                                            if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                              console.log("done data")
                                                              $(".animate-text").html("Completed");
                                                              // window.location.reload(false);
                                                          }
                                          
                                                          }
                                                        
                                                      // })
                          
                                                  }
                                              });
                                            //   setTimeout(()=>{
                                            //     // window.location.reload(false);
                                            // }, 3000);
                                          
                                                    }, 3000);
                          
                          
                                                  
                                                
                                            
                                         
                          
                                      }
                          
                          })  
                          .catch((err)=>{
                              
                              console.log(err)
                          });
                          
                                  }
                                  else if(value==58){
                                    $('#dataimport').attr('style', 'display:block')
                                    AdminService.httpPostFile('/data-sdmx/file-upload',formdata).then((res)=>{
                                        if(res){
                                          $('#statusbar').attr('style', 'display:block')
                                            let d = res.data;
                                            $(".animate-text").html("Progress");
                                            console.log("data------"+res.import_id)
                                            $(".mt-1").hide();
                                        $(".mt-2").hide();
                                        setStatusData("progress");
                                            
                                        if(  res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                                          $('#importClose').trigger('click');
                                          toast.error("Invalid file format");
                                          
                                      }
                                                
                                                      
                                            var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
                            
                                                  var  logInterval =  setInterval( function () {
                            
                                                    // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                                          
                                                    $.ajax({
                                                        url: fileName, success: function (response) {
                            
                                                            if (response) {
                                                                // $(".animate-text").html("Completed");
                                                                $(".fileName").html(res.name);
                                                                $(".currentDate").html(res.currentDate);
                                            
                                                              var result = response.split('=');
                                            
                                                              var total = result[0].split(':');
                                                              
                                                              
                                                              var totalData = total[1];
                                                              if(totalData=="null"){
                                                                $(".total_record").html("0");
                                                              }else{
                                                                $(".total_record").html(totalData);
                                                              }
                                            
                                                              //import record count
                                                              var importD = result[1].split(':');
                                                              var importData = importD[1];
                                                              var width = (importData * 100) / totalData;
                                                              width = Math.round(width);
                                                              setPercent(width)
                                                              //error count
                                                              var errorD = result[3].split(':');
                                                              var errorData = errorD[1];
                                                              var width1 = (errorData * 100) / totalData;
                                                              width1 = Math.round(width1);
                                                              setErrorPercent(width1);
                                            
                                                              //update count
                                                              var updateD = result[4].split(':');
                                                              var updateData = updateD[1];
                                                              var width2 = (updateData * 100) / totalData;
                                                              width2 = Math.round(width2);
                
                                                              var width3 = parseInt(updateData) + parseInt(importData)
                                                              var width4 = (width3 * 100) / totalData;
                                                              width4 = Math.round(width4);
                                                              setPercent(width4)
                                                              if(parseInt(importData) + parseInt(updateData)=="null"){
                                                                $(".count_imported").html("0");
                                                              }else{
                                                                $(".count_imported").html(parseInt(importData) );
                                                              }
                                                              if(updateData=="null"){
                                                                $(".update_imported").html("0");
                                                              }else{
                                                                $(".update_imported").html(updateData);
                                                              }
                                                              if(errorData=="null"){
                                                                $(".error_imported").html("0");
                                                              }else{
                                                                $(".error_imported").html(errorData);
                                                              }
                
                
                
                
                
                
                                                              // $(".count_imported").html(parseInt(importData) );
                                                              // $(".update_imported").html(updateData);
                                                              // $(".error_imported").html(errorData);
                                                              var statusComplete = result[5].split(':');
                                                              var statusCompleteGet = statusComplete[1];
                                                              SetErrorData(errorData)
                                                          SetImportData(parseInt(importData) + parseInt(updateData))
                                                              $(".mt-1").show();
                                                              $(".mt-2").show();
                                                              setStatusData("completed");
                                            
                                                              if (statusCompleteGet == 1) {
                                            
                                                                // $(".statusImport").hide();
                                                                // $(".statusFailed").hide();
                                                                // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                                // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                                clearInterval(logInterval);
                                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                                // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                                //   location.reload();
                                                                // });
                                                              } else {
                                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                                              }
                                            
                                                              if( (parseInt(importData) + parseInt(updateData) + parseInt(errorData))== parseInt(totalData)){
                                                                console.log("done data")
                                                                $(".animate-text").html("Completed");
                                                                // window.location.reload(false);
                                                            }
                                            
                                                            }
                                                          
                                                        // })
                            
                                                    }
                                                });
                                              //   setTimeout(()=>{
                                              //     // window.location.reload(false);
                                              // }, 3000);
                                            
                                                      }, 3000);
                            
                            
                                                    
                                                  
                                              
                                           
                            
                                        }
                            
                            })  
                            .catch((err)=>{
                                
                                console.log(err)
                            });
                            
                                    }
     };


     

     return (
         <div style={{ textAlign: "center" }}>
            
             <form>

                            <div>
                                <div className="button_box_3 d-flex justify-content-center flex-wrap mb-40">
                                    <input type="file" name="file-input" id="file-input" accept=".csv" onChange={handleOnChange} class="file-input__input"/>
                                    <label class="file-input__label popup_btn" for="file-input"><span> Browse File </span></label>
                                    <button  class="btn btn_upload w-25 disable_bg mx-0" id="download_icius_master_template_with_data"    onClick={(e) => {
                            handleOnSubmit(e);}}>Upload</button>
                                </div>
                                {/* <ImportData value={value} /> */}                                
                                
                                <div className='last-update-text text-left mb-3'>
                                    <h4>Last Upload</h4>
                                    <p>File Name : <span class="fileName"></span></p>
                                    <p>Status : <span  className='animate-text'> {complete=="complete"? "Completed" : ""}</span></p>
                                    <p>Total : <span class="total_record"></span></p>
                                    <p>Total Imported : <span class="count_imported"></span></p>
                                    <p id="dataimport" style={{ display: "none" }}>Update Record : <span class="update_imported"></span></p>
                                    <p>Error Record : <span class="error_imported"></span></p>
                                    <p>Date & Time : <span class="currentDate"></span></p>
                                    <div class="processDiv" id="statusbar" style={{ display: "none" }}>
                                      <p>Progress 
                                        <ProgressBar animated striped variant="success" now={percent}  label={`${percent}%`} visuallyHidden className="mt-1" />
                                      </p>
                                      <p>Error 
                                        <ProgressBar animated striped variant="danger" now={errorpercent}  label={`${errorpercent}%`} visuallyHidden className="mt-2" />
                                      </p>
                                      {/* <p className="mt-3">
                                        <ProgressBar variant="success" label={statusData} now={100} />
                                      </p> */}
                                    </div>
                                </div>
                            </div>


             </form>
         </div>
     );
 }
export default ImportData;
