import React, { useRef, useState } from "react";
import './import-sdmx.css';
import DataTable from 'react-data-table-component';
import ImportData from "../../../../Common/components/import/importData";
import adminService from "../../../../services/admin.service";
import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import * as constant from '../../../../Config/app.constants'
import ProgressBar from "react-bootstrap/esm/ProgressBar";


const ImportSDMXComponent = (props) => {
  const [importValue, setImportValue] = useState(0)
  const [file, setFile] = useState([]);
  const inputFile = useRef(null);
  const formdata = new FormData();
  const [statusData, setStatusData] = useState();
  const [percent, setPercent] = useState(0);
  const [errorpercent, setErrorPercent] = useState();
  const [importData, SetImportData] = useState();
  const [errorData, SetErrorData] = useState();
  const [importFor,setImportFor] = useState()
  
  const [dataFile,setDataFile] = useState([])
  const [metadataFile,setMetadataFile] = useState([])
  const [dsdFile,setDsdFile] = useState([])
  const [msdFile,setMsdFile] = useState([])
  const [indicatorFile,setIndicatorFile] = useState([])
  const [unitFile,setUnitFile] = useState([])
  const [subgroupFile,setSubgroupFile] = useState([])
  const [subgroupDimensionFile,setSubgroupDimensionFile] = useState([])
  const [areaFile,setAreaFile] = useState([])
  const [sourceFile,setSourceFile] = useState([])
  const [timePeriodFile,setTimeperiodFile] = useState([])

  const [dataRowChecked,setDataRowChecked] = useState(false)
  const [metadataRowChecked,setMetadataRowChecked] = useState(false)
  const [dsdRowChecked,setDsdRowChecked] = useState(false)
  const [msdRowChecked,setMsdRowChecked] = useState(false)
  const [indicatorRowChecked,setIndicatorRowChecked] = useState(false)
  const [unitRowChecked,setUnitRowChecked] = useState(false)
  const [subgroupRowChecked,setSubgroupRowChecked] = useState(false)
  const [subgroupDimensionRowChecked,setSubgroupDimensionRowChecked] = useState(false)
  const [areaRowChecked,setAreaRowChecked] = useState(false)
  const [sourceRowChecked,setSourceRowChecked] = useState(false)
  const [timePeriodRowChecked,setTimeperiodRowChecked] = useState(false)

  const [percentageForData,setPercentageForData] = useState('')
  const [percentageForMetadata,setPercentageForMetadata] = useState('')
  const [percentageForDsd,setPercentageForDsd] = useState('')
  const [percentageForMsd,setPercentageForMsd] = useState('')
  const [percentageForIndicator,setPercentageForIndicator] = useState('')
  const [percentageForUnit,setPercentageForUnit] = useState('')
  const [percentageForSubgroup,setPercentageForSubgroup] = useState('')
  const [percentageForSubgroupDimension,setPercentageForSubgroupDimension] = useState('')
  const [percentageForArea,setPercentageForArea] = useState('')
  const [percentageForSource,setPercentageForSource] = useState('')
  const [percentageForTimePeriod,setPercentageForTimePeriod] = useState('')

  const [percentOfData,setPercentOfData] = useState(0)
  const [percentOfMetadata,setPercentOfMetadata] = useState(0)
  const [percentOfDsd,setPercentOfDsd] = useState(0)
  const [percentOfMsd,setPercentOfMsd] = useState(0)
  const [percentOfIndicator,setPercentOfIndicator] = useState(0)
  const [percentOfUnit,setPercentOfUnit] = useState(0)
  const [percentOfSubgroup,setPercentOfSubgroup] = useState(0)
  const [percentOfSubgroupDimension,setPercentOfSubgroupDimension] = useState(0)
  const [percentOfArea,setPercentOfArea] = useState(0)
  const [percentOfSource,setPercentOfSource] = useState(0)
  const [percentOfTimePeriod,setPercentOfTimePeriod] = useState(0)


  const dataFileRef = useRef(null)
  const metadataFileRef = useRef(null)
  const dsdFileRef = useRef(null)
  const msdFileRef = useRef(null)
  const indicatorFileRef = useRef(null);
  const unitFileRef = useRef(null);
  const subgroupFileRef = useRef(null);
  const subgroupDimensionFileRef = useRef(null);
  const areaFileRef = useRef(null);
  const sourceFileRef = useRef(null);
  const timePeriodFileRef = useRef(null);

  const indicatorDataToSend = new FormData();
  const metaDataToSend = new FormData();
  const dsdDataToSend = new FormData();
  const msdDataToSend = new FormData();
  const unitDataToSend = new FormData();
  const subgroupDataToSend = new FormData();
  const subgroupDimensionDataToSend = new FormData();
  const areaDataToSend = new FormData();
  const sourceDataToSend = new FormData();
  const timePeriodDataToSend = new FormData();






  const handleChange = (e,element) => {
    // setFile([...file, e.target.files[0]]);

    switch (element) {
      case 'Data':
       setDataFile([e.target.files[0]])
      break;
      
      case 'Metadata':
        setMetadataFile([e.target.files[0]])
       break;

      case 'Data Structures Definition (DSD)':
        setDsdFile([e.target.files[0]])
       break;

       case 'Metadata Structure Definition (MSD)':
        setMsdFile([e.target.files[0]])
        break;

      case 'Indicator':
        setIndicatorFile([e.target.files[0]])
        break;

      case 'Unit':
        setUnitFile([e.target.files[0]])
        break;

      case 'Subgroup':
        setSubgroupFile([ e.target.files[0]])
        break;

      case 'Subgroup Dimension':
        setSubgroupDimensionFile([e.target.files[0]])
      break;

      case 'Area':
       setAreaFile([e.target.files[0]])
        break;

      case 'Source':
        setSourceFile([e.target.files[0]])
    break;

        case 'Time Period':
        setTimeperiodFile([e.target.files[0]])
        break;

      default:
        break;
    }
  }

  

  const forRef = (element)=>{
    switch (element) {
      case 'Data':
        return dataFileRef
      
      case 'Metadata':
        return metadataFileRef
      
      case 'Data Structures Definition (DSD)':
        return dsdFileRef

        case 'Metadata Structure Definition (MSD)':
          return msdFileRef

      case 'Indicator':
        return indicatorFileRef

      case 'Unit':
        return unitFileRef

      case 'Subgroup':
        return subgroupFileRef

      case 'Subgroup Dimension':
        return subgroupDimensionFileRef

      case 'Area':
        return areaFileRef

      case 'Source':
        return sourceFileRef
    
        case 'Time Period':
        return timePeriodFileRef
      default:
        break;
    }
  }

  const handleRowSelect = (element)=>{
console.log('handlerowselect',element)

switch (element) {
  
  case 'Data':
    setDataRowChecked(!dataRowChecked)
    break;

  case 'Metadata':
    setMetadataRowChecked(!metadataRowChecked)
    break;

    case 'Data Structures Definition (DSD)':
      setDsdRowChecked(!dsdRowChecked)
    break;

    case 'Metadata Structure Definition (MSD)':
      setMsdRowChecked(!msdRowChecked)
      break;

    case 'Indicator':
      setIndicatorRowChecked(!indicatorRowChecked)
    break;

    case 'Unit':
      setUnitRowChecked(true)
    break;

    case 'Subgroup':
      setSubgroupRowChecked(!subgroupRowChecked)
    break;

    case 'Subgroup Dimension':
      setSubgroupDimensionRowChecked(!subgroupDimensionRowChecked)
    break;

    case 'Area':
      setAreaRowChecked(!areaRowChecked)
    break;

    case 'Source':
      setSourceRowChecked(!sourceRowChecked)
    break;

    case 'Time Period':
      setTimeperiodRowChecked(!timePeriodRowChecked)
    break;

  default:
    break;
}
  }

  const columns = [
    {
      name: 'Select',
      selector: row => <><input type="checkbox" checked={row.elements === 'Data' ? dataRowChecked : row.elements === 'Metadata' ? metadataRowChecked : row.elements === 'Data Structures Definition (DSD)' ? dsdRowChecked : row.elements === 'Metadata Structure Definition (MSD)' ? msdRowChecked : row.elements === 'Indicator' ? indicatorRowChecked : row.elements === 'Unit' ? unitRowChecked : row.elements === 'Subgroup' ? subgroupRowChecked : row.elements === 'Subgroup Dimension' ? subgroupDimensionRowChecked : row.elements === 'Area' ? areaRowChecked : row.elements === 'Source' ? sourceRowChecked : row.elements === 'Time Period' && timePeriodRowChecked} onChange={()=>handleRowSelect(row.elements)}/></>
    },
    {
      name: 'Elements',
      selector: row => row.elements,
      sortable: true,
    },
    {
      name: 'Action',
      width: "200px",
      cell: row =>
        <>

          <div>
            <input
              type="file"
              onChange={(e)=>handleChange(e,row.elements)}
              ref={forRef(row.elements)}
              style={{ display: 'none' }}
            />

            

              {row.elements === 'Data' && dataFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>dataFileRef.current.click()}/> : ''}

              {row.elements === 'Metadata' && metadataFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>metadataFileRef.current.click()}/> : ''}
              {row.elements === 'Data Structures Definition (DSD)' && dsdFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>dsdFileRef.current.click()}/> : ''}
              {row.elements === 'Metadata Structure Definition (MSD)' && msdFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>msdFileRef.current.click()}/> : ''}

              {row.elements === 'Indicator' && indicatorFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>indicatorFileRef.current.click()}/> : ''}
              {row.elements === 'Unit' && unitFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>unitFileRef.current.click()}/> : ''}
              {row.elements === 'Subgroup' && subgroupFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>subgroupFileRef.current.click()}/> : ''}
              {row.elements === 'Subgroup Dimension' && subgroupDimensionFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>subgroupDimensionFileRef.current.click()}/> : ''}
              {row.elements === 'Area' && areaFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>areaFileRef.current.click()}/> : ''}
              {row.elements === 'Source' && sourceFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>sourceFileRef.current.click()}/> : ''}
              {row.elements === 'Time Period' && timePeriodFile.length === 0 ?<img alt="" src="assets/lib/images/new-home/uplaod.svg" className="mr-3 cursor" onClick={()=>timePeriodFileRef.current.click()}/> : ''}

            {row.elements === 'Data' && dataFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Metadata' && metadataFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Data Structures Definition (DSD)' && dsdFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Metadata Structure Definition (MSD)' && msdFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}

            {row.elements === 'Indicator' && indicatorFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Unit' && unitFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Subgroup' && subgroupFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Subgroup Dimension' && subgroupDimensionFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Area' && areaFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Source' && sourceFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}
            {row.elements === 'Time Period' && timePeriodFile.length === 1 ? <img alt="" src="assets/lib/images/new-home/uploaded.svg" className="mr-3 cursor"/> : ''}


            <img alt="" src="assets/lib/images/new-home/cross-import.png" className="cursor" onClick={() => { 
              // setImportFor(handleImportFor(row.elements));
              if(row.elements === 'Data'){
                setDataFile([])
                setPercentOfData(0)
                setDataRowChecked(false)
                dataFileRef.current.value = "";
                dataFileRef.current.type = "text";
                dataFileRef.current.type = "file";
              }else if(row.elements === 'Metadata'){
                setMetadataFile([])
                setPercentOfMetadata(0)
                setMetadataRowChecked(false)
                metadataFileRef.current.value = "";
                metadataFileRef.current.type = "text";
                metadataFileRef.current.type = "file";
              }else if(row.elements === 'Data Structures Definition (DSD)'){
                setDsdFile([])
                setPercentOfDsd(0)
                setDsdRowChecked(false)
                dsdFileRef.current.value = "";
                dsdFileRef.current.type = "text";
                dsdFileRef.current.type = "file";
              }else if(row.elements === 'Metadata Structure Definition (MSD)'){
                console.log('msd')
                setMsdFile([])
                setPercentOfMsd(0)
                setMsdRowChecked(false)
                msdFileRef.current.value = "";
                msdFileRef.current.type = "text";
                msdFileRef.current.type = "file";
              }
              else if(row.elements === 'Indicator'){
                setIndicatorFile([])
                setPercentOfIndicator(0)
                setIndicatorRowChecked(false)
                indicatorFileRef.current.value = "";
                indicatorFileRef.current.type = "text";
                indicatorFileRef.current.type = "file";
              }
              else if(row.elements === 'Unit'){
                setUnitFile([])
                setPercentOfUnit(0)
                setUnitRowChecked(false)
                unitFileRef.current.value = "";
                unitFileRef.current.type = "text";
                unitFileRef.current.type = "file";
              }else if(row.elements === 'Subgroup'){
                setSubgroupFile([])
                setPercentOfSubgroup(0)
                setSubgroupRowChecked(false)
                subgroupFileRef.current.value = "";
                subgroupFileRef.current.type = "text";
                subgroupFileRef.current.type = "file";
              }else if(row.elements === 'Subgroup Dimension'){
                setSubgroupDimensionFile([])
                setPercentOfSubgroupDimension(0)
                setSubgroupDimensionRowChecked(false)
                subgroupDimensionFileRef.current.value = "";
                subgroupDimensionFileRef.current.type = "text";
                subgroupDimensionFileRef.current.type = "file";
              }else if(row.elements === 'Area'){
                setAreaFile([])
                setPercentOfArea(0)
                setAreaRowChecked(false)
                areaFileRef.current.value = "";
                areaFileRef.current.type = "text";
                areaFileRef.current.type = "file";
              }else if(row.elements === 'Source'){
                setSourceFile([])
                setPercentOfSource(0)
                setSourceRowChecked(false)
                sourceFileRef.current.value = "";
                sourceFileRef.current.type = "text";
                sourceFileRef.current.type = "file";
              }else if(row.elements === 'Time Period'){
                setTimeperiodFile([])
                setPercentOfTimePeriod(0)
                setTimeperiodRowChecked(false)
                timePeriodFileRef.current.value = "";
                timePeriodFileRef.current.type = "text";
                timePeriodFileRef.current.type = "file";
              }
            
              // inputFile.current.click()
              
              }}/>
          </div>


        </>,
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    },
    {
      name: 'Progress',
      // selector: row => <>{row.progress}<div class="parent_progress"><div class="child_parent_progress">{percent}</div></div></>,
      selector: row => <>{row.progress}<ProgressBar animated striped variant="success" now={percentageForData===row.elements ? percentOfData : percentageForMetadata===row.elements ? percentOfMetadata : percentageForDsd===row.elements ? percentOfDsd : percentageForMsd===row.elements ? percentOfMsd :percentageForIndicator===row.elements ? percentOfIndicator : percentageForUnit===row.elements ? percentOfUnit :percentageForSubgroup===row.elements ? percentOfSubgroup :percentageForSubgroupDimension===row.elements ? percentOfSubgroupDimension :percentageForArea===row.elements ? percentOfArea :percentageForSource===row.elements ? percentOfSource :percentageForTimePeriod===row.elements ? percentOfTimePeriod : 0}  label={`${percent}%`} visuallyHidden className="mt-1" /></>,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    }
  ];


  const ValuesTable = [
    {
      elements: 'Data',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Metadata',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Data Structures Definition (DSD)',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Metadata Structure Definition (MSD)',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Indicator',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Unit',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Subgroup',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Subgroup Dimension',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Area',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Source',
      progress: '',
      status: 'No'
    },
    {
      elements: 'Time Period',
      progress: '',
      status: 'No'
    },
    
  ];


  console.log('indicatorImport',indicatorFile,indicatorFileRef)

  const handleImport = () => {
    

    if(!dataRowChecked && !metadataRowChecked && !dsdRowChecked && !msdRowChecked && !indicatorRowChecked && !unitRowChecked && !subgroupRowChecked && !subgroupDimensionRowChecked && !areaRowChecked && !sourceRowChecked && !timePeriodRowChecked){
      toast.error("Please Select File");
      return
    }
    
    if(dataRowChecked){
      if(dataFile[0] === undefined){
        toast.error("Please Select Data File");
      }else{
      setPercentOfData(0)
      indicatorDataToSend.append('files', dataFile[0]);
      indicatorDataToSend.append('import_for', 58);
      const savedItem = JSON.parse(localStorage.getItem("userInfo"));
      console.log("saved--" + savedItem)
      let id = savedItem.id;
      console.log("id--" + id)
      indicatorDataToSend.append('user_id', id);
      console.log("myFile",file[0])
      $('#dataimport').attr('style', 'display:block')
      adminService.httpPostFile('/data-sdmx/file-upload', indicatorDataToSend).then((res) => {
        if (res) {
  
          let d = res.data;
          $(".animate-text").html("Progress");
          console.log("data------" + res.import_id)
          // $(".mt-1").hide();
          // $(".mt-2").hide();
          setStatusData("progress");
  
          if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
            $('#importClose').trigger('click');
            toast.error("Invalid file format");
  
          }
  
  
          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
  
          var logInterval = setInterval(function () {
  
            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
  
            $.ajax({
              url: fileName, success: function (response) {
  
                if (response) {
                  // $(".animate-text").html("Completed");
                  $(".fileName").html(res.name);
                  $(".currentDate").html(res.currentDate);
  
                  var result = response.split('=');
  
                  var total = result[0].split(':');
  
  
                  var totalData = total[1];
                  if (totalData == "null") {
                    $(".total_record").html("0");
                  } else {
                    $(".total_record").html(totalData);
                  }
  
                  //import record count
                  var importD = result[1].split(':');
                  var importData = importD[1];
                  var width = (importData * 100) / totalData;
                  width = Math.round(width);
                  setPercent(width)
                  //error count
                  var errorD = result[3].split(':');
                  var errorData = errorD[1];
                  var width1 = (errorData * 100) / totalData;
                  width1 = Math.round(width1);
                  setErrorPercent(width1);
                  setPercentageForData('Indicator')
                  //update count
                  var updateD = result[4].split(':');
                  var updateData = updateD[1];
                  var width2 = (updateData * 100) / totalData;
                  width2 = Math.round(width2);
                  
                  var width3 = parseInt(updateData) + parseInt(importData)
                  var width4 = (width3 * 100) / totalData;
                  width4 = Math.round(width4);
                  setPercent(width4)
                  setPercentOfData(width4)
                  if (parseInt(importData) + parseInt(updateData) == "null") {
                    $(".count_imported").html("0");
                  } else {
                    $(".count_imported").html(parseInt(importData));
                  }
                  if (updateData == "null") {
                    $(".update_imported").html("0");
                  } else {
                    $(".update_imported").html(updateData);
                  }
                  if (errorData == "null") {
                    $(".error_imported").html("0");
                  } else {
                    $(".error_imported").html(errorData);
                  }
  
  
  
  
  
  
                  // $(".count_imported").html(parseInt(importData) );
                  // $(".update_imported").html(updateData);
                  // $(".error_imported").html(errorData);
                  var statusComplete = result[5].split(':');
                  var statusCompleteGet = statusComplete[1];
                  SetErrorData(errorData)
                  SetImportData(parseInt(importData) + parseInt(updateData))
                  $(".mt-1").show();
                  $(".mt-2").show();
                  setStatusData("completed");
  
                  if (statusCompleteGet == 1) {
  
                    // $(".statusImport").hide();
                    // $(".statusFailed").hide();
                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                    clearInterval(logInterval);
                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                    //   location.reload();
                    // });
                  } else {
                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                  }
  
                  if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                    console.log("done data")
                    $(".animate-text").html("Completed");
                    // window.location.reload(false);
                  }
  
                }
  
  
  
              }
            });
            
          }, 3000);
          dataFileRef.current.value = "";
          dataFileRef.current.type = "text";
          dataFileRef.current.type = "file";
          setDataFile([])
        }
  
      })
        .catch((err) => {
          dataFileRef.current.value = "";
            dataFileRef.current.type = "text";
            dataFileRef.current.type = "file";
            setDataFile([])
          console.log(err)
        });
      }
      }

      if(metadataRowChecked){
        if(metadataFile[0] === undefined){
          toast.error("Please Select Metadata File");
        }else{
        setPercentOfData(0)
        metaDataToSend.append('files', metadataFile[0]);
        metaDataToSend.append('import_for', 25);
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        console.log("saved--" + savedItem)
        let id = savedItem.id;
        console.log("id--" + id)
        metaDataToSend.append('user_id', id);
        console.log("myFile",file[0])
        $('#dataimport').attr('style', 'display:block')
        adminService.httpPostFile('/data-sdmx/file-upload', metaDataToSend).then((res) => {
          if (res) {
    
            let d = res.data;
            $(".animate-text").html("Progress");
            console.log("data------" + res.import_id)
            // $(".mt-1").hide();
            // $(".mt-2").hide();
            setStatusData("progress");
    
            if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
              $('#importClose').trigger('click');
              toast.error("Invalid file format");
    
            }
    
    
            var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
    
            var logInterval = setInterval(function () {
    
              // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
    
              $.ajax({
                url: fileName, success: function (response) {
    
                  if (response) {
                    // $(".animate-text").html("Completed");
                    $(".fileName").html(res.name);
                    $(".currentDate").html(res.currentDate);
    
                    var result = response.split('=');
    
                    var total = result[0].split(':');
    
    
                    var totalData = total[1];
                    if (totalData == "null") {
                      $(".total_record").html("0");
                    } else {
                      $(".total_record").html(totalData);
                    }
    
                    //import record count
                    var importD = result[1].split(':');
                    var importData = importD[1];
                    var width = (importData * 100) / totalData;
                    width = Math.round(width);
                    setPercent(width)
                    //error count
                    var errorD = result[3].split(':');
                    var errorData = errorD[1];
                    var width1 = (errorData * 100) / totalData;
                    width1 = Math.round(width1);
                    setErrorPercent(width1);
                    setPercentageForMetadata('Metadata')
                    //update count
                    var updateD = result[4].split(':');
                    var updateData = updateD[1];
                    var width2 = (updateData * 100) / totalData;
                    width2 = Math.round(width2);
    
                    var width3 = parseInt(updateData) + parseInt(importData)
                    var width4 = (width3 * 100) / totalData;
                    width4 = Math.round(width4);
                    setPercentOfMetadata(width4)
                    setPercent(width4)
                    if (parseInt(importData) + parseInt(updateData) == "null") {
                      $(".count_imported").html("0");
                    } else {
                      $(".count_imported").html(parseInt(importData));
                    }
                    if (updateData == "null") {
                      $(".update_imported").html("0");
                    } else {
                      $(".update_imported").html(updateData);
                    }
                    if (errorData == "null") {
                      $(".error_imported").html("0");
                    } else {
                      $(".error_imported").html(errorData);
                    }
    
    
    
    
    
    
                    // $(".count_imported").html(parseInt(importData) );
                    // $(".update_imported").html(updateData);
                    // $(".error_imported").html(errorData);
                    var statusComplete = result[5].split(':');
                    var statusCompleteGet = statusComplete[1];
                    SetErrorData(errorData)
                    SetImportData(parseInt(importData) + parseInt(updateData))
                    $(".mt-1").show();
                    $(".mt-2").show();
                    setStatusData("completed");
    
                    if (statusCompleteGet == 1) {
    
                      // $(".statusImport").hide();
                      // $(".statusFailed").hide();
                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                      clearInterval(logInterval);
                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                      //   location.reload();
                      // });
                    } else {
                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                    }
    
                    if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                      console.log("done data")
                      $(".animate-text").html("Completed");
                      // window.location.reload(false);
                    }
    
                  }
    
    
    
                }
              });
            }, 3000);
            metadataFileRef.current.value = "";
            metadataFileRef.current.type = "text";
            metadataFileRef.current.type = "file";
            setMetadataFile([])
          }
    
        })
          .catch((err) => {
            metadataFileRef.current.value = "";
              metadataFileRef.current.type = "text";
              metadataFileRef.current.type = "file";
              setMetadataFile([])
            console.log(err)
          });
        }
        }

        if(dsdRowChecked){
          if(dsdFile[0] === undefined){
            toast.error("Please Select DSD File");
          }else{
          setPercentOfData(0)
          dsdDataToSend.append('files', dsdFile[0]);
          dsdDataToSend.append('import_for', 16);
          const savedItem = JSON.parse(localStorage.getItem("userInfo"));
          console.log("saved--" + savedItem)
          let id = savedItem.id;
          console.log("id--" + id)
          dsdDataToSend.append('user_id', id);
          console.log("myFile",file[0])
          $('#dataimport').attr('style', 'display:block')
          adminService.httpPostFile('/data-sdmx/file-upload', dsdDataToSend).then((res) => {
            if (res) {
      
              let d = res.data;
              $(".animate-text").html("Progress");
              console.log("data------" + res.import_id)
              // $(".mt-1").hide();
              // $(".mt-2").hide();
              setStatusData("progress");
      
              if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                $('#importClose').trigger('click');
                toast.error("Invalid file format");
      
              }
      
      
              var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
      
              var logInterval = setInterval(function () {
      
                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
      
                $.ajax({
                  url: fileName, success: function (response) {
      
                    if (response) {
                      // $(".animate-text").html("Completed");
                      $(".fileName").html(res.name);
                      $(".currentDate").html(res.currentDate);
      
                      var result = response.split('=');
      
                      var total = result[0].split(':');
      
      
                      var totalData = total[1];
                      if (totalData == "null") {
                        $(".total_record").html("0");
                      } else {
                        $(".total_record").html(totalData);
                      }
      
                      //import record count
                      var importD = result[1].split(':');
                      var importData = importD[1];
                      var width = (importData * 100) / totalData;
                      width = Math.round(width);
                      setPercent(width)
                      //error count
                      var errorD = result[3].split(':');
                      var errorData = errorD[1];
                      var width1 = (errorData * 100) / totalData;
                      width1 = Math.round(width1);
                      setErrorPercent(width1);
                      setPercentageForDsd('Metadata')
                      //update count
                      var updateD = result[4].split(':');
                      var updateData = updateD[1];
                      var width2 = (updateData * 100) / totalData;
                      width2 = Math.round(width2);
      
                      var width3 = parseInt(updateData) + parseInt(importData)
                      var width4 = (width3 * 100) / totalData;
                      width4 = Math.round(width4);
                      setPercentOfDsd(width4)
                      setPercent(width4)
                      if (parseInt(importData) + parseInt(updateData) == "null") {
                        $(".count_imported").html("0");
                      } else {
                        $(".count_imported").html(parseInt(importData));
                      }
                      if (updateData == "null") {
                        $(".update_imported").html("0");
                      } else {
                        $(".update_imported").html(updateData);
                      }
                      if (errorData == "null") {
                        $(".error_imported").html("0");
                      } else {
                        $(".error_imported").html(errorData);
                      }
      
      
      
      
      
      
                      // $(".count_imported").html(parseInt(importData) );
                      // $(".update_imported").html(updateData);
                      // $(".error_imported").html(errorData);
                      var statusComplete = result[5].split(':');
                      var statusCompleteGet = statusComplete[1];
                      SetErrorData(errorData)
                      SetImportData(parseInt(importData) + parseInt(updateData))
                      $(".mt-1").show();
                      $(".mt-2").show();
                      setStatusData("completed");
      
                      if (statusCompleteGet == 1) {
      
                        // $(".statusImport").hide();
                        // $(".statusFailed").hide();
                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                        clearInterval(logInterval);
                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                        //   location.reload();
                        // });
                      } else {
                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                      }
      
                      if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                        console.log("done data")
                        $(".animate-text").html("Completed");
                        // window.location.reload(false);
                      }
      
                    }
      
      
      
                  }
                });
  
              }, 3000);
              dsdFileRef.current.value = "";
            dsdFileRef.current.type = "text";
            dsdFileRef.current.type = "file";
            setDsdFile([])
            }
      
          })
            .catch((err) => {
              dsdFileRef.current.value = "";
              dsdFileRef.current.type = "text";
              dsdFileRef.current.type = "file";
              setDsdFile([])
              console.log(err)
            });
          }
          }

          if(msdRowChecked){
            if(msdFile[0] === undefined){
              toast.error("Please Select MSD File");
            }else{
            setPercentOfData(0)
            msdDataToSend.append('files', msdFile[0]);
            msdDataToSend.append('import_for', 26);
            const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            console.log("saved--" + savedItem)
            let id = savedItem.id;
            console.log("id--" + id)
            msdDataToSend.append('user_id', id);
            console.log("myFile",file[0])
            $('#dataimport').attr('style', 'display:block')
            adminService.httpPostFile('/data-sdmx/file-upload', msdDataToSend).then((res) => {
              if (res) {
        
                let d = res.data;
                $(".animate-text").html("Progress");
                console.log("data------" + res.import_id)
                // $(".mt-1").hide();
                // $(".mt-2").hide();
                setStatusData("progress");
        
                if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                  $('#importClose').trigger('click');
                  toast.error("Invalid file format");
        
                }
        
        
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
        
                var logInterval = setInterval(function () {
        
                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
        
                  $.ajax({
                    url: fileName, success: function (response) {
        
                      if (response) {
                        // $(".animate-text").html("Completed");
                        $(".fileName").html(res.name);
                        $(".currentDate").html(res.currentDate);
        
                        var result = response.split('=');
        
                        var total = result[0].split(':');
        
        
                        var totalData = total[1];
                        if (totalData == "null") {
                          $(".total_record").html("0");
                        } else {
                          $(".total_record").html(totalData);
                        }
        
                        //import record count
                        var importD = result[1].split(':');
                        var importData = importD[1];
                        var width = (importData * 100) / totalData;
                        width = Math.round(width);
                        setPercent(width)
                        //error count
                        var errorD = result[3].split(':');
                        var errorData = errorD[1];
                        var width1 = (errorData * 100) / totalData;
                        width1 = Math.round(width1);
                        setErrorPercent(width1);
                        setPercentageForMsd('Metadata')
                        //update count
                        var updateD = result[4].split(':');
                        var updateData = updateD[1];
                        var width2 = (updateData * 100) / totalData;
                        width2 = Math.round(width2);
        
                        var width3 = parseInt(updateData) + parseInt(importData)
                        var width4 = (width3 * 100) / totalData;
                        width4 = Math.round(width4);
                        setPercentOfMsd(width4)
                        setPercent(width4)
                        if (parseInt(importData) + parseInt(updateData) == "null") {
                          $(".count_imported").html("0");
                        } else {
                          $(".count_imported").html(parseInt(importData));
                        }
                        if (updateData == "null") {
                          $(".update_imported").html("0");
                        } else {
                          $(".update_imported").html(updateData);
                        }
                        if (errorData == "null") {
                          $(".error_imported").html("0");
                        } else {
                          $(".error_imported").html(errorData);
                        }
        
        
        
        
        
        
                        // $(".count_imported").html(parseInt(importData) );
                        // $(".update_imported").html(updateData);
                        // $(".error_imported").html(errorData);
                        var statusComplete = result[5].split(':');
                        var statusCompleteGet = statusComplete[1];
                        SetErrorData(errorData)
                        SetImportData(parseInt(importData) + parseInt(updateData))
                        $(".mt-1").show();
                        $(".mt-2").show();
                        setStatusData("completed");
        
                        if (statusCompleteGet == 1) {
        
                          // $(".statusImport").hide();
                          // $(".statusFailed").hide();
                          // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                          // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                          clearInterval(logInterval);
                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                          // $(document).on('hidden.bs.modal', '#importModal', function () {
                          //   location.reload();
                          // });
                        } else {
                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                        }
        
                        if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                          console.log("done data")
                          $(".animate-text").html("Completed");
                          // window.location.reload(false);
                        }
        
                      }
        
        
        
                    }
                  });
                }, 3000);
                msdFileRef.current.value = "";
                msdFileRef.current.type = "text";
                msdFileRef.current.type = "file";
                setMsdFile([])
              }
        
            })
              .catch((err) => {
                msdFileRef.current.value = "";
                  msdFileRef.current.type = "text";
                  msdFileRef.current.type = "file";
                  setMsdFile([])
                console.log(err)
              });
            }
            }

    if(indicatorRowChecked){
      if(indicatorFile[0] === undefined){
        toast.error("Please Select Indicator File");
      }else{
      setPercentOfIndicator(0)
    indicatorDataToSend.append('files', indicatorFile[0]);
    indicatorDataToSend.append('import_for', 50);
    const savedItem = JSON.parse(localStorage.getItem("userInfo"));
    console.log("saved--" + savedItem)
    let id = savedItem.id;
    console.log("id--" + id)
    indicatorDataToSend.append('user_id', id);
    console.log("myFile",file[0])
    $('#dataimport').attr('style', 'display:block')
    adminService.httpPostFile('/data-sdmx/file-upload', indicatorDataToSend).then((res) => {
      if (res) {

        let d = res.data;
        $(".animate-text").html("Progress");
        console.log("data------" + res.import_id)
        // $(".mt-1").hide();
        // $(".mt-2").hide();
        setStatusData("progress");

        if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
          $('#importClose').trigger('click');
          toast.error("Invalid file format");

        }


        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';

        var logInterval = setInterval(function () {

          // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{

          $.ajax({
            url: fileName, success: function (response) {

              if (response) {
                // $(".animate-text").html("Completed");
                $(".fileName").html(res.name);
                $(".currentDate").html(res.currentDate);

                var result = response.split('=');

                var total = result[0].split(':');


                var totalData = total[1];
                if (totalData == "null") {
                  $(".total_record").html("0");
                } else {
                  $(".total_record").html(totalData);
                }

                //import record count
                var importD = result[1].split(':');
                var importData = importD[1];
                var width = (importData * 100) / totalData;
                width = Math.round(width);
                setPercent(width)
                //error count
                var errorD = result[3].split(':');
                var errorData = errorD[1];
                var width1 = (errorData * 100) / totalData;
                width1 = Math.round(width1);
                setErrorPercent(width1);

                //update count
                var updateD = result[4].split(':');
                var updateData = updateD[1];
                var width2 = (updateData * 100) / totalData;
                width2 = Math.round(width2);

                var width3 = parseInt(updateData) + parseInt(importData)
                var width4 = (width3 * 100) / totalData;
                width4 = Math.round(width4);
                setPercent(width4)
                setPercentageForIndicator('Indicator')
                setPercentOfIndicator(width4)
                console.log('percentage',width4)
                if (parseInt(importData) + parseInt(updateData) == "null") {
                  $(".count_imported").html("0");
                } else {
                  $(".count_imported").html(parseInt(importData));
                }
                if (updateData == "null") {
                  $(".update_imported").html("0");
                } else {
                  $(".update_imported").html(updateData);
                }
                if (errorData == "null") {
                  $(".error_imported").html("0");
                } else {
                  $(".error_imported").html(errorData);
                }






                // $(".count_imported").html(parseInt(importData) );
                // $(".update_imported").html(updateData);
                // $(".error_imported").html(errorData);
                var statusComplete = result[5].split(':');
                var statusCompleteGet = statusComplete[1];
                SetErrorData(errorData)
                SetImportData(parseInt(importData) + parseInt(updateData))
                $(".mt-1").show();
                $(".mt-2").show();
                setStatusData("completed");

                if (statusCompleteGet == 1) {

                  // $(".statusImport").hide();
                  // $(".statusFailed").hide();
                  // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                  // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                  clearInterval(logInterval);
                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                  // $(document).on('hidden.bs.modal', '#importModal', function () {
                  //   location.reload();
                  // });
                } else {
                  // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                }

                if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                  console.log("done data")
                  $(".animate-text").html("Completed");
                  // window.location.reload(false);
                }

              }



            }
          });
          
        }, 3000);
        indicatorFileRef.current.value = "";
        indicatorFileRef.current.type = "text";
        indicatorFileRef.current.type = "file";
        setIndicatorFile([])
      }

    })
      .catch((err) => {
        indicatorFileRef.current.value = "";
          indicatorFileRef.current.type = "text";
          indicatorFileRef.current.type = "file";
          setIndicatorFile([])
        console.log(err)
      });
    }
    }

    if(unitRowChecked){
      if(unitFile[0] === undefined){
        toast.error("Please Select Unit File");
      }else{
      setPercentOfUnit(0)
      unitDataToSend.append('files', unitFile[0]);
      unitDataToSend.append('import_for', 51);
      const savedItem = JSON.parse(localStorage.getItem("userInfo"));
      console.log("saved--" + savedItem)
      let id = savedItem.id;
      console.log("id--" + id)
      unitDataToSend.append('user_id', id);
      console.log("myFile",file[0])
      $('#dataimport').attr('style', 'display:block')
      adminService.httpPostFile('/data-sdmx/file-upload', unitDataToSend).then((res) => {
        if (res) {
  
          let d = res.data;
          $(".animate-text").html("Progress");
          console.log("data------" + res.import_id)
          $(".mt-1").hide();
          $(".mt-2").hide();
          setStatusData("progress");
  
          if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
            $('#importClose').trigger('click');
            toast.error("Invalid file format");
  
          }
  
  
          var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
  
          var logInterval = setInterval(function () {
  
            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
  
            $.ajax({
              url: fileName, success: function (response) {
  
                if (response) {
                  // $(".animate-text").html("Completed");
                  $(".fileName").html(res.name);
                  $(".currentDate").html(res.currentDate);
  
                  var result = response.split('=');
  
                  var total = result[0].split(':');
  
  
                  var totalData = total[1];
                  if (totalData == "null") {
                    $(".total_record").html("0");
                  } else {
                    $(".total_record").html(totalData);
                  }
  
                  //import record count
                  var importD = result[1].split(':');
                  var importData = importD[1];
                  var width = (importData * 100) / totalData;
                  width = Math.round(width);
                  setPercent(width)
                  //error count
                  var errorD = result[3].split(':');
                  var errorData = errorD[1];
                  var width1 = (errorData * 100) / totalData;
                  width1 = Math.round(width1);
                  setErrorPercent(width1);
  
                  //update count
                  var updateD = result[4].split(':');
                  var updateData = updateD[1];
                  var width2 = (updateData * 100) / totalData;
                  width2 = Math.round(width2);
  
                  var width3 = parseInt(updateData) + parseInt(importData)
                  var width4 = (width3 * 100) / totalData;
                  width4 = Math.round(width4);
                  setPercent(width4)
                  setPercentageForUnit('Unit')
                  setPercentOfUnit(width4)
                  if (parseInt(importData) + parseInt(updateData) == "null") {
                    $(".count_imported").html("0");
                  } else {
                    $(".count_imported").html(parseInt(importData));
                  }
                  if (updateData == "null") {
                    $(".update_imported").html("0");
                  } else {
                    $(".update_imported").html(updateData);
                  }
                  if (errorData == "null") {
                    $(".error_imported").html("0");
                  } else {
                    $(".error_imported").html(errorData);
                  }
  
  
  
  
  
  
                  // $(".count_imported").html(parseInt(importData) );
                  // $(".update_imported").html(updateData);
                  // $(".error_imported").html(errorData);
                  var statusComplete = result[5].split(':');
                  var statusCompleteGet = statusComplete[1];
                  SetErrorData(errorData)
                  SetImportData(parseInt(importData) + parseInt(updateData))
                  $(".mt-1").show();
                  $(".mt-2").show();
                  setStatusData("completed");
  
                  if (statusCompleteGet == 1) {
  
                    // $(".statusImport").hide();
                    // $(".statusFailed").hide();
                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                    clearInterval(logInterval);
                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                    //   location.reload();
                    // });
                  } else {
                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                  }
  
                  if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                    console.log("done data")
                    $(".animate-text").html("Completed");
                    // window.location.reload(false);
                  }
  
                }
  
  
  
              }
            });
          }, 3000);
          unitFileRef.current.value = "";
          unitFileRef.current.type = "text";
          unitFileRef.current.type = "file";
          setUnitFile([])
        }
  
      })
        .catch((err) => {
          unitFileRef.current.value = "";
          unitFileRef.current.type = "text";
          unitFileRef.current.type = "file";
          setUnitFile([])
          console.log(err)
        });
      }
      }

      if(subgroupRowChecked){
        if(subgroupFile[0] === undefined){
          toast.error("Please Select Subgroup File");
        }else{
        setPercentOfSubgroup(0)
        subgroupDataToSend.append('files', subgroupFile[0]);
        subgroupDataToSend.append('import_for', 52);
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        console.log("saved--" + savedItem)
        let id = savedItem.id;
        console.log("id--" + id)
        subgroupDataToSend.append('user_id', id);
        console.log("myFile",file[0])
        $('#dataimport').attr('style', 'display:block')
        adminService.httpPostFile('/data-sdmx/file-upload', subgroupDataToSend).then((res) => {
          if (res) {
    
            let d = res.data;
            $(".animate-text").html("Progress");
            console.log("data------" + res.import_id)
            $(".mt-1").hide();
            $(".mt-2").hide();
            setStatusData("progress");
    
            if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
              $('#importClose').trigger('click');
              toast.error("Invalid file format");
    
            }
    
    
            var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
    
            var logInterval = setInterval(function () {
    
              // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
    
              $.ajax({
                url: fileName, success: function (response) {
    
                  if (response) {
                    // $(".animate-text").html("Completed");
                    $(".fileName").html(res.name);
                    $(".currentDate").html(res.currentDate);
    
                    var result = response.split('=');
    
                    var total = result[0].split(':');
    
    
                    var totalData = total[1];
                    if (totalData == "null") {
                      $(".total_record").html("0");
                    } else {
                      $(".total_record").html(totalData);
                    }
    
                    //import record count
                    var importD = result[1].split(':');
                    var importData = importD[1];
                    var width = (importData * 100) / totalData;
                    width = Math.round(width);
                    setPercent(width)
                    //error count
                    var errorD = result[3].split(':');
                    var errorData = errorD[1];
                    var width1 = (errorData * 100) / totalData;
                    width1 = Math.round(width1);
                    setErrorPercent(width1);
    
                    //update count
                    var updateD = result[4].split(':');
                    var updateData = updateD[1];
                    var width2 = (updateData * 100) / totalData;
                    width2 = Math.round(width2);
    
                    var width3 = parseInt(updateData) + parseInt(importData)
                    var width4 = (width3 * 100) / totalData;
                    width4 = Math.round(width4);
                    setPercent(width4)
                    setPercentageForSubgroup('Subgroup')
                    setPercentOfSubgroup(width4)
                    if (parseInt(importData) + parseInt(updateData) == "null") {
                      $(".count_imported").html("0");
                    } else {
                      $(".count_imported").html(parseInt(importData));
                    }
                    if (updateData == "null") {
                      $(".update_imported").html("0");
                    } else {
                      $(".update_imported").html(updateData);
                    }
                    if (errorData == "null") {
                      $(".error_imported").html("0");
                    } else {
                      $(".error_imported").html(errorData);
                    }
    
    
    
    
    
    
                    // $(".count_imported").html(parseInt(importData) );
                    // $(".update_imported").html(updateData);
                    // $(".error_imported").html(errorData);
                    var statusComplete = result[5].split(':');
                    var statusCompleteGet = statusComplete[1];
                    SetErrorData(errorData)
                    SetImportData(parseInt(importData) + parseInt(updateData))
                    $(".mt-1").show();
                    $(".mt-2").show();
                    setStatusData("completed");
    
                    if (statusCompleteGet == 1) {
    
                      // $(".statusImport").hide();
                      // $(".statusFailed").hide();
                      // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                      // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                      clearInterval(logInterval);
                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                      // $(document).on('hidden.bs.modal', '#importModal', function () {
                      //   location.reload();
                      // });
                    } else {
                      // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                    }
    
                    if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                      console.log("done data")
                      $(".animate-text").html("Completed");
                      // window.location.reload(false);
                    }
    
                  }
    
    
    
                }
              });
            }, 3000);
            subgroupFileRef.current.value = "";
            subgroupFileRef.current.type = "text";
            subgroupFileRef.current.type = "file";
            setSubgroupFile([])             
          }
    
        })
          .catch((err) => {
            subgroupFileRef.current.value = "";
            subgroupFileRef.current.type = "text";
            subgroupFileRef.current.type = "file";
            setSubgroupFile([])    
            console.log(err)
          });
        }
        }

        if(subgroupDimensionRowChecked){
          if(subgroupDimensionFile[0] === undefined){
            toast.error("Please Select Subgroup dDimension File");
          }else{
          setPercentOfSubgroupDimension(0)
          subgroupDimensionDataToSend.append('files', subgroupDimensionFile[0]);
          subgroupDimensionDataToSend.append('import_for', 53);
          const savedItem = JSON.parse(localStorage.getItem("userInfo"));
          console.log("saved--" + savedItem)
          let id = savedItem.id;
          console.log("id--" + id)
          subgroupDimensionDataToSend.append('user_id', id);
          console.log("myFile",file[0])
          $('#dataimport').attr('style', 'display:block')
          adminService.httpPostFile('/data-sdmx/file-upload', subgroupDimensionDataToSend).then((res) => {
            if (res) {
      
              let d = res.data;
              $(".animate-text").html("Progress");
              console.log("data------" + res.import_id)
              $(".mt-1").hide();
              $(".mt-2").hide();
              setStatusData("progress");
      
              if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                $('#importClose').trigger('click');
                toast.error("Invalid file format");
      
              }
      
      
              var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
      
              var logInterval = setInterval(function () {
      
                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
      
                $.ajax({
                  url: fileName, success: function (response) {
      
                    if (response) {
                      // $(".animate-text").html("Completed");
                      $(".fileName").html(res.name);
                      $(".currentDate").html(res.currentDate);
      
                      var result = response.split('=');
      
                      var total = result[0].split(':');
      
      
                      var totalData = total[1];
                      if (totalData == "null") {
                        $(".total_record").html("0");
                      } else {
                        $(".total_record").html(totalData);
                      }
      
                      //import record count
                      var importD = result[1].split(':');
                      var importData = importD[1];
                      var width = (importData * 100) / totalData;
                      width = Math.round(width);
                      setPercent(width)
                      //error count
                      var errorD = result[3].split(':');
                      var errorData = errorD[1];
                      var width1 = (errorData * 100) / totalData;
                      width1 = Math.round(width1);
                      setErrorPercent(width1);
      
                      //update count
                      var updateD = result[4].split(':');
                      var updateData = updateD[1];
                      var width2 = (updateData * 100) / totalData;
                      width2 = Math.round(width2);
      
                      var width3 = parseInt(updateData) + parseInt(importData)
                      var width4 = (width3 * 100) / totalData;
                      width4 = Math.round(width4);
                      setPercent(width4)
                      setPercentageForSubgroupDimension('Subgroup Dimension')
                      setPercentOfSubgroupDimension(width4)
                      if (parseInt(importData) + parseInt(updateData) == "null") {
                        $(".count_imported").html("0");
                      } else {
                        $(".count_imported").html(parseInt(importData));
                      }
                      if (updateData == "null") {
                        $(".update_imported").html("0");
                      } else {
                        $(".update_imported").html(updateData);
                      }
                      if (errorData == "null") {
                        $(".error_imported").html("0");
                      } else {
                        $(".error_imported").html(errorData);
                      }
      
      
      
      
      
      
                      // $(".count_imported").html(parseInt(importData) );
                      // $(".update_imported").html(updateData);
                      // $(".error_imported").html(errorData);
                      var statusComplete = result[5].split(':');
                      var statusCompleteGet = statusComplete[1];
                      SetErrorData(errorData)
                      SetImportData(parseInt(importData) + parseInt(updateData))
                      $(".mt-1").show();
                      $(".mt-2").show();
                      setStatusData("completed");
      
                      if (statusCompleteGet == 1) {
      
                        // $(".statusImport").hide();
                        // $(".statusFailed").hide();
                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                        clearInterval(logInterval);
                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                        //   location.reload();
                        // });
                      } else {
                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                      }
      
                      if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                        console.log("done data")
                        $(".animate-text").html("Completed");
                        // window.location.reload(false);
                      }
      
                    }
      
      
      
                  }
                });
              }, 3000);
              subgroupDimensionFileRef.current.value = "";
              subgroupDimensionFileRef.current.type = "text";
              subgroupDimensionFileRef.current.type = "file";
              setSubgroupDimensionFile([])
            }
      
          })
            .catch((err) => {
              subgroupDimensionFileRef.current.value = "";
                subgroupDimensionFileRef.current.type = "text";
                subgroupDimensionFileRef.current.type = "file";
                setSubgroupDimensionFile([])
              console.log(err)
            });
          }
        }

          if(areaRowChecked){
            if(areaFile[0] === undefined){
              toast.error("Please Select Area File");
            }else{
            setPercentOfArea(0)
            areaDataToSend.append('files', areaFile[0]);
            areaDataToSend.append('import_for', 54);
            const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            console.log("saved--" + savedItem)
            let id = savedItem.id;
            console.log("id--" + id)
            areaDataToSend.append('user_id', id);
            console.log("myFile",file[0])
            $('#dataimport').attr('style', 'display:block')
            adminService.httpPostFile('/data-sdmx/file-upload', areaDataToSend).then((res) => {
              if (res) {
        
                let d = res.data;
                $(".animate-text").html("Progress");
                console.log("data------" + res.import_id)
                $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
        
                if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                  $('#importClose').trigger('click');
                  toast.error("Invalid file format");
        
                }
        
        
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
        
                var logInterval = setInterval(function () {
        
                  // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
        
                  $.ajax({
                    url: fileName, success: function (response) {
        
                      if (response) {
                        // $(".animate-text").html("Completed");
                        $(".fileName").html(res.name);
                        $(".currentDate").html(res.currentDate);
        
                        var result = response.split('=');
        
                        var total = result[0].split(':');
        
        
                        var totalData = total[1];
                        if (totalData == "null") {
                          $(".total_record").html("0");
                        } else {
                          $(".total_record").html(totalData);
                        }
        
                        //import record count
                        var importD = result[1].split(':');
                        var importData = importD[1];
                        var width = (importData * 100) / totalData;
                        width = Math.round(width);
                        setPercent(width)
                        //error count
                        var errorD = result[3].split(':');
                        var errorData = errorD[1];
                        var width1 = (errorData * 100) / totalData;
                        width1 = Math.round(width1);
                        setErrorPercent(width1);
        
                        //update count
                        var updateD = result[4].split(':');
                        var updateData = updateD[1];
                        var width2 = (updateData * 100) / totalData;
                        width2 = Math.round(width2);
        
                        var width3 = parseInt(updateData) + parseInt(importData)
                        var width4 = (width3 * 100) / totalData;
                        width4 = Math.round(width4);
                        setPercent(width4)
                        setPercentageForArea('Area')
                        setPercentOfArea(width4)
                        if (parseInt(importData) + parseInt(updateData) == "null") {
                          $(".count_imported").html("0");
                        } else {
                          $(".count_imported").html(parseInt(importData));
                        }
                        if (updateData == "null") {
                          $(".update_imported").html("0");
                        } else {
                          $(".update_imported").html(updateData);
                        }
                        if (errorData == "null") {
                          $(".error_imported").html("0");
                        } else {
                          $(".error_imported").html(errorData);
                        }
        
        
        
        
        
        
                        // $(".count_imported").html(parseInt(importData) );
                        // $(".update_imported").html(updateData);
                        // $(".error_imported").html(errorData);
                        var statusComplete = result[5].split(':');
                        var statusCompleteGet = statusComplete[1];
                        SetErrorData(errorData)
                        SetImportData(parseInt(importData) + parseInt(updateData))
                        $(".mt-1").show();
                        $(".mt-2").show();
                        setStatusData("completed");
        
                        if (statusCompleteGet == 1) {
        
                          // $(".statusImport").hide();
                          // $(".statusFailed").hide();
                          // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                          // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                          clearInterval(logInterval);
                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                          // $(document).on('hidden.bs.modal', '#importModal', function () {
                          //   location.reload();
                          // });
                        } else {
                          // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                        }
        
                        if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                          console.log("done data")
                          $(".animate-text").html("Completed");
                          // window.location.reload(false);
                        }
        
                      }
        
        
        
                    }
                  });
                }, 3000);
                areaFileRef.current.value = "";
                areaFileRef.current.type = "text";
                areaFileRef.current.type = "file";
                setAreaFile([])
              }
        
            })
              .catch((err) => {
                areaFileRef.current.value = "";
                  areaFileRef.current.type = "text";
                  areaFileRef.current.type = "file";
                  setAreaFile([])
                console.log(err)
              });
            }
          }

            if(sourceRowChecked){
              if(sourceFile[0] === undefined){
                toast.error("Please Select Unit File");
              }else{
              setPercentOfSource(0)
              sourceDataToSend.append('files', sourceFile[0]);
              sourceDataToSend.append('import_for', 55);
              const savedItem = JSON.parse(localStorage.getItem("userInfo"));
              console.log("saved--" + savedItem)
              let id = savedItem.id;
              console.log("id--" + id)
              sourceDataToSend.append('user_id', id);
              console.log("myFile",file[0])
              $('#dataimport').attr('style', 'display:block')
              adminService.httpPostFile('/data-sdmx/file-upload', sourceDataToSend).then((res) => {
                if (res) {
          
                  let d = res.data;
                  $(".animate-text").html("Progress");
                  console.log("data------" + res.import_id)
                  $(".mt-1").hide();
                  $(".mt-2").hide();
                  setStatusData("progress");
          
                  if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                    $('#importClose').trigger('click');
                    toast.error("Invalid file format");
          
                  }
          
          
                  var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
          
                  var logInterval = setInterval(function () {
          
                    // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
          
                    $.ajax({
                      url: fileName, success: function (response) {
          
                        if (response) {
                          // $(".animate-text").html("Completed");
                          $(".fileName").html(res.name);
                          $(".currentDate").html(res.currentDate);
          
                          var result = response.split('=');
          
                          var total = result[0].split(':');
          
          
                          var totalData = total[1];
                          if (totalData == "null") {
                            $(".total_record").html("0");
                          } else {
                            $(".total_record").html(totalData);
                          }
          
                          //import record count
                          var importD = result[1].split(':');
                          var importData = importD[1];
                          var width = (importData * 100) / totalData;
                          width = Math.round(width);
                          setPercent(width)
                          //error count
                          var errorD = result[3].split(':');
                          var errorData = errorD[1];
                          var width1 = (errorData * 100) / totalData;
                          width1 = Math.round(width1);
                          setErrorPercent(width1);
          
                          //update count
                          var updateD = result[4].split(':');
                          var updateData = updateD[1];
                          var width2 = (updateData * 100) / totalData;
                          width2 = Math.round(width2);
          
                          var width3 = parseInt(updateData) + parseInt(importData)
                          var width4 = (width3 * 100) / totalData;
                          width4 = Math.round(width4);
                          setPercent(width4)
                          setPercentageForSource('Source')
                          setPercentOfSource(width4)
                          if (parseInt(importData) + parseInt(updateData) == "null") {
                            $(".count_imported").html("0");
                          } else {
                            $(".count_imported").html(parseInt(importData));
                          }
                          if (updateData == "null") {
                            $(".update_imported").html("0");
                          } else {
                            $(".update_imported").html(updateData);
                          }
                          if (errorData == "null") {
                            $(".error_imported").html("0");
                          } else {
                            $(".error_imported").html(errorData);
                          }
          
          
          
          
          
          
                          // $(".count_imported").html(parseInt(importData) );
                          // $(".update_imported").html(updateData);
                          // $(".error_imported").html(errorData);
                          var statusComplete = result[5].split(':');
                          var statusCompleteGet = statusComplete[1];
                          SetErrorData(errorData)
                          SetImportData(parseInt(importData) + parseInt(updateData))
                          $(".mt-1").show();
                          $(".mt-2").show();
                          setStatusData("completed");
          
                          if (statusCompleteGet == 1) {
          
                            // $(".statusImport").hide();
                            // $(".statusFailed").hide();
                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                            clearInterval(logInterval);
                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                            //   location.reload();
                            // });
                          } else {
                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                          }
          
                          if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                            console.log("done data")
                            $(".animate-text").html("Completed");
                            // window.location.reload(false);
                          }
          
                        }
          
          
          
                      }
                    });
                  }, 3000);
                  sourceFileRef.current.value = "";
                  sourceFileRef.current.type = "text";
                  sourceFileRef.current.type = "file";
                  setSourceFile([])
                }
          
              })
                .catch((err) => {
                  sourceFileRef.current.value = "";
                  sourceFileRef.current.type = "text";
                  sourceFileRef.current.type = "file";
                  setSourceFile([])
                  console.log(err)
                });
              }
            }

              if(timePeriodRowChecked){
                if(timePeriodFile[0] === undefined){
                  toast.error("Please Select Timperiod File");
                }else{
                setPercentOfTimePeriod(0)
                timePeriodDataToSend.append('files', timePeriodFile[0]);
                timePeriodDataToSend.append('import_for', 56);
                const savedItem = JSON.parse(localStorage.getItem("userInfo"));
                console.log("saved--" + savedItem)
                let id = savedItem.id;
                console.log("id--" + id)
                timePeriodDataToSend.append('user_id', id);
                console.log("myFile",file[0])
                $('#dataimport').attr('style', 'display:block')
                adminService.httpPostFile('/data-sdmx/file-upload', timePeriodDataToSend).then((res) => {
                  if (res) {
            
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------" + res.import_id)
                    $(".mt-1").hide();
                    $(".mt-2").hide();
                    setStatusData("progress");
            
                    if (res?.message == 'Invalid file format' || res?.message == 'Invalid file format: areaid') {
                      $('#importClose').trigger('click');
                      toast.error("Invalid file format");
            
                    }
            
            
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
            
                    var logInterval = setInterval(function () {
            
                      // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
            
                      $.ajax({
                        url: fileName, success: function (response) {
            
                          if (response) {
                            // $(".animate-text").html("Completed");
                            $(".fileName").html(res.name);
                            $(".currentDate").html(res.currentDate);
            
                            var result = response.split('=');
            
                            var total = result[0].split(':');
            
            
                            var totalData = total[1];
                            if (totalData == "null") {
                              $(".total_record").html("0");
                            } else {
                              $(".total_record").html(totalData);
                            }
            
                            //import record count
                            var importD = result[1].split(':');
                            var importData = importD[1];
                            var width = (importData * 100) / totalData;
                            width = Math.round(width);
                            setPercent(width)
                            //error count
                            var errorD = result[3].split(':');
                            var errorData = errorD[1];
                            var width1 = (errorData * 100) / totalData;
                            width1 = Math.round(width1);
                            setErrorPercent(width1);
            
                            //update count
                            var updateD = result[4].split(':');
                            var updateData = updateD[1];
                            var width2 = (updateData * 100) / totalData;
                            width2 = Math.round(width2);
            
                            var width3 = parseInt(updateData) + parseInt(importData)
                            var width4 = (width3 * 100) / totalData;
                            width4 = Math.round(width4);
                            setPercent(width4)
                            setPercentageForTimePeriod('Time Period')
                            setPercentOfTimePeriod(width4)
                            if (parseInt(importData) + parseInt(updateData) == "null") {
                              $(".count_imported").html("0");
                            } else {
                              $(".count_imported").html(parseInt(importData));
                            }
                            if (updateData == "null") {
                              $(".update_imported").html("0");
                            } else {
                              $(".update_imported").html(updateData);
                            }
                            if (errorData == "null") {
                              $(".error_imported").html("0");
                            } else {
                              $(".error_imported").html(errorData);
                            }
            
            
            
            
            
            
                            // $(".count_imported").html(parseInt(importData) );
                            // $(".update_imported").html(updateData);
                            // $(".error_imported").html(errorData);
                            var statusComplete = result[5].split(':');
                            var statusCompleteGet = statusComplete[1];
                            SetErrorData(errorData)
                            SetImportData(parseInt(importData) + parseInt(updateData))
                            $(".mt-1").show();
                            $(".mt-2").show();
                            setStatusData("completed");
            
                            if (statusCompleteGet == 1) {
            
                              // $(".statusImport").hide();
                              // $(".statusFailed").hide();
                              // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                              // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                              clearInterval(logInterval);
                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                              // $(document).on('hidden.bs.modal', '#importModal', function () {
                              //   location.reload();
                              // });
                            } else {
                              // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                            }
            
                            if ((parseInt(importData) + parseInt(updateData) + parseInt(errorData)) == parseInt(totalData)) {
                              console.log("done data")
                              $(".animate-text").html("Completed");
                              // window.location.reload(false);
                            }
            
                          }
            
            
            
                        }
                      });
                    }, 3000);
                    timePeriodFileRef.current.value = "";
                    timePeriodFileRef.current.type = "text";
                    timePeriodFileRef.current.type = "file";
                    setTimeperiodFile([])
                  }
            
                })
                  .catch((err) => {
                    timePeriodFileRef.current.value = "";
                      timePeriodFileRef.current.type = "text";
                      timePeriodFileRef.current.type = "file";
                      setTimeperiodFile([])
                    console.log(err)
                  });
                }
              }
                // setDataFile([])
                // setIndicatorFile([])
                // setUnitFile([])   
                // setSubgroupFile([]) 
                // setSubgroupDimensionFile([])
                // setAreaFile([])
                // setSourceFile([])
                // setTimeperiodFile([])
  }

  return (
    <>


      <section className="sdmx_import">
        <div className="container-fluid">
          <div className="row">
            <div class="col-md-12">
              <div className="sdmx_import_dt">
                <DataTable
                  columns={columns}
                  data={ValuesTable}
                  // selectableRows
                  persistTableHead
                  pagination
                  noDataComponent="No Data"
                  
                />
              </div>
              <div className="import_sdmx_btn">
                <button onClick={handleImport}>Import</button>
                {/* {
                                  importValue && <ImportData value={58}/>
                              } */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

};

export default ImportSDMXComponent;