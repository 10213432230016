import React, { useEffect, useRef, useState } from "react";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";

import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

// ------------>
import adminService from "../../services/admin.service";

import Dropdown from "react-bootstrap/Dropdown";
// ================================== Password
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { CoPresentOutlined } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
// ================================== Password

function AddUserModal(props) {
  const form = useRef();
  const divRef = useRef(null);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "This field is required",
      },
    })
  );

  const [, forceUpdate] = useState();
  // const [areaTree, setAreaTree] = useState([]);
  const [callData, setParentAreaData] = useState([]);
  const [areaExpand, setAreaExpanded] = useState([]);
  const [areaChecked, setAreaChecked] = useState([]);
  const [formError, setError] = useState({ error: false });
  // new state---->
  const [departmentlist, setDepartmentlist] = useState([]);
  



  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let userRole = userInfo?.role;
  let userArea = userInfo?.area;

  const [roleValue, setRoleValue] = useState();

  const [role, setRole] = useState([
    {
      label: "Data Entry",
      value: 2,
    },
    {
      label: "Data Approve",
      value: 4,
    },
  ]);

  const [state, setState] = useState({
    
    id: "",
    name: "",
    email: "",
    password: "",
    user_role: "",
    areaList: [],
    designation: "",
    departmentName: "",
    departmentCode: "",
    contact_no: "",
  });

  useEffect(()=> {
    divRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, [props.toTop])

  const getAreaList = (e) => {
    setAreaChecked(e);

    var dataArr = [];

    e?.forEach((item) => {
      props.areaTree?.forEach((dt) => {
        if (dt.area_code == item) {
          dataArr.push({ code: dt.area_code, name: dt.name });
        }
      });
    });
    
    setState({ ...state, areaList: dataArr });
  };
  // ------------------>
  const getDepartment = async (e) => {
    try {
      const response = await adminService.httpGet(
        "/data-retrieval/department/get-all-departmentlist"
      );
      const data = response.data;
     
      setDepartmentlist(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  ValidatorForm.addValidationRule('isNumber', (value) => {
    // Custom validation rule for a number
    return /^[0-9]+$/.test(value);
  });
  ValidatorForm.addValidationRule('minLength', (value) => {
    return value?.length >= 6;
  });
  
  ValidatorForm.addValidationRule('maxLength', (value) => {
    return value?.length <= 10;
  });


  useEffect(() => {
    getDepartment();
    const facilities1 = props.facilityData;
    if (props.isEdit == true) {
      let test = "";

      let areaCodes = [];

      var dataArr = [];
      props.editMode?.area_name?.forEach((item) => {
        props.areaTree?.forEach((dt) => {
          if (dt.area_code == item[0].area_code) {
            dataArr.push({ code: dt.area_code, name: dt.name });
            areaCodes.push(dt.area_code);
          }
        });
      });

      setAreaChecked(areaCodes);
     
      setState({
        id: props.editMode?._id._id,
        name: props.editMode?.name,
        email: props.editMode?.email,
        user_role: props.editMode?.role,
        areaList: dataArr,
        departmentCode: props.editMode?.department?.department_code,
        departmentName:props.editMode?.department?.department_name,
        designation: props.editMode?.designation,
        contact_no: props.editMode?.contact_no,
      });
     

      let rlData = props.editMode.role;

      setRoleValue(rlData);
    } else {
      
      setState({
        id: "",
        name: "",
        email: "",
        password: "",
        user_role: "",
        areaList: [],
        departmentCode: "",
        departmentName:"",
        designation: "",
        contact_no:"",
      });
      setRoleValue("");
      setAreaChecked([]);
      setAreaExpanded([]);
    }
    if (
      props.areaTree != "" &&
      props.areaTree != null &&
      props.areaTree.length > 0
    ) {
      let d = props.areaTree;
      let flatLevel = [];
      let filter1 = props.areaTree.filter(
        (a) =>
          a.level == 1 ||
          userArea.includes(a.area_code) ||
          a.areaSubData?.filter(
            (b) =>
              userArea.includes(b.area_code) ||
              b.areaSubData?.filter(
                (c) =>
                  userArea.includes(c.area_code) ||
                  c.areaSubData?.filter((d) => userArea.includes(d.area_code))
                    .length > 0
              )
          ).length > 0
      );
      d?.forEach((item) => {
        var obj = {
          value: item.area_code,
          label: item.name,
          parent: item.parent_id,
          level: item.level,
        };
        flatLevel.push(obj);
      }
      );

      const data = setLevelData(flatLevel);

      setParentAreaData(data);
    }
  }, [props]);


  // area tree sctructure=========================//

  const setLevelData = (flat) => {
    var roots = [];

    var all = {};

    flat.forEach(function (item) {
      all[item.value] = item;
    });

    Object.keys(all).forEach(function (value) {
      var item = all[value];

      if (item.parent == null || item.parent === "") {
        roots.push(item);
      } else if (item.parent in all) {
        var p = all[item.parent];

        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });

    return roots;
  };

  // ============================================================================== Password Show/Hide Start
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // ============================================================================== Password Show/Hide End
  // ============================================================================== Validation Start

  const makeid = (length) => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  };

  const handleChange = (event) => {
    if (event.target.name == "userRole") {
      let selectedVal = event.target.value;
      setRoleValue(selectedVal);
    } else {
      
      setState({ ...state, [event.target.name]: event.target.value });
      
    }
  };

  useEffect(()=>{
   
  },[state])
  

  const handleSubmit = (e) => {
    e.preventDefault();
    // if(state.name.length === 0 ||  ){

    // }
    let dataToSend = [];
    let passwordString = makeid(10) + state.password;

    let password = btoa(passwordString);

    if (props.isEdit == true) {
      dataToSend = {
        id: state.id,
        name: state.name,
        email: state.email,
        password: state.password != undefined ? password : null,
        user_role: roleValue,
        area: areaChecked,
        departmentCode: state.departmentCode, 
        designation: state.designation,
        contact_no:state.contact_no,
        // password:"",
        status: 1,
      };
      
    } else {
      dataToSend = {
        id: null,
        name: state.name,
        email: state.email,
        password: password,
        user_role: roleValue,
        area: areaChecked,
        designation: state.designation,
        departmentCode: state.departmentCode,
        contact_no:state.contact_no,
        status: 1,
      };
    }
 
    if (simpleValidator.current.allValid()) {
      props.parentCallback(dataToSend);

   
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
    if (setRoleValue == "") {
      setError({ error: true, msg: "This field is required", input: "ptype" });
      return;
    }
   
  };

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
 
    console.log('5firstjfjfhffjgj')
    setState({...state,
      departmentCode: "",
      departmentName:"",
      contact_no:"",
      password:"",
    });
    
  };
//new -------------->

const handleSelectChange = (event, option) => {

  setState({...state, departmentName: option.label, departmentCode: option.value});
};

  // ============================================================================== Validation End
  

 
  ////////////=======multiple delete function ===========////////////////
  return (
    <>
      <div className="user-form data-user">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            <div className="gallery-collections mt-0 metadata_form" ref={divRef}>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Name</label>
                      </span>
                      <TextValidator
                        name="name"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.name}
                         
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Designation</label>
                      </span>
                      <TextValidator
                        name="designation"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.designation}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Department</label>
                      </span>
                     
                      <Autocomplete
                        id="dept"
                        value={{'label': state.departmentName , 'value': state.departmentCode }}
                        onChange={(e, v)=> handleSelectChange(e, v)}
                        onInputChange={(event, newInputValue) => setState({...state,departmentName: newInputValue, departmentCode: newInputValue})}
                        options={departmentlist ? departmentlist.map((dept) => { return { 'label': dept.department_name ? dept.department_name : '', 'value': dept.department_code } }) : { 'label': '', 'value': '' }}
                        getOptionLabel={(option) => option.label ? option.label : ''}
                        filterSelectedOptions

                        renderInput={(params) => <TextValidator className="w-100"
                            {...params} label=""
                            name="dept"
                            value={state.departmentName}
                            // validators={['required']} errorMessages={['This field is required']}
                        />}

                    />
                    </div>
                  </div>
                </div>
              </div>
             

              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Email</label>
                      </span>
                      <TextValidator
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="w-100"
                        disabled={props.isEdit}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.email}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Contact no</label>
                      </span>
                      <TextValidator
                        type="tel"
                        name="contact_no"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e)=>{handleChange(e)}}
                        value={state.contact_no}
                        validators={state.contact_no?.length > 0 && ['isNumber', 'minLength', 'maxLength'] }
                        errorMessages={state.contact_no?.length > 0 && [
                          'Invalid contact number',
                          'Contact number must have at least 6 digits',
                          'Contact number must have at most 10 digits'
                        ]}
                                              // validators={['required', 'isNumber']}
                        // errorMessages={['This field is required', 'Invalid contact number']}
                      />

                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style roleInput">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Role</label>
                      </span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="userRole"
                        value={parseInt(roleValue)}
                        placeholder=""
                        onChange={(e)=>{handleChange(e)}}
                      >
                        {role.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="inPuthidden">
                        <TextValidator
                          type="hidden"
                          style={{ border: "0px", outline: "none" }}
                          name="password"
                          autoComplete="off"
                          className=" w-100"
                          value={roleValue}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Area</label>
                      </span>
                      <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                          {state.areaList?.length > 0 &&
                            state.areaList.slice(0, 2).map((d, i) => {
                              return (
                                <>
                                  <span className="badge">{d.name} </span>
                                </>
                              );
                            })}
                          {state.areaList?.length > 2 ? (
                            <>
                              <span>{state.areaList?.length - 1}+</span>
                            </>
                          ) : (
                            ""
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="treeviewCheckbox">
                          <CheckboxTree
                            nodes={callData}
                            checkModel="all"
                            checked={areaChecked}
                            expanded={areaExpand}
                            onCheck={getAreaList}
                            onExpand={setAreaExpanded}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="inPuthidden">
                      <TextValidator
                        type="hidden"
                        style={{ border: "0px", outline: "none" }}
                        name="password"
                        autoComplete="off"
                        className=" w-100"
                        value={state.areaList}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style autocomplete password">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Password</label>
                      </span>
                      {props.isEdit === true?

                        <TextValidator
                        type={showPassword ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e)=>{handleChange(e)}}
                        value={state.password}
                        validators={ props.isEdit == true && ( state.password?.length === undefined ||  state.password?.length < 1)  ?  
                          [] : ["required", "minLength:8", "matchRegexp:^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$"]
                        }
                        errorMessages={ props.isEdit == true && ( state.password?.length === undefined ||  state.password?.length < 1)  ?  
                          [] : [
                          "This field is required",
                          "Password should have at least 8 characters",
                          "Password should contain at least one uppercase letter and one special character",
                        ]}
                        />
                     
                          :  <>{console.log("state.password?.length", state.password?.length === undefined ,  state.password?.length < 1)}
                          <TextValidator
                          type={showPassword ? "text" : "password"}
                          name="password"
                          autoComplete="off"
                          className="w-100"
                          onChange={(e)=>{handleChange(e)}}
                          value={state.password}
                          validators={ ( state.password?.length === undefined ||  state.password?.length < 1)  ?  
                            ["required"] : ["required", "minLength:8", "matchRegexp:^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$"]
                          }
                          errorMessages={( state.password?.length === undefined ||  state.password?.length < 1)  ?  
                          ["This field is required"] : [
                            "This field is required",
                            "Password should have at least 8 characters",
                            "Password should contain at least one uppercase letter and one special character",
                          ]}
                          /></> 
                      }
                     

                      <IconButton
                        style={{
                          float: "right",
                          marginTop: "0",
                          marginRight: "10px",
                        }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
               
                <Button
                  className="addbtn"
                  color="error"
                  variant="contained"
                  type="submit"
                >
                  {props.isEdit ? "Update" : "Add"}
                  <img
                    className="btn_arrow"
                    src="assets/lib/images/icon/btn_arrow.svg"
                  />
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
}

export default AddUserModal;
