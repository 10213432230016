import React, { Component, useEffect, useRef, useState } from "react";
import './style.css';
import * as jsx from './index.module';
import { connect } from "react-redux";
import $ from 'jquery';

import AdminService from '../../../services/admin.service';

import "react-toastify/dist/ReactToastify.css";

// import datasearchcomp from "../../../Config/data-search-component.json";
// import { all } from 'axios';

class DataSearchComponent extends Component {
    constructor() {
        super()
        this.state = {
            isDisabled: true,
            loader: false,
            mainAreaData: [],
            areaList: [],
            callData: [],
            callDataOG: [],
            indicatorData: {
                'goal': [],
                'sector': []
            },
            fIndicatorData: {
                'goal': [],
                'sector': []
            },
            selIUSdata: {},
            flatLevel: [],
            areaLevel: [],
            checked: {},
            key: 'indicator',
            results: [],
            searchText: '',
            iuIds: [],
            iusIds: [], 
            resultsData: [],
            validAreaCodes: [],
            timePeriod: [],
            fTimeperiod: [],
            selTimeperiod: [],
            validTPs: [],
            source:[],
            fSource:[],
            selSource:[],
            validSrc: [],
            goalOrSector: 'goal',
            matchingData: null,
        }

        this.handleSelect = this.handletabSelect.bind(this);
        this.steps = {
            'indicator': 1,
            'area': 2,
            'timePeriod': 3,
            'source':4,
            'viewdata': 5
        }
    }

    resetSelection = () => {
        this.setState({
            loader: false,
            areaList: [],
            areaLevel: [],
            fIndicatorData: this.state.indicatorData,
            selIUSdata: {},
            checked: {},
            key:'indicator',
            results: [],
            searchText: '',
            resultsData: [],
            validAreaCodes: [],
            fTimeperiod: this.state.timePeriod,
            selTimeperiod: [],
            fSource: this.state.source,
            selSource:[],
            isWidth:false
        });
        
   
    }
    


    ToggleClass = () => {
      this.setState({isWidth:!this.state.isWidth});
    };

    // onSearch = (srchTxt) => {
    //     let d = this.state.indicatorData;
    //     console.log("indicator list",d)
    //     let fd = [];
    //     let fInd = [];
    //     this.setState({searchText: srchTxt});
    //     if(srchTxt?.length > 0) {
    //         // fd = d.filter(x => x.level_name?.en?.toLowerCase().includes(srchTxt.toLowerCase()));
    //        if(this.state.goalOrSector === 'goal'){
    //             fd = d?.goal?.filter((item) => {
    //                 return item?.target?.some((target) => {
    //                     return target?.iusList?.some((ius) => {
    //                         return ius?.subgroup?.some((subgroup)=>{
    //                             return(
    //                                 item?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                                 target?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                                 ius?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                                 subgroup?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) 
    //                                 // || unit?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase())
    //                             )
    //                         })
                            
    //                     });
    //                 });
    //             });
            
    //        } else if(this.state.goalOrSector === 'sector'){
    //         fd = d?.sector?.filter((item) => {
    //             return item?.target?.some((target) => {
    //                 return target?.iusList?.some((ius) => {
    //                     return ius?.subgroup?.some((subgroup)=>{
    //                         return(
    //                             item?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                             target?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                             ius?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
    //                             subgroup?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase())

    //                         )
    //                     })
                        
    //                 });
    //             });
    //         });
    //        }
    //         let fTP = this.state.timePeriod?.filter(x => !!(""+x.timeperiod)?.toLowerCase()?.includes(srchTxt.toLowerCase()));
    //         let fSrc = this.state.source?.filter(x => !!((""+x.datasource)?.toLowerCase().includes(srchTxt.toLowerCase())));
    //         this.setState({
    //             searchText: srchTxt,
    //             fIndicatorData: {goal : fd, sector: fd},
    //             fTimeperiod: fTP,
    //             fSource: fSrc
    //         });
            
    //     } else {
    //         this.setState({
    //             searchText: '',
    //             fIndicatorData: this.state.indicatorData,
    //             fTimeperiod: this.state.timePeriod,
    //             fSource: this.state.source
    //         });
    //     }
      
    // }


    onSearch = (srchTxt) => {
        let d = this.state.indicatorData;
        let fd = [];
        let fInd = [];
        this.setState({searchText: srchTxt});
       
        if(srchTxt?.length > 0) {
            $('.nested').addClass('active');
            $('.caret').addClass('caret-down');
            let cd = JSON.parse(JSON.stringify(this.state.callDataOG));
            const filteredData = this.searchData(cd[0], srchTxt.toLowerCase());
            cd[0] = filteredData; 
            this.setState({searchText: srchTxt,callData:cd})
           if(this.state.goalOrSector === 'goal'){
                fd = d?.goal?.filter((item) => {
                   
                    return item?.target?.some((target) => {
                        
                        return target?.iusList?.some((ius) => {
                            return ius?.subgroup?.some((subgroup)=>{
                                return(
                                    item?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                    target?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                    ius?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                    subgroup?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) 
                                    // || unit?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase())
                                    
                                )    
                            })
                            
                        });
                        
                    });
                });
            
           } else if(this.state.goalOrSector === 'sector'){
            fd = d?.sector?.filter((item) => {
                return item?.target?.some((target) => {
                    return target?.iusList?.some((ius) => {
                        return ius?.subgroup?.some((subgroup)=>{
                            return(
                                item?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                target?.level_name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                ius?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                                subgroup?.name?.['en']?.toLowerCase()?.includes(srchTxt?.toLowerCase())
                               
                            )
                        })
                        
                    });
                });
            });
           }
            let fTP = this.state.timePeriod?.filter(x => !!(""+x.timeperiod)?.toLowerCase()?.includes(srchTxt.toLowerCase()));
            let fSrc = this.state.source?.filter(x => !!((""+x.datasource)?.toLowerCase().includes(srchTxt.toLowerCase())));

            const matchedItems = [];
            fd?.forEach(item => {
                // Check if the level_name matches the search term
                if (item.level_name?.en?.toLowerCase()?.includes(srchTxt?.toLowerCase())) {
                  matchedItems.push(item);
                } else {
                  // Check if any target matches the search term
                  const matchingTargets = item?.target?.filter(target =>
                    target.level_name?.en?.toLowerCase()?.includes(srchTxt?.toLowerCase())
                  );
              
                  if (matchingTargets.length > 0) {
                    const itemCopy = { ...item, target: matchingTargets };
                    matchedItems.push(itemCopy);
                  } else {
                    // Check if any subgroup or name matches the search term within targets
                    const matchingSubgroups = [];
                    item.target.forEach(target => {
                      const matchingIus = target?.iusList?.filter(ius =>
                        ius.subgroup.some(
                          subgroup =>
                            subgroup.name?.en?.toLowerCase()?.includes(srchTxt?.toLowerCase()) ||
                            ius?.name?.en?.toLowerCase()?.includes(srchTxt?.toLowerCase())
                        )
                      );
                      if (matchingIus.length > 0) {
                        const targetCopy = { ...target, iusList: matchingIus };
                        matchingSubgroups.push(targetCopy);
                      }
                    });
              
                    if (matchingSubgroups.length > 0) {
                      const itemCopy = { ...item, target: matchingSubgroups };
                      matchedItems.push(itemCopy);
                    }
                  }
                }
              });

            console.log(fd,"matchedItems")


            this.setState({
                searchText: srchTxt,
                fIndicatorData: {goal : matchedItems, sector: matchedItems},
                fTimeperiod: fTP,
                fSource: fSrc
            });
            
        } else {
            this.setState({
                searchText: '',
                fIndicatorData: this.state.indicatorData,
                fTimeperiod: this.state.timePeriod,
                fSource: this.state.source,
                callData: this.state.callDataOG
            });
        }
      
    }

    searchData = (data, searchTerm, path = []) => {
        const matchingData = { ...data };
        const currentPath = [...path, matchingData.label];
    
        if (data.children) {
          const matchingChildren = data.children.map((child, index) => {
            const childPath = [...currentPath, `children[${index}]`];
            return this.searchData(child, searchTerm, childPath);
          });
          matchingData.children = matchingChildren.filter(
            (child) => child !== null
          );
        }
    
        if (matchingData.label.toLowerCase().includes(searchTerm)) {
          return matchingData;
        }
    
        return matchingData.children && matchingData.children.length > 0
          ? matchingData
          : null;
      };

    filterData = (data, searchTerm) => {
        const filteredData = { ...data };
        filteredData.children = this.filterChildren(data.children, searchTerm);
        return filteredData;
      }

    filterChildren = (children, searchTerm) => {
        return children
          .map(child => {
            const filteredChild = { ...child };
            if (child.children && child.children.length > 0) {
              filteredChild.children = this.filterChildren(child.children, searchTerm);
            }
            return filteredChild;
          })
          .filter(child => child.label.toLowerCase().includes(searchTerm.toLowerCase()));
      };

      
    
    
    
    handletabSelect(key) {
        let k = key;
        if (this.state.selSource?.length == 0) {
            k = 'source'
        }
        if (this.state.selTimeperiod?.length == 0) {
            k = 'timePeriod'
        }
        if (this.state.areaList?.length == 0) {
            k = 'area'
        }
        if ((Object.keys(this.state.selIUSdata)?.length == 0)) {
            k = 'indicator'
        }
    
        if (this.steps[key] < this.steps[k]) {
            k = key;
        } else if(k == 'area') {
            this.setState({ loader: true });
            let d = this.state.selIUSdata;
            let iusIds = [];
            Object.entries(d).forEach(([key, value]) => {
               
                if( value != true) {
                    iusIds.push(value.ius_id);
                }
            });

            let iusIdNv = iusIds.filter(id => id !== null);

            let dataToSend = {
               "ius_id": iusIdNv
            };
            
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                   
                    let validAreaCodes = res.data?.[0]?.area_code;
                    let validTPs = res.data?.[0]?.time_period;
                    let validSrc = res.data?.[0]?.source;                    
                    this.setState({ validAreaCodes: validAreaCodes, 
                                    validTPs: validTPs, 
                                    validSrc: validSrc, 
                                    loader: false });
                                    
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if(k == 'timePeriod') {
            this.setState({ loader: true });
            let d = this.state.selIUSdata;
            let iusIds = [];
            Object.entries(d).forEach(([key, value]) => {
               
                if( value != true) {
                    iusIds.push(value.ius_id);
                }
            });

            let iusIdNv = iusIds.filter(id => id !== null);

            let dataToSend = {
               ius_id: iusIdNv,
               area: this.state.areaList
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                    let validTPs = res.data?.[0]?.time_period;
                    let validSrc = res.data?.[0]?.source;                    
                    this.setState({ validTPs: validTPs, 
                                    validSrc: validSrc, 
                                    loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if(k == 'source') {
            this.setState({ loader: true });
            let d = this.state.selIUSdata;
            let iusIds = [];
            Object.entries(d).forEach(([key, value]) => {
               
                if( value != true) {
                    iusIds.push(value.ius_id);
                }
            });

            let iusIdNv = iusIds.filter(id => id !== null);

            let dataToSend = {
               ius_id: iusIdNv,
               area: this.state.areaList,
               timeperiod: this.state.selTimeperiod.filter(tp=> tp != '*')
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                    let validSrc = res.data?.[0]?.source;                    
                    this.setState({ validSrc: validSrc, 
                                    loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if (k == 'viewdata') {
            this.setState({ loader: true });
          
            let d = this.state.selIUSdata;
            let iusIds = [];
            Object.entries(d).forEach(([key, value]) => {
               
                if( value != true) {
                    iusIds.push(value.ius_id);
                }
            });

            let iusIdNv = iusIds.filter(id => id !== null);
    
            let dataToSend = {
                
                "ius_id": iusIdNv,
                "area": this.state.areaList,
                "timeperiod": this.state.selTimeperiod.filter(tp=> tp != '*'),
                "source": this.state.selSource.filter(tp=> tp != '*'),
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-chart-data', dataToSend).then((res) => {
                let data = res?.data;
                let results = [];
                let index = 0;
                data.forEach(e => {
                    e?.data?.forEach(x => {
                            x.forEach(d => {
                                results.push({
                                    id: index++,
                                    area: d?.areaDetails?.name?.['en'],
                                    area_code: d?.areaDetails?.area_code,
                                    area_level: d?.areaDetails?.level,
                                    parent_id: d?.areaDetails?.parent_id,
                                    indicator:d?.indicator?.['en'],
                                    unit: d?.unit?.['en'],
                                    subgroup: d?.subgroupDetails?.name?.['en'],
                                    timeperiod: d?.time_period?.start_time_period,
                                    datavalue: (+d?.new_value),
                                    source: d?.source.publisher,
                                    element: d,
                            });
                        });
                    });
                    
                });
                this.setState({ results: results, loader: false, resultsData: data });
            }).catch((err) => {
                this.setState({ loader: false });
                console.log(err);
            });
        } 
        this.setState({ key: k });
        this.onSearch('');
    }
    
    componentDidMount() {

        this.getData();

        $('.caret').on('click', function () {
            $(this).parent('li').children('.nested').toggleClass('active')
            $(this).toggleClass('caret-down')
        });

        this.handletabSelect(this.state.key)

        $('body').removeClass('sdg_list');
    }

    componentDidUpdate() {
        $('.dcTreeView > li > span.caret').parent('li').children('.nested').addClass('active')
        $('.dcTreeView > li > span.caret').addClass('caret-down'); 
        $('.caret').off('click');
        $('.caret').on('click', function () {
            $(this).parent('li').children('.nested').toggleClass('active')
            $(this).toggleClass('caret-down')
        });      
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps?.lang) {
    //         this.handletabSelect(this.state.key)
    //     }
    // }

    getData = () => {
        this.setState({ loader: true });
        AdminService.httpNoAuthGet('/data-retrieval/data/sector-wise-without-disaster/2').then((res) => {
            if (res) {
                let x = JSON.parse(JSON.stringify(this.state.indicatorData));
                x['goal'] = res.sectorIus;
                let flag = this.state.isDisabled;
                if(res.sectorIus?.length > 0) {
                    flag = false;
                }
                this.setState({ indicatorData: x, fIndicatorData: x, loader: false, isDisabled: flag });
            }
        })
        .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
        });

        AdminService.httpNoAuthGet('/data-retrieval/data/sector-wise-without-disaster/1').then((res) => {
            if (res) {
                let x = JSON.parse(JSON.stringify(this.state.indicatorData));
                x['sector'] = res.sectorIus;
                let flag = this.state.isDisabled;
                if(res.sectorIus?.length > 0) {
                    flag = false;
                }
                this.setState({ indicatorData: x, fIndicatorData: x, loader: false, isDisabled: flag });
            }
        })
        .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
        });

        let dataToSend = {};
        AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
            if (res) {
                
                let iuIds = res.data?.[0]?.iu_id;
                let iusIds = res.data?.[0]?.ius_id;
                this.setState({ iuIds: iuIds, iusIds: iusIds });               
            }
        })
        .catch((err) => {
            console.log(err)
        });
      
        AdminService.httpGet("/data-retrieval/generic/get-data/datasource")
        .then((res) => {
          if (res) {
            
            let d = res?.data;
  
            this.setState({ fSource: d, source: d, loader: false });
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          console.log(err);
        });

        AdminService.httpGet('/data-retrieval/generic/get-data/time').then((res) => {
            if (res) {
               
                let d = res?.data;

                this.setState({ timePeriod: d, fTimeperiod: d,loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    

      


        AdminService.httpGet('/data-retrieval/generic/get-data/time').then((res) => {
            if (res) {
               
                let d = res?.data;

                this.setState({ filteredData: d, data: d,loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {
                let d = res.data;
                let flatLevel = [];
                if (d?.length > 0) {

        

                    d?.forEach((item) => {

                        if ('TTO' == item.area_code) {
                            item.areaSubData = [];
                            item['level'] = 1;
                            item['parent_id'] = '';
                        }

                        var obj = {
                            value: item.area_code,
                            label: item?.name_all?.['en'],
                            parent: item.parent_id,
                            level: item.level

                        }

                        flatLevel.push(obj)
                    })

                    var data = this.setLevelData(flatLevel);
                  
                }

                this.setState({ mainAreaData: d, callData: data, callDataOG: data, flatLevel: flatLevel });
            }
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
        
    }

    selectAllChildren(n, aList, newA, chkd) {
        if(this.state.validAreaCodes?.includes(n.value)) {
            newA.push(n.value);
            chkd[n.value] = true;
            if (!aList.includes(n.value)) {
                aList.push(n.value);
            }
        }                
        return [aList, newA, chkd];
    }




    // if (area_code === '#' && e.target.checked) {
    //     let newA = [];
    //     [aList, newA, chkd] = this.selectAllChildren(node, aList, newA, chkd);
    //     node.children.forEach(n => {
    //         [aList, newA, chkd] = this.selectAllChildren(n, aList, newA, chkd);
    //     });
    //     chkd["selectall" + node.level] = true;
    //     chkd["#"] = true;
    //     chkd[node.value] = true;
    // } else if (area_code === '#' && !e.target.checked) {
    //     // If the "select all" checkbox is unchecked, uncheck all other checkboxes
    //     aList.forEach(item => {
    //         chkd[item] = false;
    //     });
    // }
    
    areaSelect = (e, node, area_code, parent) => {
        let aList = this.state.areaList;
        let chkd = this.state.checked;
        if(area_code == '#' && e.target.checked) {
            let newA = [];
            [aList, newA, chkd] = this.selectAllChildren(node, aList, newA, chkd);
            node.children.forEach(n => {
                [aList, newA, chkd] = this.selectAllChildren(n, aList, newA, chkd);
            })
            chkd["selectall" + node.level] = true;           
            chkd["#"] = true;            
            chkd[node.value] = true; 
            
        } else if(area_code == '#') {
            aList = [];
            chkd = {};
        } else if (area_code == '*' && e.target.checked) {
            let newA = [];
            
            node.children.forEach(n => {
                [aList, newA, chkd] = this.selectAllChildren(n, aList, newA, chkd);
            })
            chkd["selectall" + node.level] = true;
            
        } else if (area_code == '*') {
            let newA = [];
            node.children.forEach(n => {
                newA.push(n.value);
                chkd[n.value] = false;
            })
            aList = aList.filter(x => !newA.includes(x));
            chkd["selectall" + node.level] = false;
            chkd["#"] = false;
        } else if (e.target.checked) {
            if (!aList.includes(area_code)) {
                aList.push(area_code);
            }
            chkd[area_code] = true;
            if (parent?.children?.filter(c => !aList.includes(c.value)).length == 0) {
                chkd["selectall" + parent.level] = true;
            }
            chkd[area_code] = true;
        } else {
            aList = aList.filter(x => x != area_code);
            chkd[area_code] = false;
            chkd["selectall" + parent?.level] = false;
            chkd["#"] = false;
        }

        let aArrs = [];
        aList.forEach(areaCde => {
            let code = areaCde;
            
            let areaArr = [];
            do {
                let n = this.state.flatLevel.filter(x => x.value == code);

                if (n?.length > 0) {
                    areaArr.push(n[0]?.label);
                    code = n[0]?.parent;
                } else {
                    code = '';
                }

            } while (code != '')
            aArrs.push(areaArr.reverse());
        })
        this.setState({ areaList: aList, 
                        areaLevel: aArrs, 
                        checked: chkd, 
                        selTimeperiod: [],
                        selSource: []
                    });
    }

    rendertree(node, parent = null) {
        if (node) {
            return (
                <li className={node?.children?.length > 0 ? '' : 'nosubgroup'}>
                    {
                        node?.children && node?.children?.length > 0 &&
                        (<span class="caret">
                            <img src="assets/lib/images/arrow_new.svg" />
                        </span>)
                    }

                    <div className='dcTreeViewLabel '>
                        <div className={this.state.validAreaCodes?.includes(node?.value)?'d-flex align-items-center dataSearchFormList':
                                                                                        'disable-sector d-flex align-items-center dataSearchFormList'}>
                            <input type="checkbox" className='d-block'
                                checked={this.state.checked[node?.value] ? 'checked' : ''}
                                id={node?.value}
                                name={node?.value}
                                onChange={(e) => this.areaSelect(e, node, node?.value, parent)}
                            />
                            <label for={node?.value}>{node?.label}</label>
                        </div>
                        
                    </div>
                    {
                        node?.children && node?.children?.length > 0 &&
                        (
                            <ul class="dcTreeViewText nested">
                                <li className="li-before-selectAll">
                                    <div className='d-flex align-items-center dataSearchFormList'>
                                        <input type="checkbox" className='d-block'
                                            checked={this.state.checked["selectall" + node.level] ? 'checked' : ''}
                                            id={"selectall" + node.level}
                                            name={"selectall" + node.level}
                                            onChange={(e) => this.areaSelect(e, node, '*', parent)}
                                        />
                                        
                                        <label for={"selectall" + node.level}>Select All</label>

                                    </div>
                                    
                                </li>
                                {
                                    node?.children?.map(child => {
                                        return this.rendertree(child, node)
                                    })
                                }

                            </ul>
                        )
                    }
                </li>
            );
        } else {
            return (<></>)
        }

    }

    renderselected() {
        return (
            <>
                <div className='disasterSelected_scroll'>
                   
                    <div className='disasterSelected_List'>
                        <h5>Indicator</h5>
                     
                        <ul>
                            {
                                Object.entries(this.state.selIUSdata)?.map(([key, ius]) => {
                                    
                                    return (
                                    !(ius == true) && !key.endsWith('#') &&
                                        <li>{ius.ind_name} <span>| {ius.unit}</span> <span>| {ius.subgroup_name}</span></li>
                                    );
                                })
                               
                            }
                           
                        </ul>
                    </div>
                    
                    <div className='disasterSelected_List'>
                        <h5>Area</h5>
                       
                        {
                            this.state.areaLevel?.map(a => {
                                return (
                                    <ul className='dsl_breadcrumb'>
                                        {
                                            a?.map(x => {
                                                
                                                return (<li>{x}</li>);
                                            })
                                        }
                                         
                                    </ul>
                                );
                            })
                        }
                    </div>
                    <div className='disasterSelected_List'>
                        <h5>Time Period</h5>
                     
                        <ul>
                        {

                            this.state.selTimeperiod.filter(tp => tp != '*').map(t=>{
                                return(<li>{t}</li>);
                            })

                         }
                            
                        </ul>
                    </div>
                    <div className='disasterSelected_List'>
                        <h5>Source</h5>
                     
                        <ul>
                            {
                                this.state.selSource.filter(tp => tp != '*').map(s=>{
                                    return(<li>{s}</li>)
                                })
                            } 
                        </ul>
                    </div>
                   
                    
                </div>
            </>
        )
    }

    setLevelData = (flat) => {

        var roots = [];

        var all = {};

        flat.forEach(function (item) {

            all[item.value] = item;
        });


        Object.keys(all).forEach(function (value) {
            var item = all[value];
            if (item.parent === null || item.parent === "") {

                roots.push(item);
            } else if (item.parent in all) {
                var p = all[item.parent];
                if (!("children" in p)) {

                    p.children = [];

                }
                p.children.push(item);
            }
        });


        return roots;
    };
   
    handleChange = (e, type) => {
        this.setState({goalOrSector: type});
    }

    selectTP(e, timeperiod) {
        const { selTimeperiod, fTimeperiod, validTPs } = this.state;
        if (timeperiod === "*") {
            if (e.target.checked) {
                const tps = this.state.fTimeperiod?.map(t => t.timeperiod);
                const allTimeperiods = tps.filter(t=> validTPs.includes(t));
                const updatedTimeperiods = [...allTimeperiods, '*'];
                this.setState({ selTimeperiod: updatedTimeperiods });
            }  else {
                this.setState({ selTimeperiod: []});
            }
        } else {
            if(!validTPs?.includes(timeperiod)) {
                e.preventDefault();
                return;
            }
            if (e.target.checked) {
                selTimeperiod.push(timeperiod);
                if(validTPs.length == selTimeperiod.length) {
                    selTimeperiod.push('*');
                }
                this.setState({selTimeperiod: selTimeperiod});
            } else {
                const updatedSelTimeperiod = selTimeperiod.filter(tp => tp !== timeperiod && tp !== '*');
                this.setState({ selTimeperiod: updatedSelTimeperiod });
            }
        }
      }

      selectSource(e, source) {
        const { selSource, fSource, validSrc } = this.state;
        if (source === "*") {
          if (e.target.checked) {
            const allSource = validSrc;
            this.setState({ selSource: [...allSource,'*'] });
          } else {
            this.setState({ selSource: [] });
           
          }
        } else {
            if(!validSrc?.includes(source)) {
                e.preventDefault();
                return;
            }
            if (e.target.checked) {
                selSource.push(source);
                if(validSrc.length == selSource.length) {
                    selSource.push('*');
                }
                this.setState({selSource: selSource});
            } else {
                const updatedSelSource = selSource.filter(s => s !== source && s !== '*');
                this.setState({ selSource: updatedSelSource });
            }
        }
        
      }
     
      

    // selectIUS = (e, row, ind, subgroup) => {
        
    //     if (row == '*' && e.target.checked) {
    //         let d = this.state.fIndicatorData;
    //         console.log("ddddd data", this.state.fIndicatorData);
    //         let sd = {};
    //         for (let i = 0; i < d.length; i++) {
    //             let iu = d[i].iusList?.filter(iu => this.state.iuIds.includes(iu._id));
    //             for (let k = 0; k < iu?.length; k++) {
    //                 sd[iu[k]._id + '#'] = true;
    //                 let sg = iu[k].subgroup?.filter(ius => this.state.iusIds.includes(ius._id));
    //                 for (let j = 0; j < sg.length; j++) {
    //                     sd[iu[k]._id + sg[j].name?.['en']] = {
    //                         iu_id: iu[k]._id,
    //                         ind_name: iu[k].name?.['en'],
    //                         unit: iu[k].unit?.['en'],
    //                         subgroup_name: sg[j].name?.['en'],
    //                         ius_id: sg[j]._id
    //                     };
    //                 }
    //             }
    //         }
    //         sd['*'] = true;
          
    //         this.setState({ selIUSdata: sd });
    //     } else if (row == '*') {
    //         this.setState({ selIUSdata: {} });
    //     } else if (row == '#' && e.target.checked) {
          
    //         let sd = this.state.selIUSdata;
    //         ind?.subgroup?.forEach(ius => {
    //             if(this.state.iusIds.includes(ius._id)) {
    //                 sd[ind._id + ius.name?.['en']] = {
    //                     iu_id: ind._id,
    //                     ind_name: ind.name?.['en'],
    //                     unit: ind.unit?.['en'],
    //                     subgroup_name: ius.name?.['en'],
    //                     ius_id: ius._id
    //                 };
    //             }                
    //         })
            
    //         sd[ind._id+'#'] = {
    //             iu_id: ind._id,
    //             ind_name: ind.name?.['en'],
    //             unit: ind.unit?.['en'],
    //             subgroup_name: null,
    //             ius_id: null
    //         };

            
    //         this.setState({ selIUSdata: sd });
    //     } else if (row == '#') {
            
    //         let sd = this.state.selIUSdata;
    //         ind?.subgroup?.forEach(ius => {
    //             if(this.state.iusIds.includes(ius._id)) {
    //                 delete sd[ind._id + ius.name?.['en']];
    //             }
    //         })
            
    //         delete sd[ind._id+'#'];
    //         if('*' in sd) {
    //             delete sd['*'];
    //         }

          
    //         this.setState({ selIUSdata: sd });
    //     } else if (e.target.checked) {
    //         let sd = this.state.selIUSdata;
    //         sd[ind._id + subgroup.name?.['en']] = {
    //             iu_id: ind._id,
    //             ind_name: ind.name?.['en'],
    //             unit: ind.unit?.['en'],
    //             subgroup_name: subgroup.name?.['en'],
    //             ius_id: subgroup._id
    //         };
    //         let flag = true;
    //         ind?.subgroup?.forEach(ius => {
    //             if(this.state.iusIds.includes(ius._id) && !((ind._id + ius.name?.['en']) in sd) ) {
    //                 flag = false;
    //             }
    //         });
    //         if(flag) {
    //             sd[ind._id+'#'] = {
    //                 iu_id: ind._id,
    //                 ind_name: ind.name?.['en'],
    //                 unit: ind.unit?.['en'],
    //                 subgroup_name: null,
    //                 ius_id: null
    //             };  
    //         }
            
           
    //         this.setState({ selIUSdata: sd });
    //     } else {
    //         let sd = this.state.selIUSdata;
    //         delete sd[ind._id + subgroup.name?.['en']];
    //         if((ind._id+'#') in sd) {
    //             delete sd[ind._id+'#'];
    //         }            
    //         if('*' in sd) {
    //             delete sd['*'];
    //         }
    //         this.setState({ selIUSdata: sd });

           
    //     }

    //     this.setState({
    //         areaList: [],
    //         areaLevel: [], 
    //         checked: {}, 
    //         selTimeperiod: [],
    //         selSource: []
    //     });
    // }
    selectIUS = (e, row, ind, subgroup) => {
        
        if (row == '*' && e.target.checked) {
            let d = this.state.fIndicatorData[this.state.goalOrSector];
            // console.log("fIndicatorData", this.state.fIndicatorData);
            let sd = {};
            for (let i = 0; i < d.length; i++) {
                for(let l=0; l<d[i].target?.length; l++) {
                    let iu = d[i].target[l].iusList?.filter(iu => this.state.iuIds.includes(iu._id));
                    for (let k = 0; k < iu?.length; k++) {
                        sd[iu[k]._id + '#'] = true;
                        let sg = iu[k].subgroup?.filter(ius => this.state.iusIds.includes(ius._id));
                        for (let j = 0; j < sg.length; j++) {
                            sd[iu[k]._id + sg[j].name?.['en']] = {
                                iu_id: iu[k]._id,
                                ind_name: iu[k].name?.['en'],
                                unit: iu[k].unit?.['en'],
                                subgroup_name: sg[j].name?.['en'],
                                ius_id: sg[j]._id
                            };
                        }
                    }
                }                
            }
            sd['*'] = true;
            this.setState({ selIUSdata: sd });
        } else if (row == '*') {
            this.setState({ selIUSdata: {} });
        } else if (row == '#' && e.target.checked) {
          
            let sd = this.state.selIUSdata;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id)) {
                    sd[ind._id + ius.name?.['en']] = {
                        iu_id: ind._id,
                        ind_name: ind.name?.['en'],
                        unit: ind.unit?.['en'],
                        subgroup_name: ius.name?.['en'],
                        ius_id: ius._id
                    };
                }                
            })
            
            sd[ind._id+'#'] = {
                iu_id: ind._id,
                ind_name: ind.name?.['en'],
                unit: ind.unit?.['en'],
                subgroup_name: null,
                ius_id: null
            };

            
            this.setState({ selIUSdata: sd });
        } else if (row == '#') {
            
            let sd = this.state.selIUSdata;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id)) {
                    delete sd[ind._id + ius.name?.['en']];
                }
            })
            
            delete sd[ind._id+'#'];
            if('*' in sd) {
                delete sd['*'];
            }

          
            this.setState({ selIUSdata: sd });
        } else if (e.target.checked) {
            let sd = this.state.selIUSdata;
            sd[ind._id + subgroup.name?.['en']] = {
                iu_id: ind._id,
                ind_name: ind.name?.['en'],
                unit: ind.unit?.['en'],
                subgroup_name: subgroup.name?.['en'],
                ius_id: subgroup._id
            };
            let flag = true;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id) && !((ind._id + ius.name?.['en']) in sd) ) {
                    flag = false;
                }
            });
            if(flag) {
                sd[ind._id+'#'] = {
                    iu_id: ind._id,
                    ind_name: ind.name?.['en'],
                    unit: ind.unit?.['en'],
                    subgroup_name: null,
                    ius_id: null
                };  
            }
            this.setState({ selIUSdata: sd });
        } else {
            let sd = this.state.selIUSdata;
            delete sd[ind._id + subgroup.name?.['en']];
            if((ind._id+'#') in sd) {
                delete sd[ind._id+'#'];
            }            
            if('*' in sd) {
                delete sd['*'];
            }
            this.setState({ selIUSdata: sd });
        }

        this.setState({
            areaList: [],
            areaLevel: [], 
            checked: {}, 
            selTimeperiod: [],
            selSource: []
        });
    }

    render() {
        return jsx.default.bind(this)();
    }
}

function mapStateToProps(state) {
    return {
        lang: state?.lang?.lang
    }
}

export default connect(mapStateToProps)(DataSearchComponent);