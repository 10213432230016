import React, { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const TimePeriodAddModal = (props) => {
    const form = useRef();

    const timeFormatRef = useRef();

    const [timeFormat, setTimeFormat] = useState('');
    const [timePeriod, setTimePeriod] = useState('');
    const [dataID, setDataID] = useState(null);

    useEffect(() => {

    });


    useEffect(() => {
        if (props.isEdit == true) {
            console.log("props in editing-", props.editData)
            getEditData(props.editData[0]);
        } else {
            setTimePeriod('')
            setTimeFormat('')
            setDataID(null)
            return false;
        }
    }, [props.isEdit, props.editData]);




    const getEditData = (data) => {
        console.log('data', data)
        console.log("======>>>", data)
        if (data != null) {
            setDataID(data['_id'])
            setTimePeriod(data['timeperiod'])
            setTimeFormat(data['timeformat'])
        }
    };


    const onDataSubmit = (e) => {
        e.preventDefault();
        console.log("This is e of data submitting-", e)

        let dataToSend = [];

        dataToSend = {
            id: dataID,
            time_period: timePeriod,
            time_format: timeFormat
        };
        props.newDataEntry(dataToSend);

    };

    // form submit function end============//

    const cancelModal = (event) => {
        event.preventDefault();
        props.closeModal(event.target);
    };
    // ====================================================== Form Validation Start

    // ====================================================== Form Validation End

    const timePeriodFormat = [{
        'format': 'YYYY',
        'index': 1,
        // 'regex': '^\\d{4}$'
        'regex': '^[0-9]{4}$'
    },
    {
        'format': 'YYYY.MM',
        'index': 2,
        // 'regex': '^\\d{4}\\.\\d{2}$'
        'regex': '^[0-9]{4}\.(0[1-9]|1[0-2])$'
    },
    {
        'format': 'YYYY.MM.DD',
        'index': 3,
        // 'regex': '^\\d{4}\\.\\d{2}\\.\\d{2}$'
        'regex': '^(?:[0-9]{4}\.(?:(?:0[1-9]|1[0-2])\.(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])\.(?:29|30)|(?:0[13578]|1[02])\.31))$' //This regex allows the user to enter till maximum number of days a month have. Example: Till 28 is allowed to enter in month february.
    },
    {
        'format': 'YYYY - YYYY',
        'index': 4,
        // 'regex': "^\\d{4} - \\d{4}$"
        'regex': "^[0-9]{4}-[0-9]{4}$"
    },
    {
        'format': 'YYYY.MM - YYYY.MM',
        'index': 5,
        // 'regex': "^\\d{4}\\.\\d{2} - \\d{4}\\.\\d{2}$"
        'regex': "^[0-9]{4}\.(0[1-9]|1[0-2])-[0-9]{4}\.(0[1-9]|1[0-2])$"
    },
    {
        'format': 'YYYY.MM.DD - YYYY.MM.DD',
        'index': 6,
        // 'regex': "^\\d{4}\\.\\d{2}\\.\\d{2} - \\d{4}\\.\\d{2}\\.\\d{2}$"
        'regex': "^(?:[0-9]{4}\.(?:(?:0[1-9]|1[0-2])\.(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])\.(?:29|30)|(?:0[13578]|1[02])\.31)|[0-9]{2}(?:0[48]|[2468][048]|[13579][26])-(?:0[48]|[2468][048]|[13579][26]))-[0-9]{4}\.(?:(?:0[1-9]|1[0-2])\.(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])\.(?:29|30)|(?:0[13578]|1[02])\.31)$"  //This regex allows the user to enter till maximum number of days a month have. Example: Till 28 is allowed to enter in month february.
    }]

    // const handleSelectChangeTimeFormat = (event) => {
    //     setTimeFormat(event.target.value);
    //     // Reset the timePeriod when the time format changes
    //     setTimePeriod(null);
    // };

    // const handleTimePeriodChange = (event) => {
    //     setTimePeriod(event.target.value);
    // };

    // const getRegex = () => {
    //     let dateFormat = timePeriodFormat.find((x) => x.format == timeFormat);
        
    //     return dateFormat?.regex?.toString();

    // }
    const handleSelectChangeTimeFormat = (event) => {
        setTimeFormat(event.target.value);
        // Reset the timePeriod when the time format changes
        setTimePeriod('');
      };


        const handleTimePeriodChange = (event) => {
            setTimePeriod(event.target.value);
        };

        const getRegex = () => {
        const dateFormat = timePeriodFormat.find((x) => x.format === timeFormat);
        return dateFormat ? new RegExp(dateFormat.regex) : null;
        };
    
        const isTimePeriodValid = () => {
            const regex = getRegex();
            return regex ? regex.test(timePeriod) : true; 
        };

        const isAddButtonDisabled = !timePeriod;

    return (
        <>
            <div className="user-form time-period">
                <ValidatorForm
                    className="main_react_form w-100"
                    ref={form}
                    onSubmit={(e) => { onDataSubmit(e) }}
                    id="addUserForm"
                >
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        {/* <form onSubmit={onDataSubmit} className="data_entry_form" id="dataEntryForm"> */}
                        <div className="gallery-collections mt-0">
                            <div className="row align-items-center mb-30">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Time Period Format</label>
                                            </span>
                                            <Select
                                                ref={timeFormatRef}
                                                id="psurvey"
                                                name="psurvey"
                                                value={timeFormat}
                                                onChange={handleSelectChangeTimeFormat}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            >
                                                {timePeriodFormat?.map((item) => (
                                                    <MenuItem key={item?.index} value={item?.format}>
                                                        {item?.format}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="inPuthidden">
                                                <TextValidator
                                                    type="hidden"
                                                    value={timeFormat}
                                                    style={{ border: "0px", outline: "none" }}
                                                    name="psurvey"
                                                    autoComplete="off"
                                                    className=" w-100"
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style datePicker aggregateDate">
                                        <div className="mat-form-field-wrapper">
                                        <span className="mat-form-field-label-wrapper">
                                        <label>Time Period</label>
                                        </span>
                                            
                                       
                                            <div>
                                              
                                              
                                            <input
                                                type="text"
                                                value={timePeriod}
                                                onChange={handleTimePeriodChange}
                                                pattern={getRegex() ? getRegex().source : ''}
                                                required
                                                disabled={!timeFormat}
                                                />
                                                {!isTimePeriodValid() && (
                                                <p className="error-msg" style={{ color: 'red' }}>{timePeriod ? `Please enter a time period in the format: ${timeFormat}` : ''}</p>
                                                )}
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button
                                    variant="contained"
                                    onClick={cancelModal}
                                    className="cancelbtn"
                                >
                                    Cancel
                                </Button>
                                <Button variant="contained" className="addbtn" type="submit"  disabled={isAddButtonDisabled}>
                                    {props.isEdit ? "Update" : "Add"}<img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg"/>
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>
        </>
    );
};

export default TimePeriodAddModal;
