import React from "react";
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import AddGoalsModal from "../../Modals/addGoals.modal";

var manageGoalsTemplate = function () {
  // console.log("this" , this.state.filteredData);

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let userRole = userInfo?.role;

  const rotateIcon = event => {
    event.currentTarget.classList.toggle('rotate');

    this.setState(prevState => {
        const sortedList = [...prevState.targetList].sort((a, b) => {
            // Extract the X and Y values from "Target X.Y ..."
            const [aMajor, aMinor] = a.targetname.replace('Target ', '').split(' ')[0].split('.');
            const [bMajor, bMinor] = b.targetname.replace('Target ', '').split(' ')[0].split('.');

            if (prevState.isAscending) {
                // First, compare the major part (X)
                if (aMajor !== bMajor) return parseInt(aMajor) - parseInt(bMajor);
                // If major parts are the same, compare the minor part (Y)
                return parseInt(aMinor) - parseInt(bMinor);
            } else {
                // For descending order
                if (aMajor !== bMajor) return parseInt(bMajor) - parseInt(aMajor);
                return parseInt(bMinor) - parseInt(aMinor);
            }
        });

        return {
            targetList: sortedList,
            isAscending: !prevState.isAscending
        };
    });
}



  return (
    <>
      <div className="main" id="main">
        <div class="container-fluid areaCont">
          <section className="areaSection" id="areaSection">
            <subHeaderComponentMemo.default
              PropDeleteBulk={this.deleteBulkUser}
              value={32}
              onSearch={this.onSearch}
              searchText={this.state.searchText}
              nRows={this.state.filteredData?.length}
              checkData={this.state.bulkDelete}
            />
            <DataTable
              columns={this.columns}
             
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              persistTableHead
              selectableRows
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className="dataTableScroll"
              noDataComponent="No Data"
              fixedHeader
            />
          </section>
        </div>
      </div>
      {/* <AddUserModal /> */}

      {/* modal area begin here============== */}
      <div
        id="AddAreaModal"
        className={
          this.state.modal
            ? "gallery-sidebar add-area-modal add-form width350"
            : "gallery-sidebar add-area-modal add-form width0"
        }
      >
        <div
          id="openDataModal"
          onClick={this.openAddModal}
          ariaTooltip="Button that displays and hides a tooltip triggered by other buttons"
          className=" gallery-toggler maroon_bg add-user "
          style={{
            touchAction: "none",
            userSelect: "none",
            WebkitUserDrag: "none",
            WebkitTopHighlightColor: "rgba(0, 0, 0, 0)",
          }}
          aria-describedby="cdk-describedby-message-38"
          cdk-describedby-host=""
        >
          <img src="assets/lib/images/svg/add1.svg" style={{ width: "17px" }} />
        </div>
        <div class="right-tab-heading are_head">
          <h2 class="form-heading">
            <span class="form-heading_he">
              <span class="ddf">
                {this.state.isEdit == true
                  ? "Edit Goal and Target"
                  : "Add Goal and Target"}
              </span>
            </span>
          </h2>
        </div>

        <AddGoalsModal
          submitCallBack={this.saveUserData}
          isEdit={this.state.isEdit}
          editData={this.state.editModeId}
          closeModal={this.openAddModal}
          resetModal={this.state.modal}
        />
        <div
          className={
            this.state.loader
              ? "loader text-center "
              : "loader text-center hide"
          }
        >
          <img
            src="assets/images/Comp-2.gif"
            style={{ marginTop: "12%", width: "30%" }}
          />
        </div>
        {/* =======================modal manage goals=================== */}
       
      </div>
      <div
          className="modal fade"
          id="manage_goals"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title area_id_title" id="exampleModalLabel">Target ({this.state.targetList?.length}) <span className="sortingIcon" onClick={(event)=>{rotateIcon(event)}}></span></h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">
                    <img src="assets/lib/images/cross.svg" alt="" />
                  </span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="maxHeight">
                  <table className="table table-striped">
                    {/* <thead>
                      <tr className="">
                        <th className="b-none border-0 a">Target Name</th>
                      </tr>
                    </thead> */}

                    <tbody class="table_scroll manage_goals_tbody">
                      {this.state.targetList?.map((item) => {
                        return (
                          <>
                            <tr class="">
                              <td class="border-none">{item?.targetname}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className={this.state.modal ? "overlay dblock" : "overlay"}></div>
    </>
  );
};

export default manageGoalsTemplate;
