import React, {useState} from 'react'

import adminService from "../../services/admin.service";
import { toast } from "react-toastify";
import * as $ from "jquery";



 const ChangePassword = () => {

    const [state, setState] = useState({
        bottom: false,
        newPassword:'',
        confirmPassword:'',
        error: '',
    
      });

      const makeid = (length) => {
        var text = "";
        var possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
      };

    const handleChangePassword = (e) => {
        e.preventDefault();
    
        if (state.newPassword !== state.confirmPassword) {
          setState({ ...state, error: 'Passwords do not match' });
        } else if (state.newPassword.length <= 0) {
          setState({ ...state, error: 'Password is Required' });
        } else if (state.newPassword.length < 8) {
          setState({ ...state, error: 'Password should be at least 8 characters long' });
        } else if (!/[A-Z]/.test(state.newPassword)) {
          setState({ ...state, error: 'Password should contain at least one uppercase letter' });
        } else if (!/[!@#$%^&*]/.test(state.newPassword)) {
          setState({ ...state, error: 'Password should contain at least one special character (!@#$%^&*)' });
        }
        else {

          let newpasswordString = makeid(10) + state.newPassword;
          let npassword = btoa(newpasswordString);
          let conpasswordString = makeid(10) + state.confirmPassword;
          let cpassword = btoa(conpasswordString);
    
          const data = {
            "new_password": npassword,
            "confirm_password": cpassword
          }
    
          adminService.httpPost("/data-import/users/change-password", data).then((res)=>{
            if(res.success == true){
              toast.success("Password changed successfully!")
            } else{
              toast.error("Error occurred while changing the password")
            }
          })
    
          $("#change_password").modal("hide");
          
          setState({ ...state, newPassword: '',confirmPassword: '', error: '' });
        }
        
      };
    
      const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
      };


  return (
    <>
     <div
        className="modal fade"
        id="change_password"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src="assets/lib/images/cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  handleChangePassword(e);
                }}
              >
                <div className="form-group">
                  <div className="form-field">
                    <input
                      type="password"
                      name="newPassword"
                      value={state.newPassword}
                      className="form-control"
                      formcontrolname="password"
                      autoComplete="off"
                      placeholder="Enter New Password"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-field">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={state.confirmPassword}
                      className="form-control"
                      formcontrolname="password"
                      autoComplete="off"
                      placeholder="Re-type Password"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {state.error && <p style={{ color: "red" }}>{state.error}</p>}
                <div className="form-group">
                  <button type="submit">Change Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
