import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SDMXMappingModal = (props) => {
    const form = useRef()

    const [associatedsd, setAssociatedsd] = useState('');
    const handleAssociate = (event) => {
        setAssociatedsd(event.target.value);
    };
    
    return (
        <>
            <div className="area-form">
                <ValidatorForm className="main_react_form w-100" ref={form} >
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0">
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Name</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="name"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                <div className="form-group mb-0 inputform_style">
                                    <div className="mat-form-field-wrapper">
                                        <span className="mat-form-field-label-wrapper">
                                            <label className="form_lable">Associate Data Structure Definition *</label>
                                        </span>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={associatedsd}
                                            label="Age"
                                            onChange={handleAssociate}
                                            >
                                            <MenuItem value={10}>Associate DSD 1</MenuItem>
                                            <MenuItem value={20}>Associate DSD 2</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Associate Metadata Structure Definition</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="amsd"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" type="submit" className="addbtn">
                                Add <img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg"/>
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>            
        </>
    );

};

export default SDMXMappingModal;