import React from 'react';
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import TopicSubtopicModal from '../../Modals/topicSubtopic.modal';


var TopicSubtopicTemplate = function () {

  return (
    <>
      <div className="main" id="main">
      <div class="container-fluid areaCont">  
        <section className="areaSection" id="areaSection">
          <subHeaderComponentMemo.default
            PropDeleteBulk={this.deleteBulkUser}
            value={33}
            onSearch={this.onSearch}
            searchText={this.state.searchText}
            nRows={this.state.filteredData?.length}
            checkData = {this.state.bulkDelete}
          />
          <DataTable
            columns={this.columns}
              data={this.state.filteredData}
            direction="auto"
            fixedHeaderScrollHeight="500px"
            pagination
            paginationRowsPerPageOptions={[10, 25, 50,100]}
            style={{ display: "flex" }}
            // subHeader
            // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
            persistTableHead
            selectableRows
            onSelectedRowsChange={this.handleChange}
            clearSelectedRows={this.handleChangeData}
            className='dataTableScroll'
            noDataComponent="No Data"
            fixedHeader
          />
        </section>
        </div>
      </div>
      {/* <AddUserModal /> */}

      {/* modal area begin here============== */}
      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

<div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
        <img
          src="assets/lib/images/svg/add1.svg" alt="add-icon" style={{ 'width': '17px' }} />
      </div>
      <div class="right-tab-heading are_head">
        <h2 class="form-heading">
          <span class="form-heading_he">
            <span class="ddf">
              {this.state.isEdit===true ? 'Edit Sector and Sub-Sector': 'Add Sector and Sub-Sector'}
            </span>
          </span>
        </h2>
      </div>

      <TopicSubtopicModal
          parentCallback = {this.callBack} 
          editMode = {this.state.editModeId} 
          sectorSubsectorList = {this.state.sectorSubsectorList}
        
        />  
        <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
          <img src="assets/images/Comp-2.gif" alt="comp-2" style={{ 'marginTop': '12%', 'width': '30%' }} />
        </div>
        {/* =======================modal manage goals=================== */}
      

      </div>
      <div className="modal fade" id="subtopic_topic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document" >
            <div className="modal-content" >
              <div className="modal-header">
                <h5 className="modal-title area_id_title" id="exampleModalLabel" >Sub-Sector </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true" >
                    <img src="assets/lib/images/cross.svg" alt="" />
                  </span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className='modal-scroll-table'>
                  <table className="table table-striped">
                    {/* <thead >
                      <tr className="">
                        <th className="b-none border-0 a">Name</th>
                      </tr>
                    </thead> */}

                    <tbody class="table_scroll manage_goals_tbody">
                      {this.state.subTopicData?.subtopic?.map((ele) => {
                        return (
                          <tr key={ele?.subtopicname} class="">
                            <td class="border-none">{ele?.subtopicname}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>
   
    </>
  );
};

export default TopicSubtopicTemplate;
