import * as SearchComponent from './Search';

const subHeaderComponentMemo = ({checkData, PropDeleteBulk, onSearch, searchText, value, statusFilter, parentCallBack, parentCallBackApprove, nRows, currStatus, dataStatus, approveData ,parentCallBackDelete, download}) => { 
// console.log("table--"+value)



const parentCall = (e) => {

  parentCallBack(e)
}

const parentCallApprove = (e) => {

  parentCallBackApprove(e)
}

const deleteBulk = (e) => {
  PropDeleteBulk(e)
}

const parentCallDelete = (e) => {

  parentCallBackDelete(e)
}



    return (
        <>
          <SearchComponent.default mainCallBack = {parentCall} 
                                    mainCallBackApprove = {parentCallApprove} 
                                    deleteAllCallBack = {parentCallDelete}
                                    statusFilter={statusFilter} 
                                    onSearch={onSearch} 
                                    searchText={searchText} 
                                    value ={value} 
                                    className="form-control"
                                    nRows={nRows}
                                    currStatus={currStatus}
                                    dataStatus={dataStatus}
                                    deleteBulk={deleteBulk}
                                    checkData={checkData}
                                    approveData={approveData}
                                    download={download}
                                    />
        </>
    );

};



export default subHeaderComponentMemo;
