import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import * as subHeaderComponentMemo from '../../../../Common/components/TableSubHeader';
import ReceiverModal from "./receiverModal";
import SenderModal from "./senderModal";
import adminService from "../../../../services/admin.service";
import swal from "sweetalert";
import { toast } from "react-toastify";

const SenderReceiverComponent = (props) => {

    const [receiver,setReceiver] = useState([])
    const [sender,setSender] = useState([])
    const [senderForWhat,setSenderForWhat] = useState('')
    const [editForSender,setEditForSender] = useState('')
    const [editForReceiver,setEditForReceiver] = useState('')
    const [receiverForWhat,setReceiverForWhat] = useState('')
    const [receiverRowChecked,setReceiverRowChecked] = useState({})
    const [senderRowChecked,setSenderRowChecked] = useState({})
    const {bulkows, setBulkRows} = useState([])
    const {state, setState} = useState({
        searchText: '',
        data:  [{
            _id:"1",
            area_code:"ATLS",
            name:"test",
            level:"2",
            parent_id:"4343",
        
        
        }]
        ,
        filteredData:  [{
            _id:"1",
            area_code:"ATLS",
            name:"test",
            level:"2",
            parent_id:"4343",
        
        
        }]
        ,
        toggledClearRows: true,
        isEdit: false,
        loader: false,
        editEntry:[],

        isEdit1: false,
        loader1: false,
        editEntry1:[]
    });

    const handleChange = ( selectedRows ) => {
    console.log(selectedRows)
    
      };    


    ////////////////////==========serach funcation===============///////////////

    const onSearch = (val) => {
        setState({...state, searchText: val});
        let d = state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            
            fd = d.filter(
                item => true);
        }
        
        this.setState({...state, filteredData : fd});
    }

    const hanleSelectSenderRow = (e,id) => {
        if(e.target.checked){
            props.getSender(id)
        }else{
            props.getSender('')
        }
        let obj ={}
        obj[id] = e.target.checked
        setSenderRowChecked(obj)
    }

    const hanleSelectReceiverRow = (e,id) => {
        if(e.target.checked){
            props.getReceiver(id)
        }else{
            props.getReceiver('')
        }
        let obj ={}
        obj[id] = e.target.checked
        setReceiverRowChecked(obj)
    }

    const deleteSenderRow = async (e,id) => {
        e.preventDefault()

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              const dataToBeSend = {
                "sender": id
            }
      
               adminService.httpPost('/data-sdmx/sdmx/delete-sender',dataToBeSend).then((res) => {
                getSenderReceiverData()
               toast.success('Deleted')
            }).catch((err) => {
              console.log(err)
            })
            }
          });
    }

    const deleteReceiverRow = async (e,id) => {
        e.preventDefault()

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              const dataToBeSend = {
                "receiver": id
            }
      
               adminService.httpPost('/data-sdmx/sdmx/delete-receiver',dataToBeSend).then((res) => {
                getSenderReceiverData()
               toast.success('Deleted')
            }).catch((err) => {
              console.log(err)
            })
            }
          });
    }

    const columns = [
        {
            name: 'Select',
            selector: row => <><input type="checkbox" checked={senderRowChecked[row._id]} onChange={(e)=>hanleSelectSenderRow(e,row._id)}/></>,
            width: "100px",
        },
        {
            name: 'Sender ID',
            selector: row => row.sender_id,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Sender Name',
            selector: row => row.sender_name,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Name',
            selector: row => row.contact_name,
            sortable: true,
            width: "150px",
          
        },
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true,
            
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
            
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "150px",
            
        },
        {
            name: 'Phone',
            selector: row => row.telephone,
            sortable: true,
            
        },
        {
            name: 'Fax',
            selector: row => row.fax,
            sortable: true,
            
        },      
        {
            name: 'Action',
            width: "130px",
            maxWidth: "130px",
            cell: row => 
                <>
              
                {/* <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.deleteEntry(e, row.area_code)} href="javascript:;">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                      <div className='action_btns'>
                       <button onClick={(e) => {e.preventDefault(); openAddModal('Update',row)}}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                       <button onClick={(e)=> {deleteSenderRow(e,row._id)}}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    const columns2 = [
        {
            name: 'Select',
            selector: row => <><input type="checkbox" checked={receiverRowChecked[row._id]} onChange={(e)=>hanleSelectReceiverRow(e,row._id)}/></>,
            width: "100px",
        },
        {
            name: 'Receiver ID',
            selector: row => row.receiver_id,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Receiver Name',
            selector: row => row.receiver_name,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Name',
            selector: row => row.contact_name,
            sortable: true,
            width: "150px",
          
        },
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true,
            
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
            
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "150px",
            
        },
        {
            name: 'Phone',
            selector: row => row.telephone,
            sortable: true,
            
        },
        {
            name: 'Fax',
            selector: row => row.fax,
            sortable: true,
            
        },      
        {
            name: 'Action',
            width: "130px",
            maxWidth: "130px",
            cell: row => 
                <>
              
                {/* <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.deleteEntry(e, row.area_code)} href="javascript:;">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                      <div className='action_btns'>
                       <button onClick={(e) => {e.preventDefault(); openAddModal1('Update',row)}}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                       <button onClick={(e)=> {deleteReceiverRow(e,row._id) }}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    // =================================================================== First Modal Start
        const [modal, setModal] = useState(false)
        const openAddModal = (senderForWhat,row) => {
            setSenderForWhat(senderForWhat)
            setEditForSender(row)
            if (modal == false) {
                
                setModal(true)       
            }else{
                // setState({...state, editEntry: [], isEdit:false, editModeId:null})
                setModal(false)
            }
        }
 
     // =================================================================== First Modal End
    // =================================================================== First Modal Start
    const [modal1, setModal1] = useState(false)
    const openAddModal1 = (receiverForWhat,row) => {
        setReceiverForWhat(receiverForWhat)
        setEditForReceiver(row)
        if (modal1 == false) {
            
            setModal1(true)       
        }else{
            // setState({...state, editEntry: [], isEdit:false, editModeId:null})
            setModal1(false)
        }
    }

    // =================================================================== First Modal End
     
    const getSenderReceiverData = () =>{
        adminService.httpGet(`/data-sdmx/sdmx/get-all-user-info`).then((res)=>{
            console.log('senderreciever',res)

            if(res.status === 1){
                setReceiver(res.data[0].receiver)
                setSender(res.data[0].sender)
            }
       }).catch((err)=>{
        console.log(err)
       })
    }

    useEffect( ()=>{
        getSenderReceiverData()
    },[])
    
    return (
        <>
          
        <div className='areaCont h-100'>
          <section className='senderBox' id="senderBox">
            <div className="srFirst">
                <subHeaderComponentMemo.default
                onSearch={onSearch} 
                searchText={state?.searchText} 
                // PropDeleteBulk={}
                value='4' 
                checkData = {false}
                nRows={state?.filteredData?.length}
                />
                <DataTable
                columns={columns}
                data={sender}
                direction="auto"
                fixedHeaderScrollHeight="500px"
                pagination
                paginationRowsPerPageOptions={[10, 25, 50,100]}
                style={{ display: "flex" }}
                persistTableHead
                onSelectedRowsChange={handleChange}
                
                clearSelectedRows={state?.toggledClearRows}
                className='dataTableScroll'
                noDataComponent="No Data"
                />


                <div id="AddAreaModal" className={modal == true ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>
                    <div id="openDataModal" onClick={()=>{openAddModal()}} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} >
                        { console.log("AREA") }
                            <img
                                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
                            </div>
                            <div class="right-tab-heading are_head aaaa">
                            <h2 class="form-heading form-heading">
                                <span class="form-heading_he">
                                <span class="ddf">Sender Details</span>
                                </span>
                            </h2>
                            </div>
                        <SenderModal
                        senderForWhat={senderForWhat}
                        editData={editForSender}
                        closeModal={openAddModal}
                        getSenderReceiverData={getSenderReceiverData}
                        // parentCallback={state?.saveAreaData}
                        
                        // closeModal={openAddModal()}
                        // sendData={state?.data}
                        // isEdit = {state?.isEdit}
                        // editData = {state?.editEntry}

                        />
                        <div className={state?.loader ? 'loader text-center ' : 'loader text-center hide'}>
                            <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
                        </div>            
                </div>
                <div className={modal == true ? 'overlay dblock':'overlay'} ></div>
            </div>

            <div className="srSecond">
                <subHeaderComponentMemo.default
                    onSearch={onSearch} 
                    searchText={state?.searchText} 
                    // PropDeleteBulk={}
                    value='4' 
                    checkData = {false}
                    nRows={state?.filteredData?.length}
                    />
                <DataTable 
                    columns={columns2}
                    data={ receiver}
                    direction="auto"
                    fixedHeaderScrollHeight="500px"
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50,100]}
                    style={{ display: "flex" }}
                    persistTableHead
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={state?.toggledClearRows}
                    className='dataTableScroll receiver-table'
                    noDataComponent="No Data"
                />

                <div id="AddAreaModal1" className={modal1 == true ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>
                    <div id="openDataModal1" onClick={()=>{openAddModal1()}} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} >
                        { console.log("AREA") }
                            <img
                                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
                            </div>
                            <div class="right-tab-heading are_head aaaa">
                            <h2 class="form-heading form-heading">
                                <span class="form-heading_he">
                                <span class="ddf">Receiver Details</span>
                                </span>
                            </h2>
                            </div>
                        <ReceiverModal
                        receiverForWhat={receiverForWhat}
                        editData ={editForReceiver}
                        closeModal={openAddModal1}
                        getSenderReceiverData={getSenderReceiverData}
                        // parentCallback={state?.saveAreaData}
                        
                        // closeModal={openAddModal()}
                        // sendData={state?.data}
                        // isEdit = {state?.isEdit}
                        // editData = {state?.editEntry}

                        />
                        <div className={state?.loader1 ? 'loader text-center ' : 'loader text-center hide'}>
                            <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
                        </div>            
                </div>
                <div className={modal1 == true ? 'overlay dblock':'overlay'} ></div>
            </div>
          </section>
        </div>
      
        </>
    );

};

export default SenderReceiverComponent;