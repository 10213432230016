import React, { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import DataTable from "react-data-table-component";
import { RadioGroup } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import adminService from "../../services/admin.service";
import { toast } from "react-toastify";
import * as $ from "jquery";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@material-ui/core";
import swal from "sweetalert";

const SubGroupAddModal = (props) => {
    const [dimensionList, setDimensionList] = useState(props.subgroupDimensionData);
    const [dimensionListCopy, setDimensionListCopy] = useState([]);
    const [dimensionText, setDimensionText] = useState("");
    const [subgroupText, setsubgroupText] = useState({});
    const [selectedDimesion, setSelectedDimesion] = useState([]);
    const [selectedSubGroup, setSelectedSubGroup] = useState([]);
    const [sectionShow, setSectionShow] = useState({
        dimension: false,
        subgroup: false,
    });
    const [showMinMax, setShowMinMax] = useState(false)
    const [inputDimensionShow, setInputDimensionShow] = useState({
        dimension: false,
        subgroup: false,
    });
    const [sector, setSector] = useState("");
    const [sectorCopy, setSectorCopy] = useState();
    const [goal, setGoal] = useState("");
    const [goalCopy, setGoalCopy] = useState();
    const [indicator, setIndicator] = useState(" ");
    const [unit, setUnit] = useState("");
    const [tableData, setTableData] = useState([]);
    const [calculateDimesionData, setCalculateDimesionData] = useState([]);
    const [show, setShow] = useState(false);
    const [searchGroup, setSearchGroup] = useState("");
    const [isEnabled, setIsEnabled] = useState(true);
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [isGood, setIsGood] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    // const flatArray = tableData.flat();
    // const dataValueAll = JSON.parse(JSON.stringify(flatArray))
    const [selectedValue, setSelectedValue] = useState();
    const [order, setOrder] = useState();
    const [sortAbleStatus, setSortAbleStatus] = useState(false);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [associated, setAssociated] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [editSubgroupData, setEditSubgroupData] = useState()
    const [indexValue, setIndexValue] = useState()
    // const [caretDown, setCaretDown] = useState(false);
    const [openSubgroups, setOpenSubgroups] = useState([]);
    const [openGoal, setOpenGoal] = useState([]);
    const [openSector, setOpenSector] = useState([]);
    const form = useRef();

    const { parentCallback, editMode, getGoals, getSubSector, formData } = props;


    const isValueTrue = (rowId) => selectedValue === rowId;

    const swapArrayElements = (arr, index1, index2) => {
        const updatedArray = [...arr];

        if (arr[index1].allSelected && arr[index2].allSelected) {
            const startIndex = Math.min(index1, index2);
            const endIndex = Math.max(index1, index2);

            for (let i = startIndex; i <= endIndex; i++) {
                updatedArray[i] = arr[endIndex - (i - startIndex)];
            }
        } else {
            [updatedArray[index1], updatedArray[index2]] = [
                updatedArray[index2],
                updatedArray[index1]
            ];
        }

        setDimensionListCopy(updatedArray);
    };
    const handleUpClick = () => {
        if (selectedIndex > 0) {
            swapArrayElements(dimensionListCopy, selectedIndex, selectedIndex - 1);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleDownClick = () => {
        if (selectedIndex < dimensionListCopy.length - 1) {
            swapArrayElements(dimensionListCopy, selectedIndex, selectedIndex + 1);
            setSelectedIndex(selectedIndex + 1);
        }
    };


    const openAddDataModal = () => {
        setShow(!show);
        parentCallback();
    };

    const subgroupChange = (value, subgId) => {
        setsubgroupText((prevTexts) => ({
            ...prevTexts,
            [subgId]: value,
        }));
    };

    useEffect(() => {
        if (props.cross === true) {
            cancelModal();
        }
    }, [props.cross])


    const handleOrderChange = (index, event) => {
        const { value } = event.target;
        setTableData((prevTargets) => {
            const updatedTargets = [...prevTargets];
            updatedTargets[index].orderValue = value;
            return updatedTargets;
        });
        setOrder(value)
    };

    const handledimesionAddClick = () => {
        const subgArr = [];
        if (dimensionText.trim() !== "") {
            const newTodo = { dimensionText, subgArr };
            setDimensionList([...dimensionList, newTodo]);
            setDimensionText("");
        }
    };

    // const handleSubgroupAddClick = (subgId) => {
    //   if (subgroupText[subgId].trim() !== "") {
    //     dimensionList?.forEach((item, id) => {
    //       if (subgId === id) {
    //         item.subgArr.push(subgroupText[subgId]);
    //       }
    //       setsubgroupText("");
    //     });
    //   }
    // };

    const handleSubgroupAddClick = (subgId) => {
        if (subgroupText[subgId]?.trim() !== "") {
            const newSubgroup = { sub: subgroupText[subgId].trim() }; // Create the new subgroup object

            setDimensionListCopy((prevDimensionListCopy) => {
                const updatedDimensionListCopy = prevDimensionListCopy.map((item, id) => {
                    if (subgId === id) {
                        return { ...item, subgArr: [...item.subgArr, newSubgroup] };
                    }
                    return item;
                });
                return updatedDimensionListCopy;
            });

            setsubgroupText((prevSubgroupText) => {
                const updatedSubgroupText = { ...prevSubgroupText };
                delete updatedSubgroupText[subgId];
                return updatedSubgroupText;
            });
        }

    };


    const handleAllSelectedDimensionToggle = (dimensionIndex) => {

        const updatedArray = [...dimensionListCopy];
        updatedArray[dimensionIndex].allSelected = !updatedArray[dimensionIndex].allSelected;
        updatedArray[dimensionIndex].subgArr.forEach(subgroup => {
            subgroup.checked = updatedArray[dimensionIndex].allSelected;
        });

        setDimensionListCopy(updatedArray);

        const filteredData = updatedArray
            .map((obj) => ({
                ...obj,
                subgArr: obj.subgArr.filter((checkbox) => checkbox.checked),
            }))
            .filter((obj) => obj.subgArr.length > 0);

        setCalculateDimesionData(filteredData);
    };

    const subGrouphandleChange = (dimensionIndex, subgroupIndex) => {
        setDimensionListCopy((prevData) => {
            const updatedData = [...prevData];
            updatedData[dimensionIndex].subgArr[subgroupIndex].checked =
                !updatedData[dimensionIndex].subgArr[subgroupIndex].checked;

            const allSubgroupsChecked = updatedData[dimensionIndex].subgArr.every(
                (subgroup) => subgroup.checked
            );
            updatedData[dimensionIndex].allSelected = allSubgroupsChecked;

            const filteredData = updatedData
                .map((obj) => ({
                    ...obj,
                    subgArr: obj.subgArr.filter((checkbox) => checkbox.checked),
                }))
                .filter((obj) => obj.subgArr.length > 0);

            setCalculateDimesionData(filteredData);

            // const tempSubgarr = [];
            // filteredData?.map((item) => {
            //   const obj = { subgArr: item?.subgArr };
            //   tempSubgarr.push(obj);
            // });

            // const transformedArray = tempSubgarr.reduce(
            //   (result, item) => {
            //     const subgValues = item.subgArr.map((subgItem) => subgItem.sub);
            //     return result.flatMap((value) =>
            //       subgValues.map((subgValue) => value + " " + subgValue)
            //     );
            //   },
            //   [""]
            // );
            // const filteredArray = transformedArray.filter((item) => item !== "");
            // setSelectedDimesion(filteredArray);
            // setSelectedRows(transformedArray);

            return updatedData;
        });
    };

    const isAllSelectedSubGroup =
        selectedDimesion.length > 0 &&
        selectedSubGroup.length === selectedDimesion.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value === "all") {
            setSelectedSubGroup(
                selectedSubGroup.length === selectedDimesion.length
                    ? []
                    : selectedDimesion
            );
            return;
        }
        const list = [...selectedSubGroup];
        const index = list.indexOf(value);
        index === -1 ? list.push(value) : list.splice(index, 1);
        setSelectedSubGroup(list);
    };

    const showSection = (secName) => {
        switch (secName) {
            case "dimension":

                const tempSubgarr = [];
                calculateDimesionData?.map((item) => {
                    const obj = { subgArr: item?.subgArr };
                    tempSubgarr.push(obj);
                });

                const transformedArray = tempSubgarr.reduce(
                    (result, item) => {
                        const subgValues = item.subgArr.map((subgItem) => subgItem.sub);
                        return result.flatMap((value) =>
                            subgValues.map((subgValue) => value + " " + subgValue)
                        );
                    },
                    [""]
                );

                let newData = [...selectedDimesion, transformedArray]
                let flatedData = newData?.flat(Infinity)
                const filteredArray = flatedData.filter((item) => item !== "");
                setSelectedDimesion(filteredArray);
                setSectionShow({ dimension: true, subgroup: false, });
                break;
            case "subgroup":
                setSectionShow({ subgroup: true, dimension: true });
                setGoal({ goalname: "", targetname: "", id: "" });
                setSector({ subg: "", target: "", id: "" });
                setGoalCopy({ goalname: "", targetname: "", id: "" });
                setSectorCopy({ subg: "", target: "", id: "" });
                setSectionShow({ dimension: true, subgroup: true, });
                break;
            default:
                setSectionShow({
                    dimension: false,
                    subgroup: false,
                });
        }
    };
    const showMinMaxFun = () => {
        setShowMinMax(true)
    }
    const shortFunction = (type) => {
        switch (type) {
            case "dimensionsort":
                if (sortAbleStatus) {
                    const status = dimensionListCopy.sort((a, b) =>
                        a.dimensionText
                            ?.toLowerCase()
                            ?.localeCompare(b.indicator?.toLowerCase())
                    );
                    setDimensionListCopy(status);
                    setSortAbleStatus(false);
                } else {
                    const status = dimensionList.sort((a, b) =>
                        b.dimensionText
                            ?.toLowerCase()
                            ?.localeCompare(a.indicator?.toLowerCase())
                    );
                    setDimensionListCopy(status);
                    setSortAbleStatus(true);
                }
                break;
            case "subgroup":
                if (sortAbleStatus) {
                    setSelectedDimesion(
                        selectedDimesion.sort((a, b) =>
                            a?.toLowerCase()?.localeCompare(b?.toLowerCase())
                        )
                    );
                    setSortAbleStatus(false);
                } else {
                    setSelectedDimesion(
                        selectedDimesion.sort((a, b) =>
                            b?.toLowerCase()?.localeCompare(a?.toLowerCase())
                        )
                    );
                    setSortAbleStatus(true);
                }
                break;
            default:
        }
    };

    const toggleSubgroup = (id) => {
        setOpenSubgroups(prevOpenSubgroups => {
            if (prevOpenSubgroups.includes(id)) {
                return prevOpenSubgroups.filter(subgroupId => subgroupId !== id);
            } else {
                return [...prevOpenSubgroups, id];
            }
        });
    };

    const toggleGoal = (id) => {
        setOpenGoal(prevOpenSubgroups => {
            if (prevOpenSubgroups.includes(id)) {
                return prevOpenSubgroups.filter(subgroupId => subgroupId !== id);
            } else {
                return [...prevOpenSubgroups, id];
            }
        });
    };
    const toggleSector = (id) => {
        setOpenSector(prevOpenSubgroups => {
            if (prevOpenSubgroups.includes(id)) {
                return prevOpenSubgroups.filter(subgroupId => subgroupId !== id);
            } else {
                return [...prevOpenSubgroups, id];
            }
        });
    };


    const showInputDimension = (secName, id) => {
        let d = JSON.parse(JSON.stringify(dimensionList));
        switch (secName) {
            case "dimension":
                setInputDimensionShow({ dimension: !inputDimensionShow.dimension });
                break;
            case "subgroup":
                d?.forEach((ele, subId) => {
                    if (subId === id) {
                        setInputDimensionShow({ subgroup: !inputDimensionShow.subgroup });
                        // setCaretDown(!caretDown);

                    }
                });
                break;
            default:
                setInputDimensionShow({
                    dimension: false,
                    subgroup: false,
                });
        }
    };




    useEffect(() => {
        const uniqueDimensions = {};
        const filteredData = dimensionList.reduce((accumulator, item) => {
            if (!uniqueDimensions[item.dimensionText]) {
                uniqueDimensions[item.dimensionText] = true;
                accumulator.push(item);
            }
            return accumulator;
        }, []);

        const convertedArr = filteredData?.map(({ dimensionText, subgArr }) => ({
            dimensionText,
            subgArr: subgArr?.map((value) => ({ sub: value })),
        }));
        setDimensionListCopy(convertedArr);

        if (associated) {
            const filteredData = dimensionListCopy.map((item) => ({
                ...item,
                subgArr: item.subgArr.filter((subItem) => subItem.checked === true)
            }));

            const dimensionEmptyCheck = filteredData.filter((item) => {
                return item.subgArr.length > 0;
            });
            setDimensionListCopy(dimensionEmptyCheck);
        }
    }, [dimensionList, associated]);


    useEffect(() => {
        setDimensionList(props?.subgroupDimensionData);
        setDimensionListCopy(props?.subgroupDimensionData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.subgroupDimensionData]);

    useEffect(() => {
        let sectorTempArray = "";
        formData?.sectorSubsectorList?.map((ele) => {
            ele?.sectorSubData && ele?.sectorSubData?.map((item) => {
                if (sector === item?._id) {
                    sectorTempArray = ele;
                }
            });
        });

        sectorTempArray?.sectorSubData?.map((item) => {
            if (sector === item?._id) {
                setSectorCopy({
                    topicName: sectorTempArray?.level_name,
                    subTopicName: item?.level_name?.en,
                    id: sector,
                });
            }
        });

        let goalTempArray = "";
        formData?.goalTargetList?.map((ele) => {
            ele?.goalSubData?.map((item) => {
                if (goal === item?._id) {
                    goalTempArray = ele;
                }
            });
        });

        goalTempArray?.goalSubData?.map((item) => {
            if (goal === item?._id) {
                setGoalCopy({
                    goalname: goalTempArray?.level_name,
                    targetname: item?.level_name,
                    id: goal,
                });
            }
        });

        if (goalTempArray !== "") {
            setIsEnabled(false);
        }
    }, [sector, goal, tableData, isEnabled]);

  const addToTabledata = () => {
    let tempArr = [];
    let setorData=sector?.id===""? sector?.id:sector
    // for(let sg of tableData) {
    //   if(editMode && sg.id === editSubgroupData?.id) {
    //     continue;
    //   }
    //   let obj = {
    //     subg: sg.subg,
    //     sect: sg.sect,
    //     subSec: sg.subSec,
    //     goal: sg?.goal,
    //     goalId: sg.goalId,
    //     target: sg?.target,
    //     sectorId: sg.sectorId,
    //     unit: unit,
    //     indicator: indicator,
    //     id: sg.id?sg?.id: "",
    //     orderValue: sg.orderValue
    //   };
    //   tempArr.push(obj);
    // }
    let i = tableData.length+1;
    for(let sg of selectedSubGroup) {
      let obj = {
        subg: sg,
        sect: sectorCopy?.topicName,
        subSec: sectorCopy?.subTopicName,
        goal: goalCopy?.goalname,
        goalId: goal,
        target: goalCopy?.targetname,
        sectorId:setorData,
        unit: unit,
        indicator: indicator,
        id:`uniqueKey_${Math.random().toString(36).substr(2, 9)}`,
        ids: sg.id?sg?.id: "",
        orderValue: i++
      };
      tempArr.push(obj);
    }
    
    const data = tempArr;

    
    const makeFlatedArray = data?.flat();
    let dataValueChange=[...makeFlatedArray,...tableData]

    const uniqueSubgs = {};
    const filteredUniueData = dataValueChange.filter((item) => {
      if (!uniqueSubgs[item.subg]) {
        uniqueSubgs[item.subg] = true;
        return true;
      }
      return false;
    });
    setSelectedValue(filteredUniueData[0]?.id)
   
    setTableData(filteredUniueData);
    setIsEnabled(true);
    setSectionShow({ subgroup: true, dimension: true });
    setGoal({ goalname: "", targetname: "", id: "" });
    setSector({ subg: "", target: "", id: "" });
    setGoalCopy({ goalname: "", targetname: "", id: "" });
    setSectorCopy({ subg: "", target: "", id: "" });
    setSectionShow({ dimension: true, subgroup: true, }); 

    };

  console.log(tableData,"dataValueChange")


    const handleRowSelected = (rows) => {
        setSelectedRows(rows.selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const handleDeleteSelected = () => {
        const data = tableData?.flat();
        const updatedData = data.filter(
            (item) => !selectedRows.some((row) => row.id === item.id)
        );
        setTableData(updatedData);
        handleClearRows()
        setSelectedRows([]);
    };



    const dimensionDelete = () => {
        const updatedDataArray = dimensionListCopy.map((item) => {
            const updatedSubgArr = item.subgArr.filter((subItem) => !subItem.checked);

            // Check if all sub-items are checked
            const allSubItemsChecked = item.subgArr.every((subItem) => subItem.checked);

            if (allSubItemsChecked) {
                // If all sub-items are checked, delete the entire object (dimension)
                return null;
            }

            return { ...item, subgArr: updatedSubgArr };
        });

        // Remove null values (objects where all sub-items were checked) from the array
        const filteredDataArray = updatedDataArray.filter((item) => item !== null);

        setDimensionListCopy(filteredDataArray);
    };


    const subGroupDelete = () => {
        const updatedDataArray = selectedDimesion.filter(item => !selectedSubGroup.includes(item));
        setSelectedDimesion(updatedDataArray)
    }

    const handleSingleDelete = (index) => {
        const flatedtRemove = tableData?.flat();
        const updatedData = [...flatedtRemove];
        updatedData.splice(index, 1);
        setSelectedValue(updatedData[0]?.id)
        setTableData(updatedData);
    };



    useEffect(() => {
        const handleClick = (event) => {
            const caretElement = event.target;
            const nestedElement = caretElement.parentElement.querySelector('.nested');
            nestedElement.classList.toggle('active');
            caretElement.classList.toggle('caret-down');
        };

        const togglerElements = document.getElementsByClassName('caret');

        // for (let i = 0; i < togglerElements.length; i++) {
        //   if (editMode) {
        //     // If editMode is true, add the "active" and "caret-down" classes on component mount
        //     const nestedElement = togglerElements[i].parentElement.querySelector('.nested');
        //     nestedElement.classList.add('active');
        //     togglerElements[i].classList.add('caret-down');
        //   }

        //   togglerElements[i].addEventListener('click', handleClick);
        // }

        // Clean up the event listeners when the component is unmounted
        return () => {
            for (let i = 0; i < togglerElements.length; i++) {
                togglerElements[i].removeEventListener('click', handleClick);
            }
        };
        showInputDimension()

    });

    const cancelModal = (addData) => {
        if (!addData) {
            openAddDataModal();
            setTableData([]);
            setIndicator("");
            setUnit("");
            setMaxValue("");
            setMinValue("");
            setIsGood(true);
            setDimensionList([]);
            setDimensionListCopy([]);
            setGoal("")
            setSector("")
            setIsEnabled(true);
        }
        setCalculateDimesionData([]);
        setSelectedSubGroup([]);
        setSelectedDimesion([]);
        setOpenGoal([])
        setOpenSector([])
        setOpenSubgroups([])
        setGoal({ goalname: "", targetname: "", id: "" });
        setSector({ subg: "", target: "", id: "" });
        setGoalCopy({ goalname: "", targetname: "", id: "" });
        setSectorCopy({ subg: "", target: "", id: "" });
        $("#manage_popup").trigger("click");
    };

    function addCheckedProperty(data1, data2) {
        data2.forEach(item2 => {
            item2.subgArr.forEach(subItem2 => {
                if (data1.some(item1 => item1.subgArr.includes(subItem2.sub))) {
                    subItem2.checked = true;
                }
            });
        });
    }



    useEffect(() => {
        if (editMode) {
            setIsEdit(true);
            setIndicator(editMode?.name);
            setUnit(editMode?.unit);
            setMinValue(editMode?.minValue);
            setMaxValue(editMode?.maxValue);
            setIsGood(editMode?.high_is_good);
            // setGoal(editMode?.subgroup[0]?.target);
            // setSector(editMode?.subgroup[0]?.subtopic);

            let goalname = "";
            let goalTarget = "";
            getGoals.map((item) => {
                item?.target?.map((ele) => {
                    if (ele?._id === editMode?.subgroup[0]?.target) {
                        goalTarget = ele?.targetname;
                        goalname = item?.goalname;
                    }
                });
            });

            let topicValue = "";
            let subTopicValue = "";
            getSubSector?.map((item) => {
                item?.subtopic?.map((ele) => {
                    if (ele?._id === editMode?.subgroup[0]?.subtopic) {
                        topicValue = item?.topicname;
                        subTopicValue = ele?.subtopicname;
                    }
                });
            });

      let tempArr = [];
      let orderArr = [];
      let defaultval='';
      editMode?.subgroup.map((ele) => {
        let obj = {
          subg: ele?.name?.en,
          sect: ele?.topicname,
          subSec: ele?.subtopicname,
          goal: ele?.goalname,
          target: ele?.targetname,
          unit: unit,
          indicator: indicator,
          id: ele?._id,
          ids:ele?._id,
          orderValue: ele?.subgroup_order,
          goalId: ele?.target,
          sectorId: ele?.subtopic,
        };
        tempArr?.push(obj);
        orderArr?.push(ele?.subgroup_order);

                if (ele?.default_subgroup === 1) {
                    defaultval = ele?._id
                }
            });
            setSelectedValue(defaultval);
            setTableData(tempArr);

            const arr1 = Object.values(
                editMode?.subgroup?.map((item) => item?.subgroup_dimension_values)
            );

            let checkIsEmpty = [];
            arr1?.map((item) => {
                if (item.length) {
                    checkIsEmpty.push(item);
                }
            });

            // const transformedData = checkIsEmpty.reduce((result, item) => {
            //   item.forEach((obj) => {
            //     const dimensionText = Object.keys(obj)[0];
            //     const value = obj[dimensionText];


            const transformedData = checkIsEmpty.reduce((result, item) => {
                item.forEach((obj) => {
                    const dimensionText = Object.keys(obj)[0];
                    const value = obj[dimensionText];

                    const existingEntry = result.find(
                        (entry) => entry.dimensionText === dimensionText
                    );
                    if (existingEntry) {
                        if (!existingEntry.subgArr.includes(value)) {
                            existingEntry.subgArr.push(value);
                        }
                    } else {
                        result.push({ dimensionText, subgArr: [value] });
                    }
                });

                return result;
            }, []);

            addCheckedProperty(transformedData, dimensionListCopy)

            const filteredData = dimensionListCopy
                .map((obj) => ({
                    ...obj,
                    subgArr: obj.subgArr.filter((checkbox) => checkbox.checked),
                }))
                .filter((obj) => obj.subgArr.length > 0);



            setCalculateDimesionData(filteredData);


            const tempSubgarr = [];
            filteredData?.map((item) => {
                const obj = { subgArr: item?.subgArr };
                tempSubgarr.push(obj);
            });

            // console.log(tempSubgarr,"transformedData test")

            // const transformedArray = tempSubgarr.reduce(
            //     (result, item) => {
            //         const subgValues = item.subgArr.map((subgItem) => subgItem.sub);
            //         return result.flatMap((value) =>
            //             subgValues.map((subgValue) => value + " " + subgValue)
            //         );
            //     },
            //     [""]
            // );

            const combinedArray = tempSubgarr.reduce((result, item) => {
                return result.concat(item.subgArr);
            }, []);
            const uniqueSubValues = Array.from(new Set(combinedArray.map(item => item.sub)));

            // const filteredArray = transformedArray.filter((item) => item !== "");

            const subgValuesArray = tempArr.map(item => item.subg);
            console.log(subgValuesArray,"filteredArray")

            setSelectedDimesion(subgValuesArray);
            setSelectedSubGroup(selectedDimesion);
        } else {
            setIsEdit(false);
            setIsGood(true);
        }
    }, [editMode, props.subgroupDimensionData]);

    const getSubgroupDimensionValues = (subgroup) => {
        const values = subgroup?.split(" ");
        const subgroup_dimension_values = calculateDimesionData.reduce(
            (result, dimension) => {
                const value = values.find((val) =>
                    dimension.subgArr.some((sub) => sub.sub === val)
                );
                if (value) {
                    result.push({ [dimension.dimensionText]: value });
                }
                return result;
            },
            []
        );
        return subgroup_dimension_values;
    };


    const subGroupEditModeOpen = (event, subGroupId, index) => {
        event.preventDefault();

        tableData?.forEach((item) => {
            if (item.id === subGroupId) {
                setEditSubgroupData(item)
            }

        })

        setIndexValue(index)

    };


    const handleSubgroupSubmit = (e) => {
        e.preventDefault();

        const orderValues = new Set();
        let hasDuplicates = false;

        tableData.forEach((item) => {
            if (orderValues.has(+item.orderValue)) {
                hasDuplicates = true;
                return;
            }
            orderValues.add(item.orderValue);
        });

        if (hasDuplicates) {
            toast.error(`Duplicate Order value exists!`)
            return;
        }
        let idData = []
        const idArray = tableData?.map(obj => idData.push(obj.id));


        if (!isEdit) {
            let dataToSend = {
                indicator: indicator,
                unit: unit,
                is_framework_indicator: true,
                high_is_good: isGood ? 1 : 0,
                minValue: minValue,
                maxValue: maxValue,
                subgroup_form_array: [],
            };

            let subg_dimension = [];
            calculateDimesionData.forEach((sub_dimension) => {
                subg_dimension.push(sub_dimension?.dimensionText);
            });

            const tempArr = [];

            tableData?.forEach((subgroup) => {
                let sb = {
                    subgroup: subgroup.subg,
                    default_subgroup: isValueTrue(subgroup?.id),
                    subgroup_dimension: subg_dimension,
                    subgroup_dimension_values: getSubgroupDimensionValues(subgroup.subg),
                    // subsector: subgroup?.sectorId,
                    target: subgroup?.goalId,
                    subgroup_order: subgroup?.orderValue,
                    // topic: subgroup?.sect,
                    subsector: subgroup?.sectorId,
                };
                tempArr.push(sb);
            });
            dataToSend.subgroup_form_array = JSON.stringify(tempArr);
            adminService
                .httpPost("/data-import/ius/add-goal-ius", dataToSend)
                .then((res) => {
                    if (res?.status === 1) {
                        toast.success(!isEdit ? res?.message : 'Updated Successfully')
                        setIndicator("");
                        setUnit("");
                        setMaxValue("");
                        setMinValue("");
                        setIsGood(true);
                        setCalculateDimesionData([]);
                        setSelectedDimesion([]);
                        setGoal("")
                        setSector("")
                        setIsEnabled(true);
                        setTableData([]);
                        setSelectedSubGroup([]);
                        setDimensionList([]);
                        setDimensionListCopy([]);
                        setSelectedDimesion([]);
                        // setOrder([]);
                        openAddDataModal();
                    } else {
                        toast.error(res?.message);
                    }
                })
                .catch((e) => {
                    toast.error("Error in adding indicator");
                });
        } else {

            let dataToSend = {
                indicator: indicator,
                unit: unit,
                minValue: minValue,
                maxValue: maxValue,
                high_is_good: isGood ? 1 : 0,
                subgroup_form_array: [],
                // subgroup_id: [editSubgroupData?.id],
            };

            let subg_dimension = [];
            calculateDimesionData.forEach((sub_dimension) => {
                subg_dimension.push(sub_dimension?.dimensionText);
            });

            const tempArr = [];

        tableData?.forEach((subgroup, i) => {
          let sb = {
            default_subgroup: isValueTrue(subgroup?.id),
            subgroup_dimension: subg_dimension,
            subgroup_dimension_values: getSubgroupDimensionValues(subgroup.subg),
            subsector: subgroup?.sectorId,
            target: subgroup?.goalId,
            subgroup_order: subgroup?.orderValue,
            subgroup: subgroup?.subg,
            id : subgroup?.ids,
          };
          tempArr.push(sb);
        });
    
        dataToSend.subgroup_form_array = JSON.stringify(tempArr);

            console.log(tableData, dataToSend, "temprary Array")

            adminService
                .httpPost("/data-import/ius/update-subgroup", dataToSend)
                .then((res) => {
                    if (res?.status === 1) {
                        toast.success(res?.msg);
                        setIndicator("");
                        setUnit("");
                        setMaxValue("");
                        setMinValue("");
                        setIsGood(true);
                        setCalculateDimesionData([]);
                        setGoal("")
                        setSector("")
                        setIsEnabled(true);
                        setTableData([]);
                        setSelectedSubGroup([]);
                        setDimensionList([]);
                        setDimensionListCopy([]);
                        setSelectedDimesion([]);
                        openAddDataModal();
                    } else {
                        toast.error(res?.msg);
                    }
                })
                .catch((e) => {
                    toast.error("Error in adding indicator");
                });
        }
    };
    const removeSubgroupRow = (event, id) => {
        event.preventDefault();

        swal({
            title: "Are you sure you want to delete?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = { ius_id: id, iu_id: editMode?._id };
                const req = adminService
                    .httpPost("/data-import/ius/delete-subgroup", data)
                    .then((res) => {
                        if (res.status !== 200) {
                            toast.error(res.message);
                        } else {
                            // this.getData();
                            toast.success("subgroup deleted successfully");
                            openAddDataModal();
                        }
                    })
                    .catch((err) => {
                        toast.error("Error in deleting subgroup");
                    });
            }
        });
    }

    const indicatorOptions =
        props.indicatorData?.map((ind) => ({
            label: ind?.indicator || "",
            value: ind?._id || "",
        })) || [{ label: "dummy", value: "dummy" }];

    const findObjectByName = (nameToFind, unitToFind) => {
        const foundObject = props.getIusData?.find((item) => item.name?.toLowerCase() === nameToFind?.toLowerCase() && item.unit?.toLowerCase() === unitToFind?.toLowerCase());
        return foundObject;
    }

    useEffect(() => {
        if (showMinMax) {
            const foundObject = findObjectByName(indicator, unit);
            const sgTempArr = []
            if (foundObject) {
                setMinValue(foundObject?.minValue)
                setMaxValue(foundObject?.maxValue)
                let defaultval = '';
                for (let sg of foundObject?.subgroup) {
                    if (sg?.default_subgroup == 1) {
                        defaultval = sg?._id
                    }
                    let obj = {
                        subg: sg?.name?.en,
                        sect: sg?.topicname,
                        subSec: sg?.subtopicname,
                        goal: sg?.goalname,
                        goalId: sg?.target,
                        target: sg?.targetname,
                        sectorId: sg?.subtopic,
                        unit: foundObject?.unit,
                        indicator: foundObject?.name,
                        id: sg?._id ? sg?._id : `uniqueKey_${Math.random().toString(36).substr(2, 9)}`,
                        orderValue: Number(sg?.subgroup_order)
                    };
                    sgTempArr.push(obj);
                }

                const data = sgTempArr;
                setTableData(data);
                if (defaultval == '') {
                    setSelectedValue(data[0]?.id);
                } else {
                    setSelectedValue(defaultval);
                }
            }
        }
    }, [showMinMax])



    const sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.subg;
        const b = rowB?.subg;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };



    const columns = [
        {
            name: "#",
            // selector: (row, index) => row.index + 1,
            selector: (row, index) =>
                isNaN(row.index) ? `${index + 1}` : row.index + 1,

            sortable: true,
            width: "80px",
        },
        {
            name: "Subgroup",
            width: "350px",
            selector: (row) => (
                <>
                    <Tooltip title={ row?.subg } placement="top" arrow>
                        <div className="subgroup-box">
                            { row?.subg }
                        </div>
                    </Tooltip>
                </>
            ),
            sortable: true,
            // sortFunction: sortWithToolTip()
        },

        {
            name: "Sector",
            width: "150px",
            selector: (row) => row?.sect,
            sortable: true,
            // sortFunction:sortWithsect()
        },
        {
            name: "Sub-sector",
            width: "150px",
            selector: (row) => row?.subSec,
            sortable: true,
            // sortFunction: ()=>sortWithToolTip()
        },
        {
            name: "Goal",
            width: "200px",
            selector: (row) => row?.goal,
            sortable: true,
        },

        {
            name: "Target",
            width: "370px",
            selector: (row) => row?.target,
            sortable: true,
        },

        {
            name: "Action",
            cell: (row, index) => (
                <>
                    <div onClick={ () => handleSingleDelete(index) }>
                        <img
                            src="assets/lib/images/icon/del.svg"
                            className="cursor"
                            alt="ddd"
                        />
                    </div>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const columns1 = [
        {
            name: "#",
            // selector: (row, index) => row.index + 1,
            selector: (row, index) =>
                isNaN(row.index) ? `${index + 1}` : row.index + 1,

            sortable: false,
            width: "50px",
        },
        {
            name: "Default",
            selector: (row, index) => (
                <>
                    <div className="ius_table_radio">
                        <FormControlLabel
                            checked={ selectedValue === row?.id }
                            value={ row?.id }
                            onChange={ (e) => setSelectedValue(e.target.value) }
                            defaultValue={ row?.id }
                            control={ <Radio /> }
                        />
                    </div>
                </>
            ),
            sortable: true,
        },
        {
            name: "Order",
            width: "90px",
            class: 'test',
            selector: (row, index) => (
                <>
                    <div className="ius_table_input" key={ index }>
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            min="1"
                            value={ row?.orderValue }
                            onChange={ (e) => handleOrderChange(index, e) }
                        />
                    </div>
                </>
            ),
            sortable: true,
        },

        {
            name: "Subgroup",
            width: "200px",
            selector: (row, index) => row?.subg,
            sortable: true,
            sortFunction: sortWithToolTip()
        },
        {
            name: "Sector",
            width: "200px",
            selector: (row) => row?.sect,
            sortable: true,
        },
        {
            name: "Sub-sector",
            width: "200px",
            selector: (row) => row?.subSec,
            sortable: true,
        },
        {
            name: "Goal",
            width: "200px",
            selector: (row) => row?.goal,
            sortable: true,
        },

        {
            name: "Target",
            width: "450px",
            selector: (row) => row?.target,
            sortable: true,
        },

        {
            name: "Action",
            cell: (row, index) => (
                <>
                    <div className="action_btns">
                        {
                            editMode && <button onClick={ (e) => subGroupEditModeOpen(e, row?.id, index) }>
                                <img className="cursor" src="assets/lib/images/icon/edit_icon.svg" alt="dd" data-toggle="modal"
                                    data-target="#manage_popup" />
                            </button>
                        }
                        {
                            !editMode ? <button onClick={ () => handleSingleDelete(index) }>
                                <img
                                    src="assets/lib/images/icon/del.svg"
                                    className="cursor"
                                    alt="ddd"
                                />
                            </button> : <button onClick={ (e) => removeSubgroupRow(e, row?.id) }>
                                <img
                                    src="assets/lib/images/icon/del.svg"
                                    className="cursor"
                                    alt="ddd"
                                />
                            </button>
                        }
                    </div>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    return (
        <>
            <div className="user-form">
                <ValidatorForm
                    className="main_react_form w-100"
                    ref={ form }
                    onSubmit={ (e) => handleSubgroupSubmit(e) }
                    id="addUserForm"
                >
                    <Stack spacing={ 2 } sx={ { width: 300 } } className="w-100">
                        {/* <form  className="data_entry_form" id="dataEntryForm"> */ }
                        <div className="gallery-collections mt-0 pb-0 custom_gallery position-relative ius_gallery">
                            <div className="row">
                                <div class="col-md-12">
                                    <div className="steps">
                                        <p>Step 1</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row align-items-center custom_row">
                                        <div className="col-md-8">
                                            <div className="form-group mb-0 inputform_style">
                                                <div className="mat-form-field-wrapper">
                                                    <span className="mat-form-field-label-wrapper">
                                                        <label>Indicator</label>
                                                    </span>
                                                    <div className="MuiInput-root">
                                                        {/* <input type="text" /> */ }
                                                        <Autocomplete
                                                            id="free-solo-demo1"
                                                            value={ { label: indicator } }
                                                            onChange={ (e, v) => { setIndicator(v?.label || ""); setShowMinMax(false); setTableData([]); } }
                                                            inputValue={ indicator }
                                                            onInputChange={ (event, newInputValue) => {
                                                                setIndicator(newInputValue);
                                                            } }
                                                            options={ indicatorOptions }
                                                            getOptionLabel={ (option) => option.label || "" }
                                                            renderInput={ (params) => (
                                                                <TextValidator
                                                                    className="w-100"
                                                                    { ...params }
                                                                    label=""
                                                                    name="indicator"
                                                                    value={ indicator }
                                                                    validators={ ["required"] }
                                                                    errorMessages={ ["This field is required"] }
                                                                />
                                                            ) }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group mb-0 inputform_style">
                                                        <div className="mat-form-field-wrapper">
                                                            <span className="mat-form-field-label-wrapper">
                                                                <label>Unit</label>
                                                            </span>
                                                            <div className="MuiInput-root">
                                                                <Autocomplete
                                                                    id="free-solo-demo2"
                                                                    value={ { label: unit } }
                                                                    className="w-100"
                                                                    onChange={ (e, v) => { setUnit(v?.label || ""); setShowMinMax(false); setTableData([]); } }
                                                                    inputValue={ unit }
                                                                    onInputChange={ (event, newInputValue) => {
                                                                        setUnit(newInputValue);
                                                                    } }
                                                                    options={
                                                                        props?.unitdata
                                                                            ? props?.unitdata?.map((uni) => {
                                                                                return {
                                                                                    label: uni ? uni : "",
                                                                                    value: uni,
                                                                                };
                                                                            })
                                                                            : { label: "dummy", value: "dummy" }
                                                                    }
                                                                    getOptionLabel={ (option) =>
                                                                        option.label ? option.label : ""
                                                                    }
                                                                    filterSelectedOptions
                                                                    renderInput={ (params) => (
                                                                        <TextValidator
                                                                            className="w-100"
                                                                            { ...params }
                                                                            label=""
                                                                            name="unit"
                                                                            value={ unit }
                                                                            validators={ ["required"] }
                                                                            errorMessages={ ["This field is required"] }
                                                                        />
                                                                    ) }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 d-flex align-items-end" onClick={ () => showMinMaxFun() }>
                                                    <div class="manage_btn-area d-flex justify-content-center">
                                                        <p class="cursor step_btn">
                                                            <img class="ml-1" src="assets/lib/images/icon/btn_arrow.svg" alt="" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            showMinMax && indicator && unit && <div className="col-md-12">
                                                <div className="high_goods_area">
                                                    <div className="high_checkbox">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={ (e) => setIsGood(e.target.checked) }
                                                                        checked={ isGood }
                                                                    />
                                                                }
                                                                label="High is Good"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="high_numbers col-md-2">
                                                        <span className="mat-form-field-label-wrapper">
                                                            <label>Minimum Value</label>
                                                        </span>
                                                        <TextField
                                                            id="outlined-number"
                                                            label=""
                                                            type="number"
                                                            InputLabelProps={ {
                                                                shrink: true,
                                                            } }
                                                            value={ minValue }
                                                            onChange={ (e) => setMinValue(e.target.value) }
                                                        />
                                                    </div>
                                                    <div className="inPuthidden">
                                                        <TextValidator
                                                            type="hidden"
                                                            style={ { border: "0px", outline: "none" } }
                                                            name="number"
                                                            autoComplete="off"
                                                            className=" w-100"
                                                            value={ minValue }
                                                        />
                                                    </div>

                                                    <div className="high_numbers col-md-2">
                                                        <span className="mat-form-field-label-wrapper">
                                                            <label>Maximum Value</label>
                                                        </span>
                                                        <TextField
                                                            id="outlined-number"
                                                            label=""
                                                            type="number"
                                                            InputLabelProps={ {
                                                                shrink: true,
                                                            } }
                                                            value={ maxValue }
                                                            onChange={ (e) => setMaxValue(e.target.value) }

                                                        />
                                                    </div>
                                                    <div className="inPuthidden">
                                                        <TextValidator
                                                            type="hidden"
                                                            style={ { border: "0px", outline: "none" } }
                                                            name="number"
                                                            autoComplete="off"
                                                            className=" w-100"
                                                            value={ maxValue }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-5 user_steps d-none">
                                <div className="col-md-12">
                                    <div className="all_steps_area d-flex justify-content-center flex-column">
                                        <h4>
                                            <span>Step 1 -</span> Create Indicator and Unit
                                        </h4>
                                        <h4>
                                            <span>Step 2 -</span> Create Subgroup
                                        </h4>
                                        <h4>
                                            <span>Step 3 -</span> Associate with Sector and Goal
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    { tableData?.flat(1)?.length || editMode ? (
                                        <div className="first_subgrp_head d-flex mt-4 mb-2">
                                            <h3>Subgroups ({ tableData?.flat(1)?.length }) </h3>
                                            <img
                                                src="assets/lib/images/icon/del.svg"
                                                alt=""
                                                onClick={ handleDeleteSelected }
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    ) }
                                    { tableData?.flat(1)?.length || editMode ? (
                                        <div className="ius_datatable w-100">
                                            <DataTable
                                                columns={ columns1 }
                                                data={ tableData?.flat(1) }
                                                selectableRows
                                                // onSelectedRowsChange={handleRowSelected}
                                                persistTableHead
                                                className="pl-0"
                                                onSelectedRowsChange={ handleRowSelected }
                                                clearSelectedRows={ toggledClearRows }
                                                // pagination
                                                noDataComponent="No Data"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    ) }
                                    <div className="manage_btn-area w-100 d-flex justify-content-center">
                                        <p
                                            className="cursor manage_btn"
                                            data-toggle="modal"
                                            data-target="#manage_popup"
                                        >
                                            Manage Subgroups
                                            <img
                                                className="ml-1"
                                                alt="fsf"
                                                src="assets/lib/images/icon/btn_arrow.svg"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { tableData?.flat(1)?.length ? (
                            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Button
                                        variant="contained"
                                        onClick={ () => { cancelModal() } }
                                        className="cancelbtn"
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" className="addbtn" type="submit">
                                        { props.isEdit ? "Update" : "Add" }
                                        <img
                                            alt="dfdf"
                                            className="btn_arrow"
                                            src="assets/lib/images/icon/btn_arrow.svg"
                                        />
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            ""
                        ) }
                        {/* </form> */ }
                    </Stack>
                </ValidatorForm>
            </div>

            {/* ==================MANAGE SUBGROUP POPUP============= */ }

            <div
                className="modal fade"
                id="manage_popup"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Manage Subgroups
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="assets/lib/images/close.svg" style={ { width: '12px' } } alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row position-relative w-100 m-auto subgrp-row">
                                <div className="steps">
                                    <p>Step 2</p>
                                </div>
                                <div className="col-md-4 pl-0 dash_border">
                                    <div className="manage_subgroup question_mark position-relative">
                                        <div className="adding-subgrp d-flex justify-content-between align-items-center">
                                            <div className="left_add">
                                                <p >
                                                    <img
                                                        src="assets/lib/images/new-home/add_grp.svg"
                                                        alt=""
                                                        onClick={ () => showInputDimension("dimension") }
                                                    />
                                                    Subgroup Dimension
                                                    <span>( { dimensionListCopy?.length } )</span>
                                                </p>
                                            </div>
                                            <div className="right_imgs">
                                                <img
                                                    alt=""
                                                    src="assets/lib/images/icon/del.svg"
                                                    // className="mr-2"
                                                    onClick={ () => { dimensionDelete() } }
                                                    className={ `mr-2 ${!calculateDimesionData?.length ? 'up_down_disabled' : ''}` }
                                                />
                                                <img
                                                    onClick={ handleDownClick }
                                                    src="assets/lib/images/download.svg"
                                                    alt=""
                                                    className={ `mr-2 ${!calculateDimesionData?.length ? 'up_down_disabled' : ''}` }
                                                />
                                                <img
                                                    onClick={ handleUpClick }
                                                    src="assets/lib/images/upload.svg"
                                                    alt=""
                                                    className={ `mr-2 ${!calculateDimesionData?.length ? 'up_down_disabled' : ''}` }
                                                />
                                            </div>
                                        </div>
                                        { inputDimensionShow.dimension && (
                                            <div className="add_dimension">
                                                <input
                                                    placeholder="add dimension"
                                                    name="dimesion"
                                                    value={ dimensionText }
                                                    onChange={ (e) => setDimensionText(e.target.value) }
                                                />
                                                <button onClick={ handledimesionAddClick }>
                                                    <img
                                                        src="assets/lib/images/new-home/add_grp.svg"
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        ) }
                                   {
                                    dimensionListCopy &&      <div className="select_subgrp">
                                            <div className="select-all mt-3 mb-3 d-flex justify-content-between">
                                                <FormControlLabel
                                                    control={ <Checkbox onChange={ (e) => setAssociated(e.target.checked) } checked={ associated } /> }
                                                    label="Associated"
                                                />

                        <img
                          src="assets/lib/images/new-home/new-sort.svg"
                          alt=""
                        />
                      </div>
                      <div className="childs_subgrp d-flex flex-column">
                        <ul id="myUL">
                          {dimensionListCopy?.map((ele, id) => {
                             const isSubgroupOpen = openSubgroups.includes(id);
                            return (
                              <li key={id}>
                                <span className={`caret main_parent ${isSubgroupOpen ? 'caret-down' : ''}`} onClick={() =>
                                    {showInputDimension("subgroup", id); toggleSubgroup(id)}
                                  }>
                                </span>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={ele?.id}
                                      checked={ ele?.allSelected?'allSelected': ""}
                                      onChange={() =>
                                        {
                                          handleAllSelectedDimensionToggle(id)
                                          setSelectedIndex(selectedIndex === id ? null : id)
                                        }
                                      }
                                    />
                                  }
                                  label={ele?.dimensionText}
                                />
                                <img
                                  src="assets/lib/images/new-home/add_grp.svg"
                                  alt=""
                                  className={`caret main_parent ${isSubgroupOpen ? 'caret-down' : ''}`} onClick={() =>
                                    {showInputDimension("subgroup", id);toggleSubgroup(id)}
                                  }
                                />
                                <ul className={`nested ${isSubgroupOpen ? 'active' : ''}`}>
                                <div className="add_dimension">
                                      <input
                                        placeholder="add subgroup"
                                        name={`subGroupValue${id}`}
                                        // ref={inputRef}
                                        value={subgroupText[id] || ""}
                                        onChange={(e) =>
                                          subgroupChange(e.target.value, id)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubgroupAddClick(id)
                                        }
                                      >
                                        <img
                                          src="assets/lib/images/new-home/add_grp.svg"
                                          alt=""
                                         
                                        />
                                      </button>
                                    </div>
                                  {ele?.subgArr?.map((checkbox, subIndex) => {
                                    return (
                                      <li key={subIndex}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                            checked={checkbox.checked? 'checked': ""}
                                              onChange={() => subGrouphandleChange(  id,  subIndex ) }
                                            />
                                          }
                                          
                                          label={checkbox?.sub}
                                        />
                                      </li>
                                    );
                                  })}
                                  {/* {inputDimensionShow.subgroup && ( */}
                                    {/* <div className="add_dimension">
                                      <input
                                        placeholder="add subgroup"
                                        name={`subGroupValue${id}`}
                                        // ref={inputRef}
                                        value={subgroupText[id] || ""}
                                        onChange={(e) =>
                                          subgroupChange(e.target.value, id)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubgroupAddClick(id)
                                        }
                                      >
                                        <img
                                          src="assets/lib/images/new-home/add_grp.svg"
                                          alt=""
                                         
                                        />
                                      </button>
                                    </div> */}
                                  {/* )} */}
                                </ul>
                                {/* {id === selectedIndex && (
                              <div>
                                <button
                                  onClick={() => {
                                    if (id > 0) {
                                      swapArrayElements(dimensionListCopy, id, id - 1);
                                      setSelectedIndex(id - 1);
                                    }
                                  }}
                                >
                                  Up
                                </button>
                                <button
                                  onClick={() => {
                                    if (id < dimensionListCopy.length - 1) {
                                      swapArrayElements(dimensionListCopy, id, id + 1);
                                      setSelectedIndex(id + 1);
                                    }
                                  }}
                                >
                                  Down
                                </button>
                              </div>
          )} */}
                                                            </li>
                                                        );
                                                    }) }
                                                </ul>
                                            </div>
                                        </div>
                                   }
                                        <div className="play_btns">
                                            <button>
                                                <img
                                                    src="assets/lib/images/new-home/question.svg"
                                                    alt=""
                                                />
                                            </button>
                                            <button
                                                className={ `${!calculateDimesionData?.length && "next_btn"}` }
                                                onClick={ () => showSection("dimension") }
                                            >
                                                <img
                                                    src="assets/lib/images/new-home/play_btn.svg"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                { (
                                    sectionShow?.dimension === true) ||
                                    editMode ? (
                                    <div className="col-md-4 dash_border">
                                        <div className="manage_goal question_mark position-relative">
                                            <div className="adding-subgrp d-flex justify-content-between align-items-center position-relative">
                                                <div className="left_add">
                                                    <p>
                                                        Available Subgroups
                                                        (<span>{ Array.from(new Set(selectedDimesion))?.length }</span>)
                                                    </p>
                                                </div>
                                                <div className="right_imgs">
                                                    <img
                                                        alt="sfsf"
                                                        src="assets/lib/images/icon/del.svg"
                                                        className="mr-2"
                                                        onClick={ () => subGroupDelete() }
                                                    />
                                                    <img
                                                        src="assets/lib/images/new-home/sort.svg"
                                                        alt=""
                                                        className="mr-2"
                                                        onClick={ () => shortFunction("subgroup") }
                                                    />
                                                </div>
                                                <div className="more_steps">
                                                    <p>Step 3</p>
                                                </div>
                                            </div>
                                            <div className="select_subgrp select-all-list">
                                                <div className="select-all mt-3 mb-3 d-flex justify-content-between">
                                                    <FormControlLabel
                                                        className="step-3-list"
                                                        control={
                                                            <Checkbox
                                                                value="all"
                                                                onChange={ handleChange }
                                                                checked={ isAllSelectedSubGroup }
                                                            />
                                                        }
                                                        label="Select All"
                                                    />
                                                    <div className="search_subgrp">
                                                        <div className="MuiInput-root">
                                                            <input
                                                                type="search"
                                                                placeholder="Search Subgroup"
                                                                onChange={ (e) => setSearchGroup(e.target.value) }
                                                                value={ searchGroup }
                                                            />
                                                            <img
                                                                src="assets/lib/images/home/search.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="childs_subgrp d-flex flex-column">
                                                    { Array.from(new Set(selectedDimesion))?.filter((item) =>
                                                        item?.toLowerCase()?.includes(searchGroup?.toLowerCase()))
                                                        .map((option) => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            value={ option }
                                                                            onChange={ handleChange }
                                                                            checked={ selectedSubGroup.includes(
                                                                                option
                                                                            ) }
                                                                        />
                                                                    }
                                                                    label={ option }
                                                                />
                                                            );
                                                        }) }
                                                </div>
                                            </div>
                                            <div className="play_btns">
                                                <button>
                                                    <img
                                                        src="assets/lib/images/new-home/question.svg"
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    className={ `${!selectedSubGroup?.length && "next_btn"
                                                        }` }
                                                    onClick={ () => {
                                                        showSection("subgroup");
                                                    } }
                                                >
                                                    <img
                                                        src="assets/lib/images/new-home/play_btn.svg"
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null }

                                { (sectionShow?.subgroup === true) ||
                                    editMode ? (
                                    <div className="col-md-4">
                                        <div className="manage_goal question_mark position-relative">
                                            <div className="adding-subgrp d-flex justify-content-between align-items-center position-relative">
                                                <div className="left_add">
                                                    <p>Classification</p>
                                                </div>
                                                <div className="right_imgs"></div>
                                                <div className="more_steps">
                                                    <p>Step 4</p>
                                                </div>
                                            </div>
                                            <div className="first_subgrp_head">
                                                <nav>
                                                    <div
                                                        className="nav nav-tabs mb-3"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        <button
                                                            className="nav-link active"
                                                            id="nav-home-tab"
                                                            data-toggle="tab"
                                                            data-target="#nav-home"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-home"
                                                            aria-selected="true"
                                                        >
                                                            Goal
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-profile-tab"
                                                            data-toggle="tab"
                                                            data-target="#nav-profile"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-profile"
                                                            aria-selected="false"
                                                        >
                                                            Sector
                                                        </button>
                                                    </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="nav-home"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-home-tab"
                                                    >
                                                        <div className="childs_subgrp d-flex flex-column">
                                                            <ul id="myUL" className="tab_childs">
                                                                { formData?.goalTargetList?.sort((a, b) => a?.level_name?.toLowerCase().localeCompare(b?.level_name?.toLowerCase())).map((item, id) => {
                                                                    const isopenGoal = openGoal.includes(id);
                                                                    return (
                                                                        <li>
                                                                            <span className={ `caret main_parent ${isopenGoal ? 'caret-down' : ''}` } onClick={ () => toggleGoal(id) }>
                                                                                { item?.level_name }
                                                                            </span>
                                                                            <ul className={ `nested ${isopenGoal ? 'active' : ''}` }>
                                                                                { item?.goalSubData?.sort((a, b) => a?.level_name?.toLowerCase().localeCompare(b?.level_name?.toLowerCase())).map((ele) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <RadioGroup
                                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                                name="controlled-radio-buttons-group"
                                                                                                value={ goal }
                                                                                                onChange={ (e) =>
                                                                                                    setGoal(e.target.value)
                                                                                                }
                                                                                            >
                                                                                                <FormControlLabel
                                                                                                    value={ ele?._id }
                                                                                                    control={ <Radio /> }
                                                                                                    label={ ele?.level_name }
                                                                                                />
                                                                                            </RadioGroup>
                                                                                        </li>
                                                                                    );
                                                                                }) }
                                                                            </ul>
                                                                        </li>
                                                                    );
                                                                }) }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-profile-tab"
                                                    >
                                                        <div className="childs_subgrp d-flex flex-column">
                                                            <ul id="myUL" className="tab_childs">
                                                                { formData?.sectorSubsectorList?.sort((a, b) => b?.level_name?.toLowerCase().localeCompare(a?.level_name?.toLowerCase()))?.map((item, id) => {
                                                                    const isopenSector = openSector.includes(id);
                                                                    return (
                                                                        <li key={ item?.level_id }>
                                                                            <span className={ `caret main_parent ${isopenSector ? 'caret-down' : ''}` } onClick={ () => toggleSector(id) }>
                                                                                { item?.level_name }
                                                                            </span>
                                                                            <ul className={ `nested ${isopenSector ? 'active' : ''}` }>
                                                                                { item?.sectorSubData && item?.sectorSubData?.sort((a, b) => a?.level_name?.en?.toLowerCase().localeCompare(b?.level_name?.en?.toLowerCase()))?.map((ele) => {
                                                                                    return (
                                                                                        <li key={ ele?.level_id }>
                                                                                            <RadioGroup
                                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                                name="controlled-radio-buttons-group"
                                                                                                value={ sector }
                                                                                                onChange={ (e) =>
                                                                                                    setSector(e.target.value)
                                                                                                }
                                                                                            >
                                                                                                <FormControlLabel
                                                                                                    value={ ele?._id }
                                                                                                    control={ <Radio /> }
                                                                                                    label={ ele?.level_name?.en }
                                                                                                />
                                                                                            </RadioGroup>
                                                                                        </li>
                                                                                    );
                                                                                }) }
                                                                            </ul>
                                                                        </li>
                                                                    );
                                                                }) }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null }
                            </div>

                            <div className="row w-100 m-auto justify-content-center" style={{paddingTop: "15px", paddingBottom: "15px"}}>
                                <button
                                    className={ `forward_btn ${isEnabled && "manage_btn_restricated"}`}
                                    disabled={ isEnabled }
                                    onClick={ addToTabledata }
                                >
                                    Ok
                                    <img
                                        alt="dd"
                                        className=""
                                        src="assets/lib/images/icon/btn_arrow.svg"
                                    ></img>
                                </button>
                            </div>

                            <div className="row w-100 m-auto border-top flex-column">
                                <div className="first_subgrp_head d-flex mt-4 mb-2">
                                    <h3>Subgroups ({ tableData?.flat(1)?.length }) </h3>
                                    <img
                                        src="assets/lib/images/icon/del.svg"
                                        alt="dd"
                                        onClick={ handleDeleteSelected }
                                    />
                                </div>
                                <div className="ius_datatable w-100 manage-subgroup">
                                    <DataTable
                                        columns={ columns }
                                        data={ tableData?.flat(1) }
                                        selectableRows
                                        onSelectedRowsChange={ handleRowSelected }
                                        clearSelectedRows={ toggledClearRows }
                                        persistTableHead
                                        className="height_scroll pl-0"
                                        // pagination
                                        noDataComponent="No Data"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className={ `${tableData?.flat(1)?.length ? "" : "btn btn-primary disable"
                                    }` }
                                onClick={ () => $("#manage_popup").trigger("click") }
                            >
                                Add
                                <img
                                    alt="ss"
                                    className=""
                                    src="assets/lib/images/icon/btn_arrow.svg"
                                ></img>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubGroupAddModal;
