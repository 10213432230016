import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import '../../App.css';
import * as $ from 'jquery';

// ========================================
function BurgerMenu(props) {


  useEffect(()=> {
    $(".burger_box").on("click",function(){
      $(".menu-dropdown").addClass("show-menu");
     });
   
    $(".hmClose").on("click", function(){
        $(".menu-dropdown").removeClass("show-menu");
    });
    
  }, []);


  return (
                <div className="menu-dropdown">
                  <button className="hmClose hmbtnClose btn btn-secondary">
                    <img src="assets/lib/images/close.svg" alt="" />
                  </button>
                  <nav className="hmMenu navbar navbar-expand-lg navbar-light">
                    <div class="navbar-collapse collapse" id="basic-navbar-nav">
                      <ul className="me-auto navbar-nav">
                        <li>
                          <Link to='/Home' className='nav-link'>
                            <span>Home</span>
                            <p className='detail_para'>Centralized SDG data hub of Trinidad and Tobago to measure, track and accelerate the 2030 SDGs agenda</p>
                          </Link>
                        </li>


                        <li>
                          <Link to='/dashboard/overview' className='nav-link'>
                            <span>Explore</span>
                            <p className='detail_para'> Explore and Visualize the performance of SDG indicators by SDG Goals and Targets at national level.</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/dashboard/data-analysis' className='nav-link'>
                            <span>Data Analysis</span>
                            <p className='detail_para'>Navigate SDG Data in three views and Visualize the performance of SDG indicators by geographical area and time period using charts and thematic map.</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/dashboard/data-search' className='nav-link'>
                            <span>Search Data</span>
                            <p className='detail_para'>Data Search and View the SDG data by indicator, area, time period and data source and present using various charts and thematic map</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/information-kiosk' className='nav-link'>
                            <span>Information Kiosk</span>
                            <p className='detail_para'>A valuable resource hub for promoting and advancing the United Nations' Sustainable Development Goals in Trinidad and Tobago</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/about' className='nav-link'>
                            <span>About</span>
                            <p className='detail_para'>Working towards achieving the Sustainable Development Goals (SDGs) that addresses the major development challenges faced by people in Trinidad and Tobago</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/contact' className='nav-link'>
                            <span>Contact</span>
                            <p className='detail_para'>Connect with us to help achieve Sustainable Development Goals</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
  );
}


export default BurgerMenu;
