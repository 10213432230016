import "./manageGids.css";
// import * as jsx from "./manageGids.module.jsx";
import LanguageTab from './tabs';

const ManageGids = () => {
    return (
        <>
      <div className='main'>
      <LanguageTab />  
    </div>
        </>
    )
}

export default ManageGids