import { Component } from "react";
import "./manage-scheme.css";
import * as jsx from "./manage-scheme.module.jsx";
import * as constants from "../../../Config/app.constants";
import * as $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import AdminService from "../../../services/admin.service";
import { Tooltip } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";

class ManageScheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      isEdit: false,
      editModeId: null,
      modal: false,
      assignModal: false,
      loader: false,
      allIndicatorList: null,
      allUnitList: null,
      allSubgroupDimensionList: null,
      allDimensionList: null,
      sectorSubsectorList: null,
      bulkDelete: [],
      goalTargetList: null,
      disasterID: null,
      isAssigned: [],
      disasterName: null,
      selectedAssignedData: [],
      departmentdata:[],
      areaData:[]
    };
  }

  onSearch = (val) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    console.log(d,"45search")
    let fd = null;
    if (val === "") {
      fd = d;
    } else {
      fd = d.filter(
        (item) =>
          (item.name &&item.name.toLowerCase().includes(val?.toLowerCase()))
          || (item.department.filter(d=> d.department_name?.toLowerCase().includes(val.toLowerCase())).length > 0)
          || (item.overview && item.overview.toLowerCase().includes(val.toLowerCase()))
      );
    }
    this.setState({ filteredData: fd });
  };

  sortWithToolTip = (rowA, rowB) => {
    const a = rowA?.department?.[0];
    const b = rowB?.overview;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  columns = [
    // {
    //   name: "#",
    //   selector: (row, index) => index + 1,
    //   sortable: false,
    //   width: "70px",
    // },
    {
      name: "Scheme Name",
      selector: (row) => row.name,
      sortable: true,
    },
  
    {
      name: "Department Name",
      selector: (row) => (
        <>
          {row?.department?.[0]?.department_name?.length > 0 &&
            row?.department?.slice(0, 1).map((d, i) => {
              return (
                <>
                  <span className="badge">{d?.department_name} </span>
                </>
              );
            })}
            
          {row?.department?.length > 1 ? (
            <>
              <span>{row?.department?.length - 1}+</span>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      name: "Scheme Budget | USD Million",
      selector: (row) => row.budget,
      sortable: true,
    },
    {
      name: "Overview",
      selector: (row) => (
        <Tooltip title={row.overview} placement="top">
          <p>{row.overview}</p>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: this.sortWithToolTip,
    },
    {
      name: "Action",
      width: "150px",
      cell: (row) => (
        <>
          <div className="action_btns">
            <button onClick={(e) => this.editMode(e, row._id)}>
              <img src="assets/lib/images/icon/edit_icon.svg" alt="edit-icon" />
            </button>
            <button
              onClick={(e) => {
                this.deleteEntry(row._id);
              }}
            >
              <img
                src="assets/lib/images/icon/delete_icon.svg"
                alt="delete-icon"
              />
            </button>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  takeAssignedID = (event = null) => {
    let disasterid = "";
    if (event) {
      disasterid = event?._id;
      this.setState({ disasterID: event?._id, disasterName: event?.title?.en });
    } else {
      disasterid = this.state.disasterID;
    }

    const data = {
      _id: disasterid,
    };
    AdminService.httpPost(
      "/data-import/disaster/get-assign-indicator",
      data
    ).then((res) => {
      if (res) {
        let d = res.data;
        let finalarray = [];
        d?.forEach((element) => {
          finalarray.push(element);
        });
        let finalArrayWithoutNullID = finalarray?.filter((x) => x?._id != null);
        this.setState({ isAssigned: finalArrayWithoutNullID });
      }
    });
  };

  componentDidMount() {
    this.getData();
    this.getAllAreaData()
    this.getAllIndicators();
    adminService.checkToken();
  }

  render() {
    return jsx.default.bind(this)();
  }

  getAllAreaData = async () => {
    this.setState({ loader: true });
    try {
      const res = await AdminService.httpGet(
        "/data-retrieval/area/getAreaData"
      );
      
      if (res && res.data) {
        const { data } = res;
        this.setState({ areaData: data });
      }
      this.setState({ loader: false });
    } catch (err) {
      this.setState({ loader: false });
    }
  };

  callBack = () => {
    this.setState({
      modal: false,
      isEdit: false,
      editModeId: null,
    });
    this.forceUpdate();
    this.getData();
  };

  editMode(event, row_id) {
    event.preventDefault();
    this.setState({
      isEdit: true,
      modal: true,
    });
    this.state.data.forEach((item) => {
      console.log(item,"197")
      if (item._id === row_id) {
        this.setState({ editModeId: item });
      }
    });
    this.openEditModal();
  }

  openAddModal = () => {
    if (this.state.modal === false) {
      this.setState({ modal: true });
    } else {
      this.setState({
        modal: false,
        isEdit: false,
        editModeId: null,
      });
    }
  };

  // delete entry===========//
  deleteEntry = (id) => {
    swal({
      title: "Are you sure delete this?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const dataToBeSend = {
          id: id,
        };
        console.log(dataToBeSend,"229")
        adminService
          .httpPost("/data-import/scheme/delete-scheme", dataToBeSend)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res;
              if (res.status === true) {
                toast.success("Sheme deleted successfully");
                this.getData();
              } else {
                toast.error(res?.message);
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
            toast.error(err);
          });
      }
    });
  };

  ////////////////get api///////////
  getData = async () => {
    this.setState({ loader: true });

    adminService
      .httpGet("/data-retrieval/scheme/get-scheme-data")
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;
          console.log("get shemeData", res);
          this.setState({ filteredData: d, data: d });
        }
        // this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  //add and edit api/////

  saveDisasterData = (saveData) => {
    if (this.state.isEdit ) {

      const data = {
        editSchemeName: saveData.schemeName,
        editBudgetName:saveData.schemeBudget,
        editDepartmentName:saveData.schemeDepartment,
        editOverview:saveData.schemeOverview,
        editRowId:saveData.id,
        indicatorList:saveData.schemeIndicator,
        areaList:saveData.schemeArea,
      };

      adminService
        .httpPost("/data-import/scheme/update-scheme" , data)
        .then((res) => {
          if (res) {
            let d = res.data;
            if (res.status !== 0 && res.success === true) {
              this.setState({ loader: false });
              toast.success("Sheme updated successfully");
              this.openAddModal();
              this.getData();
            } else {
              this.getData();
              this.openAddModal();
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          toast.error("Failed to update Scheme");
          console.log(err);
        });
    } else {

      adminService
        .httpPost("/data-import/scheme/add-scheme", saveData)
        .then((res) => {
          if (res) {
            let d = res.data;
            if (res.success === true) {
              toast.success("Sheme added successfully");
              this.openAddModal();
              this.getData();
              this.setState({ loader: false });
            } else {
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          console.log(err);
        });
    }
  };

  ///multiple delete

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id);
    });

    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete this Sheme?",
        // text: "Once deleted, you will not be able to recover this disaster",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            id: ids.toString(),
          };
          adminService
            .httpPost("/data-import/scheme/delete-scheme", data)
            .then((res) => {
              if (res) {
                let d = res.data;
                if (res.status === true) {
                  toast.success("Scheme deleted successfully");
                  this.getData();
                } else {
                  toast.error("Failed to delete Scheme");
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });
      return false;
    }
  };

  // get all indicators===================//

  getAllIndicators = async () => {
    adminService
      .httpGet("/data-retrieval/department/get-all-departmentlist")
      .then((res) => {
        if (res) {
          let d = res.data;
          console.log(d,"396smkdm")
          this.setState({ departmentdata: d, rowIndData: d });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  lang: state.lang.lang,
});

export default connect(mapStateToProps)(ManageScheme);
