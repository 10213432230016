import React from 'react';
import lazyLoader from './Common/components/lazy/lazyLoader.js';
import { Helmet } from "react-helmet";
import './Common/custom.js'
import { HashRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';
import Area from './admin/pages/area/area';
import Masterlist from './admin/pages/master-list/masterlist';
import DataApprove from './admin/pages/data_approve/data_approve';
import Resources from './admin/pages/manage-resource/resources';
import DataSources from './admin/pages/data_source/data_source';
import Actor from './admin/pages/manage-actor/actor.js';
import Login from './Common/pages/login';
import Indicator from './admin/pages/indicator';
// import Disaster from './admin/pages/disaster/index.js';
import User from './admin/pages/user';
import Department from './admin/pages/department/index.js';
import ManageScheme from './admin/pages/manage-scheme/index.js';
import Popper from 'popper.js';
import SummaryPage from './admin/pages/summary';
import { ToastContainer } from 'react-toastify';
import DashboardComponent from './dashboard/dashboard.jsx';
import DataEntry from './admin/pages/data-entry/index.js';
import ManageForm from './admin/pages/manage-form/index.js';
import Metadata from './admin/pages/metadata/index.js';
import Generatereport from './admin/pages/generatereport/index.js';
import history from './services/history';
import Log from './admin/pages/log/index.js';
import { store } from './store';
import { Provider } from 'react-redux';
import { login } from './features/loginSlice';
import GeoData from './dashboard/pages/geo-data/geo-data.js';
import HomePage from './dashboard/pages/home/home.js';
import InformationPage from './dashboard/pages/information-kiosk/information-kiosk.js';
import DataanalyticsPage from './dashboard/pages/data-analytics/data-analytics.js';
import ContactPage from './dashboard/pages/contact/contact.js';
import ProfilePage from './dashboard/pages/profile/profile.js';
import TrackerPage from './dashboard/pages/tracker/tracker.js';
import DashboardPage from './dashboard/pages/dashboard/dashboard.js';
import ConfirmEmail from './Common/pages/confirmEmail/ConfirmEmail.js';
import { RolesAuthRoute } from './Common/components/auth/rolesAuthGuard.js';
import ForgotPassword from './Common/pages/forgotPassword/ForgotPassword.js';
import LanguagePage from './admin/pages/language/language.js'
import AboutPage from './dashboard/pages/about/about.js';
// import DataSearch from './dashboard/pages/data-search/data-search.js';
// import DataSearchresult from './dashboard/pages/data-search-results/data-search-result.js';

import DataSearchComponent from './dashboard/pages/data-search-component/index.js';

import ManageAggregations from './admin/pages/manage-aggregations/aggregation.js';
import FormMaster from './admin/pages/form-master/index.js';
import Webdataentry from './dashboard/pages/web-data-entry/web-data-entry.js';
import TimePeriod from './admin/pages/time-period/timeperiod.js';
import SubGroup from './admin/pages/sub-group/sub-group.js'
import ManageGoals from './admin/pages/manageGoals/manage-goal.js';
import ReportSettings from './admin/pages/generatereport/reportSettings.js';
import AuditTrailComponent from './admin/pages/audit_trail/audit_trail.js';
import ManageGids from './admin/pages/manageGids/manageGids.js';
import ExportSDMX from './admin/pages/sdmx/export-sdmx/exportSDMX.js';
import ImportSDMXComponent from './admin/pages/sdmx/import-sdmx/importSDMXComponent.js';
import ManageMappingComponent from './admin/pages/sdmx/sdmx-mapping/manageMappingComponent.js';
import TopicSubtopic from './admin/pages/topic-subtopic/topic-subtopic.js';
import OverviewPage from './dashboard/pages/overview/overview.js';
import MetadataPage from './dashboard/pages/metadata/metadata.js';
import { useEffect } from 'react';
import * as $ from 'jquery';
//import AdminHomeComponent from './admin/pages/adminHome.jsx';


const AdminHomeComponent = lazyLoader(() => import('./admin/pages/adminHome.jsx'));


function App(props) {

  // const jsonUser =  JSON.parse(localStorage.getItem('userInfo'));

  // useEffect(()=> {
  //   $(document).ready(function(){
  //     $(".burger_box").on("click", function () {
  //       $(".menu-dropdown").addClass("show-menu");
  //     });
      
  //     $(".hmClose, .nav-link").on("click", function () {
  //         $(".menu-dropdown").removeClass("show-menu");
  //     });
  //   })
 

  // }, []);


  const getLoginInfoFrmLocalStorage = () => {
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');
      const userInfo = localStorage.getItem('userInfo');


      if (token && token != '' && email && email != '' && userInfo && userInfo != '') {
        return {
          email: email,
          token: token,
          userInfo: JSON.parse(userInfo)
        };
      } else {
        // history.push('/home');
        return null;
      }

    }
    catch (e) {
      console.log(e)
    }
  }

  const loginInfo = getLoginInfoFrmLocalStorage()
  if (loginInfo) {
    store.dispatch(login(loginInfo));
  }

  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <Router history={history}>
          <Helmet>
            <meta charSet="utf-8" />
          </Helmet>
          <div className="App">

            <Routes>
              <Route path='/' element={<Navigate to="/home" />} />
              {/* <Route path='/login' element={<Login />} /> */}
              <Route path='/datamanager' element={<Login />} />
              <Route path='/forgetPassword' element={<ForgotPassword />} />
              <Route path='/confirmEmail' element={<ConfirmEmail />} />
              <Route path='/home' element={<HomePage />} />
              <Route path='/information-kiosk' element={<InformationPage />} />

              {/* <Route path='/dashboard/data-analysis' element={<TrackerPage />   } /> */}
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/about' element={<AboutPage />} />
              <Route path='/web-data-entry' element={<Webdataentry />} />

              <Route path='/dashboard' element={

                <DashboardComponent />

              }>
                <Route path='/dashboard/data-analysis' element={<TrackerPage />} />
              </Route>


              <Route path='/admin' element={
                <RolesAuthRoute roles={[1, 2, 4, 7]}>
                  <AdminHomeComponent />
                </RolesAuthRoute>


              } >
                <Route path='Summary' element={<RolesAuthRoute roles={[1]}><SummaryPage /></RolesAuthRoute>} />
                <Route path='import-area' element={<RolesAuthRoute roles={[1]}><Area /></RolesAuthRoute>} />
                <Route path='manage-indicator' element={<RolesAuthRoute roles={[1]}><Indicator /></RolesAuthRoute>} />
                {/* <Route path='disaster' element={<RolesAuthRoute roles={[1]}><Disaster /></RolesAuthRoute>} /> */}
                <Route path='department' element={<RolesAuthRoute roles={[1]}><Department /></RolesAuthRoute>} />
                <Route path='manage-scheme' element={<RolesAuthRoute roles={[1]}><ManageScheme /></RolesAuthRoute>} />
                <Route path='import-metadata' element={<RolesAuthRoute roles={[1]}><Metadata /></RolesAuthRoute>} />
                <Route path='user-management' element={<RolesAuthRoute roles={[1]}><User /></RolesAuthRoute>} />
                <Route path='data-entry' element={<RolesAuthRoute roles={[2, 1]}><DataEntry /></RolesAuthRoute>} />
                <Route path='data-approve' element={<RolesAuthRoute roles={[4, 1]}><DataApprove /></RolesAuthRoute>} />
                <Route path='manage-goals' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><ManageGoals /></RolesAuthRoute>} />
                <Route path='sector-subsector' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><TopicSubtopic /></RolesAuthRoute>} />
                <Route path='manage-gids' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><ManageGids /></RolesAuthRoute>} />
                <Route path='export-sdmx' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><ExportSDMX /></RolesAuthRoute>} />
                <Route path='import-sdmx' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><ImportSDMXComponent /></RolesAuthRoute>} />
                <Route path='mapping-sdmx' element={<RolesAuthRoute roles={[1, 2, 4, 7]}><ManageMappingComponent /></RolesAuthRoute>} />
                <Route path='form-master' element={<RolesAuthRoute roles={[1, 2]}><FormMaster /></RolesAuthRoute>} />

                <Route path='timeperiod' element={<RolesAuthRoute roles={[1]}><TimePeriod /></RolesAuthRoute>} />
                <Route path='subgroup' element={<RolesAuthRoute roles={[1]}><SubGroup /></RolesAuthRoute>} />
                {/* <Route path='manage-aggregation' element={<RolesAuthRoute roles={[1]}><ManageAggregations/></RolesAuthRoute>} /> */}
                <Route path='manage-resource' element={<RolesAuthRoute roles={[1]}><Resources /></RolesAuthRoute>} />
                <Route path='data-source' element={<RolesAuthRoute roles={[1]}><DataSources /></RolesAuthRoute>} />
                <Route path='master-list' element={<RolesAuthRoute roles={[1]}><Masterlist /></RolesAuthRoute>} />
                <Route path='report-settings' element={<RolesAuthRoute roles={[1]}><ReportSettings /></RolesAuthRoute>} />
                <Route path='generate-report' element={<RolesAuthRoute roles={[1]}><Generatereport /></RolesAuthRoute>} />
                <Route path='audit-trail' element={<RolesAuthRoute roles={[1]}><AuditTrailComponent /></RolesAuthRoute>} />
                <Route path='import-log' element={<RolesAuthRoute roles={[1, 2]}><Log /></RolesAuthRoute>} />
                {/* <Route path='manage-language' element={<RolesAuthRoute roles={[1]}><LanguagePage/></RolesAuthRoute>} /> */}
              </Route>

              {/* dahboard routes */}

              <Route path='/dashboard' element={
                // <RolesAuthRoute roles={[1,2,4,5]}>
                <DashboardComponent />
                // </RolesAuthRoute>                              
              }>

                <Route path='dashboard' element={<DashboardPage />} />

                {/* <Route path='profile' element={ <RolesAuthRoute roles={[5]}><ProfilePage /></RolesAuthRoute>}  /> */}
                <Route path='geo-data' element={<GeoData />} />
                {/* <Route path='data-search-old' element={<DataSearch />} /> */}
                {/* <Route path='data-search-result' element={<DataSearchresult />} /> */}

                <Route path='data-search' element={<DataSearchComponent />} />
                <Route path='profile' element={<DataanalyticsPage />} />
                <Route path='overview' element={<OverviewPage />} />
                <Route path='metadata' element={<MetadataPage />} />

              </Route>


              <Route path='*' element={<HomePage />} />
            </Routes>

          </div>

        </Router>
      </Provider>
    </>
  );
}

export default App;
