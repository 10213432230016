import React, { Component, useRef } from "react";
import "./tracker.css";
import * as jsx from "./tracker.module.jsx";
// import adminService from "../../../services/admin.service";
// import "react-toastify/dist/ReactToastify.css";
import eventBus from "../../../features/eventBus";
import * as $ from "jquery";
import { connect } from "react-redux";
import AdminService from "../../../services/admin.service";
// import { store } from "../../../store";
import { setHeaderAreaName, setHeaderLevel, setHeaderDisasterCode, setGlovalLevel, setHeaderAreaCode, setHeaderDisasterName } from "../../../features/formStateSlice"
import { setAreaName, setDisasterTypeName, setDisasterTypeCode } from '../../../features/areaNameSlice'
import { setAreaCode } from '../../../features/areaSlice'
import withRouter from "../../../services/withRouter";
// import * as echarts from 'echarts';

import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Checkbox, MenuItem } from "@mui/material";

class TrackerPage extends Component {




  constructor(props) {
    super(props);
    this.location = this.props?.location;
    this.rangeData = [];
    this.route = this.location?.pathname
    this.reportTemplateRef = React.createRef();
    this.state = {
        finalMetaData:{},
      sourceDataIndex: null,
      value: 0,
      indicatorType: (
        <>
          Number of houses destroyed <span>| Number</span>
        </>
      ),
      totalType: "Total",
      chartLabel: false,
      yearType: "2021",
      totalIndicators:0,
      loader: false,
      changeAreaName: "ASITLS",
      setValue: null,
      downloadTableData: [],
      filterTableData: [],
      indDisable: true,
      indicatorRender: [],
      DataView:[],
      isGoal:false,
      onlyTarget:false,

            data: [],
            tableData: [],
            areaObject: null,
            areaData: [],
            callData: [],
            show: false,
            collapsed: true,
            goalData: [],
            mainAreaData: [],

            selectIndicator: {
                id: "",
                name: "",
                unit: "",
            },
            subGroupData: [],
            timePeriodData: [],
            selectedLevel: [],
            subGroupSelected: {
                id: "",
                name: "",
            },
            slectedPeriod: "",
            defOptions: [],
            source: [],
            dataRanges: [],
            labelOn: 1,
            metadata: [],
            selectedMetaInfo: [],
            searchValue: "",
            searchValueData: [],
            levelData: [],
            currentLevelValue: 2,
            currentSource: "",
            isLevel: false,
            levelArr: [],
            tableDataExit: [],

            areaName: this.props?.formStateAreaName,
            areaCode: this.props?.formStateAreaCode,
            disaterName: this.props?.formStateDisasterName,
            areaLabel: this.props?.formStateLevel,
            totalIus: 0,

            // selectedSub:[],
            // selectedTp:[],
            // source:'',
            // afterDat:[],
            widgetData: [],

            filterChartDataTable: [],
            dataSet: [],
            indicatorList: [],
            sourceStoredata: "",
            sdgGoal: this.location?.state?.goalname ? this.location?.state?.goalname : 'Goal 1:',
            downloadCsv: false,

            // locationGoalData: this.props.location.state.dataToDisplay,
            // locationGoalTargetData: this.props.location.state.goalTargetData,
            activeN: 1,
            selectedGoalData: [],
            checkSDG: null,
            level2Data: [],
            level3Data: [],
            level3ShowData: [],
            stateValue: {
                areaName: '',
                areaCode: '',
            },
            districtValue: {
                areaName: '',
                areaCode: ''
            },
            indicatorValue: {
                indName: '',
                indId: ''
            },
            targetValue: {
                targetName: '',
                targetId: ''
            },

            chartData: null,
            chartType: {},
            isGeo: false,
            age: '',
        };


        this.chartRefs = "";
        this.index = [];
        this.chartLoaded = [];

        this.defOption = {
            title: {
                show: true,
                textStyle: {
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "Mulish",
                    fontWeight: "400",
                },
                text: "Loading...",
                left: "center",
                top: "center",
            },
            xAxis: {
                show: false,
            },
            yAxis: {
                show: false,
            },
            series: [
                {
                    type: "bar",
                    barMaxheight: "100px",
                    barMinheight: "100px",
                },
            ],
        };
        this.charts = ["bar", "line", "pie"];
        this.chartIcon = {
            bar: "assets/lib/images/home/multi_bar_chart.svg", //assets/lib/images/charts/bar_chart.svg
            line: "assets/lib/images/home/line_chart.svg", //assets/lib/images/charts/line.svg
            pie: "assets/lib/images/home/pie_chart.svg", //assets/lib/images/charts/pie.svg
        };
        this.optionData = []

    }



    componentDidMount() {

        $('body').removeClass('src_list');
        $(document).on('click', '.main_drop_css  .dropdown-menu', function (e) {
            e.stopPropagation();
        });

        this.getAllAreaData();

        // const { dataToDisplay } = this.props.location.state;

        // Set the default selected goal name
        // if (dataToDisplay) {
        //   this.setState({ selectedGoalData: dataToDisplay });
        // }

        // Set the initial data based on homepage data
        // if (goalTargetData) {
        //   this.setState({ selectedGoalData: goalTargetData });
        // }

        let active = 0;
        switch (this.location?.state?.goalname ? this.location?.state?.goalname : this.state.sdgGoal) {
            case "Goal 1:":
                active = 0;
                break;
            case "Goal 2:":
                active = 1;
                break;
            case "Goal 3:":
                active = 2;
                break;
            case "Goal 4:":
                active = 3;
                break;
            case "Goal 5:":
                active = 4;
                break;
            case "Goal 6:":
                active = 5;
                break;
            case "Goal 7:":
                active = 6;
                break;
            case "Goal 8:":
                active = 7;
                break;
            case "Goal 9:":
                active = 8;
                break;
            case "Goal 10:":
                active = 9;
                break;
            case "Goal 11:":
                active = 10;
                break;
            case "Goal 12:":
                active = 11;
                break;
            case "Goal 13:":
                active = 12;
                break;
            case "Goal 14:":
                active = 13;
                break;
            case "Goal 15:":
                active = 14;
                break;
            case "Goal 16:":
                active = 15;
                break;
            case "Goal 17:":
                active = 16;
                break;
        }
        this.setState({ activeN: active + 1 });

        let flag = false;
        var legendData = [];


        eventBus.on("triggerArea", (data) => {



            this.rangeData = data.rangeArr;
            legendData.push(data.rangeArr);
            // data?.rangeArr?.forEach(element => {
            // 	let obj = {
            // 		color: element.color, value: element.value
            // 	}
            // 	legendData.push(obj)
            // });

            this.setState({ dataRanges: data.rangeArr, isLevel: false, currentSource: "" });





            // if (!flag) {
            //   this.getAllAreaData();
            // }
            // flag = true;
        });









        this.getAllAreaData();
        this.goalData();



        this.loadChartView()
    }




    // handleSubgroupChange = (e, chartData, source) => {
    //   const selectedSubgroups = [e.target.value];


    //   // if (selectedSubgroups.length === 0) {
    //   //   setSelectedSub(['None Selected']);
    //   //   setSelectedTp(['None Selected']);
    //   //   setIsAllSelectedSub(false);
    //   //   setIsAllSelectedTp(false);
    //   //   setDat([]);
    //   //   setBakupDat([]);
    //   //   return;
    //   // }

    //   let newDat = [];
    //   let tpData = new Set();

    //   let sortedData = chartData.sort((a, b) => {
    //     return parseInt(a.timeperiod) - parseInt(b.timeperiod);
    //   });

    //   sortedData.forEach(item => {

    //     if (selectedSubgroups.includes(item?.subgroup)) {
    //       newDat.push(item);
    //       tpData.add(item?.timeperiod);
    //     }
    //   });
    //   // console.log("Subgroup Change-", newDat,tpData);
    //   let dataSelSub = selectedSubgroups?.filter((subg) => subg !== 'None Selected');

    //   this.setState({ selectedSub:[...new Set(dataSelSub?.flat(Infinity))], [this.state.selectedTp]:Array.from(tpData) });

    //   // Filter subgroups based on the selected years
    //   const selectedYears = [...tpData].map(timeperiod => timeperiod.substring(0, 4));

    //   // Filter the data to include only items with selected years and subgroups
    //   const filteredDat = newDat.filter(item =>
    //     selectedYears.includes(item?.timeperiod.substring(0, 4)) &&
    //     selectedSubgroups.includes(item?.subgroup)
    //   );

    //   let finalDat = [];
    //   filteredDat?.forEach(item => {
    //     let sbData = []
    //     item?.subgroup?.forEach(sb => {

    //       sbData.push(sb)

    //     })

    //     item['subgroup'] = sbData;

    //     finalDat.push(item)
    //   })

    //   console.log('finalDattttttt1', finalDat)



    // };




    //    generateSubgroupMenuItems = (data, selectedSub) => {





    //     const menuItems = [];

    //     let selectedSubgroup = selectedSub.filter((item) => item !== 'None Selected');
    //     let fnData = []

    //     data?.forEach(item => {

    //       item?.subgroup.forEach(sb => {
    //         fnData.push(sb)
    //       })

    //     })




    //     let uniqueValues = [...new Set(fnData)];


    //     console.log('checkDataUnnn', uniqueValues)

    //     for (const item of uniqueValues) {


    //       menuItems.push(
    //         <MenuItem key={item} value={item}>
    //           {/* <Checkbox checked={selectedSubgroup.indexOf(item) > -1} /> */}
    //           {item}
    //         </MenuItem>
    //       );

    //     }


    //  this.setState({selectedSub:selectedSub})

    //     return menuItems;
    //   }



    // sendSource = (e) =>{

    //   this.setState({source:e})
    // }




    //   handleTimeperiodChange = (e, datad, source, mainChartData) => {
    //     const selectedTimeperiods = [e.target.value];
    //     console.log("This iss split issue-", selectedTimeperiods, e.target.value)

    //     // if (selectedTimeperiods.length === 0) {
    //     //   setSelectedSub(['None Selected']);
    //     //   setSelectedTp(['None Selected']);
    //     //   setIsAllSelectedSub(false);
    //     //   setIsAllSelectedTp(false);
    //     //   setDat([]);
    //     //   setBakupDat([]);
    //     //   return;
    //     // }

    //     let newDat = [];
    //     let subData = [];
    //     const data = datad?.length > 0 ? datad.filter(x => x.source == source) : datad.filter(x => x.source == source);
    //     console.log('lkndskknd onchangee', source)

    //     data.forEach(item => {
    //       if (selectedTimeperiods.includes(item.timeperiod)) {
    //         newDat.push(item);
    //         console.log('lkndskknd', item.subgroup)
    //         subData.push(item.subgroup);
    //       }
    //     });

    //     // const newDat = data.filter(item => selectedTimeperiods.includes(item.timeperiod));
    //     // const newSubData = selectedSub.filter(subgroup => {
    //     //   const correspondingTimeperiod = data.find(item => item.subgroup === subgroup);
    //     //   return selectedTimeperiods.includes(correspondingTimeperiod?.timeperiod);
    //     // });


    //     let sortedData = newDat?.sort((a, b) => {
    //       return parseInt(a.timeperiod) - parseInt(b.timeperiod);
    //     });



    //     let tp = selectedTimeperiods?.filter((time) => time !== 'None Selected');





    //     let finalDat = [];
    //     let sbData = []
    //     sortedData?.forEach(item => {

    //       item?.subgroup?.forEach(sb => {

    //         sbData.push(sb)

    //       })

    //       item['subgroup'] = sbData;

    //       finalDat.push(item)
    //     })

    //     const uniqueSubgroupValues = [...new Set(sbData)]



    //     this.setState({selectedSub:uniqueSubgroupValues?.map((x) => x)});
    //  console.log('tpDatttttt',finalDat )

    //  this.setState({selectedTp:tp, selectedSub:uniqueSubgroupValues?.map((x) => x), afterDat:finalDat});



    // console.log('props.selectedSub, props.selectedTp1', tp, this.state.selectedSub, finalDat)

    //   };



    //  generateTimeperiodMenuItems = (data, selectedTp) => {
    //   // this.setState({source:source})

    //   const uniqueTimeperiod = new Set();
    //   const menuItems = [];

    //   let sortedData = data.sort((a, b) => {
    //     return parseInt(b.timeperiod) - parseInt(a.timeperiod);
    //   });

    //   let selectedTimeperiod = selectedTp.filter((item) => item !== 'None Selected');

    //   for (const item of sortedData) {
    //     if (!uniqueTimeperiod.has(item?.timeperiod)) {
    //       uniqueTimeperiod.add(item?.timeperiod);
    //       menuItems.push(
    //         <MenuItem key={item?.timeperiod} value={item?.timeperiod}>
    //           {/* <Checkbox checked={selectedTimeperiod.indexOf(item?.timeperiod) > -1} /> */}
    //           {item?.timeperiod}
    //         </MenuItem>
    //       );
    //     }
    //   }

    //   this.setState({selectedTp:selectedTp})

    //   return menuItems;
    // }



    handleClick = () => {
        this.setState((prevState) => ({
            collapsed: !prevState.collapsed,
        }));
    };




    classChange = () => {

        $('body').addClass('sdg_list');
        //$('body').toggleClass('sdg_list');
    }
    geoSelect = () => {
        $('body').removeClass('sdg_list');
    }

    handleChange = (event) => {
        this.setState({ age: event.target.value });
    };









    onClearGeo = () => {
        this.setState({ isGeo: false })
    }


    downloadWidgets = () => {
        // $('.target_indi_area, .dsrBarchart_list ul').hide()
        const input = document.getElementById('divToDownload');
        // html2canvas(input)
        //   .then((canvas) => {
        //       const imgData = canvas.toDataURL('image/png');
        //       const pdf = new jsPDF();
        //       pdf.addImage(imgData, 'JPEG', 0, 0);
        //       pdf.save("download.pdf");
        //   })
        let container_counter = 0;
        let executedContainers = [];
        // input.scrollTo(0, document.body.scrollHeight);
        let printReady = false;

        if (executedContainers.length == container_counter && !printReady) {
            printReady = true;
            // setTimeout(() => {
            window.print();
            // }, 1500);

            //$('.target_indi_area, .dsrBarchart_list ul').show()
        }


    }


    // customSortInd= (a, b) => {
    //   function getNumericValue(name) {
    //     const numericPartRegex = /(\d+(\.\d+)?)/;
    //     const numericMatch = name.match(numericPartRegex);
    //     return numericMatch ? parseFloat(numericMatch[1]) : Number.MAX_SAFE_INTEGER;
    //   }

    //   const aNumericValue = getNumericValue(a.name);
    //   const bNumericValue = getNumericValue(b.name);

    //   if (aNumericValue !== bNumericValue) {
    //     return aNumericValue - bNumericValue;
    //   } else {
    //     return a.name.localeCompare(b.name);
    //   }
    // }


    //  customSort = (a, b) => {
    //   function getNumericValue(targetname) {
    //     const numericPartRegex = /(\d+(\.\d+)?)/;
    //     const numericMatch = targetname.match(numericPartRegex);
    //     return numericMatch ? parseFloat(numericMatch[1]) : Number.MAX_SAFE_INTEGER;
    //   }

    //   const aNumericValue = getNumericValue(a.targetname);
    //   const bNumericValue = getNumericValue(b.targetname);

    //   if (aNumericValue !== bNumericValue) {
    //     return aNumericValue - bNumericValue;
    //   } else {
    //     return a.targetname.localeCompare(b.targetname);
    //   }
    // }

    customSortInd = (a, b) => {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        return collator.compare(a.name, b.name);
    }


    customSort = (a, b) => {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        return collator.compare(a.targetname, b.targetname);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.sdgGoal !== this.state.sdgGoal) {
            let active = 0;
            switch (this.state.sdgGoal) {
                case "Goal 1:":
                    active = 0;
                    break;
                case "Goal 2:":
                    active = 1;
                    break;
                case "Goal 3:":
                    active = 2;
                    break;
                case "Goal 4:":
                    active = 3;
                    break;
                case "Goal 5:":
                    active = 4;
                    break;
                case "Goal 6:":
                    active = 5;
                    break;
                case "Goal 7:":
                    active = 6;
                    break;
                case "Goal 8:":
                    active = 7;
                    break;
                case "Goal 9:":
                    active = 8;
                    break;
                case "Goal 10:":
                    active = 9;
                    break;
                case "Goal 11:":
                    active = 10;
                    break;
                case "Goal 12:":
                    active = 11;
                    break;
                case "Goal 13:":
                    active = 12;
                    break;
                case "Goal 14:":
                    active = 13;
                    break;
                case "Goal 15:":
                    active = 14;
                    break;
                case "Goal 16:":
                    active = 15;
                    break;
                case "Goal 17:":
                    active = 16;
                    break;
            }
            this.setState({ activeN: active + 1 });
        }
    }

    handleSdgGoal = (event) => {

        let fixGoalVal = event.target.value
        let realData = this.state.goalData?.filter(row => row.goalname?.includes(fixGoalVal))



        const newStateValue = {
            ...this.state.indicatorValue,
            indName: '',
            indId: ''
        };

        const newStateValueT = {
            ...this.state.targetValue,
            targetName: '',
            targetId: ''
        };


        // const sortedInd = iusCount?.sort(this.customSortInd);  

        // realData[0]['target'] = sortedData;

        realData?.[0]?.target?.forEach((item, index) => {



            let iusList = item?.ius?.sort(this.customSortInd);


            realData[0].target[index]['ius'] = iusList;

        })

        let iusCount = []

        realData?.[0]?.target?.forEach(data => {

            data?.ius?.forEach(tr => {
                iusCount.push(tr)
            })

        })



        const resultArray = this.removeDuplicates(iusCount);


        // const sortedData = realData?.[0]?.target?.sort(this.customSort);

        // let finalSortInd =   iusCount?.sort(this.customSortInd);  


        this.setState({ isGeo: false, indicatorList: [], sdgGoal: fixGoalVal, targetValue: newStateValueT, indicatorValue: newStateValue, selectedGoalData: realData, totalIndicators: resultArray })

        this.loadChartView(realData?.[0]?._id, "", "", true)
        // element.classList.add('raajkumar')

    }


    // handleGoalSDG = (event) => {
    //   const value = event.target.value;

    //   let realData;

    //   if (this.props.location.state.goalTargetData) {
    //     realData = this.props.location.state.goalTargetData.filter(
    //       (x) => x.goalname.toLowerCase().includes(value.toLowerCase())
    //     );
    //   }

    //   this.setState({ selectedGoalData: realData });
    // };







    removeDuplicates = (arr) => {
        const seenIds = new Set();
        return arr.filter((obj) => {
            if (!seenIds.has(obj._id)) {
                seenIds.add(obj._id);
                return true;
            }
            return false;
        });
    };



    subgroupList = () => {

        $('body').removeClass('sdg_list');
    }




    setDataRange = (data) => {
        this.setState({ dataRanges: data });

    };

    render() {
        return jsx.default.bind(this)();
    }


    // state on change ====================///

    handleStateChange = (e, v) => {
        const newStateValue = {
            ...this.state.stateValue,
            areaName: v?.label || '',
            areaCode: v?.areaCode || ''
        };


        let dataToShow = this.state.level3Data?.filter((x) => {

            return x.parent_id === v?.areaCode;
        })


        this.setState({ stateValue: newStateValue, level3ShowData: dataToShow });


    }



    changeChartType = (type, id) => {
        let objectDt = this.state.chartType
        objectDt[id] = type;

        this.setState({ chartType: objectDt });
    }

    // district on change ====================///

    handleDistrictChange = (e, v) => {
        const newStateValue = {
            ...this.state.districtValue,
            areaName: v?.label || '',
            areaCode: v?.areaCode || ''
        };


        this.setState({ districtValue: newStateValue });


    }

    arraysAreEqual(arr1, arr2) {
        if (arr1 === arr2) return true;
        if (arr1.length !== arr2.length) return false;
      
        for (let i = 0; i < arr1.length; i++) {
          if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
            if (!this.arraysAreEqual(arr1[i], arr2[i])) {
              return false;
            }
          } else if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
      
        return true;
      }



      callBackMapData = (data) => {
        if (!this.arraysAreEqual(data, this.state.widgetData)) {
          console.log("Data is not the same as widgetData", this.arraysAreEqual(data, this.state.widgetData));
          this.setState({ widgetData: data }); // Update the state correctly
        }
      }
      


    showMetaData = (data) =>{
        console.log('metaaaaa', data)
 
        this.setState({finalMetaData:data})

        $("#metadata-modal").modal('show');
    }


    labelCallback =(label)=>{
        this.setState({chartLabel:label})
    }

    // indicator on change ====================///

    handleIndicatorChange = (e, v) => {
        this.setState({ loader: true })

        const newStateValue = {
            ...this.state.indicatorValue,
            indName: v?.label || '',
            indId: v?.indId || ''
        };

        if (e.target.value == undefined || e.target.value == null) {


            this.setState({ loader: true, isGeo: false, indicatorValue: newStateValue, chartType: { ...this.state.chartType, [this.state.indicatorValue?.indId]: 'bar' } })
            this.loadChartView(this.state.selectedGoalData?.[0]?._id, newStateValue.indId, this.state.targetValue?.targetId, true, false);

        } else {
            this.setState({ loader: true, isGeo: true, indicatorValue: newStateValue, chartType: { ...this.state.chartType, [newStateValue.indId]: 'map' } })
            this.loadChartView(this.state.selectedGoalData?.[0]?._id, newStateValue.indId, this.state.targetValue?.targetId, false, true)


        }

    }



    // target on change function=======================//

    handleTargetChange = (e, v) => {
        const newStateValue = {
            ...this.state.targetValue,
            targetName: v?.label || '',
            targetId: v?.targetId || ''
        };




        let selectedIusTarget = this.state.selectedGoalData?.[0]?.target?.filter(item => {
            return item?.targetid === v?.targetId;
        })




        const newStateValueI = {
            ...this.state.indicatorValue,
            indName: '',
            indId: ''
        };


        let finData = Object.values(selectedIusTarget?.[0]?.ius.reduce((acc, cur) => Object.assign(acc, { [cur._id]: cur }), {}));


        let iusList = finData?.sort(this.customSortInd);

        console.log('iusListiusList', iusList)


        let realData = this.state.goalData?.filter(row => row.goalname?.includes(this.state.sdgGoal))
        let iusCount = []

        realData?.[0]?.target?.forEach(data => {

            data?.ius?.forEach(tr => {
                iusCount.push(tr)
            })

        })





        let indoCount = selectedIusTarget?.[0]?.ius?.length !== undefined ? selectedIusTarget?.[0]?.ius?.length : iusCount?.length;


    this.setState({onlyTarget:true, isGeo:false, loader:true, indDisable: false, chartType: {[this.state.indicatorValue?.indId]:'bar'}, targetValue: newStateValue, totalIus: indoCount, indicatorList: iusList, indicatorValue: newStateValueI, })


        this.loadChartView(this.state.selectedGoalData?.[0]?._id, this.state.indicatorValue?.indId, newStateValue?.targetId, false)

    }



    onClearTarget = () => {


    this.handleSdgGoal({ target: { value: this.state.sdgGoal } })
    this.setState({isGeo:false, onlyTarget:false})

    }


    // get area data api======================///////////

    getAllAreaData = () => {
        this.setState({ loader: true });

        AdminService.httpGet("/data-retrieval/area/getAreaData")
            .then((res) => {
                if (res) {
                    // this.setState({ loader: false })
                    let d = res.data;


                    let level2 = d?.filter((lvl) => lvl?.level === 2);
                    let level3 = d?.filter((lvl) => lvl?.level === 3)




                    this.setState({ level2Data: level2, level3Data: level3, areaData: d, loader:false })



                }


            })
            .catch((err) => {
                this.setState({ loader: false });

            });
    };





    // get goal data api==================///////////

    goalData = () => {
        this.setState({ loader: true });

        AdminService.httpGet("/data-retrieval/generic/get-goal-ius")
            .then((res) => {
                if (res) {
                    // this.setState({ loader: false })
                    let d = res.data;

                    d?.forEach(item => {

                        let sortTargets = item?.target?.sort(this.customSort);
                        item['target'] = sortTargets;


                    })






                    this.setState({ goalData: d, loader:false })




                    this.handleSdgGoal({ target: { value: this.location?.state?.goalname ? this.location?.state?.goalname : 'Goal 1:' } })

                }


            })
            .catch((err) => {
                this.setState({ loader: false });

            });
    }

    falseCSV = (e) => {


        this.setState({ downloadCsv: false })


    }


    csvDownloadTrigger = () => {

        this.setState({ downloadCsv: this.state.downloadCsv === true ? false : true })


        // setTimeout(() => {
        //   this.setState({ downloadCsv: false })
        // }, 100)

    }



    loadChartView = async (goal, iu, target, isGoal, isMap = false) => {

        this.setState({ loader: true })

        const postData = {
            "goal_id": goal,
            "target": target,
            "area": "",
            "ius": iu
        }


        if (postData?.goal_id !== '' && postData?.goal_id !== undefined) {



            AdminService.httpPost("/data-retrieval/data/goal-target-data", postData)
                .then((res) => {
                    if (res) {




                        let d = res.list;
                        let chartDataObj = {};
                        let typeObject = {}
                        let allTypes = ['bar', 'pie', 'line', 'treemap', 'radar', 'doughnut']
                        // let random = Math.floor(Math.random() * 6);



                        d?.forEach((item) => {

                            if (item?.ius?.length > 0) {
                                let sortTargets = item?.ius?.sort(this.customSortInd);

                item['ius'] = sortTargets;
  
                item?.ius?.forEach((iu) => {
                 
  
                  let metaData = null;
                  let subgroupsData = {}
              
                 
                  this.state.goalData?.forEach(goal => {
  
                    let iuId = iu?._id;
  
  
                    goal?.target?.forEach(tr => {
  
                      tr?.ius?.forEach(ius => {
  
                        if (ius?._id === iuId) {
  
                          metaData = ius?.metadata?.[0];
                     
                          iu?.subgroup?.forEach(item=>{
                            subgroupsData[item._id] = item
                           
                           
                          })
  
                        }
  
  
  
  
                      })
  
                    })
  
                  })
  
  
  
                  iu['metaData'] = metaData;
  
                
  
                  let random = Math.floor(Math.random() * 6);
                  typeObject[iu?._id] = allTypes[random]
  
                  let iuData = []
  
  
                  iu?.data?.forEach(async (data)=>{
                 
                    const subDataArr =  subgroupsData?.[data.ius_id];
                 
            
             
  
                    let obj = {
                      area: data?.areaDetails?.[0]?.areaName,
                      area_code: data?.area_code,
                      area_level: data?.areaDetails?.[0]?.level,
                      parent_id: null,
                      indicator: data?.indicator?.en,
                      unit: data?.unit?.en,
                      timeperiod: data?.time_period?.start_time_period,
                      value: +data?.value,
                      subgroup:[subDataArr?.name?.en],
                      defaultSubgroup:subDataArr?.default_subgroup,
                      source: data.source?.publisher,
                      metaData: metaData,
                      
                    
  
                    }
                    
                   
  
                    iuData.push(obj)
  
  
  
                   
                  })
                  chartDataObj[iu?._id] =   iuData;
  
                 
                })
  
              }
          
          

                        })



                        let iusCount = []

                        d?.forEach(data => {

                            data?.ius?.forEach(tr => {
                                iusCount.push(tr)
                            })





                        })



                        let mainSortData = d?.sort(this.customSort);


                        console.log('mainSortedIus', iusCount,)
                        // let mainSortedIus = iusCount?.sort(this.customSortInd);

                        let x = {};
                        if (isMap) {
                            x = { DataView: mainSortData, indicatorRender: iusCount, chartData: mainSortData, dataSet: chartDataObj, loader: false };

                        } else {
                            x = { DataView: mainSortData, indicatorRender: iusCount, chartData: mainSortData, dataSet: chartDataObj, loader: false, chartType: typeObject }

                        }


                        if (isGoal) {
                            this.setState({ isGoal: isGoal, indicatorRender: iusCount, loader: false })
                        }
                        this.setState(x);

                        this.setState({ loader: false });




                    }


                })
                .catch((err) => {
                    this.setState({ loader: false });

                });

        }

    }







}




function mapStateToProps(state) {

    return {
        area: state.area?.areaCode,
        lang: state.lang?.lang,
        disasterNameType: state?.areaName?.disaterName,
        disaterCodeType: state?.areaName?.disastercode,
        levelValue: state?.currentLevel?.level,
        areaName: state?.areaName?.areaName,
        formStateAreaName: state?.formSlice?.areaCode,
        formStateAreaCode: state?.formSlice?.disasterCode,
        formStateDisasterName: state?.formSlice?.disasterName,
        formStateLevel: state?.formSlice?.level,
        formStateGlovalLevel: state?.formSlice.glovalLevel
    }
}

const mapDispatchToProps = { setAreaCode, setHeaderDisasterName, setHeaderAreaName, setHeaderLevel, setHeaderDisasterCode, setHeaderAreaCode, setGlovalLevel, setAreaName, setDisasterTypeName, setDisasterTypeCode };





const connectedProps = connect(mapStateToProps, mapDispatchToProps)(TrackerPage);
export default withRouter(connectedProps);
