import "./ExportArea.css";
import * as constants from "../../../Config/app.constants";

import { useEffect, useRef, useState } from "react";

import AdminService from "../../../services/admin.service";
import { useLocation } from "react-router";

const ExportWithData = ({ value, nRows }) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadedData, setDownloadedData] = useState([]);

  const [approvedDataCount, setApprovedDataCount] = useState();

  const location = useLocation();
  const route = location.pathname;

  // console.log("value--" + value)

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  const currentDate =
    today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  const todaysDate =
    String(currentYear) + String(currentMonth) + String(currentDate);

  if (value == 11) {
    value = 9;
  } else if (value == 10) {
    value = 18;
  } else if (value == 12) {
    value = 10;
  } else if (value == 13) {
    value = 14;
  } else if (value == 14) {
    value = 12;
  } else if (value == 15) {
    value = 11;
  } else if (value == 16) {
    value = 13;
  } else if (value == 17) {
    value = 17;
  } else if (value == 18) {
    value = 24;
  } else if (value == 19) {
    value = 23;
  } else if (value == 20) {
    value = 25;
  } else if (value == 21) {
    value = 27;
  } else if (value == 22) {
    value = 22;
  } else if (value == 23) {
    value = 26;
  } else if (value == 27) {
    value = 27;
  } else if (value == 28) {
    value = 28;
  }
  // else if (value == 50) {
  //   value = 1;
  // }
  // else if (value == 51) {
  //   value = 2;
  // }else if (value == 52) {
  //   value = 3;
  // }else if (value == 53) {
  //   value = 4;
  // }else if (value == 54) {
  //   value = 5;
  // }else if (value == 55) {
  //   value = 6;
  // }else if (value == 56) {
  //   value = 7;
  // }else if (value == 57) {
  //   value = 8;
  // }

  // useEffect(async () => {

  // const datamn = await AdminService.httpPost('/data-import/data/get-approvedData-count').then((res) => {
  //   try {
  //     if (res) {
  //       setApprovedDataCount(res.data)
  //     }
  //     else {
  //       console.log("error");
  //     }
  //   } catch (error) {
  //     console.log("This is error--", error)
  //   }

  // })
  // }, [])

  const fetchData = async (e) => {
    e.preventDefault();

    setLoader(true);

    if (
      value === 50 ||
      value === 51 ||
      value == 52 ||
      value == 53 ||
      value == 54 ||
      value == 55 ||
      value == 56 ||
      value == 57
    ) {
      
      await AdminService.httpGet("/data-sdmx/sdmx/export-gid/" + value)
        .then((res) => {
          e.preventDefault();
          if (res) {
            let d = res;
            if (d?.success == true) {
              setLoader(false);
            }

            let filepath = constants.logFilePath + d.filepath;
            let filename = d.filepath.replace(/\.[^/.]+$/, "");

            let a = document.createElement("a");
            a.href = filepath;
            a.download = "SDG_" + filename + todaysDate + ".csv";

            document.body.appendChild(a);
            setTimeout(() => {
              a.click();
            }, 1000);
            document.body.removeChild(a);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      await AdminService.httpGet("/data-retrieval/data/exportData/" + value)
        .then((res) => {
          e.preventDefault();
          if (res) {
            let d = res;
            if (d?.success == true) {
              setLoader(false);
            }

            let filepath = constants.logFilePath + d.filepath;

            console.log("filepath:" + filepath);
            let filename = d.filepath.replace(/\.[^/.]+$/, "");

            let a = document.createElement("a");
            a.href = filepath;
            a.download = "SDG_" + filename + todaysDate + ".csv";

            document.body.appendChild(a);
            setTimeout(() => {
              a.click();
            }, 1000);
            document.body.removeChild(a);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      {loader == true && (
        <div
          className={
            loader == true ? "loader text-center " : "loader text-center hide"
          }
        >
          <img
            src="assets/images/Comp-2.gif"
            style={{ marginTop: "12%", width: "30%" }}
          />
        </div>
      )}
      
      <div className="w-100">
        <button
          type="button"
          onClick={(e) => fetchData(e)}
          class="btn btn_browse w-100 popup_btn"
          id="download_icius_master_template_with_data"
          disabled={ value == 1 ? false : (nRows > 0? false : true)}
          //  disabled={route != '/admin/data-entry' ? (nRows == 0 ? true : false) : (approvedDataCount == 0 ? true : false)}
        >
          With Data{" "}
        </button>
      </div>
      {console.log("downloadedData1",downloadedData)}
    </>
    
  );
};

export default ExportWithData;
