import React from "react";
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import SourceAddModal from "../../Modals/addSourceModal.model";

const dataSourceTemplate = function () {
  return (
    <>
      <div className="main manage-resource" id="main">
        <div className="container-fluid areaCont">
          <section className="areaSection" id="areaSection">
            <subHeaderComponentMemo.default
              onSearch={this.onSearch}
              searchText={this.state.searchText}
              PropDeleteBulk={this.deleteBulkUser}
              nRows={this.state.filteredData?.length}
              checkData={this.state.bulkDelete}
              value='31'
            />

            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              persistTableHead
              selectableRows
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className="dataTableScroll"
              noDataComponent="No Data"
              fixedHeader
            />
          </section>
        </div>
      </div>

      {/* modal area begin here============== */}

      <div
        id="AddAreaModal"
        className={
          this.state.modal
            ? "gallery-sidebar add-area-modal add-form width350"
            : "gallery-sidebar add-area-modal add-form width0"
        }
      >
        <div
          id="openDataModal"
          onClick={this.openAddModal}
          ariaTooltip="Button that displays and hides a tooltip triggered by other buttons"
          className=" gallery-toggler maroon_bg add-user "
          style={{
            touchAction: "none",
            userSelect: "none",
            WebkitUserDrag: "none",
            WebkitTopHighlightColor: "rgba(0, 0, 0, 0)",
          }}
          aria-describedby="cdk-describedby-message-38"
          cdk-describedby-host=""
        >
          <img src="assets/lib/images/svg/add1.svg" style={{ width: "17px" }} />
        </div>
        <div class="right-tab-heading are_head">
          <h2 class="form-heading">
            <span class="form-heading_he">
              <span class="ddf">
                {this.state.isEdit == true
                  ? "Edit Data Source"
                  : "Add Data Source"}
              </span>
            </span>
          </h2>
        </div>
        <SourceAddModal
          formList={this.state.formList}
          newDataEntry={this.saveData}
          editData={this.state.editEntry}
          isEdit={this.state.isEdit}
          closeModal={this.openAddModal}
        />
      </div>

      <div
        className={
          this.state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

      <div className={this.state.modal ? "overlay dblock" : "overlay"}></div>
    </>
  );
};

export default dataSourceTemplate;
