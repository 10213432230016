import React, { Component } from 'react'
import './aggregation.css';
import * as jsx from './aggregations.module';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import dayjs from 'dayjs';
import AdminService from '../../../services/admin.service';
import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
class ManageAggregations extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            aggregatedData: [],
            headersForAggregatedData: [
                { label: "AreaID", key: "AreaID" },
                { label: "AreaName", key: "AreaName" },
                { label: "Timeperiod", key: "Timeperiod" },
                { label: "Source", key: "Source" },
                { label: "Indicator", key: "Indicator" },
                { label: "PlanName", key: "PlanName" },
                { label: "Subgroup", key: "Subgroup" },
                { label: "DataValue", key: "DataValue" },
                { label: "DisasterName", key: "DisasterCode" },
                { label: "Footnote", key: "Footnote" },

            ],
            filteredData: [],
            searchText: '',
            formList: [],
            isEdit: false,
            editModeId: null,
            modal: false,
            loader: false,
            dataLimit: 10,
            // image:"",
            // document:"",
            // innovationLogoBase64:"",
            // filename:"",
            dataStatus: 2,
            indicatorData: [],
            rowIndData: [],
            subIndData: [],
            disasterData: [],
            areaData: [],
            bulkDelete: [],
            editEntry: [],

        }



    }

    componentDidMount() {
        this.getFormList(1);
        this.getDisasterData()
        setTimeout(() => {
            this.getData();
        }, 200);

        AdminService.checkToken();

    }


    render() {
        return jsx.default.bind(this)();
    }



    getFormList = async (status) => {
        var payload = payload = { "status": status };

        await AdminService.httpPost('/data-retrieval/survey/get-survey-list', payload).then((res) => {
            this.setState({ formList: res.data })
        }).catch((err) => {

            console.log(err)
        });
    }

    onSearch = (val) => {
        this.setState({ searchText: val });
        let backupData = this.state.data;
        let d = this.state.filteredData;
        let fd = null;
        if (val?.length > 0) {
            fd = d.filter(item => {
                const matchingForm = this.state.formList?.find(x => x?._id === item?.survey_id);
                const matchingDisaster = this.state.disasterData?.find(x => x?._id === item?.disasterID);

                return (matchingForm && matchingForm?.name?.en.toLowerCase().includes(val?.toLowerCase())) ||
                    (matchingDisaster && matchingDisaster?.title?.en.toLowerCase().includes(val?.toLowerCase())) ||
                    (item && item?.periodicity?.toLowerCase()?.includes(val?.toLowerCase()));
            });
            console.log("This is fd after filter-", fd)
            this.setState({ filteredData: fd })
        }
        else {
            this.setState({ filteredData: backupData });
            // this.getDate();
        }
    }

    sortWithToolTip = (rowA, rowB) => {
        const b = rowB?.survey_id;
        const a = rowA?.survey_id;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };



    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => row.index + 1,


        //     sortable: false,
        //     width: "50px"
        // },
        {
            name: 'Form',
            selector: row => {
                const matchingForm = this.state.formList?.find(x => x?._id === row?.survey_id);
                return matchingForm ? (
                    <Tooltip title={matchingForm?.name?.en} placement="top">
                        <p>{matchingForm?.name?.en}</p>
                    </Tooltip>
                ) : null;
            },
            sortable: true,
            width: '160px',
            sortFunction: this.sortWithToolTip
        },
        {
            name: 'Disaster',
            selector: row => {
                const matchingDisaster = this.state.disasterData?.find(x => x?._id === row?.disasterID);
                return matchingDisaster ? matchingDisaster?.title?.en : null;
            },
            sortable: true,
            width: '130px'

        },
        {
            name: 'Periodicity',
            selector: row => row?.periodicity,
            sortable: true
        },
        {
            name: 'From Date',
            selector: row => row?.from_date ? dayjs(row?.from_date).format("YYYY-MM-DD") : '-',
            width: '130px',
            sortable: true,

        },
        {
            name: 'To Date',
            selector: row => row?.to_date ? dayjs(row?.to_date).format("YYYY-MM-DD") : '-',
            width: '130px',
            sortable: true,

        },
        {
            name: 'Added Records(#)',
            selector: row => row?.added_records,
            width: '170px',
            sortable: true,

        },
        {
            name: 'Updated Records(#)',
            selector: row => row?.updated_records,
            width: '182px',
            sortable: true,

        },
        {
            name: 'Error Records(#)',
            selector: row => row?.error_records,
            width: '160px',
            sortable: true,

        },
        {
            name: "Date Time",
            selector: row => dayjs(row?.createdAt).format("YYYY-MM-DD | h:mm A"),
            width: '200px',
            sortable: true,

        },
        {
            name: 'Status',
            selector: row => 'Completed',
            sortable: true,
            width: '120px'

        },

        {
            name: 'Action',
            width: "200px",
            cell: row =>
                <>
                    <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { this.downloadAggregatedData(row) }} href="javascript:;">Download</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.deleteEntry(row._id) }} href="javascript:;">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];







    editDataEntry = (id) => {


        const edData = [];
        this.state.filteredData.forEach((item) => {

            if (item._id == id) {
                edData.push(item)
                return;
            }

        });

        this.setState({ editEntry: edData })
        this.setState({ isEdit: true })
        this.openAddModal();

    }

    getDisasterData = async () => {
        this.setState({ loader: true })

        AdminService.httpGet('/data-retrieval/disaster/getDisasterData').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                // d.forEach((ele  , index)=>{ele.index = index })
                this.setState({ disasterData: d })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });






    }

    getData = async () => {
        this.setState({ loader: true, searchText: "" });

        const dataToBeSend = {};

        await AdminService.httpGet('/data-retrieval/aggregation/get-aggregations', dataToBeSend).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res?.data;
                console.log("new data check..", d);
                // d.forEach((ele  , index)=>{ele.index = index })
                this.setState({ filteredData: d, data: d })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }


    // save data===========================//

    saveData = async (e) => {

        if (e.id != null) {

            this.updateEntry(e)

        }
        else {
            this.addNewEntry(e)
        }

    }


    updateEntry = async (e) => {
        console.log("This is updateEntry--", e)
        // this.setState({ loader: true })
        const dataToBe2 = {

            "id": e.id,
            "description": e.description,
            "title": e.title,
            "url": e.url,
            "type": e.type,
            "doc": e.document ? e.document : e.referenceMaterials,
            "image": e.image


        }

        console.log('dten', dataToBe2)

        AdminService.httpPut('/data-import/resources/update/' + e.id, dataToBe2).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;

                console.log(res)
                if (res.success == true) {
                    toast.success("Resource updated successfully");
                    this.openAddModal();
                    this.getData();
                    this.setState({ isEdit: false })

                    this.setState({ editEntry: [] })
                }
                else {
                    toast.error(res.message);

                }


            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }

    // add new entry=============================///


    addNewEntry = async (e) => {

        console.log('dten', e)
        this.setState({ loader: true })
        const dataToBeSend = {


            "survey_id": e.survey_id,
            "disasterID": e.disasterID,
            "periodicity": e.periodicity,
            "from_date": e.from_date ? e.from_date : '',
            "to_date": e.to_date ? e.to_date : ''
        }



        AdminService.httpPost('/data-import/aggregation/add-aggregation', dataToBeSend).then((res) => {
            if (res) {
                this.setState({ loader: false })

                console.log(res)

                if (res.success == true) {
                    toast.success("Aggregation added successfully");
                    this.openAddModal();
                    this.getData();
                }
                else {
                    toast.error(res.message);

                }

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }



    // update status==================//
    changeDataStatus(e, id, status) {
        this.setState({ loader: true })
        let changed = 0;

        if (status == 1) {
            changed = 0;
        }
        else {
            changed = 1;
        }

        let statusData = { "id": id, "resStatus": changed };


        AdminService.httpPost('/data-import/resources/update-status', statusData).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                console.log(res)

                if (res.success == true) {
                    toast.success("Resource status updated successfully");
                    this.getData();
                }
                else {
                    toast.error("Failed to update status");

                }

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }

    downloadAggregatedData = async (row) => {

        // debugger;
        console.log("This is row", row)
        try {
            this.setState({ loader: true })

            this.state.aggregatedData = [];
            const data = {
                'survey_id': row?.survey_id,
                'periodicity': row?.periodicity,
                'from_date': row?.from_date,
                'to_date': row?.to_date,
            }
            await AdminService.httpPost("/data-retrieval/aggregation/download_aggregation_CSV", data).then((res) => {
                if (res) {
                    console.log("This is downloadCSV response--", res)

                    let data = res;

                    data.forEach((element) => {
                        console.log('elem', element)

                        if (element.AreaID != null || element.AreaName != null || element.DataValue != null || element.Footnote != null || element.Indicator?.en != null || element.Source != null || element.Subgroup?.en != null || element.Timeperiod != null || element?.DisasterCode != null) {
                            this.state.aggregatedData.push({
                                AreaID: element.AreaID,
                                AreaName: element?.AreaName?.en,
                                DataValue: element?.DataValue,
                                Footnote: element?.Footnote,
                                Indicator: element?.Indicator?.en,
                                Source: element?.Source,
                                Subgroup: element?.Subgroup?.en,
                                Timeperiod: element?.Timeperiod,
                                DisasterCode: element?.DisasterCode?.en,
                            })
                        }
                    })
                    this.setState({ loader: false });

                    if (this.state.aggregatedData.length > 0) {
                        // this.aggregateData.link.click()
                        // console.log("ss",this.aggregateData)
                        this.downloadCsvData(row)
                    } else {
                        toast.error('No Data Available To Download')
                    }

                }
            })
        } catch (error) {
            console.log("errorrr", error)
        }

    }

    downloadCsvData(data) {
        console.log("aaa", data)

        let downloadData = this.state.aggregatedData

            .map((element) => (

                {
                    AreaID: element.AreaID,
                    AreaName: element?.AreaName,
                    DataValue: element?.DataValue,
                    Footnote: element?.Footnote,
                    Indicator: element?.Indicator?.replaceAll(',', ' '),
                    Source: element?.Source,
                    Subgroup: element?.Subgroup,
                    Timeperiod: element?.Timeperiod,
                    DisasterName: element?.DisasterCode,
                    // PlanName : element?.FormName,
                }
            ))


        this.downloadFile(this.arrayToCSV(downloadData), `TERS_AggregatedData_${this.getDate()}`)
    }

    getDate() {
        let d = new Date();
        let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
            d.getHours() + ":" + d.getMinutes();
        return (datestring.replace(':', '.'))
    }


    arrayToCSV(data) {
        const csv = data.map(row => Object.values(row));
        // console.log("csv:: ",csv, data)
        csv.unshift(Object.keys(data[0]));
        return csv.join('\n');
    }

    downloadFile(data, filename = 'data') {
        let csvData = data
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }


    // delete entry===========


    deleteEntry = (id) => {

        swal({
            title: "Are you sure you want to delete?",
            // text: "Once deleted, you will not be able to recover this resource entry!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                this.setState({ loader: true })
                if (willDelete) {
                    const dataToBeSend = {
                        "id": id
                    }

                    AdminService.httpPost('/data-import/aggregation/delete-aggregation', dataToBeSend).then((res) => {

                        if (res) {

                            this.setState({ loader: false })

                            if (res.success == true) {
                                toast.success("Aggregation deleted successfully");
                                this.getData();

                            }
                            else {
                                toast.error(res.message);

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });

    }


    // open add modal=====================//


    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })


        } else {
            this.setState({ modal: false })
            this.setState({ isEdit: false })

            this.setState({ editEntry: [] })
        }

    }

    ///multiple delete

    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
        })


        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete?",
                // text: "Once deleted, you will not be able to recover this resource entry!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/aggregation/delete-multiple-aggregations', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log(res)

                                if (res.success == true) {
                                    toast.success("Aggregation deleted successfully");
                                    this.getData();
                                }
                                else {
                                    toast.error("Failed to delete aggregation");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }


        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }




}







export default ManageAggregations;
