import React, { Component, useEffect } from "react";
import $ from "jquery";
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import DataSearchResultChart from "./data-searchchart";
import Tooltip from "@mui/material/Tooltip";


import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import dataSearchResultLang from '../../../Config/data-search-results-component.json';
import { Button } from "@mui/material";

let DataSearchResultTemplate = function (props) {



  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;



  return (
    <>
      <div className="dsrResult">
        <ul>
          <li
            className={
              this.state.type == "table" ? "active enabled" : " enabled"
            }
            onClick={(e) => this.changeType("table")}
          >
            <Tooltip title="Table" placement="Bottom">
            <img src="assets/lib/images/charts/icon-1.svg" /></Tooltip>
          </li>

          <li
            className={
              (this.state.type == "line" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("line")}
          >
            <img src="assets/lib/images/charts/icon-2.svg" />
          </li>

          <li
            className={
              (this.state.type == "bar" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("bar")}
          >
            <img src="assets/lib/images/charts/icon-3.svg" />
          </li>

          {/* <li className={(this.state.type=='column'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('column')} ><img src="assets/lib/images/charts/icon-4.svg" /></li> */}

          <li
            className={
              (this.state.type == "pie" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("pie")}
          >
            <img src="assets/lib/images/charts/icon-5.svg" />
          </li>

          <li
            className={
              (this.state.type == "doughnut" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("doughnut")}
          >
            <img src="assets/lib/images/charts/icon-6.svg" />
          </li>

          {/* <li className={(this.state.type=='scatter'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('scatter')}><img src="assets/lib/images/charts/icon-7.svg" /></li> */}

          {/* <li className={(this.state.type=='funnel'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('funnel')}><img src="assets/lib/images/charts/icon-8.svg" /></li> */}

          <li
            className={
              (this.state.type == "map" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("map")}
          >
            <img src="assets/lib/images/charts/icon-9.svg" />
          </li>

          {/* <li className={(this.state.type==''?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('table')}><img src="assets/lib/images/charts/icon-10.svg" /></li> */}

          <li
            className={
              (this.state.type == "treemap" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("treemap")}
          >
            <img src="assets/lib/images/charts/icon-11.svg" />
          </li>

          <li
            className={
              (this.state.type == "radar" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("radar")}
          >
            <img src="assets/lib/images/charts/icon-12.svg" />
          </li>

          {/* <li className={(this.state.type=='venn'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('venn')}><img src="assets/lib/images/charts/icon-13.svg" /></li> */}
        </ul>
      </div>

      <div
        className={this.state.type == "table" ? "dsrTable" : "dsrTable d-none"}
      >
        {this.props.data?.length > 0 && (
          <div className={this.state.filteredData?.length > 0 ? "tableData h-100" : "tableData h-100 hide-opt"}>
            <div className="dsrTableTop d-flex align-items-center justify-content-between">
            <div className='show-option'>
                <span>Show</span>
                    <select>
                        <option>10</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                    </select>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                
            </div>
            
              <div class="dropdown">
                <button
                  className="viewSelection dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="assets/lib/images/icon/metadata.svg" /> 
                  View Selection 
                </button>
                <div
                  className="viewSelectionBox dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <img src="assets/lib/images/close.svg"
                   className="cross" />
                  <div className="d-flex h-100">
                  <div className="disasterSelected_List">
                      <h5>
                        Indicator 
                        
                        (
                          { Object.entries(this.props.selIUSdata)?.filter(([key,value]) =>  key != value?.iu_id+'#' && key != '*')?.length }
                        )
                      </h5>
                      <ul>
                        {Object.entries(this.props.selIUSdata)?.map(
                          ([key, ius]) => {
                            return (
                              !(ius == true)  && !key.endsWith('#') && (
                                <li>
                                  {ius.ind_name} <span>| {ius.unit}</span>{" "}
                                  <span>| {ius.subgroup_name}</span>
                                </li>
                              )
                            );
                          }
                        )
                        }
                        
                      </ul>
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                      Area 
                      ({this.props.selAreaList?.length})
                      </h5>
                      {this.props.areaLevel?.map((a) => {
                        return (
                          <ul className="dsl_breadcrumb">
                            {a?.map((x) => {
                              return <li>{x}</li>;
                            })}
                          </ul>
                        );
                      })}
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                      Time Period
                      {/* ({this.props.selTimeperiod?.length}) */}
                      ({this.props.selTimeperiod.includes("*") ? this.props.selTimeperiod.length - 1 : this.props.selTimeperiod.length})
                      </h5>
                      <ul>
                        {this.props.selTimeperiod?.map(
                          (tp) => {
                            return (
                              !(tp == '*') && (
                                <li>
                                  {tp}
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                        Source
                      
                        ({this.props.selSource.includes("*") ? this.props.selSource.length - 1 : this.props.selSource.length})

                      </h5>
                      <ul>
                        {this.props.selSource?.map(
                          (tp) => {
                            return (
                              !(tp == '*') && (
                                <li>
                                  {tp}
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dsrTablesearch">
                <subHeaderComponentMemo.default
                  onSearch={this.onSearch}
                  searchText={this.state.searchText}
                  nRows={this.state.filteredData?.length}
                />

                <Button
                  className="dsrTableReset"
                  onClick={() => this.props.callBack()}
                >
                  <img src="assets/lib/images/icon/reset-2.svg" />
                </Button>
                <Button
                  className="dsrTableReset"
                  onClick={(e) => this.downloadCsvData()}
                  // onClck ={(e)=> this.downloadExcelData()}
                >
                  <img src="assets/lib/images/home/download.svg" />
                </Button>
              </div>
            </div>
            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{ inkDisabled: true }}
              onSelectedRowsChange={(s) => this.selectedRowsChange(s)}
              clearSelectedRows={this.state.toggledClearRows}
              sortIcon={<ArrowDownwardIcon />}
              persistTableHead
              className="dsrTableComponent"
              noDataComponent="No Data"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
          </div>
        )}
        {this.props.data?.length == 0 && (
          <div className="tableNodata">
            <p>No Data</p>
          </div>
        )}
      </div>

      <div
        className={this.state.type != "table" ? "dsrChart" : "dsrChart d-none"}
      >
        <DataSearchResultChart
          data={this.state.selectedRows}
          type={this.state.type}
        />        
      </div>
    </>
  );
};
export default DataSearchResultTemplate;
