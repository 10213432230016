import React from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import DataEntryModal from '../../Modals/dataEntry.modal';
import RemarksModal from './remarks-modal';
			  
const dataEntryTemplate = function () {

const  openCloseModal = async () => {

      this.setState({scrollableModal:false})

}


  return (
    <>


      <div className="main" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection data-entry' id="areaSection">

                <subHeaderComponentMemo.default parentCallBack={this.sendStatus}  
                statusFilter = {this.state.statusData} 
                onSearch={this.onSearch} 
                PropDeleteBulk={this.deleteBulkData}
                parentCallBackDelete ={this.deleteAllData}
                
                searchText={this.state.searchText} 
                value='1'
                checkData = {this.state.bulkDelete}
                currStatus={this.state.currStatus}
                nRows={this.state.filteredData?.length}
                /> 
            
            <DataTable
              columns={this.state.currStatus==1 && this.columns1 || this.state.currStatus==3 && this.columns2 ||  this.columns  }
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[100]}
              paginationServer
              paginationTotalRows={this.state.totalElements}
              paginationPerPage={this.state.rowsPerPage}
              paginationComponentOptions={{
                noRowsPerPage: false
              }}
              onChangePage={page => this.setPage(page)}
              style={{ display: "flex" }}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              selectableRows={this.state.currStatus == 1 ? false : true}
               onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className='dataTableScroll'
              noDataComponent="No Data"
              fixedHeader
            />
            {console.log('this.state.filteredData',this.state.filteredData)}
          </section>
        </div>
      </div>






      {/* modal area begin here============== */}



      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

      <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img
                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
        </div>
            <div class="right-tab-heading are_head">
              <h2 class="form-heading">
                <span class="form-heading_he">
                  <span class="ddf">
                    {this.state.isEdit==true ? 'Edit Record': 'Add Record'}
                  </span>
                </span>
              </h2>
            </div>
            <DataEntryModal 
              indicatorData = {this.state.indicatorData} 
              closeModal={this.openAddModal}
              subGroupData={this.state.subIndData}
              areaTree = {this.state.areaData}
              newDataEntry ={this.saveData}
              editData = {this.state.editEntry}
              isEdit = {this.state.isEdit}
              loginArea = {this.state.loginArea}
              allSources = {this.state.allSources}
              allTimeperiods = {this.state.allTimeperiods}
            />


   
      </div>

     
      
      <RemarksModal remarkData={this.state.remarkData} />


      <Modal show={this.state.scrollableModal} onHide={openCloseModal} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Reason of Disapproval</Modal.Title>
          </Modal.Header>
          <Modal.Body>please recheck the data</Modal.Body>
        </Modal>



      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>

    </>
  );
};

export default dataEntryTemplate;
