import React, { useEffect, useRef, useState } from 'react';
import './explorevisualise.css'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CheckboxTree from 'react-checkbox-tree';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as constants from '../../../../Config/app.constants';
import adminService from '../../../../services/admin.service';

const Label = ({ children }) => <span>{children}</span>;

class ExploreVisualiseModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            expanded: [],
            callData: [],
            fcallData: [],
            clicked: [],
            value: '1',
            show: false,
            indicatorList: [],
            fIndicatorList: [],
            selectedIndicators: [],
            subgroupList: [],
            selectedIUS: [],
            areaData: [],
            allAreaList: [],
            fAreaList: [],
            mainAreaData: [],
            selectedAreas: [],
            sourceList: [],
            fSourceList: [],
            isAllSelected: false,
            timeperiodList: [],
            fTimeperiodList: [],
            selectedTimePeriods: [],
            checkedIndicators: {},
            checkedSubgroups: {},
            loader: false,

            tickedSubgroups: [],
            areaChecked: [],
            areaExpand: [],
            areaList: [],
            selectedTimes: [],
            isTimeSelectAllChecked: false,
            isTimeViewSelectedChecked: false,
            isSourceSelectAllChecked: false,
            isSourceViewSelectedChecked: false,
            selectedSource: [],
            selectionCombinationData: [],
            maxTab: '4',
            viewSelected: false,
            showDataExist: false,
            sourceFilterValue:"",
            sourceFilteredData:[],
            filterTimePeriod: [],
            timePeriodValue: '',
        };

        this.nodes = [{
            value: 'adolescentTonnes',
            label: <><Label>adolescent, Tonne/s  </Label></>,
            children: [
                { value: 'selectall', label: 'Select All' },
                { value: 'populationofdelhi', label: 'population of delhi' },
            ],
        },
        {
            value: 'adolescentTonnes1',
            label: 'adolescent1, Tonne/s ',
            children: [
                { value: 'selectall1', label: 'Select All' },
                { value: 'employees', label: 'employees' },
            ],
        }
        ];
        this.subgroupList = [];
    }

    componentDidMount() {
        this.getData();
        console.log('componetDidMount -', this.state.fIndicatorList, "and", this.state.indicatorList)
    }

    getAreaList = (checkedNodes) => {
        this.setState({ areaChecked: checkedNodes }, () => {
          console.log("Updated areaChecked state:", this.state.areaChecked);
          var dataArr = [];
          checkedNodes?.forEach((item) => {
            this.state.mainAreaData?.forEach((dt) => {
              if (dt.area_code === item) {
                dataArr.push({ code: dt.area_code, name: dt.name });
              }
            });
          });
          console.log("area list check-", dataArr)
          this.setState({ areaList: dataArr });
        });
      };

    //   componentDidUpdate(prevProps, prevState) {
    //     if (prevState.viewSelected !== this.state.viewSelected) {
    //         // if(this.state?.viewSelected){
    //             const matchingObjects = [];
    
    //             for (const topLevelNode of this?.state?.callData) {
    //                 if (this.state.areaChecked.includes(topLevelNode.value)) {
    //                     matchingObjects.push(topLevelNode);
    //                 } else if (topLevelNode.children) {
    //                     for (const childNode of topLevelNode.children) {
    //                     if (this.state.areaChecked.includes(childNode.value)) {
    //                         matchingObjects.push(childNode);
    //                     }
    //                     }
    //                 }
    //             }
    //             this.setState({ callData: matchingObjects });
    //             console.log(matchingObjects,"matchingObjects");
    //           }
    //     // }
    //   }

    componentDidUpdate(prevProps, prevState) {
        const { viewSelected, areaChecked, fcallData } = this.state;
      
        if (prevState.viewSelected !== viewSelected) {
          if (viewSelected) {
            const matchingObjects = this.findMatchingObjects(fcallData, areaChecked);
            this.setState({ fcallData: matchingObjects });
            console.log(matchingObjects, "matchingObjects");
          } else {
            // Reset callData or perform any other desired action
            this.setState({fcallData: this.state.callData});
          }
        }
      }

      findMatchingObjects = (data, checkedNodes) => {
        const matchingObjects = [];
      
        for (const topLevelNode of data) {
          if (checkedNodes.includes(topLevelNode.value)) {
            matchingObjects.push(topLevelNode);
          } else if (topLevelNode.children) {
            for (const childNode of topLevelNode.children) {
              if (checkedNodes.includes(childNode.value)) {
                matchingObjects.push(childNode);
              }
            }
          }
        }
      
        return matchingObjects;
      };
      
      handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked }, () => {
          if ((name === 'viewSelected') && checked) {
            this.setState({ fcallData: this.findMatchingObjects(this.state.callData, this.state.areaChecked) });
          } else {
            // Reset callData or perform any other desired action
            this.setState({fcallData: this.state.callData});
          }
        });
      };

    // getData = () => {
    //     this.setState({ loader: true });
    //     fetch(constants.server+"api/data-retrieval/data/get-data-for-advance", { 
    //         method: 'get', 
    //         headers: new Headers({
    //             'Content-Type': 'application/json'
    //         })
    //     })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         console.log("data check-", data)
    //         let chk = this.selectAllInd(false, data.indicatorData);
    //         // let indList = data.indicatorData;
    //         this.setState({
    //             indicatorList: data.indicatorData,
    //             fIndicatorList: data.indicatorData,
    //             subgroupList: [],
    //             selectedIUS: [],
    //             areaData: data.areaData,
    //             allAreaList: data.allArea,
    //             fAreaList: data.allArea,
    //             sourceList: data.sourceData,
    //             fSourceList: data.sourceData,
    //             timeperiodList: data.timePeriodData,
    //             fTimeperiodList: data.timePeriodData,
    //             checkedIndicators: chk,
    //             checkedSubgroups: {},

    //             loader: false,
    //         });

    //     })
    //     .catch((err) => {
    //         this.setState({ loader: false });
    //         toast.error(err.message);
    //     });        
    // }

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    handleChange = (event, newValue) => {
        this.setState({ value: newValue,sourceFilteredData:this.state.fSourceList,sourceFilterValue:"",filterTimePeriod:this.state?.fTimeperiodList,timePeriodValue:"" });
    }

    handleNextTab = () => {
        const currentValue = parseInt(this.state.value);
        const nextValue = (currentValue % this.state.maxTab) + 1;
        this.setState({ value: nextValue.toString() ,sourceFilteredData:this.state.fSourceList,sourceFilterValue:"",filterTimePeriod:this.state?.fTimeperiodList,timePeriodValue:""});
    };

    handlePreviousTab = () => {
        const currentValue = parseInt(this.state.value);
        const previousValue = currentValue === 1 ? this.state.maxTab : currentValue - 1;
        this.setState({ value: previousValue.toString(),sourceFilteredData:this.state.fSourceList,sourceFilterValue:"",filterTimePeriod:this.state?.fTimeperiodList,timePeriodValue:"" });
    };

    selectAllInd = (status, list = null) => {
        let l = list ? list : this.state.fIndicatorList;
        let chkIU = {};
        // chkIU['*'] = status;
        for (let i = 0; i < l?.length; i++) {
            chkIU[l[i]._id] = status;
        }
        return chkIU;
    }

    selectAllSg = (status, list = null) => {
        let l = list ? list : this.state.subgroupList;
        let chkIUS = {};
        chkIUS['*'] = status;
        for (let i = 0; i < l?.length; i++) {
            chkIUS[l[i]._id] = status;
        }
        return chkIUS;
    }

    changeSgSelection = (e, ius_id) => {
        let chkSg = this.state.checkedSubgroups;
        if (ius_id == '*' && e.target.checked) {
            chkSg = this.selectAllInd(true);
        } else if (ius_id == '*') {
            chkSg = this.selectAllInd(false);
        } else if (e.target.checked) {
            let trueChkSg = Object.entries(chkSg).filter(([key, value]) => { return value == true; });
            if (trueChkSg?.length == this.state.subgroupList?.length) {
                chkSg = this.selectAllSg(true);
            } else {
                chkSg[ius_id] = true;
            }
        } else {
            let trueChkSg = Object.entries(chkSg).filter(([key, value]) => { return value == true; });
            if (trueChkSg?.length > this.state.subgroupList?.length) {
                chkSg['*'] = false;
            }
            chkSg[ius_id] = false;
        }
    }


    changeIndSelection = (e, iu_id) => {

        let chkInd = this.state.checkedIndicators;

        if (e.target.checked) {
            let trueChkInd = Object.entries(chkInd).filter(([key, value]) => value === true);
            Object.keys(trueChkInd).forEach(k => {
                chkInd[k] = false;
            })
            chkInd[iu_id] = true;

            let subgroupsChecked = this.getSubgroups(iu_id);
            console.log("subgroupsChecked-", subgroupsChecked)

            // Add the new subgroup to subgroupList
            this.subgroupList.push(subgroupsChecked);
        } else {
            chkInd[iu_id] = false;

            // Remove the unchecked subgroup from subgroupList
            this.subgroupList = this.subgroupList.filter(subgroup => subgroup !== this.getSubgroups(iu_id));
        }

        let chkSg = this.selectAllSg(false, this.subgroupList);

        console.log("ok depression-", chkSg, "ok", chkInd, "subgroupList-", this.subgroupList);

        // setState
        this.setState({ subgroupList: this.subgroupList, checkedIndicators: chkInd, checkedSubgroups: chkSg });
    }


    getSubgroups = (iu_id) => {
        let ind = this.state.indicatorList.filter(i => i._id === iu_id);
        return ind?.[0]?.subgroup;
    }

    setLevelData = (flat) => {

        var roots = [];

        var all = {};

        flat.forEach(function (item) {

            all[item.value] = item;
        });


        Object.keys(all).forEach(function (value) {
            var item = all[value];
            if (item.parent === null || item.parent === "") {

                roots.push(item);
            } else if (item.parent in all) {
                var p = all[item.parent];
                if (!("children" in p)) {

                    p.children = [];

                }
                p.children.push(item);
            }
        });


        return roots;
    };

    getData = () => {
        this.setState({ loader: true });

        adminService.httpGet("/data-retrieval/generic/get-data/datasource")
            .then((res) => {
                if (res) {

                    let d = res?.data;

                    this.setState({ fSourceList: d,sourceFilteredData:d, sourceList: d, loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err);
            });

        adminService.httpPost("/data-retrieval/ius/get-indicator-by-iu")
            .then((res) => {
                if (res) {
                 console.log(res,"getIndicator")
                    let d = res?.data;

                    this.setState({ fIndicatorList: d, indicatorList: d, loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err);
            });

        adminService.httpGet('/data-retrieval/generic/get-data/time').then((res) => {
            if (res) {

                let d = res?.data;
                console.log("ddd time-", d)
                this.setState({ timeperiodList: d, fTimeperiodList: d, filterTimePeriod:d, loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });



        adminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {
                let d = res.data;
                let flatLevel = [];
                if (d?.length > 0) {



                    d?.forEach((item) => {

                        if ('TTO' == item.area_code) {
                            item.areaSubData = [];
                            item['level'] = 1;
                            item['parent_id'] = '';
                        }

                        var obj = {
                            value: item.area_code,
                            label: item?.name_all?.['en'],
                            parent: item.parent_id,
                            level: item.level

                        }

                        flatLevel.push(obj)
                    })

                    var data = this.setLevelData(flatLevel);

                }

                this.setState({ mainAreaData: d, callData: data, fcallData: data, flatLevel: flatLevel });
            }
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });



        let d = this.state.indicatorList;
        let iusIds = [];
        console.log("d--->", d)

        Object.entries(d).forEach(([key, value]) => {
            console.log("value--->", value)
            if (value != true) {
                iusIds.push(value.ius_id);
            }
        });
        console.log("iusIds--->", iusIds)

        let iusIdNv = iusIds.filter(id => id !== null);
        console.log("ind check-", iusIdNv)
        let dataToSend = {};

        adminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
            if (res) {

                let validAreaCodes = res.data?.[0]?.area_code;
                let validTPs = res.data?.[0]?.time_period;
                let validSrc = res.data?.[0]?.source;
                this.setState({
                    validAreaCodes: validAreaCodes,
                    validTPs: validTPs,
                    validSrc: validSrc,
                    loader: false
                });

            }
        })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });

    }

    toggleCheckbox = (id) => {
        this.setState((prevState) => {
            
            const updatedSubgroupList = prevState.subgroupList?.flat(Infinity)?.map((item) =>
                item._id === id ? { ...item, checked: !item.checked } : item
            );

            const isAllSelected = updatedSubgroupList.every((item) => item.checked);
            let checkedData = updatedSubgroupList?.filter((subg) => subg?.checked);
            let realData = [];

            checkedData?.forEach((item)=>{
                this.state.fIndicatorList?.forEach((ele)=>{
                    ele.subgroup?.forEach((sg)=>{
                        let newdt=sg?._id ===item?._id
                        if(newdt){
                             sg.indName=ele?.name
                             sg.unit=ele?.unit
                            realData.push(sg)
                        }
                    })
                })
            })

            return {
                isAllSelected,
                subgroupList: updatedSubgroupList,
                tickedSubgroups: realData
            };
        });
    };



    toggleSelectAll = () => {
        this.setState((prevState) => {
            const isAllSelected = !prevState.isAllSelected;
           
            const updatedSubgroupList = prevState.subgroupList?.flat(Infinity)?.map((item) => ({
                ...item,
                checked: isAllSelected,
            }));

            const checkedData = updatedSubgroupList?.filter((subg) => subg?.checked);
            let realData = [];

            checkedData?.forEach((item)=>{
                this.state.fIndicatorList?.forEach((ele)=>{
                    ele.subgroup?.forEach((sg)=>{
                        let newdt=sg?._id ===item?._id
                        if(newdt){
                             sg.indName=ele?.name
                             sg.unit=ele?.unit
                            realData.push(sg)
                        }
                    })
                })
            })

            return {
                isAllSelected,
                subgroupList: updatedSubgroupList,
                tickedSubgroups: realData,
            };
        });
    };

    handleSingleSelectTime = (timeperiod) => {
        this.setState((prevState) => {
            let updatedSelectedTimes;
            if (prevState.selectedTimes.includes(timeperiod?.timeperiod)) {
                updatedSelectedTimes = prevState.selectedTimes.filter((time) => time !== timeperiod?.timeperiod);
            } else {
                updatedSelectedTimes = [...prevState.selectedTimes, timeperiod?.timeperiod];
            }

            const isAllSelected = updatedSelectedTimes.length === this.state.fTimeperiodList.length;

            return {
                selectedTimes: updatedSelectedTimes,
                isTimeSelectAllChecked: isAllSelected,
            };
        });
    };


    handleSelectAllChange = (type) => {
        if (type == 'time') {
            this.setState((prevState) => ({
                isTimeSelectAllChecked: !prevState.isTimeSelectAllChecked,
                selectedTimes: prevState.isTimeSelectAllChecked ? [] : this.state.fTimeperiodList.map((time) => time.timeperiod),
            }));
        } else if (type == 'source') {
            this.setState((prevState) => ({
                isSourceSelectAllChecked: !prevState.isSourceSelectAllChecked,
                selectedSource: prevState.isSourceSelectAllChecked ? [] : prevState.fSourceList.map((src) => src.datasource),
            }));

        }
    };

    handleViewSelectedChange = (type) => {
        if (type == 'time') {
            this.setState((prevState) => ({
                isTimeViewSelectedChecked: !prevState.isTimeViewSelectedChecked,
            }));
        } else if (type == 'source') {
            this.setState((prevState) => ({
                isSourceViewSelectedChecked: !prevState.isSourceViewSelectedChecked,
            }));
        }
    };
    getSelectedTimePeriods = () => {
        const { isTimeViewSelectedChecked, selectedTimes, fTimeperiodList } = this.state;
        if (isTimeViewSelectedChecked) {
            return fTimeperiodList.filter((time) => selectedTimes.includes(time.timeperiod));
        }
        return [];
    };
    getSelectedSources = () => {
        const { isSourceViewSelectedChecked, selectedSource, fSourceList } = this.state;
        if (isSourceViewSelectedChecked) {
            return fSourceList.filter((src) => selectedSource.includes(src.datasource));
        }
        return fSourceList;
    };

    handleSingleSelectSource = (src) => {
        this.setState((prevState) => {
            const index = prevState.selectedSource.indexOf(src.datasource);
            const updatedSelectedSources = [...prevState.selectedSource];

            if (index !== -1) {
                updatedSelectedSources.splice(index, 1); // Remove the source if already selected
            } else {
                updatedSelectedSources.push(src.datasource); // Add the source if not selected
            }

            const isAllSelected = updatedSelectedSources.length === prevState.fSourceList.length;

            return {
                selectedSource: updatedSelectedSources,
                isSourceSelectAllChecked: isAllSelected,
            };
        });
    };

    resetFunc = () => {
        
        this.setState({
            tickedSubgroups: [],
            selectedTimes: [],
            selectedSource: [],
            subgroupList: [],
            checkedIndicators: {},
            checkedSubgroups: {},
            isTimeSelectAllChecked: false,
            isSourceSelectAllChecked: false,
            isTimeViewSelectedChecked: false,
            isSourceViewSelectedChecked: false,
            isAllSelected: false,
            value: '1',
            areaList: [],
            areaChecked: [],
        })
        this.subgroupList = [];

    }

    removeIus = (ius) => {
        let newTickedSubgroups = this.state.tickedSubgroups.filter((subg) => subg._id !== ius?._id);
        this.setState({ tickedSubgroups: newTickedSubgroups })
    }

    combineData = () => {
        const currentData = [...this.state.selectionCombinationData];
    
        const newDataObject = {
            ius: this.state.tickedSubgroups,
            timeperiod: this.state.selectedTimes,
            source: this.state.selectedSource,
            area: this.state.areaList
            // Add more properties here if needed.
        };
    
        // Add the new object to the existing array
        currentData.push(newDataObject);
    
        // Update the state with the updated array
        this.setState({ selectionCombinationData: currentData, value: '1' });
        this.subgroupList = [];
        this.resetFunc();

    };


    handleDatasourceChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ sourceFilterValue:searchQuery });
    
        if (searchQuery === '') {
          this.setState({ sourceFilteredData: this.state.fSourceList }); 
        } else {
          const filtered = this.state.fSourceList.filter(item =>
            item.datasource.toLowerCase().includes(searchQuery.toLowerCase())
          );
          this.setState({ sourceFilteredData: filtered });
        }
      };
    


      timePeriodChangeFilter = (event) => {
        const searchQuery = event.target.value;
        this.setState({ timePeriodValue:searchQuery });
    
        if (searchQuery === '') {
          this.setState({ filterTimePeriod: this.state.fTimeperiodList });
        } else {
          const filtered = this.state.fTimeperiodList.filter(item =>
            item.timeperiod.toLowerCase().includes(searchQuery.toLowerCase())
          );
          this.setState({ filterTimePeriod: filtered });
        }
      
    }

    componentDidUpdate() {
        console.log("state checking--", this.state.selectionCombinationData)
    }


    render() {
        const selectedTimePeriod = this.getSelectedTimePeriods();
        const selectedSource = this.getSelectedSources();
        const currentValue = parseInt(this.state.value);
        const isMaxTab = currentValue === parseInt(this.state.maxTab);
        const isFirstTab = currentValue === 1;
        const { viewSelected, showDataExist } = this.state;
        return (
            <>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={this.state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={this.handleChange} aria-label="" className='tabsList '>
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>1</div>
                                        <div className='adph_text'>Indicator</div>
                                        <div className='adph_count'>{this.state.tickedSubgroups?.length}</div>
                                    </div>
                                } value="1" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>2</div>
                                        <div className='adph_text'>Area</div>
                                        <div className='adph_count'>{this.state.areaList?.length}</div>
                                    </div>
                                } value="2" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>3</div>
                                        <div className='adph_text'>Time Period</div>
                                        <div className='adph_count'>{this.state.selectedTimes?.length}</div>
                                    </div>
                                } value="3" />
                                <Tab label={
                                    <div className='d-flex align-items-center'>
                                        <div className='adph_number'>4</div>
                                        <div className='adph_text'>Source</div>
                                        <div className='adph_count'>{this.state.selectedSource?.length}</div>
                                    </div>
                                } value="4" />
                            </TabList>
                            <div className='info-rest-icons pr-4'>
                                <ul>
                                    <li>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <img src="assets/lib/images/home/information.svg" alt="" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='ius_information'>
                                                        <h4>IUS</h4>
                                                        {this.state.tickedSubgroups?.length > 0 ?
                                                            this.state.tickedSubgroups?.map((ius) => {
                                                                return (
                                                                    <ul>
                                                                        <div className='evSelected_ius_txt'>{ius?.indName} <span>| {ius?.unit}</span> <span>| {ius?.name?.en}</span> </div>
                                                                    </ul>
                                                                )
                                                            })
                                                            :
                                                            <ul>
                                                                <li>No IUS selected</li>
                                                            </ul>
                                                        }

                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Area</h4>
                                                        {this.state.areaList?.length > 0 ? this.state.areaList?.map((area)=>{return(
                                                            <ul>
                                                            <li>{area?.name}</li>
                                                        </ul>
                                                        )})
                                                        :
                                                        <ul>
                                                            <li>No Area selected</li>
                                                        </ul>
                                                        }
                                                        
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Time Period</h4>
                                                        {this.state.selectedTimes?.length > 0 ?
                                                            this.state.selectedTimes?.map((time) => {
                                                                return (
                                                                    <ul>
                                                                        <li>{time}</li>
                                                                    </ul>
                                                                )
                                                            })

                                                            :
                                                            <ul>
                                                                <li>No Time Period selected</li>
                                                            </ul>
                                                        }

                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Source</h4>
                                                        {this.state.selectedSource?.length > 0 ?
                                                            this.state.selectedSource?.map((source) => {
                                                                return (
                                                                    <p className='nodata'>{source}</p>
                                                                )
                                                            })

                                                            :
                                                            <p className='nodata'>No Source selected</p>
                                                        }

                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    <li onClick={() => { this.resetFunc() }}><img src="assets/lib/images/home/reset-icon.svg" /></li>
                                </ul>
                            </div>
                        </Box>
                        <TabPanel value="1">
                            <div className='ads_allselect d-flex align-items-center position-relative'>
                                <div className='ads_as_checkbox'>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                    </FormGroup>
                                </div>
                                <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                            </div>
                            <div className='ev_treeList'>
                                <div className='row'>
                                    <div className='col-md-4 ev_treeListFirst'>
                                        <h4>Indicators</h4>
                                        {/*
                                    <TreeView
                                        aria-label="file system navigator"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                    >
                                        <TreeItem nodeId="1" label="Bodies of state power and administration">
                                            <TreeItem nodeId="2" label="Administration">
                                                <TreeItem nodeId="3" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Civil servants holding public positions by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                                <TreeItem nodeId="4" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Deputies of the Jogorku Kenesh (at the end of the year) by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                            </TreeItem>                                            
                                        </TreeItem>

                                        <TreeItem nodeId="5" label="Education and science">
                                            <TreeItem nodeId="6" label="Full-time educational">
                                                <TreeItem nodeId="7" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Students of full-time educational institutions <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                                <TreeItem nodeId="8" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Teachers of full-time educational institutions (without part-timers) by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                            </TreeItem> 
                                            <TreeItem nodeId="9" label="General education">
                                                <TreeItem nodeId="10" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Graduation of students of general education organizations <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />                                                
                                            </TreeItem>                                           
                                        </TreeItem>
                                    </TreeView>
                                    */}
                                        <FormGroup>
                                            {/* <FormControlLabel control={<Checkbox 
                                                                    checked={this.state.checkedIndicators?.['*']}
                                                                    onChange={(e) => this.changeIndSelection(e, '*')} />} 
                                                                label="Select All" /> */}
                                            {
                                                this.state.fIndicatorList?.map((indicator) => {
                                                    console.log("indicatorr", indicator)
                                                    return (
                                                        <FormControlLabel control={<Checkbox
                                                            checked={this.state.checkedIndicators?.[indicator._id]}
                                                            onChange={(e) => this.changeIndSelection(e, indicator?._id)}
                                                        />}
                                                            label={indicator.name + ' | ' + indicator.unit} />
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-4 ev_treeListSecond'>
                                        <h4>Subgroup</h4>
                                        <div className='evSubgroup'>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={this.state.isAllSelected || false}
                                                    onChange={this.toggleSelectAll} />} label="Select All" />
                                                {this.state.subgroupList?.flat(Infinity)?.map((subg) => {
                                                    console.log("subgroup list-", this.state.subgroupList?.flat(Infinity))
                                                    return (

                                                        <FormControlLabel control={<Checkbox checked={subg.checked || false}
                                                            onChange={() => this.toggleCheckbox(subg._id)} />} label={subg?.name?.en} />
                                                    )
                                                })
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className='col-md-4 ev_treeListThree pr-3'>
                                        <h4>Selected IUS ({this.state.tickedSubgroups?.length})</h4>
                                        <div className='evSelected_ius'>
                                            <ul>
                                                {
                                                    this.state.tickedSubgroups?.map((ius) => {
                                                        console.log("first", ius)
                                                        return (
                                                            <li>
                                                                <div className='evSelected_ius_txt'>{ius?.indName} <span>| {ius?.unit}</span> <span>| {ius?.name?.en}</span> </div>
                                                                <div className='evSelected_ius_img' onClick={() => this.removeIus(ius)}>
                                                                    <img src="assets/lib/images/home/delete-i.svg" alt="dd" />
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value="2">
                            <div className='ads_allselect d-flex align-items-center position-relative'>
                                <div className='ads_as_checkbox'>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={viewSelected} onChange={this.handleCheckboxChange} name="viewSelected"  />} label="View Selected" />
                                        <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                    </FormGroup>
                                </div>
                                <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                            </div>
                            <div className='ev_treeList'>
                                <CheckboxTree
                                    nodes={this.state.fcallData}
                                    checkModel="all"
                                    checked={this.state.areaChecked}
                                    expanded={this.state.areaExpand}
                                    onCheck={(e) => { this.getAreaList(e) }}
                                    // onClick={(clicked) => this.setState({ clicked })}
                                    onExpand={(expanded) => this.setState({ ...this.state, areaExpand: expanded })}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel value="3">
                            <div className='ads_allselect d-flex align-items-center position-relative'>
                                <div className='ads_as_checkbox'>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={this.state.isTimeSelectAllChecked}
                                            onChange={() => { this.handleSelectAllChange('time') }} />} label="Select All" />
                                        <FormControlLabel control={<Checkbox checked={this.state.isTimeViewSelectedChecked}
                                            onChange={() => { this.handleViewSelectedChange('time') }} />} label="View Selected" />
                                        <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                    </FormGroup>
                                </div>
                                <input autocomplete="off" name="search" type="text" onChange={this.timePeriodChangeFilter} className='ads_search' value={this.state.timePeriodValue} placeholder='Search...' />
                            </div>
                            {
                                this.state.isTimeViewSelectedChecked === false ?
                                    <div className='ev_treeList'>
                                        <div className='evSubgroup'>
                                            <FormGroup>
                                                {
                                                    this.state.filterTimePeriod?.map((time) => (
                                                        <FormControlLabel control={<Checkbox checked={this.state.selectedTimes.includes(time.timeperiod)}
                                                            onChange={() => { this.handleSingleSelectTime(time) }} />} label={time.timeperiod} />
                                                    ))
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                                    :
                                    <div className='ev_treeList'>
                                        <div className='evSubgroup'>
                                            <FormGroup>
                                                {
                                                    selectedTimePeriod?.map((time) => (
                                                        <FormControlLabel control={<Checkbox checked={this.state.selectedTimes.includes(time.timeperiod)}
                                                            onChange={() => { this.handleSingleSelectTime(time) }} />} label={time.timeperiod} />
                                                    ))
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                            }
                        </TabPanel>

                        <TabPanel value="4">
                            <div className='ads_allselect d-flex align-items-center position-relative'>
                                <div className='ads_as_checkbox'>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={this.state.isSourceSelectAllChecked}
                                            onChange={() => { this.handleSelectAllChange('source') }} />} label="Select All" />
                                        <FormControlLabel control={<Checkbox checked={this.state.isSourceViewSelectedChecked}
                                            onChange={() => { this.handleViewSelectedChange('source') }} />} label="View Selected" />
                                        <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                    </FormGroup>
                                </div>
                                <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...'value={this.state.sourceFilterValue} onChange={this.handleDatasourceChange} />
                            </div>
                            {
                                this.state.isSourceViewSelectedChecked === false ?

                                    <div className='ev_treeList'>
                                        <div className='evSubgroup'>
                                            <FormGroup>
                                                {
                                                    this.state.sourceFilteredData?.map((src) => (
                                                        <FormControlLabel control={<Checkbox checked={this.state.selectedSource.includes(src.datasource)}
                                                            onChange={() => this.handleSingleSelectSource(src)} />} label={src.datasource} />
                                                    ))
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                                    :
                                    <div className='ev_treeList'>
                                        <div className='evSubgroup'>
                                            <FormGroup>
                                                {
                                                    selectedSource?.map((src) => (
                                                        <FormControlLabel control={<Checkbox checked={this.state.selectedSource.includes(src.datasource)}
                                                            onChange={() => this.handleSingleSelectSource(src)} />} label={src.datasource} />
                                                    ))
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                            }
                        </TabPanel>
                    </TabContext>
                </Box>
                <div className='add_query_btn'>
                    <div className="add_query">
                        <ul>
                            {
                                (this.state.selectionCombinationData && this.state.selectionCombinationData.length > 0) &&
                                this.state.selectionCombinationData?.map((data, index) => {
                                    return (
                                        <li>
                                            <div className='add_query_content'>Selection {index+1}: indicators combination - {data?.ius?.length} | Area - {data?.area?.length} | Time Period - {data?.timeperiod?.length} | Source - {data?.source?.length}</div>
                                            <div className='add_query_img'>
                                                <span><img src='assets/lib/images/home/indicator.svg' alt='dd' /></span>
                                                <span><img src="assets/lib/images/home/delete-i.svg" alt="gg" /></span>
                                            </div>
                                        </li>
                                    )
                                })


                            }
                        </ul>
                    </div>
                    <Button className="query-btn" onClick={() => { this.combineData() }} disabled={this.state.tickedSubgroups?.length > 0 && this.state.selectedTimes?.length > 0 && this.state.selectedSource?.length > 0 ? false : true} >
                        <div className="advance_plus">
                            <span className="btn-plus">
                                <img src="assets/lib/images/home/plus.svg" className="plus_search" />
                            </span>
                        </div>
                    </Button>
                </div>
                <div className='addiDataFooter'>
                    {/* <div className='adfBtn adfleft disabled'> */}
                    <div className={isFirstTab ? 'adfBtn adfleft disabled' : 'adfBtn adfleft'} onClick={this.handlePreviousTab}>
                        <img src="assets/lib/images/home/arrow_right2.svg" alt="" />
                    </div>
                    <div className='adfok'>
                        OK
                    </div>
                    <div className={isMaxTab ? 'adfBtn adfRight disabled' : 'adfBtn adfRight'} onClick={this.handleNextTab}>
                        <img src="assets/lib/images/home/arrow_right2.svg" alt="" />
                    </div>
                </div>



                {/* ================================================================ Modal ======================================================= */}
                <Modal centered show={this.state.show} onHide={this.handleClose} className="addiDataPopup indicator_popup">
                    <Modal.Header closeButton>
                        <Modal.Title>adolescent1 | <span>Tonne/s</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='no_data'><p>No Data</p></div>
                    </Modal.Body>
                </Modal>
                {/* ================================================================ Modal ======================================================= */}

            </>
        );
    }
}

export default ExploreVisualiseModal;