import { Component } from 'react'
import './style.css';
import * as jsx from './DataSearchResultTemplate.module.jsx';
import { connect } from "react-redux";
import * as $ from 'jquery';
import dataSearchResultLang from '../../../Config/data-search-results-component.json';
import Tooltip from '@mui/material/Tooltip';

class DataSearchResultComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader : false,
            selectedRows : [],
            type : 'table',
            filteredData: this.props.data,
            searchText: "",
            toggledClearRows: true
        }
    }
    
    selectedRowsChange = (selectedData) => {
        this.setState({selectedRows : selectedData.selectedRows});
    }

    sortWithInd = (rowA, rowB) => {
        const a = rowA?.indicator;
        const b = rowB?.indicator;
    
        if (a > b) {
          return 1;
        }
    
        if (b > a) {
          return -1;
        }
    
        return 0;
      };
    sortWithToolTip2 = (rowA, rowB) => {
        const a = rowA?.timeperiod;
        const b = rowB?.timeperiod;
    
        if (a > b) {
          return 1;
        }
    
        if (b > a) {
          return -1;
        }
    
        return 0;
      };
      sortArea = (rowC, rowD) => {
        const a = rowC?.area;
        const b = rowD?.source;
    
        if (a > b) {
          return 1;
        }
    
        if (b > a) {
          return -1;
        }
    
        return 0;
      };
      sortSource = (rowC, rowD) => {
        const a = rowC?.source;
        const b = rowD?.source;
    
        if (a > b) {
          return 1;
        }
    
        if (b > a) {
          return -1;
        }
    
        return 0;
      };

     

      

    columns = [
        {
            name: "Indicator",
            selector: row => <><Tooltip title={`${row.indicator} | ${row.unit} | ${row.subgroup}`} placement="top">
                <p>{row.indicator} <span className='dsrt_unit'>| {row.unit}</span> <span className='dsrt_unit'> | {row.subgroup}</span></p>
            </Tooltip></>,
            minWidth: "250px",
            sortFunction: this.sortWithInd,
            sortable: true,
            
        },
        {
            name: "Area",
            selector: row => row.area,
            width: "200px",
            sortFunction: this.sortArea,
            sortable: true,
        },
        {
            name: "Time Period",
            selector: row => row.timeperiod,
            width: "200px",
            sortable: true,
            sortFunction: this.sortWithToolTip2,
        },
        {
            name: "Data Value",
            selector: row => row.datavalue,
            width: "150px",
            sortable: true,
        },
        {
            name: "Source",
            selector: row => <Tooltip title={row.source} placement="top"><p>{row.source}</p></Tooltip>,
            width: "200px",
            sortFunction: this.sortSource,
            sortable: true,
        },
    ];



    onSearch = (val) => {
        let d = this.props.data;
        let fd = [];
        if (val == "") {
          fd = d;
        } else {
          fd = d.filter(
            (item) =>
            (item.area && item.area.toLowerCase().includes(val.toLowerCase()))
              || (item.indicator && item.indicator.toLowerCase().includes(val.toLowerCase()))
              || (item.timeperiod && item.timeperiod.toLowerCase().includes(val.toLowerCase()))
              || (item.source && item.source.toLowerCase().includes(val.toLowerCase()))
          );
        }
    
        this.setState({ filteredData: fd, searchText: val });
      };
    
    
    changeType = (t) => {
        this.setState({type : t});
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.data !== this.state.filteredData) {
          this.setState({ filteredData: nextProps.data, 
                            selectedRows : [],
                            toggledClearRows: !this.state.toggledClearRows,
                            type : 'table',
                            searchText: "" });
        }
    }


    downloadCsvData() {
		// console.log("aaa", data)

		let downloadData = this.state.filteredData
    
		.map((element) => (

			{
                Area : element?.area,
                Indicator: element?.indicator,
                Unit: element?.unit,
                Subgroup: element?.subgroup,
                "Time period": element?.timeperiod,                
                "Data Value": element?.datavalue,                
                Source: element?.source,
			}
		))

    	this.downloadFile(this.arrayToCSV(downloadData), `SDG_T&T_Data_${this.getDate()}`)
	}

    //   arrayToCSV(data) {
    //   const csv = data.map(row => Object.values(row));
    //   csv.unshift(Object.keys(data[0]));
    //   return csv.join('\n');
    // }
    arrayToCSV(data) {
      const csv = data.map(row => {
          const escapedRow = Object.values(row).map(value => {
              if (typeof value === 'string' && value.includes(',')) {
                  return `"${value.replace(/"/g, '""')}"`;
              }
              return value;
          });
          return escapedRow.join(',');
      });
      csv.unshift(Object.keys(data[0]));
      return csv.join('\n');
  }
  

	downloadFile(data, filename = 'data') {
		let csvData = data
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		dwldLink.setAttribute("download", filename + ".csv");
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

  
    getDate() {
		let d = new Date();
		let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
			d.getHours() + ":" + d.getMinutes();
		return (datestring.replace(':', '.'))
	}

    render() {
        return jsx.default.bind(this)();
    }  
}

function mapStateToProps(state) {
    return {
      lang: state.lang?.lang
    }
 }

export default connect( mapStateToProps )(DataSearchResultComponent);