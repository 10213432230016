import React, { Component, useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DataSearchResultComponent from "../data-search-results-component/index";


function dataSearchTemplate() {

    let sd = this.state.selIUSdata;



    return (
        <>

            <div class="rotate_pop">
                <h1>Rotate Your Phone
                    <span>
                        <img class="rotate-img" src="assets/images/rotate-phone.svg" />
                    </span>
                </h1>
            </div>
            <section className="ds_tab">
                <div className="container-fluid p-0 h-100">
                    <div className="DataSearchTabs">
                        <Tabs
                            defaultActiveKey="viewdata"
                            id="uncontrolled-tab-example"
                            transition={ false }
                            activeKey={ this.state.key }
                            onSelect={ this.handleSelect }
                        >
                            <Tab
                                eventKey="indicator"
                                title={
                                    <>

                                        <div className="dst_list">
                                            <div className="dst_listContent">
                                                {/* <span>STEP 1</span> */ }
                                                <h4>Indicator</h4>
                                            </div>
                                            <div className="dst_count">
                                                { Object.entries(this.state.selIUSdata)?.filter(([key, value]) => key != value?.iu_id + '#' && key != '*').length }
                                            </div>
                                        </div>
                                    </>
                                }
                                className="dstColumn dsIndicator_tabs">
                                <div className="disasterSearch d-flex align-items-center">
                                    <div className="listByGroup col-md-6">
                                        <ul>
                                            <li>
                                                <input type="radio" id="selectGroupBy" name="selectGroupBy"
                                                    onChange={ (e) => this.handleChange(e, "goal") }
                                                    checked={ this.state.goalOrSector == 'goal' }
                                                />
                                                <label for="selectGroupBy"> By Goal</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="selectGroupBysector" name="selectGroupBy"
                                                    onChange={ (e) => this.handleChange(e, "sector") }
                                                    checked={ this.state.goalOrSector == 'sector' }
                                                />
                                                <label for="selectGroupBysector"> By Sector</label>
                                            </li>
                                            <li>
                                                <div className="dsSelectAll">
                                                    <div className="dataSearchFormList d-flex align-items-center">
                                                        {this.state.fIndicatorData == null}
                                                        <input type="checkbox" checked={ "*" in this.state.selIUSdata ? "checked" : "" } disabled={this.state.isDisabled} onChange={ (e) => this.selectIUS(e, "*", true, true) } id="indicator-selectAll" name="indicator-selectAll" />
                                                        <label for="indicator-selectAll"> Select All</label>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={ this.state.isWidth ? "dsInput col-md-6 activeWidth" : "dsInput col-md-6" }>
                                        <div className="searchBox">
                                            <input type="search" value={ this.state.searchText } onChange={ (e) => this.onSearch(e.target.value) } placeholder="Search..." className="dsSearch s" />
                                            <button type="button" className="searchBox__button" onClick={ (e) => this.ToggleClass() }><img src="assets/lib/images/icon/searchicon.svg" alt="" /></button>
                                        </div>
                                        <button className="btnReset" onClick={ (e) => this.resetSelection() }><img src="assets/lib/images/icon/reset.svg" alt="" /></button>
                                    </div>
                                </div>
                                <div className="disasterTab">
                                    <div className="disasterList">
                                        <div className="disasterCheckbox">
                                            <ul className="dcTreeView treeviewIndicator" id="myUL">
                                                <>
                                                    { this.state.fIndicatorData?.[this.state.goalOrSector]?.map((sectorData) => {

                                                        return (
                                                            <li className="position-relative" key={ sectorData._id }> {/*  */ }
                                                                <span class="caret indicator-caret">
                                                                    <img src="assets/lib/images/arrow_new.svg" />
                                                                </span>


                                                                <div className="dcTreeViewLabel">
                                                                    <> { sectorData.level_name?.['en'] } </>

                                                                </div>
                                                                <ul className="dcTreeViewText nested sub-category">
                                                                    { sectorData.target?.map((target) => {
                                                                        return (
                                                                            <>
                                                                                {/* <li className={!!this.state.iuIds?.includes(target._id) ? '': 'disable-sector'}> */ }
                                                                                <li className={ target.iusList?.length == 0 ? "disable-sector" : "" }>
                                                                                    <span class="caret indicator-caret">
                                                                                        <img src="assets/lib/images/arrow_new.svg" />
                                                                                    </span>
                                                                                    <div className="dcTreeViewLabel">
                                                                                        <>
                                                                                            <div className="d-md-flex d-block align-items-center">
                                                                                                {/* <input type="checkbox" className="align-self-center" 
                                                   id={"indicator-selectAll"+target._id}
                                                   name={"indicator-selectAll"+target._id}
                                                   onChange={(e) =>
                                                     this.selectIUS(
                                                       e,
                                                       '#',
                                                       target,
                                                       null
                                                     )
                                                   }
                                                   checked={
                                                     (target._id + '#') in
                                                     this.state.selIUSdata
                                                       ? "checked"
                                                       : ""
                                                   }
                                           /> */}
                                                                                                { target.level_name?.['en'] }
                                                                                            </div>
                                                                                        </>
                                                                                    </div>

                                                                                    <ul class="nested sub-sub-category">
                                                                                        { target.iusList?.length > 0 && target.iusList?.map((iu) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <span class="caret indicator-caret position-relative mr-2">
                                                                                                        <img src="assets/lib/images/arrow_new.svg" />
                                                                                                    </span>
                                                                                                    {/* <div className={ !!this.state.iusIds?.includes(ius._id) ? "d-flex align-items-center dataSearchFormList": "d-flex align-items-center dataSearchFormList disabled"}> */ }
                                                                                                    <div className={ !!this.state.iuIds?.includes(iu._id) ? "d-flex align-items-center dataSearchFormList" : "d-flex align-items-center dataSearchFormList disabled" }>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            id={ iu._id + '#' }
                                                                                                            name={ iu._id + '#' }
                                                                                                            checked={
                                                                                                                (iu._id + '#') in this.state.selIUSdata ? "checked" : ""
                                                                                                            }
                                                                                                            onChange={ (e) =>
                                                                                                                this.selectIUS(
                                                                                                                    e,
                                                                                                                    '#',
                                                                                                                    iu,
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                        <label
                                                                                                            for={ iu._id + '#' }
                                                                                                        >
                                                                                                            { <> { iu.name?.['en'] }</> }
                                                                                                        </label>
                                                                                                    </div>

                                                                                                    <ul class="nested sub-sub-sub-category">
                                                                                                        { iu?.subgroup?.map((subgroup) => {
                                                                                                            return (

                                                                                                                <li>
                                                                                                                    <div className={ (!!this.state.iuIds?.includes(iu._id) && !!this.state.iusIds?.includes(subgroup._id)) ? "d-flex align-items-center dataSearchFormList" : "d-flex align-items-center dataSearchFormList disabled" }>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            id={ iu._id + subgroup.name?.['en'] }
                                                                                                                            name={ iu._id + subgroup.name?.['en'] }
                                                                                                                            checked={
                                                                                                                                (iu._id + subgroup.name?.['en']) in this.state.selIUSdata ? "checked" : ""
                                                                                                                            }
                                                                                                                            onChange={ (e) =>
                                                                                                                                this.selectIUS(
                                                                                                                                    e,
                                                                                                                                    false,
                                                                                                                                    iu,
                                                                                                                                    subgroup
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                        <label
                                                                                                                            for={ iu._id + subgroup.name?.['en'] }
                                                                                                                        >
                                                                                                                            { <> { subgroup.name?.['en'] }</> }
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </li>

                                                                                                            )
                                                                                                        })
                                                                                                        }

                                                                                                    </ul>
                                                                                                </li>
                                                                                            );
                                                                                        }) }
                                                                                    </ul>
                                                                                </li>
                                                                            </>
                                                                        );
                                                                    }) }
                                                                </ul>
                                                            </li>
                                                        );
                                                    }) }
                                                </>
                                            </ul>
                                        </div>

                                        <div className="btnForm">
                                            {/* <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                        Reset
                      
                      </button> */}
                                            <button
                                                className="nextBtn"
                                                onClick={ () => this.handletabSelect("area") }
                                                disabled={ Object.entries(this.state.selIUSdata).length > (Object.keys(this.state.selIUSdata)?.includes('*') ? 1 : 0) ? false : true }
                                            >
                                                Next

                                            </button>
                                        </div>

                                        <div className="disasterSelected">
                                            <h4>Selected</h4>
                                            { this.renderselected() }
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey="area"
                                title={
                                    <>
                                        <div className="dst_list">
                                            <div className="dst_listContent">
                                                {/* <span>STEP 2</span> */ }
                                                <h4>Area</h4>
                                            </div>
                                            <div className="dst_count">
                                                { this.state.areaList?.length }
                                            </div>
                                        </div>
                                    </>
                                }
                                className="dstColumn dsAreaTab"
                            >
                                <div className="disasterSearch">
                                    <div className={ this.state.isWidth ? "dsInput col-md-12 activeWidth" : "dsInput col-md-12" }>
                                        <div className="searchBox">
                                            <input type="search" value={ this.state.searchText } onChange={ (e) => this.onSearch(e.target.value) } placeholder="Search..." className="dsSearch" />
                                            <button type="button" className="searchBox__button" onClick={ (e) => this.ToggleClass() }><img src="assets/lib/images/icon/searchicon.svg" alt="" /></button>
                                        </div>

                                        <button
                                            className="btnReset"
                                            onClick={ (e) => this.resetSelection() }
                                        >
                                            <img src="assets/lib/images/icon/reset.svg" alt="" />
                                            {/* Reset */ }

                                        </button>

                                    </div>


                                </div>
                                <div className="disasterTab">
                                    <div className="dsSelectAll">
                                        <div className="d-flex align-items-center dataSearchFormList">
                                            <input
                                                type="checkbox"
                                                checked={
                                                    this.state.checked["#"] ? "checked" : ""
                                                }
                                                onChange={ (e) => this.areaSelect(e, this.state.callData?.[0], '#', null) }
                                                id="area-selectAll"
                                                name="area-selectAll"
                                            />
                                            <label for="area-selectAll"> Select All</label>
                                        </div>
                                    </div>
                                    <div className="disasterList">
                                        <div className="disasterCheckbox disasterCheckbox--noSearchBox">
                                            <ul className="dcTreeView" id="myUL">
                                                { this.rendertree(this.state.callData?.[0]) }
                                            </ul>
                                        </div>
                                        <div className="btnForm">
                                            {/* <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                        Reset
                      
                      </button> */}
                                            <button
                                                className="nextBtn"
                                                onClick={ () => this.handletabSelect("timePeriod") }
                                                disabled={ this.state.areaList?.length > 0 ? false : true }
                                            >
                                                Next

                                            </button>
                                        </div>

                                        <div className="disasterSelected">
                                            <h4>Selected</h4>

                                            { this.renderselected() }
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="timePeriod"
                                title={
                                    <>
                                        <div className="dst_list">
                                            <div className="dst_listContent">
                                                {/* <span>STEP 3</span> */ }
                                                <h4>Time Period</h4>
                                            </div>
                                            <div className="dst_count">
                                                { this.state.selTimeperiod.includes("*") ? this.state.selTimeperiod.length - 1 : this.state.selTimeperiod.length }
                                            </div>
                                        </div>
                                    </>
                                }
                                className="dstColumn dsTimeperiodTab"
                            >
                                <div className="disasterSearch">
                                    <div className={ this.state.isWidth ? "dsInput col-md-12 activeWidth" : "dsInput col-md-12" }>
                                        <div className="searchBox">
                                            <input type="search" value={ this.state.searchText } onChange={ (e) => this.onSearch(e.target.value) } placeholder="Search..." className="dsSearch" />
                                            <button type="button" className="searchBox__button" onClick={ (e) => this.ToggleClass() }><img src="assets/lib/images/icon/searchicon.svg" alt="" /></button>
                                        </div>
                                        <button
                                            className="btnReset"
                                            onClick={ (e) => this.resetSelection() }
                                        >
                                            <img src="assets/lib/images/icon/reset.svg" alt="" />
                                            {/* Reset */ }

                                        </button>
                                    </div>

                                </div>
                                <div className="disasterTab">
                                    <div className="dsSelectAll">
                                        <div className="d-flex align-items-center dataSearchFormList">
                                            <input
                                                type="checkbox"
                                                checked={
                                                    this.state.selTimeperiod.includes("*") ? "checked" : ""
                                                }
                                                onChange={ (e) => this.selectTP(e, "*") }

                                                id="tp-selectall"
                                                name="tp-selectall"
                                            />
                                            <label for="tp-selectall"> Select All</label>
                                        </div>
                                    </div>
                                    <div className="disasterList">
                                        <div className="disasterCheckbox disasterCheckbox--noSearchBox">
                                            <div className="inner_disaster">
                                                {
                                                    this.state.fTimeperiod
                                                        .sort((a, b) => b.timeperiod?.localeCompare(a.timeperiod))
                                                        .map(tp => {
                                                            return (
                                                                <>
                                                                    <div className={ !!this.state.validTPs?.includes(tp.timeperiod) ? "d-flex align-items-center dataSearchFormList" : "d-flex align-items-center dataSearchFormList disabled" }>
                                                                        <label for={ "tp-" + tp.timeperiod }> <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                this.state.selTimeperiod.includes(tp.timeperiod) ? "checked" : ""
                                                                            }
                                                                            onChange={ (e) => this.selectTP(e, tp.timeperiod) }
                                                                            id={ "tp-" + tp.timeperiod }
                                                                            name={ "tp-" + tp.timeperiod }
                                                                        /> { tp.timeperiod }
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                        <div className="btnForm">
                                            {/* <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                        Reset
                      
                      </button> */}
                                            <button
                                                className="nextBtn"
                                                onClick={ () => this.handletabSelect("source") }
                                                disabled={ this.state.selTimeperiod?.length > 0 ? false : true }
                                            >
                                                Next

                                            </button>
                                        </div>

                                        <div className="disasterSelected">
                                            <h4>Selected</h4>

                                            { this.renderselected() }
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey="source"
                                title={
                                    <>
                                        <div className="dst_list">
                                            <div className="dst_listContent">
                                                {/* <span>STEP 4</span> */ }
                                                <h4>Source</h4>
                                            </div>
                                            <div className="dst_count">
                                                { this.state.selSource?.includes('*') ? this.state.selSource.length - 1 : this.state.selSource.length }
                                            </div>
                                        </div>
                                    </>
                                }
                                className="dstColumn dsSourcetab"
                            >
                                <div className="disasterSearch">
                                    <div className={ this.state.isWidth ? "dsInput col-md-12 activeWidth" : "dsInput col-md-12" }>
                                        <div className="searchBox">
                                            <input type="search" value={ this.state.searchText } onChange={ (e) => this.onSearch(e.target.value) } placeholder="Search..." className="dsSearch" />
                                            <button type="button" className="searchBox__button" onClick={ (e) => this.ToggleClass() }><img src="assets/lib/images/icon/searchicon.svg" alt="" /></button>
                                        </div>

                                        <button
                                            className="btnReset"
                                            onClick={ (e) => this.resetSelection() }
                                        >
                                            <img src="assets/lib/images/icon/reset.svg" alt="" />
                                            {/* Reset */ }

                                        </button>
                                    </div>


                                </div>
                                <div className="disasterTab">
                                    <div className="dsSelectAll">
                                        <div className="d-flex align-items-center dataSearchFormList">
                                            <input
                                                type="checkbox"
                                                // checked={
                                                //   this.state.selSource.includes("*") ? "checked" : ""
                                                // }
                                                checked={ this.state.selSource.includes("*") ? true : false }
                                                onChange={ (e) => this.selectSource(e, "*") }
                                                id="source-selectall"
                                                name="source-selectall"
                                            />
                                            <label for="source-selectall"> Select All</label>
                                            {/* {console.log("This is sel ssource--", this.state.selSource)} */ }
                                        </div>
                                    </div>
                                    <div className="disasterList">
                                        <div className="disasterCheckbox disasterCheckbox--noSearchBox">
                                            <div className="inner_disaster mobile_view_labels">


                                                {
                                                    this.state.fSource.map(tp => {
                                                        return (
                                                            <>
                                                                <div className={ !!this.state.validSrc?.includes(tp.datasource) ? "d-flex align-items-start dataSearchFormList" : "d-flex align-items-center dataSearchFormList disabled" }>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            this.state.selSource.includes(tp.datasource) ? "checked" : ""
                                                                        }
                                                                        onChange={ (e) => this.selectSource(e, tp.datasource) }
                                                                        id={ "source-" + tp.datasource }
                                                                        name={ "source-" + tp.datasource }
                                                                    />
                                                                    <label for={ "source-" + tp.datasource }>   { tp.datasource }</label>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="btnForm">
                                            {/* <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                        Reset
                      
                      </button> */}
                                            <button
                                                className="nextBtn"
                                                onClick={ () => this.handletabSelect("viewdata") }
                                                disabled={ this.state.selTimeperiod?.length > 0 ? false : true }
                                            >
                                                Next

                                            </button>
                                        </div>

                                        <div className="disasterSelected">
                                            <h4>Selected</h4>

                                            { this.renderselected() }
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="viewdata"
                                title={
                                    <>
                                        <div className="dst_list">
                                            <div className="dst_listContent">
                                                {/* <span>STEP 5</span> */ }
                                                <h4>View Data</h4>

                                            </div>
                                        </div>
                                    </>
                                }
                                className="h-100"
                            >
                                <DataSearchResultComponent
                                    selIUSdata={ this.state.selIUSdata }
                                    selAreaList={ this.state.areaList }
                                    selTimeperiod={ this.state.selTimeperiod }
                                    selSource={ this.state.selSource }
                                    data={ this.state.results }
                                    areaLevel={ this.state.areaLevel }
                                    callBack={ this.resetSelection }
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>

            <div
                className={
                    this.state.loader ? "loader text-center " : "loader text-center hide"
                }
            >
                <img
                    src="assets/images/Comp-2.gif"
                    style={ { marginTop: "12%", width: "30%" } }
                />
            </div>
        </>
    );
}

export default dataSearchTemplate;
