import React,  {useEffect, useState} from "react";
// ======================================================= Dropdown End
import Dropdown from 'react-bootstrap/Dropdown';
import * as $ from 'jquery';
// ======================================================= Dropdown End
// ======================================================= Form Field Start
    import TextField from '@mui/material/TextField';
    import Checkbox from '@mui/material/Checkbox';
    import Button from '@mui/material/Button';
    import FormControlLabel from '@mui/material/FormControlLabel';
    import Table from 'react-bootstrap/Table';
// ======================================================= Form Field End
// ======================================================= Tab Start
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// ======================================================= Tab End
import FixedHeaderStory from 'react-data-table-component';

// ======================================= Multi Select Start
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import adminService from "../../../../services/admin.service";
import { ToastContainer, toast } from "react-toastify";
import { json, useAsyncError } from "react-router";
import { getMonth, set } from "date-fns";
import { grid } from "@mui/system";
// ======================================= Multi Select End
// ======================================================== Date
import moment from 'moment';
import { Stack } from "@mui/system";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useStepContext } from "@mui/material";
import { dataentryfile, resourceImagePath } from "../../../../Config/app.constants";
import { validate } from "schema-utils";
import { Toast } from "bootstrap";
import dayjs from "dayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers";
// ======================================================== Date
// ======================================================= Datatable End
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

// ======================================= Multi Select Start


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


// ======================================= Multi Select End 
const  ViewDatavalidation = (props) => {
    const [userNames, setUserNames]  = useState([])
    const [questions,setQuestions  ] = useState([])
    const [answers,setAnswers  ] = useState([])

    console.log('props',props);
const getDataValidationData  = ()=>{
    if(props.data.length>0){
        let users = []
        let option = []
        let payload = {
            surveyId:props.surveyId,
            manageform_id:props.data[0]._id,
            areas:[],
            users:[]
        }
        let test = []
        props.data.forEach(x=>{
            console.log('1234',x)
            users.push(x)
            !payload.areas.includes(x.area_code)?payload.areas.push(x.area_code):test.push('')
            !payload.users.includes(x.userid)?payload.users.push(x.userid):test.push('')

           if(x.household !== null){
               x._id = x.area_code + x.household
           }
               
        })
        setUserNames(users)
        setPersonName(users)
        // setSelectedOption

        console.log('optionn',users)
        // console.log('1234',users,personName)


        adminService.httpPost('/data-retrieval/survey-data-entry/get-survey-response',payload).then(res=>{
            
                adminService.httpGet("/data-retrieval/option/get-option").then((getOptionResponse) => {
            //   console.log('getOptionResponse',getOptionResponse)
                    res.questionList.forEach(q=>{
                        q.title = q.title.en??q.title
                    })

            res.data.forEach(response=>{
                console.log('rrrr',response)
                if(response.household !== null){
                    response._idForHouseHold = response.area_code + response.household
                }

                response.question_answer.forEach(qa=>{
                    console.log('type',qa, getOptionResponse)
                    qa.questionData.options?.forEach(e2 => {
                        let q1 = getOptionResponse.data.filter(e1 => e1._id == e2.option_text)
                        console.log('===================',e2, q1)
                        e2.selected_option_text =  (q1.length > 0 && ![undefined, null].includes(q1) ) ? q1[0].name.en : ''
                    })
                        qa.options[0].original_ans = qa.options[0].ans 
                        qa.responseId = response.responseId 
                })
            }) 
            console.log(res)
            setAnswers(res.data)
            console.log('resans',res.data)
            setQuestions(res.questionList)
        })

        }).catch(err=>{
            console.log(err);
        })
    }
}

console.log('questions',questions);

    useEffect(()=>{
        getDataValidationData()
    },[props.data])

// console.log('answers',answers);

    // const changeAnswers = (row,value)=>{
    //     console.log('row',row);
    //     let preValue = answers
    //     preValue.forEach(answer=>{
    //         if(answer.area_code==row.area_code && answer.userId==row.user_id){
    //             answer.question_answer.forEach((qa)=>{
    //                 if(qa.question_id == row.question_id){
    //                     qa.options[0].ans = row.questionData.type == 'image' ? imageNameWithTimeStamp: value
    //                     console.log('imageNameWithTimeStamp ',imageNameWithTimeStamp);
    //                 }

    //             })
    //         }
    //     })
    //     console.log(value)
    //     setAnswers([...preValue])
    //     console.log('answers',answers)
    // }







  






// ======================================================= Dropdown Start
const [areaList, setArea] = useState('');


const [value, setValue] = useState(2);
const [time, setTime] = useState(null);
const [gridData,setGridData] = useState([])
const [row, setRow] = useState({});
const [column, setColumn] = useState({});
const [gridRows,setRows] =useState({})
const [gridRow,setGridRow] = useState({})
const [gridColumns,setGridColumns] = useState({})
const [gridQuestions,setGridQuestions] =useState([])
const [showGridModal,setShowGridModal] = useState(false)

const [fromdate, setFromdate] = useState(null);
const [files,setFiles]=useState([])
const [modalViewType,setModalViewType] = useState({})

let imageNameWithTimeStamp = ''
let timeStamp = new Date().getTime()

console.log('gridRow',gridRow);
console.log('gridQuestions',gridQuestions);
// const onImageChange = (event) => {
//  if (event.target.files && event.target.files[0]) {
//    setImage(URL.createObjectURL(event.target.files[0]));
//  }

// }

// function formatAMPM(date) {
//     var hours = date.getHours();
//     var minutes = date.getMinutes();
//     var ampm = hours >= 12 ? 'pm' : 'am';
//     hours = hours % 12;
//     hours = hours ? hours : 12; // the hour '0' should be '12'
//     minutes = minutes < 10 ? '0'+minutes : minutes;
//     var strTime = hours + ':' + minutes + ' ' + ampm;
//     return strTime;
// }

console.log('time',time);



const getBackendImage =(row,rowAnswer)=>{
    if(row.options[0].original_ans !== rowAnswer ){
        return row.options[0].original_ans
    }
    return rowAnswer
}

const handleFromdate = (newValue) => {
    setFromdate(newValue);
  };

  const getValue= (responseBackendDate) =>{
    const date = new Date(responseBackendDate)
    return date
  }

//   console.log('fromdate',fromdate)

const columns1 = [
    {
        name: 'Data Value',
        // selector: row => row.options[0].ans,
        sortable: true,
        cell:row => {
            let type = row.questionData.type
            return (
                <>
                {
                        type=='text'? 
                            <div className="col-md-12 px-0">
                                <TextField onChange={(e)=>changeAnswers(row,e.target.value)} type="text" id="outlined-basic" value={row.options[0].ans} className="input-text w-100" variant="outlined" /> 
                            </div>
                        :
                        type=='numeric'? 
                            <div className="col-md-12 px-0">
                                <TextField onChange={(e)=>changeAnswers(row,e.target.value)} type="number" id="outlined-basic" value={row.options[0].ans} className="input-text w-100" variant="outlined" /> 
                            </div>
                        :
                        // type=='time'?
                        //     <TextField onChange={(e)=>{changeAnswers(row,e.target.value);console.log(e.target.value);}} type="time" id="outlined-basic" value={row.options[0].ans} className="input-text" variant="outlined" />
                        // :
                        type=='single_select'?
                            <div className="col-md-12 px-0">
                                <select className="custom-select-input w-100" onClick={()=>console.log(row)}   value={row.options[0].ans} onChange={(e)=>changeAnswers(row,e.target.value)}>
                                    {
                                        row.questionData.options.map(option=>{
                                            return(
                                                <option value={option.selected_option_text} >{option.selected_option_text}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        :
                        type == 'date'?
                         <>
                            {/* <TextField onClick={()=>console.log(row)} type="date" placeholder="2222" value={row.options[0].ans != null ? getValue(row.options[0].ans) : null}/> */}
                            {/* <div className="col-md-3">
                                <div className="form-group mb-0 inputform_style datePicker">
                                   <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                        <TextField disabled value={row.options[0].ans != null ? getValue(row.options[0].ans) : null } id="outlined-basic" />                                          
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-12 px-0">
                                    <div className="form-group mb-0 inputform_style fp_dropdown dataValidationDatapicker">
                                        <div className="mat-form-field-wrapper">                                        
                                            <LocalizationProvider dateAdapter={AdapterDayjs}  onClick={()=>console.log(row.options[0].ans)}   >
                                                <Stack spacing={1}>
                                                    <DesktopDatePicker                                               
                                                        inputFormat="DD/MM/YYYY"
                                                        value={row.options[0].ans != null ? getValue(row.options[0].ans ) : fromdate}
                                                        onChange={handleFromdate}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        format="YYYY-MM-DD"
                                                        />                                                 
                                                </Stack>
                                            </LocalizationProvider>                                         
                                        </div>
                                    </div>
                                </div> 
                          </>
                        :
                        type == 'time'?
                            <div className="col-md-12 px-0 inputTime">
                                {/* <input type="time"  onChange={(e)=>{changeAnswers(row,e.target.value)}} value={row.options[0]?.ans}  onClick={()=> console.log(row.options[0]?.ans)} /> */}
                        
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                     <Stack>
                                         <DesktopTimePicker
                                         value={row.options[0]?.ans == null ? time: dayjs(row.options[0]?.ans, "h:mm a")}
                                         defaultValue={null}
                                         onChange={(newValue) => {
                                            // setTime( dayjs(newValue).format("h:mm a"))
                                            changeAnswers(row,dayjs(newValue).format("h:mm a"))
                                         }}
                                         renderInput={(params) => <TextField {...params} />}
                                         />
                                     </Stack>
                                 </LocalizationProvider>                   
                            </div>
                        :
                        type == 'image'?
                        <>
                            <div className="arrow-sec py-1">
                                {/* <TextField title={row.options[0].ans} onClick={()=>console.log(row)} onChange={(e)=>{changeAnswers(row,e.target.value); onImageChange(e)}}type="file" />
                               
                                {/* <TextField title={row.options[0].ans} onClick={()=>console.log(row)} onChange={(e)=>{saveDocQuestion(e.target.value)}} type="file" /> */}
                                <input id="inputImage" title={row.options[0].ans}  onChange={(e)=>{
                                    if(validateExtension(row,e.target.files[0])){
                                        saveDocQuestion(row._id,e.target.files[0])
                                        changeAnswers(row,e.target.files[0]?.name)
                                    }else{
                                        toast.error("Please Selet These Types Only jpg|jpeg|png|gif") 
                                    }
                                    }} type="file" />

                                <p className="image-name d-inline-block mt-1 d-flex mb-0  align-items-end">
                                {row.options[0].ans != null ? getBackendImage(row,row.options[0].ans) : null} 
                                    {
                                        row.options[0].ans != null ?
                                    
                                <img data-toggle="modal" data-target="#showImage" src="assets/lib/images/home/hover_arrow.svg" className="ml-1" style={{'width': '10px'}} onClick={()=> {
                                setModalViewType({
                                    ans: row.options[0].ans,
                                    type: row.questionData.type
                                })
                                    }}/> : null
                                }

                                
                                </p>

                            </div>
                            </>
                        :
                        type == 'audio'?
                        <>
                            <div className="arrow-sec arrow_sec_audio py-1">
                                {/* <TextField title={row.options[0].ans} onClick={()=>console.log(row)} onChange={(e)=>changeAnswers(row,e.target.value)} type="file" /> */}
                                <TextField title={row.options[0].ans} onClick={()=>console.log(row)} 
                                // onChange={(e)=>{saveDocQuestion(row._id,e.target.files[0]); changeAnswers(row,e.target.files[0].name)}} 
                                onChange={(e)=>{
                                    if(validateExtension(row,e.target.files[0])){
                                        saveDocQuestion(row._id,e.target.files[0])
                                        changeAnswers(row,e.target.files[0]?.name)
                                    }else{
                                        toast.error("Please Selet These Types Only mpeg") 
                                    }
                                }}
                                type="file" />
                                <p className="image-name d-inline-block mt-1 mb-0  align-items-end">
                                {row.options[0].ans != null ? getBackendImage(row,row.options[0].ans) : null} 
                                {
                                        row.options[0].ans != null ?
                                <img data-toggle="modal" data-target="#showImage" src="assets/lib/images/home/hover_arrow.svg" className="ml-1" style={{'width': '10px'}} onClick={()=> {
                                setModalViewType({
                                    ans: row.options[0].ans,
                                    type: row.questionData.type
                                })
                            }}
                                /> : null
                            }
                                
                                {/* {console.log('audio',row.options[0].ans)} */}
                                </p>
                            </div>
                        </>
                        :
                        type == 'grid'?
                        <>
                           {/* <TextField value='...' onClick={()=> console.log(JSON.parse(row.questionData.grid))} disabled/> */}
                           {/* <a  class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#gridanswer_popup"  >...</a> */}
                           <a  class="dropdown-item text-bold grid_txtbox"  
                           onClick={()=>{
                            // setGridData(JSON.parse(row.questionData.grid))

                            let data = JSON.parse(row.questionData.grid)
                            setColumn(data.columns.count)
                            setGridColumns(data.columns.names)
                            setRow(data.rows.count)
                            setRows(data.rows.names)
                            setGridRow(row)
                            // setGridColumns(data.columns)
                            // setRows(data.)
                            console.log('dddd',data)
                             console.log('grid',row.options)
                             let obj={}
                             row.options.forEach(data=>{
                                console.log('grid',data)
                                let key=data.grid_row + data.grid_col
                               obj[key] = data.ans
                             })
                             console.log('grid',obj)
                             setGridData(obj)

                               Object.keys(obj).map((gridKeys)=>{
                                    Object.keys(data.questions).map((questionKeys)=>{
                                        if(gridKeys == questionKeys){
                                            console.log('dddd',data.questions[gridKeys])
                                            data.questions[gridKeys].ans = obj[gridKeys]
                                        }
                                    })
    
                                 })
                                 let dataQuestions = data.questions
                                setGridQuestions(dataQuestions)
                            //  gridQuestions = data.questions
                         
                            // setTimeout(()=>{
                                setShowGridModal(true)
                                $('#xyz').modal()
                            // },0)
                             console.log('griddata',row);
                           }}  >...</a> 
                    
                        </>:
                        <p onClick={()=>console.log(row,type)} >{row.options[0].ans+'ans'}</p>  
                }
                </>
            )

            }
    },
    // {
    //     name: 'Time Period Format',
    //     selector: row => row.options[0].time_period_format,
    //     sortable: true,
    // },
    // {
    //     name: 'Time Period',
    //     selector: row => row.options[0].time_period,
    //     sortable: true,
    // },
    // {
    //     name: 'Source',
    //     selector: row => row.options[0].source,
    //     sortable: true,
    // },   
    {
        name: 'Action',
        width: "200px",
        cell: row =>
            <>
                 {/* <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={() =>saveResponse(row)}>Save</a>
                            <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={() =>deleteResponse(row)}>Delete</a>
                        </div>
                </div> */}
                <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() =>saveResponse(row)}  href="javascript:;">Save</Dropdown.Item>
                            <Dropdown.Item onClick={() =>deleteResponse(row)} href="javascript:;">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                </Dropdown>
            </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        
    },
];

const saveResponse = (e)=>{
    console.log('save',e)
    if(e.questionData.type == 'image' || e.questionData.type == 'audio'){
        if(files.length == 0){
            toast.error('Please Select Valid Type')
            return
        }
    }
        let payload = {
            "responseId": e.responseId,
            "answerId": e.options[0]._id,
            "qid":e.question_id,
            "options": JSON.stringify(e.options),
            "original_options": [],
            "type": questions.filter(x=> x._id == e.question_id)[0]?.type,
            "all_files": e.questionData.type == 'image' || e.questionData.type == 'audio' ?JSON.stringify(files) :JSON.stringify([])
        }
        adminService.httpPost('/data-import/survey-data-entry/update-response-value',payload).then(res=>{
            console.log(res);
            getDataValidationData() 
            setGridQuestions([])
            toast.success("Response is updated")
        }).catch(err=>{
            console.log(err);
        })
}



const deleteResponse = (e)=>{
    console.log('delete',e)


    if(e.questionData.type == 'grid'){
        e.options.forEach((data,index)=>{
            e.options[index].ans = null
        })
    }else{
        e.options[0].ans = null
    }

    // consolelog('delete',e.options)
    let payload = {
        "responseId": e.responseId,
        "answerId": e.options[0]._id,
        "qid":e.question_id,
        "options": JSON.stringify(e.options),
        "original_options": [],
        "type": questions.filter(x=> x._id == e.question_id)[0]?.type,
        "all_files": e.questionData.type == 'image' || e.questionData.type ==  'audio' ?JSON.stringify(files) :JSON.stringify([])
    }
    console.log('delete',payload)
    adminService.httpPost('/data-import/survey-data-entry/update-response-value',payload).then(res=>{
        if(e.questionData.type == 'date'){
            setFromdate(null)
        }else if(e.questionData.type == 'time'){
            setTime(null)
        }
        console.log(res);
       changeAnswers(e,'')
        toast.success("Response is updated")
        getDataValidationData()
    }).catch(err=>{
        console.log(err);
    })
}

// console.log('imageNameWithTimeStamp',imageNameWithTimeStamp);

const validateExtension = (row,file)=>{
    console.log('validate',row)
    if(row.questionData.type === "image"){
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            console.log('Please select valid image')
            setFiles([]) 
            return false
        }
    }else if(row.questionData.type === "audio"){
        if (!file.name.match(/\.(mpeg)$/)) {
            console.log('Please select valid audio')
            setFiles([]) 
            return false
        }
    }
    return true
}

const saveDocQuestion= async (id,file)=>{
    console.log('for image called')
        let fileObj = {
            q_id:id,
            name:timeStamp.toString() +'_' + file.name,
            file:await toBase64(file)
        }
    
        let preValue = files
        let preFile = preValue.filter(x=> x.q_id===fileObj.q_id)
        if(preFile.length>0){
            preValue.forEach(x=>{
                if(x.q_id===x.q_id){
                    x = fileObj
                }
            })
        }else{
            preValue.push(fileObj)
        }
        setFiles(preValue)
     
}

console.log('files',files )

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const   changeAnswers = (row,value,gridKey,optionIndex)=>{

  
    let preValue = answers
    preValue.forEach(answer=>{
        if(answer.area_code==row.area_code && answer.userId==row.user_id){
            answer.question_answer.forEach((qa)=>{
                console.log('qa',qa);
                if(qa.question_id == row.question_id){
                    if(row.questionData.type == 'image'){
                        qa.options[0].ans = timeStamp.toString() +'_' + value
                    }else if(row.questionData.type == 'audio'){
                        qa.options[0].ans = timeStamp.toString() +'_' + value
                    }else if(row.questionData.type == 'grid'){
                        let data = JSON.parse(row.questionData.grid)
                            console.log('griddata',gridData)
                            gridData[gridKey] = value

                               Object.keys(gridData).map((gridKeys)=>{
                                    Object.keys(data.questions).map((questionKeys)=>{
                                        if(gridKeys == questionKeys){
                                            console.log('dddd',data.questions[gridKeys])
                                            data.questions[gridKeys].ans = gridData[gridKeys]
                                            qa.options[optionIndex].ans = value
                                        }
                                    })
    
                                 })
                                 let dataQuestions = data.questions
                                 setGridQuestions(dataQuestions)
                            
                    }
                    else{
                        qa.options[0].ans = value
                    }
                    // qa.options[0].ans = row.questionData.type == 'image' || row.questionData.type == 'audio' ? timeStamp.toString() +'_' + value: value
                    // console.log('imageNameWithTimeStamp ',imageNameWithTimeStamp);
                }

            })
        }
    })
    console.log('value',value)
    setAnswers([...preValue])
    console.log('answers',answers,optionIndex)
    console.log('gridQuestions',gridQuestions);
    console.log('answers',optionIndex)

}

const dataValidate = [
    {
        id: 1,
        question: <><TextField id="outlined-basic" className="input-text" variant="outlined" /></>,
        section: 'YYYY',
        type: '2023',
        mandatoryquestion: 'ddd',
       
    },
    {
        id: 2,
        question: <><TextField id="outlined-basic" className="input-text" variant="outlined" /></>,
        section: 'YYYY',
        type: '2023',
        mandatoryquestion: 'ddd',
      
    },
    {
        id: 3,
        question: <><TextField id="outlined-basic" className="input-text" variant="outlined" /></>,
        section: 'YYYY',
        type: '2023',
        mandatoryquestion: 'ddd',
      
    },
]

const handleChange = (event, newValue) => {
  setValue(newValue);
};

// ======================================================= Dropdown End
// ======================================= Multi Select Start
const [personName, setPersonName] = useState([]);
const [selectedOption,setSelectedOption] = useState([]);

console.log('personName',personName)

  const handleMultiselect = (event) => {
    setPersonName(event.target.value);
    // console.log(personName);
  };

  const handlePrevious =(row)=>{
props.openDataEntryModal(row)
  }
  const day = new Date();
    const m = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const currentDate = day.getDate() + ' ' + m[day.getMonth()] + ' ' + day.getFullYear();

// ======================================= Multi Select End
    return (
        <>
            <div class="modal fade timePeriodPopup dataentryModal dev_modal" id="datavalidationview" tabindex="-1" role="dialog" aria-labelledby="dataentryCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <div class="summary_head">
                            <h5 class="modal-title" id="dataentryTitle">Data Validation | <span  >{props.name?.en}</span></h5>
                            <p  class="date mb-0 p-0">{currentDate}</p>
                        </div>  
                        <div className="completion_status">
                            <div className="cs_head">
                                <h4>Completion Status</h4>
                                <span className="csh_count">06 /06</span>
                            </div>    
                            <div className="cs_print">
                                <img src="assets/lib/images/home/print.svg" />
                                <h4>Download / Print</h4>
                            </div>
                        </div>                      
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body viewModal p-0">
                        <div className="rightTab_form ">
                            <Box sx={{ borderBottom: 1 }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={<div className='label-text tabsection1' disabled ><div className="rtf_num">1</div><div className="rtf_text">Setup</div></div>}  {...a11yProps(0)}/>
                                    <Tab label={<div className='label-text tabsection1' disabled><div data-dismiss="modal" data-toggle="modal" data-target="#dataentryview" className="rtf_num">2</div><div className="rtf_text">Data Entry</div></div>}  {...a11yProps(1)}/>
                                    <Tab label={<div className='label-text tabsection1'><div className="rtf_num">3</div><div className="rtf_text">Data Validation</div></div>}  {...a11yProps(2)}/>
                                </Tabs>
                            </Box>
                        </div>  
                        <div className="viewModal_body">
                            <div className="row align-items-center mb-30 justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <label>Area</label>
                                        <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{areaList}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Dhaulpur')}}>Dhaulpur</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Karauli')}}>Karauli</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Sirohi')}}>Sirohi</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Baran')}}>Baran</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Jaisalmer')}}>Jaisalmer</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setArea('Rajasthan')}}>Rajasthan</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>                                    
                                        </div>
                                    </div>
                                </div>         
                            </div>
                            <div className="px-70 mb-5 pb-3">
                                <div className="form_type">
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>User Name</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField disabled placeholder="Admin"/>                                   
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div class="indicatorUnit pb-3">
                                                <p>Location</p>
                                                <div className="row align-items-end mx-0">
                                                    <div className="col-md-2">
                                                        <div className="longitude cursor-disabled">
                                                            <img src="assets/lib/images/home/pin.svg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Longitude</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled placeholder="77.2060128" />                                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Latitude</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled placeholder="28.5649161" />                                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Source</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField disabled placeholder="Finland Proposal Results Framework" />                                   
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 inputform_style labelCheckbox">
                                                <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Mandatory Question" />
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div class="indicatorUnit pb-3">
                                                <p>Time Period</p>
                                                <div className="row align-items-end mx-0 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Time Period Format</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <Dropdown disabled>
                                                                    <Dropdown.Toggle disabled>YYYY.MM</Dropdown.Toggle>                                                           
                                                                </Dropdown>                                                        
                                                            </div>
                                                        </div>
                                                    </div>  
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                            <label>Time Period</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled placeholder="2023.01" />                                          
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="row align-items-end mx-0">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-0 inputform_style labelCheckbox">
                                                            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Default Time Period" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="general_qus">
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-12">
                                        <h3 className="section_heading">Personal Details</h3>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-2">
                                    <div className="col-md-1">
                                        <h4>Q1</h4>
                                    </div>
                                    <div className="col-md-11 pl-0">
                                        <p>How many members have in family</p>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-md-1 pt-3">
                                        <h4>Ans</h4>
                                    </div>
                                    <div className="col-md-11 pl-0">
                                        <div className="row">
                                        <div className="col-md-3">
                                                <div className="form-group mb-0 inputform_style dropdown_format">
                                                    <label>Answer</label>
                                                    <div className="mat-form-field-wrapper">
                                                        <TextField disabled placeholder="5" />                                
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-md-3">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <label>Time Period Format</label>
                                                    <div className="mat-form-field-wrapper">
                                                        <Dropdown disabled>
                                                            <Dropdown.Toggle disabled>YYYY.MM</Dropdown.Toggle>                                                    
                                                        </Dropdown>                                                
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-md-3">
                                                <div className="form-group mb-0 inputform_style datePicker">
                                                    <label>Time Period</label>
                                                    <div className="mat-form-field-wrapper">
                                                        <TextField disabled placeholder="2023.01" />                                          
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <label>Enter Source</label>
                                                    <div className="mat-form-field-wrapper">
                                                        <TextField disabled placeholder="Finland Proposal Results Framework" />                                   
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                        {                                       
                            props.alignment === 'Completed' ?  <Button variant="contained" className="mb-0 text-unset" data-dismiss="modal" data-toggle="modal" data-target="#dataentryview" onClick={()=> handlePrevious(props.row)}> Previous <img src="assets/lib/images/home/arrow_right.svg" /></Button> : null
                                // <Button variant="contained" className="mb-0 text-unset" disabled> Next <img src="assets/lib/images/home/arrow_right.svg" /></Button>                    
                        }
                                      
                        </div>                        
                    </div>
                    </div>
                </div>
            </div>
         
            <div class="modal fade data-validation timePeriodPopup dataentryModal" id="datavalidation1" tabindex="-1" role="dialog" aria-labelledby="dataentryCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center py-3">
                            <div class="summary_head">
                                <h5 class="modal-title" id="dataentryTitle">Data Validation | {props.name?.en}</h5>
                            </div>                       
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={()=> setFromdate(null)}></button>
                        </div>
                        <div class="modal-body viewModal p-0">
                            <div class="viewModal_body h-100">
                                <div class="row align-items-center mb-30 justify-content-center">
                                    <div class="col-md-6">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Area</label>
                                            <div className="mat-form-field-wrapper">
                                                <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={personName}
                                                        onChange={handleMultiselect}
                                                        input={<OutlinedInput label="" />}
                                                        renderValue={(selected) => selected.map(obj => `${obj.area_name.en??obj.area_name} - ${obj.household != undefined ? 'Household' + ' ' + obj.household : ''} | ${obj.name}`).join(" , ")}
                                                        MenuProps={MenuProps}
                                                        >
                                                        {userNames.map((name,i) => {
                                                              console.log('namename',personName)
                                                              console.log('namename',name)
                                                           return (
                                                            <MenuItem key={i} value={name}>
                                                            {/* <Checkbox checked={personName.some(x=> x.area_name.en == name.area_name.en && x.name == name.name )} /> */}
                                                            <Checkbox checked={personName.some(x=> x._id== name._id)} />
                                                            <ListItemText primary={`${name.area_name.en??name.area_name} ${name.household == undefined ? '' : 'Household' + name.household}| ${name.name}`} />
                                                            </MenuItem>
                                                        )})}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>    
                                   
                               </div>
                               {/* <div class="">
                                    <button disabled>
                                        <i class="fa fa-trash"></i>
                                    </button>
                               </div> */}
                               <div className="row mb-30 formula_area mt-4 datavalida_main_row">
                                        <div className="col-md-5 p-0">
                                            {/* <h5>Select Question</h5> */}

                                                <div className="calc_table data-table overflow_adjust">
                                                    <Table className="h-100">
                                                    <thead>
                                                        <tr>
                                                        <th>#</th>
                                                        <th className="popup_icon_drop align-items-center"> Question 
                                                    
                                                            {/* <div class="dropdown d-inline-block">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                            <img src="assets/lib/images/popup_icon.svg"></img>
                                                            </button>
                                                            <div class="dropdown-menu">
                                                            <div className="calc_search d-flex align-items-center">
                                                                    <input type="search" placeholder="Search..." />
                                                                    <img class="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                                                </div>
                                                            <a class="dropdown-item">Sort A - Z</a>
                                                            <a class="dropdown-item"> Sort Z - A</a>
                                                            <a class="dropdown-item">Something else here</a>
                                                            </div>
                                                            </div> */}
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        {
                                                            answers?.filter(itemB => personName.some(itemA => itemA.userid === itemB.userId && itemA.area_code === itemB.area_code))[0]?.question_answer.map((ques,i)=>{
                                                                return (
                                                                    <tr>
                                                                        <td class="check_table"><span class="chec_lable"><span class="example-margin">{i+1}</span></span></td>
                                                                        <td>
                                                                            {ques.questionData.title.en}
                                                                            { ques.questionData.type == "audio" || ques.questionData.type == "image" ?
                                                                                <p></p> : ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </tbody>
                                                </Table>
                                                </div>

                                        </div>

                                            
                                                 { 
                                                    answers.filter(itemB => personName.some(itemA => itemB._idForHouseHold == undefined ? itemA.userid === itemB.userId && itemA.area_code === itemB.area_code :itemA._id === itemB._idForHouseHold )).map(response=>{
                                                        // console.log('response.question_answer',response.question_answer)
                                                        return (
                                                            <div  class="col-md-7 p-0 right_table_adjust">
                                                                    <div className="sticky_head">
                                                                        <p class="text-center vt_grey vt-country">
                                                                        {response.area.en??response.area} <span class="vt_dar_grey">- {response.household != null ? 'Household'+response.household : ''} {response.user}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="assignedList validate-table">
                                                                        <div className="row m-0"> 
                                                                            <div className="col-md-12 px-0">
                                                                           
                                                                                <FixedHeaderStory
                                                                                   
                                                                                    data={response.question_answer}
                                                                                   
                                                                                    columns={columns1}
                                                                                    noDataComponent="No Data"
                                                                                    // selectableRows
                                                                                    persistTableHead
                                                                                    onSelectedRowsChange={handleChange}
                                                                                    className="right_side_main_table"
                                                                                />                                    
                                                                            </div>
                                                                        </div>
                                                                
                                                                    </div>
                                                             </div>
                                                        )
                                                    })
                                                }

                                                {/* { 
                                                answers.filter(itemB=>{
                                                    itemB._idForHouseHold == undefined ?
                                                    
                                                    answers.filter(itemB => personName.some(itemA => itemA.userid === itemB.userId && itemA.area_code === itemB.area_code)).map(response=>{
                                                        // console.log('response.question_answer',response.question_answer)
                                                        return (
                                                            <div  class="col-md-7 p-0 right_table_adjust">
                                                                    <div className="sticky_head">
                                                                        <p class="text-center vt_grey vt-country">
                                                                        {response.area.en??response.area} <span class="vt_dar_grey">- {response.household != null ? 'Household'+response.household : ''} {response.user}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="assignedList validate-table">
                                                                        <div className="row m-0"> 
                                                                            <div className="col-md-12 px-0">
                                                                           
                                                                                <FixedHeaderStory
                                                                                   
                                                                                    data={response.question_answer}
                                                                                   
                                                                                    columns={columns1}
                                                                                    noDataComponent="No Data"
                                                                                    // selectableRows
                                                                                    persistTableHead
                                                                                    onSelectedRowsChange={handleChange}
                                                                                    className="right_side_main_table"
                                                                                />                                    
                                                                            </div>
                                                                        </div>
                                                                
                                                                    </div>
                                                             </div>
                                                        )
                                                        
                                                    }
                                                    )
                                                    :
                                                    answers.filter(itemB => personName.some(itemA => itemA._id === itemB._idForHouseHold )).map(response=>{
                                                        // console.log('response.question_answer',response.question_answer)
                                                        return (
                                                            <div  class="col-md-7 p-0 right_table_adjust">
                                                                    <div className="sticky_head">
                                                                        <p class="text-center vt_grey vt-country">
                                                                        {response.area.en??response.area} <span class="vt_dar_grey">- {response.household != null ? 'Household'+response.household : ''} {response.user}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="assignedList validate-table">
                                                                        <div className="row m-0"> 
                                                                            <div className="col-md-12 px-0">
                                                                           
                                                                                <FixedHeaderStory
                                                                                   
                                                                                    data={response.question_answer}
                                                                                   
                                                                                    columns={columns1}
                                                                                    noDataComponent="No Data"
                                                                                    // selectableRows
                                                                                    persistTableHead
                                                                                    onSelectedRowsChange={handleChange}
                                                                                    className="right_side_main_table"
                                                                                />                                    
                                                                            </div>
                                                                        </div>
                                                                
                                                                    </div>
                                                             </div>
                                                        )
                                                        
                                                    }
                                                    )
                                                })
                                                   
                                                } */}

                                                 {/* { 
                                                    answers.filter((itemB) => {
                                                        itemB._idForHouseHold == undefined ?
                                                            
                                                                   personName.some(itemA => itemA.userid === itemB.userId && itemA.area_code === itemB.area_code)
                                                                   .map(response=>{
                                                                   // answers.filter(itemB => personName.some(itemA => itemA._id === itemB._idForHouseHold )).map(response=>{
                                                                   // console.log('response.question_answer',response.question_answer)
                                                                   console.log('responsee',answers._idForHouseHold)
                                                                   return (
                                                                       <div  class="col-md-7 p-0 right_table_adjust">
                                                                               <div className="sticky_head">
                                                                                   <p class="text-center vt_grey vt-country">
                                                                                   {response.area.en??response.area} <span class="vt_dar_grey">- {response.household != null ? 'Household'+response.household : ''} {response.user}</span>
                                                                                   </p>
                                                                               </div>
                                                                               <div className="assignedList validate-table">
                                                                                   <div className="row m-0"> 
                                                                                       <div className="col-md-12 px-0">
                                                                                      
                                                                                           <FixedHeaderStory
                                                                                              
                                                                                               data={response.question_answer}
                                                                                              
                                                                                               columns={columns1}
                                                                                               noDataComponent="No Data"
                                                                                               // selectableRows
                                                                                               persistTableHead
                                                                                               onSelectedRowsChange={handleChange}
                                                                                               className="right_side_main_table"
                                                                                           />                                    
                                                                                       </div>
                                                                                   </div>
                                                                           
                                                                               </div>
                                                                        </div>
                                                                   )
                                                               })
                                                        : 
                                                                    personName.some(itemA => itemA._id === itemB._idForHouseHold )
                                                                   .map(response=>{
                                                                   // answers.filter(itemB => personName.some(itemA => itemA._id === itemB._idForHouseHold )).map(response=>{
                                                                   // console.log('response.question_answer',response.question_answer)
                                                                   console.log('responsee',answers._idForHouseHold)
                                                                   return (
                                                                       <div  class="col-md-7 p-0 right_table_adjust">
                                                                               <div className="sticky_head">
                                                                                   <p class="text-center vt_grey vt-country">
                                                                                   {response.area.en??response.area} <span class="vt_dar_grey">- {response.household != null ? 'Household'+response.household : ''} {response.user}</span>
                                                                                   </p>
                                                                               </div>
                                                                               <div className="assignedList validate-table">
                                                                                   <div className="row m-0"> 
                                                                                       <div className="col-md-12 px-0">
                                                                                      
                                                                                           <FixedHeaderStory
                                                                                              
                                                                                               data={response.question_answer}
                                                                                              
                                                                                               columns={columns1}
                                                                                               noDataComponent="No Data"
                                                                                               // selectableRows
                                                                                               persistTableHead
                                                                                               onSelectedRowsChange={handleChange}
                                                                                               className="right_side_main_table"
                                                                                           />                                    
                                                                                       </div>
                                                                                   </div>
                                                                           
                                                                               </div>
                                                                        </div>
                                                                   )
                                                               })
                                                    })
                                                 } */}
                

                                            
                                    </div>
                        
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3 py-0">  
                                <Button variant="contained" className="mb-0 text-unset mx-auto" data-dismiss="modal" data-toggle="modal">Close</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


          
            <div class="modal fade data-validation timePeriodPopup dataentryModal" id="xyz" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header align-items-center">
                            <div class="summary_head">
                                <h5 class="modal-title" id="dataentryTitle">Data Validation</h5>
                            </div>                       
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                <div class="modal-body">
                    <div className="row align-items-center">                                                                            
                        <div className="col-md-12 pl-0">
                            <div class="row align-items-center membersTable mx-0">
                          
                            {
                                showGridModal ? 
                            Object.keys(gridColumns).map((c) => {
                              return c <= column ? (
                                <>
                                 <div class="col-md-2"><h4 class="col-head"></h4></div>
                                 <div class="col-md-2"><h4 class="col-head">{gridColumns[c]}</h4></div>
                                </>
                              ) : null;
                            }) 
                            : null
                            }
                        
                            </div>

                            <div className="main-row">
                           
                               
                            {
                            showGridModal ?
                            Object.keys(gridRows).map((r, ri) => {
                            return Number(r) <= Number(row) ? (
                                <>
                                <div className="row mb-3 mx-0">
                                            <div className="col-md-2">
                                                {gridRows[r]}
                                                {console.log('griddata',gridData)}
                                            </div>
                                {Object.keys(gridColumns).map((c, ci) => {
                                  return c <= column ? (
                                    <>
                                <div className="col-md-5">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <div className="mat-form-field-wrapper">
                                            {console.log("ccc", ri,ci)}
                                            <label>Answer</label>
                                            <TextField  type="number" 
                                            value={gridQuestions[ri+''+ci]?.ans} 
                                            onChange={(e)=>changeAnswers(gridRow,e.target.value,ri+''+ci,ri)}
                                            />
                                        </div>
                                    </div>                                                   
                                </div>
                                    </>
                                  ) : null;
                                })}
                                </div>
                            </>
                            ) : null;
                          }) 
                          : null
                        }  
                          
                            </div>
                        </div>
                    </div>
                </div> 

{/* <div className="row mb-30">
                    <div className="col-md-12 areaTable">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {Object.keys(gridColumns).map((c) => {
                              return c <= column ? (
                                <th >{gridColumns[c]}</th>
                              ) : null;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(gridRows).map((r, ri) => {
                            return Number(r) <= Number(row) ? (
                              <tr>
                                <td>
                                <div className="row mb-1 mx-0">
                                            <div className="col-md-3">  
                                            <div className="mat-form-field-wrapper">
                                            {gridRows[r]}
                                            </div>
                                </div>
                                </div>
                                </td>
                                {Object.keys(gridColumns).map((c, ci) => {
                                  return c <= column ? (
                                    <td>
                                      <div className="row mb-3 mx-0">
                                            <div className="col-md-3">
                                            <div className="mat-form-field-wrapper">
                                                <TextField disabled type="number" />
                                            </div>
                                </div>
                                </div>
                                    </td>
                                  ) : null;
                                })}
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div> */}
                
                
                <div class="modal-footer d-flex">
                    <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <Button variant="contained" onClick={()=> setShowGridModal(false)} className="mb-0 text-unset mx-auto" data-dismiss="modal" data-toggle="modal">Close</Button>
                    </div>
                </div>
                </div>
            </div>
            </div> 

            <div class="modal fade showImage timePeriodPopup showAudio" id="showImage" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header align-items-center border-0">
                            {/* <div class="summary_head">
                                <h5 class="modal-title" id="dataentryTitle"></h5>
                            </div>                        */}
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className={modalViewType.type == 'audio'? "popupImageSize audiodiv" : "popupImageSize"} >
                                {/* {
                                    image.type == 'image'?  <img src={`${dataentryfile}${image.ans}`} class="d-block mx-auto"/> : 
                                    <>
                                    <audio controls>
                                        <source src={`${dataentryfile}${audio.ans}`} type="audio/mpeg"/>
                                    </audio>
                                    </> 
                                } */}
                                 {
                                    modalViewType.type == 'audio'?  
                                    <audio controls>
                                    <source src={`${dataentryfile}${modalViewType.ans}`} type="audio/mpeg"/>
                                   </audio>: 
                                    <img src={`${dataentryfile}${modalViewType.ans}`} class="d-block mx-auto"/>
                                }
                                 {console.log(`modalviewtype ${dataentryfile}${modalViewType.ans}`)}
                               
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    );
}

export default ViewDatavalidation



