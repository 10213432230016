import { FormControl, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import adminService from '../../../services/admin.service';
import * as $ from "jquery";
const MetadataPage = () => {

  const location = useLocation();
  // const goalSDG = location?.state?.goalname;
  const [goalSDG, setGoalSDG] = useState(location?.state?.goalname ? location?.state?.goalname : 'Goal 1:');

  const [allData, setAllData] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [iusDisplay, setIusDisplay] = useState([]);
  const [activeIus, setActiveIus] = useState({
    iusName: '',
    id: '',
    targetId: '',
    targetName: '',
    metaData: null
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGoals();

    let dataToDisplay = allData?.filter((x) => x?.goalname?.toLowerCase()?.includes(goalSDG?.toLowerCase()));


    let filteredData = dataToDisplay[0]?.target?.flatMap((x) =>
      x?.ius.map((ius) => {
        const { targetname, targetid } = x; // Destructure targetname and targetid from x
        return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
      })
    )
      ?.reduce((acc, curr) => {
        if (!acc.some((item) => item.ius._id === curr.ius._id)) {
          acc.push(curr);
        }
        return acc;
      }, []);

    setMetaData(dataToDisplay);
    setIusDisplay(filteredData);
    // console.log("metadataaaaaa-", metaData, "ius state-", iusDisplay, "goalSDG--", goalSDG, "activeeius-",activeIus, 'filteredData--', filteredData)
  }, [location.state, goalSDG])

  //   useEffect(() => {
  //     let dataToDisplay = allData?.filter((x) => x?.goalname?.toLowerCase()?.includes(goalSDG?.toLowerCase()));


  //     let filteredData = dataToDisplay[0]?.target?.flatMap((x) =>
  //       x?.ius.map((ius) => {
  //         const { targetname, targetid } = x; // Destructure targetname and targetid from x
  //         return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
  //       })
  //     )
  //       ?.reduce((acc, curr) => {
  //         if (!acc.some((item) => item.ius._id === curr.ius._id)) {
  //           acc.push(curr);
  //         }
  //         return acc;
  //       }, []);


  //     // let searchData = filteredData?.filter((ius) =>
  //     //   ius?.ius?.name?.toLowerCase()?.includes(goalSDG?.toLowerCase())
  //     // );

  //     setMetaData(dataToDisplay);
  //     setIusDisplay(filteredData);
  // console.log("metadataaaaaa-", metaData, "ius state-", iusDisplay, "goalSDG--", goalSDG, "activeeius-",activeIus)
  //   }, [location.state, goalSDG])

  const getGoals = async () => {
    setLoading(true);
    await adminService.httpGet('/data-retrieval/generic/get-goal-ius').then((res) => {
      if (res) {
        let data = res?.data;
        // let filteredData = data?.filter((x)=> x?.goalname?.toLowerCase()?.includes(goalSDG?.toLowerCase()));

        let displayData = data?.filter((x) => x?.goalname?.toLowerCase()?.includes(goalSDG?.toLowerCase()));

        setAllData(data)


        let data1 = displayData[0]?.target?.flatMap((x) =>
          x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
        );

        // let dataToGo = allData?.filter((x) =>
        //   x?.goalname?.toLowerCase()?.includes(displayData[0]?.goalname?.toLowerCase())
        // );

        let filteredData = displayData
          ?.flatMap((item) =>
            item?.target?.flatMap((x) =>
              x?.ius.map((ius) => {
                const { targetname, targetid } = x; // Destructure targetname and targetid from x
                return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
              })
            )
          )
          ?.reduce((acc, curr) => {
            if (!acc.some((item) => item?.ius?._id === curr?.ius?._id)) {
              acc.push(curr);
            }
            return acc;
          }, []);

        // let searchData = filteredData?.filter((ius) =>
        //   ius?.ius?.name?.toLowerCase()?.includes(key)
        // );


        if (data1) {
          console.log("data hai consoled ok-", data1)
          setIusDisplay(filteredData);
          setMetaData(displayData);
          setActiveIus({
            iusName: data1[0]?.name,
            id: data1[0]?._id,
            targetId: data1[0]?.targetId,
            targetName: data1[0]?.targetName,
            metaData: data1[0]?.metadata[0]
          })
        } else {
          setIusDisplay(filteredData);
          setMetaData(displayData);
          setActiveIus({
            iusName: '',
            id: '',
            targetId: '',
            targetName: '',
            metaData: ''
          })

        }

      }
    })


    setLoading(false);

  }

  const handleSDGChange = (e) => {
    let value = e.target.value;
    let dataToDisplay = allData?.filter((x) => x?.goalname?.toLowerCase()?.includes(value?.toLowerCase()));




    setGoalSDG(value);
    setMetaData(dataToDisplay);
    console.log("metadata pp- filteredData", dataToDisplay)
  }

  const selectIus = (iusName, id, targetid, targetname, metadata) => {
    setActiveIus({
      iusName: iusName,
      id: id,
      targetId: targetid,
      targetName: targetname,
      metaData: metadata
    })
  }

  const onSearch = (e) => {
    let key = e.target.value.toLowerCase();

    let dataToDisplay = allData?.filter((x) =>
      x?.goalname?.toLowerCase()?.includes(goalSDG?.toLowerCase())
    );

    let filteredData = dataToDisplay[0]?.target?.flatMap((x) =>
      x?.ius.map((ius) => {
        const { targetname, targetid } = x; // Destructure targetname and targetid from x
        return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
      })
    )
      ?.reduce((acc, curr) => {
        if (!acc.some((item) => item.ius._id === curr.ius._id)) {
          acc.push(curr);
        }
        return acc;
      }, []);


    let searchData = filteredData?.filter((ius) =>
      ius?.ius?.name?.toLowerCase()?.includes(key)
    );
    console.log("sssdd ius", searchData)

    setIusDisplay(searchData)

  }

  const selectOnClick = () => {
    $('body').toggleClass('sdg_list');
  }
  // useEffect(() => {
  //   $('.MuiBackdrop-root, .MuiMenu-list li.MuiButtonBase-root').click(function () {
  //     setTimeout(() => {
  //       $('body').removeClass('sdg_list');
  //     }, 2000);      
  //   })
  // });
  return (
    <>
      <div className={loading ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>
      <div className="modal-body d-flex justify-content-between dasboard_popup_pages metadata_area p-0">
        <div className="col-md-3 box-shadow">
          <div className="fixed_autocomplete">
            {/* <FormControl>
                  <Select
                    value=''
                    onChange={this.handleGoalSDG}
                    id="combo-box-demo"
                    sx={{ width: 300 }}
                  >
                    <MenuItem value={'Goal 1:'}>No Poverty</MenuItem>
                    <MenuItem value={'Goal 2:'}>Zero Hunger</MenuItem>
                    <MenuItem value={'Goal 3:'}>Good Health and Well Being</MenuItem>
                    <MenuItem value={'Goal 4:'}>Quality Education</MenuItem>
                    <MenuItem value={'Goal 5:'}>Gender Equality</MenuItem>
                    <MenuItem value={'Goal 6:'}>Clean Water and Sanitation</MenuItem>
                    <MenuItem value={'Goal 7:'}>Affordable and Clean Energy</MenuItem>
                    <MenuItem value={'Goal 8:'}>Decent Work and Economic Growth</MenuItem>
                    <MenuItem value={'Goal 9:'}>Industry Innovation and Infrastructure</MenuItem>
                    <MenuItem value={'Goal 10:'}>Reduced Inequalities</MenuItem>
                    <MenuItem value={'Goal 11:'}>Sustainable Cities and Communities</MenuItem>
                    <MenuItem value={'Goal 12:'}>Responsible Consumption and Production</MenuItem>
                    <MenuItem value={'Goal 13:'}>Climate Action</MenuItem>
                    <MenuItem value={'Goal 14:'}>Life Below Water</MenuItem>
                    <MenuItem value={'Goal 15:'}>Life Of Land</MenuItem>
                    <MenuItem value={'Goal 16:'}>Peace, Justice and Strong Institution</MenuItem>
                    <MenuItem value={'Goal 17:'}>Partnerships for the Goals</MenuItem>
                  </Select>
                </FormControl> */}


            <FormControl fullWidth>
              <Select
                className="sgd-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={goalSDG}
                // onMouseDown={()=> {this.classChange()}}
                onChange={(e) => { handleSDGChange(e) }}
                onOpen={(e)=> selectOnClick()}
              >
                <MenuItem value={'Goal 1:'}><img src="assets/lib/images/icons/no_Poverty.svg" /></MenuItem>
                <MenuItem value={'Goal 2:'}><img src="assets/lib/images/icons/zero_hunger.svg" /></MenuItem>
                <MenuItem value={'Goal 3:'}><img src="assets/lib/images/icons/good_health_and_well_being.svg" /></MenuItem>
                <MenuItem value={'Goal 4:'}><img src="assets/lib/images/icons/quality_education.svg" /></MenuItem>
                <MenuItem value={'Goal 5:'}><img src="assets/lib/images/icons/gender_equality.svg" /></MenuItem>
                <MenuItem value={'Goal 6:'}><img src="assets/lib/images/icons/clean_water_and_senitation.svg" /></MenuItem>
                <MenuItem value={'Goal 7:'}><img src="assets/lib/images/icons/affordable_and_clean_energy.svg" /></MenuItem>
                <MenuItem value={'Goal 8:'}><img src="assets/lib/images/icons/decent_work_and_economic_growth.svg" /></MenuItem>
                <MenuItem value={'Goal 9:'}><img src="assets/lib/images/icons/industry_innovation_and_infrastructure.svg" /></MenuItem>
                <MenuItem value={'Goal 10:'}><img src="assets/lib/images/icons/reduced_inequalities.svg" /></MenuItem>
                <MenuItem value={'Goal 11:'}><img src="assets/lib/images/icons/sustainable_consumption_and_production.svg" /></MenuItem>
                <MenuItem value={'Goal 12:'}><img src="assets/lib/images/icons/responsible_consumption_and_production.svg" /></MenuItem>
                <MenuItem value={'Goal 13:'}><img src="assets/lib/images/icons/climate_action.svg" /></MenuItem>
                <MenuItem value={'Goal 14:'}><img src="assets/lib/images/icons/life_below_water.svg" /></MenuItem>
                <MenuItem value={'Goal 15:'}><img src="assets/lib/images/icons/life_on_land.svg" /></MenuItem>
                <MenuItem value={'Goal 16:'}><img src="assets/lib/images/icons/peace_justice_and_strong_institutions.svg" /></MenuItem>
                <MenuItem value={'Goal 17:'}><img src="assets/lib/images/icons/partnerships_for_the_goals.svg" /></MenuItem>

              </Select>
            </FormControl>

          </div>
          <div className="main_sdgs_area">
            <div className="sdgs_series d-flex align-item-center">
              <div className="series_content">
                <h3>{metaData[0]?.goalname}</h3>
                <div className="meta_search">
                  <div className="inner_meta_search w-100">
                    <TextField
                      id="metadata_search"
                      type="search"
                      onChange={(e) => { onSearch(e) }}
                      variant="outlined"
                      placeholder="Search"
                    />
                  </div>
                  <div className="indicator_values">
                    {iusDisplay?.map((ius, index) => {
                      console.log("iyususus-", ius)
                      return (

                        <p
                          key={ius?.ius?._id}
                          style={{ cursor: 'pointer' }}
                          className={
                            ius?.ius?._id === activeIus.id ||
                              (!activeIus.id && index === 0)
                              ? 'active_values'
                              : ''
                          }
                          onClick={() =>
                            selectIus(
                              ius?.ius?.name,
                              ius?.ius?._id,
                              ius?.targetid,
                              ius?.targetname,
                              ius?.ius?.metadata?.[ius?.ius?.metadata?.length - 1]
                            )
                          }
                        >
                          {ius?.ius?.name}
                        </p>
                      )
                    })}
                    {/* <p>Indicator Names</p> */}
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 pr-0 target_box">

          <div className="targets_section ">
            <div className="target_area">
              <h2 className="target_head">Indicator</h2>
              <p className="target_dis">{activeIus?.iusName}</p>
            </div>

            <div className="target_area">
              <h2 className="target_head">Goal</h2>
              <p className="target_dis">{metaData[0]?.goalname}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Target</h2>
              <p className="target_dis">{activeIus?.targetName}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Indicator Definition</h2>
              <p className="target_dis">{activeIus?.metaData?.mt1?.en}</p>
            </div>

            <div className="target_area">
              <h2 className="target_head">Method of Computation</h2>
              <p className="target_dis">{activeIus?.metaData?.mt2?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Overview</h2>
              <p className="target_dis">{activeIus?.metaData?.mt3?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Comments and Limitations</h2>
              <p className="target_dis">{activeIus?.metaData?.mt4?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Data Collection for Global Monitoring</h2>
              <p className="target_dis">{activeIus?.metaData?.mt5?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Obtaining Data</h2>
              <p className="target_dis">{activeIus?.metaData?.mt6?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Data Availability</h2>
              <p className="target_dis">{activeIus?.metaData?.mt7?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Treatment of Missing Values</h2>
              <p className="target_dis">{activeIus?.metaData?.mt8?.en}</p>
            </div>
            <div className="target_area">
              <h2 className="target_head">Regional and Global Estimates</h2>
              <p className="target_dis">{activeIus?.metaData?.mt9?.en}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MetadataPage