import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
// import moment from "moment";
import * as constants from '../../../Config/app.constants';
import "./generatereport.css";
import Dropdown from "react-bootstrap/Dropdown";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import DropdownSelect from './DropdownSelect';
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import CheckboxTree from "react-checkbox-tree";
// import Checkbox from "@mui/material/Checkbox";
import AdminService from "../../../services/admin.service";
import { useNavigate } from "react-router";

const ReportSettings = (props) => {
  const [state, setState] = useState({
    isEdit: false,
    disabledApply: false,
    disabledDownload: true,
    editModeId: null,
    loader: false,
    disabled: true,
    fromDt: "",
    fromTo: "",
    areaData: [],
    filteredData: [],
    areaList: [],
    data: [],
  });


  const [sourceOptions, setSourceOptions] = useState([]);
  const [selectDsource, setSelectDsource] = useState([]);
  const [tpOptions, setTpOptions] = useState([]);
  const [selectTP, setSelectTP] = useState([]);
  const [goalOptions ,setGoalOptions] = useState([]);
  const [selectGoals, setSelectGoals] = useState([]);
  const [callData, setParentAreaData] = useState([]);
  const [areaExpand, setAreaExpanded] = useState([]);
  const [areaChecked, setAreaChecked] = useState([]);
  const [goalsToSend, setGoalsToSend] = useState([]);
  const [sourceToSend, setSourceToSend] = useState([]);
  const [tpToSend,setTpToSend] = useState([]);
  const [areaIpvalue,setAreaIpvalue] = useState();

  const [loader, setLoader] = useState(false);

  const setLevelData = (flat) => {
    var roots = [];

    var all = {};

    flat.forEach(function (item) {
      all[item.value] = item;
    });

    Object.keys(all).forEach(function (value) {
      var item = all[value];

      if (item.parent == null || item.parent === "") {
        roots.push(item);
      } else if (item.parent in all) {
        var p = all[item.parent];

        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });

    return roots;
  };

  const getAreaList = (e) => {
    setAreaChecked(e);
    var dataArr = [];

    e?.forEach((item) => {
      props.areaTree?.forEach((dt) => {
        if (dt.area_code == item) {
          dataArr.push({ code: dt.area_code, name: dt.name });
        }
      });
    });

    setState({ ...state, areaList: dataArr });
  };

  const areaPlaceholder = {
    color: '#515151',
    fontSize: '14px',
  };

  useEffect(() => {
    getGoal();
    getArea();
    getDataSource();
    getTimePeriod();
  
    AdminService.checkToken();
  }, []);

  useEffect(() => {
    
   let data = state.areaData?.filter((x)=> areaChecked.includes(x.area_code) )
   let arr =[];
   data.forEach((element) => {
    arr.push(element.name);
  });
   setAreaIpvalue(arr)

  }, [areaChecked]);

  // const navigate = useNavigate();

  // -----------------select Formcontrol end----------------------------------------->


  const onChangeGoal = async (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...goalOptions];
      let arr = [];
      setSelectGoals(stp);
       for(let item of stp){
        arr.push(item?.value)
       }
       let newArr = arr.filter((x)=> x != "*")
       setGoalsToSend(newArr);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      setSelectGoals([]);
      setGoalsToSend([]);
    } else if (event.action === "deselect-option") {
      setSelectGoals(value.filter((o) => o.value !== event.option.value && o.value !== "*"));
      setGoalsToSend();
    } else if (value.length === goalOptions.length) {
      setSelectGoals([{ label: "All", value: "*" }, ...goalOptions]);
      // setGoalsToSend();
    } else {
      setSelectGoals([...selectGoals, event.option]);
      const selectedValue = event.option?.value;
      const valuesArray = selectGoals.map(item => item.value);
      valuesArray.push(selectedValue);
      setGoalsToSend(valuesArray);
    }
  };

  const onChangeSource = async (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...sourceOptions];
      let arr =[];
      setSelectDsource(stp);
      for(let item of stp){
        arr.push(item?.label)
      }
      let newArr = arr.filter((x)=> x != "*")
      setSourceToSend(newArr);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      setSelectDsource([]);
      setSourceToSend([])
    } else if (event.action === "deselect-option") {
      setSelectDsource(value.filter((o) => o.value !== event.option.value && o.value !== "*"));
    } else if (value.length === sourceOptions.length) {
      setSelectDsource([{ label: "All", value: "*" }, ...sourceOptions]);
    } else {

      setSelectDsource([...selectDsource, event.option]);
      const selectedValue = event.option?.label;
      const valuesArray = selectDsource.map(item => item.label);
      valuesArray.push(selectedValue);
      setSourceToSend(valuesArray);


    }
  };
  

  const onChangeTP = async (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...tpOptions];
      setSelectTP(stp);
      let arr = [];
      for(let item of stp){
        arr.push(item.label)
      }
      let newArr = arr.filter((x)=> x != "*")
      setTpToSend(newArr);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      setSelectTP([]);
    } else if (event.action === "deselect-option") {
      setSelectTP(value.filter((o) => o.value !== event.option.value && o.value !== "*"));
    } else if (value.length === tpOptions.length) {
      setSelectTP([{ label: "All", value: "*" }, ...tpOptions]);
    } else {
      setSelectTP([...selectTP, event.option]);
      const selectedValue = event.option?.label;
      const valuesArray = selectTP.map(item => item.label);
      valuesArray.push(selectedValue);
      setTpToSend(valuesArray);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    const [showPlaceholder, setShowPlaceholder] = useState(true);
  
    const handleToggleClick = (event) => {
      setShowPlaceholder(false);
      onClick(event);
    };
  
    return (
      <div ref={ref} onClick={handleToggleClick}>
        {showPlaceholder ? <span className="placeholder">Select an option</span> : children}
      </div>
    );
  });


  const getGoal = async () => {
    setLoader(true);
    AdminService.httpGet("/data-retrieval/generic/get-data/goal")
      .then((res) => {
        if (res) {
          let arr = [];
          let d = res.data;

          d.forEach((element) => {
            arr.push({ label: element.goalname, value: element._id });
          });

          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

          arr = arr.sort((a,b)=> collator.compare(a.label, b.label));

          setGoalOptions([{ label: "All", value: "*" }, ...arr]);
          setLoader(false);
        }
        
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getArea = async () => {
    AdminService.httpGet("/data-retrieval/area/getAreaData")
      .then((res) => {
        if (res) {
          let data = res.data;

          setState({ ...state, areaData: data });
          let flatLevel = [];
          data?.forEach((item) => {
            var obj = {
              value: item.area_code,
              label: item.name,
              parent: item?.parent_id,
              level: item.level,
            };
            flatLevel.push(obj);
          });

          const data1 = setLevelData(flatLevel);

          setParentAreaData(data1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getDataSource = async () => {
    setLoader(true);
    AdminService.httpGet("/data-retrieval/generic/get-data/datasource")
      .then((res) => {
        if (res) {
          let arr = [];
          let d = res.data;

          d.forEach((element) => {
            arr.push({ label: element.datasource, value: element._id });
          });

          console.log("response", arr);

          setSourceOptions([{ label: "All", value: "*" }, ...arr]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getTimePeriod = async () => {
    setLoader(true);
   
    AdminService.httpGet("/data-retrieval/generic/get-data/time")
      .then((res) => {
        if (res) {
          let arr = [];
          let d = res.data;

          d.forEach((element) => {
            arr.push({ label: element.timeperiod, value: element._id });
          });

          setTpOptions([{ label: "All", value: "*" }, ...arr]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };


 
  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
      return `All selected`;
    } else if (value && Array.isArray(value) && value.length !== 0) {
      return `${value.length} selected`;
    } else if (value?.length === 0) {
      return `${placeholderButtonLabel}`;
    } else {
      return `1 selected`;
    }
  };



  const [selectedOption, setSelectedOption] = useState('goal');

 
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate('/admin/generate-report', { state: {columnsNew: checkboxValues, params: state} })

    const dataToBeSend = {
      groupby: selectedOption,
      area :areaChecked,
      goal: goalsToSend,
      source: sourceToSend,
      timeperiod: tpToSend
    };

   console.log("dataToBeSend",dataToBeSend)

    setLoader(true);

    AdminService.httpPost("/data-retrieval/data/get-data-by-period", dataToBeSend)

      .then((res) => {
        if (res) {
        
          const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
  const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
  const todaysDate = String(currentYear) + String(currentMonth) + String(currentDate);
        
            let d = res;
            if(d?.status == 'success'){
              setLoader(false);
            }
    
    
            let filepath = constants.logFilePath + d.filename;
    
            console.log("filepath:" + filepath);
            // let filename = d.filepath;
            let filename = d.filename.replace(/\.[^/.]+$/, "");
    
    
            console.log(1)
    
            let a = document.createElement("a");
            a.href = filepath;
            a.download = filename + todaysDate + '.xlsx';
            console.log(444445)
            
            // alert("data")
            // setTimeout(() => {
              
    
    
              
    
            // }, 1000);
            console.log(a);
            document.body.appendChild(a);
            setTimeout(() => {
              console.log(3)
              
              a.click();
              
    
              console.log(4)
              
    
            }, 1000);
            
            document.body.removeChild(a);
    
    
    
          

          // let arr = [];
          // let d = res.data;

          // d.forEach((element) => {
          //   arr.push({ value: element._id });
          // });
          // setLoader(false);
          
        }
        
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

  
  };

  return (
    <>

          {loader == true && (
                  <div
                    className={
                      loader == true ? "loader text-center " : "loader text-center hide"
                    }
                  >
                    <img
                      src="assets/images/Comp-2.gif"
                      style={{ marginTop: "12%", width: "30%" }}
                    />
                  </div>
                )}


      <div className="main generate-report">
        <div className="report-bg">
          <div className="row">
            <div className="col-md-12">
              <p>Report Settings</p>
            </div>
          </div>
        </div>
        <div className="reportSettingsSection">
          <div className="row">
            <div className="col-md-12 user-name-btn">
              <div className="row">
                <div className="col-md-12">
                  <h3>Group By</h3>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Criteria</label>
                    <button className="reportSettingsSection user-name-btn form-group button">
                    <select  className="selecC" value={selectedOption} onChange={handleDropdownChange}>
                      <option value="goal">Goal</option>
                      <option value="area">Area</option>
                      <option value="timeperiod">Timeperiod</option>
                    </select>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 user-name-btn">
              <div className="row">
                <div className="col-md-12">
                  <h3>Filter By</h3>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Goal</label>
                    <ReactMultiSelectCheckboxes
                      className=""
                      options={goalOptions}
                      required
                      placeholderButtonLabel="Select Goal"
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      value={selectGoals}
                      id="selectGoals"
                      hideSelectedOptions={false}
                      onChange={onChangeGoal}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group inputform_style ifs_dropdown multiselectArea checkboxRemove same_tree_css">
                    <label>Area</label>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="white" id="dropdown-basic" title="Select an Area">
                       
                        {areaIpvalue?.length > 0 &&
                            areaIpvalue?.slice(0, 1)?.map((d, i) => {
                              
                              return (
                                <>
                                  <span className="badge">{d} </span>
                                 
                                </>
                              );
                            })}
                           {areaChecked?.length > 1 ? (
                            <>
                              <span>{areaChecked?.length - 1}+</span>
                            </>
                          ) : (
                            ""
                          )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="treeviewCheckbox">
                        <CheckboxTree
                          nodes={callData}
                          checkModel="all"
                          checked={areaChecked}
                          expanded={areaExpand}
                          onCheck={getAreaList}
                          onExpand={setAreaExpanded}
                        />
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic" title="Select an Area">
                         
                          {areaIpvalue?.length === 0 && areaChecked?.length === 0 ? (
                            <span style={areaPlaceholder}>Select an Area</span>
                          ) : (
                            <>
                              {areaIpvalue?.length > 0 &&
                                areaIpvalue?.slice(0, 1)?.map((d, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <span className="badge">{d}</span>
                                    </React.Fragment>
                                  );
                                })}
                              {areaChecked?.length > 1 ? (
                                <React.Fragment>
                                  <span>{areaChecked?.length - 1}+</span>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="treeviewCheckbox">
                          <CheckboxTree
                            nodes={callData}
                            checkModel="all"
                            checked={areaChecked}
                            expanded={areaExpand}
                            onCheck={getAreaList}
                            onExpand={setAreaExpanded}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Time Period</label>
                    <ReactMultiSelectCheckboxes
                      className=""
                      options={tpOptions}
                      required
                      placeholderButtonLabel="Select Time Period"
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      value={selectTP}
                      id="selectTP"
                      hideSelectedOptions={false}
                      onChange={onChangeTP}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Data Source</label>
                   <ReactMultiSelectCheckboxes
                      className=""
                      options={sourceOptions}
                      required
                      placeholderButtonLabel="Select Source"
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      value={selectDsource}
                      id="selectSource"
                      hideSelectedOptions={false}
                      onChange={onChangeSource}
                    />
                 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button
                className="apply_btn"
                disabled={state.disabledApply}
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSettings;
