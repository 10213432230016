import React, { useRef, useState } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import adminService from "../../../../services/admin.service";

const DsdMsdModal = (props) => {
    const [dsdFile,setDsdFile] = useState([])
    const [msdFile,setMsdFile] = useState([])
    const [dsdId,setDsdId] = useState('')
    const [dsdVersion,setDsdVersion] = useState('')
    const [agencyId,setAgencyId] = useState('')
    const [publisher,setPublisher] = useState('')
    const [msdId,setMsdId] = useState('')
    const [msdVersion,setMsdVersion] = useState('')

    const fileToSend = new FormData();

    const form = useRef()

    const handleChange = (e,type)=>{
        if(type === 'dsd'){
            setDsdFile([e.target.files[0]])
        }else{
            setMsdFile([e.target.files[0]])
        }
    }

    console.log('file',dsdFile[0],msdFile[0])

    const handleSubmit = ()=>{
        fileToSend.append('dsdfile', dsdFile[0]);
        fileToSend.append('msdfile', msdFile[0]);
        fileToSend.append('dsd_id', dsdId);
        fileToSend.append('dsd_version', dsdVersion);
        fileToSend.append('agency_id', agencyId);
        fileToSend.append('publisher', publisher);
        fileToSend.append('msd_id', msdId);
        fileToSend.append('msd_version', msdVersion);
        console.log('file',fileToSend)
        adminService.httpPostFile('/data-sdmx/sdmx/simple-file-upload', fileToSend).then((res)=>{
            console.log('mapping',res)
        })
    }
    return (
        <>
            <div className="area-form">
                <ValidatorForm className="main_react_form w-100" ref={form} >
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0">
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">DSD ID</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdID"
                                                // validators={['required']}
                                                value={dsdId}
                                                errorMessages={['This field is required']}
                                                onChange={(e)=> setDsdId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">DSD Version</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdVersion"
                                                // validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={dsdVersion}
                                                onChange={(e)=> setDsdVersion(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Agency ID</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="agencyID"
                                                // validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={agencyId}
                                                onChange={(e)=> setAgencyId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Publisher</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="publisher"
                                                // validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={publisher}
                                                onChange={(e)=> setPublisher(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">MSD ID</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdID"
                                                // validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={msdId}
                                                onChange={(e)=> setMsdId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">MSD Version</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                // validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={msdVersion}
                                                onChange={(e)=> setMsdVersion(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">DSD</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                type="file"
                                                onChange={(e)=> handleChange(e,'dsd')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">MSD</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                type="file"
                                                onChange={(e)=> handleChange(e,'msd')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" type="submit" className="addbtn" onClick={handleSubmit}>
                                Add <img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg"/>
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>
        </>
    );

};

export default DsdMsdModal;