import { Component } from "react";
import "./topic-subtopic.css";
import * as jsx from "./topic-subtopic.module.jsx";
import * as $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import adminService from "../../../services/admin.service";
import * as constants from '../../../Config/app.constants';

class TopicSubtopic extends Component {

  constructor() {
    super();
    this.state = {
      filteredData: [],
      searchText: "",
      modal: false,
      data: [],
      isEdit: false,
      editModeId: null,
      parentCallback: [],
      loader: false,
      bulkDelete: [],
      subTopicData:[],
      sectorSubsectorList:null,
    };
  }

  onSearch = (val) => {
    this.setState({ searchText: val});
    let d = this.state.data;
    console.log(d,"datavalue Send")
    let fd=null;
    if(val === '') {
        fd = d;
    } else {
        fd = d.filter(
            item => ((item.topicdescription && item.topicdescription.toLowerCase().includes(val.toLowerCase()))
            || (item.topicname && item.topicname.toLowerCase().includes(val.toLowerCase()))
        ));
    }
    this.setState({ filteredData : fd});
}

sortWithToolTip = (rowA, rowB) => {
  const a = rowA?.indicator;
  const b = rowB?.unit;

  if (a > b) {
      return 1;
  }

  if (b > a) {
      return -1;
  }

  return 0;
};

  openAddModal = () => {
    if (this.state.modal === false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null });
    }
  };


  columns = [
    // {
    //   name: "#",
    //   selector: (row,ind) => ind+1,
    //   sortable: true,
    // },

    {
      name: 'Sector Name',
      selector: row => row?.topicname,
      sortable: true,
    },
    {
      name: 'Sector Description',
      selector: row => row?.topicdescription,
      sortable: true,
    },

    {
      name: "Sub-Sector",
      selector: (row) => (
        <>
          {row?.subtopic?.length > 0 &&
            <>
              <span>{row?.subtopic?.length} <img className="side_arrow" alt="side-arrow" src='assets/lib/images/side_arrow.svg' data-toggle="modal" data-target="#subtopic_topic" onClick={(e) => this.openIncPopup(e, row._id)} /></span>
            </>
          }
        </>
      ),
    },

    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div className='action_btns'>
            <button onClick={(e) => this.editMode(e, row._id)}> <img src="assets/lib/images/icon/edit_icon.svg" alt="edit-icon" /></button>
            <button onClick={(e) => { this.removeRow(e, row._id); }}> <img src="assets/lib/images/icon/delete_icon.svg" alt="delete-icon" /></button>
          </div>

        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  //==========add and edit api user=========///
  componentDidMount() {
    this.getData();
    adminService.checkToken();
}

render() {
    return jsx.default.bind(this)();
}

callBack = () => {
    this.setState({ 
        modal: false,
        isEdit:false,
        editModeId:null});
    this.forceUpdate();
    this.getData();
    
}

openModal = () =>{
    if(this.state.modal===false){
        this.setState({ modal: true})
    }else{
        this.setState({ modal: false,
        isEdit:false,
        editModeId:null});
    }
    this.forceUpdate();
}

openIncPopup (event, id) {
    event.preventDefault();
    
    this.state.data.forEach((item)=>{
        if(item._id===id){
            this.setState({ subTopicData:item});
            this.forceUpdate();
            return;
        }
    });
}

editMode (event, id) {
    event.preventDefault();
    
    this.state.data.forEach((item)=>{
        if(item._id===id){
            this.setState({isEdit:true, editModeId:item, modal: true});
            this.forceUpdate();
            return;
        }
    });
}

removeRow(event, id) {
    event.preventDefault();
    
    swal({
        title:"Are you sure you want to delete?",
        text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if(willDelete) {
            let data = { "id" : id};
            const req = adminService.httpPost('/data-import/generic/delete/topic', data).then((res)=> {
                if(res.status !== true) {
                    toast.error(res.message);
                } else {
                    this.getData();
                    toast.success(res.message);
                }
              
            }).catch(err => {
              toast.error("Error in deleting sectors-subsectors");
            });
          }                
        });            
}

getData = () => {
    this.setState({ loader: true, searchText: "" });
    
    fetch(constants.server+"api/data-retrieval/generic/get-data/topic", { 
        method: 'get', 
        headers: new Headers({
            'Authorization': this.props.token, 
            'Content-Type': 'application/json'
            }),
        body: null
        })
        .then((res) => res.json())
        .then((data) => {
            let d = data?.data;
            d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({
                loader: false,
                data: d,
                filteredData: d
            })  
        })
        .catch((err) => {
            this.setState({ loader: false });
            toast.error(err.message);
        });        
}

handleChange = ({ selectedRows }) => {
this.setState({bulkDelete:selectedRows})
};

handleChangeData = ({ toggleCleared }) => {
this.setState({bulkDelete:toggleCleared})

};

//////////=========checkbox delete============/////////

deleteBulkUser = (e) =>{
let ids = [];

this.state.bulkDelete.forEach(item=>{
ids.push(item._id)
})

if(ids?.length > 0){

        swal({
            title:"Are you sure you want to delete?",
            text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if(willDelete) {
                const data= {
                    "id": ids.toString()
                }
                adminService.httpPost('/data-import/generic/delete/topic', data).then((res) => {
    
                    if (res) {
                        this.setState({ loader: false })
                        let d = res;
            
                        console.log(res)
            
                        if(res.status === true){
                            toast.success(res.message);

                        console.log(res.message,"new mondal-see")
                            this.getData();
                        }
                        else{
                            toast.error(res.message);
            
                        }
                   
                    }
            
                }).catch((err) => {
                    this.setState({ loader: false })
                    console.log(err)
                });
              }                
            });     
        }
    
        else{
            swal({
                title:"No Record Selected",
                text: "Please select at a record to delete.",
                  icon: "warning",
                  button: true,
                })
        
                return false;
        }
    }            


}


export default TopicSubtopic;
