import React, { Component } from 'react';
import * as jsx from './wheel.module.jsx';
import './wheel.css';
import * as $ from 'jquery';

class Wheel extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        // this.props = this.props;
        this.state = {
            wheelColor: ['#e5243b', '#DDA63A', '#4C9F38', '#C5192D', '#FF3A21', '#26BDE2', '#FCC30B', '#A21942', '#FD6925', '#DD1367', '#FD9D24', '#BF8B2E', '#3F7E44', '#0A97D9', '#56C02B', '#00689D', '#19486A',],
            activeColor: 0,
           
        }
    }
        
    render() {
        return jsx.default.bind(this)();
    }

    // Core
    // initCarousel = ( options ) => {
    //         this.node        = options.node;
    //         this.node.slider = this;
    //         this.slides      = this.node.querySelector( '.slides' ).children;
    //         this.slidesN     = this.slides.length;
    //         this.pagination  = this.node.querySelector( '.pagination' );
    //         this.pagTransf   = 'translate( -50%, -50% )';
    //         this.dots        = this.pagination.children;
    //         this.dotsN       = this.dots.length;
    //         this.step        = -360/this.dotsN;
    //         this.angle       = 0;
    //         this.next        = this.node.querySelector( '.next' );
    //         this.prev        = this.node.querySelector( '.prev' );
    //         this.activeN     = options.activeN || 0;
    //         this.prevN       = this.activeN;
    //         this.speed       = options.speed || 300;
    //         this.autoplay    = options.autoplay || false;
    //         this.autoplayId  = null;

    //         this.setSlide( this.activeN );
    //         this.arrangeDots();
    //         this.pagination.style.transitionDuration = this.speed +'ms';
    //         if ( this.autoplay ) this.startAutoplay();
        
        
    //     this.addListeners();
    //     // return new CustomCarousel( options );
    // }

    initCarousel = ( ) => {
        this.node        = this.myRef.current;
        // this.node.slider = this;
        this.slides      = this.node.querySelector( '.slides' ).children;
        this.slidesN     = this.slides.length;
        this.pagination  = this.node.querySelector( '.pagination' );
        this.pagTransf   = '';
        this.dots        = this.pagination.children;
        this.dotsN       = this.dots.length;
        this.step        = -360/this.dotsN;
        this.angle       = 0;
        this.next        = this.node.querySelector( '.next' );
        this.prev        = this.node.querySelector( '.prev' );
        this.activeN     = this.props.activeN || 0;
        this.prevN       = this.activeN;
        this.speed       = this.node.dataset.speed || 300;
        this.autoplay    = !this.props.isOverview && this.node.dataset.autoplay || false;
        this.autoplayId  = null;

        this.setSlide( this.activeN );
        this.arrangeDots();
        this.pagination.style.transitionDuration = this.speed +'ms';
        if ( this.autoplay ) this.startAutoplay();
    
    
    this.addListeners();
    // return new CustomCarousel( options );
}

// componentWillReceiveProps = (newProps) => {
//     this.initCarousel();
// }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.activeN !== this.props.activeN) {
            // this.initCarousel();
            this.setSlide( this.props.activeN );
        }        
    }


    addListeners = () => {
        var slider = this;

        if ( this.next ) {
            this.next.addEventListener( 'click', function() {
                slider.setSlide( slider.activeN + 1 );
            });
        }

        if ( this.prev ) {
            this.prev.addEventListener( 'click', function() {
                slider.setSlide( slider.activeN - 1 );
            });
        }

        for ( var i = 0; i < this.dots.length; i++ ) {		
            this.dots[i].addEventListener( 'click', function( i ) {
                return function() { slider.setSlide( i ); }
            }( i ));
        }

        if ( this.autoplay ) {
            this.node.addEventListener( 'mouseenter', function() {
                slider.stopAutoplay();
            });

            this.node.addEventListener( 'mouseleave', function() {
                slider.startAutoplay();
            });
        }
    };

    setSlide = function ( slideN ) {
        if(!this.props.isOverview && !this.props.play) {
            return;
        }
        this.slides[ this.activeN ].classList.remove( 'active' );
        if ( this.dots[ this.activeN ] ) this.dots[ this.activeN ].classList.remove( 'active' );

        this.prevN = this.activeN;
        this.activeN = slideN;
        if ( this.activeN < 0 ) this.activeN = this.slidesN -1;
        else if ( this.activeN >= this.slidesN ) this.activeN = 0;

        this.slides[ this.activeN ].classList.toggle( 'active' );	
        if (this.dots[this.activeN]) {
            this.dots[this.activeN].classList.toggle('active');

            
        }

        this.rotate();
        this.props.wheelCallback({
            goal: 'Goal '+(this.activeN+1)+':',
            outerSVG: 'assets/lib/images/new-home/sdg'+(this.activeN+1)+'.svg',
            innerSVG: 'assets/lib/images/innerSDG'+(this.activeN+1)+'.svg'
        })
        this.setState({ activeColor: this.activeN })
        this.props.sendActiveIndex(this.activeN)
        // $('.circle').removeClass('active');
        // $(`.circle.c${this.activeN+1}`).addClass('active');
        if(this.activeN == 1) {
            let els = document.querySelectorAll('.circle.c2');
            // .array.forEach(element => {
            //     element.addClass('active');
            // });
            // $('.circle.c2').addClass('active');
        }
    };

    rotate = function () {
        if ( this.activeN < this.dotsN ) {
            this.angle += function ( dots, next, prev, step ) {
                var inc, half = dots/2;
                if( prev > dots ) prev = dots - 1;
                if( Math.abs( inc = next - prev ) <= half ) return step * inc;
                if( Math.abs( inc = next - prev + dots ) <= half ) return step * inc;
                if( Math.abs( inc = next - prev - dots ) <= half ) return step * inc;
            }( this.dotsN, this.activeN, this.prevN, this.step )

            this.pagination.style.transform = this.pagTransf +'rotate('+ this.angle +'deg)';
        }
    };

    startAutoplay = function () {
        var slider = this;

        this.autoplayId = setInterval( function(){
            slider.setSlide( slider.activeN + 1 );
        }, this.autoplay );
    };

    stopAutoplay = function () {
        clearInterval( this.autoplayId );
    };

    arrangeDots = function () {
        for ( var i = 0; i < this.dotsN; i++ ) {
            this.dots[i].style.transform = 'rotate('+ 360/this.dotsN * i +'deg)';
        }
    };


    // Init
    componentDidMount() {
        // $('.circle-carousel').each(() => {
        //     this.initCarousel({
        //         node: $(this),
        //         speed: $(this).attr( 'data-speed' ),
        //         autoplay: $(this).attr( 'data-autoplay' )
        //     });
        // });
        this.initCarousel();
        // this.init();

    }

    // init = () => {
    //     this.el = document.getElementById('Layer_1');

    //     this.elDisplay = this.el.children[0];
    //     this.elInteraction = this.el.children[1];

    //     // this.offsetRad = 0;
    //     // this.targetRad = 0;
    //     // this.previousRad = 0;


    //     try {
    //         this.elDisplay.addEventListener('mousedown', this.down);
    //     }
    //     catch (err) {
    //         console.log("Interaction not found");
    //     }

    // }
    //             down = (event) => {
    //                 console.log("Sudhanshu in down");
    //                 // this.offsetRad = this.getRotation(event);
    //                 // this.previousRad = this.offsetRad;
    //                 window.addEventListener('mousemove', this.move);
    //                 window.addEventListener('mouseup', this.up);
    //             }

    //             move = (event) => {
    //                 // console.log("Sudhanshu in move");
    //                 // let newRad = this.getRotation(event);
    //                 // console.log("Sudhanshu in move newRad previousRad", newRad, this.previousRad);
    //                 // this.targetRad += (newRad - this.previousRad);
    //                 // console.log("Sudhanshu in move newRad previousRad", newRad, this.targetRad);
    //                 // this.previousRad = newRad;
    //                 this.elDisplay.style.transform = 'rotate(' + 30 + 'deg)';
    //             }

    //             up = () => {
    //                 console.log("Sudhanshu in up");
    //                 window.removeEventListener('mousemove', this.move);
    //                 window.removeEventListener('mouseup', this.up);
    //             }

    //             getRotation = (event) => {
    //                 console.log("Sudhanshu in getRotation");
    //                 var pos = this.mousePos(event, this.elDisplay);
    //                 var x = pos.x - this.elDisplay.clientWidth * .5;
    //                 var y = pos.y - this.elDisplay.clientHeight * .5;
    //                 console.log("Sudhanshu in getRotation", x, y);
    //                 return Math.atan2(y, x);
    //             }

    //             mousePos = (event, currentElement) => {
    //                 console.log("Sudhanshu in mousePos");
    //                 let totalOffsetX = 0;
    //                 let totalOffsetY = 0;
    //                 let canvasX = 0;
    //                 let canvasY = 0;

    //                 do {
    //                     totalOffsetX += currentElement.offsetLeft - currentElement.scrollLeft;
    //                     totalOffsetY += currentElement.offsetTop - currentElement.scrollTop;
    //                 }
    //                 while (currentElement = currentElement.offsetParent)

    //                 canvasX = event.pageX - totalOffsetX;
    //                 canvasY = event.pageY - totalOffsetY;

    //                 return {
    //                     x: canvasX,
    //                     y: canvasY
    //                 };
    //             }
    
}

export default Wheel;