import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const ExportProgressComponent = (props) => {
    console.log('progress',props)
    return (
        <>
        <div className="export-box">
            <div className="row">
                <div className="col-md-3">
                    <label>Data</label>
                </div>
                <div className="col-md-8">
                    <ProgressBar now={props?.progress?.dataProgress} />        
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label>Metadata</label>
                </div>
                <div className="col-md-8">
                    <ProgressBar now={props?.progress?.metaDataProgress} />        
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label>Data Structures Definition (DSD)	</label>
                </div>
                <div className="col-md-8">
                    <ProgressBar now={props?.progress?.dsdProgress} />        
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label>Metadata Structure Definition (MSD)	</label>
                </div>
                <div className="col-md-8">
                    <ProgressBar now={props?.progress?.msdProgress} />        
                </div>
            </div>
            <label className="mb-3">Code List</label>
            <div className=""> 
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Indicator</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.indicatorProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Unit</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.unitProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Subgroup</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.subgroupProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Subgroup Dimension</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.subgroupDimensionProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Area</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.areaProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Source</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.sourceProgress} />        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label class="pl-4">Time Period</label>
                    </div>
                    <div className="col-md-8">
                        <ProgressBar now={props?.progress?.timeperiodProgress} />        
                    </div>
                </div>
            </div>

        </div>
        
        </>
    );

};

export default ExportProgressComponent;