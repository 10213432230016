import React, { Component } from "react";
import "./data_source.css";
import * as jsx from "./data_source_module";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

import AdminService from "../../../services/admin.service";
import Tooltip from "@mui/material/Tooltip";

class DataSources extends Component {
  arr = ["dxgf"];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      isEdit: false,
      editModeId: null,
      modal: false,
      loader: false,
      dataLimit: 10,
      dataStatus: 2,
      bulkDelete: [],
      editEntry: [],
    };
  }

  componentDidMount() {
    this.getData();
    AdminService.checkToken();
  }

  render() {
    return jsx.default.bind(this)();
  }

  // filter table and get data====================//

  onSearch = (val) => {
    this.setState({ searchText: val });
    let backupData = this.state.data;
    let d = this.state.filteredData;
    let fd = null;
    if (val?.length > 0) {
      fd = d.filter(
        (item) => item?.datasource && item?.datasource.toLowerCase().includes(val.toLowerCase())
      );
      this.setState({ filteredData: fd });
    } else {
      this.setState({ filteredData: backupData });
    }
  }
  

  sortWithToolTip = (rowA, rowB) => {
    const a = rowA?.datasource;
    const b = rowB?.datasource;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  columns = [
    // {
    //     name: '#',
    //     selector: (row, index) => isNaN(row.index) ? `${index + 1}` : row.index + 1,

    //     sortable: false,
    //     width: "50px"
    // },
    {
        name: 'Source Name',
        selector: row => <Tooltip title={row?.datasource} placement="top">
            <p>{row?.datasource}</p>
        </Tooltip>,
        sortable: true,
        sortFunction: this.sortWithToolTip
    },
    
 
    {
        name: 'Action',
        cell: row =>
            <>
                <div className='action_btns'>
                   <button  onClick={(e) => this.editDataEntry(row._id)}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                   <button  onClick={(e) => this.deleteEntry(row._id)}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
            </div>

            </>,
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
    },
];

  editDataEntry = (id) => {
    const edData = [];
    this.state.filteredData.forEach((item) => {
      if (item._id == id) {
        edData.push(item);
        return;
      }
    });

    this.setState({ editEntry: edData, isEdit: true });
    this.openAddModal();
  };

  getData = async () => {
    this.setState({ loader: true, searchText: "" });

    AdminService.httpGet("/data-retrieval/generic/get-data/datasource")
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res?.data;
          d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          this.setState({ filteredData: d, data: d });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  // save data===========================//

  saveData = async (e) => {
    if (e.id != null) {
      this.updateEntry(e);
    } else {
      this.addNewEntry(e);
    }
  };

  updateEntry = async (e) => {
    const dataToBe2 = {
      datasource: e.data_source,
    };

    AdminService.httpPut(
      "/data-import/generic/update/datasource/" + e.id,
      dataToBe2
    )
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;

          console.log(res);
          if (res.success == true) {
            toast.success("Data Source updated successfully");
            this.openAddModal();
            this.getData();
            this.setState({ isEdit: false });

            this.setState({ editEntry: [] });
          } else {
            toast.error(res.message);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  // add new entry=============================///



  addNewEntry = async (e) => {
    console.log("dataToBeSend", e);
    this.setState({ loader: true });
    const dataToBeSend = {
      datasource: e.data_source,
      
    };
   

    AdminService.httpPost("/data-import/generic/add/datasource", dataToBeSend)
      .then((res) => {
        if (res) {
          this.setState({ loader: false });

          console.log(res);

          if (res.status == 200) {
            toast.success("Data Source added successfully");
            this.openAddModal();
            this.getData();
          } else {
            toast.error(res.message);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  deleteEntry = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      // this.setState({ loader: true });
      if (willDelete) {
        const dataToBeSend = {
          id: id,
        };

        AdminService.httpPost(
          "/data-import/generic/delete/datasource",
          dataToBeSend
        )
          .then((res) => {
            if (res) {
              this.setState({ loader: false });

              if (res.status == true) {
                toast.success("Data Source deleted successfully");
                this.getData();
              } else {
                toast.error(res.message);
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };


  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
      this.setState({ isEdit: false });

      this.setState({ editEntry: [] });
    }
  };

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////


  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id);
    });

    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            id: ids.toString(),
          };
          AdminService.httpPost("/data-import/generic/delete/datasource", data)
            .then((res) => {
              if (res) {
                this.setState({ loader: false });
                let d = res;

                console.log(res);

                if (res.status == true) {
                  toast.success("Data Source deleted successfully");
                  this.getData();
                } else {
                  toast.error("Failed to delete Data Source");
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });

      return false;
    }
  };
}

export default DataSources;
