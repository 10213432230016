import React, { useState, useEffect, useMemo } from "react";
import "./generatereport.css";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import AdminService from "../../../services/admin.service";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";

import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useLocation } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const Export = ({ onExport, disabledDownload }) => (
  <Button
    variant="contained"
    className="download cancelbtn"
    disabled={disabledDownload}
    onClick={onExport}
  >
    Download
  </Button>
);

const GenerateReport = (props) => {
  const location = useLocation();
  const [colState, setColState] = useState(location?.state?.columnsNew);

  const [state, setState] = useState({
    data: [],
    searchText: "",
    setScrollableModal: false,
    isEdit: false,
    disabledApply: true,
    disabledDownload: true,
    disaaprovest: false,
    publishModal: false,
    disscrollableModal: false,
    editModeId: null,
    selectedUsers: [],
    modal: false,
    defOptions: [],
    remarkdata: "",
    loader: false,
    dataLimit: 10,
    disabled: true,
    dataStatus: 2,
    indicatorData: [],
    fromDt:
      location?.state?.params?.fromDt?.$d !== undefined
        ? dayjs(location?.state?.params.fromDt?.$d)
        : "",
    fromTo:
      location?.state?.params?.fromTo?.$d !== undefined
        ? dayjs(location?.state?.params.fromTo?.$d)
        : "",
    selectedUsers: location?.state?.params?.selectedUsers || [],
    filteredData: location?.state?.params?.filteredData
      ? location?.state?.params?.filteredData
      : "",
  });



  const handleSelectAllCol = (event) => {
    const { checked } = event.target;
    setColState((prevState) => ({
      ...prevState,
      area_code: checked,
      area_name: checked,
      time_period: checked,
      source: checked,
      indicator: checked,
      unit: checked,
      subgroup: checked,
      value: checked,
      createdBy: checked,
      createdAt: checked,
    }));
  };

  const handleCheckboxChangeCol = (event) => {
    const { name, checked } = event.target;
    setColState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    getUser();
    AdminService.checkToken();
    getData(state.fromDt, state.fromTo, state.selectedUsers);
  }, [props, location.state]);

  // ------------------------>

  const getUser = async () => {
    setState({ ...state, loader: true });

    // setState({...state, loader: false });

    AdminService.httpGet("/data-retrieval/users/getUsersForReport")
      .then((res) => {
        if (res) {
          let arr = [];
          let d = res.data.records;

          d.forEach((element) => {
            arr.push({ label: element.name, value: element._id });
          });

          setState({ ...state, defOptions: arr, loader: false });
        }
      })
      .catch((err) => {
        setState({ ...state, loader: false });
        console.log(err);
      });
  };

  const onChange = async (value, event) => {
    setState({
      ...state,
      disabledApply: false,
      disabledDownload: true,
    });

    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...state.defOptions];
      setState({
        ...state,
        selectedUsers: stp,
      });
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setState({
        ...state,
        selectedUsers: [],
      });
    } else if (event.action === "deselect-option") {
      setState({
        ...state,
        selectedUsers: value.filter(
          (o) => o.value !== event.option.value && o.value !== "*"
        ),
      });
    } else if (value.length === state.defOptions.length) {
      setState({
        ...state,
        selectedUsers: [{ label: "All", value: "*" }, ...state.defOptions],
      });
    } else {
      setState({
        ...state,
        selectedUsers: [...state.selectedUsers, event.option],
      });
    }
  };

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All selected`;
    } else if (value && Array.isArray(value) && value?.length !== 0) {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    } else if (value?.length === 0) {
      return `${placeholderButtonLabel}`;
    } else {
      return `${placeholderButtonLabel}: 1 selected`;
    }
  };

  const dropdownButtonLabel = getDropdownButtonLabel({
    placeholderButtonLabel: "Dropdown",
    value: state.selectedUsers,
  });

  const handleDate = async (newValue) => {
    setState({
      ...state,
      disabledApply: false,
      disabledDownload: true,
      fromDt: newValue,
    });
  };

  const handleDate1 = async (newValue) => {
    setState({
      ...state,
      disabledApply: false,
      disabledDownload: true,
      fromTo: newValue,
    });
  };

  const onSearch = (val) => {
    setState({
      ...state,
      searchText: val,
      filteredData:
        val === ""
          ? state.data
          : state.data.filter(
              (item) =>
                (item?.area_name &&
                  item?.area_name["en"]
                    .toLowerCase()
                    .includes(val.toLowerCase())) ||
                (item?.source &&
                  item?.source.toLowerCase().includes(val.toLowerCase())) ||
                (item?.value &&
                  item?.value.toLowerCase().includes(val.toLowerCase())) ||
                (item?.time_period &&
                  item?.time_period
                    .toLowerCase()
                    .includes(val.toLowerCase())) ||
                (item?.indicator &&
                  item?.indicator["en"]
                    .toLowerCase()
                    .includes(val.toLowerCase())) ||
                (item?.unit &&
                  item?.unit["en"].toLowerCase().includes(val.toLowerCase())) ||
                (item?.subgroup &&
                  item?.subgroup["en"]
                    .toLowerCase()
                    .includes(val.toLowerCase()))
            ),
    });
  };

  
  const columns = useMemo(
    () => [
      {
        name: "#",
        selector: (row, index) => row.index + 1,
        sortable: false,
        width: "50px",
      },
      {
        name: "Area ID",
        selector: (row) => row?.area_code,
        sortable: false,
        width: "100px",
        omit: !colState?.area_code,
        
      },

      {
        name: "Area Name",
        selector: (row) => row?.area_name?.en,
        sortable: true,
        width: "170px",
        omit: !colState?.area_name,
       
      },
      {
        name: "Time Period",
        selector: (row) => row.time_period,
        sortable: true,
        width: "170px",
        omit: !colState?.time_period,
        
      },
      {
        name: "Source",
        selector: (row) => (
          <Tooltip title={row?.source} placement="top">
            <p>{row?.source}</p>
          </Tooltip>
        ),
        sortable: true,
        minWidth: "250px",
        omit: !colState?.source,
       
      },

      {
        name: "Indicator",
        selector: (row) => row?.indicator?.en,
        sortable: true,
        width: "200px",
        omit: !colState?.indicator,
        
      },
      {
        name: "Unit",
        selector: (row) => row?.unit?.en,
        sortable: true,
        width: "120px",
        omit: !colState?.unit,
        
      },

      {
        name: "Subgroup",
        selector: (row) => row?.subgroup?.en,
        sortable: true,
        width: "120px",
        omit: !colState?.subgroup,
        
      },
      {
        name: "Data Value",
        selector: (row) => row?.value,
        sortable: true,
        width: "120px",
        omit: !colState?.value,
       
      },

      {
        name: "Created By",
        selector: (row) => row?.createdBy,
        sortable: true,
        width: "120px",
        omit: !colState?.createdBy,
        
      },
      {
        name: "Created At",
        selector: (row) => row?.createdAt,
        sortable: true,
        width: "120px",
        omit: !colState?.createdAt,
       
      },

      {
        name: "Status",
        selector: (row) => row?.status,
        sortable: true,
        width: "120px",
        
      },
    ],
    [colState]
  );



  // --------------------Get User Data------------------------------------->

  const getData = async (dateFrom, dateTo, user) => {
    let userData = [];
    user = user.filter((x) => x.value !== "*");
    user.forEach((element) => {
      userData.push(element.value);
    });

    const dataToBeSend = {
      fromDate: dayjs(dateFrom).format("YYYY-MM-DDTHH:mm:ss[.000Z]"),
      toDate: dayjs(dateTo).format("YYYY-MM-DDTHH:mm:ss[.000Z]"),
      users: userData,
    };

    setState({ ...state, loader: true });

    try {
      const res = await AdminService.httpPost(
        "/data-retrieval/data/get-data-by-period",
        dataToBeSend
      );
      if (res) {
        // setDisabled(false);
        let d = res.data;
        d.forEach((ele, index) => {
          ele.index = index;
        });
        let disabledApply = false;
        let disabledDownload = false;
        if (d.length > 0) {
          disabledApply = true;
          disabledDownload = false;
        } else {
          disabledApply = false;
          disabledDownload = true;
        }
        setState({
          ...state,
          loader: false,
          disabled: false,
          filteredData: d,
          data: d,
          disabledApply: disabledApply,
          disabledDownload: disabledDownload,
        });
      }
    } catch (err) {
      setState({ ...state, loader: false });
      console.log(err);
    }
  };

  const downloadCSV = ({ data, fileType }) => {
    if (!data) {
      return;
    }
    const headersForAllData = [
      { label: "Area Code", key: "area_code" },
      { label: "Area Name", key: "area_name" },
      { label: "Source", key: "source" },
      { label: "Indicator", key: "indicator" },
      { label: "Unit", key: "unit" },
      { label: "Subgroup", key: "subgroup" },
      { label: "Time Period", key: "time_period" },
      { label: "Data Value", key: "value" },
      { label: "Status", key: "status" },
      { label: "Footnote", key: "footnote" },
    ];

    const headers = headersForAllData
      .map((header) => `"${header.label}"`)
      .join(",");

    const csv = data.reduce((acc, row) => {
      const rowValues = headersForAllData
        .map((header) => {
          const key = header.key;
          let value = "";
          if (
            key === "area_name" ||
            key === "indicator" ||
            key === "subgroup" ||
            key === "unit"
          ) {
            value = row[key] && row[key]?.en ? `"${row[key]?.en}"` : "";
          } else {
            value = row[key] ? `"${row[key]}"` : "";
          }
          return value;
        })
        .join(",");
      return acc + rowValues + "\r\n";
    }, headers + "\r\n");

    const blob = new Blob([csv], { type: fileType });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    const a = document.createElement("a");
    a.download = `GenerateReport_${year}_${month}_${day}`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const downloadCSVFromJson = () => {
    const { filteredData } = state;
    const fileType = "text/csv";
    downloadCSV({ data: filteredData, fileType: fileType });
  };

  const sendStatus = (e) => {
    state.dataStatus = e;
    getData(e);
  };

  return (
    <>
      <div className="main dataApprove generatereports" id="main">
        <div className="container-fluid areaCont">
          <section
            className="areaSection data_approve gr_hsBox"
            id="areaSection"
          >
            <div className="col-lg-12 col-md-12">
              <div className="row header-div new-h-d pt-0 pl-0">
                <div className="col-md-12 pl-2 position-relative gr_hs">
                  <div class="">
                    <subHeaderComponentMemo.default
                      parentCallBack={sendStatus}
                      onSearch={onSearch}
                      searchText={state.searchText}
                      value="40"
                      checkData={state.bulkDelete}
                      nRows={state.filteredData?.length}
                    />
                  </div>

                  <div className="grf_form d-flex">
                    <div className="grf_list">
                      <div className="grf_form_date align-self-center">
                        Select Date
                      </div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              value={state.fromDt}
                              id="dateFrom"
                              maxDate={moment().toDate()}
                              onChange={handleDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              id="dateTo"
                              required
                              value={state.fromTo}
                              maxDate={moment().toDate()}
                              onChange={handleDate1}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div className="grf_suser">
                        <ReactMultiSelectCheckboxes
                          options={
                            state.defOptions
                              ? [
                                  { label: "All", value: "*" },
                                  ...state.defOptions,
                                ]
                              : []
                          }
                          required
                          placeholderButtonLabel="Select Users"
                          getDropdownButtonLabel={getDropdownButtonLabel}
                          value={state.selectedUsers}
                          id="selectUser"
                          hideSelectedOptions={false}
                          onChange={onChange}
                        />
                      </div>
                      <div className="grfBtn">
                        <Button
                          variant="contained"
                          className="apply"
                          disabled={state.disabledApply}
                          onClick={(e) => {
                            getData(
                              state.fromDt,
                              state.fromTo,
                              state.selectedUsers
                            );
                          }}
                        >
                          Apply
                        </Button>

                        <Export
                          disabledDownload={state.disabledDownload}
                          onExport={() => downloadCSVFromJson()}
                        />

                        <div class="dropdown filter_area">
                          <button
                            className="filter_drop"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src="assets/lib/images/new-home/filter.svg" />
                          </button>
                          <div class="dropdown-menu">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={Object.values(
                                    colState
                                  ).every(Boolean)}
                                  indeterminate={
                                    Object.values(colState).some(
                                      Boolean
                                    ) &&
                                    !Object.values(colState).every(
                                      Boolean
                                    )
                                  }
                                  onChange={handleSelectAllCol}
                                />
                              }
                              label="Select All"
                            />
                         
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.area_code}
                                  name="area_code"
                                  onChange={handleCheckboxChangeCol}
                                />
                              }
                              label="Area ID"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.area_name}
                                  name="area_name"
                                  onChange={handleCheckboxChangeCol}
                                />
                              }
                              label="Area Name"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.time_period}
                                  name="time_period"
                                  onChange={handleCheckboxChangeCol}
                                />
                              }
                              label="Time Period"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.source}
                                  name="source"
                                  onChange={handleCheckboxChangeCol}
                                />
                              }
                              label="Source"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.indicator}
                                  name="indicator"
                                  onChange={handleCheckboxChangeCol}
                                />
                              }
                              label="Indicator"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={colState.unit} />
                              }
                              name="unit"
                              onChange={handleCheckboxChangeCol}
                              label="Unit"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.subgroup}
                                />
                              }
                              name="subgroup"
                              onChange={handleCheckboxChangeCol}
                              label="Subgroup"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={colState.value} />
                              }
                              name="value"
                              onChange={handleCheckboxChangeCol}
                              label="Data Value"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.createdBy}
                                />
                              }
                              name="createdBy"
                              onChange={handleCheckboxChangeCol}
                              label="Created By"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={colState.createdAt}
                                />
                              }
                              name="createdAt"
                              onChange={handleCheckboxChangeCol}
                              label="Created At"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GeneratereportModal />               */}
            <DataTable
              // actions={actionsMemo}
              columns={columns}
              data={state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              noDataComponent="No Data"
              persistTableHead
              className="dataTableScroll"
            />
          </section>
        </div>
      </div>

      <div
        className={
          state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

      <div className={state.modal ? "overlay dblock" : "overlay"}></div>
    </>
  );
};

export default GenerateReport;
