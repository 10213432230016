import React,  {useState,useEffect} from "react";
// ======================================================= Dropdown End
import Dropdown from 'react-bootstrap/Dropdown';
// ======================================================= Dropdown End
// ======================================================= Form Field Start
    import TextField from '@mui/material/TextField';
    import Checkbox from '@mui/material/Checkbox';
    import Button from '@mui/material/Button';
    import FormControlLabel from '@mui/material/FormControlLabel';
// ======================================================= Form Field End
// ======================================================= Tab Start
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { grid } from "@mui/system";
import AdminService from "../../../../services/admin.service";
import { dataentryfile, resourceImagePath } from "../../../../Config/app.constants";

// ======================================================= Tab End
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
 function ViewDataentry(props) {
    // console.log(props,"surya");
// ======================================================= Dropdown Start
console.log('propss',props)
const[loader,setLoader] = useState(false)
const [age, setAge] = React.useState('');
const [gridAnswers,setGridAnswers]=React.useState([])
const [orderedDataEntryQustions, setOrderedDataEntryQuestions] = useState('')

  const handleChange2 = (event) => {
    console.log('handleChange2',event.target.value);
    // let household = event.target.value
    let areaObj = props.data.area_info.filter(x=> x._id == event.target.value )[0]
    props.getData(null,areaObj, event.target.value)
    setAge(event.target.value);
  };

const [value, setValue] = useState(1);

const handleChange = (event, newValue) => {
  setValue(newValue);
};
useEffect(()=>{
    setLoader(true)
    console.log(props);
    // setAge('10')
    // setArea(x)
    setAnswers({})
    if(props.selectedArea!=undefined && props.data!=undefined ){
        // setDisasters(props.data.disaster_info[0])
        // setAge(props.selectedArea._id)
       console.log('propsselectedArea',props.selectedArea)
        setAge(props.selectedArea._id)
        setArea(props.data.area_info[0]?.name?.en)
    }
    if(props.surveyRecord!=null && props.surveyRecord!=null ){
        let answers = props.surveyRecord.question_answer
        

        Object.values(props.dataEntryQuestion).forEach(section=>{
            section.forEach(question=>{
                if(question.type!='grid'){
                    let answer = answers?.filter(a=> a.question_id==question._id)??[] 
                    // console.log(question._id, answer)
                    if(answer.length>0){
                        let multiString = answer[0]?.options.map(field => field.selected_option.en).join(', ');
                        console.log("question.type", question.type, multiString)
                        // saveAnswers('id'+question._id,'answer', multiString)
                        // saveAnswers('id'+question._id,'answer',question.type=='single_select'?answer[0].options[0].option_id : answer[0].options[0].ans)

                        // saveAnswers('id'+question._id,'answer', question.type == "single_select" && answer[0].options[0].ans , question.type == "multi_select"  && answer[0].options[0].ans ,  multiString)
                        saveAnswers('id'+question._id,'answer', question.type == "multi_select" ? multiString : answer[0].options[0].ans )

                        saveAnswers('id'+question._id,'timeFormat',answer[0].options[0].time_period_format)
                        saveAnswers('id'+question._id,'timePeriod',answer[0].options[0].time_period)
                    }
                }else if(question.type=='grid'){ 
                    let answer = answers?.filter(a=> a.question_id==question._id)??[] 
                    // console.log("question.type", question.type)
                    // console.log('grid',question._id, answer)
                    // console.log('grid',answer)
                    if(answer.length>0){
                        saveGridQuestion(question._id,answer[0].options)
                        // saveGridQuestion('id'+question._id, ''+answer[0].options[0].grid_row + answer[0].options[0].grid_col,answer[0].options[0].ans )
                        saveAnswers('id'+question._id,'timeFormat',answer[0].options[0].time_period_format)
                        saveAnswers('id'+question._id,'timePeriod',answer[0].options[0].time_period)
                    }
                }
            })
        })
        
        setLatitude(props.surveyRecord.lat);
        setLongitude(props.surveyRecord.long);
        setUsername(props.surveyRecord?.userData?.name)
        console.log("dd1",props.surveyRecord);
        console.log("dd", props.dataEntryQuestion);
       
    } else {
        setLatitude("");
        setLongitude("");
        setUsername("")
        // props.surveyRecord = {}
    }
    setLoader(false)
   },[props])

   useEffect(()=>{
    if(props.data!=null){
        setArea(props.data.area_info[0]?.name?.en)
    }
   
   },[props.data])
   useEffect(()=>{
    let dataEntryQuestion = props.dataEntryQuestion
    let dataEnteryOptions = props.dataEnteryOptions
    if(props.dataEnteryOptions && props.dataEntryQuestion && props.dataentryFormData ){
        setFormState({ 
            'questions' : dataEntryQuestion,
            'options' :dataEnteryOptions
        })
    }
   },[props.dataEnteryOptions,props.dataEntryQuestion])
   const [activeQuestion,setActiveQuestion] = useState('a')
   const [longitude,setLongitude] = useState()
   const [latitude,setLatitude] = useState()
   const [username, setUsername] = useState()
   const [area, setArea] = useState({});
   const [modalViewType,setModalViewType] = useState({})

       const [answers,setAnswers] = useState({})
       const [FormState, setFormState] = useState(
        {
          'id': '',
          'formName':'',
          'questions': [],
          'options': []
        });

       

       const saveAnswers = (id,type,value)=>{
        // if(id === '6467650e03a7e55931515a3e'){
        //     console.log('id,type,value',id,'6467650e03a7e55931515a3e',type,value);
        // }
        console.log('saveanswerss',id,type,value)
        let preData = answers
        if(preData.hasOwnProperty(id)){
            preData[id][type]=value
        }else{
            preData[id]= {[type]:value,...preData[id]}
        }
        setAnswers({...preData})
        // console.log(answers);
       }

       const saveGridQuestion=(id,options)=>{
        console.log('options',id,options)
        let ans ={}
        options.map((option)=>{
            // console.log('op',option.grid_col + option.grid_row ,':', option.ans)
           let key = option.grid_row + option.grid_col
           ans[key] = option.ans
        })
        console.log('ans',ans)
        saveAnswers('id'+id,'answer',ans)
       }

       console.log('gridAnswers',gridAnswers)

    //    const saveGridQuestion = (id,cell,value)=>{
    //     let i=0

    //     console.log('saveGridQuestion',id,cell,value)
    //         let preValue = answers
    //         if(preValue.hasOwnProperty(id)){
    //             let savedGrids =  preValue[id].answer
    //             if(savedGrids!=undefined){
    //                 preValue[id].answer = {
    //                     ...preValue[id].answer,
    //                     [cell]:value
    //                 }
    //             }else{
    //                 preValue[id].answer[cell] = value
    //             }
    //         }else{
    //             preValue[id] = {
    //                 answer:{
    //                     [cell]:value
    //                 }
    //             }
    //         }
    //         setAnswers({...preValue})
    //         console.log(answers);
    //    }

       console.log('answers',answers)

     const  handlePrevious = (id) => {

        props.viewData(id)


    }

    const day = new Date(props.data?.createdAt);
    const m = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const currentDate = day.getDate() + ' ' + m[day.getMonth()] + ' ' + day.getFullYear();

    //Print Function call
    const handlePrint = () => {
        window.print();
      };

// ======================================================= Dropdown End

    return (
        <>
            <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
                    <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
            </div>
            <div class="modal fade timePeriodPopup dataentryModal dev_modal" id="dataentryview" tabindex="-1" role="dialog" aria-labelledby="dataentryCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <div class="summary_head">
                            <h5 class="modal-title" id="dataentryTitle">Data Entry | <span>{props.dataentryFormData?.name}</span></h5>
                            <p  class="date mb-0 p-0">Created Date ( {currentDate} )</p>
                        </div>  
                        <div className="completion_status">
                            <div className="cs_head">
                                <h4>Completion Status</h4>
                                <span className="csh_count">{ props.surveyRecord?.question_answer.length??'00'} /{Object.values(FormState.questions).reduce((acc,arr) => acc.concat(arr), [] ).length }</span>
                            </div>    
                            <div className="cs_print" onClick={handlePrint}>
                                <img src="assets/lib/images/home/print.svg" />
                                <h4>Download / Print</h4>
                            </div>
                        </div>                      
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body viewModal p-0">
                        <div className="rightTab_form ">
                            <Box sx={{ borderBottom: 1 }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={<div className='label-text tabsection1' ><div data-dismiss="modal" data-toggle="modal" data-target="#viewModal" className="rtf_num">1</div><div className="rtf_text">Setup</div></div>}  {...a11yProps(0)}/>
                                    <Tab label={<div className='label-text tabsection1'><div className="rtf_num">2</div><div className="rtf_text">Data Entry</div></div>}  {...a11yProps(1)}/>
                                </Tabs>
                            </Box>
                        </div>  
                        {                                               
}
                        <div className="viewModal_body">
                            <div className="row align-items-center mb-30 justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        {/* <label>Area { age?.name?.en }</label> */}
                                        <div className="mat-form-field-wrapper">
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={(e) => handleChange2(e)}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {
                                                props?.data?.household.length == 0 ?

                                                    props.data!=null ? props.data.area_info.map(x=>{
                                                        console.log('areamenu',x)
                                                        return <MenuItem value={x._id}>{x.name.en}</MenuItem>
                                                    }) :''
                                                :

                                                props.data!=null ? props.data.area_info.map(x=>{
                                                    // console.log('areamenu',x)
                                                    return (
                                                        props?.data?.household.map((h)=>{
                                                            // console.log('menu',h,h._id , x.area_code,h._id == x.area_code)
                                                             if(h._id == x.area_code){
                                                                return(
                                                                    h.household.map((menu)=>{
                                                                        console.log('menu',x.area_code+menu,props.selectedArea)
                                                                        return <MenuItem value={x.area_code+'-'+menu}>{x.name.en}-Household {menu}</MenuItem>
                                                                    })
                                                                )
                                                             }
                                                        })
                                                    )
                                                }) :''

                                            }
                                            {/* <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem> */}
                                        </Select>

                                            {/* <Dropdown>
                                                <Dropdown.Toggle>{area?.name?.en?? null}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        props.data!=null?props.data.area_info.map(x=>{
                                                            return <Dropdown.Item  onClick={(e)=>{setArea(x)}}>{x.name.en}</Dropdown.Item>
                                                        }):''
                                                    }
                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>                                     */}
                                        </div>
                                    </div>
                                </div>         
                            </div>
                            <div className="px-70 mb-5 pb-3">
                                <div className="form_type">
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>User Name</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField disabled value={ username }/>                                   
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div class="indicatorUnit pb-3">
                                                <p>Location</p>
                                                <div className="row align-items-end mx-0">
                                                    <div className="col-md-2">
                                                        <div className="longitude cursor-disabled">
                                                            <img src="assets/lib/images/home/pin.svg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Longitude</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled value={longitude} />                                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Latitude</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled value={latitude} />                                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                
                                                <div className="mat-form-field-wrapper">
                                                    <TextField disabled placeholder="COWIN" />                                   
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 inputform_style labelCheckbox">
                                                <FormControlLabel disabled control={<Checkbox />} label="Mandatory Question" />
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div class="indicatorUnit pb-3">
                                                <p>Time Period</p>
                                                <div className="row align-items-end mx-0 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                            <label>Time Period Format</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <Dropdown disabled>
                                                                    <Dropdown.Toggle disabled>YYYY.MM</Dropdown.Toggle>                                                           
                                                                </Dropdown>                                                        
                                                            </div>
                                                        </div>
                                                    </div>  
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                            <label>Time Period</label>
                                                            <div className="mat-form-field-wrapper">
                                                                <TextField disabled placeholder="2023.01" />                                          
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="row align-items-end mx-0">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-0 inputform_style labelCheckbox">
                                                            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Default Time Period" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            {
                            props.dataEntryQuestion!=null?Object.keys(props.dataEntryQuestion).map(section=>{
                                return (
                                    <div className="general_qus">
                                        <div className="row align-items-center mb-30">
                                            <div className="col-md-12">
                                                <h3 className="section_heading">{section}</h3>
                                            </div>
                                        </div>
                                        {
                                            props.dataEntryQuestion[section].map((question,index)=>{
                                                return (
                                                    <>
                                                        <div className="row align-items-center mb-2">
                                                            <div className="col-md-1">
                                                                <h4 title={question.type} >Q{index+1}</h4>
                                                            </div>
                                                            <div className="col-md-11 pl-0">
                                                                <p>{question.title?.en}</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            question.type == "text"?

                                                            // question type text
                                                            <div className="row align-items-center mb-5">
                                                                <div className="col-md-1 pt-3">
                                                                    <h4>Ans</h4>
                                                                </div>
                                                                <div className="col-md-11 pl-0">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Answer</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <TextField disabled onChange={(e)=>{saveAnswers('id'+question._id,'answer',e.target.value)}} value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''}  onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                   
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Time Period Format</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                    
                                                                                </Dropdown>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>   */}
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <label>Time Period</label>
                                                                                
                                                                                <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                    <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Enter Source</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <TextField disabled />                                   
                                                                                </div>
                                                                            </div>
                                                                        </div>   */}
                                                                    </div>
                                                                </div>
                                                            </div>:




                                                            // question type date
                                                            question.type == "date" || question.type == "time" ?

                                                            <div className="row align-items-center mb-5">
                                                                <div className="col-md-1 pt-3">
                                                                    <h4>Ans</h4>
                                                                </div>
                                                                <div className="col-md-11 pl-0">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Answer</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    {/* <TextField disabled type={question.type == "time" ? 'time':'date'}  value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''} /> */}
                                                                                    <TextField disabled value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''} onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Time Period Format</label>
                                                                                <div className="mat-form-field-wrapper ">
                                                                                <Dropdown   >
                                                                                    <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                    
                                                                                </Dropdown>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <label>Time Period</label>
                                                                                
                                                                                <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                    <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Enter Source</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <TextField disabled />                                   
                                                                                </div>
                                                                            </div>
                                                                        </div>   */}
                                                                    </div>
                                                                </div>
                                                            </div>:

                                                


                                                            // question type single-select
                                                            question.type == "single_select"?

                                                            <div className="row align-items-center mb-5">
                                                                <div className="col-md-1 pt-3">
                                                                    <h4>Ans</h4>
                                                                </div>
                                                                <div className="col-md-11 pl-0">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Answer</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <TextField disabled onClick={()=>console.log('answer',answers[`id${question._id}`]['answer'],question._id)} value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''}  />
                                                                                {/* <Dropdown>                                                                                    
                                                                                    <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].answer:null)}>{answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].answer:null}</Dropdown.Toggle>                                                                                    
                                                                                </Dropdown>                                    */}
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Time Period Format</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                    
                                                                                </Dropdown>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <label>Time Period</label>
                                                                                
                                                                                <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                    <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="col-md-3">
                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                <label>Enter Source</label>
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <TextField disabled />                                   
                                                                                </div>
                                                                            </div>
                                                                        </div>   */}
                                                                    </div>
                                                                </div>
                                                            </div>:

                                                            
                                                             // question type multi_select
                                                             question.type == "multi_select"?

                                                             <div className="row align-items-center mb-5">
                                                                 <div className="col-md-1 pt-3">
                                                                     <h4>Ans</h4>
                                                                 </div>
                                                                 <div className="col-md-11 pl-0">
                                                                     <div className="row">
                                                                         <div className="col-md-12">
                                                                             <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                 <label>Answer</label>
                                                                                 <div className="mat-form-field-wrapper">
                                                                                     <TextField disabled value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''}  />
                                                                                 {/* <Dropdown>                                                                                    
                                                                                     <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].answer:null)}>{answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].answer:null}</Dropdown.Toggle>                                                                                    
                                                                                 </Dropdown>                                    */}
                                                                                 </div>
                                                                             </div>
                                                                         </div>  
                                                                         {/* <div className="col-md-3">
                                                                             <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                 <label>Time Period Format</label>
                                                                                 <div className="mat-form-field-wrapper">
                                                                                 <Dropdown>
                                                                                     <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                     
                                                                                 </Dropdown>
                                                                                 
                                                                                 </div>
                                                                             </div>
                                                                         </div>  
                                                                         <div className="col-md-3">
                                                                             <div className="form-group mb-0 inputform_style datePicker">
                                                                                 <label>Time Period</label>
                                                                                 
                                                                                 <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                     <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                                 </div>
                                                                             </div>
                                                                         </div> */}
                                                                         {/* <div className="col-md-3">
                                                                             <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                 <label>Enter Source</label>
                                                                                 <div className="mat-form-field-wrapper">
                                                                                     <TextField disabled />                                   
                                                                                 </div>
                                                                             </div>
                                                                         </div>   */}
                                                                     </div>
                                                                 </div>
                                                             </div>:

                                                            // question type numeric
                                                            question.type == "numeric"?
                                                                <div className="row align-items-center mb-5">
                                                                    <div className="col-md-1 pt-3">
                                                                        <h4>Ans</h4>
                                                                    </div>
                                                                    <div className="col-md-11 pl-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                    <label>Answer</label>
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <TextField disabled onChange={(e)=>{saveAnswers('id'+question._id,'answer',e.target.value)}} value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''} type='number' onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                   
                                                                                    </div>
                                                                                </div>
                                                                            </div>  
                                                                            {/* <div className="col-md-3">
                                                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                    <label>Time Period Format</label>
                                                                                    <div className="mat-form-field-wrapper">
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                        
                                                                                    </Dropdown>
                                                                                    
                                                                                    </div>
                                                                                </div>
                                                                            </div>  
                                                                            <div className="col-md-3">
                                                                                <div className="form-group mb-0 inputform_style datePicker">
                                                                                    <label>Time Period</label>
                                                                                    
                                                                                    <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                        <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="col-md-3">
                                                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                    <label>Enter Source</label>
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <TextField disabled />                                   
                                                                                    </div>
                                                                                </div>
                                                                            </div>   */}
                                                                        </div>
                                                                    </div>
                                                                </div>:


                                                            // question type image & document
                                                            question.type == "image" || question.type == "document"  || question.type == "audio" ?
                                                            <div className="row align-items-center mb-5">
                                                            <div className="col-md-1 pt-3">
                                                                <h4>Ans</h4>
                                                            </div>
                                                            <div className="col-md-11 pl-0">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                            <label>Answer</label>
                                                                            <div className="mat-form-field-wrapper inputFile">
                                                                            {/* type='file' onChange={(e)=>{saveAnswers('id'+question._id,'answer',e.target.value)}} value={answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:''}  accept = {question.type=="image"?question.file.file_format.map(x => `image/${x.toLowerCase()}`).join(' , '):question.file.file_format.map(x => `application/${x.toLowerCase()}`).join(' , ')} */}
                                                                                {/* <input  type='file' disabled   onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/> */}
                                                                                <p className="image-name d-inline-block mt-2 d-flex" >
                                                                                    {answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'-'}
                                                                                    { answers.hasOwnProperty('id'+question._id) ?
                                                                                        <img data-toggle="modal"  data-target="#showImage2" src="assets/lib/images/home/hover_arrow.svg" className="ml-1" style={{cursor: "pointer", width: "12px"}} onClick={()=> {
                                                                                            setModalViewType({
                                                                                                ans: answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'',
                                                                                                type: question.type
                                                                                            })
                                                                                        }}
                                                                                        />
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </p>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>  
                                                                    {/* <div className="col-md-3">
                                                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                            <label>Time Period Format</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                    
                                                                                </Dropdown>
                                                                            
                                                                            </div>
                                                                        </div>
                                                                    </div>  
                                                                    <div className="col-md-3">
                                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                                            <label>Time Period</label>
                                                                            
                                                                            <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                <TextField disabled value={answers['id'+question._id]?.timePeriod??null} id="outlined-basic" onClick={()=> console.log(answers.hasOwnProperty('id'+question._id)?answers[`id${question._id}`]['answer']:'')}/>                                          
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>:

                                                            // question type grid
                                                            
                                                            question.type == 'grid'?

                                                                        <div className="row align-items-center mb-5">
                                                                            <div className="col-md-1 pt-3">
                                                                                <h4>Ans</h4>
                                                                            </div>
                                                                            <div className="col-md-11 pl-0">
                                                                                <div className="row align-items-center membersTable mx-0">
                                                                                    <div className="col-md-2"></div>     
                                                                                        {
                                                                                            Object.values(JSON.parse(question.grid).columns.names).map((c,i)=>{
                                                                                                if(JSON.parse(question.grid).columns.count>i){

                                                                                                    return (
                                                                                                        <div className="col-md-2">
                                                                                                        <h4 className="col-head text-center">{c}</h4>
                                                                                                    </div>         
                                                                                                )
                                                                                            }
                                                                                            })
                                                                                        }
                                                                                </div>
                                                                                <div className="main-row">
                                                                                    <div className="row mb-3 mx-0">
                                                                                        <div className="col-md-2 ">
                                                                                        {
                                                                                            Object.values(JSON.parse(question.grid).rows.names).map((r,i)=>{
                                                                                                if(JSON.parse(question.grid).rows.count>i){

                                                                                                    return (
                                                                                                        <h4>{r}</h4>
                                                                                                        )
                                                                                                    }
                                                                                                    })
                                                                                                }
                                                                                        </div>
                                                                                        {
                                                                                            [0,1,2,3,4].map(c=>{
                                                                                                if(c<JSON.parse(question.grid).columns.count){
                                                                                                    return (
                                                                                                        <div className="col-md-3">
                                                                                                            {
                                                                                                                [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(r=>{
                                                                                                                    if(r<JSON.parse(question.grid).rows.count){
                                                                                                                       return (
                                                                                                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                                                                    <label onClick={()=>{console.log((JSON.parse(question.grid).questions[''+r+c]) ,''+r+c, (JSON.parse(question.grid).questions) ) }} >Answer</label>
                                                                                                                                    <div className="mat-form-field-wrapper">
                                                                                                                                        {
                                                                                                                                            JSON.parse(question.grid).questions[''+r+c].type=="Textbox" || JSON.parse(question.grid).questions[''+r+c].type=="Numeric" || JSON.parse(question.grid).questions[''+r+c].type=="Single Choice"?
                                                                                                                                                <TextField disabled value={answers['id'+question._id]?.answer?.[''+r+c]??null}   onChange={(e)=>{saveGridQuestion('id'+question._id,''+r+c,e.target.value)}}  type={JSON.parse(question.grid).questions[''+r+c].type=="Textbox"?'text':JSON.parse(question.grid).questions[''+r+c].type=="Numeric"?'number':''} />                                   
                                                                                                                                            :
                                                                                                                                            <Dropdown>
                                                                                                                                                <Dropdown.Toggle>{  answers['id'+question._id]?.answer?.[''+r+c]?.name??null}</Dropdown.Toggle>
                                                                                                                                                <Dropdown.Menu>
                                                                                                                                                    {
                                                                                                                                                      JSON.parse(question.grid).questions[''+r+c].singleChoice.selectedOption.map(option=>{
                                                                                                                                                        return (
                                                                                                                                                            <Dropdown.Item  onClick={(e)=>{saveGridQuestion('id'+question._id,''+r+c, option)}}>{option.name}</Dropdown.Item>
                                                                                                                                                        )
                                                                                                                                                      })  
                                                                                                                                                    }
                                                                                                                                                </Dropdown.Menu>
                                                                                                                                            </Dropdown>  
                                                                                                                                            
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                           
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        
                                                                                        {/* <div className="col-md-2">
                                                                                            <div className="form-group mb-0 inputform_style">
                                                                                                <label>Answer</label>
                                                                                                <div className="mat-form-field-wrapper">
                                                                                                    <TextField disabled type="number" />                                   
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                    {/* <div className="row align-items-center mx-0 mb-2">
                                                                                        <div className="col-md-2">
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                                <label>Time Period Formatt  </label>
                                                                                                <div className="mat-form-field-wrapper">
                                                                                                <Dropdown>
                                                                                                    <Dropdown.Toggle>{ answers.hasOwnProperty('id'+question._id)? answers['id'+question._id].timeFormat:null}</Dropdown.Toggle>
                                                                                                    
                                                                                                </Dropdown>                                            
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> 
                                                                                        <div className="col-md-3">
                                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                                <label>Time Period</label>
                                                                                                
                                                                                                <div className="mat-form-field-wrapper"  data-target="#timeperiod1">
                                                                                                    <TextField disabled value={answers['id'+question._id]?.timePeriod??null}  id="outlined-basic" />                                          
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            
                                                                                        </div>                                            
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>:

                                                            null

                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }):null
                        }
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                        {
                            props.alignment === 'In-Progress'? 
                            <div>
                                <Button variant="contained" className="mb-0 text-unset" data-dismiss="modal" data-toggle="modal" data-target="#viewModal" onClick={()=> handlePrevious(props.rowId)}><img src="assets/lib/images/home/arrow_right.svg" className="prev_arrow" /> Previous</Button>
                                <Button variant="contained" className="mb-0 text-unset ml-2" data-dismiss="modal" data-toggle="modal" >Close</Button>
                            </div>
                            : props.alignment === 'Completed' ? <>
                            <Button sx={{marginRight:'4px'}} variant="contained" className="mb-0 text-unset" data-dismiss="modal" data-toggle="modal" data-target="#viewModal"  onClick={()=> handlePrevious(props.rowId)}><img src="assets/lib/images/home/arrow_right.svg" className="prev_arrow" /> Previous</Button> 
                            <Button variant="contained" className="mb-0 text-unset ml-2" data-dismiss="modal" data-toggle="modal" >Close</Button>
                            {/* <Button  variant="contained" className="mb-0 text-unset" data-dismiss="modal" data-toggle="modal" data-target="#datavalidationview"> Next <img src="assets/lib/images/home/arrow_right.svg" /></Button> */}
                            </>
                            :null
                        }
                                      
                                                    
                        </div>                        
                    </div>
                    </div>
                </div>
            </div>


            {/* Model for audio, image */}
            <div class="modal fade showImage2 timePeriodPopup" id="showImage2" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header align-items-center border-0">
                            {/* <div class="summary_head">
                                <h5 class="modal-title" id="dataentryTitle"></h5>
                            </div>                        */}
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="popupImageSize">
                                {/* {
                                    image.type == 'image'?  <img src={`${dataentryfile}${image.ans}`} class="d-block mx-auto"/> : 
                                    <>
                                    <audio controls>
                                        <source src={`${dataentryfile}${audio.ans}`} type="audio/mpeg"/>
                                    </audio>
                                    </> 
                                } */}
                                 {
                                    modalViewType.type == 'audio'?  
                                    <audio controls className="w-100">
                                    <source src={`${dataentryfile}${modalViewType.ans}`} type="audio/mpeg"/>
                                   </audio>: 
                                    <img src={`${dataentryfile}${modalViewType.ans}`} class="d-block mx-auto w-100"/>
                                }
                               
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default  React.memo(ViewDataentry)