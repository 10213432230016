import { Component } from 'react'
import './department.css';
import * as jsx from './department.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import { Tooltip } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';

class Department extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cross:false,
            data: [],
            filteredData: [],
            searchText: '',
            isEdit: false,
            editModeId: null,
            modal: false,
            assignModal: false,
            loader: false,
            allIndicatorList: null,
            allUnitList: null,
            allSubgroupDimensionList: null,
            allDimensionList: null,
            sectorSubsectorList: null,
            bulkDelete: [],
            goalTargetList: null,
            disasterID: null,
            isAssigned: [],
            disasterName: null,
            selectedAssignedData: [],
            getGoalTargetData: [],
            nodalIUS: [],
            viewIUS: [],
            isNodalIUS: false,
            cleanData:false
        }

    }

    isNodalIUS =(boolean)=>{
        console.log("boolean is-", boolean)
        this.setState({isNodalIUS: boolean})
    }

    callBackCheck = (nodal, view)=>{

        console.log('nodal, view', nodal, view)
        this.setState({nodalIUS: nodal, viewIUS: view})
    }

    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => ((item.department_code && item.department_code.toLowerCase().includes(val.toLowerCase()))
                    || (item.department_name && item.department_name.toLowerCase().includes(val.toLowerCase()))
                ));
        }
        this.setState({ filteredData: fd });
    }

    sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.description.en;
        const b = rowB?.description.en;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => index + 1,
        //     sortable: false,
        //     width: '70px'
        // },
        {
            name: 'Department Name',
            selector: row => row.department_name,
            sortable: true,
        },
        {
            name: 'ID',
            selector: row => row.department_code,
            sortable: true,
        },
        // {
        //     name: 'Description',
        //     selector: row =><Tooltip title={row.description?.en} placement='top'><p>{row.description?.en}</p></Tooltip>,
        //     sortable: true,
        //     sortFunction: this.sortWithToolTip
        // },
        {
            name: 'Action',
            width: '150px',
            cell: row =>
                <>
                    {/* <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a onClick={(e) => { this.takeAssignedID(row) }} class="dropdown-item" href="javascript:void(0);" data-toggle="modal" data-target='#assignmodal'>Assign Indicator</a>

                            <a class="dropdown-item" onClick={(e) => this.editMode(e, row._id)} href="javascript:;">Edit</a>
                             <a class="dropdown-item" onClick={(e) => this.download(e, row._id)} href="javascript:;">Download</a> 
                            <a class="dropdown-item" onClick={(e) => { this.deleteEntry(row._id) }} href="javascript:;">Delete</a>
                            <a class="dropdown-item"  onClick={(e)=> this.removeRow(e, row.subgroup_id)} href="javascript:;">Delete</a>
                        </div>
                    </div> */}

                    {/* <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { this.takeAssignedID(row) }} href="javascript:;" data-toggle="modal" data-target='#assignmodal'>Assign Indicator</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.deleteEntry(row._id) }} href="javascript:;">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                        <div className='action_btns'>
                            <button onClick={(e) => this.editMode(e, row._id)} > <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                            <button onClick={(e) => { this.deleteEntry(row._id) }}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                        </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    getGoalTargetIUS = async ()=>{
        await AdminService.httpGet("/data-retrieval/generic/get-goal-ius").then((res)=>{
            console.log("This is res we getting-", res.data)
            let data = res.data;

            this.setState({getGoalTargetData: data})
        })
    }

    componentDidMount() {

        this.getData();
        this.getGoalTargetIUS();
        this.getAllIndicators();
        adminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }

    callBack = () => {
        this.setState({
            modal: false,
            isEdit: false,
            editModeId: null
        });
        this.forceUpdate();
        this.getData();

    }
    /////open edit model function

    editMode(event, row_id) {
        event.preventDefault();
        this.setState({
            isEdit: true,
            modal: true
        });

        this.state.data.forEach((item) => {
            if (item._id == row_id) {
                this.setState({ editModeId: item });
            }
        });

        this.openEditModal()

    }
    
    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({
                modal: false,
                isEdit: false,
                editModeId: null,
                cross:true,
                nodalIUS: [], viewIUS: []
            })

      


        }

    }

    // openModal = () =>{

    //     if(this.state.modal==false){
    //         this.setState({ modal: true})
    //     }else{
    //         this.setState({ modal: false,
    //                         isEdit:false,
    //                         editModeId:null});
    //     }
    //     this.forceUpdate();
    // }

    // editMode (event, id) {
    //     event.preventDefault();

    //     this.state.data.forEach((item)=>{
    //         if(item._id==id){
    //             this.setState({isEdit:true, editModeId:item, modal: true});
    //             this.forceUpdate();
    //             return;
    //         }
    //     });

    //     // this.openAddModal();

    //     // $('#openDataModal').trigger('click');

    // }

    //////////=========checkbox delete============/////////

    // delete entry===========//
    deleteEntry = (id) => {

        swal({
            title: "Are you sure delete this?",
            // text: "Once deleted, you will not be able to recover this disaster!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        "_id": id
                    }

                    adminService.httpPost('/data-import/department/delete-departments', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            if (res.status == true) {
                                toast.success("Department deleted successfully");
                                this.getData();
                            }
                            else {
                                toast.error(res?.message);

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        toast.error(err);
                    });
                }
            });






    }


    ////////////////get api///////////
    getData = async () => {
        this.setState({ loader: true })

        adminService.httpGet('/data-retrieval/department/get-all-departmentlist').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                // d.forEach((ele  , index)=>{ele.index = index })
                this.setState({ filteredData: d, data: d })
            }

        }).catch((err) => {
            this.setState({ loader: false })
        });






    }


    //add and edit api/////

    saveDisasterData = (saveData) => {
        if (this.state.isEdit && saveData.id != null) {
            const data = {
                "id": saveData.id,
                "department_code": saveData.department_code,
                "department_name": saveData.department_name,
                "nodalius": saveData.nodalius,
                "viewius": saveData.viewius,
                // "department_code": saveData.description,
                
            }

            adminService.httpPost('/data-import/department/update-department/' + saveData.id, data).then((res) => {
                if (res) {

                    let d = res.data;
                    if (res.status == 1) {
                        this.setState({ loader: false, cleanData:true, nodalIUS: [], viewIUS: [] })
                        toast.success("Department updated successfully");
                        this.openAddModal();
                        this.getData();
                      

                    }

                    else {
                        this.getData();
                        this.openAddModal();
                        this.setState({ loader: false})
                        toast.error(res.message);

                    }

                }

            }).catch((err) => {
                this.setState({ loader: false })
                toast.error("Failed to update Disaster");
            });

        }
        else {



            adminService.httpPost('/data-import/department/add-department', saveData).then((res) => {
                if (res) {
                    let d = res.data;


                    if (res.status == 1) {
                        this.setState({ loader: false })
                        toast.success("Department added successfully");
                        this.openAddModal();
                        this.getData();
                    }

                    else {
                        this.setState({ loader: false })
                        toast.error(res.message);

                    }
                }
            }).catch((err) => {
                this.setState({ loader: false })
            });
        }


    }







    ///multiple delete

    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
        })
        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete this disaster?",
                // text: "Once deleted, you will not be able to recover this disaster",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        adminService.httpPost('/data-import/department/delete-departments', data).then((res) => {

                            if (res) {
                                let d = res.data;
                                if (res.status == true) {
                                    toast.success("Department deleted successfully");
                                    this.getData();
                                }
                                else {
                                    toast.error("Failed to delete department");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }

        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }
    }


    // get all indicators===================//

    getAllIndicators = async () => {


        adminService.httpPost('/data-retrieval/disaster/get-indicator').then((res) => {
            if (res) {

                let d = res.data;



                this.setState({ indicatorData: d, rowIndData: d })



            }

        }).catch((err) => {

            console.log(err)
        });






    }


}

const mapStateToProps = (state) => ({
    token: state.login.token,
    lang: state.lang.lang
});

export default connect(
    mapStateToProps
)(Department);
