import { Component } from "react";
import "./information-kiosk.css";
import * as jsx from "./information-kiosk.module.jsx";

import * as constants from "../../../Config/app.constants";

import "react-toastify/dist/ReactToastify.css";

import AdminService from "../../../services/admin.service";


class InformationPage extends Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      loader: false,
      shortItem: false,
      data: [],
      shortAbc: false,
      resData: [],
      tab: "All",
      currentPage: 1,
      backupRes: [],
      pagerCount: 0,
      pageLimit: 1,
      backupLimit: 1,
      nonFilterData: [],
    };
  }

  handlePageChange = (event, page) => {
    this.setState({ currentPage: page });
  };

  componentDidMount() {
    this.getResource();
  }

  render() {
    return jsx.default.bind(this)();
  }

  chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = myArray.slice(index, index + chunk_size);

      tempArray.push(myChunk);
    }

    return tempArray;
  }

  filterData(e) {
    let key = e.target.value;
    const storeData = this.state.resData;
    if (key.length > 0) {
      if (storeData != null && storeData != "") {
        const results = storeData.filter(
          (item) =>
            item.title?.en
              .toString()
              .toLowerCase()
              .includes(key.toString().toLowerCase()) ||
            item.description?.en
              .toString()
              .toLowerCase()
              .includes(key.toString().toLowerCase())
        );

        if (results.length > 5) {
          const result = this.chunkArray(results, 5);

          const preRes =
            result[this.state.pagerCount] != undefined
              ? result[this.state.pagerCount]
              : result;

          this.setState({ resData: preRes, pageLimit: result });
        } else {
          const result = this.chunkArray(results, 5);

          this.setState({ resData: results, pageLimit: result?.length });
        }
      } else {
        const results = this.state.backupRes.filter(
          (item) =>
            item.title?.en
              .toString()
              .toLowerCase()
              .includes(key.toString().toLowerCase()) ||
            item.description?.en
              .toString()
              .toLowerCase()
              .includes(key.toString().toLowerCase())
        );

        if (results.length > 5) {
          const result = this.chunkArray(results, 5);

          const preRes =
            result[this.state.pagerCount] != undefined
              ? result[this.state.pagerCount]
              : result;

          this.setState({ resData: preRes, pageLimit: result?.length });
        } else {
          const result = this.chunkArray(results, 5);
          this.setState({ resData: results, pageLimit: result?.length });
        }
      }
    } else {
      this.tabDataChange(this.state.tab);
    }
  }

  paginate = (e, page) => {
    const cnt = page - 1;
    this.setState({ pagerCount: cnt });
    this.getResource();
  };

  tabDataChange(d, fromGetResource = false) {
    this.setState({ tab: d });
    console.log("tabD",d)
    const storeData = [];
    if (d != "All") {
      if (this.state.nonFilterData?.length > 0) {
        this.state.nonFilterData
          .filter((data) => data.category === d)
          .map((item) => storeData.push(item));
      } else {
        this.state.data
          .filter((data) => data.category === d)
          .map((item) => storeData.push(item));
      }

      if (storeData.length > 5) {
        const result = this.chunkArray(storeData, 5);

        const preRes =
          result[this.state.pagerCount] != undefined
            ? result[this.state.pagerCount]
            : result;

        this.setState({
          resData: preRes,
          backupRes: preRes,
          pageLimit: result?.length,
        });
      } else {
        const result = this.chunkArray(storeData, 5);

        this.setState({
          resData: storeData,
          backupRes: storeData,
          pageLimit: result?.length,
        });
      }
    } else {
      if (!fromGetResource) {
        this.getResource();
      }

      storeData = this.state.nonFilterData;

      if (storeData.length > 5) {
        const result = this.chunkArray(storeData, 5);

        const preRes =
          result[this.state.pagerCount] != undefined
            ? result[this.state.pagerCount]
            : result;

        this.setState({
          resData: preRes,
          backupRes: preRes,
          pageLimit: result?.length,
        });
      } else {
        const result = this.chunkArray(storeData, 5);

        this.setState({
          resData: storeData,
          backupRes: storeData,
          pageLimit: result?.length,
        });
      }
    }
  }

  getResource() {
    const dataToBeSend = {};
    AdminService.httpGet(
      "/data-retrieval/resources/get-all-resources",
      dataToBeSend
    )
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;
         
            if (d.length > 5) {
              const result = this.chunkArray(d, 5);

              const preRes =
                result[this.state.pagerCount] != undefined
                  ? result[this.state.pagerCount]
                  : result;

              this.setState({
                nonFilterData: d,
                resData: preRes,
                backupRes: preRes,
                data: preRes,
                pageLimit: result?.length,
                backupLimit: result?.length,
              });
            } else {
              const result = this.chunkArray(d, 5);

              this.setState({
                resData: d,
                backupRes: d,
                data: d,
                pageLimit: result,
                backupLimit: d?.length,
              });
            }
          }

          this.tabDataChange(this.state.tab, true);
        
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  }

  shortData = () => {
    if (this.state.shortItem == false) {
      this.setState({ shortItem: true });
      const short = this.state.resData.reverse();

      this.setState({ resData: short, backupRes: short });
    } else {
      const short = this.state.resData.reverse();

      this.setState({ resData: short, backupRes: short });
      this.setState({ shortItem: false });
    }
  };

  shortAbc = () => {
    if (this.state.shortAbc == false) {
      this.setState({ shortAbc: true });

      const short = this.state.resData.sort((a, b) =>
        a.title.en.localeCompare(b.title.en)
      );

      this.setState({ resData: short, backupRes: short });
    } else {
      this.setState({ shortAbc: false });

      const short = this.state.resData.sort((a, b) =>
        b.title.en.localeCompare(a.title.en)
      );

      this.setState({ resData: short, backupRes: short });
    }
  };
}



export default InformationPage;
