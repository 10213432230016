/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
// import Select from 'react-select';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TreeItem from '@mui/lab/TreeItem';
import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import AdminService from '../../services/admin.service';
import * as $ from 'jquery';

const DataEntryModal = (props) => {
    const form = useRef();

    const [unit, setUnit] = useState({
        unit: '',
        ius: '',
        unitName: ''
    })
    const [subData, setSubGroup] = useState([]);
    const [labelData, setLabelData] = useState([]);
    const [dfInd, setDfInd] = useState({
        dfLabel: '',
        dfUnit: ''
    });
    const [areaTree, setAreaTree] = useState([]);

    const [indLabel, setIndLabel] = useState({
        indCode: '',
        indText: '',
    })
    const [disasters, setDisasters] = useState([]);
    const [FormState, setFormState] = useState(
        {
            indicData: '',
            subGroup: '',
            areaCode: '',
            areaName: '',
            timePeriod: '',
            source: '',
            dataValue: '',
            footnote: null,
        });

    const [fLevel, setFLevel] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const toggleArrow = (e) => {
        e.target.parentElement.querySelector(".nested").classList.toggle("active");
        e.target.classList.toggle("caret-down");
    }

    useEffect(() => {

        const filterIndData = props.indicatorData;
        // const filterIndData = filterInd.filter(x => x.unit?.en == "Tons" || x.unit?.en == "Number" || x.unit?.en == "USD" || x.unit?.en == "Hectare" || x.unit?.en == "Km")



        setLabelData(filterIndData)

        if (props.editData == null || props.editData === undefined || props.editData.length === 0) {
            setFormState({
                ...FormState,
                indicData: '',
                subGroup: '',
                areaCode: '',
                areaName: '',
                footnote: '',
                dataValue: '',
                source: '',
                timePeriod: '',
            });
            setIndLabel({ ...indLabel, indCode: '', indText: '' })
            setUnit({ ...unit, unit: '', ius: '', unitName: '' })
        }
        else {
            const data = props.editData[0];
            setFormState({
                ...FormState,
                indicData: data.indicator,
                subGroup: data.iusData[0].subgroup.name,
                areaCode: data.area_code,
                areaName: data.areaData[0].name,
                footnote: data.footnote,
                dataValue: data.new_value,
                source: data.source.publisher,
                timePeriod: data.time_period.start_time_period,
            });
            let i = -1;
            let j = -1;
            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id === props.editData[0].iu_id) {
                    setDfInd({ ...dfInd, dfLabel: i, dfUnit: 0 })
                    item.subgroup?.forEach((item2, index2) => {
                        j = j + 1;
                        setSubGroup([...subData, item2])
                        if (item2._id === props.editData[0].ius_id) {
                            setDfInd({ ...dfInd, dfLabel: i, dfUnit: j })
                        }
                    })
                }
            })

            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id === props.editData[0].ius_id) {
                    setDfInd({ ...dfInd, dfUnit: i })
                }
            })
            setIndLabel({ ...indLabel, indCode: data.iu_id, indText: data.indicator })
            setUnit({ ...unit, unit: data.iusData[0].subgroup.name, ius: data.ius_id, unitName: data.iusData[0].unit })
            getDisasterData(data.iu_id, false);
        }

        if (props.areaTree != '' && props.areaTree != null) {
            let flatLevel = [];
            props.areaTree.forEach((item) => {
                item.areaSubData = [];
                flatLevel.push(item)
            })

            setFLevel(flatLevel);
            const data = setLevelData(flatLevel)
            setAreaTree(data)
        }

        $(".nested.active:not(:has(li))").hide();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {

        let aArrs = [];
        props.loginArea.forEach(areaCde => {
            let code = areaCde;
            do {
                // eslint-disable-next-line no-loop-func
                let n = fLevel.filter(x => x.area_code === code);

                if (n?.length > 0) {
                    if (!aArrs.includes(code)) {
                        aArrs.push(code);
                    }
                    code = n[0]?.parent_id;
                } else {
                    code = '';
                }

            } while (code != '')
        });
        setExpanded(aArrs);
    }, [props.loginArea, fLevel]);

    const getDisasterData = (iuId, flag = true) => {
        const dataToBeSend = {
            "_id": iuId
        }

        AdminService.httpPost('/data-retrieval/ius/get-indicator-disaster', dataToBeSend).then((res) => {

            if (res) {
                let d = [];
                setDisasters(d);
            }

        }).catch((err) => {
            console.log(err)
        });

    };

    const getSubIndigator = (e, v, r) => {

        if (r !== 'clear') {
            if (['Number', 'Hectare lost', 'Hectare', 'Tons', 'USD', 'Km', 'Local Currency']?.includes(v.unit?.en)) {
                $('#typeChange').attr('type', 'number');
            } else {
                $('#typeChange').attr('type', 'text');
            }

            setFormState({ ...FormState, indicData: v.name })

            setIndLabel({ ...indLabel, indCode: v._id, indText: v.name })

            getDisasterData(v._id);

            labelData?.map(item => {

                if (item._id === v._id) {
                    if (item.subgroup.length > 1) {
                        const subgroups = item.subgroup.map((subgroup) => ({
                            label: subgroup.name.en,
                            value: subgroup._id,
                            name: item.unit.en,
                        }));
                        setSubGroup(subgroups);
                    } else if (item.subgroup.length === 1) {
                        setSubGroup([
                            {
                                label: item.subgroup[0].name.en,
                                value: item.subgroup[0]._id,
                                name: item.unit.en,
                            },
                        ]);
                    }
                }
            });

        }
        else {
            setFormState({
                ...FormState, indicData: '', subGroup: ''
            });
            setIndLabel({ indText: '' })
            setSubGroup([...subData, subData])
        }
    }
    
    const getSource = (e, v, r) => {

        if (r !== 'clear') {
            // setUnit({ ...unit, unit: v.label, ius: v.value, unitName: v.name })
            setFormState({
                ...FormState, source: v.label
            });
        }
        else {
            // setUnit({ ...unit, unit: '', ius: '', unitName: '' })
            setFormState({
                ...FormState, source: ''
            });
        }
    }

    const getTimeperiod = (e, v, r) => {

        if (r !== 'clear') {
            // setUnit({ ...unit, unit: v.label, ius: v.value, unitName: v.name })
            setFormState({
                ...FormState, timePeriod: v.label
            });
        }
        else {
            // setUnit({ ...unit, unit: '', ius: '', unitName: '' })
            setFormState({
                ...FormState, timePeriod: ''
            });
        }
    }

    const getUnit = (e, v, r) => {

        if (r !== 'clear') {
            setUnit({ ...unit, unit: v.label, ius: v.value, unitName: v.name })
            setFormState({
                ...FormState, subGroup: v.label
            });
        }
        else {
            setUnit({ ...unit, unit: '', ius: '', unitName: '' })
            setFormState({
                ...FormState, subGroup: ''
            });
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        setFormState({ ...FormState, [event.target.name]: event.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let dataToSend = [];
        if (props.isEdit === true) {
            dataToSend = {
                "id": props.editData[0]._id,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote,
            }
        }
        else {
            dataToSend = {
                "id": null,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote,
            }
        }
        props.newDataEntry(dataToSend);
        setFormState({
            ...FormState,
            areaCode: '',
            areaName: '',
            timePeriod: '',
            source: '',
            dataValue: '',
            footnote: null,
        })

        setIndLabel({
            ...indLabel,
            indCode: '',
            indText: '',
        })

        setUnit({
            ...unit,
            unit: '',
            ius: ''
        })
        setDisasters([]);
    }

    // area tree sctructure=========================//

    const setLevelData = (flat) => {
        var roots = []
        var all = {}
        flat.forEach(function (item) {
            all[item.area_code] = item
        })
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code]
            if (item.parent_id === null || item.parent_id === '') {
                roots.push(item)
            } else if (item.parent_id in all) {
                var p = all[item.parent_id]
                if (!('areaSubData' in p)) {
                    p.areaSubData = []
                }
                p.areaSubData.push(item)
            }
        })

        return roots
    }


    const renderTestTree = (nodes) => {
        if (JSON.parse(localStorage.getItem('userInfo')).role != 1) {
            nodes = nodes.filter(a => props.loginArea.includes(a.area_code) || a.areaSubData?.filter(b => props.loginArea.includes(b.area_code) || b.areaSubData?.filter(c => props.loginArea.includes(c.area_code) || c.areaSubData?.some(d => props.loginArea.includes(d.area_code))).length > 0).length > 0)
        }
        return (
            <>
                {nodes && nodes.map(node => {
                    console.log("Sudhanshu node", node);
                    return (
                        <li key={node.value}>
                            <span className={('areaSubData' in node && node.areaSubData.length > 0)? (expanded.includes(node.value) ? "caret caret-down" : "caret" ):"dot-product"} onClick={(e) => toggleArrow(e)}></span>
                            <div
                                className={!props.loginArea.includes(node.value) && JSON.parse(localStorage.getItem('userInfo')).role !== 1 ? 'text-gray' : ''} >
                                <input
                                    disabled={!props.loginArea.includes(node.value) && JSON.parse(localStorage.getItem('userInfo')).role !== 1}
                                    value={FormState.areaCode}
                                    checked={FormState.areaCode == node.value}
                                    onChange={(e) => { setFormState({ ...FormState, areaCode: node.area_code, areaName: node.label }) }}
                                    type="checkbox" name="arearadio" />
                                <span for="arearadio">{node.label}</span></div>
                            <ul className={expanded.includes(node.value) ? "nested active" : "nested"}>
                                {
                                    renderTestTree(node.areaSubData)
                                }
                            </ul>
                        </li>
                    )
                })

                }
            </>
        );
    }

    const cancelModal = (event) => {
        event.preventDefault();
        props.closeModal(event.target);
    }

    return (

        <>
            <div className="formBox dataEntryForm">
                <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0 metadata_form dataentry_inputs">
                            <div className="subgroup_indicator  subgrp_area mb-30">
                                <div className="row align-items-center mb-30">

                                    <div className="col-md-12">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>Indicator | Unit</label>
                                                </span>
                                                <Autocomplete
                                                    className={props.isEdit ? 'readonly-autocomplete' : ''}
                                                    value={({ name: indLabel?.indText })}
                                                    readOnly={props.isEdit}
                                                    onChange={(event, value, reason) => { getSubIndigator(event, value, reason) }}
                                                    // options={labelData?.map((option) => option)}
                                                    options={labelData}
                                                    // readOnly= {props.isEdit} 
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextValidator  {...params}
                                                        value={indLabel?.indText}
                                                        name="indicData"
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                    />}
                                                    getOptionLabel={(option) => (option.unit ? option.name?.en + ' | ' + option.unit?.en : option.name?.en) || ""}
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">

                                    <div className="col-md-12">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>Subgroup</label>
                                                </span>
                                                <Autocomplete
                                                    className={props.isEdit ? 'readonly-autocomplete' : ''}
                                                    value={{ label: FormState.subGroup }}
                                                    readOnly={props.isEdit}
                                                    onChange={(event, value, reason) => { getUnit(event, value, reason); }}
                                                    options={subData}
                                                    renderInput={(params) => <TextValidator  {...params}
                                                        name="subGroup"
                                                        value={FormState.subGroup}
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                    />}
                                                    defaultValue={subData[dfInd.dfUnit]}
                                                    getOptionLabel={(option) => option.label}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">

                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown dataEntryModal">
                                        <div className="mat-form-field-wrapper area-btn">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Area</label>
                                            </span>
                                            <Dropdown >
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    {FormState.areaName}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                    <ul id="myUL1" className="pl-0">
                                                        {renderTestTree(areaTree)}
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="inPuthidden ">
                                            <TextValidator
                                                type="hidden"
                                                style={{ border: "0px", outline: 'none' }}
                                                name="password"
                                                autoComplete="off"
                                                className=" w-100"
                                                value={FormState.areaName}
                                                validators={["required"]}
                                                errorMessages={["This field is required"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                {/* <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Time Period</label>
                                    </span>
                                </div> */}

                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style autocomplete position-relative time_area">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Time Period</label>
                                            </span>
                                            <Autocomplete
                                            
                                                value={({ label: FormState.timePeriod })}
                                                // readOnly={props.isEdit}
                                                onChange={(event, value, reason) => { getTimeperiod(event, value, reason) }}
                                                // options={labelData?.map((option) => option)}
                                                options={props.allTimeperiods ? props.allTimeperiods.map((x)=> ({label:x.timeperiod})): []}
                                                // readOnly= {props.isEdit} 
                                                filterSelectedOptions
                                                renderInput={(params) => <TextValidator  {...params}
                                                    value={FormState.timePeriod}
                                                    name="tpData"
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                />}
                                                getOptionLabel={(option) => option.label}
                                             
                                            />
                                            
                                            <Tooltip title="Valid formats: YYYY, YYYY.MM, YYYY.MM.DD, YYYY-YYYY, YYYY.MM-YYYY.MM, YYYY.MM.DD-YYYY.MM.DD" placement="top">
                                                <a className="date_values" style={{ 'float': 'right', 'cursor': 'pointer', 'color': '#2A2B6A' }} href="javascript:;"><InfoIcon /></a></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">

                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Source</label>
                                            </span>
                                            <Autocomplete
                                                value={({ label: FormState.source })}
                                                // readOnly={props.isEdit}
                                                onChange={(event, value, reason) => { getSource(event, value, reason) }}
                                                // options={labelData?.map((option) => option)}
                                                options={props.allSources ? props.allSources.sort((a,b)=> a.datasource.localeCompare(b.datasource)).map((x)=> ({label:x.datasource})): []}
                                                // readOnly= {props.isEdit} 
                                                filterSelectedOptions
                                                renderInput={(params) => <TextValidator  {...params}
                                                    value={FormState.source}
                                                    name="srcData"
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                />}
                                                getOptionLabel={(option) => option.label}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Data Value</label>
                                            </span>
                                            <TextValidator id="typeChange" className="w-100" label="" onChange={handleChange} name="dataValue" value={FormState.dataValue} validators={['required']} errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Footnote</label>
                                            </span>
                                            <TextValidator className="w-100" label="" onChange={handleChange} name="footnote" value={FormState.footnote} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                                <Button className="addbtn" color="error" variant="contained" type="submit">
                                    {props.editData[0] != null ? 'Update' : 'Add'} <img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg" />
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>
        </>
    );
}


export default DataEntryModal