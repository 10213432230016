import { Component } from "react";
import "./audit_trail.css";
import * as jsx from "./audit_trail.module.jsx";
import AdminService from "../../../services/admin.service";
import moment from "moment";

class AuditTrailComponent extends Component {
  // arr = ["dxgf"];

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      loader: false,
      pageNumber: 1,
      totalElements: 100,
      dtotalElements: 100,
      rowsPerPage: 100,
      dataLimit: 10,
      disabledApply: true,
      disabledDownload: true,
      selectedUsers: [],
      defOptions: [],  
      fromDt: "",
      fromTo: ""      
    };
  }

  componentDidMount() {
    this.getUser();
    // this.getData();
  }

  handleDate = async (newValue) => {
    this.setState({
      disabledApply: false,
      disabledDownload: true,
      fromDt: newValue,
    });
  };

  handleDate1 = async (newValue) => {
    this.setState({
      disabledApply: false,
      disabledDownload: true,
      fromTo: newValue,
    });
  };

  getUser = async () => {
    this.setState({ loader: true });

    // setState({...state, loader: false });

    AdminService.httpGet("/data-retrieval/users/getUsersForReport")
      .then((res) => {
        if (res) {
          let arr = [];
          let d = res.data.records;

          d.forEach((element) => {
            arr.push({ label: element.name, value: element._id });
          });

          this.setState({ defOptions: arr, loader: false });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  }

  render() {
    return jsx.default.bind(this)();
  }

  downloadCSVFromJson = () => {
    const fileType = "text/csv";
    this.downloadCSV({ data: this.state.filteredData, fileType: fileType });
  };

  downloadCSV = ({ data, fileType }) => {
    if (!data) {
      return;
    }
    const headersForAllData = [
      { label: "Table", key: "collectionName" },
      { label: "Change Type", key: "type" },
      { label: "ID", key: "_id" },
      { label: "Old Value", key: "value" },
      { label: "New Value", key: "value" },
      { label: "DateTime", key: "createdAt" },      
      { label: "User", key: "name" }
    ];

    const headers = headersForAllData
      .map((header) => `"${header.label}"`)
      .join(",");

    const csv = data.filter(row => row.type != 'delete').reduce((acc, row) => {
      const rowValues = headersForAllData
        .map((header) => {
          const key = header.key;
          let value = row[key] ? `"${row[key]}"` : " ";
          if(header.label == "Old Value") {
            
            let string = [];
            let val = null;
            try{
              val = JSON.parse(row.value);
              if(val) {
                for(const [key, value] of Object.entries(val)) {
                  string.push(`'${key}' : ${value.__old}`);
                }
              }
            } catch(e) {
              string.push(row.value);
            }       
            value = string.join(' | ');    
          } else if(header.label == "New Value") {
            let string = [];
            let val = null;
            try{
              val = JSON.parse(row.value);
              if(val) {
                for(const [key, value] of Object.entries(val)) {
                  string.push(`'${key}' : ${value.__new}`);
                }
              }
            } catch(e) {
              string.push(row.value);
            }       
            value = string.join(' | ');
          } 
          if(row.type == 'delete') {
            value = ' ';
          }         
          return value.replace(/,/g, '|');
        })
        .join(",");
      return acc + rowValues + "\r\n";
    }, headers + "\r\n");

    const blob = new Blob([csv], { type: fileType });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    const a = document.createElement("a");
    a.download = `AuditTrail_${year}_${month}_${day}`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  // update data limit===================//
  updateDataLimit = (e) => {
    this.setState({ dataLimit: e.target.value });
    this.getData();
  };

  // filter table and get data====================//

  onSearch = (val, enterPressed = false) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    let fd = null;
      
    if (enterPressed) {
      if (val === "") {
        fd = d;
        this.setState({
          filteredData: fd,
          totalElements: this.state.dtotalElements,
        });
      } else {
        this.getData(1, val.toLowerCase());

        fd = d.filter( 
          item =>((item.collectionName && item.collectionName.toLowerCase().includes(val.toLowerCase))
        || (item.type && item.type.toLowerCase().includes(val.toLowerCase))
        || (item.refId && item.refId.toLowerCase().includes(val.toLowerCase))
        || (item.name && item.name.toLowerCase().includes(val.toLowerCase))        
        ));
      }
      this.setState({filteredData : fd});
    } else if (val?.length === 0) {
      fd = d;
      this.setState({
        filteredData: fd,
        totalElements: this.state.dtotalElements,
      });
    }
  };

  columns = [
    {
      name: "Dataset",
      selector: (row, index) => row?.collectionName,
      sortable: true,
      width: "100px",
    },
    {
      name: "Type of Action",
      selector: (row) => row?.type?.toUpperCase(),
      sortable: true,
      width: "150px",
    },
    {
      name: "Record ID",
      selector: (row) => row?.refId,
      sortable: false,
      width: "250px",
    },
    {
      name: "Old Value",
      selector: (row) => {
        if(row.type == 'delete') {
          return <></>;
        }
        let string = [];
        let val = null;
        try{
          val = JSON.parse(row.value);
          if(val) {
            for(const [key, value] of Object.entries(val)) {
              string.push(`'${key}' : ${value.__old}`);
              // string.push(`New: ${value.__new}`);
            }
          }
        } catch(e) {
          string.push(row.value);
        }       
        return (
          <>
          {
            string.map(x=> (
              <>
              {x} <br />
              </>
            ))
          }
          </>
        );
      },
      sortable: false,
      // width: "300px",
    },
    {
      name: "New Value",
      selector: (row) => {
        console.log("Sudhanshu", row.type);
        if(row.type == 'delete') {
          return <></>;
        }
        let string = [];
        let val = null;
        try{
          val = JSON.parse(row.value);
          if(val) {
            for(const [key, value] of Object.entries(val)) {
              string.push(`'${key}' : ${value.__new}`);
            }
          }
        } catch(e) {
          string.push(row.value);
        }       
        return (
          <>
          {
            string.map(x=> (
              <>
              {x} <br />
              </>
            ))
          }
          </>
        );
      },
      sortable: false,
      // width: "300px",
    },
    {
      name: "Date/Time",
      width: "150px",
      cell: (row) => moment(row?.createdAt).toLocaleString(),
      sortable: true,
    },
    {
      name: "User",
      width: "150px",
      cell: (row) => row?.name,
      sortable: true,
    }
  ];

  onChange = async (value, event) => {
    this.setState({
      disabledApply: false,
      disabledDownload: true,
    });

    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...this.state.defOptions];
      this.setState({
        selectedUsers: stp,
      });
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState({
        selectedUsers: [],
      });
    } else if (event.action === "deselect-option") {
      this.setState({
        selectedUsers: value.filter(
          (o) => o.value !== event.option.value && o.value !== "*"
        ),
      });
    } else if (value.length === this.state.defOptions.length) {
      this.setState({
        selectedUsers: [{ label: "All", value: "*" }, ...this.state.defOptions],
      });
    } else {
      this.setState({
        selectedUsers: [...this.state.selectedUsers, event.option],
      });
    }
  };

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All selected`;
    } else if (value && Array.isArray(value) && value?.length !== 0) {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    } else if (value?.length === 0) {
      return `${placeholderButtonLabel}`;
    } else {
      return `${placeholderButtonLabel}: 1 selected`;
    }
  };


  setPage = (page) => {
    this.getData(page);
    this.setState({ pageNumber: page });
  };

  getData = async (
    page = this.state.pageNumber,
    keyword = this.state.searchText
  ) => {
    if (keyword !== "" && keyword.length < 3) {
      this.setState({ searchText: keyword });
      return;
    }
    this.setState({ loader: true, searchText: keyword, pageNumber: page });
    // this.setState({ loader: true, searchText: "", pageNumber: page });
    const savedItem = JSON.parse(localStorage.getItem("userInfo"));
    let userid = savedItem.id;
    const offset = (page - 1) * this.state.rowsPerPage;
    let users = this.state.selectedUsers.filter(x=> x.value != '*')
                                        .map(y => y.value);
    const dataToBeSend = {
      size: this.state.rowsPerPage,
      totalElements: 0,
      totalPages: 0,
      pageNumber: 0,
      filterKeyWord: keyword,
      dataStatus: "inactive",
      userId: userid,
      startOffset: offset,
      endOffset: 0,
      status: "",
      fromDate: this.state.fromDt,
      toDate: this.state.fromTo,
      users: users
    };

    AdminService.httpPost("/data-retrieval/audit-trail/get-audit", dataToBeSend)
      .then((res) => {
        if (res) {
          let d = res.data;
          console.log("sudhanshu", d);
          d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          let t = res.totalElements;

          d.forEach((ele, index) => {
            ele.index = index;
          });
          if (keyword !== "") {
            this.setState({ filteredData: d, loader: false, totalElements: t });
          } else {
            this.setState({
              filteredData: d,
              data: d,
              loader: false,
              totalElements: t,
              dtotalElements: t,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  };

}

export default AuditTrailComponent;
