import React from "react";
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import SubGroupAddModal from "../../Modals/subgroup.modal";

const subgroupTemplate = function () {
  // const handleChange = ({ selectedRows }) => {
  //   // You can set state or dispatch with something like Redux so we can use the retrieved data
  //   console.log('Selected Rows: ', selectedRows);
  // };

  return (
    <>
      {/* 
      <Breadcrumbs />

      <Sidebar /> */}

      <div className="main manage-resource" id="main">
        <div className="container-fluid areaCont">
          <section className="areaSection" id="areaSection">
            {/* <div className='col-lg-12 col-md-12'>
            <div className="row header-div new-h-d pt-0 pl-0"> */}
            {/* <div className="col-md-1 ">
              <div class="form-group form-inline">                            
                  <label >Show</label>
                      <select  className='form-control' value={this.state.dataLimit} onChange={this.updateDataLimit}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        </select>
                  </div>
     

              </div> */}
            {/* <div className="col-md-2">

              <div class="form-group form-inline">                            
                  <label >Status</label>
                      <select id="statusSelect" className='form-control' value={this.state.dataStatus} onChange={this.getDataByStatus}>
                        <option value={2}>Pending</option>
                        <option value={1}>Approved</option>
                        <option value={3}>Disapproved</option>
                        </select>
                  </div>
              </div> */}
            {/* <div className="col-md-12 pl-2">
              <div class="">     */}
            {/* <SearchComponent.default onSearch={props.onSearch} searchText={props.searchText} /> */}
            <subHeaderComponentMemo.default
              onSearch={this.onSearch}
              searchText={this.state.searchText}
              PropDeleteBulk={this.deleteBulkUser}
              nRows={this.state.filteredData?.length}
              checkData={this.state.bulkDelete}
            />{" "}
            {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}
            {/* </div>
              </div>
            </div>
          </div> */}
            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              selectableRows
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className="dataTableScroll"
              noDataComponent="No Data"
              fixedHeader
            />
          </section>
        </div>
      </div>

      {/* modal area begin here============== */}

      <div
        id="AddAreaModal"
        className={
          this.state.modal
            ? "gallery-sidebar add-area-modal add-form width90"
            : "gallery-sidebar add-area-modal add-form width0"
        }
      >
        <div
          id="openDataModal"
          onClick={this.openAddModal}
          ariaTooltip="Button that displays and hides a tooltip triggered by other buttons"
          className=" gallery-toggler maroon_bg add-user "
          style={{
            touchAction: "none",
            userSelect: "none",
            WebkitUserDrag: "none",
            WebkitTopHighlightColor: "rgba(0, 0, 0, 0)",
          }}
          aria-describedby="cdk-describedby-message-38"
          cdk-describedby-host=""
        >
          <img src="assets/lib/images/svg/add1.svg" style={{ width: "17px" }} />
        </div>
        <div class="right-tab-heading are_head">
          <h2 class="form-heading">
            <span class="form-heading_he">
              <span class="ddf">
                {this.state.isEdit == true
                  ? "Edit Indicator - Unit - Subgroup"
                  : "Add Indicator - Unit - Subgroup"}
              </span>
            </span>
          </h2>
        </div>
        <SubGroupAddModal
          formList={this.state.formList}
          disasterData={this.state.disasterData}
          indicatorData={this.state.indicatorData}
          indicatorId={this.getSubIndicators}
          subGroupData={this.state.subIndData}
          areaTree={this.state.areaData}
          newDataEntry={this.saveData}
          editData={this.state.editEntry}
          isEdit={this.state.isEdit}
          closeModal={this.openAddModal}
        />
      </div>

      {/* 
      <Footer /> */}

      <div
        className={
          this.state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

      <div className={this.state.modal ? "overlay dblock" : "overlay"}></div>
    </>
  );
};

export default subgroupTemplate;
