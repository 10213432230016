import { Component } from "react";
import "./indicator.css";
import * as jsx from "./indicator.module.jsx";
import * as constants from "../../../Config/app.constants";
import * as $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
class Indicator extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      isEdit: false,
      editModeId: null,
      modal: false,
      inddata: [],
      loader: false,
      allIndicatorList: null,
      allUnitList: null,
      allSubgroupDimensionList: null,
      allDimensionList: null,
      sectorSubsectorList: null,
      bulkDelete: [],
      goalTargetList: null,
      getSubSector: [],
      getGoals:[],
      indicatorData:[],
      unitdata:[],
      subgroupDimension:[],
      subgroupList:[],
      formData:[],
      cross: true
    };
  }

  onSearch = (val) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    let fd = null;
    if (val === "") {
      fd = d;
    } else {
      fd = d.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(val.toLowerCase())) ||
          (item.unit && item.unit.toLowerCase().includes(val.toLowerCase()))
      );

    }
    this.setState({ filteredData: fd });
  };

  sortWithToolTip = (rowA, rowB) => {
    const a = rowA?.name;
    const b = rowB?.name;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  sortforSb = (rowA, rowB) => {
    const a = rowA?.subgroup;
    const b = rowB?.subgroup;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  clearCallback = (cancelModal)=>{
    cancelModal();
  }
 

  openAddModal = () => {
    if (this.state.modal === false) {
      this.setState({ modal: true,cross: false });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null, cross: true });
      this.clearCallback();
    }
  };

  

  columns = [

    {
      name: "Indicator",
      // selector: (row) => row.name,
      selector: (row) => row?.name,
      sortable: true,
      width: "450px",
      sortFunction: this.sortWithToolTip,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "Subgroup",
      selector: (row) => (
        <>
          {row?.subgroup?.length > 0 &&
           <div className="metaDataindicator" key={row._id}>
            <span
              className="indicatorImg d-flex"
              data-toggle="modal"
              data-target="#subgroupindicator"
              onClick={(e) => this.openIncPopup(e, row._id)}
            >
              {row?.subgroup?.length}
              <img src="assets/lib/images/side_arrow.svg"  alt="iii" className="ml-1" />
            </span>
          </div>
          }
        </>
      ),
      sortable: true,
      sortFunction: this.sortforSb,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>

          <div className="action_btns">
            <button onClick={(e) => this.editMode(e, row._id)}>
              <img src="assets/lib/images/icon/edit_icon.svg" alt="dd" />
            </button>
            <button onClick={(e) => this.removeRow(e, row._id)}>
              <img src="assets/lib/images/icon/delete_icon.svg" alt="ff" />
            </button>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  componentDidMount() {
    this.getData();
    adminService.checkToken();
    this.getSubtopic();
    this.getGoal();
    this.getIndicatorData()
    this.getSubgropDimension()
  }

  render() {
    return jsx.default.bind(this)();
  }

  callBack = () => {
    this.setState({ modal: false, isEdit: false, editModeId: null});
    this.forceUpdate();
    this.getData();
    this.getSubtopic();
    this.getGoal();
    this.getIndicatorData()
    this.getSubgropDimension()
  };

  openModal = () => {
    if (this.state.modal === false) {
      this.setState({ modal: true,cross:false });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null, cross: true });
    }
    this.forceUpdate();
  };

  openIncPopup(event, id) {
    event.preventDefault();

    this.state.filteredData.forEach((item) => {
      if (item._id === id) {
        this.setState({ inddata: item });
        this.forceUpdate();
        return;
      }
    });
    
  }

  editMode(event, id) {
    event.preventDefault();
    this.state?.filteredData?.forEach((item) => {
      if (item._id === id) {
        this.setState({ isEdit: true, editModeId: item, modal: true, cross:false });
        this.forceUpdate();
        return;
      }
    });
    // this.openAddModal();
    // $('#openDataModal').trigger('click');
  }

  //////////=========checkbox delete============/////////

  deleteProducts = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          selectedIds: this.state.checkedBoxes,
        };
        adminService
          .httpPost("/data-import/ius/delete-multiple-indicator-by-iu", data)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res;


              if (res.status === 1) {
                toast.success("Indicator/s deleted successfully");
                this.getData();
              } else {
                toast.error(res.message);
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  changeStatus(event, id, ind_no) {
    let statusData = { iuid: id, iusid: ind_no };
    fetch(constants.server + "api/data-retrieval/ius/change-indicator-status", {
      method: "POST",
      body: JSON.stringify(statusData),
      headers: {
        Authorization: this.props.token,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.getData();
        toast.success("Status updated successfully");
      })
      .catch((err) => {
        toast.error("Error in status update");
      });
  }

  removeRow(event, id) {
    event.preventDefault();

    swal({
      title: "Are you sure you want to delete?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data = { _id: id };
        const req = adminService
          .httpPost("/data-import/ius/delete-multiple-indicator-by-iu", data)
          .then((res) => {
            if (res.status == 0) {
              toast.error(res.message);
            } else {
              this.getData();
              toast.success("Indicator deleted successfully");
            }
          })
          .catch((err) => {
            toast.error("Error in deleting indicator");
          });
      }
    });
  }

 
  getData = () => {
    this.setState({ loader: true, searchText: "" });

    fetch(constants.server + "api/data-retrieval/ius/get-indicator", {
      method: "post",
      headers: new Headers({
        Authorization: this.props.token,
        "Content-Type": "application/json",
      }),
      body: null,
    })
      .then((res) => res.json())
      .then((data) => {
        let d = data.rowData;
        let allIndicatorList = data?.formData?.indicator;
        let allUnitList = data?.formData?.unit;
        let allSubgroupDimensionList = data?.formData?.sbgroup_dimension;
        let allDimensionList = data?.formData?.all_dimension;
        let sectorSubsectorList = data?.formData?.sectorSubsectorList;
        let goalTargetList = data.formData?.goalTargetList;
        goalTargetList = goalTargetList?.sort((a, b) =>
          a.level_name.localeCompare(b.level_name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
        d.forEach((ele, index) => {
          ele.index = index;
        });

        this.setState({
          // data: d,
          // filteredData: d,
          allIndicatorList: allIndicatorList,
          allUnitList: allUnitList,
          allSubgroupDimensionList: allSubgroupDimensionList,
          allDimensionList: allDimensionList,
          sectorSubsectorList: sectorSubsectorList,
          goalTargetList: goalTargetList,
          // loader: false,
        });
      })
      .catch((err) => {
        toast.error(err.message);
      });

      adminService.httpPost("/data-retrieval/ius/get-indicator-by-iu").then((res) => {
        if (res) {
          this.setState({ data: res?.data, filteredData: res?.data ,formData:res?.formData, loader : false});
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  getSubtopic = async () => {
    this.setState({ loader: true });

    adminService
      .httpGet("/data-retrieval/generic/get-data/topic")
      .then((res) => {
        if (res) {
          const sortData= res?.data?.sort((a, b) => {
            const indicatorA = a.topicname.toLowerCase();
            const indicatorB = b.topicname.toLowerCase();
            return indicatorA.localeCompare(indicatorB);
          });
          this.setState({ getSubSector: sortData,loader: false, });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };
  getGoal = async () => {
    this.setState({ loader: true });

    adminService
      .httpGet("/data-retrieval/generic/get-data/goal")
      .then((res) => {
        if (res) {
          function customSort(a, b) {
            const regex = /Goal (\d+)/;
            const numberA = parseInt(a.goalname.match(regex)[1]);
            const numberB = parseInt(b.goalname.match(regex)[1]);
            
            return numberA - numberB;
          }

          const sortData= res?.data?.sort(customSort)
          this.setState({ getGoals: sortData,loader: false  });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  getIndicatorData = async () => {
    this.setState({ loader: true});

    adminService.httpPost('/data-retrieval/ius/get-indicator').then((res) => {
        if (res) {
               const sortData= res?.formData?.indicator?.sort((a, b) => {
                const indicatorA = a.indicator.toLowerCase();
                const indicatorB = b.indicator.toLowerCase();
                return indicatorA.localeCompare(indicatorB);
              });
             const sortUnit= res?.formData?.unit?.sort((a,b)=>a?.toLowerCase().localeCompare(b?.toLowerCase()))
            this.setState({ indicatorData:sortData,unitdata:sortUnit,loader: false})
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });
}

getSubgropDimension = async () => {
    this.setState({ loader: true});

    adminService.httpGet('/data-retrieval/ius/get-subgroup-dimension').then((res) => {
        if (res) {
            
            const tempsubdime =[]
            res?.data?.forEach((item)=>{
              let obj={
                  dimensionText:item?._id,
                  subgArr:item?.values
              }
              tempsubdime.push(obj)
          })
            this.setState({ subgroupDimension:tempsubdime,loader: false  })
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });
}

  ///multiple delete

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id);
    });
    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            _id: ids.toString(),
          };
          adminService
            .httpPost("/data-import/ius/delete-multiple-indicator-by-iu", data)
            .then((res) => {
              if (res) {
                this.setState({ loader: false });
                let d = res;


                if (res.status == 1) {
                  toast.success("Indicator deleted successfully");
                  this.getData();
                } else {
                  toast.error(res.message);
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });

      return false;
    }
  };
}



const mapStateToProps = (state) => ({
  token: state.login.token,
});

export default connect(mapStateToProps)(Indicator);
