import { Component } from 'react'
import './area.css';
import * as jsx from './area.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import Dropdown from 'react-bootstrap/Dropdown';
import swal from 'sweetalert';
class Area extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            isEdit: false,
            editEntry: [],
            modal: false,
            loader: false,
            bulkDelete: [],
            areaNames: {}
        }
    }


    //////////////===========add and edit api code========///////////////
    saveAreaData = (childData) => {

        if (childData.id != null) {

            this.editNewAreaData(childData)

        }
        else {
            this.addNewAreaData(childData)

        }



    }


    addNewAreaData = async (data) => {
        this.setState({ loader: true })
        AdminService.httpPost('/data-import/area/add-area', data).then((res) => {
            if (res) {

                if (res.success == true) {
                    this.setState({ loader: false })
                    console.log("abc", res.message)
                    toast.success("Area added successfully");
                    this.openAddModal();
                    this.getData();
                }

                else {
                    this.setState({ loader: false })
                    toast.error(res.message);

                }

            }

        }).catch((err) => {
            this.setState({ loader: false })

        });





    }


    editNewAreaData = async (data, e) => {
        this.setState({ loader: true })
        AdminService.httpPut('/data-import/area/update-area/' + data.id, data).then((res) => {
            if (res) {

                if (res.status != 0 && res.success == true) {
                    this.setState({ loader: false })
                    toast.success("Area updated successfully");
                    this.openAddModal();
                    this.getData();
                }

                else if (res.status == 0) {
                    this.setState({ loader: false })
                    toast.error('Failed To Update Area');

                }

                else {
                    this.setState({ loader: false })
                    toast.error(res.message);

                }

            }

        }).catch((err) => {
            toast.error(err);
            this.setState({ loader: false })

        });

    }



    ////////////////////==========serach funcation===============///////////////

    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {

            fd = d.filter(
                item => (item.area_code && item.area_code.toLowerCase().includes(val.toLowerCase())
                    || (item.name && item.name.toLowerCase().includes(val.toLowerCase()))


                ));
        }

        this.setState({ filteredData: fd });
    }

    ////////=======create datatable column=======/////////// 
    columns = [

        {
            name: 'Area ID',
            selector: row => row.area_code.toUpperCase(),
            sortable: true,
            width: "250px",
        },
        {
            name: 'Area Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Area Level',
            selector: row => row.level,
            sortable: true,
            width: "150px",

        },
        {
            name: 'Parent Area',
            selector: row => row.parent_id ? "" + this.state.areaNames[row.parent_id] + " (" + row.parent_id + ")" : "",
            sortable: true,

        },

        {
            name: 'Action',
            width: "200px",
            cell: row =>
                <>

                    {/* <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.deleteEntry(e, row.area_code)} href="javascript:;">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                    <div className='action_btns'>
                        <button onClick={(e) => this.editMode(e, row._id)}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                        <button onClick={(e) => this.deleteEntry(e, row.area_code)}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                    </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    //////=======this function use to change status 1 & 0 ===========////////////////////
    changeDataStatus(e, id, status) {
        this.setState({ loader: true })
        let changed = 0;

        if (status == 1) {
            changed = 0;
        }
        else {
            changed = 1;
        }

        let statusData = { "areaId": id, "areaStatus": changed };


        AdminService.httpPost('/data-import/area/update-status', statusData).then((res) => {

            if (res) {
                this.setState({ loader: false })


                if (res.success == true) {
                    toast.success("Area status updated successfully");
                    this.getData();
                }
                else {
                    toast.error(res.message);

                }

            }

        }).catch((err) => {
            this.setState({ loader: false })

        });


    }



    ////////////===============this api is use to delete data by id ==================/////////////////


    deleteEntry = (e, id) => {

        swal({
            title: "Are you sure you want to delete?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        "areaId": id,

                    }

                    AdminService.httpPost('/data-import/area/delete-area', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })

                            if (res.status == 1) {
                                toast.success(res?.message);
                                this.getData();
                            }

                            else {
                                toast.error(res.message);

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })

                    });
                }
            });






    }


    /////////////=========this funcation is use edit mode ========//////


    editMode(event, id) {
        const edData = [];
        this.state.filteredData.forEach((item) => {

            if (item._id == id) {
                edData.push(item)
                return;
            }

        });

        this.setState({ ...this.state.editEntry, editEntry: edData, isEdit: true })


        this.openAddModal();

    }


    openEditModal() {
        if ($('#EditAreaModal').hasClass('width350')) {
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else {
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getData();
        AdminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }



    ///////===========this api is use to get the data in data base==========//////////

    getData = async () => {
        this.setState({ loader: true, searchText: "" });

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {

                let d = res.data
                d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                let areaNames = {};
                d.forEach(x => {
                    areaNames[x.area_code] = x.name;
                })

                this.setState({ filteredData: d, data: d, loader: false, areaNames: areaNames })

            }

        }).catch((err) => {
            this.setState({ loader: false })

        });

    }







    // open add modal=====================//


    openAddModal = () => {


        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({ ...this.state.editEntry, editEntry: [], modal: false, isEdit: false, editModeId: null })
        }

    }

    checkExixts(val) {

        fetch(constants.server + 'api/data-retrieval/area/check-areacode', { area_code: val })
            .subscribe((res) => {

                if (res.status == 1) {
                    this.existError = true
                    this.errorMessage = res.message
                } else {
                    this.existError = false
                }
            })
    }





    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];


        this.state.bulkDelete.forEach(item => {
            ids.push(item.area_code)

        })

        if (ids?.length > 0) {
            swal({
                title: "Are you sure you want to delete?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "area": ids.toString()
                        }
                        AdminService.httpPost('/data-import/area/delete-multiple-area', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log(res)

                                if (res.status == true) {
                                    toast.success("Area deleted successfully");
                                    this.getData();
                                }

                                else {
                                    toast.error(res.message);

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })

                        });
                    }
                });
        }

        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }









}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
)(Area);
