import { Component } from "react";
import "./manage-goal.css";
import * as jsx from "./manage-goal.module.jsx";
import * as $ from "jquery";
import * as constants from "../../../Config/app.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Usermodel from "../../Modals/addUser.model";
import swal from "sweetalert";
import AdminService from "../../../services/admin.service";
import { connect } from "react-redux";
import { id } from "date-fns/locale";
import Dropdown from 'react-bootstrap/Dropdown';
class ManageGoals extends Component {

  constructor() {
    super();
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let userRole = userInfo?.role;
    this.state = {
      filteredData: [],
      searchText: "",
      modal: false,
      data: [],
      isEdit: false,
      editModeId: null,
      parentCallback: [],

      loader: false,
      bulkDelete: [],
      targetList:[],
      isAscending: true
    };
  }

  editMode(event, row_id) {
    event.preventDefault();
    this.setState({ isEdit: true, modal: true });

    this.state.data.forEach((item) => {
      if (item._id == row_id) {
        console.log("This is item edit console--", item)
        this.setState({ editModeId: item });
      }
    });

    this.openEditModal();
  }

  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null });
    }
  };



  openEditModal() {
    if ($("#EditAreaModal").hasClass("width350")) {
      $("#EditAreaModal").removeClass("width350");
      $("#EditAreaModal").addClass("width0");
      $(".overlay").removeClass("dblock");
    } else {
      $("#EditAreaModal").addClass("width350");
      $("#EditAreaModal").removeClass("width0");
      $(".overlay").addClass("dblock");
    }
  }

  sortWithToolTip = (rowA, rowB) => {
    const a = rowA?.goalname;
    const b = rowB?.goalname;
  
    // Use a regular expression to extract numeric parts from the strings
    const regex = /\d+/;
  
    const numA = parseInt(a?.match(regex)[0], 10);
    const numB = parseInt(b?.match(regex)[0], 10);
  
    if (numA < numB) {
      return -1;
    }
  
    if (numA > numB) {
      return 1;
    }
  
    // If the numeric parts are equal, perform a standard string comparison
    if (a < b) {
      return -1;
    }
  
    if (a > b) {
      return 1;
    }
  
    return 0;
  };
  
  columns = [
    // {
    //   name: "#",
    //   selector: (row) => row.sno,
    //   sortable: true,
    //   width: "50px",
    // },

    {
      name: "Goal Name",
      selector: (row) => row.goalname,
      sortable: true,
      sortFunction: this.sortWithToolTip,
      width: "370px",
    },
    {
      name: "Goal Description",
      selector: (row) => row.goaldescription,
      sortable: true,
      width: "1000px",
    },


    {
      name: "Target",
      width: "100px",
      selector: (row) => (
        <>
          {row?.target?.length > 0 &&
            <>
              <span onClick={()=>this.showTargets(row.target)} >{row?.target?.length} <img className="side_arrow" src='assets/lib/images/side_arrow.svg' data-toggle="modal" data-target="#manage_goals" /></span>
            </>
          }
        </>
      ),
    },

    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <>

          {/* <Dropdown className='table-action'>
            <Dropdown.Toggle id="dropdown-basic">
              Select
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => this.editMode(e, row._id)} href="javascript:;">Edit</Dropdown.Item>
              <Dropdown.Item onClick={(e) => { this.deleteEntry(row._id); }} href="javascript:;">Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
                <div className='action_btns'>
                       <button onClick={(e) => this.editMode(e, row._id)}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                       <button onClick={(e) => { this.deleteEntry(row._id); }}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                </div>

        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  //==========add and edit api user=========///
  saveUserData = (saveData) => {
    console.log(saveData)

    this.setState({ loader: true })
    let apiUrlMethod = null;
    if (saveData?.id !== null && saveData?.id !== '') {
      apiUrlMethod = AdminService.httpPut('/data-import/generic/update/goal/' + saveData?.id, saveData)
    }
    else {
      apiUrlMethod = AdminService.httpPost('/data-import/generic/add/goal', saveData)
    }



    apiUrlMethod.then(response => {

      if (response?.status === 200) {

        toast.success(saveData?.id !== null ? "Goal updated successfully!" : "Goal Added successfully!")
        this.getData()
        this.setState({ loader: false })
        this.openAddModal()


      }
      else {
        toast.error(response?.message)
        this.setState({ loader: false })
      }


    }, error => {
      console.log('error in api', error)
      this.setState({ loader: false })
    })



  };



  componentDidMount() {
    this.getData();

  }




  render() {
    return jsx.default.bind(this)();
  }

  testClickEvent(param) {
    alert("Row Click Event");
  }

  ///============get api==========///

  getData = () => {
    this.setState({ loader: true, searchText: "" });



    AdminService.httpGet('/data-retrieval/generic/get-data/goal').then(response => {

      let resData = response?.data;
      resData.sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt))
     
      let filtData = []
      resData?.forEach((item, index) => {
        item['sno'] = index + 1;

        filtData.push(item)

      })

      this.setState({ loader: false, data: filtData, filteredData: filtData })

    }, error => {
      console.log('error', error)
    })

  };

  ///==========change status api=============


  showTargets=(target)=>{
    target.sort((a, b) => {
      const targetNumA = parseFloat(a.targetname.match(/\d+(\.\d+)?/)[0]);
      const targetNumB = parseFloat(b.targetname.match(/\d+(\.\d+)?/)[0]);
      return targetNumA - targetNumB;
  });
    console.log('target',target )
    this.setState({targetList:target})
  }

  deleteEntry = (id) => {
    swal({
      title: "Are you sure to delete this goal?",
      //text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          id: id,
        };

        AdminService.httpPost("/data-import/generic/delete/goal", data)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res;

              if (res.status == true) {
                toast.success("Goal deleted successfully");
                this.getData();
              } else {
                toast.error(res.message);
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };




  // delete bulk records=======================///
  handleChange = ({ selectedRows }) => {

    this.setState({ bulkDelete: selectedRows })

  };



  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared })

  };

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach(item => {
      ids.push(item._id)
    })


    if (ids?.length > 0) {

      swal({
        title: "Are you sure you want to delete?",
        // text: "Once deleted, you will not be able to recover this resource entry!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            const data = {
              "id": ids.toString()
            }
            AdminService.httpPost('/data-import/generic/delete/goal', data).then((res) => {

              if (res) {
                this.setState({ loader: false })


                if (res.status == true) {
                  toast.success("Goals deleted successfully.");
                  this.getData();
                }
                else {
                  toast.error("Failed to delete goals!");

                }

              }

            }).catch((err) => {
              this.setState({ loader: false })
              console.log(err)
            });
          }
        });
    }


    else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,

      })

      return false;


    }

  }


  ////<==============serach function=====>////
  onSearch = (val) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    let fd = null;
    if (val == '') {
      fd = d;
    } else {
      // fd = d.filter(
      //     item => (item.iusData[0].name && item.areaData[0].area_code.toLowerCase().includes(val.toLowerCase())
      //         || (item.areaData[0].name && item.source?.publisher?.toLowerCase().includes(val.toLowerCase()))
      //     ));
      fd = d.filter(
        item => (item.goalname && item.goalname.toLowerCase().includes(val.toLowerCase())
          || (item.goaldescription && item.goaldescription.toLowerCase().includes(val.toLowerCase()))
          || (item.createdAt && item.createdAt.toLowerCase().includes(val.toLowerCase()))
        ));
    }

    this.setState({ filteredData: fd });

  }


}


export default ManageGoals;
