import React, { useState } from "react";
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../../Common/components/TableSubHeader";
import SDMXMappingModal from "./sDMXMappingModal";

const MappingComponent = (props) => {
    const {bulkows, setBulkRows} = useState([])
    const { state, setState } = useState({
        searchText: '',
        toggledClearRows: true,
        // modal: false,
        isEdit: false,
        loader: false,
        data: [],
        editEntry: []
    });
    const [modal, setModal] = useState(false);

    const handleChange = ( selectedRows ) => {
    console.log(selectedRows)
    
      };    


    ////////////////////==========serach funcation===============///////////////
    const onSearch = (val) => {
        setState({...state, searchText: val});
        let d = state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {            
            fd = d.filter(
                item => true);
        }        
        this.setState({...state, filteredData : fd});
    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
            width:'70px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Associated DSD',
            selector: row => row.associatedDSD,
            sortable: true,
        },
        {
            name: 'Associated MSD',
            selector: row => row.AssociatedMSD,
            sortable: true,
          
        },
        {
            name: 'Date / Time',
            selector: row => row.datetime,
            sortable: true,
            
        },      
        {
            name: 'Action',
            width: "200px",
            cell: row => 
                <>              
                    <div className='action_btns'>
                       <button onClick={(e) => e.preventDefault()}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                       <button onClick={(e)=> e.preventDefault()}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                    </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];
    const data = [
        {
            id: 1,
            name: "text",
            associatedDSD: "cat",
            AssociatedMSD: "",
            datetime: "",
        },
        {
            id: 2,
            name: "text",
            associatedDSD: "cat",
            AssociatedMSD: "",
            datetime: "",
        }
    ];

    // ===================================================================    
        const openAddModal = () => {
        if (modal == false) {
                
                setModal(true)       
            }else{           
                setModal(false)
            }
        }    
    // ===================================================================
    return (
        <>
            <div className='container-fluid areaCont position-relative px-0'>
                <section class="areaSection" id="areaSection">
                    <subHeaderComponentMemo.default
                        onSearch={onSearch}
                        searchText={state?.searchText}
                        // PropDeleteBulk={}
                        value='4'
                        checkData={false}
                        nRows={state?.filteredData?.length}
                    />
                    <DataTable
                        columns={columns}
                        data={data}
                        direction="auto"
                        fixedHeaderScrollHeight="500px"
                        pagination
                        paginationRowsPerPageOptions={[10, 25, 50,100]}
                        style={{ display: "flex" }}
                        persistTableHead
                        // selectableRows
                        // onSelectedRowsChange={handleChange}
                        
                        // clearSelectedRows={state?.toggledClearRows}
                        className='dataTableScroll'
                        noDataComponent="No Data"
                    />
                </section>
            </div>


            <div id="AddAreaModal" className={modal == true ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>
                <div id="openDataModal" onClick={()=>{openAddModal()}} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} >
                    { console.log("AREA") }
                        <img
                            src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
                        </div>
                        <div class="right-tab-heading are_head aaaa">
                        <h2 class="form-heading form-heading">
                            <span class="form-heading_he">
                            <span class="ddf">{state?.isEdit==true ? 'Edit Mapping': 'Add Mapping'}</span>
                            </span>
                        </h2>
                        </div>
                    <SDMXMappingModal
                        // parentCallback={state?.saveAreaData}                    
                        // closeModal={openAddModal()}
                        // sendData={state?.data}
                        // isEdit = {state?.isEdit}
                        // editData = {state?.editEntry}
                    />
                <div className={state?.loader ? 'loader text-center ' : 'loader text-center hide'}>
                    <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
                </div>            
            </div>
            <div className={modal == true ? 'overlay dblock':'overlay'} ></div>
        </>
    );

};

export default MappingComponent;