import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import * as $ from 'jquery';

import './layout.css';
import '../../App.css';

import headerAndFooterJson from '../../Config/headerAndFooter.json'

import LoginForm from '../../Common/components/login-form';


import { useDispatch, useSelector } from 'react-redux';
import eventBus from '../../features/eventBus';
import adminService from '../../services/admin.service';
import BurgerMenu from './burger-menu';

// ========================================
function Header(props) {
    // console.log(props,"props setprops")

    const logClass = 'dropdown-menu log_out_specific logout_box open'
    const [dropClass, changeClasses] = useState(false);


    // const progresss = useSelector((state) => state.header.progress);
    const areaCode = useSelector((state) => state.area.areaCode)
    const headerFormStateAreaName = useSelector((state) => state.formSlice.areaName)

    const dispatch = useDispatch();
    const location = useLocation();
    const route = location.pathname;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [areaExpand, setAreaExpanded] = useState(["ASITLS"]);
    const [, forceUpdate] = useState();
    const [status, setStatus] = useState('');
    const [statusCode, setStatusCode] = useState('');
    const [areaChecked, setAreaChecked] = useState([])
    const [statusColor, setStatusColor] = useState();
    const [allTreeData, setAllTreeData] = useState([]);

    const [headerLocationData, setHeaderLocationData] = useState([]);
    const [userName, setUserName] = useState();
    const [callData, setParentAreaData] = useState([]);
    const [progressEvent, setProgressEvent] = useState()
    const [flatAreas, setFlatArea] = useState([])
    const [userRole, setRole] = useState();
    const [name, setName] = useState();
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    //  var dataMan = '';
    //  const userInfo = localStorage.getItem('userInfo');
    //  var role = null;
    //  if(userInfo) {
    //    role = JSON.parse(userInfo);
    //  } 
    // useEffect(()=>{
    //   console.log('dispacth')
    //   dispatch(setHeaderAreaCode(FormState.pareaCode))
    //   dispatch(setHeaderDisasterCode(changeDisaterCode))
    // },[FormState.pareaCode,changeDisaterCode])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopClose = () => {
        setAnchorEl(null);
    };

    const handleOverviewButtonClick = (e) => {
        e.preventDefault();

        navigate('/dashboard/overview', {
            state: {
                dataToDisplay: headerLocationData,
                goalname: 'Goal 1:'
            }
        });
        // setTimeout(()=> {
        //   eventBus.dispatch("overview", {});
        // }, 1000);
    };

    const handleMetadataButtonClick = (e) => {
        e.preventDefault();

        navigate('/dashboard/metadata', {
            state: {
                dataToDisplay: headerLocationData,
                goalname: 'Goal 1:'
            }
        });
        // setTimeout(()=> {
        //   eventBus.dispatch("metadata", {});
        // }, 1000);
    };


    const getGoals = async () => {
        await adminService.httpGet('/data-retrieval/generic/get-goal-ius').then((res) => {
            if (res) {

                let data = res?.data;

                let dataToDisplay = data?.filter((x) => x?.goalname?.toLowerCase()?.includes('goal 1:'));

                setHeaderLocationData(dataToDisplay);

                // let data1 = dataToDisplay?.flatMap((item) =>
                //     item?.target?.flatMap((x) =>
                //         x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
                //     )
                // );
                console.log("headers power", dataToDisplay);
                // this.setState({
                //     activeIus: {
                //         iusName: data1[0]?.name,
                //         id: data1[0]?._id,
                //         targetId: data1[0]?.targetId,
                //         targetName: data1[0]?.targetName,
                //         metaData: data1[0]?.metadata

                //     }
                // })
                // let filteredData = dataToDisplay
                //     ?.flatMap((item) =>
                //         item?.target?.flatMap((x) =>
                //             x?.ius.map((ius) => {
                //                 const { targetname, targetid } = x; // Destructure targetname and targetid from x
                //                 return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                //             })
                //         )
                //     )
                //     ?.reduce((acc, curr) => {
                //         if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                //             acc.push(curr);
                //         }
                //         return acc;
                //     }, []);

                // this.setState({ loader: false, goalData: data, dataToDisplay: dataToDisplay, searchData: filteredData })

            }

        }).catch((err) => {
            // this.setState({ loader: false })
            setLoading(false);
            console.log(err)
        });
    }

    useEffect(() => {
        getGoals();
    }, [])

    // const handleClose = () => setShow(false);
    // const searchShow = () => setShow(true);

    // ================================================================================
    const [state, setState] = useState({
        bottom: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleDrawerClose = () => {
        setState(false);
    };




    return (
        <>
            <div className={ loading ? 'loader text-center ' : 'loader text-center hide' }>
                <img src="assets/images/Comp-2.gif" style={ { 'marginTop': '12%', 'width': '30%' } } />
            </div>
            <header>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='sdg_logo'>
                                <Link to="/home" activeClassName="active"><img src="assets/lib/images/new-home/tobago_logo.svg" alt="" /></Link>
                            </div>
                            {/* <div className="sdg_modals_btn">
                                <button onClick={handleOverviewButtonClick} className="right_border" data-toggle="modal" data-target="#overview_modal"> <img src="assets/lib/images/new-home/overview.svg" alt="" />Overview</button>
                                <button onClick={handleMetadataButtonClick} className="right_border" data-toggle="modal" data-target="#metadata_modal"> <img src="assets/lib/images/new-home/metadata.svg" alt="" />Metadata </button>
                                <button className="right_border"> <img src="assets/lib/images/new-home/explore.svg" alt="" /><Link to="/dashboard/data-analysis" activeClassName="active">Data Analysis</Link></button>
                                <button> <img src="assets/lib/images/new-home/data-search.svg" alt="" /> <Link to="/dashboard/data-search" activeClassName="active"> Data Search</Link></button>
                            </div> */}
                        </div>
                        <div className='col-8 d-flex justify-content-end'>
                            <div className="menu_area sdg_modals_btn">
                                <Link onClick={ (e) => { handleOverviewButtonClick(e) } } className={ route == '/dashboard/overview' ? 'right_border active' : 'right_border' }> <img src="assets/lib/images/new-home/explore_icon.svg" alt="" />Explore</Link>
                                {/* <Link onClick={(e)=>{handleMetadataButtonClick(e)}} className={route == '/metadata' ? 'right_border active' : 'right_border'} id="metadataModalButton"> <img src="assets/lib/images/new-home/metadata.svg" alt="" />Metadata </Link> */ }
                                <Link to="/dashboard/data-analysis" className={ route == '/dashboard/data-analysis' ? 'right_border data-icon active' : 'right_border' } activeClassName="active "><img src="assets/lib/images/new-home/explore.svg" alt="" /> Data Analysis</Link>
                                <Link to="/dashboard/data-search" className={ route == '/dashboard/data-search' ? 'right_border active' : 'right_border' } activeClassName="active"><img src="assets/lib/images/new-home/data-search.svg" alt="" />  Search Data</Link>
                                <button className="burger_box">
                                    {/* <img src="assets/lib/images/new-home/menu.svg" alt="" /> */ }
                                    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_431_3390)">
                                            <circle cx="39" cy="36" r="30" fill="white" />
                                        </g>
                                        <path d="M25 27H53M25 36H53M25 45H53" stroke="#333" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                                        <defs>
                                            <filter id="filter0_d_431_3390" x="0" y="0" width="78" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="3" />
                                                <feGaussianBlur stdDeviation="4.5" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_431_3390" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_431_3390" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </button>
                                <BurgerMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </header>










            {/* <div className="modal fade login_popup" id="login" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h4 className="modal-title h4" id="exampleModalLabel">
                {headerAndFooterJson?.login?.[seletedLangValue]}
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <img src="assets/lib/images/grey_cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body p-0">
              <LoginForm />
            </div>

          </div>
        </div>
      </div> */}

            {/* =============================================================== Search Popup ========================================================= */ }
            {/* <Modal size="lg" className='search_popup' centered show={searchshow} onHide={handleClose}>
        <Modal.Body>
          <TextField type="search" id="search" placeholder='Search' variant="outlined" />
          <Button variant="secondary" className='searcBtn'>
            <img src='assets/lib/images/home/search.svg' />
          </Button>
        </Modal.Body>
        <Button variant="secondary" className='searcClose' onClick={handleClose}>
          <img src='assets/lib/images/close.svg' />
        </Button>
      </Modal> */}
            {/* =============================================================== Search Popup ========================================================= */ }
            {/* =============================================================== Loader Start ========================================================= */ }
            <div className='loaderImg' style={ { display: 'none' } }>
                <img src='assets/lib/images/home/loader.gif' />
            </div>
            {/* ================================================================ Loader End ========================================================== */ }


        </>

    );
}


export default Header;
