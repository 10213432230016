import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
// ======================================== Checkbox Start
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { Multiselect } from "multiselect-react-dropdown";
// ======================================== Checkbox End
// ======================================== Tree View Start
import PropTypes from 'prop-types';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Select from "react-select";
import MenuItem from '@mui/material/MenuItem';
import AdminService from '../../../../services/admin.service';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import adminService from "../../../../services/admin.service";
import $ from 'jquery';
import { ConstructionOutlined } from "@mui/icons-material";
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);



    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        handleSelection(event);
    };




    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});

CustomContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    displayIcon: PropTypes.node,
    expansionIcon: PropTypes.node,
    icon: PropTypes.node,
    label: PropTypes.node,
    nodeId: PropTypes.string.isRequired,
};

function CustomTreeItem(props) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
}


function AssignIndicatorModal(props) {

    const [searchText, setSearchText] = useState('');
    const [nodalIUS, setNodalIUS] = useState([]);
    const [viewIUS, setViewIUS] = useState([]);


    const [viewSelectedIndicator, setViewSelectedIndicator] = useState(false);

    const viewAssignedHandleChange = (e) => {

        // setViewSelectedIndicator(e.target.checked)

        // setViewAssignedData(props.isAssigned)


    }


    const handleModalClose = () => {
        // Reset search text and default data when modal is closed

    }

    $(document).ready(function () {


        $('.caret').unbind().click(function () {

            $(this).parent().children('.nested').toggleClass('active')
            $(this).toggleClass('caret-down')


        })

    })


    const handleCheckboxChange = (event, value) => {

        const isChecked = event.target.checked;


        if (isChecked) {
            if (props?.isNodalIUS === true) {
                setNodalIUS((prevNodalIUS) => [...prevNodalIUS, value]);
            } else {
                setViewIUS((prevViewIUS) => [...prevViewIUS, value]);
            }
        } 

        else {

            if (props?.isNodalIUS === true) {
                setNodalIUS((prevNodalIUS) => Array(prevNodalIUS)?.filter((item) => item?._id !== value?._id));
                // setNodalIUS((prevNodalIUS) => console.log("nodalIUS HANDLECB-",Array(prevNodalIUS)?.filter((value) => value?._id !== value)));
            } else {
                setViewIUS((prevViewIUS) => Array(prevViewIUS)?.filter((item) => item?._id !== value?._id));
                // setViewIUS((prevViewIUS) => console.log("nodalIUS HANDLECB-fwfjgef", Array(prevViewIUS)?.filter((value) => value?._id !== value)));
            }
        }
    };


    const checkEditNodalIUS = () => {
        console.log("sdhfjkerhvmfne", nodalIUS, "fwfw", viewIUS)
        let selectedIUSIds = props?.editMode?.nodalius?.map((n) => n?.ius_id) || [];
        let data = props?.indicatorData?.filter((x) => selectedIUSIds.includes(x?.iu_id));
      
        console.log("this data represents-", data?.map((item) => item?.name));
      
        if (data?.length === 1) {
          return [
            {
              name: data[0]?.name,
              _id: data[0]?.iu_id
            }
          ];
        } else if (data?.length > 1) {
          return data.map((item) => ({
            name: item?.name,
            _id: item?.iu_id
          }));
        } else {
          return [];
        }
      };
      
      
      
      

      const checkEditViewIUS = () => {
        console.log("in pop up modal-", props.editMode);
        let selectedIUSIds = props?.editMode?.viewius?.map((n) => n?.ius_id) || [];
        let data = props?.indicatorData?.filter((x) => selectedIUSIds.includes(x?.iu_id));
      
        console.log("this data represents-", data?.map((item) => item?.name));
      
        if (data?.length === 1) {
          return [
            {
              name: data[0]?.name,
              _id: data[0]?.iu_id
            }
          ];
        } else if (data?.length > 1) {
          return data.map((item) => ({
            name: item?.name,
            _id: item?.iu_id
          }));
        } else {
          return [];
        }
      };
      

    const selectAll = (event) => {
        const isChecked = event.target.checked;

        // Update the state variables for nodalIUS and viewIUS based on isChecked value
        if (props?.isNodalIUS === true) {
            if (isChecked) {
                let arr =[];
                 props.getGoalTargetData.flatMap((displayData) =>
                    displayData.target.flatMap((targetData) =>
                        targetData.ius.map((iusData) => {
                            arr.push({
                                name: iusData?.name,
                                _id: iusData?._id
                            })
                        })
                    )
                );
                setNodalIUS(arr);
                
            } else {
                setNodalIUS([]);
            }
        } else {
            if (isChecked) {
                const selectedIUSIds = props.getGoalTargetData.flatMap((displayData) =>
                    displayData.target.flatMap((targetData) =>
                        targetData.ius.map((iusData) => iusData._id)
                    )
                );
                setViewIUS(selectedIUSIds);
            } else {
                setViewIUS([]);
            }
        }
    };
    console.log("chewcking state--",nodalIUS)

    const getSelectedIUSCount = () => {
        let selectedIUSIds = [];

        props.getGoalTargetData.forEach((displayData) => {
            displayData.target.forEach((targetData) => {
                targetData.ius.forEach((iusData) => {
                    selectedIUSIds.push(iusData._id);
                    // const isSelected = props.isNodalIUS ? nodalIUS.includes(iusId) : viewIUS.includes(iusId);

                    // if (isSelected) {
                    //     selectedIUSIds.push(iusId);
                    // }
                });
            });
        });
        console.log("selectedIUSIdsselectedIUSIds", selectedIUSIds.length)
        return selectedIUSIds.length;
    };

    const clearAll = (e) => {
        if (props.isNodalIUS) {
            setNodalIUS([]);
        } else {
            setViewIUS([]);
        }
    };

    const viewAssigned = (checked) => {
        // Create an array to store the selected IUS IDs

        let selectedIUSIds = [];

        // Iterate over the data and collect the selected IUS IDs based on the checkbox state
        props.getGoalTargetData.forEach((displayData) => {
            displayData.target.forEach((targetData) => {
                targetData.ius.forEach((iusData) => {
                    const iusId = iusData._id;

               
                    const isSelected = props.isNodalIUS === true ? 
                    nodalIUS?.filter(item=>{
                        return item?._id===iusId;
                    })  :  viewIUS?.filter(view=>{
                        return view?._id===iusId;
                    }) 



                    if (isSelected) {
                        isSelected?.forEach(item=>{
                            selectedIUSIds.push({_id:item._id, name:item.name});
                        })

                    }
                });
            });
        });
       

        // Filter the data based on the selected IUS IDs
        const filteredData = props.getGoalTargetData.filter((displayData) =>
            displayData.target.some((targetData) =>
                targetData.ius.some((iusData) => selectedIUSIds.filter(item=>{ return iusData._id===item._id; }))
            )
        );
        console.log('filteredData', filteredData)



        $(document).ready(function () {


               
        
            $('.caret').parent().children('.nested').toggleClass('active')
            $('.caret').toggleClass('caret-down')


  

    })


        // Return the filtered data
        return filteredData;
    };

    const assignedData = viewAssigned();
    const dataIncomingEditView = checkEditViewIUS();
    const dataIncomingEditNodal = checkEditNodalIUS();

    useEffect(() => {
  if (props?.isEdit) {
            setNodalIUS(dataIncomingEditNodal);
            setViewIUS(dataIncomingEditView);
            props.callBackCheck(dataIncomingEditNodal, dataIncomingEditView);

            console.log("nodaliusss-", dataIncomingEditNodal, "viewiusss", dataIncomingEditView)

            $(document).ready(function () {


               
        
                    $('.caret').parent().children('.nested').toggleClass('active')
                    $('.caret').toggleClass('caret-down')
        
        
          
        
            })

        }
    }, [props.isEdit , props.modal])

    // useEffect(() => {
    //     console.log("checking states-", nodalIUS, 'and', viewIUS);
    //     // props.callBackCheck(nodalIUS, viewIUS);
    // }, [nodalIUS, viewIUS]);

    const sendSelectedData = () =>{
     props.callBackCheck(nodalIUS, viewIUS);


    }


    const onCheckAsigned = () =>{
        viewSelectedIndicator == false ? setViewSelectedIndicator(true) : setViewSelectedIndicator(false)
        $('.caret').parent().children('.nested').toggleClass('active')
        $('.caret').toggleClass('caret-down')
    }

    return (
        <>

            <div className="modal fade timePeriodPopup  datasummary_popup assignindicator assignmodal" id="assignmodal" tabindex="-1" aria-labelledby="assignindicator" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {/* {props.disasterName} */}
                                Select Output Indicator
                            </h5>
                            <button onClick={handleModalClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt=""></img> </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='aisBar pb-1'>

                                <div className="select-area d-flex align-items-center justify-content-between">
                                    <div className="input_checkbox d-flex">
                                        <FormGroup className='viewAssigned'>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={
                                                        props.isNodalIUS
                                                            ? nodalIUS?.length === getSelectedIUSCount()
                                                            : viewIUS?.length === getSelectedIUSCount()
                                                    }
                                                    onChange={(e) => { selectAll(e) }}
                                                />
                                            } label={
                                                <>
                                                    <div className='va_name'>Select All</div>
                                                </>
                                            } />
                                        </FormGroup>
                                        <FormGroup className='viewAssigned'>
                                            <FormControlLabel
                                                control={<Checkbox checked={viewSelectedIndicator} onClick={() => {onCheckAsigned()}} onChange={(e) => { viewAssigned(e.target.checked) }} />}
                                                label={
                                                    <>
                                                        <div className='va_name'>
                                                            View Assigned
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </FormGroup>


                                        <div className="clear_btnarea d-flex align-items-center">
                                            <button onClick={(e) => { clearAll(e) }}> <img src="assets/lib/images/clear.svg" /> Clear All</button>
                                        </div>
                                    </div>


                                    {/* <div className='ai_searchbar'>
                                        <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                        <input onChange={(e) => { onSearch(e) }} value={searchText} id="searchBar" type="search" autoComplete="off" placeholder="Search..." />
                                    </div> */}
                                </div>

                            </div>
                            {
                                viewSelectedIndicator ? (
                                    <div className="indicator_datatable scheme_tree_area ">
                                        <div className="disasterTab">
                                            <div className="disasterList">
                                                <div className="disasterCheckbox">
                                                    <ul id="myUL">
                                                        {assignedData?.map((displayData) => {
                                                            console.log('displayData', displayData)

                                                            return (
                                                                <li key={displayData?.goalname}>
                                                                    <span className="caret">{displayData?.goalname}</span>
                                                                    <ul className="nested">
                                                                        {displayData?.target?.some((targetData) => targetData?.ius?.length > 0) &&
                                                                            displayData?.target?.map((targetData) => {
                                                                                if (targetData?.ius?.length > 0) {
                                                                                    const hasCheckedIusData = targetData?.ius?.map((iusData) => {
                                                                                        const isChecked =
                                                                                            props?.isNodalIUS === true
                                                                                                ? nodalIUS?.filter(item=>{
                                                                                                    return item._id === iusData?._id;
                                                                                                })
                                                                                                : viewIUS?.filter(item=>{
                                                                                                    return item._id === iusData?._id;
                                                                                                })
                                                                                        return isChecked;
                                                                                    });

                                                                                    console.log('hasCheckedIusData', hasCheckedIusData?.[0]?.length)

                                                                                    if (hasCheckedIusData?.[0]?.length=== 0) {
                                                                                        return null; // Skip rendering if no iusData is checked in this targetData
                                                                                    }

                                                                                    return (
                                                                                        <li key={targetData?.targetname}>
                                                                                            <span className="caret">{targetData?.targetname}</span>
                                                                                            <ul className="nested">
                                                                                                {targetData?.ius?.map((iusData) => {
                                                                                                    let value = {"_id": iusData?._id, "name": iusData?.name}
                                                                                                    const isChecked =
                                                                                                        props?.isNodalIUS === true
                                                                                                        ? nodalIUS?.filter(item=>{
                                                                                                            return item._id === iusData?._id;
                                                                                                        })
                                                                                                        : viewIUS?.filter(item=>{
                                                                                                            return item._id === iusData?._id;
                                                                                                        })

                                                                                                    if (isChecked?.length===0) {
                                                                                                        return null; // Skip rendering if iusData is not checked
                                                                                                    }


                                                                                                  
                                                                                                    return (
                                                                                                        <li key={iusData?._id}>
                                                                                                            <span className="caret">{iusData?.name}</span>
                                                                                                            <ul className="nested">
                                                                                                               
                                                                                                                
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="ius"
                                                                                                                    onChange={(e) => {
                                                                                                                        handleCheckboxChange(e, value);
                                                                                                                    }}
                                                                                                                    value={iusData?._id}
                                                                                                                    checked={isChecked?.length > 0? true: false}
                                                                                                                />
                                                                                                                <span className="subgroupname">{iusData?.subgroupname}</span>
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    );
                                                                                                })}
                                                                                            </ul>
                                                                                        </li>
                                                                                    );
                                                                                } else {
                                                                                    return null; // If targetData.ius is empty, skip rendering this section
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                </li>
                                                            );
                                                        })}


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="indicator_datatable scheme_tree_area ">
                                        <div className="disasterTab">
                                            <div className="disasterList">
                                                <div className="disasterCheckbox">
                                                    <ul id="myUL">
                                                        {props.getGoalTargetData?.map((displayData) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <span className="caret">{displayData?.goalname}</span>
                                                                        <ul className="nested">
                                                                            {displayData?.target?.map((targetData) => {
                                                                                return (
                                                                                    <>
                                                                                        <li>
                                                                                            <span className="caret">{targetData?.targetname}</span>
                                                                                            <ul className="nested">
                                                                                                {targetData?.ius?.map((iusData) => {
                                                                                                    let value ={"_id": iusData?._id, "name": iusData?.name}

                                                                                                    let isExist = 0;

                                                                                                    if( props?.isNodalIUS === true){
                                                                                                        isExist =  nodalIUS?.filter(item=>{
                                                                                                            return item?._id === iusData?._id;
                                                                                                        })
    
                                                                                                    }
                                                                                                    else{
                                                                                                        isExist =  viewIUS?.filter(item=>{
                                                                                                            return item?._id === iusData?._id;
                                                                                                        })
                                                                                                    }

                                                                                                  console.log('ischeckedddd', isExist)
                                                                                                   


                                                                                                    return (
                                                                                                        <>
                                                                                                            <li>
                                                                                                                <span className="caret">{iusData?.name}</span>
                                                                                                                <ul className="nested">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="ius"
                                                                                                                        onChange={(e) => { handleCheckboxChange(e,value) }}
                                                                                                                        value={iusData?._id}
                                                                                                                        checked={isExist?.length > 0? true: false}

                                                                                                                       
                                                                                                                    />

                                                                                                                    <span className="subgroupname">{iusData?.subgroupname}</span>
                                                                                                                </ul>
                                                                                                            </li>
                                                                                                        </>
                                                                                                    );
                                                                                                })}
                                                                                            </ul>
                                                                                        </li>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                </>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        <div className="modal-footer justify-content-center py-3">
                            <Button variant="contained" onClick={()=>sendSelectedData()} className="mb-0 text-unset assign_btn" data-dismiss="modal" data-target=""> Select <img class="" src="assets/lib/images/home/thick_arrow.svg"></img></Button>
                        </div>
                    </div >
                </div >
            </div >

        </>
    );
}

export default AssignIndicatorModal;
