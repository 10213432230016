import React from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import { HashLink as Link } from 'react-router-hash-link';
import GeoData from '../geo-data/geo-data.js';
import {resourceImagePath} from '../../../Config/app.constants';
import CountUp, { useCountUp } from 'react-countup';
import aboutjson from '../../../Config/about.json'
import langSlice from '../../../features/langSlice';

var aboutTemplate = function (props) {

    return (
      <>
        <Header />


        <section className='pageBanner' style={{backgroundImage: "url(assets/lib/images/home/about-banner.jpg)"}}>
            <div className='custom_container h-100'>
                <div className='row align-items-end h-100'>
                    <div className='col-md-12'>
                    {/* {props.lang && <h2>Test successful</h2>} */}
                    
                        <h2><strong>{aboutjson.title[this.props.lang]}</strong></h2>
                    </div>
                </div>
            </div>   
        </section>

        <section className='information_system' style={{
          backgroundImage: "url(assets/lib/images/home/bg_bg.jpg)",
        }}>
          <div className='container aboutus_container'>
            <div className='row'>
              <div className='col-md-12 text-center p-0'>
                <h5>{aboutjson.text1[this.props.lang]}</h5>
                {/* <h2>A Circular Economy Approach to Disaster Risk Management</h2> */}
                <h2>{aboutjson.heading[this.props.lang]}</h2>
                <img src="assets/lib/images/curve-line.svg" alt="" className='curve-img' />
                <p>{aboutjson.para[this.props.lang]}</p>
                {/* <p>Timor-Leste is highly vulnerable to climate changes, particularly increasing variability of rainfall and extreme weather events. Lives and livelihoods in the remote interior of the country and coastal regions are both highly exposed. Impacts of intensified extreme events include damage and degradation of decentralized small-scale critical infrastructure, particularly water supply and drainage structures, embankments, and feeder roads and bridges.</p> */}

                <p className='about_secondpara'>
                {aboutjson.para2[this.props.lang]}
                </p>
                <p className='about_secondpara'>
                {aboutjson.para3[this.props.lang]}
                </p>
                <p className='about_secondpara'>
                {aboutjson.para4[this.props.lang]}
                </p>
                <p className='about_secondpara list-title'>
                {aboutjson.para5[this.props.lang]}
                </p>
                <ul className='about-list'>
                  <li>{aboutjson.para6[this.props.lang]}</li>
                  <li>{aboutjson.para7[this.props.lang]}</li>
                  <li>{aboutjson.para8[this.props.lang]}</li>
                  <li>{aboutjson.para9[this.props.lang]}</li>
                  <li>{aboutjson.para10[this.props.lang]}</li>
                  <li>{aboutjson.para11[this.props.lang]}</li>
                  <li>{aboutjson.para12[this.props.lang]}</li>
                </ul>
                {/* <p className='about_secondpara'>
                Damages leave rural populations isolated, lacking basic services. In response to this challenge, in 2019, the Government of Timor-Leste (GoTL) with the support of UNDP received funding from the Green Climate Fund (GCF) to implement the “Safeguarding rural communities and their physical assets from climate induced disasters in Timor-Leste” project from 2020 to 2026. The project is represented by the Secretariat of State of Environment (SSE), under the Coordinating Minister of Economic Affairs, as the Implementing Partner with the main responsible parties/ministries being the Ministry of State Administration (MSA), Ministry of Agriculture and Fisheries, Ministry of Public Works (MoPW) and the Secretary of State for Civil Protection (SSCP).
                </p> */}
              </div>
            </div>
          </div>
        </section>
        
        <Footer />
      </>
    );


};


export default aboutTemplate;