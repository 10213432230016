import { Component } from 'react'
import './home.css';
import * as jsx from './home.module.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";
import withRouter from '../../../services/withRouter';
import eventBus from '../../../features/eventBus';

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            overviewModal: false,
            metadataModal: false,
            dataToDisplay: [],
            fecData: [],
            resData: [],
            searchData: [],
            keyData: [],
            loader: false,
            data: [],
            goalName: {
                goal: 'Goal 1:',
                outerSVG: 'assets/lib/images/new-home/sdg1.svg',
                innerSVG: 'assets/lib/images/innerSDG1.svg'
            },
            goalData: [],
            activeColor: 0,
            activeIus: {
                iusName: '',
                id: '',
                targetId: '',
                targetName: '',
                metaData: null
            },
            metadataDisplay: {},
            play: true,
            targetAndIusCount: {
                target: null,
                ius: null
            }
        }


        this.countUpRefs = [];
        this.starts = [];
    }


    render() {
        return jsx.default.bind(this)();
    }





    handleOpenOverviewModal = () => {
        // Code to open the Overview modal
        this.setState({ overviewModal: true, metadataModal: false, play: false });
    };

    handleOpenMetadataModal = () => {
        // Code to open the Metadata modal
        this.setState({ overviewModal: false, metadataModal: true, play: false });
    };



    componentDidMount() {
        $('body').removeClass('src_list');
        this.getGoals();

        // this.getResource();
        // this.getFacilities();
        // this.getkeyFact();

        eventBus.on('overview', () => {
            this.setState({ overviewModal: true, metadataModal: false, play: false });
        });
        eventBus.on('metadata', () => {
            this.setState({ overviewModal: false, metadataModal: true, play: false });
        });



        //   subscribeToEvent('openOverviewModal', this.handleOpenOverviewModal());
        //   subscribeToEvent('openMetadataModal', this.handleOpenMetadataModal());




        $(document).ready(function () {
            var react = function () {

                var classes = ['x1', 'x2', 'x3', 'x4', 'x5', 'x6', 'x7', 'x8', 'x9', 'x10', 'x11', 'x12', 'x13', 'x14', 'x15', 'x16', 'x17'];

                var random = [];

                for (var a = classes, i = a.length; i--;) {
                    random.push(a.splice(Math.floor(Math.random() * (i + 1)), 1)[0]);
                    //  $(this).addClass( random);
                }


                $('img.bubble').each(function (i) {
                    let that = this
                    console.log(random[i])
                    random.map((e) => {
                        $(this).removeClass(e)
                    })
                    $(this).addClass(random[i]);


                    // clearInterval(abc)
                });
            }


            var abc = setInterval(
                react, 30000
            );


        })

    }

    // componentWillUnmount() {
    //     unsubscribeFromEvent('openOverviewModal', this.handleOpenOverviewModal());
    //     unsubscribeFromEvent('openMetadataModal', this.handleOpenMetadataModal());
    //   }

    handleNavigation = (e) => {
        e.preventDefault();
        console.log("clicked once");

        const { navigate } = this.props;

        navigate('/dashboard/data-analysis', {
            state: {
                goalTargetData: this.state.goalData,
                dataToDisplay: this.state.dataToDisplay,
                goalname: this.state.goalName.goal,
            }
        });
    };

    overViewNavigation = (e) => {
        e.preventDefault();
        const { navigate } = this.props;

        navigate('/dashboard/overview', {
            state: {
                goalTargetData: this.state.goalData,
                dataToDisplay: this.state.dataToDisplay,
                goalname: this.state.goalName.goal,
            }
        })

    }

    arrowNavigationToExplore = (e) => {
        e.preventDefault();
        const { navigate } = this.props;

        navigate('/dashboard/overview', {
            state: {
                goalTargetData: this.state.goalData,
                dataToDisplay: this.state.dataToDisplay,
                goalname: this.state.goalName.goal,
            }
        })
    }

    metadataNavigation = (e) => {
        e.preventDefault();
        const { navigate } = this.props;

        navigate('/dashboard/metadata', {
            state: {
                goalTargetData: this.state.goalData,
                dataToDisplay: this.state.dataToDisplay,
                goalname: this.state.goalName.goal,
            }
        })

    }


    sendActiveIndex = (active) => {
        console.log('home', active)
        this.setState({ activeColor: active })
    }


    wheelCallback = (goal) => {
        // console.log("Harshit", goal);
        // this.setState();

        let iusSet = new Set();

        let dataToDisplay = this.state.goalData?.filter((x) =>
            x?.goalname?.toLowerCase()?.includes(goal?.goal?.toLowerCase())
        );
        
        //   let data = dataToDisplay?.flatMap((item) =>
        //     item?.target?.flatMap((x) => x?.ius.map((ius) => ius))
        //   );
        
        let data = dataToDisplay?.flatMap((item) =>
        item?.target?.flatMap((x) =>
        x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
        )
        );
        let dataToGo = this.state.goalData?.filter((x) =>
        x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
        );
        
        let filteredData = dataToGo
        ?.flatMap((item) =>
        item?.target?.flatMap((x) =>
        x?.ius.map((ius) => {
            const { targetname, targetid } = x; // Destructure targetname and targetid from x
                        return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                    })
                    )
                    )
                    ?.reduce((acc, curr) => {
                        if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);
                    
                    for(let i = 0; i < data?.length; i++){
                        if(!iusSet.has(data[i]?.name)){
                            iusSet.add(data[i]?.name);
                        }
                    }
                    console.log("Harshit dtd", data?.length, "dataaaa-", data, 'iusSet--', iusSet, "sizeee-" , iusSet?.size);
        // this.setState({ searchData: filteredData })

        if (data) {
            console.log("dataooooo-", data)
            this.setState({
                goalName: goal,
                dataToDisplay: dataToDisplay,
                searchData: filteredData,
                targetAndIusCount: {
                    target: dataToDisplay[0]?.target?.length,
                    ius: iusSet?.size
                },
                activeIus: {
                    iusName: data[0]?.name,
                    id: data[0]?._id,
                    targetId: data[0]?.targetId,
                    targetName: data[0]?.targetName,
                    metaData: data[0]?.metadata?.[data[0]?.metadata?.length - 1]
                }
            });
        } else {
            this.setState({
                goalName: '',
                dataToDisplay: dataToDisplay,
                searchData: filteredData,
                targetAndIusCount: {
                    target: dataToDisplay[0]?.target?.length,
                    ius: iusSet?.size
                },
                activeIus: {
                    iusName: '',
                    id: '',
                    targetId: '',
                    targetName: '',
                    metaData: null
                }
            })
        }
    }

    stopWheel = () => {
        this.setState({ play: false, overviewModal: true, metadataModal: true });
    }

    startWheel = () => {
        this.setState({ play: true, overviewModal: false, metadataModal: false });
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState?.goalName?.goal !== this.state.goalName?.goal) {
    //         let dataToDisplay = this.state.goalData?.filter((x) =>
    //             x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
    //         );

    //         //   let data = dataToDisplay?.flatMap((item) =>
    //         //     item?.target?.flatMap((x) => x?.ius.map((ius) => ius))
    //         //   );

    //         let data = dataToDisplay?.flatMap((item) =>
    //             item?.target?.flatMap((x) =>
    //                 x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
    //             )
    //         );

    //         console.log("dataaaaapooo",)

    //         let dataToGo = this.state.goalData?.filter((x) =>
    //             x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
    //         );

    //         console.log("datatogoooo-", dataToGo)

    //         let filteredData = dataToGo
    //             ?.flatMap((item) =>
    //                 item?.target?.flatMap((x) =>
    //                     x?.ius.map((ius) => {
    //                         const { targetname, targetid } = x; // Destructure targetname and targetid from x
    //                         return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
    //                     })
    //                 )
    //             )
    //             ?.reduce((acc, curr) => {
    //                 if (!acc.some((item) => item.ius._id === curr.ius._id)) {
    //                     acc.push(curr);
    //                 }
    //                 return acc;
    //             }, []);

    //             console.log("kkkkkkkkppp", filteredData)

    //         this.setState({ searchData: filteredData })
    //         console.log("filtereddataacom", filteredData)
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevState?.goalName?.goal !== this.state.goalName?.goal) {
            let dataToDisplay = this.state.goalData?.filter((x) =>
                x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
            );

            let data = dataToDisplay?.flatMap((item) =>
                item?.target?.flatMap((x) =>
                    x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
                )
            );

            console.log("dataaaaapooo", data); // Add the 'data' variable to the console log

            let dataToGo = this.state.goalData?.filter((x) =>
                x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
            );

            console.log("datatogoooo-", dataToGo); // Add the 'dataToGo' variable to the console log

            let filteredData = dataToGo
                ?.flatMap((item) =>
                    item?.target?.flatMap((x) =>
                        x?.ius.map((ius) => {
                            const { targetname, targetid } = x; // Destructure targetname and targetid from x
                            return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                        })
                    )
                )
                ?.reduce((acc, curr) => {
                    if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);


            this.setState({ searchData: filteredData });
            console.log("filtereddataacom", filteredData);
        }
        if (prevState.overviewModal !== this.state.overviewModal && this.state.overviewModal) {
            let el = document.getElementById('overview_modal');
            el.click();
            $('#overview_modal').addClass("show");
            $('#overview_modal').css('display', 'block');

        }
        if (prevState.metadataModal !== this.state.metadataModal && this.state.metadataModal) {
            let el = document.getElementById('metadata_modal');
            el?.click();
            $('#metadata_modal').addClass("show");
            $('#metadata_modal').css('display', 'block');

        }
    }



    selectIUS = (iusName, _id, targetid, targetname, metadata) => {
        this.setState({
            activeIus: {
                'iusName': iusName,
                'id': _id,
                'targetId': targetid,
                'targetName': targetname,
                'metaData': metadata
            }
        })
    };

    handleModalClose = () => {
        // Reset the search input field when the modal is closed
        const searchInput = document.getElementById('metadata_search');
        if (searchInput) {
            searchInput.value = '';
        }

        let dataToDisplay = this.state.goalData?.filter((x) =>
            x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
        );

        let filteredData = dataToDisplay
            ?.flatMap((item) =>
                item?.target?.flatMap((x) =>
                    x?.ius.map((ius) => {
                        const { targetname, targetid } = x; // Destructure targetname and targetid from x
                        return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                    })
                )
            )
            ?.reduce((acc, curr) => {
                if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);

        this.setState({ searchData: filteredData })
    };

    onSearch = (e) => {
        let key = e.target.value.toLowerCase();

        let dataToDisplay = this.state.goalData?.filter((x) =>
            x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase())
        );

        let filteredData = dataToDisplay
            ?.flatMap((item) =>
                item?.target?.flatMap((x) =>
                    x?.ius.map((ius) => {
                        const { targetname, targetid } = x; // Destructure targetname and targetid from x
                        return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                    })
                )
            )
            ?.reduce((acc, curr) => {
                if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);

        let searchData = filteredData?.filter((ius) =>
            ius?.ius?.name?.toLowerCase()?.includes(key)
        );

        this.setState({ searchData });
    };

    getGoals() {
        this.setState({ loader: true });
        AdminService.httpGet('/data-retrieval/generic/get-goal-ius').then((res) => {
            if (res) {

                let iusSet = new Set();
                let data = res?.data;

                let dataToDisplay = data?.filter((x) => x?.goalname?.toLowerCase()?.includes(this.state.goalName?.goal?.toLowerCase()));

                let data1 = dataToDisplay?.flatMap((item) =>
                    item?.target?.flatMap((x) =>
                        x?.ius.map((ius) => ({ ...ius, targetId: x?.targetid, targetName: x?.targetname }))
                    )
                );

                let filteredData = dataToDisplay
                    ?.flatMap((item) =>
                        item?.target?.flatMap((x) =>
                            x?.ius.map((ius) => {
                                const { targetname, targetid } = x; // Destructure targetname and targetid from x
                                return { targetname, targetid, ius }; // Return an object containing targetname, targetid, and ius
                            })
                        )
                    )
                    ?.reduce((acc, curr) => {
                        if (!acc.some((item) => item.ius._id === curr.ius._id)) {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);


                    for(let i = 0; i < data1?.length; i++){
                        if(!iusSet.has(data1[i]?.name)){
                            iusSet.add(data1[i]?.name);
                        }
                    }

                this.setState({
                    loader: false, goalData: data, dataToDisplay: dataToDisplay, searchData: filteredData, activeIus: {
                        iusName: data1[0]?.name,
                        id: data1[0]?._id,
                        targetId: data1[0]?.targetId,
                        targetName: data1[0]?.targetName,
                        metaData: data1[0]?.metadata
                    },
                    targetAndIusCount: {
                        target: dataToDisplay[0]?.target?.length,
                        ius: iusSet?.size
                    }
                })

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }

}




function mapStateToProps(state) {
    return {
        lang: state.lang?.lang
    }
}

const connectedMapState = connect(mapStateToProps)(HomePage);

export default withRouter(connectedMapState);