import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SenderReceiverComponent from "./senderReceiverComponent";
import CodeListComponent from "./codeListComponent";
import ExportProgressComponent from "./exportProgressComponent";
import "./export-SDMX.css";
import { Stack, Button } from "react-bootstrap";
import adminService from "../../../../services/admin.service";
import * as constants from '../../../../Config/app.constants';
import $ from 'jquery'

const ExportSDMX = (props) => {
    const [senderId,setSenderId] = useState('')
    const [receiverId,setReceiverId] = useState('')
    const [allChecked,setAllChecked] = useState(false)
    const [selectedChecked,setSelectedCheked] = useState(false)
    const [dataChecked, setDataChecked] = useState(false);
    const [metaDataChecked,setMetaDataChecked] =  useState(false)
    const [dsdChecked,setDsdChecked] = useState(false)
    const [msdChecked,setMsdChecked] =useState(false)
    const [indicatorChecked,setIndicatorChecked] = useState(false)
    const [unitChecked,setUnitChecked] = useState(false)
    const [SubgroupChecked,setSubgroupChecked] = useState(false)
    const [subgroupDimensionChecked,setSubgroupDimensionChecked] = useState(false)
    const [areachecked,setAreaChecked] = useState(false)
    const [sourceChecked,setSourceChecked] = useState(false)
    const [timeperiodChecked,setTimeperiodChecked] = useState(false)
    const [loader, setLoader] = useState(false);

    const [dataProgress,setDataProgress] = useState(0)
    const [metaDataProgress,setMetaDataProgress] = useState(0)
    const [dsdProgress,setDsdProgress] = useState(0)
    const [msdProgress,setMsdProgress] = useState(0)
    const [indicatorProgress,setIndicatorProgress] = useState(0)
    const [unitProgress,setUnitprogress] = useState(0)
    const [subgroupProgress,setSubgroupProgress] = useState(0)
    const [subgroupDimensionProgress,setSubgroupDimensionProgress] = useState(0)
    const [areaProgress,setAreaProgress] = useState(0)
    const [sourceProgress,setSourceProgress] = useState(0)
    const [timeperiodProgress,setTimeperiodProgress] = useState(0)

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
    const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    const todaysDate = String(currentYear) + String(currentMonth) + String(currentDate);

    var rece_sdmx_id = "";
    var rece_sdmx_name = "";
    var rece_sdmx_phone = "";
    var rece_sdmx_email = "";
    var rece_sdmx_department = "";
    var rece_sdmx_role = "";
    var rece_sdmx_fax = "";

    var send_sdmx_id = "";
    var send_sdmx_name = "";
    var send_sdmx_phone = "";
    var send_sdmx_email = "";
    var send_sdmx_department = "";
    var send_sdmx_role = "";
    var send_sdmx_fax = "";

console.log("objectobject",props);
    const [currentTab, setCurrentTab] = useState(0);   
    const onchangeTab = (key) => {
        setCurrentTab(key)
    }
    const prev = () => {
    let prv = parseInt( currentTab - 1);
        if (prv >= 0) {
            setCurrentTab(prv)   
        }        
    }
    const next = () => {
        let prv = parseInt( currentTab + 1);
        if (prv <= 2) {
            setCurrentTab(prv)
        }        
    }

    const getData = (dataChecked,allChecked,selectedChecked,metaDataChecked,dsdChecked,msdChecked,indicatorChecked,unitChecked,SubgroupChecked,subgroupDimensionChecked,areachecked,sourceChecked,timeperiodChecked) => {
        console.log('getdata',allChecked)
        setAllChecked(allChecked)
        setSelectedCheked(selectedChecked)
        setDataChecked(dataChecked)
        setMetaDataChecked(metaDataChecked)
        setDsdChecked(dsdChecked)
        setMsdChecked(msdChecked)
        setIndicatorChecked(indicatorChecked)
        setUnitChecked(unitChecked)
        setSubgroupChecked(SubgroupChecked)
        setSubgroupDimensionChecked(subgroupDimensionChecked)
        setAreaChecked(areachecked)
        setSourceChecked(sourceChecked)
        setTimeperiodChecked(timeperiodChecked)
    }

    const groupBy = (value)=>{

      return value.indicator + '#' + value.unit + '#' + value.subgroup + '#' + value.area_code;

    };

    const handleExport = async (e) => {
        console.log('handleexport',senderId.length,receiverId.length)
        console.log('checked',dataChecked,metaDataChecked,dsdChecked,msdChecked,indicatorChecked,unitChecked,SubgroupChecked,subgroupDimensionChecked,areachecked,sourceChecked,timeperiodChecked)
        if(dataChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${9}/${undefined}`).then((res) => {
                e.preventDefault();
                rece_sdmx_id = "";
                rece_sdmx_name = "";
                rece_sdmx_phone = "";
                rece_sdmx_email = "";
                rece_sdmx_department = "";
                rece_sdmx_role = "";
                rece_sdmx_fax = "";

                send_sdmx_id = "";
                send_sdmx_name = "";
                send_sdmx_phone = "";
                send_sdmx_email = "";
                send_sdmx_department = "";
                send_sdmx_role = "";
                send_sdmx_fax = "";
               let randomNumber = Math.floor(Math.random() * 100000000) + 1;
               let allDataList

                if (res) {
                  
                  let d = res;
                  if(d?.success == true){
                    setLoader(false);


              var totalAllData = 0;

              // var groups =  groupBy(res.data)

              // for (var key1 in groups) {

              //   totalAllData++;

              // }
              // var allDataCount = 0;


              // for (var key in groups) {


              //   allDataCount++;

              //   var allDataProgress = (allDataCount * 100) / totalAllData;


              //   if (groups.hasOwnProperty(key)) {

              //     let iusDeatil = key.split("#");



              //     allDataList += '<sts:Series INDICATOR="' + iusDeatil[0] + '" UNIT="' + iusDeatil[1] + '" SUBGROUP="' + iusDeatil[2] + '" AREA="' + iusDeatil[3] + '">';

              //     groups[key].forEach((ele) => {


              //       allDataList += '<sts:Obs OBS_VALUE="' + ele.value + '" TIMEPERIOD="' + ele.time_period + '" SOURCE="' + ele.source + '"  FOOTNOTES="0"/>';


              //     })

              //     allDataList += '</sts:Series>';
              //     allDataList += '\n';



              //   }

              //   $("#alldataprogress").css("width", allDataProgress + "%");

              //   $("#alldataprogress").html(allDataProgress + "%");


              // }
              // // $("#alldataprogress").css("width", "100%");

              // // $("#alldataprogress").html("100%");

              // $("#alldatastatus").addClass("fa-check-square-o");

              // //$("#alldatastatus").removeClass("fa-times-circle");

              // $("#alldataprogress").removeClass("progress-bar-striped");





              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:StructureSpecificData xmlns:sts="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/sts" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                                <message:Header>
                                   <message:ID>`+ randomNumber + `</message:ID>
                                    <message:Test>true</message:Test>
                                    <message:Prepared>2019-10-22T06:15:35+05:30</message:Prepared>
                                   <message:Sender id="`+ send_sdmx_id + `">
                                    <message:Contact>
                                      <message:Name>`+ send_sdmx_name + `</message:Name>
                                      <message:Department>`+ send_sdmx_department + `</message:Department>
                                      <message:Role>`+ send_sdmx_role + `</message:Role>
                                      <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                      <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                      <message:Email>`+ send_sdmx_email + `</message:Email>
                                    </message:Contact>
                                    </message:Sender>
                                    <message:Receiver id="`+ rece_sdmx_id + `">
                                    <message:Contact>
                                      <message:Name>`+ rece_sdmx_name + `</message:Name>
                                      <message:Department>`+ rece_sdmx_department + `</message:Department>
                                      <message:Role>`+ rece_sdmx_role + `</message:Role>
                                      <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                      <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                      <message:Email>`+ rece_sdmx_email + `</message:Email>
                                    </message:Contact>
                                    </message:Receiver>
                                    <message:Structure structureID="DS_iTechMission" dimensionAtObservation="TIMEPERIOD" namespace="testURI:compact">
                                    <common:Structure>
                                    <Ref id="DS_iTechMission" agencyID="iTechMission" version="6.0"/>
                                    </common:Structure>
                                    </message:Structure>
                                </message:Header>
                                <sts:DataSet dataScope="DataStructure" structureRef="DS_iTechMission">
                                  `+ allDataList + `
                                </sts:DataSet>
                              </message:StructureSpecificData>`;
              var date = new Date();
              var current_date = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate() + '_' + date.getHours() + '' + date.getMinutes();

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Data_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();

              $("#alldataprogress").css("width", "100%");
              $("#alldataprogress").html("100%");

              $("#alldataprogress").css("width", "100%");

              $("#alldataprogress").html("100%");

              };
                  }
          
                 
                setDataProgress(100)
          
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(senderId.length !== 0){
          await adminService.httpGet('/data-sdmx/sdmx/get-sender-info/' + senderId).then((res) => {
            console.log('sender',res)
            send_sdmx_id = res.data.sender_id;
            send_sdmx_name = res.data.contact_name;
            send_sdmx_phone = res.data.telephone;
            send_sdmx_email = res.data.email;
            send_sdmx_department = res.data.department;
            send_sdmx_role = res.data.role;
            send_sdmx_fax = res.data.fax;

        }).catch((err) => {
          setLoader(false);
          console.log(err)
        })
        }

        if(receiverId.length !== 0){
          await adminService.httpGet('/data-sdmx/sdmx/get-receiver-info/' + receiverId).then((res) => {
            console.log('sender',res)
            rece_sdmx_id = res.data.receiver_id;
            rece_sdmx_name = res.data.contact_name;
            rece_sdmx_phone = res.data.telephone;
            rece_sdmx_email = res.data.email;
            rece_sdmx_department = res.data.department;
            rece_sdmx_role = res.data.role;
            rece_sdmx_fax = res.data.fax;

        }).catch((err) => {
          setLoader(false);
          console.log(err)
        })
        }

        if(metaDataChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${12}/${undefined}`).then((res) => {
                e.preventDefault();

                 rece_sdmx_id = "";
                 rece_sdmx_name = "";
                 rece_sdmx_phone = "";
                 rece_sdmx_email = "";
                 rece_sdmx_department = "";
                 rece_sdmx_role = "";
                 rece_sdmx_fax = "";

                 send_sdmx_id = "";
                 send_sdmx_name = "";
                 send_sdmx_phone = "";
                 send_sdmx_email = "";
                 send_sdmx_department = "";
                 send_sdmx_role = "";
                 send_sdmx_fax = "";
                let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                let metaDataIU = "";

                if (res) {
                  if (res.data.length > 0) {
                    res.data.forEach((ele) => {
    
    console.log('ele',ele?.name?.en,ele?.unit?.en)
                      metaDataIU += '<structure:Metadata indicator_id="' + ele.sdmx_indicator_gid + '" unit_id="' + ele.sdmx_unit_gid + '">';
                      metaDataIU += '<common:Indicator xml:lang="en">' + ele?.name?.en + '</common:Indicator>';
                      metaDataIU += '<common:Unit xml:lang="en">' + ele?.unit?.en + '</common:Unit>';
    
                      if(ele.metadata!=undefined){
                      metaDataIU += '<metadata>';
                      metaDataIU += '<Category name="Indicator Definition"><para>' + ele.metadata.mt1 + '</para></Category>';
                      metaDataIU += '<Category name="Method of Computation"><para>' + ele.metadata.mt2 + '</para></Category>';
                      metaDataIU += '<Category name="Overview"><para>' + ele.metadata.mt3 + '</para></Category>';
                      metaDataIU += '<Category name="Comments and Limitations"><para>' + ele.metadata.mt4 + '</para></Category>';
                      metaDataIU += '<Category name="Data Collection for Global Monitoring"><para>' + ele.metadata.mt5 + '</para></Category>';
                      metaDataIU += '<Category name="Obtaining Data"><para>' + ele.metadata.mt6 + '</para></Category>';
                      metaDataIU += '<Category name="Data Availability"><para>' + ele.metadata.mt7 + '</para></Category>';
                      metaDataIU += '<Category name="Treatment of Missing Values"><para>' + ele.metadata.mt8 + '</para></Category>';
                      metaDataIU += '<Category name="Regional and Global Estimates"><para>' + ele.metadata.mt9 + '</para></Category>';
    
                      metaDataIU += '</metadata>';
    
                      }else{
    
                          metaDataIU += '<metadata>';
                          metaDataIU += '<Category name="Indicator Definition"><para></para></Category>';
                          metaDataIU += '<Category name="Method of Computation"><para></para></Category>';
                          metaDataIU += '<Category name="Overview"><para></para></Category>';
                          metaDataIU += '<Category name="Comments and Limitations"><para></para></Category>';
                          metaDataIU += '<Category name="Data Collection for Global Monitoring"><para></para></Category>';
                          metaDataIU += '<Category name="Obtaining Data"><para></para></Category>';
                          metaDataIU += '<Category name="Data Availability"><para></para></Category>';
                          metaDataIU += '<Category name="Treatment of Missing Values"><para></para></Category>';
                          metaDataIU += '<Category name="Regional and Global Estimates"><para></para></Category>';
    
                          metaDataIU += '</metadata>';
    
                        
                      }
    
                      metaDataIU += '</structure:Metadata>';
    
                    });
    
    
                  }
    
    
                    let metaDataString = ""
    
                  metaDataString += `<?xml version="1.0" encoding="utf-8"?>
                        <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                          
                          <message:Header>
                           <message:ID>`+ randomNumber + `</message:ID>
                            <message:Test>true</message:Test>
                            <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                            <message:Sender id="`+ send_sdmx_id + `">
                              <message:Contact>
                                <message:Name>`+ send_sdmx_name + `</message:Name>
                                <message:Department>`+ send_sdmx_department + `</message:Department>
                                <message:Role>`+ send_sdmx_role + `</message:Role>
                                <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                <message:Email>`+ send_sdmx_email + `</message:Email>
                              </message:Contact>
                            </message:Sender>
                            <message:Receiver id="`+ rece_sdmx_id + `">
                              <message:Contact>
                                <message:Name>`+ rece_sdmx_name + `</message:Name>
                                <message:Department>`+ rece_sdmx_department + `</message:Department>
                                <message:Role>`+ rece_sdmx_role + `</message:Role>
                                <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                <message:Email>`+ rece_sdmx_email + `</message:Email>
                              </message:Contact>
                            </message:Receiver>
                          </message:Header>
                        
                         <message:Structures>
                            <structure:Metadatas>
                             `+ metaDataIU + `
                            </structure:Metadatas>
                          </message:Structures>
                        </message:Structure>`
    
                  var pom = document.createElement('a');
                  var filename = "CDP4OSI_metadata.xml";
                  var pom = document.createElement('a');
                  var bb = new Blob([metaDataString], { type: 'text/xml' });
                  pom.setAttribute('href', window.URL.createObjectURL(bb));
                  pom.setAttribute('download', filename);
                  pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
                  pom.draggable = true;
                  pom.classList.add('dragout');
                  pom.click();
                  $("#metadataprogress").html("100%");
                  $("#metadataprogress").css("width", "100%");
                
                
                setMetaDataProgress(100)
              }}).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(dsdChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${10}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                 rece_sdmx_name = "";
                 rece_sdmx_phone = "";
                 rece_sdmx_email = "";
                 rece_sdmx_department = "";
                 rece_sdmx_role = "";
                 rece_sdmx_fax = "";

                 send_sdmx_id = "";
                 send_sdmx_name = "";
                 send_sdmx_phone = "";
                 send_sdmx_email = "";
                 send_sdmx_department = "";
                 send_sdmx_role = "";
                 send_sdmx_fax = "";
                let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                let allDsdList

                if (res) {
                if (res.data.area.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_AREA" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Area</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Areas</common:Description>';


                  res.data.area.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_area_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.name + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });


                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.timeperiod.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_TIMEPERIOD" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Timeperiod</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Timeperiods</common:Description>';

                  res.data.timeperiod.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_start_time_period_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.timePeriod + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });


                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.source.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_SOURCE" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Source</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Sources</common:Description>';


                  res.data.source.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_source_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.source + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });


                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.indicator.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_INDICATOR" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Indicator</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Indicators</common:Description>';


                  res.data.indicator.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_indicator_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.indicator + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });



                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.unit.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_UNIT" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Unit</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Units</common:Description>';

                  res.data.unit.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_unit_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.unit + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';

                    allDsdList += '\n';

                  });



                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.subgroupDimension.length > 0) {


                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_SUBGROUPDIMENSION" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Subgroup Dimension</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Subgroup Dimensions</common:Description>';

                  res.data.subgroupDimension.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_subgroup_dimension_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.name + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });


                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

                if (res.data.subgroup.length > 0) {

                  allDsdList += '<message:Structures>';
                  allDsdList += '<structure:Codelists>';
                  allDsdList += '<structure:Codelist id="CL_SUBGROUP" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">';
                  allDsdList += '<common:Name xml:lang="en">Subgroup</common:Name>';
                  allDsdList += '<common:Description xml:lang="en">List of Subgroups</common:Description>';

                  res.data.subgroup.forEach((ele) => {

                    allDsdList += '<structure:Code id="' + ele.sdmx_subgroup_gid + '"  uri="">';
                    allDsdList += '<common:Annotations>';
                    allDsdList += '<common:Annotation>';
                    allDsdList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                    allDsdList += '<common:AnnotationText>True</common:AnnotationText></common:Annotation>';
                    allDsdList += '</common:Annotations><common:Name xml:lang="en">' + ele.name + '</common:Name >';
                    allDsdList += '<structure:Parent><Ref id=""/></structure:Parent>';
                    allDsdList += '</structure:Code>';
                    allDsdList += '\n';
                  });

                  allDsdList += '</structure:Codelist>';
                  allDsdList += '</structure:Codelists>';
                  allDsdList += '</message:Structures>';

                }

              }

              $("#dsdprogress").css("width", "100%");

              $("#dsdprogress").html("100%");

              $("#dsdstatus").addClass("fa-check-square-o");

              //$("#dsdstatus").removeClass("fa-times-circle");

              $("#dsdprogress").removeClass("progress-bar-striped");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                              <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                                
                                <message:Header>
                                  <message:ID>`+ randomNumber + `</message:ID>
                                  <message:Test>true</message:Test>
                                  <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                                  <message:Sender id="`+ send_sdmx_id + `">
                                    <message:Contact>
                                      <message:Name>`+ send_sdmx_name + `</message:Name>
                                      <message:Department>`+ send_sdmx_department + `</message:Department>
                                      <message:Role>`+ send_sdmx_role + `</message:Role>
                                      <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                      <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                      <message:Email>`+ send_sdmx_email + `</message:Email>
                                    </message:Contact>
                                  </message:Sender>
                                  <message:Receiver id="`+ rece_sdmx_id + `">
                                    <message:Contact>
                                      <message:Name>`+ rece_sdmx_name + `</message:Name>
                                      <message:Department>`+ rece_sdmx_department + `</message:Department>
                                      <message:Role>`+ rece_sdmx_role + `</message:Role>
                                      <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                      <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                      <message:Email>`+ rece_sdmx_email + `</message:Email>
                                    </message:Contact>
                                  </message:Receiver>
                                </message:Header>
                                `+ allDsdList + `
                               
                              </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_DSD.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();

              setDsdProgress(100)
          
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(msdChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${11}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                 rece_sdmx_name = "";
                 rece_sdmx_phone = "";
                 rece_sdmx_email = "";
                 rece_sdmx_department = "";
                 rece_sdmx_role = "";
                 rece_sdmx_fax = "";

                 send_sdmx_id = "";
                 send_sdmx_name = "";
                 send_sdmx_phone = "";
                 send_sdmx_email = "";
                 send_sdmx_department = "";
                 send_sdmx_role = "";
                 send_sdmx_fax = "";
               
                if (res) {
                  
              let msdData = "";

              let metaData = "";

              $("#msdprogress").html("Fetching data from server..");

              $("#msdprogress").css("width", "30%");

              res.data.forEach((ele) => {

                metaData += '<Concept id="' + ele.sdmx_code + '" agencyID="IAEG">';
                metaData += '<Name>' + ele.name + '</Name>';
                metaData += ' <Description></Description>';
                metaData += '<TextFormat textType="String"/>';
                metaData += '</Concept>';

              });

              console.log(metaData);

              msdData += `<?xml version="1.0" encoding="utf-8"?>
                        <Structure xmlns="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/message" xmlns:common="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/common" xmlns:compact="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/compact" xmlns:cross="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/cross" xmlns:generic="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/generic" xmlns:query="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/query" xmlns:structure="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/structure" xmlns:utility="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/utility" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/message SDMXMessage.xsd">
                          <Header>
                            <ID>CountryData_MSD_1_0</ID>
                            <Test>true</Test>
                            <Truncated>false</Truncated>
                            <Name xml:lang="en">Metadata Structure Definition for CountryData</Name>
                            <Prepared>2011-10-19T15:15:15-05:00</Prepared>


                            <Sender id="`+ send_sdmx_id + `">
                              <Name xml:lang="en">`+ send_sdmx_name + `</Name>
                              <Contact>
                                <Name xml:lang="en">`+ send_sdmx_name + `</Name>
                                <Department xml:lang="en">`+ send_sdmx_department + `</Department>
                                <Role xml:lang="en">`+ send_sdmx_role + `</Role>
                                <Telephone>`+ send_sdmx_phone + `</Telephone>
                                <Fax>`+ send_sdmx_fax + `</Fax>
                                <Email>`+ send_sdmx_email + `</Email>
                              </Contact>
                            </Sender>
                            <Receiver id="`+ rece_sdmx_id + `">
                              <Name xml:lang="en">`+ rece_sdmx_name + `</Name>
                              <Contact>
                                <Name xml:lang="en">`+ rece_sdmx_name + `</Name>
                                <Department xml:lang="en">`+ rece_sdmx_department + `</Department>
                                <Role xml:lang="en">`+ rece_sdmx_role + `</Role>
                                <Telephone>`+ rece_sdmx_phone + `</Telephone>
                                <Fax>`+ rece_sdmx_fax + `</Fax>
                                <Email>`+ rece_sdmx_email + `</Email>
                              </Contact>
                            </Receiver>


                          </Header>
                          <CodeLists/>
                          <Concepts>

                            <ConceptScheme xmlns="http://www.SDMX.org/resources/SDMXML/schemas/v2_0/structure" id="MDG_CONCEPTS" agencyID="IAEG" version="1.0">
                              <Name>MDG Metadata Concepts</Name>
                              `+ metaData + `
                           </ConceptScheme>
                          </Concepts>
                        </Structure>`;

              var pom = document.createElement('a');
              var filename = "CDP4OSI_msd.xml";
              var pom = document.createElement('a');
              var bb = new Blob([msdData], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
              $("#msdprogress").html("100%");
              $("#msdprogress").css("width", "100%");

          setMsdProgress(100)
              }}).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(indicatorChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${5}/${undefined}`).then((res) => {
                e.preventDefault();
                if (res) {
                  
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;

              let sourceData = res.data;
                let subgroupList;
              let subgroupCount = 0;

              let totalSubgroup = res.data.length;

              sourceData.forEach((ele) => {
                console.log('eleele',ele)
                subgroupCount++;

                let subgroupProgress = (subgroupCount * 100) / totalSubgroup;



                subgroupList += '<structure:Code id="' + ele.sdmx_subgroup_gid + '"  uri="">';
                subgroupList += '<common:Annotations>';
                subgroupList += '<common:Annotation>';
                subgroupList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                subgroupList += '<common:AnnotationText>True</common:AnnotationText>';
                subgroupList += '</common:Annotation>';
                subgroupList += '</common:Annotations>';
                subgroupList += '<common:Name xml:lang="en">' + ele.name + '</common:Name >';
                subgroupList += '<structure:Parent>';
                subgroupList += '<Ref id=""/>';
                subgroupList += '</structure:Parent>';
                subgroupList += '</structure:Code>';
                subgroupList += '\n';



                $("#subgroupprogress").css("width", subgroupProgress + "%");

                $("#subgroupprogress").html(subgroupProgress + "%");


              });



              $("#subgroupstatus").addClass("fa-check-square-o");

              //$("#subgroupstatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                              <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                             <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_SUBGROUP" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Subgroup</common:Name>
                                  <common:Description xml:lang="en">List of Subgroups</common:Description>
                                  `+ subgroupList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Indictor_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();

                setIndicatorProgress(100)
              }}).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(unitChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${2}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let unitList
                  
                if (res) {
                  
              var unitData = res.data;

              let unitCount = 0;

              var totalUnit = res.data.length;

              unitData.forEach((ele) => {

                unitCount++;

                var unitProgress = (unitCount * 100) / totalUnit;

                if (ele.sdmx_unit_gid != 'undefined') {

                  unitList += '<structure:Code id="' + ele.sdmx_unit_gid + '"  uri="">';
                  unitList += '<common:Annotations>';
                  unitList += '<common:Annotation>';
                  unitList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                  unitList += '<common:AnnotationText>True</common:AnnotationText>';
                  unitList += '</common:Annotation>';
                  unitList += '</common:Annotations>';
                  unitList += '<common:Name xml:lang="en">' + ele.unit + '</common:Name >';
                  unitList += '<structure:Parent>';
                  unitList += '<Ref id=""/>';
                  unitList += '</structure:Parent>';
                  unitList += '</structure:Code>';
                  unitList += '\n';

                }

                $("#unitprogress").css("width", unitProgress + "%");

                $("#unitprogress").html(unitProgress + "%");

              });




              $("#unitstatus").addClass("fa-check-square-o");

              //$("#unitstatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                      <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                        
                        <message:Header>
                          <message:ID>`+ randomNumber + `</message:ID>
                          <message:Test>true</message:Test>
                          <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                          <message:Sender id="`+ send_sdmx_id + `">
                            <message:Contact>
                              <message:Name>`+ send_sdmx_name + `</message:Name>
                              <message:Department>`+ send_sdmx_department + `</message:Department>
                              <message:Role>`+ send_sdmx_role + `</message:Role>
                              <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                              <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                              <message:Email>`+ send_sdmx_email + `</message:Email>
                            </message:Contact>
                          </message:Sender>
                          <message:Receiver id="`+ rece_sdmx_id + `">
                            <message:Contact>
                              <message:Name>`+ rece_sdmx_name + `</message:Name>
                              <message:Department>`+ rece_sdmx_department + `</message:Department>
                              <message:Role>`+ rece_sdmx_role + `</message:Role>
                              <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                              <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                              <message:Email>`+ rece_sdmx_email + `</message:Email>
                            </message:Contact>
                          </message:Receiver>
                        </message:Header>
                      
                       <message:Structures>
                          <structure:Codelists>
                            <structure:Codelist id="CL_UNIT" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                              <common:Name xml:lang="en">Unit</common:Name>
                              <common:Description xml:lang="en">List of Units</common:Description>
                              `+ unitList + `
                        </structure:Codelist>
                          </structure:Codelists>
                        </message:Structures>
                      </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Unit_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                setUnitprogress(100)
              }}).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(SubgroupChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${3}/${undefined}`).then((res) => {
                e.preventDefault();
                if (res) {
                   rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let subgroupList

              var sourceData = res.data;

              let subgroupCount = 0;

              var totalSubgroup = res.data.length;

              sourceData.forEach((ele) => {

                subgroupCount++;

                var subgroupProgress = (subgroupCount * 100) / totalSubgroup;



                subgroupList += '<structure:Code id="' + ele.sdmx_subgroup_gid + '"  uri="">';
                subgroupList += '<common:Annotations>';
                subgroupList += '<common:Annotation>';
                subgroupList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                subgroupList += '<common:AnnotationText>True</common:AnnotationText>';
                subgroupList += '</common:Annotation>';
                subgroupList += '</common:Annotations>';
                subgroupList += '<common:Name xml:lang="en">' + ele.name + '</common:Name >';
                subgroupList += '<structure:Parent>';
                subgroupList += '<Ref id=""/>';
                subgroupList += '</structure:Parent>';
                subgroupList += '</structure:Code>';
                subgroupList += '\n';



                $("#subgroupprogress").css("width", subgroupProgress + "%");

                $("#subgroupprogress").html(subgroupProgress + "%");


              });



              $("#subgroupstatus").addClass("fa-check-square-o");

              //$("#subgroupstatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                              <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                             <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_SUBGROUP" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Subgroup</common:Name>
                                  <common:Description xml:lang="en">List of Subgroups</common:Description>
                                  `+ subgroupList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Subgroup_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                setSubgroupProgress(100)
              }}).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(subgroupDimensionChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${6}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let subgroupDimensionList

                if (res) {
                  
              var sourceData = res.data;

              let dimensionCount = 0;

              var totalDimension = res.data.length;

              sourceData.forEach((ele) => {

                dimensionCount++;

                var dimensionProgress = (dimensionCount * 100) / totalDimension;



                subgroupDimensionList += '<structure:Code id="' + ele.sdmx_subgroup_dimension_gid + '"  uri="">';
                subgroupDimensionList += '<common:Annotations>';
                subgroupDimensionList += '<common:Annotation>';
                subgroupDimensionList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                subgroupDimensionList += '<common:AnnotationText>True</common:AnnotationText>';
                subgroupDimensionList += '</common:Annotation>';
                subgroupDimensionList += '</common:Annotations>';
                subgroupDimensionList += '<common:Name xml:lang="en">' + ele.name + '</common:Name >';
                subgroupDimensionList += '<structure:Parent>';
                subgroupDimensionList += '<Ref id=""/>';
                subgroupDimensionList += '</structure:Parent>';
                subgroupDimensionList += '</structure:Code>';
                subgroupDimensionList += '\n';



                $("#subgroupdimensionprogress").css("width", dimensionProgress + "%");

                $("#subgroupdimensionprogress").html(dimensionProgress + "%");

              });




              $("#subgroupdimensionstatus").addClass("fa-check-square-o");

              //$("#subgroupdimensionstatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                              <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                             <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_SUBGROUPDIMENSION" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Subgroup Dimension</common:Name>
                                  <common:Description xml:lang="en">List of Subgroup Dimensions</common:Description>
                                  `+ subgroupDimensionList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Dimension_SDMX-ML.xml";

              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                }
                setSubgroupDimensionProgress(100)
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(areachecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${4}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let areaList

                if (res) {
                  
              var areaData = res.data;

              let areaCount = 0;

              areaData.forEach((ele) => {

                areaCount++;

                var totalArea = res.data.length;

                var areaProgress = (areaCount * 100) / totalArea;

                if (ele.sdmx_area_gid != 'undefined') {

                  areaList += '<structure:Code id="' + ele.sdmx_area_gid + '"  uri="">';
                  areaList += '<common:Annotations>';
                  areaList += '<common:Annotation>';
                  areaList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                  areaList += '<common:AnnotationText>True</common:AnnotationText>';
                  areaList += '</common:Annotation>';
                  areaList += '</common:Annotations>';
                  areaList += '<common:Name xml:lang="en">' + ele.name + '</common:Name >';
                  // areaList += '</common:Name >';
                  areaList += '<structure:Parent>';
                  areaList += '<Ref id=""/>';
                  areaList += '</structure:Parent>';
                  areaList += '</structure:Code>';
                  areaList += '\n';



                }

                $("#areaprogress").css("width", areaProgress + "%");

                $("#areaprogress").html(areaProgress + "%");


              });

              // $("#areaprogress").css("width", "100%");

              // $("#areaprogress").html("100%");

              $("#areastatus").addClass("fa-check-square-o");

              //$("#areastatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                              <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                               <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                              <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_AREA" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Area</common:Name>
                                  <common:Description xml:lang="en">List of Areas</common:Description>
                                  `+ areaList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`;



              var pom = document.createElement('a');
              var filename = "CDP4OSI_Area_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                }
                setAreaProgress(100)
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(sourceChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${7}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let sourceList

                if (res) {
                  
              var sourceData = res.data;

              let sourceCount = 0;

              var totalSource = res.data.length;

              sourceData.forEach((ele) => {

                sourceCount++;

                var sourceProgress = (sourceCount * 100) / totalSource;


                sourceList += '<structure:Code id="' + ele.sdmx_source_gid + '"  uri="">';
                sourceList += '<common:Annotations>';
                sourceList += '<common:Annotation>';
                sourceList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                sourceList += '<common:AnnotationText>True</common:AnnotationText>';
                sourceList += '</common:Annotation>';
                sourceList += '</common:Annotations>';
                sourceList += '<common:Name xml:lang="en">' + ele.source + '</common:Name >';
                sourceList += '<structure:Parent>';
                sourceList += '<Ref id=""/>';
                sourceList += '</structure:Parent>';
                sourceList += '</structure:Code>';
                sourceList += '\n';



                $("#sourceprogress").css("width", sourceProgress + "%");

                $("#sourceprogress").html(sourceProgress + "%");


              });



              $("#sourcestatus").addClass("fa-check-square-o");

              //$("#sourcestatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                             <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                              <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_SOURCE" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Source</common:Name>
                                  <common:Description xml:lang="en">List of Sources</common:Description>
                                  `+ sourceList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Source_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                }
                setSourceProgress(100)
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }

        if(timeperiodChecked){
            await adminService.httpGet(`/data-sdmx/sdmx/exportData/${8}/${undefined}`).then((res) => {
                e.preventDefault();
                 rece_sdmx_id = "";
                   rece_sdmx_name = "";
                   rece_sdmx_phone = "";
                   rece_sdmx_email = "";
                   rece_sdmx_department = "";
                   rece_sdmx_role = "";
                   rece_sdmx_fax = "";

                   send_sdmx_id = "";
                   send_sdmx_name = "";
                   send_sdmx_phone = "";
                   send_sdmx_email = "";
                   send_sdmx_department = "";
                   send_sdmx_role = "";
                   send_sdmx_fax = "";
                  let randomNumber = Math.floor(Math.random() * 100000000) + 1;
                  let timeperiodList

                if (res) {
                  
              var timeData = res.data;

              let timeCount = 0;

              var totalTime = res.data.length;

              timeData.forEach((ele) => {

                timeCount++;

                var timeProgress = (timeCount * 100) / totalTime;



                timeperiodList += '<structure:Code id="' + ele.sdmx_start_time_period_gid + '"  uri="">';
                timeperiodList += '<common:Annotations>';
                timeperiodList += '<common:Annotation>';
                timeperiodList += '<common:AnnotationTitle>IsGlobal</common:AnnotationTitle>';
                timeperiodList += '<common:AnnotationText>True</common:AnnotationText>';
                timeperiodList += '</common:Annotation>';
                timeperiodList += '</common:Annotations>';
                timeperiodList += '<common:Name xml:lang="en">' + ele.timePeriod + '</common:Name >';
                timeperiodList += '<structure:Parent>';
                timeperiodList += '<Ref id=""/>';
                timeperiodList += '</structure:Parent>';
                timeperiodList += '</structure:Code>';
                timeperiodList += '\n';


                $("#timeprogress").css("width", timeProgress + "%");

                $("#timeprogress").html(timeProgress + "%");

              });



              $("#timestatus").addClass("fa-check-square-o");

              //$("#timestatus").removeClass("fa-times-circle");

              let xmlString = `<?xml version="1.0" encoding="utf-8"?>
                          <message:Structure xmlns:structure="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/structure" xmlns:common="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/common" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:message="http://www.sdmx.org/resources/sdmxml/schemas/v2_1/message">
                            
                            <message:Header>
                             <message:ID>`+ randomNumber + `</message:ID>
                              <message:Test>true</message:Test>
                              <message:Prepared>2019-10-22T06:12:03+05:30</message:Prepared>
                              <message:Sender id="`+ send_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ send_sdmx_name + `</message:Name>
                                  <message:Department>`+ send_sdmx_department + `</message:Department>
                                  <message:Role>`+ send_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ send_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ send_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ send_sdmx_email + `</message:Email>
                                </message:Contact>
                              </message:Sender>
                              <message:Receiver id="`+ rece_sdmx_id + `">
                                <message:Contact>
                                  <message:Name>`+ rece_sdmx_name + `</message:Name>
                                  <message:Department>`+ rece_sdmx_department + `</message:Department>
                                  <message:Role>`+ rece_sdmx_role + `</message:Role>
                                  <message:Telephone>`+ rece_sdmx_phone + `</message:Telephone>
                                  <message:Fax>`+ rece_sdmx_fax + `</message:Fax>
                                  <message:Email>`+ rece_sdmx_email + `</message:Email>
                                </message:Contact>

                              </message:Receiver>
                            </message:Header>
                          
                           <message:Structures>
                              <structure:Codelists>
                                <structure:Codelist id="CL_TIMEPERIOD" agencyID="agency" version="6.0" isPartial="true" isExternalReference="false">
                                  <common:Name xml:lang="en">Timeperiod</common:Name>
                                  <common:Description xml:lang="en">List of Timeperiods</common:Description>
                                  `+ timeperiodList + `
                            </structure:Codelist>
                              </structure:Codelists>
                            </message:Structures>
                          </message:Structure>`

              var pom = document.createElement('a');
              var filename = "CDP4OSI_Timeperiod_SDMX-ML.xml";
              var pom = document.createElement('a');
              var bb = new Blob([xmlString], { type: 'text/xml' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
                }
                setTimeperiodProgress(100)
              }).catch((err) => {
                setLoader(false);
                console.log(err)
              })
        }
        setAllChecked(false)
        setSelectedCheked(false)
        setDataChecked(false)
        setMetaDataChecked(false)
        setDsdChecked(false)
        setMsdChecked(false)
        setIndicatorChecked(false)
        setUnitChecked(false)
        setSubgroupChecked(false)
        setSubgroupDimensionChecked(false)
        setAreaChecked(false)
        setSourceChecked(false)
        setTimeperiodChecked(false)
        
    }

    useEffect(()=>{

    },[allChecked,selectedChecked,dataChecked,metaDataChecked,dsdChecked,msdChecked,indicatorChecked,unitChecked,SubgroupChecked,subgroupDimensionChecked,areachecked,sourceChecked,timeperiodChecked])
    
    const getSenderId = (id) => {
      console.log('getSenderReceiverData',id)
      setSenderId(id)
    }

    const getReceiverId = (id) => {
      console.log('getSenderReceiverData',id)
      setReceiverId(id)
    }

    return (
        <>
          { loader == true && <div className={loader == true ? 'loader text-center ' : 'loader text-center hide'}>
            <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
          </div>}
          <section className="areaSection export-sdmx sdmxMapping main" id="areaSection">
              <Tabs activeKey={currentTab} id="sdmxmapping" onSelect={(key) => onchangeTab(key)} className="mb-3 sdmx-tab">
                  <Tab eventKey="0" title={<><span>Step 01</span> <h4>Sender/Receiver</h4></>}>
                      <SenderReceiverComponent getSender={getSenderId} getReceiver={getReceiverId}></SenderReceiverComponent>
                  </Tab>
                  <Tab eventKey="1" title={<><span>Step 02</span> <h4>Select</h4></>}>
                      <CodeListComponent func={getData} uncheck={{allChecked,selectedChecked,dataChecked,metaDataChecked,dsdChecked,msdChecked,indicatorChecked,unitChecked,SubgroupChecked,subgroupDimensionChecked,areachecked,sourceChecked,timeperiodChecked}}></CodeListComponent>
                  </Tab>
                  <Tab eventKey="2" title={<><span>Step 03</span> <h4>Export</h4></>}>
                      <ExportProgressComponent progress={{dataProgress,metaDataProgress,indicatorProgress,unitProgress,subgroupProgress,subgroupDimensionProgress,areaProgress,sourceProgress,timeperiodProgress,dsdProgress,msdProgress}}></ExportProgressComponent>
                  </Tab>
              </Tabs>                 
              <Stack gap={3} direction="horizontal" className="tabNavs">
                  <Button
                  className="success previous"
                  disabled={parseInt(currentTab) === 0}
                  onClick={() => prev()}
                  >
                  <img src="assets/lib/images/icon/btn_arrow.svg" /> Back
                  </Button>
                  <Button
                  className="export success"
                  disabled={parseInt(currentTab) === 1}
                  onClick={(e) =>{next(); handleExport(e)} }
                  >
                  Export
                  </Button>
                  <Button
                  className="success"
                  disabled={parseInt(currentTab) === 2}
                  onClick={() =>next() }
                  >
                  Next <img src="assets/lib/images/icon/btn_arrow.svg" />
                  </Button>
              </Stack>
          </section>
        </>
    );

};

export default ExportSDMX;