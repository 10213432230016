import React, { Component } from "react";
import "./resources.css";
import * as jsx from "./resources.module.jsx";
import * as js from "../../Modals/resources.modal.js";

import * as $ from "jquery";
import { data } from "jquery";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import dayjs from "dayjs";
import AdminService from "../../../services/admin.service";
import Tooltip from "@mui/material/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
class Resources extends Component {
  arr = ["dxgf"];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      isEdit: false,
      editModeId: null,
      modal: false,
      loader: false,
      dataLimit: 10,
      dataStatus: 2,
      indicatorData: [],
      rowIndData: [],
      subIndData: [],
      areaData: [],
      bulkDelete: [],
      editEntry: [],
    };
  }

  componentDidMount() {
    this.getData();
    
    AdminService.checkToken();
  }

  render() {
    return jsx.default.bind(this)();
  }

  // update data limit===================//

  updateDataLimit = (e) => {
    this.setState({ dataLimit: e.target.value });
    this.getData();
  };



  getDataByStatus = (e) => {
    this.setState({ dataStatus: e.target.value });
    this.getData();
  };

  // filter table and get data====================//

  onSearch = (val) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    let fd = null;
    if (val == "") {
      fd = d;
    } else {
      fd = d.filter(
        (item) =>
          (item.title &&
            item.title["en"].toLowerCase().includes(val.toLowerCase())) ||
          (item.description &&
            item.description["en"].toLowerCase().includes(val.toLowerCase())) ||
          (item.type && item.type.toLowerCase().includes(val.toLowerCase())) ||
          (item.category &&
            item.category.toLowerCase().includes(val.toLowerCase()))
      );
    }

    this.setState({ filteredData: fd });
  };

  sortWithToolTip = (rowA, rowB) => {
    const a = rowA?.title?.en;
    const b = rowB?.description?.en;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  columns = [
    // {
    //   name: "#",
    //   selector: (row, index) =>
    //     isNaN(row.index) ? `${index + 1}` : row.index + 1,

    //   sortable: false,
    //   width: "50px",
    // },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => (
        <Tooltip title={row?.title?.en} placement="top">
          <p>{row?.title?.en}</p>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: this.sortWithToolTip,
    },
    {
      name: "Description",
      selector: (row) => (
        <Tooltip title={row?.description?.en} placement="top">
          <p>{row?.description?.en}</p>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: this.sortWithToolTip,
    },
    {
      name: "Date",
      // selector: row => row.createdAt,

      selector: (row) => dayjs(row.createdAt).format("YYYY-MM-DD"),

      sortable: true,
      width: "300px",
    },

    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },

    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div className="action_btns">
            <button
              onClick={(e) => {
                this.editDataEntry(row._id);
              }}
            >
              {" "}
              <img src="assets/lib/images/icon/edit_icon.svg" />
            </button>
            <button
              onClick={(e) => {
                this.deleteEntry(row._id);
              }}
            >
              {" "}
              <img src="assets/lib/images/icon/delete_icon.svg" />
            </button>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  editDataEntry = (id) => {
    const edData = [];
    this.state.filteredData.forEach((item) => {
      if (item._id == id) {
        edData.push(item);
        return;
      }
    });

    this.setState({ editEntry: edData });
    this.setState({ isEdit: true });
    this.openAddModal();
  };

  getData = async () => {
    this.setState({ loader: true, searchText: "" });
    const dataToBeSend = {};

    AdminService.httpGet(
      "/data-retrieval/resources/get-all-resources",
      dataToBeSend
    )
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res?.data;
          d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          this.setState({ filteredData: d, data: d });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  // save data===========================//

  saveData = async (e) => {
    if (e.id != null) {
      this.updateEntry(e);
    } else {
      this.addNewEntry(e);
    }
  };

  updateEntry = async (e) => {
    const dataToBe2 = {
      id: e.id,
      description: e.description,
      title: e.title,
      url: e.url,
      type: e.type,
      doc: e.document,
      image: e.image,
      category: e.category,
    };

    AdminService.httpPut("/data-import/resources/update/" + e.id, dataToBe2)
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;

          console.log(res);
          if (res.success == true) {
            toast.success("Resource updated successfully");
            this.openAddModal();
            this.getData();
            this.setState({ isEdit: false });

            this.setState({ editEntry: [] });
          } else {
            toast.error(res.message);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  // add new entry=============================///

  addNewEntry = async (e) => {
    console.log("dten", e);
    this.setState({ loader: true });
    const dataToBeSend = {
      description: e.description,
      title: e.title,
      url: e.url,
      type: e.type,
      doc: e.document,
      image: e.image,
      category: e.category,
    };

    AdminService.httpPost("/data-import/resources/add-resource", dataToBeSend)
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;

          if (res.success == true) {
            toast.success("Resource Added successfully");
            this.openAddModal();
            this.getData();
          } else {
            toast.error(res.message);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  // update status==================//
  changeDataStatus(e, id, status) {
    this.setState({ loader: true });
    let changed = 0;

    if (status == 1) {
      changed = 0;
    } else {
      changed = 1;
    }

    let statusData = { id: id, resStatus: changed };

    AdminService.httpPost("/data-import/resources/update-status", statusData)
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res;

          console.log(res);

          if (res.success == true) {
            toast.success("Resource status updated successfully");
            this.getData();
          } else {
            toast.error("Failed to update status");
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  }

  // delete entry===========//

  deleteEntry = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      // this.setState({ loader: true });
      if (willDelete) {
        const dataToBeSend = {
          id: id,
        };

        AdminService.httpPost(
          "/data-import/resources/delete-resource",
          dataToBeSend
        )
          .then((res) => {
            if (res) {
              let d = res;
              this.setState({ loader: false });

              if (res.success == true) {
                toast.success("Resource deleted successfully");
                this.getData();
              } else {
                toast.error(res.message);
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  // open add modal=====================//

  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
      this.setState({ isEdit: false });

      this.setState({ editEntry: [] });
    }
  };

  enableDis = (e, value) => {
    this.setState({ loader: true });
    console.log("e--" + e);
    if (value == 0) {
      value = 1;
    } else {
      value = 0;
    }

    const dataToBeSend = {
      id: e,
      resStatus: value,
    };

    AdminService.httpPost("/data-import/resources/update-status", dataToBeSend)
      .then((res) => {
        if (res) {
          let d = res.data;
          
          this.getData(this.state.dataStatus);

          if (res.message == "Status  successfully updated!") {
            toast.success("Status  successfully updated");
          } else {
            toast.error("Failed to update status ");
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  ///multiple delete

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id);
    });

    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            _id: ids.toString(),
          };
          AdminService.httpPost(
            "/data-import/resources/delete-multiple-resource",
            data
          )
            .then((res) => {
              if (res) {
                this.setState({ loader: false });
                let d = res;

                console.log(res);

                if (res.success == true) {
                  toast.success("Resource deleted successfully");
                  this.getData();
                } else {
                  toast.error("Failed to delete resource");
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });

      return false;
    }
  };
}

export default Resources;
