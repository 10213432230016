import React, { useEffect, Component } from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import adminService from '../../../services/admin.service';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import contactJson from '../../../Config/contact.json'


var contactTemplate = function () {

    const handleChange = (event) => {
        
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loader: true });

        const data = {
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.email,
            message: this.state.message,
            contact: this.state.contactNumber
        }

        adminService.httpUserPost('/data-import/users/contact-us', data).then((res) => {

            if (res.success === true) {
                this.setState({ loader: true })
                swal(
                    `${contactJson?.['message-sent']?.[this.props.lang]}`,
                    `${contactJson?.['received-enquiry']?.[this.props.lang]}`,
                    "success"
                ).then((ok) => {
                    if (ok) {
                        this.setState({
                            loader: true,
                            firstName: "",
                            lastName: "",
                            email: "",
                            message: "",
                            contactNumber: ''
                        })
                    }
                })
            } else {
                toast.error(res.message)
            }

        }).catch(error => {
            toast.error(error.message)
        })
    }

    return (
        <>
            <Header />
            <section className='pageBanner' style={{ backgroundImage: "url(assets/lib/images/home/contact_us.jpg)" }}>
                <div className='custom_container h-100'>
                    <div className='row align-items-end h-100'>
                        <div className='col-md-12'>
                            {/* <h2>Contact Us</h2> */}
                            <h2>Contact</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contact_form' style={{ backgroundImage: "url(assets/lib/images/home/bg_bg.jpg)" }}>
                <div className='custom_container'>
                    <div className='row justify-content-between'>
                        <div className='col-md-6'>
                            <div className='cf_form'>
                                {/* <h3>Contact</h3> */}
                                <h3>{contactJson?.contact[this.props.lang]}</h3>
                                <div className="client_details">
                                    <div className="detail-area">
                                        <h2>Customer support</h2>
                                        <p>Visit <span><a href='mailto:faine.richards@un.org'>faine.richards@un.org</a></span> </p>
                                    </div>
                                    <div className="detail-area">
                                        <h2>General inquiries</h2>
                                        <p>Call  <span><a href='tel:+18686237056'>+1 868-623-7056</a></span> </p>
                                    </div>
                                    <div className="detail-area">
                                        <h2>Address</h2>
                                        <p>UN House, 3A Chancery Lane, PO Box 812, Port of Spain, Trinidad and Tobago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 pl-45'>
                            <div className='cf_form'>
                                <h3>Please fill your details</h3>
                                <form action='' onSubmit={handleSubmit} method='POST'>
                                    <div className='cf_form_list'>
                                        <div className="contact_fields d-flex align-items-center gap-40">
                                            <div className='cfl_form'>
                                                {/* <label>Name</label> */}
                                                <label>First name</label>
                                                <TextField onChange={handleChange} value={this.state.firstName} id="firstName" name="firstName" variant="outlined" required />
                                            </div>
                                            <div className='cfl_form'>
                                                {/* <label>Email Address</label> */}
                                                <label>Last name</label>
                                                <TextField onChange={handleChange} value={this.state.lastName} id="lastName" type="name" name="lastName" variant="outlined" required />
                                            </div>
                                        </div>
                                        <div className="contact_fields d-flex align-items-center gap-40">
                                            <div className='cfl_form'>
                                                {/* <label>Name</label> */}
                                                <label>Email</label>
                                                <TextField onChange={handleChange} value={this.state.email} id="email" name="email" variant="outlined" required />
                                            </div>
                                            <div className='cfl_form'>
                                                {/* <label>Email Address</label> */}
                                                <label>Contact number</label>
                                                <TextField onChange={handleChange} value={this.state.contactNumber} id="contactNumber" type="tel" name="contactNumber" variant="outlined" required />
                                            </div>
                                        </div>
                                        <div className='cfl_form w-100'>
                                            {/* <label>Enquiry Message</label> */}
                                            <label>Message</label>
                                            <TextField type='text' onChange={handleChange} value={this.state.message} id="message" name="message" multiline rows={6} variant="outlined" required />
                                        </div>
                                        <div className='cfl_form w-100 text-center'>
                                            {/* <Button type='submit' variant="contained">Submit <img src='assets/lib/images/home/arrow_right.svg' /></Button> */}
                                            <Button type='submit' variant="contained">{contactJson?.['submit-btn'][this.props.lang]} <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );


};


export default contactTemplate;
