import { Component } from 'react'
import './manage-form.css';
import * as jsx from './manage-form.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import swal from 'sweetalert';
import Tooltip from '@mui/material/Tooltip';
import { CSVLink } from "react-csv";
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import Dropdown from 'react-bootstrap/Dropdown';
class ManageForm extends Component {
    arr = ["dxgf"]
    defSurveryID = ""
    constructor(props) {
        super(props);
        this.formId = ''
        this.state = {
            rowIdForPending:'',
            rowForCompleted:'',
            showFormModal: false,
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit: false,
            editEntry: [],
            modal: false,
            loader: false,
            profileData: [],
            // setValue:dayjs('2014-08-18T21:11:54')
            setOpen: false,
            openTab: 'Pending',
            alignment: this.role == 1 ? 'Pending' : 'Pending',
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData: false,
            disscrollableModal: false,
            editModeId: null,
            modal: false,
            remarkdata: '',
            loader: false,
            dataLimit: 10,
            areaData: [],
            age: 10,
            formName: '',
            tbStatus: 0,
            gridStatus: 1,
            dataStatus: 2,
            statusData: [
                { "label": "Pending", "value": 2 },
                { "label": "Approved", "value": 1 },
                { "label": "Disapproved", "value": 3 }
            ],
            copyForm: null,
            viewMode:false,
            mainViewData: [],
            formId: '',
            disasterList: [],
            formList: [],
          
            dataEntryUsers: [],
            dataApproveUser: [],
            editModeData: null,
            dataEntryData: null,
            activeRow:null,
            areaForView:null,

            // open:false,
            dataEntryQuestion: null,
            dataEnteryOptions: null,
            dataentryFormData: null,
            datasummaryFormData: null,
            dataEntryAnswers: null,
            summaryData: [],
            dataForValidation: [],
            activeSurvay: '',
            dataValidatationName:'',
            assigneduserData: { users: [], areas: [] },
            aggregatedData: [],
            headersForAggregatedData: [
                { label: "AreaID", key: "AreaID" },
                { label: "AreaName", key: "AreaName" },
                { label: "Timeperiod", key: "Timeperiod" },
                { label: "Source", key: "Source" },
                { label: "Indicator", key: "Indicator" },
                { label: "PlanName", key: "PlanName" },
                { label: "Subgroup", key: "Subgroup" },
                { label: "DataValue", key: "DataValue" },
                { label: "DisasterName", key: "DisasterCode" },
                { label: "Footnote", key: "Footnote" },

            ],
            assignedAreaToUserData:{},
            fileNameFormate: "TERS_AGGREGATED_DATA",
            viewData:(id) => {

                this.setState({ loader: true })
                console.log('id:',id)
        
        
                AdminService.httpGet('/data-retrieval/survey/getsurveyView/' + id).then((res) => {
                    if (res) {
        
                        let d = res.survey?.[0];
                        console.log('rows', d.name.en)
        
                        this.setState({ mainViewData: d, loader: false })
        
                      
        
                    }
        
                }).catch((err) => {
                    this.setState({ loader: false })
                    console.log(err)
                });
        
        
            },
            openDataEntryModal:(row,household) => {
                console.log('household',household)
                this.setState({ loader: true })
                // console.log("row: ", this.defSurveryID, row)
                this.state.rowIdForPending = row._id
                let date = new Date()
                let dtObj = {
                    day: date.getUTCDate(),
                    month: date.toLocaleString('en', { month: 'short' }),
                    year: date.getFullYear()
                }
        
                let currObj = {
                    date: dtObj.day + ' ' + dtObj.month + ' ' + dtObj.year,
                    name: row.name?.en
                }
                this.setState({ dataentryFormData: currObj })
        
                AdminService.httpGet('/data-retrieval/option/get-option').then((res) => {
                    if (res) {
        
                        let optionData = res.data
                        console.log("dd", optionData)
                        // dataEnteryOptions
                        this.setState({ dataEnteryOptions: optionData })
                    } else { }
                }).catch((err)=>{
                    console.log(err)
                })
        
                let areaCode = row.assigned_user[0]['area_code'].length ? row.assigned_user[0]['area_code'][0] : ""
                let user_id = row.assigned_user[0]['user_id'] != "" ? row.assigned_user[0]['user_id'] : ""
        
                let obj = {}

                console.log('row?.household[0]',row?.household[0])

                if(row?.household[0]?.household?.length>0){
                    console.log('householdd',typeof(household))
                    obj = {
                        "survey_id": this.defSurveryID,
                        "manage_id": row._id,
                        "area":  areaCode,
                        "user": user_id,
                        "household": 1
                    }
                }
                else{
                    console.log('householdd',typeof(household))
                    obj = {
                        "survey_id": this.defSurveryID,
                        "manage_id": row._id,
                        "area":  areaCode,
                        "user": user_id,
                    }
                }

                AdminService.httpPost('/data-retrieval/survey-data-entry/get-questionlist', obj).then((res) => {
                    if (res) {
                        console.log('ffff1')
                       

                        let questionData = Object.keys(res.data).sort().reduce(
                            (obj, key) => {
                                obj[key] = res.data[key];
                                return obj;
                            },
                            {}
                        );

                        
                        this.setState({ dataEntryQuestion: questionData, dataEntryAnswers: res.survey_record })
                        console.log('questionData',questionData);
                        
        
                    } else { }
                }).catch((err)=>{
                    console.log(err)
                })
        
                let payload = {
                    survey_id: row.survey_id,
                    "manage_id": row._id,
                    status: row.status,
                    user_id: JSON.parse(localStorage.getItem('userInfo')).id,
                    role: JSON.parse(localStorage.getItem('userInfo')).role
                }
                AdminService.httpPost('/data-import/survey/get-single-form', payload).then((res) => {
                    console.log('index',res)
                    if(res.data.length > 0){
                        this.setState({ dataEntryData: res.data[0] })
                    }
                    this.setState({ loader: false })
                }).catch((err) => {
                    this.setState({ loader: true })
                    console.log(err)
                });
        
        
            },
            // getOptionsData:[]
            
        }

    }

   



     

    role = JSON.parse(localStorage.getItem('userInfo')).role

    // =============================================================
    //   [age, setAge] = useState('');
    selectForm = (newForm) => {
        // console.log("new", newForm, this.state.alignment)
        let newAlignment = this.state.alignment

        let status = 1;

        if (newAlignment == 'In-Progress') {
            status = 2;
        }
        else if (newAlignment == 'Completed') {
            status = 3;
        }
        else if (newAlignment == 'Validated') {
            status = 4;
        }
        else if (newAlignment == 'Aggregated') {
            status = 5;
        }
        else if (newAlignment == 'Cancelled') {
            status = 6;
        }
        else {
            status = 1;
        }
        let obj = {
            "status": status,
            survey_id: newForm.target.value._id
        }
        let survId = newForm.target.value._id
        this.defSurveryID = survId
        this.getCount(survId);
        this.getData(obj);
        this.setState({ age: newForm.target.value, formName: newForm.target.value.name.en,currentSatus:status });

        // this.state.tbStatus
        // this.age._id
    };
    // =============================================================
    changeTab = (event) => {
        // console.log("BBB", event, this.state.age)
        let newAlignment = event.target.value

        let status = 1;

        if (newAlignment == 'In-Progress') {
            status = 2;
        }
        else if (newAlignment == 'Completed') {
            status = 3;
        }
        else if (newAlignment == 'Validated') {
            status = 4;
        }
        else if (newAlignment == 'Aggregated') {
            status = 5;
        }
        else if (newAlignment == 'Cancelled') {
            status = 6;
        }
        else {
            status = 1;
        }
        let obj = {
            "status": status,
            "survey_id": this.state.age._id
        }
        this.getData(obj);

        this.setState({ alignment: newAlignment, openTab: newAlignment });
    }

    handleChange = (event, newAlignment) => {
        console.log("AAA", event, newAlignment)

        this.setState({ alignment: newAlignment, openTab: newAlignment });
        let status = 1;

        if (newAlignment == 'In-Progress') {
            status = 2;
        }
        else if (newAlignment == 'Completed') {
            status = 3;
        }
        else if (newAlignment == 'Validated') {
            status = 4;
        }
        else if (newAlignment == 'Aggregated') {
            status = 5;
        }
        else if (newAlignment == 'Cancelled') {
            status = 6;
        }
        else {
            status = 1;
        }
        // let obj = {
        //     "status": status, 
        //     "survey_id": obj.survey_id
        // }
        this.getData(status);
        console.log('newAlignment', newAlignment)
    };



    handleDate = (newValue) => {
        this.setState({ setValue: newValue });
    };


    createCopy = (data) => {
        AdminService.httpPost('/data-import/question/copy', data).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                // console.log(res)
                // this.getData(this.state.dataStatus);

                if (res.status == true) {
                    toast.success("A new copy of form created successfully");

                    $('#closeCopy').trigger('click');
                    this.getData(1);
                }
                else {
                    toast.error(res.message);

                }





            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }

    showArea = (row)=>{
            this.setState({ loader: true })
    
    
            AdminService.httpGet('/data-retrieval/survey/getsurveyView/' + row._id).then((res) => {
                if (res) {
                }
                if(res.survey[0].AU){
                    let userData = {}
                    res.survey[0].AU.forEach(data=>{
                        data?.area?.forEach(area=>{
                            userData[area?.name?.en] = data?.user?.name
                        })
                    })
                    this.state.assignedAreaToUserData = userData
                    // setAssignedUserData(userData)
                }
                this.setState({ loader: false })
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
        console.log('areatouser',this.state.areaToUser)

      

       
    }


    editMode(event, id) {
        event.preventDefault();

        this.state.data.forEach((item) => {
            if (item._id == id) {
                this.setState({ isEdit: true, editModeId: item, modal: true });
                this.forceUpdate();
                return;
            }
        });

        // this.openAddModal();

        // $('#openDataModal').trigger('click');

    }

    onSearch = (val) => {
        console.log('searchworking');

        console.log(this.state.data);

        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                (item) => {
                    if(item?.name?.en?.toLowerCase()?.includes(val?.toLowerCase())){
                        return item?.name?.en?.toLowerCase()?.includes(val?.toLowerCase())
                    }else if(item?.description?.en?.toLowerCase()?.includes(val?.toLowerCase())){
                        return item?.description?.en?.toLowerCase()?.includes(val?.toLowerCase())
                    }else{
                        return item.disasterList[0].title.en.toLowerCase().includes(val.toLowerCase())
                    }
                }
                );
        }
    console.log('filtereddata',this.props.selectedLang.lang);
        this.setState({ filteredData: fd });
    }




    editForm = (id) => {
        document.getElementById('openDataModal').click()
        this.setState({ loader: true })
        AdminService.httpGet('/data-retrieval/survey/get-single-form/' + id).then((res) => {

            this.setState({
                isEdit: true,
                formId: res.data[0].form_id,
                id: res.data[0]._id,
                formName: res.data[0].name.en ?? res.data[0].name,
                description: res.data[0].description,
                disaster_type: res.data[0].disaster_type,
                editModeData: res.data[0]
            });
            // if(res){                
            //     let d = res.data;

            //     this.setState({filteredData: d, data:d})
            // } else {
            //     this.setState({filteredData: [], data: []})
            // }
            this.setState({ loader: false })
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }

    markComplete = (status, id) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        var payload = {
            "status": 2,
            "survey_id": this.state?.age._id,
            "user_id": userInfo.id,
            "role": userInfo.role
        };
        // console.log("cc", payload)
        // return
        AdminService.httpPost('/data-retrieval/survey-data-entry/check-survey-completed', { manageform_id: id }).then(res => {
            console.log(res);
            if (res.status == 1) {
                if (status == 5) {
                    return
                }

                swal({
                    title: `Are you sure to move from "In-Progress" to "Completed"?`,
                    text: ``,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((active) => {
                        if (active) {
                            AdminService.httpPost('/data-retrieval/survey/statusUpdate', { "form_id": id, "status": status + 1 }).then((res) => {
                                // AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {

                                if (res) {
                                    this.setState({ loader: false })
                                    let d = res;

                                    // console.log(res)
                                    // this.getData(this.state.dataStatus);

                                    if (res.status == true) {
                                        toast.success("Status updated successfully");
                                        // this.openAddModal();
                                        console.log("this.defSurveryID:: ", this.defSurveryID)
                                        this.getCount(this.defSurveryID);
                                        // this.getData(status + 1);
                                        this.getData(payload);
                                    }
                                    else {
                                        toast.error(res.message);

                                    }





                                }

                            }).catch((err) => {
                                this.setState({ loader: false })
                                console.log(err)
                            });

                        }

                    })

            } 
            else if (res.status == 0 && res.form=='Form2') {
                if (status == 5) {
                    return
                }

                swal({
                    title: `Form is incomplete, Are you sure to move from "In-Progress" to "Completed"?`,
                    text: ``,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((active) => {
                        if (active) {
                            AdminService.httpPost('/data-retrieval/survey/statusUpdate', { "form_id": id, "status": status + 1 }).then((res) => {
                                // AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {

                                if (res) {
                                    this.setState({ loader: false })
                                    let d = res;

                                    // console.log(res)
                                    // this.getData(this.state.dataStatus);

                                    if (res.status == true) {
                                        toast.success("Status updated successfully");
                                        // this.openAddModal();
                                        console.log("this.defSurveryID:: ", this.defSurveryID)
                                        this.getCount(this.defSurveryID);
                                        // this.getData(status + 1);
                                        this.getData(payload);
                                    }
                                    else {
                                        toast.error(res.message);

                                    }





                                }

                            }).catch((err) => {
                                this.setState({ loader: false })
                                console.log(err)
                            });

                        }

                    })

            }
            
            
            else {
                toast.error("All submissions should be completed to approve the form")
            }
        }).catch(err => {
            console.log(err);
        })
    }

    approveStep1 = (status, id) => {

        // console.log("curr-status: ", status)
        const data = {
            "status": status + 1
        }
        swal({
            title: `Are you sure to move from "Pending" to "In-Progress"?`,
            text: ``,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((active) => {
                if (active) {
                    AdminService.httpPost('/data-retrieval/survey/statusUpdate', { "form_id": id, "status": status + 1 }).then((res) => {
                        // AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            // console.log(res)
                            // this.getData(this.state.dataStatus);

                            if (res.status == true) {
                                toast.success("Status updated successfully");
                                // this.openAddModal();
                                console.log("this.defSurveryID:: ", this.defSurveryID)
                                this.getCount(this.defSurveryID);
                                // this.getData(status + 1);
                                const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                                var payload = {
                                    "status": this.state?.currentSatus,
                                    "survey_id": this.state?.age._id,
                                    "user_id": userInfo.id,
                                    "role": userInfo.role
                                };
                                this.getData(payload);
                            }
                            else {
                                toast.error(res.message);

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });

                }

            })

    }

    approveStep3 = (status, id) => {

        // console.log("curr-status: ", status)
        const data = {
            "status": status + 1
        }
        swal({
            title: `Are you sure to move from "Completed" to "Validated"?`,
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((active) => {
                if (active) {
                    AdminService.httpPost('/data-retrieval/survey/statusUpdate', { "form_id": id, "status": status + 1 }).then((res) => {
                        // AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            // console.log(res)
                            // this.getData(this.state.dataStatus);

                            if (res.status == true) {
                                toast.success("Status updated successfully");
                                // this.openAddModal();
                                console.log("this.defSurveryID:: ", this.defSurveryID)
                                this.getCount(this.defSurveryID);
                                // this.getData(status + 1);
                                const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                                var payload = {
                                    "status": 3,
                                    "survey_id": this.state?.age._id,
                                    "user_id": userInfo.id,
                                    "role": userInfo.role
                                };
                                this.getData(payload);
                            }
                            else {
                                toast.error(res.message);

                            }





                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });

                }

            })

    }

    approveStep4 = (status, mid, id, disaster, fid) => {

        // console.log("curr-status: ", status)
        const data = {
            "status": status + 1
        }
        swal({
            title: `Are you sure to move from "Validated" to "Aggregated"?`,
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((active) => {
                if (active) {
                    AdminService.httpPut('/data-import/survey/status/' + id, { "status": status + 1, manage_id: mid, disaster: disaster, form_id: fid }).then((res) => {
                        // AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            // console.log(res)
                            // this.getData(this.state.dataStatus);

                            if (res.status == true) {
                                toast.success("Status updated successfully");
                                // this.openAddModal();
                                console.log("this.defSurveryID:: ", this.defSurveryID)
                                this.getCount(this.defSurveryID);
                               // this.getData(status + 1);
                               const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                               var payload = {
                                   "status": 4,
                                   "survey_id": this.state?.age._id,
                                   "user_id": userInfo.id,
                                   "role": userInfo.role
                               };
                               this.getData(payload);
                            }
                            else {
                                toast.error(res.message);

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });

                }

            })

    }



    openDataSummaryModal = (row) => {
        // console.log("rr", row)
        let date = new Date(row.createdAt)
        let dtObj = {
            day: date.getUTCDate(),
            month: date.toLocaleString('en', { month: 'short' }),
            year: date.getFullYear()
        }

        let currObj = {
            date: dtObj.day + ' ' + dtObj.month + ' ' + dtObj.year,
            name: row.name?.en
        }
        this.setState({ datasummaryFormData: currObj })

        const obj = {
            mid: row._id
        }
        AdminService.httpPost('/data-retrieval/data/get-data-summary', obj).then((res) => {
            if (res) {
                this.setState({ summaryData: res })
            }
        })
    }


    openDataEntryModal = (row ,areaFromView ,household) => {
        console.log('householdd',household)
        
        
        if(row==null){
            row = this.state.activeRow
        }
        console.log("row: ", this.defSurveryID, row)
        this.state.rowIdForPending = row._id
        let date = new Date()
        let dtObj = {
            day: date.getUTCDate(),
            month: date.toLocaleString('en', { month: 'short' }),
            year: date.getFullYear()
        }

        let currObj = {
            date: dtObj.day + ' ' + dtObj.month + ' ' + dtObj.year,
            name: row.name?.en
        }
        let tempArea = areaFromView!=null?areaFromView:row.areaList[0][0]

            this.setState({ dataentryFormData: currObj ,activeRow:row ,areaForView:tempArea })
        
        
        this.setState({ dataentryFormData: currObj ,activeRow:row})
console.log('tempArea',tempArea)
        AdminService.httpGet('/data-retrieval/option/get-option').then((res) => {
            if (res) {

                let optionData = res.data
                console.log("dd", optionData)
                // dataEnteryOptions
                this.setState({ dataEnteryOptions: optionData })
            } else { }
        })

console.log('areaFromView',areaFromView);

        let areaCode = areaFromView!=null ?areaFromView.area_code:   row.areaList[0][0].area_code
        let user_id = row.assigned_user[0]['user_id'] != "" ? row.assigned_user[0]['user_id'] : ""
        let initialSelectedId= {}
        if(household != undefined){
            initialSelectedId._id = household
            this.setState({ areaForView: initialSelectedId})
        }

        let obj={}
    

        if(!isNaN(household?.split("-")[1])){
            console.log('householdd',typeof(household))
            obj = {
                "survey_id": this.defSurveryID,
                "manage_id": row._id,
                "area":  household?.split("-")[0],
                "user": user_id,
                "household":Number(household?.split("-")[1])
            }
        }else if(row?.household[0]?.household?.length>0){
            obj = {
                "survey_id": this.defSurveryID,
                "manage_id": row._id,
                "area":  areaCode,
                "user": user_id,
                "household": 1
            }
        }
        else{
            console.log('householdd',typeof(household))
            obj = {
                "survey_id": this.defSurveryID,
                "manage_id": row._id,
                "area":  areaCode,
                "user": user_id,
            }
        }

        AdminService.httpPost('/data-retrieval/survey-data-entry/get-questionlist', obj).then((res) => {
            if (res) {
                console.log('ffff')
                let questionData = Object.keys(res.data).sort().reduce(
                    (obj, key) => {
                        obj[key] = res.data[key];
                        return obj;
                    },
                    {}
                );
                // dataEntryQuestion
                // console.log("questionData", questionData)
                // console.log("survey_record",res.survey_record.question_answer);
                this.setState({ dataEntryQuestion: questionData, dataEntryAnswers: res.survey_record ,viewMode:true})
                console.log('questionData',questionData);
                // this.setState({ dataEntryQuestion: questionData})
                // this.setState({ areaForView:tempArea })

            } else { }
        })

        let payload = {
            survey_id: row.survey_id,
            "manage_id": row._id,
            status: row.status,
            user_id: JSON.parse(localStorage.getItem('userInfo')).id,
            role: JSON.parse(localStorage.getItem('userInfo')).role
        }
        AdminService.httpPost('/data-import/survey/get-single-form', payload).then((res) => {
            this.setState({ dataEntryData: res.data[0] })
            console.log('get-single-form',res.data[0].household[0].household)

            if(res.data[0].household[0].household.length > 0){
            res.data[0].area_info.map(x=>{
                // if(res.data[0].household.household.length > 0){
                    res.data[0].household.map((h)=>{
                        console.log('get-single-form',h)
                    // if(h._id == 'ASITLS0201'){
                    let initialSelectedId= {}
                    initialSelectedId._id =  h._id +'-'+ 1
                   
                    if(household == undefined){
                        this.setState({ areaForView: initialSelectedId})
                    }else{
                        initialSelectedId._id = household
                        this.setState({ areaForView: initialSelectedId})
                    }
                    console.log('initialSelectedId',initialSelectedId)
                    // }
                   })
                // }    
                
            })
        }

        
            // this.setState({ areaForView:tempArea })

            // this.setState({ 
            //     isEdit:true,
            //     formId: res.data[0].form_id,
            //     id: res.data[0]._id, 
            //     formName: res.data[0].name,
            //     description: res.data[0].description,
            //     disaster_type: res.data[0].disaster_type,
            //     editModeData:res.data[0]
            // });
        }).catch((err) => {

            console.log(err)
        });


    }

    undoData = (status, rowId, survayId) => {

        AdminService.httpPut(`/data-import/survey/status/${survayId}`, { status: 2, survey_id: survayId, manage_id: rowId }).then((res => {
            if (res.status == true) {
                toast.success(res.message);
                this.getCount(this.defSurveryID);
                const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                var payload = {
                    "status": 6,
                    "survey_id": this.state?.age._id,
                    "user_id": userInfo.id,
                    "role": userInfo.role
                };

                this.getData(payload);
            } else {
                toast.error(res.message);
            }
        }))
    }

    cancelData = (status,id,e) => {

        const data = {
            "manage_id": id
        }
        swal({
            title: "Are you sure you want to cancel this plan?",
            text: ``,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((active) => {
                if (active) {
                    AdminService.httpPost('/data-import/survey/cancel-survey-response', data).then((res) => {

                        if (res) {
                            toast.success(res.message);
                            // this.openAddModal();
                            this.getCount(this.defSurveryID);

                            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                            var payload = {
                                "status": 2,
                                "survey_id": this.state?.age._id,
                                "user_id": userInfo.id,
                                "role": userInfo.role
                            };

                            this.getData(payload);
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });

                }

            })


    }


    // downloadAggregatedData = () => {
    //     this.aggregateData.link.click();
    // }

    // copy form data=====================//



    columnsPending = [
        {
            name: '#',
            selector: (row, index) => index+1,
            sortable: false,
            width: "50px"  
        },
        {
            name: 'Name',
            // selector: row => row?.name?.en,
            selector: row => <Tooltip title={row?.name?.en} placement="top">
                <p>{row?.name?.en}</p>
            </Tooltip>,
            sortable: true,
            minWidth: "170px",
            maxWidth: "300px"
        },

        {
            name: 'Description',
            selector: row => <Tooltip title={row?.description?.en} placement="top">
                <p>{row?.description?.en}</p>
            </Tooltip>,
            // selector: row => row?.description?.en,
            sortable: true,
            minWidth: "170px"
        },
        {
            name: 'Disaster',
            selector: row => row?.disasterList?.[0]?.title?.en,
            sortable: true,
            width: "128px"
        },
        {
            name: 'From',
            selector: row => row?.from_date,
            sortable: true,
            width: "100px"
        },
        {
            name: 'To',
            selector: row => row?.to_date,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Assigned Area - User',
            // selector: row => { var count = 0; row?.assigned_user?.forEach(item => { count = count + item.area_code?.length; }); return (<>{count + ' Area to ' + row.assigned_user?.length + ' User'} <img src='assets/lib/images/arrow_upp.svg' className='arrow-up-icon' onClick={() => { this.setState({ assigneduserData: { users: row.userList, areas: row.areaList } }); console.log(row); this.showArea(row) }} data-toggle='modal'  data-target='#area-user' /></>) },
            selector: (row) => {
                 var count = 0; 
                //  console.log('row?.assigned_user',row?.assigned_user)
                 row?.assigned_user?.forEach(item => {
                        // const countObj =  item.area_code?.reduce((accumulator, value) => {
                        // return {...accumulator, [value]: (accumulator[value] || 0) + 1};
                        // }, {});
                        // count = count + Object.keys(countObj).length

                        const uniqueAges = [...new Set(item.area_code)]
                        count = count + uniqueAges.length
                        // console.log('uniqueAges',uniqueAges)
                    }); 
                 
                    return (<>
                    {count + ' Area to ' + row.assigned_user?.length + ' User'} 
                    <img src='assets/lib/images/arrow_upp.svg' className='arrow-up-icon ml-2' 
                    onClick={() => { this.setState({ assigneduserData: { users: row.userList, areas: row.areaList } }); 
                    console.log(row); this.showArea(row) }} data-toggle='modal'  data-target='#area-user' />
                    </>) },
            sortable: false,
            width: "200px"
        },


        {
            name: 'Action',
            width: "170px",
            cell: row =>
                <>
                    {/* <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"> */}
                        <Dropdown className='table-action'>
                            <Dropdown.Toggle id="dropdown-basic">
                                Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            {row.status == 1 && <>
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.viewData(e, row._id)}>View</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.editForm(row._id)} >Edit</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal">Copy</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.approveStep1(row.status, row._id)}>Approve</a>
                                <a class="dropdown-item text-bold" onClick={(e) => this.deleteEntry(e, row._id)} href="javascript:void(0);">Delete</a> */}
                                {/* <a class="dropdown-item text-bold"  href="javascript:void(0);" data-toggle="modal" data-target="#NewModal">NewModal</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentry" onClick={(e)=> this.openDataEntryModal(row)} >Data Entry</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datasummary_popup" onClick={(e)=> this.openDataSummaryModal(row)} >Data Summary</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datavalidationview">Data Validation View</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datavalidation1">Data Validation View</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataaggregateview">Data Aggregate View</a> 
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#addcalculation_popup">Add Calculation</a>        
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentryview">In Progress View</a>      */}
                                
                                {[1,7,4].includes(this.role) && <Dropdown.Item onClick={(e) => this.viewData(e, row._id)} href="javascript:;">View</Dropdown.Item>}
                               {[1, 7].includes(this.role)  && <Dropdown.Item onClick={(e) => this.editForm(row._id)} href="javascript:;">Edit</Dropdown.Item>}
                               { [1, 7].includes(this.role) && <Dropdown.Item onClick={(e) => this.approveStep1(row.status, row._id)} href="javascript:;">Approve</Dropdown.Item>}
                               {[1, 7].includes(this.role) && <Dropdown.Item onClick={(e) => this.deleteEntry(e, row._id)} href="javascript:;">Delete</Dropdown.Item>}
                                    
                            </>

                            }


                            {row.status == 2 && <>
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentryview" onClick={(e) => this.openDataEntryModal(row)} >View</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#viewModal" onClick={(e)=>this.viewData(e, row._id)}>View</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);"    onClick={(e)=>this.editMode(e, row._id)} >Edit</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal">Copy</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.markComplete(row.status, row._id)}>Complete</a> */}
                                {/* <a class="dropdown-item text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:void(0);">Delete</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentry" onClick={(e)=> this.openDataEntryModal(row)}>Data Entry</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datasummary_popup" onClick={(e) => this.openDataSummaryModal(row)} >Data Summary</a> */}

                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.cancelData(row.status, row._id, 2)}>Cancel</a> */}
                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentryview" onClick={(e)=> this.openDataEntryModal(row)} >In Progress View</a> */}
                                <a class="dropdown-item text-bold"  href="javascript:void(0);" data-toggle="modal" data-target="#inprogressview" onClick={(e) => this.openDataEntryModal(row ,null )}   >View</a>
                                {/* {[1, 4, 7].includes(this.role) && <Dropdown.Item data-toggle="modal" data-target="#dataentryview" onClick={(e) => this.openDataEntryModal(row ,null )} href="javascript:;">View</Dropdown.Item>} */}
                                {[1, 7].includes(this.role) && <Dropdown.Item onClick={(e) => this.markComplete(row.status, row._id)} href="javascript:;">Complete</Dropdown.Item>}
                               {[1, 7].includes(this.role) && <Dropdown.Item data-toggle="modal" data-target="#datasummary_popup" onClick={(e) => this.openDataSummaryModal(row)} href="javascript:;">Data Summary</Dropdown.Item>}
                                {[1, 7].includes(this.role) && <Dropdown.Item onClick={(e) => this.cancelData(row.status, row._id, 2)} href="javascript:;">Cancel</Dropdown.Item>}
                            </>

                            }


                            {row.status == 3 && <>

                                {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" onClick={() => { this.validationClick(row._id, row.survey_id,row.name,row) }} data-target="#datavalidationview" >View</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" onClick={() => { this.validationClick(row._id, row.survey_id,row.name,row); this.getOption() }} data-target="#datavalidation1">Data Validation</a>
                                <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.approveStep3(row.status, row._id)}>Validate</a> */}

                                {/* <Dropdown.Item  data-toggle="modal" onClick={() => { this.validationClick(row._id, row.survey_id,row.name,row) }} data-target="#datavalidationview" href="javascript:;">View</Dropdown.Item> */}
                               {[1, 4, 7].includes(this.role) && <Dropdown.Item data-toggle="modal" data-target="#dataentryview" onClick={(e) => this.openDataEntryModal(row ,null )} href="javascript:;">View</Dropdown.Item>}
                              {[1, 4, 7].includes(this.role) &&  <Dropdown.Item data-toggle="modal" onClick={() => { this.validationClick(row._id, row.survey_id,row.name,row); this.getOption() }} data-target="#datavalidation1" href="javascript:;">Data Validation</Dropdown.Item>}
                               {[1, 7].includes(this.role) && <Dropdown.Item onClick={(e) => this.approveStep3(row.status, row._id)} href="javascript:;">Validate</Dropdown.Item>}
                            </>

                            }

                            {
                                row.status == 4 &&
                                <>
                                    {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#addcalculation_popup">Add Calculation</a> */}
                                    {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.approveStep4(row.status, row._id, row.survey_id, row.disaster_type)}>Aggregate</a> */}
                                    <Dropdown.Item onClick={(e) => this.approveStep4(row.status, row._id, row.survey_id, row.disaster_type, row.form_id)} href="javascript:;">Aggregate</Dropdown.Item>                                
                                </>
                            }
                            {
                                row.status == 5 &&
                                <>
                                    {/* <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#addcalculation_popup">Add Calculation</a> */}
                                    {/* <a class="dropdown-item text-bold" href="javascript:void(0);"  onClick={()=> this.downloadAggregatedData(row.status,row.survey_id, row._id)}>Download</a> */}
                                    
                                    <Dropdown.Item onClick={()=> this.downloadAggregatedData(row.status,row.survey_id, row._id, row)} href="javascript:;">Download</Dropdown.Item> 
                                    {/* {this.state.aggregatedData?.length >
                                        0 ? (
                                        <CSVLink
                                            data={this.state.aggregatedData}
                                            // ref={(r) => console.log("rr", r) (this.aggregateData = r)}
                                            // headers={this.state.headersForAggregatedData}
                                            // filename={this.state.fileNameFormate}
                                            className="d-flex"
                                        />
                                    ) : null} */}

                                    
                                    
                                </>
                            }

                            {
                                row.status == 6 &&
                                <>
                                    {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e) => this.undoData(row.status, row._id, row.survey_id)}>Undo</a> */}
                                    <Dropdown.Item onClick={(e) => this.undoData(row.status, row._id, row.survey_id)} href="javascript:;">Undo</Dropdown.Item> 
                                </>
                            }
                            {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=>this.viewData(e, row._id)}>View</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);"    onClick={(e)=>this.editMode(e, row._id)} >Edit</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal">Copy</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.approveStep1(row.status, row._id)}>Approve</a>
                        <a class="dropdown-item text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:void(0);">Delete</a>

                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentry">Data Entry</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#Undo_popup">Undo</a>     
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#addcalculation_popup">Add Calculation</a>        
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentryview">In Progress View</a>     
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datavalidationview">Data Validation View</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataaggregateview">Data Aggregate View</a>               */}
                        {/* </div>
                        </div> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    
    cancelModal = async (e) => {

        if (this.state.disaaprovest == false) {
            this.setState({ disaaprovest: true })
            this.setState({ remarkdata: e })
        }
        else {

            this.setState({ disaaprovest: false })
        }

    }

    openViewModal = async (e) => {

        swal({
            title: "REASON OF DISAPPROVAL",
            text: e

        })

    }

    sendStatus = (e) => {
        this.state.dataStatus = e;
        this.getData(e);
    }

    getDataByStatus = (e) => {
        console.log("data-----" + e.target.value)
        const val = e.target.value;
        this.setState({ dataStatus: val })
        // console.log("------aa--" + val)
        // console.log("data-----" + e.target.value)
        // console.log("state--" + this.state.dataStatus)

        this.getData(e.target.value);
    }


    publishAll = (e) => {

        console.log("length++--" + this.state.filteredData)
        if (this.state.data == "") {
            toast.error("No record found to approve");
        } else {


            if (this.state.publishModal == false) {
                this.setState({ publishModal: true })
                this.setState({ remarkdata: e })
            }
            else {

                this.setState({ publishModal: false })
            }
        }

    }








    sendActorData(row) {
        this.setState({ profileData: row });

        console.log('row', row)
    }
    // /==========change status api=============

    changeStatus(e, id, status, value) {
        if (value == 0) {
            value = 1;
        } else {
            value = 0;
        }


        const dataToBeSend = {

            show: value

        }


        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                // console.log(res)
                // this.getData(this.state.dataStatus);

                if (res.message == 'Status updated successfully') {
                    toast.success("Status updated successfully");
                    // this.openAddModal();
                    // this.getData();
                }
                else {
                    toast.success("Failed to Status updated");

                }





            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }



    validationClick = (id, surveyId,name,row) => {
        console.log('validationcheckclicked')
        console.log('validateclick',id, surveyId,name,row)
        this.state.rowForCompleted = row
        AdminService.httpGet('/data-retrieval/survey/get-assigned-users/' + id).then(res => {
            

            this.setState({ dataForValidation: res.data, activeSurvay: surveyId ,  dataValidatationName:name});
        }).catch(err => {
            console.log(err);
        })
    }

    getOption = () => {
        AdminService.httpGet("/data-retrieval/option/get-option").then((res) => {
      
        this.setState({getOptionsData:res.data})
          console.log("data------<", this.state.getOptionsData );
        }).catch((err)=>{
            console.log(err)
        })
      };


      downloadCsvData(data) {
		console.log("aaa", data)

		let downloadData = this.state.aggregatedData
    
		.map((element) => (

			{
                AreaID: element.AreaID,
                AreaName : element?.AreaName,
                DataValue: element?.DataValue,
                Footnote: element?.Footnote,
                Indicator: element?.Indicator?.replaceAll(',', ' '),
                Source: element?.Source,
                Subgroup: element?.Subgroup,
                Timeperiod: element?.Timeperiod,
                DisasterName : element?.DisasterCode,
                // PlanName : element?.FormName,
			}
		))


    	this.downloadFile(this.arrayToCSV(downloadData), `TERS_${data.name.en}_${this.getDate()}`)
	}

    arrayToCSV(data) {
		const csv = data.map(row => Object.values(row));
		// console.log("csv:: ",csv, data)
		csv.unshift(Object.keys(data[0]));
		return csv.join('\n');
	}

	downloadFile(data, filename = 'data') {
		let csvData = data
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		dwldLink.setAttribute("download", filename + ".csv");
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

    getDate() {
		let d = new Date();
		let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
			d.getHours() + ":" + d.getMinutes();
		return (datestring.replace(':', '.'))
	}

   downloadAggregatedData= async (status, survey_id, manageFormid, data)=>{
       try {
           this.setState({ loader: true });
           this.state.aggregatedData = []
           let dataToSend = {
            status:status,
            survey_id:survey_id,
            manageFormid : manageFormid
           }
           const result = await AdminService.httpPost("/data-retrieval/survey/get-aggregated-diet", dataToSend)
           console.log('result', result)
        //    console.log('aggregatedData', this.aggregatedData)
    
           result.forEach((element) => {
               console.log('elem', element)
             
               if(element.AreaID != null || element.AreaName != null || element.FormName != null || element.DataValue != null || element.Footnote != null || element.Indicator?.en != null || element.Source != null|| element.Subgroup?.en != null|| element.Timeperiod != null || element?.DisasterCode != null){
                this.state.aggregatedData.push({
                    AreaID: element.AreaID,
                    AreaName : element?.AreaName?.en,
                    DataValue: element?.DataValue,
                    Footnote: element?.Footnote,
                    Indicator: element?.Indicator?.en,
                    Source: element?.Source,
                    Subgroup: element?.Subgroup?.en,
                    Timeperiod: element?.Timeperiod,
                    DisasterCode : element?.DisasterCode?.en,
                    PlanName : element?.FormName?.en,
                })
               }
           })
           console.log('aggregatedData', this.state.aggregatedData)
           this.setState({ loader: false });

           if(this.state.aggregatedData.length > 0){
                // this.aggregateData.link.click()
                // console.log("ss",this.aggregateData)
                this.downloadCsvData(data)
            }else{
                toast.error('No Data Available To Download')
            }
           
       } catch (error) {
           console.log(error)
       }
   }
         
    





    deleteEntry = (e, id) => {

        swal({
            title: "Once deleted, you will not be able to recover this plan!",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({ loader: true })
                    const dataToBeSend = {
                        "id": id
                    }

                    AdminService.httpPost('/data-import/survey/delete', dataToBeSend).then((res) => {

                        if (res) {

                            let d = res;

                            console.log(res)

                            if (res.message == "Form deleted successfully") {
                                toast.success("Plan deleted successfully");

                                var payload = {
                                    "status": this.state.currentSatus,
                                    "survey_id": this.state.age._id,
                                };
                                this.getCount(this.state.age._id)
                                this.getData(payload);
                            }
                            else {
                                this.setState({ loader: false })
                                toast.error("Failed to delete record!");

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });






    }





    editMode(event, id) {
        event.preventDefault();


        this.state.filteredData.forEach((item) => {
            if (item._id == id) {
                this.setState({ isEdit: true, editModeId: item, modal: true });
                this.forceUpdate();
                return;
            }
        });
        // console.log("data--->", this.state.data ,  id );
        // this.setState({isEdit:true, editModeId:id, modal: true});

    }



    openEditModal() {
        if ($('#EditAreaModal').hasClass('width350')) {
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else {
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getCount();
        this.getFormList(1)

        this.getRoleData();
        this.getAllareaData();
        // AdminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }



    // ////////////get count ---------------////

    getCount = (survey_id) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        this.setState({ loader: true })
        var payload = {
            "survey_id": survey_id,
            "user_id": userInfo.id,
            "role": userInfo.role
        };

        // console.log("survey_id:: ", survey_id)

        AdminService.httpPost('/data-retrieval/survey/statusCount', payload).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.count;

                this.setState({ tbStatus: d })
                console.log('getStatus', d)
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }


    ///////////get api------------------/////////////
    getFormList = async (status) => {
        var payload = payload = { "status": status };

        AdminService.httpPost('/data-retrieval/survey/get-survey-list', payload).then((res) => {
            this.setState({ formList: res.data })
        }).catch((err) => {

            console.log(err)
        });
    }

    getData = async (obj) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        this.setState({ loader: true })
        var payload = {
            "status": obj.status,
            "survey_id": obj.survey_id,
            "user_id": userInfo.id,
            "role": userInfo.role
        };

        AdminService.httpPost('/data-import/survey/get-all-mngform', payload).then((res) => {
            this.setState({ loader: false })
            if (res) {
                let userCount = 0;
                console.log('element',res)

                
               
                res?.data?.forEach(element => {
                    let areaCount = 0;
                    element.user_count = element.assigned_user.length;
                    element?.assigned_user?.forEach(ele => {
                        ele?.area_code?.forEach(ele1 => {
                            areaCount++;
                        });
                    });
                    element.area_count = areaCount;

                    let date = new Date(element.createdAt)
                    let dtObj = {
                        day: date.getUTCDate(),
                        month: date.toLocaleString('en', { month: 'short' }),
                        year: date.getFullYear(),
                        time: date.toLocaleString('en', { hour: 'numeric', minute: 'numeric', hour12: true })
                    }
                    element.created_date = dtObj.day + ' ' + dtObj.month + ', ' + dtObj.year + ' | ' + dtObj.time

                    console.log('rees', element )
                    this.state.list = element
                    // element.areaList.forEach((data)=>{
                    //     console.log('en',data.name.en)
                    // })
                    // this.state.userList.push({
                       
                    // })
                   
                })
                let d = res.data;

                this.setState({ filteredData: d, data: d })
            } else {
                this.setState({ filteredData: [], data: [] })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }


    //close the modal

    closeForm=(e)=>
    {
        this.openAddModal(e);
        var payload = {
            "status": this.state.currentSatus,
            "survey_id": this.state.age._id,
        };
        this.getCount(this.state.age._id)
        if(this.state.alignment==="Pending"){
            this.getData(payload);
        }
        // this.getData(payload);
    }     
    // open add modal=====================//


    openAddModal = (e) => {

        console.log("this.state.modal: ", this.state.modal, e)

        if (this.state.modal == false) {
            this.setState({ showFormModal: true })


            AdminService.httpGet('/data-import/survey/get-form-id').then((res) => {
                this.formId = res.data.form_id
                this.setState({ formId: res.data.form_id })
            }).catch((err) => {

                console.log(err)
            });

            // Get disaster list

            AdminService.httpGet('/data-retrieval/disaster/getDisasterData').then((res) => {
                this.setState({ disasterList: res.data })
            }).catch((err) => {

                console.log(err)
            });

            this.setState({ modal: true })

        } else {
            this.setState({ ...this.state.editEntry, editEntry: [] })
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({ editModeId: null })
        }

    }




    getAllareaData = () => {
        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {
            if (res) {

                let data = res.data;

                this.setState({ areaData: data })

            }
        }).catch((err) => {

            console.log(err)
        });

        AdminService.httpGet('/data-retrieval/users/get-all-dataentry-user').then(res => {
            if (res) {
                this.setState({ dataEntryUsers: res.data, dataApproveUser: res.dataApprove })
            }
        })


    }



    /////////////////==========role and actor  data get==========//////////////
    getRoleData = async () => {
        this.setState({ loader: true })


        AdminService.httpGet('/data-retrieval/data/masterData').then((res) => {
            if (res) {

                let d = res[0]?.data.Actors;

                console.log("somratest========>", d);
                this.setState({ actorData: d, data: d })

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }



    viewData = (e, id) => {

        this.setState({ loader: true })
        console.log('id:',id)


        AdminService.httpGet('/data-retrieval/survey/getsurveyView/' + id).then((res) => {
            if (res) {

                let d = res.survey?.[0];
                console.log('rows', d.name.en)

                this.setState({ mainViewData: d, loader: false })

                $('#viewModal').modal();

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }



}


const mapStateToProps = (state) => ({
    token: state.login.token,
    selectedLang:state.lang
});

export default connect(
    mapStateToProps
)(ManageForm);


