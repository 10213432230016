import { Component } from 'react'
import './form-master.css';
import * as jsx from './form-master.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dropdown from 'react-bootstrap/Dropdown';
class FormMaster extends Component {
     arr = ["dxgf"]
   
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit:false,
            editEntry:[],
            modal:false,
            loader:false,
            profileData:[],
            // setValue:dayjs('2014-08-18T21:11:54')
            setOpen:false,
            openTab:'Pending',
            alignment: 'Pending',
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData:false,
            disscrollableModal : false,
            editModeId: null,
            modal: false,
            remarkdata: '',
            loader: false,
            dataLimit:10,
            areaData:[],
            age:10,
            tbStatus:0,
            gridStatus:1,
            dataStatus:2,
            statusData:[
                {"label":"Pending", "value":2},
                {"label":"Approved", "value":1},
                {"label":"Disapproved", "value":3}
            ],
            copyForm:null,

            mainViewData:[]
     
            // open:false,
        }
    }



    // =============================================================
//   [age, setAge] = useState('');
    selectForm = (newForm) => {
        this.setState({age:newForm.target.value});
    };
// =============================================================


        handleChange = (event, newAlignment) => {
            
            this.setState({alignment:newAlignment, openTab: newAlignment});
            let status = 1;

            if(newAlignment=='In-Progress'){
                status = 2;
            }
            else if(newAlignment=='Completed'){
                status = 3;
            }
            else if(newAlignment=='Validated'){
                status = 4;
            }
            else if(newAlignment=='Aggregated'){
                status = 5;
            }
            else if(newAlignment=='Cancelled'){
                status = 6;
            }
            else{
                status = 1;
            }
            this.getData(status);
          console.log('newAlignment', newAlignment)
        };

    

handleDate = (newValue) => {
  this.setState({setValue:newValue});
};


createCopy =(data)=>{
    AdminService.httpPost('/data-import/question/copy', data).then((res) => {
    
        if (res) {
            this.setState({ loader: false })
            let d = res;

            // console.log(res)
            // this.getData(this.state.dataStatus);
                    
            if(  res.status == true){
                toast.success("A new copy of form created successfully");
 
                $('#closeCopy').trigger('click');
                this.getData(1);
            }
            else{
                toast.error(res.message);

            }
        
            

            
       
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });
}


editMode (event, id) {
    event.preventDefault();
    
    this.state.data.forEach((item)=>{
        if(item._id==id){
            this.setState({isEdit:true, editModeId:item, modal: true});
            this.forceUpdate();
            return;
        }
    });

    // this.openAddModal();
    
    // $('#openDataModal').trigger('click');
   
}

    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.date_of_registration && item.date_of_registration.toLowerCase().includes(val.toLowerCase())
                      || (item.usersData?.[0]?.email && item.usersData?.[0]?.email.toLowerCase().includes(val.toLowerCase()))
              
            ));
        }
        
        this.setState({filteredData : fd});
    }
    
    approveStep1= (status, id) =>{

            const data = {
                "status":status+1
            }
            swal({
                title:"Are you sure?",
                text: `Are you sure to move from "Pending" to "In progress"?`,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((active) => {
                    if(active){
                        AdminService.httpPut('/data-import/survey/status/'+id, data).then((res) => {
    
                            if (res) {
                                this.setState({ loader: false })
                                let d = res;
                    
                                // console.log(res)
                                // this.getData(this.state.dataStatus);
                                        
                                if(  res.status == true){
                                    toast.success("Status updated successfully");
                                    // this.openAddModal();
                                    this.getData(status+1);
                                }
                                else{
                                    toast.error(res.message);
                
                                }
                            
                                
                    
                                
                           
                            }
                    
                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
    
                    }
              
                })
  

    }



    // copy form data=====================//



    columnsPending = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Name',
            selector: row => row.name.en??row.name,
            sortable: true,
            // width: "300px"  
        },
    
        {
            name: 'Description',
            selector: row => row.description.en??row,
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'Number of question',
            selector: row => row.totalQuestions,
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'View',
            cell: row => 
                <>              
                    <div className='switchBtn'>                        
                        <span onClick={()=>{console.log( row.web_view)}} >Web</span> <FormControlLabel control={<Switch onChange={(e) => this.changeView(!row.web_view, row) } checked={!row.web_view} color="default" />}  />Mobile 
                    </div>
                

                </>,
            // selector: row => row.questionsList?.length,
            // sortable: true,
            // width: "300px"  
        },

        // {
        //     name: 'Assigned Area - User',
        //     selector: row => {var count = 0;  row?.assigned_user?.forEach(item=>{ count = count + item.area_code?.length; }); return (<>{count +  ' Area to '+ row.assigned_user?.length+' User'} </>)} ,
        //     sortable: true,
        //     // width: "300px"  
        // },

      
        {
            name: 'Action',
            width: "200px"   ,
            cell: row => 
                <>
                
                <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        
                    
                {/* <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"> */}
                    {row.status==1 && <>
                    
                        {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=>this.viewData(e, row._id)}>View</a> */}
                        {/* <a class="dropdown-item text-bold" href="javascript:void(0);"    onClick={(e)=>this.editMode(e, row._id)} >Edit</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal">Copy</a> */}
                        {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.approveStep1(row.status, row._id)}>Approve</a> */}
                        {/* <a class="dropdown-item text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:void(0);">Delete</a>
                        <a class="dropdown-item text-bold" onClick={(e)=> this.fetchQuesData(e, row._id)} href="javascript:void(0);">Download Question</a> */}

                        <Dropdown.Item onClick={(e)=>this.editMode(e, row._id)} href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal" href="javascript:;">Copy</Dropdown.Item>
                        {/* <Dropdown.Item onClick={(e)=> this.fetchQuesData(e, row._id)} href="javascript:;">Download Question</Dropdown.Item> */}
                        <Dropdown.Item onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:;">Delete</Dropdown.Item>                        
                    </>

                    }
                        {/* <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=>this.viewData(e, row._id)}>View</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);"    onClick={(e)=>this.editMode(e, row._id)} >Edit</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.setState({copyForm:row})} data-toggle="modal" data-target="#copymodal">Copy</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" onClick={(e)=> this.approveStep1(row.status, row._id)}>Approve</a>
                        <a class="dropdown-item text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:void(0);">Delete</a>

                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentry">Data Entry</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#Undo_popup">Undo</a>     
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#addcalculation_popup">Add Calculation</a>        
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataentryview">In Progress View</a>     
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#datavalidationview">Data Validation View</a>
                        <a class="dropdown-item text-bold" href="javascript:void(0);" data-toggle="modal" data-target="#dataaggregateview">Data Aggregate View</a>               */}
                    {/* </div>
                </div>  */}
                </Dropdown.Menu>
                </Dropdown>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ]; 

    cancelModal = async (e) => {

        if(this.state.disaaprovest==false){
            this.setState({disaaprovest:true})
            this.setState({remarkdata:e})
        }
        else{
            
            this.setState({disaaprovest:false})
        }
      
    }

    openViewModal = async (e) => {

        swal({
            title:"REASON OF DISAPPROVAL",
            text: e
          
            })
      
    }

    sendStatus = (e) => {
        this.state.dataStatus=e;
        this.getData(e);
    }

    getDataByStatus = (e) => {
        console.log("data-----"+e.target.value)
        const val = e.target.value;
        this.setState({dataStatus:val})
        console.log("------aa--"+val)
        console.log("data-----"+e.target.value)
        console.log("state--"+this.state.dataStatus)
        
        this.getData(e.target.value);
    }


    publishAll =  (e) => {

        console.log("length++--"+this.state.filteredData)
        if(this.state.data==""){
            toast.error("No record found to approve");
        }else{

        
        if(this.state.publishModal==false){
            this.setState({publishModal:true})
            this.setState({remarkdata:e})
        }
        else{
            
            this.setState({publishModal:false})
        }
       }
      
    }








    sendActorData(row){
        this.setState({profileData:row});

        console.log('row',row)
    }
    // /==========change status api=============
     
    changeStatus(e, id, status,value) {
        if(value==0){
            value=1;
        }else{
            value=0;
        }
  

        const dataToBeSend = {
            
            show: value
        
        }


        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
    
            if (res) {
                this.setState({ loader: false })
                let d = res;
    
                // console.log(res)
                // this.getData(this.state.dataStatus);
                        
                if(  res.message == 'Status updated successfully'){
                    toast.success("Status updated successfully");
                    // this.openAddModal();
                    // this.getData();
                }
                else{
                    toast.success("Failed to Status updated");

                }
            
                
    
                
           
            }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }




    deleteEntry = (e, id) =>{

        swal({
            title:"Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if(willDelete) {
                this.setState({ loader: true })
                const dataToBeSend = {
                    "id": id
                }
               
                AdminService.httpPost('/data-import/survey/delete-survey', dataToBeSend).then((res) => {
    
                    if (res) {
                 
                        let d = res;
            
                        // console.log(res)
            
                        if(res.message == "Form deleted Successfully"){
                            toast.success("Form deleted successfully");
                            this.getData(1);
                        }
                        else{
                            this.setState({ loader: false })
                            toast.error("Failed to delete record");
            
                        }
                   
                    }
            
                }).catch((err) => {
                    this.setState({ loader: false })
                    console.log(err)
                });
              }                
            });     
    
    
    
    
    
    
      }
    


 

    editMode (event, id) {
        event.preventDefault();

        
        this.state.filteredData.forEach((item)=>{
            if(item._id==id){
                this.setState({isEdit:true, editModeId:item, modal: true});
                this.forceUpdate();
                return;
            }
        });
            // console.log("data--->", this.state.data ,  id );
            // this.setState({isEdit:true, editModeId:id, modal: true});
    
    }



    openEditModal(){
        if($('#EditAreaModal').hasClass('width350')){
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else{
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getCount();
        this.getData(1);
        this.getRoleData();
        this.getAllareaData();
        AdminService.checkToken();
    }
    
    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }



// ////////////get count ---------------////

getCount = () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-retrieval/survey/get-status-count').then((res) => {
        if (res) {
            this.setState({ loader: false })
            let d = res.count;

            this.setState({tbStatus:d})
        console.log('getStatus', d)
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });
}



fetchData = async (e) => {
    e.preventDefault();

     await AdminService.httpGet('/data-retrieval/data/exportData/20').then((res)=>{
      e.preventDefault();
        if(res){
            
            let d = res;
            
            

                        
                let filepath = constants.logFilePath + d.filepath;
                
                console.log("filepath:"+filepath);
                let filename = d.filepath.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'TERS_' + filename + new Date() + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);


     
          
        }
    
    }).catch((err)=>{
    
    console.log(err)
    })


    

  



};

fetchQuesData = async (e, sid) => {
    e.preventDefault();

     await AdminService.httpGet('/data-retrieval/data/exportQuestionData/21/'+sid).then((res)=>{
      e.preventDefault();
        if(res){
            
            let d = res;
            
            

                        
                let filepath = constants.logFilePath + d.filepath;
                
                console.log("filepath:"+filepath);
                let filename = d.filepath.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'TERS_' + filename + new Date() + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                    a.click();                
                }, 1000);
              document.body.removeChild(a);


     
          
        }
    
    }).catch((err)=>{
    
    console.log(err)
    })


    

  



};


///////////get api------------------/////////////

    getData = async (alignment) => {
        this.setState({ loader: true })

        
        var payload  = payload = { "status": alignment};

    



        AdminService.httpPost('/data-retrieval/survey/get-all-survey', payload).then((res)=>{
            if(res){
                this.setState({loader:false})


                let d = res.data;
                console.log('data-tobe', d);
                
                this.setState({filteredData: d, data:d})
                
                
                // this.forceUpdate();
                this.setState({ loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }

    changeView = (check, data) => {
        var payload  =  { "survey_id": data._id, "viewWeb": check};
        var web = check ? "web to mobile" : "mobile to web"
        swal({
            title:"Are you sure?",
            text: `To change the view mode from ${web}?`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((active) => {
                if(active){
                    this.setState({ loader: true })
                    AdminService.httpPost('/data-import/survey/update-status', payload).then((res)=>{
                        this.setState({ loader: false })
                        if(res.status == true){
                            toast.success("View mode changed successfully");
                            // this.openAddModal();
                            this.getData(1);
                        }
                        else{
                            toast.error(res.message);
        
                        }
                        // if(res){
                        //     this.setState({loader:false})
                        //     let d = res.data;
                        //     console.log('data-tobe', d);
                            
                        //     this.setState({filteredData: d, data:d})
                        
                            
                        //     // this.forceUpdate();
                        //     this.setState({ loader: false })
                        // }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            })
    }

 
    // open add modal=====================//


    openAddModal = () =>{


    if(this.state.modal==false){

        this.setState({modal: true})
        
    }else{
        this.setState({...this.state.editEntry, editEntry: []})
        this.setState({modal: false})
        this.setState({isEdit:false})
        this.setState({editModeId:null})
    }

}




getAllareaData= ()=>{


    AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
        if(res){
          
            let data = res.data;

            this.setState({areaData:data})
        
        }
      }).catch((err) => {
        
          console.log(err)
      });

    
}



/////////////////==========role and actor  data get==========//////////////
getRoleData = async () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-retrieval/data/masterData').then((res)=>{
                if(res){
                    
                    let d = res[0]?.data.Actors;
                  
                    console.log("somratest========>",d);
                    this.setState({actorData: d, data:d})
                   
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}



viewData = (e, id) => {

    this.setState({ loader: true })


    AdminService.httpGet('/data-retrieval/survey/get-survey/'+id).then((res)=>{
                if(res){
                    
                    let d = res.survey?.[0];
                  console.log('rows', d)
             
                  this.setState({mainViewData:d})

                  $('#viewModal').modal();
                   
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}



}


const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
  )(FormMaster);
