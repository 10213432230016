import { Component } from 'react'
import './log.css';
import * as jsx from './log.module.jsx';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
class Log extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            bulkDelete: [],
            loader: false
        }

    }

    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.filename && item.filename.toLowerCase().includes(val.toLowerCase()))
            );
        }
        this.setState({ filteredData: fd });
    }

    sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.filename;
        const b = rowB?.filename;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => index + 1,
        //     sortable: false,
        //     width:'50px'
        // },
        {
            name: 'Log File',
            minWidth: '350px',
            selector: (row, index) => <Tooltip title={row.filename} placement="top">
                <p>{row.filename}</p>
            </Tooltip>,
            sortable: true,
            sortFunction: this.sortWithToolTip,

        },
        {
            name: 'Imported Records',
            width: '200px',
            selector: (row, index) => row.imported_records,
            sortable: true,
        },
        {
            name: 'Updated Records',
            width: '200px',
            selector: (row, index) => row.updated_records,
            sortable: true,
        },
        {
            name: 'Error Records',
            width: '200px',
            selector: (row, index) => row.error,
            sortable: true,

        },
        {
            name: 'Total Records',
            width: '200px',
            selector: (row, index) => row.total_records,
            sortable: true,
        },
        // {
        //     name: 'CREATED AT',
        //     width:'250px',
        //     selector: (row, index) => row.created_at,
        //     sortable: true,
        // },
        {
            name: 'Action',
            width: '170px',
            cell: row =>
                <>
                    {/* <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => this.downloadData(e, row)} href="javascript:;">Download</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => this.removeRow(e, row._id)} href="javascript:;">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    <div className='action_btns'>
                       <button onClick={(e) => this.downloadData(e, row)}> <img src="assets/lib/images/download.svg" /></button>
                       <button onClick={(e) => this.removeRow(e, row._id)}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    downloadData(rowData, row) {
        // $(".vertical-centered-box").fadeIn();
        this.setState({ loader: true });
        let a = document.createElement("a");
        let filepath = constants.uploadPath + row.error_filename;
        // let filepath = constants.logFilePath + d.filepath;
        a.href = filepath;
        a.download = row.error_filename;
        document.body.appendChild(a);
        setTimeout(() => {
            a.click();
            this.setState({ loader: false });
            document.body.removeChild(a);
            //    $(".vertical-centered-box").fadeOut();
        }, 1000);
    }

    componentDidMount() {
        this.getData();
        // adminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }

    removeRow(event, id) {
        event.preventDefault();
        let selectedRows = {};
        selectedRows = { selectedIds: id }

        swal({
            title: "Are you sure you want to delete?",
            // text: "Once deleted, you will not be able to recover this log!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const req = adminService.httpPost('/data-retrieval/data/delete-import', selectedRows).then((res) => {
                        this.getData();
                        toast.success(res.message);
                    }).catch(err => {
                        toast.error("Error in delete log");
                    });
                }
            });
    }

    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };


    deleteBulkUser = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
        })

        if (ids?.length > 0) {
            swal({
                title: "Are you sure you want to delete?",
                // text: "Once deleted, you will not be able to recover this log!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        adminService.httpPost('/data-retrieval/importlog/delete-multiple-log', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log(res)

                                if (res.status == 1) {
                                    toast.success("Log deleted successfully");
                                    this.getData();
                                }

                                else {
                                    toast.error("Failed to delete Log");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }

        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }




    // getData = () => {
    //     fetch(constants.server + "api/data-retrieval/importlog/get-log-list", {
    //         method: 'get',
    //         headers: new Headers({
    //             'Authorization': this.props.token,
    //             'Content-Type': 'application/json'
    //         })
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //             let d = data.data;
    //             console.log(data);
    //             this.setState({
    //                 data: d,
    //                 filteredData: d
    //             });
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         });
    // }

    getData = async () => {
        await adminService.httpGet('/data-retrieval/importlog/get-log-list').then((res) => {

            let data = res.data;
            this.setState({ data: data, filteredData: data })

        }).catch((error) => {
            console.log(error.message)
        })
    }

}


const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
)(Log);
