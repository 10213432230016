import React from "react";
export default function MetaDataindiactor(props) {
  const subGroupData = props?.data?.subgroup;

  return (
    <>
      <div
        className="modal fade export_popups indicatormetadata"
        id="subgroupindicator"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title area_id_title" id="exampleModalLabel">
                Subgroup Classification
              </h5>
              <button
                aria-label="Close"
                className="close position-relative"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">
                  <img src="assets/lib/images/cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="table-scroll">
                <table className="table table-striped sub-group-table">
                  <thead>
                    <tr>
                    <th className="b-none a">Subgroup</th>
                    <th className="b-none a">Goal</th>
                    <th className="b-none a">Target</th>
                    <th className="b-none a">Sector</th>
                    <th className="b-none a">Sub-Sector</th>
                    </tr>
                  </thead>

                  <tbody className="table_scroll manage_goals_tbody">
                    {subGroupData?.map((item,ind) => {
                      return (
                        <>
                          <tr className="" key={ind}>
                            <td className="border-none">{item?.name?.en}</td>
                          
                            <td className="border-none">{item?.goalname}</td>
                      
                            <td className="border-none">{item?.targetname}</td>
                        
                            <td className="border-none">{item?.topicname}</td>
                          
                            <td className="border-none">{item?.subtopicname}</td>
                          </tr>
                          
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
