import { Link, useLocation } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import "./Breadcrumbs.css";
import $ from 'jquery';
const routes = [
  { path: '/admin/users/:userId', breadcrumb: 'Example 1' },
  { path: '/admin/summary', breadcrumb: ' Summary' },
  { path: '/admin/import-area', breadcrumb: 'Area' },
  { path: '/admin/manage-indicator', breadcrumb: 'Indicator' },
  { path: '/admin/import-metadata', breadcrumb: 'Indicator Metadata' },
  // { path: '/admin/disaster', breadcrumb: 'Disaster' },
  { path: '/admin/department', breadcrumb: 'Department' },
  { path: '/admin/manage-scheme', breadcrumb: 'Scheme' },
  { path: '/admin/data-entry', breadcrumb: 'Data Entry' },
  { path: '/admin/data-approve', breadcrumb: 'Data Approve' },
  { path: '/admin/manage-resource', breadcrumb: 'Information Kiosk' },
  { path: '/admin/master-list', breadcrumb: 'Master List' },
  { path: '/admin/report-settings', breadcrumb: 'Generate Report' },
  { path: '/admin/generate-report', breadcrumb: 'Generate Report' },
  { path: '/admin/user-management', breadcrumb: 'User' },


  // { path: '/admin/manage-form', breadcrumb: 'Manage Plan' },
  { path: '/admin/timeperiod', breadcrumb: 'Time Period' },
  { path: '/admin/subgroup', breadcrumb: 'Sub Group' },
  { path: '/admin/data-source', breadcrumb: 'Data Source' },

  { path: '/admin/manage-goals', breadcrumb: 'Goal and Target' },
  { path: '/admin/sector-subsector', breadcrumb: 'Sector and Sub-Sector' },
  { path: '/admin/manage-gids', breadcrumb: 'Manage GIDS' },
  { path: '/admin/export-sdmx', breadcrumb: 'Export SDMX' },
  { path: '/admin/import-sdmx', breadcrumb: 'Import SDMX' },
  { path: '/admin/mapping-sdmx', breadcrumb: 'Mapping SDMX' },
  { path: '/admin/audit-trail', breadcrumb: 'Audit Trail' },
  // { path: '/admin/form-master', breadcrumb: 'Form Master' },
  // { path: '/admin/manage-aggregation', breadcrumb: 'Manage Aggregations' },
  { path: '/admin/import-log', breadcrumb: 'Log' },
  // {path: '/admin/manage-language',breadcrumb:'Manage Language'}
]; 
    
function Breadcrumbs() {
  let pageTitle;
  const newBred = [];
  const breadcrumbs = useBreadcrumbs(routes).forEach((item)=>{
    newBred.push(item)
  if(item.match.key==item.match.url){

    pageTitle = item.match.route;

  }


  })




  const location = useLocation()
  return (


    <>

<section className="breadcrumbSection">
  <div className="container-fluid">
    <div className="breadcrumbSection__main row">
      <div className="breadcrumbSection__left col-md-4">
        <nav >
          <ol className="cd-breadcrumb custom-separator custom-icons">
            {newBred.map(({ match, breadcrumb }) => (
              <li>
                <Link key={match.url} to={match.url} className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}>
                  <a className="change-clr">
                    {breadcrumb} 
                  </a>
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="breadcrumbSection__right  col-md-4">
        <div className="admin_page_name text-center d-flex align-items-center justify-content-center">
          <h3 className="page-name">{pageTitle.breadcrumb}</h3>
        </div>
      </div>
      <div class='col-md-4'></div>
    </div>
  </div>
</section>
      {/* <section className="main-breadcrum">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 py-2 pl">
              <nav >
                <ol className="cd-breadcrumb custom-separator custom-icons">

                  {newBred.map(({ match, breadcrumb }) => (
                    <li>
                      <Link key={match.url} to={match.url} className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}>
                        <a className="change-clr">
                          {breadcrumb} 
                        </a>
                      </Link>
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
            <div className="col-md-4 text-center">
                        <div className="admin_page_name">
                                  <h3>area management</h3>
                        </div>
                      </div>
       
          </div>
        </div>
      </section> */}

    </>
  );
}
export default Breadcrumbs;
