import { Component } from 'react'
import './web-data-entry.css';
import * as jsx from './web-data-entry.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import webdataentryj from "../../../Config/web-data-enrty.json";
import { connect } from "react-redux";
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import Dropdown from 'react-bootstrap/Dropdown';



class Webdataentry extends Component {


	constructor(props) {
        super(props)
        
		this.state = {
            value: 0,
            data:[],
            nonMandatoryQuestion : [],
            lang : this.props.lang,
            filteredData:[],
            searchText: '',
            loader: false,
            defaultPage : false,
            sectorCount : [],
            gridQuestion : {},
            answersData : {},
            selectedOptionsData : {},
            selectedRow: {},
            readOnly: true,
            allquestion : [],
            count : 0,
            progressbar : 0,
            counterValue : {}
            
        }

    }

    componentDidMount = () => {
        this.getData();
        
        $(document).ready(function(){
            // $('button.nav-link.inner_tabers.d-flex.flex-column').click(function(e){
            //     debugger
            //     // e.stopImmediatePropagation();
            //     // e.preventDefault();
            //     setTimeout(function() {
            //         $('button.nav-link.inner_tabers .collapse').addClass('show');
            //     });    
            // });
        })
    }
    componentDidUpdate = () => {

        if(this.state.lang!=this.props.lang){
            this.setState({lang : this.props.lang})
            this.getData()
            console.log("language",this.props.lang)
        }
       
    }

    sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.name?.[this.props.lang];
        const b = rowB?.description?.[this.props.lang];
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    columns = [
        {
            // name: 'Form Name',
            name: webdataentryj?.formname[this.props.lang],
            // selector: row => row.name?.[this.props.lang],
            selector: row => <Tooltip title={row?.name?.[this.props.lang]} placement="top">
            <p>{row?.name?.[this.props.lang]}</p>
            </Tooltip>,
            sortable: true,
            minWidth: "200px",
            maxWidth: "280px",
            sortFunction: this.sortWithToolTip
        },
        {
            // name: 'Description',
            name: webdataentryj?.description[this.props.lang],
            // selector: row => row.description?.[this.props.lang],
            selector: row => <Tooltip title={row?.description?.[this.props.lang]} placement="top">
            <p>{row?.description?.[this.props.lang]}</p>
            </Tooltip>,
            sortable: true,
            minWidth: "200px",
            sortFunction: this.sortWithToolTip
        },
        {
            // name: 'Area',
            name: webdataentryj?.area[this.props.lang],
            selector: row => row?.area,
            sortable: true,
            width: "130px",
        },
        {
            // name: 'Disaster Type',
            name: webdataentryj?.disastertype[this.props.lang],
            selector: row => row.disaster?.[this.props.lang],
            sortable: true,
            width: "150px",
        },
        {
            // name: 'Creation Date',
            
            name: webdataentryj?.creationdate[this.props.lang],
            selector: row => dayjs(row.createdAt).format('YYYY-MM-DD | hh:mm:ss A'),
            sortable: true,
            width: '170px'
        },
        {
            // name: 'Total Question', 
            name: webdataentryj?.totalquestion[this.props.lang],
            selector: row => row.tot_questions,
            sortable: true,
            width: "140px",
        },
        {
            // name: 'Status',
            name: webdataentryj?.status[this.props.lang],
            selector: row => (row.status == 2 && row.surveyresponse?.is_completed==1 && 'Submitted') ||  (row.status == 2 && 'In Progress') || (row.status == 3 && 'Completed'),
            sortable: false,
            width: "120px",
        },
        {
            // name: 'Action',
            name: webdataentryj?.action[this.props.lang],
            selector: row =>
                <>
                    {/* <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        {webdataentryj.select[this.props.lang]}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="javascript:;" onClick={(e)=>this.view(row)}  data-toggle="modal" data-target="#form_view_popup">{webdataentryj.view[this.props.lang]}</a>
                            
                            <a class="dropdown-item" href="javascript:;" onClick={(e)=>this.fill(row)} data-toggle="modal" data-target="#form_view_popup">{webdataentryj.fill[this.props.lang]}</a>
                            <a class="dropdown-item" href="javascript:;" onClick={(e)=>this.onSubmit(row)} data-toggle="modal" data-target="#form_view_popup">{webdataentryj.submit[this.props.lang]}</a>
                        </div>
                    </div> */}
                    <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            {webdataentryj.select[this.props.lang]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                           
                            <Dropdown.Item onClick={(e)=>this.view(row)} data-toggle="modal" data-target="#form_view_popup" href="javascript:;">{webdataentryj.view[this.props.lang]}</Dropdown.Item>
                            <Dropdown.Item onClick={(e)=>this.fill(row)} disabled={row.surveyresponse?.is_completed==1? true : false} data-toggle="modal" data-target="#form_view_popup" href="javascript:;">{webdataentryj.fill[this.props.lang]}</Dropdown.Item>
                            <Dropdown.Item onClick={(e)=> row?.surveyresponse?.form_percentage!= 100? '' : this.onSubmit(row?.surveyresponse) } disabled={(row?.surveyresponse?.form_percentage!= 100 || row.surveyresponse?.is_completed==1 )? true : false} href="javascript:;">{webdataentryj.submit[this.props.lang]}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>,
            sortable: false,
            ignoreRowClick: true,
            minWidth: "170px",
            maxWidth: "170px",
            // allowOverflow: true,
            // button: true
        },
    
    ];

    view = async (row) => {
       await this.questionAnswer(row)
        
        this.setState({ selectedRow: row, readOnly: true});
    }

    


    questionAnswer = (row) => {
        let allques = []
        let nonmandatory = []
        let sec = []
        let countercheck = {}
        let  count =0
        let reqData = row?.questionsList?.filter(row => row?.mandatory == true);
        let questionLength = reqData?.length;
        if(row?.surveyresponse!=undefined){
            let anserObject = {}
            row?.questionsList?.forEach(element => {
                

                        let fd = row?.surveyresponse?.question_answer?.filter(row => row?.question_id == element?._id)
                        if(fd.length>0){
                          

                            if(element?.sub_sectionDetails?.length>0){
                                if(countercheck.hasOwnProperty(element?.sub_sectionDetails?.[0]?.name?.en)){
                                    countercheck[element?.sub_sectionDetails?.[0]?.name?.en] ++
                                }else{
                                    countercheck[element?.sub_sectionDetails?.[0]?.name?.en] = 1
                                }
                            }else{
                                if(countercheck.hasOwnProperty(element?.sectionDetails?.[0]?.name?.en)){
                                    countercheck[element?.sectionDetails?.[0]?.name?.en] ++
                                }else{
                                    countercheck[element?.sectionDetails?.[0]?.name?.en] = 1
                                }
                            }

                                if(element?.mandatory ==true){

                                    if(element?.sub_sectionDetails?.length>0){
                                        if(sec?.includes(element?.sub_sectionDetails?.[0]?.name?.en)){
                                                // console.log("thanky","!")
                                        }else{
                                            // console.log("thanky","2")
                                            sec?.push(element?.sub_sectionDetails?.[0]?.name?.en)
                                            
                                          
                                        }
                                    }else{
                                        if(sec?.includes(element?.sectionDetails?.[0]?.name?.en)){
                                            // console.log("thanky","3")
                                        }else{
                                            // console.log("thanky","4")
                                            sec?.push(element?.sectionDetails?.[0]?.name?.en)
                                            
                                        }
                                    }


                                    count+=1;
                                }
                                    
                                if(element?.type == "grid"){
                                    let subArray = []
                                    let preValue = this.state.gridQuestion

                                    fd?.[0]?.options?.forEach(elem => {

                                        let obj = {
                                            "row" : elem?.grid_row,
                                            "col" : elem?.grid_col,
                                            "ans" : elem?.ans,
                                            "grid_id" : elem?.grid_id
                                        }
                                        subArray.push(obj)

                                    });
                                    preValue[fd?.[0]?.question_id] = subArray
                                    allques.push(element?._id)
                                    nonmandatory.push(element?.title?.en?.split(".")[0])
                                    this.setState({ gridQuestion: preValue }) 

                                }else if(element?.type == "multi_select"){
                                    let optionsarr = []

                                    fd?.[0]?.options?.forEach(elem => {

                                        optionsarr.push(elem?.ans);

                                    });


                                    nonmandatory.push(element?.title?.en?.split(".")[0])
                                    allques.push(element?._id)
                                    this.setState({ selectedOptionsData : {...this.state.selectedOptionsData, [fd?.[0]?.question_id] : optionsarr} });

                                }else{
                                    nonmandatory.push(element?.title?.en?.split(".")[0])
                                    allques.push(element?._id)
                                    anserObject[fd?.[0]?.question_id] = fd?.[0]?.options?.[0]?.ans
                                    
                                }
                               
                        }

            });
            console.log("data--------------------aa",this.state.sectorCount)
            let maxValue = 100;
            let calculation = (count * maxValue) / questionLength;
            this.setState({defaultPage : true,nonMandatoryQuestion : nonmandatory ,answersData:  anserObject, allquestion : allques, count : count, progressbar : calculation, sectorCount : sec, counterValue : countercheck})
            // console.log("answerr21233",anserObject)
        }else{
            this.setState({defaultPage : true, answersData : {}, selectedOptionsData : {}, gridQuestion : {},allquestion : [], count : 0, progressbar : 0})
        }
           
        


    }

    fill = async (row) => {

           await this.questionAnswer(row)



            this.setState({ selectedRow: row, readOnly: false});
    }

    onSubmit = async (row) => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let dataToSend = [];
        dataToSend = {
            "user_id": userInfo?.id, //
            "survey_id": row?.survey_id,//
            "area_code": row?.area_code,//
            "manageform_id": row?.manageform_id,//
            "lat": row?.lat, //
            "long": row?.long, //
            "defaultsource": true,//
            "form_percentage" : row?.form_percentage,//
            "defaulttimeperiod": true,//
            "defaultsourcevalue": row?.defaultsourcevalue,//
            "save_status": row?.save_status,
            "is_completed": 1, //
            "all_files": "[]",//
            "question_answer" : row?.question_answer
        }
        await AdminService.httpPost('/data-import/survey-data-entry/add-survey-response', dataToSend).then((res)=>{
            
            // console.log("res",res)
            if(res.status==1){
                toast.success("Form submitted succesfully")
                window.location.reload(true);
                    
            }else{
                toast.error(res?.message)
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
        // console.log("form submitted", row);
    }
    
    onSearch = (val) => {
        this.setState({ searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (
                    (item.name && item.name.en?.toLowerCase().includes(val.toLowerCase()))
                || (item.description && item.description.en?.toLowerCase().includes(val.toLowerCase()))
                || (item.area && item.area?.toLowerCase().includes(val.toLowerCase()))
                || (item.disaster && item.disaster?.en?.toLowerCase().includes(val.toLowerCase()))
            ));
        }
        this.setState({ filteredData : fd});
        console.log("check area ------>");
    }


	render() {
        return jsx.default.bind(this)();
	}


    

    handleChange = (e)=>{
        this.setState({value:e})
    }

   
    getData = () => {
        this.setState({ loader: true, searchText: "" });

        let dataToBeSend = {
            status : [2,3]
        };
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
       
        console.log(userInfo);

        AdminService.httpPost('/data-import/survey/get-all-mngform-by-survey-data', dataToBeSend).then((res)=>{
            
                    if(res){

                     
                        // if(userInfo.role == 1){

                        //     let fd = res.data?.filter(row=>  row.manageform?.length > 0);
                        //     let d = [];
                        //     fd.forEach(element => {
                        //         element.manageform.forEach(row=> {
    
                        //             // console.log("disaster",row.disasterData);
                        //             // console.log("disaster row",element);
    
    
                        //             row?.assigned_user?.forEach((usr,i) => {
                                        
                        //                 if((userInfo.role == 1 || userInfo.id == usr.user_id)  && (row.status==2 || row.status==3)) {
    
                        //                         usr?.area_code?.forEach(ar => {
                                                    
                        //                             let x = JSON.parse(JSON.stringify(row)); //manageplan data
                        //                             let q = JSON.parse(JSON.stringify(element)); //form data
                        //                             let areaName = row?.areaData?.[i]?.filter(row=> row.area_code == ar);
                        //                             q.manageform = null;
                        //                             x.surveyData = q;
                                                    
                        //                             // console.log("areaname",areaName)
                        //                             d.push({
                        //                                 '_id' : row._id,
                        //                                 'name': row.name,
                        //                                 'form_id' : row.form_id,
                        //                                 'areaCode' : areaName?.[0]?.area_code,
                        //                                 'survey_id' : element._id,
                        //                                 'description': row.description,
                        //                                 'area': areaName?.[0]?.name?.[this.props.lang],
                        //                                 'disaster': row.disasterData?.[0]?.[0]?.title,
                        //                                 'createdAt': row.createdAt,
                        //                                 'tot_questions': element.questionsList?.length,
                        //                                 'status': row.status,
                        //                                 'questionsList': element.questionsList,
                        //                                 'manageForm' : x
                        //                             })
    
                        //                         });
    
    
                                          
                        //                 }
    
    
    
                        //             });
    
    
                        //             // row.areaData?.forEach(ar => {
                        //             //     if((userInfo.role == 1 || userInfo.id == row.user_id)  && (row.status==2 || row.status==3)) {
                        //             //         let x = JSON.parse(JSON.stringify(row)); //manageplan data
                        //             //         let q = JSON.parse(JSON.stringify(element)); //form data
                        //             //         q.manageform = null;
                        //             //         x.surveyData = q;
    
                        //             //         d.push({
                        //             //             '_id' : row._id,
                        //             //             'name': row.name,
                        //             //             'form_id' : row.form_id,
                                                
                        //             //             'survey_id' : element._id,
                        //             //             'description': row.description,
                        //             //             'area': ar?.[0]?.name?.[this.props.lang],
                        //             //             'disaster': row.disasterData?.[0]?.[0]?.title,
                        //             //             'createdAt': row.createdAt,
                        //             //             'tot_questions': element.questionsList?.length,
                        //             //             'status': row.status,
                        //             //             'questionsList': element.questionsList,
                        //             //             'manageForm' : x
                        //             //         })
                        //             //     }
                        //             // })
                                                                    
                        //         })
                        //     });
                        //     // d.forEach((ele  , index)=>{ele.index = index })
                        //     console.log("d-------------",d)
                        //     this.setState({filteredData: d, data:d, loader: false})

                        // }else if(userInfo.role == 2){

                            let fd = res.data?.filter(row=> row.web_view && row.manageform?.length > 0);
                            let d = [];
                            fd.forEach(element => {
                                element.manageform.forEach(row=> {
    
                                    // console.log("disaster",row.disasterData);
                                    // console.log("disaster row",element);
    
    
                                    row?.assigned_user?.forEach((usr,i) => {
                                        
                                        if(([1, 2, 6, 7].includes(userInfo.role) && (userInfo.id == usr.user_id || [1, 7].includes(userInfo.role)) )  && (row.status==2 || row.status==3)) {
    
                                                usr?.area_code?.forEach(ar => {
                                                    
                                                    let x = JSON.parse(JSON.stringify(row)); //manageplan data
                                                    let q = JSON.parse(JSON.stringify(element)); //form data
                                                    let areaName = row?.areaData?.[i]?.filter(row=> row.area_code == ar);
                                                    if(areaName?.length>0){
                                                        let surveyresponse = row?.surveyresponse?.[i]?.filter(row=> row.area_code == ar);
                                                        q.manageform = null;
                                                        x.surveyData = q;
                                                        
                                                        console.log("areaname",areaName)
                                                        d.push({
                                                            '_id' : row?._id,
                                                            'name': row?.name,
                                                            'form_id' : row?.form_id,
                                                            'areaCode' : areaName?.[0]?.area_code,
                                                            'survey_id' : element?._id,
                                                            'form_date' : row?.from_date,
                                                            'description': row?.description,
                                                            'area': areaName?.[0]?.name?.[this.props.lang],
                                                            'disaster': row?.disasterData?.[0]?.[0]?.title,
                                                            'disasterCode': row?.disasterData?.[0]?.[0]?.disaster_code,
                                                            'createdAt': row?.createdAt,
                                                            'tot_questions': element?.questionsList?.length,
                                                            'status': row?.status,
                                                            'surveyresponse' : surveyresponse?.[0],
                                                            'questionsList': element?.questionsList,
                                                            'manageForm' : x
                                                        })
        
                                                    }
                                                    
                                                });
    
    
                                          
                                        }
    
    
    
                                    });
    
    
                                    // row.areaData?.forEach(ar => {
                                    //     if((userInfo.role == 1 || userInfo.id == row.user_id)  && (row.status==2 || row.status==3)) {
                                    //         let x = JSON.parse(JSON.stringify(row)); //manageplan data
                                    //         let q = JSON.parse(JSON.stringify(element)); //form data
                                    //         q.manageform = null;
                                    //         x.surveyData = q;
    
                                    //         d.push({
                                    //             '_id' : row._id,
                                    //             'name': row.name,
                                    //             'form_id' : row.form_id,
                                                
                                    //             'survey_id' : element._id,
                                    //             'description': row.description,
                                    //             'area': ar?.[0]?.name?.[this.props.lang],
                                    //             'disaster': row.disasterData?.[0]?.[0]?.title,
                                    //             'createdAt': row.createdAt,
                                    //             'tot_questions': element.questionsList?.length,
                                    //             'status': row.status,
                                    //             'questionsList': element.questionsList,
                                    //             'manageForm' : x
                                    //         })
                                    //     }
                                    // })
                                                                    
                                })
                            });
                            // d.forEach((ele  , index)=>{ele.index = index })
                            console.log("d-------------",d)
                            this.setState({filteredData: d, data:d, loader: false})

                        // }

                        
                       
                       
                    }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

}
function mapStateToProps(state) {
    return {
      lang: state.lang?.lang
    }
  }

export default connect(
    mapStateToProps
)(Webdataentry);
