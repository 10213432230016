import React, { useState } from "react";
import './sdmx-mapping.css';
import { Tabs, Tab, Stack, Button } from "react-bootstrap";
import RegisterDSDComponent from "./registerDSDComponent";
import MappingComponent from "./mappingComponent";

const ManageMappingComponent = (props) => {

    // ============================================================= Tab Switch Start
        const [modal, setModal] = useState(false)
        const [currentTab, setCurrentTab] = useState(0);   
        const onchangeTab = (key) => {
            setCurrentTab(key)
    }
    
    const changeModal = () => {
        if (modal == false) {
            setModal(true)
        } else {
            setModal(false);
        }
    }
        const prev = () => {
        let prv = parseInt( currentTab - 1);
            if (prv >= 0) {
                setCurrentTab(prv)   
            }        
        }
        const next = () => {
            let prv = parseInt( currentTab + 1);
            if (prv <= 2) {
                setCurrentTab(prv)
            }        
        }
    // ============================================================= Tab Switch End
    return (
        <>
            <div className="sdmxMapping main">                
                <Tabs activeKey={currentTab} id="sdmxmapping" onSelect={(key) => onchangeTab(key)}>
                    <Tab eventKey="0" title={<><span>Step 01</span> <h4>Register DSD</h4></>}>
                        <RegisterDSDComponent modal={modal} changeFun={ changeModal} />
                    </Tab>
                    <Tab eventKey="1" title={<><span>Step 02</span> <h4>Mapping</h4></>}>
                        <MappingComponent />
                    </Tab>
                </Tabs> 
                

                <Stack gap={3} direction="horizontal" className="tabNavs">
                    <Button
                    className="success previous"
                    disabled={parseInt(currentTab) === 0}
                    onClick={() => prev()}
                    >
                     <img src="assets/lib/images/icon/btn_arrow.svg" /> Back
                    </Button>
                    <Button
                    className="success"
                    disabled={parseInt(currentTab) === 1}
                    onClick={() =>next() }
                    >
                    Next <img src="assets/lib/images/icon/btn_arrow.svg" />
                    </Button>
                </Stack>

                
            </div>
        </>
    );

};

export default ManageMappingComponent;