import React from "react";
import DataTable from "react-data-table-component";
import AddSchemeModal from "../../Modals/scheme.modal";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";

var ManageSchemeTemplate = function () {
  return (
    <>
      <div className="main" id="main">
        <div className="container-fluid areaCont">
          <section className="areaSection" id="areaSection">
            <subHeaderComponentMemo.default
              onSearch={this.onSearch}
              searchText={this.state.searchText}
              PropDeleteBulk={this.deleteBulkUser}
              checkData={this.state.bulkDelete}
              value="5"
              nRows={this.state?.filteredData?.length}
            />
            <DataTable
              columns={this.columns}
              data={this.state?.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              selectableRows
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              persistTableHead
              className="dataTableScroll"
              noDataComponent="No Data"
              fixedHeader
            />
          </section>
        </div>
      </div>
      <div
        id="AddAreaModal"
        className={
          this.state.modal
            ? "gallery-sidebar add-area-modal add-form width350"
            : "gallery-sidebar add-area-modal add-form width0"
        }
      >
        <div
          id="openDataModal"
          onClick={this.openAddModal}
          ariaTooltip="Button that displays and hides a tooltip triggered by other buttons"
          className=" gallery-toggler maroon_bg add-user "
          style={{
            touchAction: "none",
            userSelect: "none",
            WebkitUserDrag: "none",
            WebkitTopHighlightColor: "rgba(0, 0, 0, 0)",
          }}
          aria-describedby="cdk-describedby-message-38"
          cdk-describedby-host=""
        >
          <img
            src="assets/lib/images/svg/add1.svg"
            style={{ width: "17px" }}
            alt="add-one"
          />
        </div>
        <div class="right-tab-heading are_head">
          <h2 class="form-heading">
            <span class="form-heading_he">
              <span class="ddf">
                {this.state.isEdit === true ? "Edit Scheme" : "Add Scheme"}
              </span>
            </span>
          </h2>
        </div>
        <AddSchemeModal
          parentCallback={this.saveDisasterData}
          editMode={this.state.editModeId}
          editData={this.state.editEntry}
          closeModal={this.openAddModal}
          isEdit={this.state.isEdit}
          departmentdata={this.state?.departmentdata}
          areaTree = {this.state.areaData}
        />

        <div
          className={
            this.state.loader
              ? "loader text-center "
              : "loader text-center hide"
          }
        >
          <img
            src="assets/images/Comp-2.gif"
            style={{ marginTop: "12%", width: "30%" }}
            alt="comp"
          />
        </div>
      </div>
      <div className={this.state.modal ? "overlay dblock" : "overlay"}></div>
    </>
  );
};

export default ManageSchemeTemplate;
