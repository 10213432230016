import React from "react";
import DataTable from 'react-data-table-component';
import SubGroupAddModal from '../../Modals/subgroup.modal';
import * as $ from 'jquery';

import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import MetaDataindiactor from './metaData-indicator';
import MetaDataindiactorGoal from "./metaData-indicator-goal"

var indTemplate = function () {

    //   useEffect(() => {

    //     var toggler = document.getElementsByClassName("caret");
    //     var i;

    //     for (i = 0; i < toggler.length; i++) {
    //         toggler[i].addEventListener("click", function () {
    //             this.parentElement.querySelector(".nested").classList.toggle("active");
    //             this.classList.toggle("caret-down");
    //         });
    //     }
    // });


    return (
        <>
            <div className="main" id="main">
                <div className='container-fluid areaCont'>
                    <section className='areaSection' id="areaSection">
                        <subHeaderComponentMemo.default
                            onSearch={ this.onSearch }
                            searchText={ this.state.searchText }
                            PropDeleteBulk={ this.deleteBulkUser }
                            value='5'
                            checkData={ this.state.bulkDelete }
                            nRows={ this.state.filteredData?.length }
                        />
                        <DataTable
                            columns={ this.columns }
                            data={ this.state.filteredData }
                            direction="auto"
                            pagination
                            paginationRowsPerPageOptions={ [10, 25, 50, 100] }
                            style={ { display: "flex" } }
                            selectableRows
                            onSelectedRowsChange={ this.handleChange }
                            clearSelectedRows={ this.handleChangeData }
                            persistTableHead
                            className='dataTableScroll'
                            noDataComponent="No Data"
                            fixedHeader
                        />
                    </section>
                </div>
            </div>
            <div id="AddAreaModal" className={ this.state.modal ? 'gallery-sidebar add-area-modal add-form width90' : 'gallery-sidebar add-area-modal add-form width0' }>
                <div id="openDataModal" onClick={ () => { this.openAddModal(); $("#openDataModal").trigger("click"); } } ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={ { 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' } } aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
                    <img src="assets/lib/images/svg/add1.svg" style={ { 'width': '17px' } } alt="" />
                </div>
                <div className="right-tab-heading are_head">
                    <h2 className="form-heading">
                        <span className="form-heading_he">
                            <span className="ddf">
                                { this.state.isEdit === true ? 'Edit Indicator - Unit - Subgroup' : 'Add Indicator - Unit - Subgroup' }
                            </span>
                        </span>
                    </h2>
                </div>
                <SubGroupAddModal
                    formList={ this.state.formList }
                    clearCallback={ this.clearCallback }
                    disasterData={ this.state.disasterData }
                    indicatorData={ this.state.indicatorData }
                    unitdata={ this.state.unitdata }
                    cross={ this.state.cross }
                    indicatorId={ this.getSubIndicators }
                    subGroupData={ this.state.subIndData }
                    areaTree={ this.state.areaData }
                    newDataEntry={ this.saveData }
                    editData={ this.state.editEntry }
                    isEdit={ this.state.isEdit }
                    closeModal={ this.openAddModal }
                    getSubSector={ this.state?.getSubSector }
                    getGoals={ this.state?.getGoals }
                    subgroupDimensionData={ this.state?.subgroupDimension }
                    parentCallback={ this.callBack }
                    formData={ this.state?.formData }
                    goalAllData={ this.state?.goalAllData }
                    editMode={ this.state.editModeId }
                    getIusData={ this.state?.data }
                />
                <div className={ this.state.loader ? 'loader text-center ' : 'loader text-center hide' }>
                    <img src="assets/images/Comp-2.gif" alt="ss" style={ { 'marginTop': '12%', 'width': '30%' } } />
                </div>
            </div>
            <div className={ this.state.modal ? 'overlay dblock' : 'overlay' } ></div>
            <MetaDataindiactor data={ this.state.inddata } />
            <MetaDataindiactorGoal data={ this.state.inddata } />
        </>
    )
}

export default indTemplate;
