import React, { Component, useEffect } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Slider from "react-slick";
import { HashLink as Link } from "react-router-hash-link";
import GeoData from "../geo-data/geo-data.js";
import { resourceImagePath } from "../../../Config/app.constants";
import CountUp, { useCountUp } from "react-countup";
import homeJson from "../../../Config/home.json";
import TextField from "@mui/material/TextField";
import Wheel from './wheel.js';
import BurgerMenu from "../../layout/burger-menu";
import Tooltip from '@mui/material/Tooltip';

var homeTemplate = function (props) {

  const uniqueIusNames = new Set();
  const numericDecimals = [];
  const alphabeticDecimals = [];

  this.state.dataToDisplay?.forEach((item) => {
    item?.target?.forEach((target) => {
      const matchResult = target?.targetname.match(/Target (\d+(\.\d+)?|[a-z])/);

      if (matchResult && matchResult[1]) {
        const targetValue = matchResult[1];
        const isNumericDecimal = /^\d+(\.\d+)?$/.test(targetValue);

        if (isNumericDecimal) {
          const targetNumber = targetValue.split('.').map(part => part.padStart(2, '0')).join('');

          const sortedIus = target?.ius
            ?.slice() // Create a shallow copy of the array to avoid modifying the original
            .sort((a, b) => a.name.localeCompare(b.name));

          numericDecimals.push({ target: target?.targetname, sno: targetNumber, ius: sortedIus });
        } else {
          alphabeticDecimals.push({ target: target?.targetname, sno: targetValue });
        }
      } else {
        console.log("Target number not found in the string.");
      }
    });
  });

  // Sort numeric decimals based on the target number with custom sorting function
  numericDecimals.sort((a, b) => a.sno.localeCompare(b.sno));

  // Sort alphabetic decimals in ascending order
  alphabeticDecimals.sort((a, b) => a.sno.localeCompare(b.sno));

  // Concatenate alphabetic and numeric decimals in the desired order
  const sortedData = [...numericDecimals, ...alphabeticDecimals];


  return (
    <>
      {/* <Header /> */}

      {/* <section className={"homepage_bg grade" + this.state.activeColor} > */}
      <section className="homepage_bg bg-white">
        <div className="container-fluid h-100 p-md-0">
          <div className="row h-100 justify-content-between">
            <div className="col-12 logo-header">
            <div className="logo_area">
                <img src="assets/lib/images/new-home/tobago_logo.svg" alt="" />

                <button className="burger_box homeBurger">
                  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_431_3390)">
                      <circle cx="39" cy="36" r="30" fill="white" />
                    </g>
                    <path d="M25 27H53M25 36H53M25 45H53" stroke="#333" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                    <defs>
                      <filter id="filter0_d_431_3390" x="0" y="0" width="78" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="4.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_431_3390" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_431_3390" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                </button>
                <BurgerMenu />
              </div>
            </div>
            <div className="col-md-4 position-relative wheel-box">
              {/* <div className="white_circle">
                <img src="assets/lib/images/new-home/white_circle.png" alt="" />
              </div> */}
              <div className="sdg_wheel">
                <Wheel
                  init={true}
                  wheelCallback={this.wheelCallback}
                  sendActiveIndex={this.sendActiveIndex}
                  play={this.state.play}
                  isOverview={false}
                  activeN={0}
                />
              </div>
            </div>

            <div className="col-md-8 position-relative sdg_right_box">
              

              <div className="sdg_content">

                {
                  this.state.dataToDisplay?.map((item) => {
                    return (
                      <>
                        <div className="sdg_name position-relative">
                          <img src={this.state.goalName?.outerSVG} alt="" />
                          <span className={'goal'+(this.state.activeColor+1)}>
                            <Tooltip title={item?.goaldescription} placement="right-start">
                            <a onClick={(e)=>{this.arrowNavigationToExplore(e)}} className="goal-arrow meta-text" >
                              <img src="assets/lib/images/icon/goal-arrow.svg" alt="" />
                            </a>
                            </Tooltip>
                          </span>
                        </div>
                        <div className="sdg_box">
                          <div className="sdg_name_detail">
                            <h1>{item?.goalname}</h1>
                          </div>
                          {/* <div className="sdg_main_content">
                            <p>
                              {item?.goaldescription}
                            </p>
                          </div> */}

                        </div>
                      </>
                    )
                  })
                }

              </div>

              {/* <div className="sdg_modals_btn">
                <button className="right_border" data-toggle="modal" data-target="#overview_modal" onClick={() => this.handleOpenOverviewModal()}> <img src="assets/lib/images/new-home/overview.svg" alt="" />Overview</button>
                <button id="metadataModalButton" className="right_border" data-toggle="modal" data-target="#metadata_modal" onClick={() => this.handleOpenMetadataModal()}> <img src="assets/lib/images/new-home/metadata.svg" alt="" />Metadata </button>
                <button className="right_border"> <img src="assets/lib/images/new-home/explore.svg" alt="" /><a onClick={(e) => { this.handleNavigation(e) }} activeClassName="active">Data Analysis</a></button>
                <button> <img src="assets/lib/images/new-home/data-search.svg" alt="" /> <Link to="/dashboard/data-search" activeClassName="active"> Data Search</Link></button>
              </div> */}

              <div class="target_indi_area">            
                  <div class="autoCursor remove_cursor"> Targets <span>{this.state.targetAndIusCount.target ? this.state.targetAndIusCount.target : 0}</span></div>              
              
                  <div class="autoCursor remove_cursor"> Indicators <span>{this.state.targetAndIusCount.ius}</span></div>
                
              </div>
              <div className="sdg_modals_btn">
                <Link onClick={(e) => { this.overViewNavigation(e) }} className="right_border"> <img src="assets/lib/images/new-home/explore_icon.svg" alt="" />Explore</Link>
                {/* <Link onClick={(e)=>{this.metadataNavigation(e)}} className="right_border" id="metadataModalButton"> <img src="assets/lib/images/new-home/metadata.svg" alt="" />Metadata </Link> */}
                <Link onClick={(e)=>{this.handleNavigation(e)}} className="right_border data-icon" activeClassName="active"><img src="assets/lib/images/new-home/explore.svg" alt="" /> Data Analysis</Link>
                <Link to="/dashboard/data-search" activeClassName="active"><img src="assets/lib/images/new-home/data-search.svg" alt="" />  Search Data</Link>
                {/* <Link to="/dashboard/data-analysis" onClick={(e) => { this.handleNavigation(e) }} className="right_border" activeClassName="active"><img src="assets/lib/images/new-home/explore.svg" alt="" /> Data Analysis</Link> */}
              </div>
              {/* <div className="sdgall_icons bubbles">
                <img className="bubble x1" src="assets/lib/images/sdg-icons/1.svg" alt="" />
                <img className="bubble x2" src="assets/lib/images/sdg-icons/2.svg" alt="" />
                <img className="bubble x3" src="assets/lib/images/sdg-icons/3.svg" alt="" />
                <img className="bubble x4" src="assets/lib/images/sdg-icons/4.svg" alt="" />
                <img className="bubble x5" src="assets/lib/images/sdg-icons/5.svg" alt="" />
                <img className="bubble x6" src="assets/lib/images/sdg-icons/6.svg" alt="" />
                <img className="bubble x7" src="assets/lib/images/sdg-icons/7.svg" alt="" />
                <img className="bubble x8" src="assets/lib/images/sdg-icons/8.svg" alt="" />
                <img className="bubble x9" src="assets/lib/images/sdg-icons/9.svg" alt="" />
                <img className="bubble x10" src="assets/lib/images/sdg-icons/10.svg" alt="" />
                <img className="bubble x11" src="assets/lib/images/sdg-icons/11.svg" alt="" />
                <img className="bubble x12" src="assets/lib/images/sdg-icons/12.svg" alt="" />
                <img className="bubble x13" src="assets/lib/images/sdg-icons/13.svg" alt="" />
                <img className="bubble x14" src="assets/lib/images/sdg-icons/14.svg" alt="" />
                <img className="bubble x15" src="assets/lib/images/sdg-icons/15.svg" alt="" />
                <img className="bubble x16" src="assets/lib/images/sdg-icons/16.svg" alt="" />
                <img className="bubble x17" src="assets/lib/images/sdg-icons/17.svg" alt="" />
              </div> */}
            </div>
          </div>

        </div>
      </section>


      {/* ====================OVERVIEW MODAL START===================================== */}
      {this.state.overviewModal == true &&
        this.state.dataToDisplay?.map((item) => {
          return (<>
            <div className="modal fade home_popups" id="overview_modal" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Overview</h5>
                    <button type="button" className="fade_close" data-dismiss="modal" aria-label="Close" onClick={() => this.startWheel()}>
                      <img src="assets/lib/images/light_cross.svg" className="img-fluid" />
                    </button>
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <div className="col-md-4">
                      <div className="sdgs_icons_head">
                        <img src={this.state.goalName?.innerSVG} alt="" />
                      </div>
                      <div className="main_sdgs_area">
                        <div className="sdgs_series d-flex align-item-center">
                          {/* <div className="counting">
                            <h3>1.</h3>
                          </div> */}
                          <div className="series_content">
                            <h3>{item?.goalname}</h3>
                            <p>{item?.goaldescription}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 pr-0">
                      <div className="targets_section">
                        <div className="targets_box">
                          {sortedData?.map((target) => (
                            <div key={target.sno}>
                              <h2 className="target_head">{target.target}</h2>
                              {target.ius?.map((ius) => {
                                if (!uniqueIusNames.has(ius.name)) {
                                  uniqueIusNames.add(ius.name);
                                  return <p key={ius._id} className="target_dis">{ius.name}</p>;
                                }
                                return null; // Return null for duplicate ius names to skip rendering them
                              })}
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>)
        })
      }
      {/* ====================OVERVIEW MODAL END===================================== */}


      {/* ===============METADATA MODAL START=============== */}
      {this.state.metadataModal == true &&
        this.state.dataToDisplay?.map((item) => {
          return (
            <>
              <div className="modal fade home_popups" id="metadata_modal" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Metadata Details</h5>
                      <button type="button" className="fade_close" data-dismiss="modal" aria-label="Close" onClick={() => this.startWheel()}>
                        <img onClick={() => { this.handleModalClose() }} src="assets/lib/images/light_cross.svg" className="img-fluid" />
                      </button>
                    </div>
                    <div className="modal-body d-flex justify-content-between">
                      <div className="col-md-4">
                        <div className="sdgs_icons_head">
                          <img src={this.state.goalName?.innerSVG} alt="" />
                        </div>
                        <div className="main_sdgs_area">
                          <div className="sdgs_series d-flex align-item-center">
                            <div className="series_content">
                              <h3>{item?.goalname}</h3>
                              <div className="meta_search">
                                <div className="inner_meta_search w-100">
                                  <TextField
                                    id="metadata_search"
                                    type="search"
                                    onChange={this.onSearch}
                                    variant="outlined"
                                    placeholder="Search"
                                  />
                                </div>
                                <div className="indicator_values">
                                  {this.state.searchData?.map((ius, index) => {
                                    return (

                                      <p
                                        key={ius?.ius?._id}
                                        style={{ cursor: 'pointer' }}
                                        className={
                                          ius?.ius?._id === this.state.activeIus.id ||
                                            (!this.state.activeIus.id && index === 0)
                                            ? 'active_values'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.selectIUS(
                                            ius?.ius?.name,
                                            ius?.ius?._id,
                                            ius?.targetid,
                                            ius?.targetname,
                                            ius?.ius?.metadata?.[ius?.ius?.metadata?.length - 1]
                                          )
                                        }
                                      >
                                        {ius?.ius?.name}
                                      </p>
                                    )
                                  })}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7 pr-0">

                        <div className="targets_box">

                          <h2 className="target_head">Indicator</h2>
                          <p className="target_dis">{this.state.activeIus?.iusName}</p>

                          <h2 className="target_head">Goal</h2>
                          <p className="target_dis">{item?.goalname}</p>

                          <h2 className="target_head">Target</h2>
                          {console.log("targetname consoling-", this.state.activeIus)}
                          <p className="target_dis">{this.state.activeIus?.targetName}</p>

                          <h2 className="target_head">Indicator Definition</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt1?.en}</p>

                          <h2 className="target_head">Method of Computation</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt2?.en}</p>

                          <h2 className="target_head">Overview</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt3?.en}</p>

                          <h2 className="target_head">Comments and Limitations</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt4?.en}</p>

                          <h2 className="target_head">Data Collection for Global Monitoring</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt5?.en}</p>

                          <h2 className="target_head">Obtaining Data</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt6?.en}</p>

                          <h2 className="target_head">Data Availability</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt7?.en}</p>

                          <h2 className="target_head">Treatment of Missing Values</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt8?.en}</p>

                          <h2 className="target_head">Regional and Global Estimates</h2>
                          <p className="target_dis">{this.state.activeIus?.metaData?.mt9?.en}</p>

                        </div>
                      </div>
                    </div >
                  </div >
                </div >
              </div >
            </>
          )
        })
      }
      {/* ===============METADATA MODAL END=============== */}
      <Footer />
      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>



    </>
  );
};

export default homeTemplate;

