import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import * as $ from "jquery";

function Sidebar(props) {
  const location = useLocation();
  const route = location.pathname;

  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const role = userData?.["role"];

  useEffect(() => {
    $(".menu-collapse").on("click", function () {
      $(".inner_sidebar").toggleClass("side-bar-close");
      $(".main").toggleClass("expand-page");
      $(".footer_area").toggleClass("expand-footer");
    });
  //   $('.card-category a').on("click", function(){
  //     $('#master-list').removeClass('collapsed');
  //     $('#master-list').attr('aria-expanded="true"');
  //     $('#sub-menu2').addClass('show');
  // });
  }, [props]);

  return (
    <>
      <section className="sidebar_area">
        <div className="inner_sidebar">
          <ul id="sidebarAccordion">
            {role == 1 && (
              <>
                <Link to="/admin/summary">
                  <li className={route != "/admin/summary" ? "" : "active_bg"}>
                    <div>
                      <img src="assets/lib/images/sidebars/summary.svg" />
                    </div>
                    <span>Summary</span>
                  </li>
                </Link>
                {/* <Link>
                <li data-toggle="collapse" data-target="#multiCollapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <div className='sub-category-menu'>
                    <div><img src="assets/lib/images/sidebar_icon/home.svg" /></div>
                    <span>Home</span>
                    <img src="assets/lib/images/arrow_new.svg" className='sd_arrow' />
                  </div>
                </li>
                      <ul className="collapse sub-category-list" id="multiCollapseExample">
                        
                        <Link to="/admin/summary" >
                          <li className={route != '/admin/summary' ? '' : 'active_bg'}>
                            <div><img src="assets/lib/images/icon/logout.svg" /></div>
                            <span>Login</span>
                          </li>
                        </Link>
                        <Link to="/admin/summary" >
                          <li className={route != '/admin/summary' ? '' : 'active_bg'}>
                            <div>
                              <img src="assets/lib/images/sidebars/summary.svg" /></div>
                            <span>Summary</span>
                          </li> 
                        </Link>
                        <Link to="">
                          <li>
                            <div><img src="assets/lib/images/icon/main_admin.svg" /></div>
                            <span>Profile</span>
                          </li>
                        </Link>
                        
                          <li data-toggle="modal" data-target="#change_password">
                            <div><img src="assets/lib/images/icon/change_password.svg" /></div>
                            <span>Change Password</span>
                          </li>
                        

                      </ul>

              </Link> */}
                <Link>
                  <li
                    id="master-list"
                    data-toggle="collapse"
                    data-target="#sub-menu2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#sub-menu2"
                  >
                    <div className="sub-category-menu">
                      <div>
                        <img src="assets/lib/images/sidebar_icon/master.svg" />
                      </div>
                      <span>Master</span>
                      <img
                        src="assets/lib/images/arrow_new.svg"
                        className="sd_arrow"
                      />
                    </div>
                  </li>
                  <ul className="collapse sub-category-list" id="sub-menu2" aria-labelledby="master-list" data-parent="#sidebarAccordion">
                    <Link to="/admin/import-area">
                      <li
                        className={
                          route != "/admin/import-area" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/area.svg" />
                        </div>
                        <span>Area</span>
                      </li>
                    </Link>
                    <Link to="/admin/manage-indicator">
                      <li
                        className={
                          route != "/admin/manage-indicator" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/manage-indi.svg" />
                        </div>
                        <span>Indicator</span>
                      </li>
                    </Link>

                    <Link to="/admin/import-metadata">
                      <li
                        className={
                          route != "/admin/import-metadata" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/indi-metadata.svg" />
                        </div>
                        <span>Indicator Metadata</span>
                      </li>
                    </Link>
                    <Link to="/admin/manage-goals">
                      <li
                        className={
                          route != "/admin/manage-goals" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img class="cursor-pointer" src="assets/lib/images/new-home/goal_target.svg"/>
                        </div>
                        <span>Goal and Target</span>
                      </li>
                    </Link>
                    <Link to="/admin/sector-subsector">
                      <li
                        className={
                          route != "/admin/sector-subsector" ? "" : "active_bg"
                        }
                      >
                        <div>
                        <img class="cursor-pointer" src="assets/lib/images/new-home/sector-subsector.svg"/>
                        </div>
                        <span>Sector and Sub-Sector</span>
                      </li>
                    </Link>

                    <Link to="/admin/timeperiod">
                      <li
                        className={
                          route != "/admin/timeperiod" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/time_period.svg" />
                        </div>
                        <span>Time Period</span>
                      </li>
                    </Link>
                    <Link to="/admin/data-source">
                      <li
                        className={
                          route != "/admin/data-source" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/data-resources.svg" />
                        </div>
                        <span>Data Source</span>
                      </li>
                    </Link>
                    <Link to="/admin/manage-resource">
                  <li
                    className={
                      route != "/admin/manage-resource" ? "" : "active_bg"
                    }
                  >
                    <div>
                      <img class="cursor-pointer" src="assets/lib/images/new-home/informationkosk.svg"/>
                    </div>
                    {/* <span>Resource</span> */}
                    <span>Information Kiosk</span>
                  </li>
                </Link>
                    {/* <Link to="/admin/department">
                      <li
                        className={
                          route != "/admin/department" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/department.svg" />
                        </div>
                        <span>Department</span>
                      </li>
                    </Link> */}
                    {/* <Link to="/admin/manage-scheme">
                      <li
                        className={
                          route != "/admin/manage-scheme" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/scheme.svg" />
                        </div>
                        <span>Scheme</span>
                      </li>
                    </Link> */}

        
                  </ul>
                </Link>
                <Link>
                  <li
                    id="data-list"
                    className=""
                    data-toggle="collapse"
                    data-target="#sub-menu3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#sub-menu3"
                  >
                    <div className="sub-category-menu">
                      <div>
                        <img src="assets/lib/images/sidebar_icon/data.svg" />
                      </div>
                      <span>Data</span>
                      <img
                        src="assets/lib/images/arrow_new.svg"
                        className="sd_arrow"
                      />
                    </div>
                  </li>
                  <ul className="collapse sub-category-list" id="sub-menu3" aria-labelledby="data-list" data-parent="#sidebarAccordion">
                    <Link to="/admin/data-entry">
                      <li
                        className={
                          route != "/admin/data-entry" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/data-entry.svg" />
                        </div>
                        <span>Data Entry</span>
                      </li>
                    </Link>
                    <Link to="/admin/data-approve">
                      <li
                        className={
                          route != "/admin/data-approve" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/data-approve.svg" />
                        </div>
                        <span>Data Approve</span>
                      </li>
                    </Link>

                    <Link to="/admin/import-log">
                      <li
                        className={
                          route != "/admin/import-log" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/manage-resources.svg" />
                        </div>
                        <span>Import Log</span>
                      </li>
                    </Link>
                    <Link to="/admin/user-management">
                      <li
                        className={
                          route != "/admin/user-management" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/user.svg" />
                        </div>
                        <span>User</span>
                      </li>
                    </Link>
                  </ul>
                </Link>

                <Link>
                  <li
                    id="report-list"
                    className=""
                    data-toggle="collapse"
                    data-target="#sub-menu4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#sub-menu4"
                  >
                    <div className="sub-category-menu">
                      <div>
                        <img src="assets/lib/images/sidebar_icon/report.svg" />
                      </div>
                      <span>Report</span>
                      <img
                        src="assets/lib/images/arrow_new.svg"
                        className="sd_arrow"
                      />
                    </div>
                  </li>
                  <ul className="collapse sub-category-list" id="sub-menu4" aria-labelledby="report-list" data-parent="#sidebarAccordion">
                    <Link to="/admin/report-settings">
                      <li
                        className={
                          route == "/admin/generate-report" ||
                          route == "/admin/report-settings"
                            ? "active_bg"
                            : ""
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/generate_report.svg" />
                        </div>
                        <span>Generate Report</span>
                      </li>
                    </Link>
                    <Link to="/admin/audit-trail">
                      <li
                        className={
                          route != "/admin/audit-trail" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/audit_trail.svg" />
                        </div>
                        <span>Audit Trail</span>
                      </li>
                    </Link>
                  </ul>
                </Link>

                <Link>
                  <li
                    id="SDMX-list"
                    className=""
                    data-toggle="collapse"
                    data-target="#sub-menu5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#sub-menu5"
                  >
                    <div className="sub-category-menu">
                      <div>
                        <img src="assets/lib/images/sidebar_icon/SDMX.svg" />
                      </div>
                      <span>SDMX</span>
                      <img
                        src="assets/lib/images/arrow_new.svg"
                        className="sd_arrow"
                      />
                    </div>
                  </li>
                  <ul className="collapse sub-category-list" id="sub-menu5" aria-labelledby="SDMX-list" data-parent="#sidebarAccordion">
                    <Link to="/admin/manage-gids">
                      <li
                        className={
                          route != "/admin/manage-gids" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebars/manage-form.svg" />
                        </div>
                        <span>Manage GIDs</span>
                      </li>
                    </Link>
                    <Link to="/admin/import-sdmx">
                      <li
                        className={
                          route != "/admin/import-sdmx" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/SDMX_import.svg" />
                        </div>
                        <span>SDMX Import</span>
                      </li>
                    </Link>
                    <Link to="/admin/export-sdmx">
                      <li
                        className={
                          route != "/admin/export-sdmx" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/SDMX_export.svg" />
                        </div>
                        <span>SDMX Export</span>
                      </li>
                    </Link>
                    {/* <Link to="/admin/mapping-sdmx">
                      <li
                        className={
                          route != "/admin/mapping-sdmx" ? "" : "active_bg"
                        }
                      >
                        <div>
                          <img src="assets/lib/images/sidebar_icon/SDMX_export.svg" />
                        </div>
                        <span>SDMX Mapping</span>
                      </li>
                    </Link> */}
                  </ul>
                </Link>

                {/* <Link to="/admin/manage-goals">
                  <li
                    className={
                      route != "/admin/manage-goals" ? "" : "active_bg"
                    }
                  >
                    <div>
                      <img src="assets/lib/images/sidebar_icon/Manage_Goals_and_targets.svg" />
                    </div>
                    <span>Goals & Targets</span>
                  </li>
                </Link>
                <Link to="/admin/topic-subtopic">
                  <li
                    className={
                      route != "/admin/topic-subtopic" ? "" : "active_bg"
                    }
                  >
                    <div>
                      <img src="assets/lib/images/sidebar_icon/Manage_Goals_and_targets.svg" />
                    </div>
                    <span>Topics & Sub-Topics</span>
                  </li>
                </Link> */}

                {/* <Link to="/admin/master-list"> 
                        <li className={route!='/admin/master-list'? '':'active_bg'}>
                          <div><img src="assets/lib/images/sidebars/masterlist.svg"/></div>
                          <span>Master List</span>
                        </li>
                    </Link>        */}

                {/* <Link to="/admin/manage-resource">
                  <li
                    className={
                      route != "/admin/manage-resource" ? "" : "active_bg"
                    }
                  >
                    <div>
                      <img src="assets/lib/images/sidebars/data-resources.svg" />
                    </div>
                    <span>Resource</span>
                  </li>
                </Link> */}
              </>
            )}

            {role == 2 && (
              <>
                <Link to="/admin/data-entry">
                  <li
                    className={route != "/admin/data-entry" ? "" : "active_bg"}
                  >
                    <div>
                      <img src="assets/lib/images/sidebars/data-entry.svg" />
                    </div>
                    <span>Data Entry</span>
                  </li>
                </Link>
                <Link to="/admin/import-log">
                  <li
                    className={route != "/admin/import-log" ? "" : "active_bg"}
                  >
                    <div>
                      <img src="assets/lib/images/sidebars/manage-resources.svg" />
                    </div>
                    <span>Log</span>
                  </li>
                </Link>
              </>
            )}

            {role == 4 && (
              <>
                <Link to="/admin/data-approve">
                  <li
                    className={
                      route != "/admin/data-approve" ? "" : "active_bg"
                    }
                  >
                    <div>
                      <img src="assets/lib/images/sidebars/data-approve.svg" />
                    </div>
                    <span>Data Approve</span>
                  </li>
                </Link>
              </>
            )}
          </ul>

          <div className="menu-collapse">
            <img src="assets/lib/images/sidebars/collapse-icon.svg" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
