import React, { Component, useEffect, useRef, useState } from 'react';
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import * as $ from "jquery";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "@mui/material/Tooltip";
import domtoimage from "dom-to-image";
import geoJSONData1 from "../../../Config/map/json/level1.json";
import geoJSONData2 from "../../../Config/map/json/level2.json";
// import geoJSONData3 from "../../Config/map/json/TLS_L03_2019.json";
// import geoJSONData4 from "../../Config/map/json/TLS_L04_2019.json";
import dataSearchResultLang from '../../../Config/data-search-results-component.json';
import DataTable from 'react-data-table-component';

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from '@mui/material';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
} from 'react-share';
import adminService from '../../../services/admin.service';
import * as constants from "../../../../src/Config/app.constants";


var AnalysisChart = function (props) {

    const fbShareButton = useRef(null);
    const lnShareButton = useRef(null);
    const twShareButton = useRef(null);

    const [state, setState] = useState([]);

    const location = useLocation();
    const route = location.pathname;
    // const [chartRefs, setChartRefs] = useState({});
    const chartRefs = useRef(null);
    // const [flag, setFlag] = useState(false);
    const [dat, setDat] = useState([]);
    const [tpMenuItems, setTPMenuItems] = useState([]);
    const [sgMenuItems, setSGMenuItems] = useState([]);
    const [chartTypes, setChartTpes] = useState({})
    const [showData, setShowData] = useState([]);
    const [noData, setNoData] = useState(false);
    const [label, setLabel] = useState(false);
    const [legend, setLegend] = useState([]);
    const [title, setTitle] = useState("");
    const [swap, setSwap] = useState(false);
    const [zoomData, setZoomData] = useState(1.25);
    const [type, setType] = useState('bar');
    const formatUtil = echarts.format;
    const [uCase, setUCase] = useState(null);
    const [isCompass, setIsCompass] = useState(false);
    const [srcList, setSourceList] = useState([]);
    const [indicator, setIndicator] = useState("");
    const [timeperiod, setTimeperiod] = useState("");
    const [source, setSource] = useState([]);
    const [backupDat, setBakupDat] = useState([])

    const [isAllSelectedSub, setIsAllSelectedSub] = useState(false);
    const [isAllSelectedTp, setIsAllSelectedTp] = useState(false);
    const [selectedSub, setSelectedSub] = useState([]);
    const [selectedTp, setSelectedTp] = useState([]);
    const [colorPaletteState, setColorPalette] = useState([]);

    const [tableState, setTable] = useState({
        filteredData: [],
        searchText: ''
    })


    const [loader, setLoader] = useState(false);


    const [mapState, setMapState] = useState({
        uniIndicators: [],
        uniNewIndicators: [],
        uniTP: [],
        uniSrc: [],
    });

    // const [loader, setLoader] = useState(false);
    const [url, setUrl] = useState("none");
    const [sector, setSector] = useState("");
    const [iuId, setIuId] = useState("");
    const [buttonType, setButtonType] = useState("");
    const [base64Data, setBase64Data] = useState(null);
    // const captureElement = useRef(null);

    const getBase64 = async (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const [shareType, setShareType] = useState('fb');

    useEffect(() => {
        if (url !== "none") {
            if (shareType == 'fb') {
                fbShareButton.current?.click();
            } else if (shareType == 'ln') {
                lnShareButton.current?.click();
            } else {
                twShareButton.current?.click();
            }
            setUrl("none");
        }
    }, [url, fbShareButton, lnShareButton, twShareButton]);

    const onClick = async (sType = 'fb') => {
        setShareType(sType);
        // Be sure to check for the "none" state, so we don't trigger an infinite loop.
        if (url === "none") {
            const newUrl = await handleCaptureImage();
            setUrl(newUrl);
        }
    }

    const onChangeSrc = (val, type, e) => {
        let s = [...source];
        // if(label){
        //   setLabel(true)
        // }
        // setFlag(true);
        if (val === '*') {
            if (e.target.checked) {
                // Select All
                s = srcList.filter((item) => item !== '*');
                s.push(val);
            } else {
                // Deselect All
                s = [];
            }
        } else {
            if (e.target.checked) {
                // Select an option
                s.push(val);
            } else {
                // Deselect an option
                s = s.filter((item) => item !== val);
            }

            // Check if 'Select All' should be deselected
            if (s.includes('*') && s.length > 1) {
                s = s.filter((item) => item !== '*');
            }
        }

        const allOtherCheckboxesSelected = srcList.filter((item) => item !== '*').every((item) => s.includes(item));

        // Set 'Select All' checkbox to be checked if all others are selected
        if (allOtherCheckboxesSelected) {
            s.push('*');
        } else {
            s = s.filter((item) => item !== '*');
        }

        setSource(s.filter((x)=> x !== 'None Selected'));
    };

    // const onChangeSrc = (val , key, e)=>{
    //   // setFlag(true);
    //   let array = [];
    //   if(val == '*' && e.target.checked){
    //     let item = srcList.filter((x)=> x !== '*');
    //     array.push(item);

    //   } else if(val == '*' && !e.target.checked){
    //     array = []; 
    //   }
    //   else if(val !== '*' && e.target.checked){
    //     let item = srcList.filter((x)=> x == val);
    //     array.push(item);
    //   }
    //   else{
    //     array = array.filter((item)=> item !== val);
    //   }

    //   setSource(array.flat(Infinity));


    // }

    const handleCaptureImage = () => {
        return new Promise((resolve, reject) => {
            setLoader(true);



            $("div#og").css({ 'height': 'auto', 'padding': '10px', 'width': '100%' });
            $(".drsIndicators_img").hide();
            $("p.sub-category-text, div.mapSource").removeClass('indDD')
            $(".dsrBarchart_list").hide();
            $(".legends, .filter, .content_arrow ").hide();
            $(".drs_sources").hide();

            $(".multiple-value.drsChartss").css('flex-direction', 'column')
            $(".dsrChart .maps_source").css('position', 'unset')
            $(".dsrDropdown").addClass("dsrdropDownload")
            $(".legendsNumber.legendsChart").css({ 'bottom': '40px', 'left': '10px' });
            // mapSource-hide

            // $(".dropdown-toggle").hide();

            let node1 = document.getElementById("og");
            let node2 = document.getElementById("copy");
            let mapSource = document.getElementById("downloadchartds");

            mapSource.style.display = "block !important";

            node2.innerHTML = node1.innerHTML;
            node2.style.display = "block";
            // $(".copy .secInd").removeClass('indDD')

            let div = $(".downloadChartBG");
            div.css("background-color : white");

            domtoimage.toBlob(node1).then((blob) => {
                let file = [];
                let bfile = "";
                let path = "";
                getBase64(blob).then((result) => {
                    file["base64"] = result;
                    let b = file.base64;
                    bfile = b.split(",").pop();
                    const dt = Date.now();
                    path = `SDG_${dt}.png` + ";" + bfile;
                    let dataToSend = {
                        image: path,
                    };

                    adminService
                        .httpPost("/data-import/resources/add-image", dataToSend)
                        .then((res) => {
                            //   setLoader(false);
                            if (res.success == true) {
                                resolve(constants.resourceImagePath + res.file);
                                setUrl(constants.resourceImagePath + res.file)
                                // $("#main_ind_viewsss").find("button").show();
                                node2.style.display = "none";
                            } else {
                                reject(null);
                                console.log("error")
                                setUrl("none");
                                node2.style.display = "none";
                                // $("#main_ind_viewsss").find("button").show();
                            }
                        });
                    // captureElement.current.removeChild(additionalContentElement);


                });
            })
                .catch((error) => {
                    console.error("Error capturing image:", error);
                })
                .finally(() => {
                    $("p.sub-category-text,  div.mapSource").addClass('indDD')
                    $(".dsrBarchart_list").show();
                    $(".drsIndicators_img").show();
                    $(".legends, .filter, .content_arrow ").show();
                    $(".drs_sources").show();
                    $(".multiple-value.drsChartss").css('flex-direction', '')
                    $('.downloadchartds').css('display', 'block');

                    $(".dsrChart .maps_source").css('position', 'absolute')
                    $("div#og").css({ 'height': '100%', 'padding': '', 'width': '' });
                    $(".dsrDropdown").removeClass("dsrdropDownload");
                    $(".legendsNumber.legendsChart").css({ 'bottom': '20px', 'left': '0px' });
                    node2.style.display = "none";

                    setLoader(false);

                });


            // });

        })
    };


    useEffect(() => {

        if (props.activeN) {
            let colorPalette = [];

            switch (props.activeN) {
                case 1:
                    // colorPalette = ['#e8253d', '#ec5064', '#f17c8b', '#f6a8b2', '#fbd4d9', '#feeef0'];
                    // colorPalette = ['#FAD3D8', '#F5A7B1', '#EF7C89', '#EA5062', '#E5243B', '#B71D2F', '#891623', '#5C0E18', '#2E070C'];
                    colorPalette = ["#A01929", "#B71D2F", "#CE2035", "#E5243B", "#E83A4F", "#EA5062", "#ED6676", "#EF7C89", "#EDA2AA"]
                    break;
                case 2:
                    // colorPalette = ['#e4b631', '#e9c45a', '#efd383', '#f4e2ad', '#faf1d6', '#fdfaef'];
                    // colorPalette = ['#F8EDD8', '#F1DBB0', '#EBCA89', '#E4B861', '#DDA63A', '#B1852E', '#856423', '#584217', '#2C210C'];
                    colorPalette = ["#9B7429", "#B1852E", "#C79534", "#DDA63A", "#E0AF4E", "#E4B861", "#E7C175", "#EBCA89", "#F4D8A1"];
                    break;
                case 3:
                    // colorPalette = ['#4a9e46', '#6eb16b', '#92c590', '#b7d8b5', '#dbecda', '#f1f8f1'];
                    // colorPalette = ['#DBECD7', '#B7D9AF', '#94C588', '#70B260', '#4C9F38', '#3D7F2D', '#2E5F22', '#E4016', '#0F200B'];
                    colorPalette = ["#356F27", "#3D7F2D", "#448F32", "#4C9F38", "#5EA94C", "#70B260", "#82BC74", "#94C588", "#AED0A6"]
                    break;
                case 4:
                    // colorPalette = ['#c4222f', '#d04e58', '#dc7a82', '#e8a7ac', '#f4d3d6', '#fbeeef'];
                    // colorPalette = ['#F3D1D5', '#E8A3AB', '#DC7581', '#D14757', '#C5192D', '#9E1424', '#760F1B', '#4F0A12', '#270509'];
                    colorPalette = ["#8A121F", "#9E1424", "#B11729", "#C5192D", "#CB3042", "#D14757", "#D65E6C", "#DC7581", "#EB929C"]
                    break;
                case 5:
                    // colorPalette = ['#ee412b', '#f16755', '#f58d80', '#f8b3aa', '#fcd9d5', '#fef0ee'];
                    // colorPalette = ['#FFD8D3', '#FFB0A6', '#FF897A', '#FF614D', '#FF3A21', '#CC2E1A', '#992314', '#66170D', '#330C07'];
                    colorPalette = ["#B32917", "#CC2E1A", "#E6341E", "#FF3A21", "#FF4E37", "#FF614D", "#FF7564", "#FF897A", "#FFA69B"]
                    break;
                case 6:
                    // colorPalette = ['#29bce1', '#54c9e7', '#7fd7ed', '#aae4f3', '#d5f2f9', '#eefafd'];
                    // colorPalette = ['#D4F2F9', '#A8E5F3', '#7DD7EE', '#51CAE8', '#26BDE2', '#1E97B5', '#177188', '#0F4C5A', '#08262D'];
                    colorPalette = ["#1B849E", "#1E97B5", "#22AACB", "#26BDE2", "#3CC4E5", "#51CAE8", "#67D1EB", "#7DD7EE", "#9FE5F6"]
                    break;
                case 7:
                    // colorPalette = ['#f7c318', '#f8cf46', '#fadb74', '#fce7a3', '#fef3d1', '#fffbed'];
                    // colorPalette = ['#FEF3CE', '#FEE79D', '#FDDB6D', '#FDCF3C', '#FCC30B', '#CA9C09', '#977057', '#654E04', '#322702'];
                    colorPalette = ["#B08908", "#CA9C09", "#E3B00A", "#FCC30B", "#FCC923", "#FDCF3C", "#FDD554", "#FDDB6D", "#FFE799"]
                    break;
                case 8:
                    // colorPalette = ['#a21c45', '#b4496a', '#c7778f', '#daa4b5', '#edd2da', '#f8edf1'];
                    // colorPalette = ['#ECD1D9', '#DAA3B3', '#C7758E', '#B54768', '#A21942', '#821435', '#610F28', '#410A1A', '#20050D'];
                    colorPalette = ["#71122E", "#821435", "#92173B", "#A21942", "#AB3055", "#B54768", "#BE5E7B", "#C7758E", "#D794A8"]
                    break;
                case 9:
                    // colorPalette = ['#f16b2d', '#f48857', '#f7a681', '#fac4ab', '#fde2d5', '#fef4ef'];
                    // colorPalette = ['#FFE1D3', '#FEC3A8', '#FEA57C', '#FD8751', '#FD6925', '#CA541E', '#983F16', '#652A0F', '#331507'];
                    colorPalette = ["#B14A1A", "#CA541E", "#E45F21", "#FD6925", "#FD783B", "#FD8751", "#FE9666", "#FEA57C", "#FFBB9C"]
                    break;
                case 10:
                    // colorPalette = ['#dc1768', '#e34586', '#ea74a4', '#f1a2c3', '#f8d1e1', '#fdedf3'];
                    // colorPalette = ['#F8D0E1', '#F1A1C2', '#EB71A4', '#E44285', '#DD1367', '#B10F52', '#850B3E', '#580829', '#2C0415'];
                    colorPalette = ["#9B0D48", "#B10F52", "#C7115D", "#DD1367", "#E02B76", "#E44285", "#E75A95", "#EB71A4", "#F491BA"]
                    break;
                case 11:
                    // colorPalette = ['#f89f27', '#f9b252', '#fbc57d', '#fcd9a9', '#feecd4', '#fff8ee'];
                    // colorPalette = ['#FFEBD3', '#FED8A7', '#FEC47C', '#FDB150', '#FD9D24', '#CA7E1D', '#985E16', '#653F0E', '#331F07'];
                    colorPalette = ["#B16E19", "#CA7E1D", "#E48D20", "#FD9D24", "#FDA73A", "#FDB150", "#FEBA66", "#FEC47C", "#FFD6A4"]
                    break;
                case 12:
                    // colorPalette = ['#c5962d', '#d0ab57', '#dcc081', '#e8d5ab', '#f4ead5', '#fbf7ef'];
                    // colorPalette = ['#F2E8D5', '#E5D1AB', '#D9B982', '#CCA258', '#BF8B2E', '#996F25', '#73531C', '#4C3812', '#261C09'];
                    colorPalette = ["#866120", "#996F25", "#AC7D29", "#BF8B2E", "#C59743", "#CCA258", "#D2AE6D", "#D9B982", "#F0D4A4"]
                    break;
                case 13:
                    // colorPalette = ['#428044', '#689969', '#8eb38f', '#b4ccb4', '#c6dcc6', '#ecf7ec'];
                    // colorPalette = ['#D9E5DA', '#B2CBB4', '#8CB28F', '#659869', '#3F7E44', '#326536', '#264C29', '#19321B', '#0D190E'];
                    colorPalette = ["#2C5830", "#326536", "#39713D", "#3F7E44", "#528B57", "#659869", "#79A57C", "#8CB28F", "#A6C5A9"]
                    break;
                case 14:
                    // colorPalette = ['#1b95d2', '#48aadb', '#76bfe4', '#a4d5ed', '#d2eaf6', '#edf7fc'];
                    // colorPalette = ['#CEEAF7', '#9DD5F0', '#6CC1E8', '#3BACE1', '#0A97D9', '#0879AE', '#065B82', '#043C57', '#021E2B'];
                    colorPalette = ["#076A98", "#0879AE", "#0988C3", "#0A97D9", "#23A1DD", "#3BACE1", "#54B6E4", "#6CC1E8", "#87D1F3"]
                    break;
                case 15:
                    // colorPalette = ['#60ba46', '#80c86b', '#a0d690', '#c0e4b5', '#e0f2da', '#f3faf1'];
                    // colorPalette = ['#DDF2D5', '#BBE6AA', '#9AD980', '#78CD55', '#56C02B', '#459A22', '#34731A', '#224D11', '#112609'];
                    colorPalette = ["#3C861E", "#459A22", "#4DAD27", "#56C02B", "#67C640", "#78CD55", "#89D36B", "#9AD980", "#B3EB9C"]
                    break;
                case 16:
                    // colorPalette = ['#056a9d', '#3788b0', '#69a6c4', '#9bc4d8', '#cde2ec', '#ebf4f8'];
                    // colorPalette = ['#CCE1EB', '#99C3D8', '#66A4C4', '#3386B1', '#00689D', '#00537E', '#003E5E', '#002A3F', '#00151F'];
                    colorPalette = ["#00496E", "#00537E", "#005E8D", "#00689D", "#1A77A7", "#3386B1", "#4D95BA", "#66A4C4", "#83BCD9"]
                    break;
                case 17:
                    // colorPalette = ['#19486a', '#476c88', '#7591a6', '#a3b6c4', '#d1dbe2', '#edf1f4'];
                    // colorPalette = ['#D1DAE1', '#A3B6C3', '#7591A6', '#476D88', '#19486A', '#143A55', '#0F2B40', '#0A1D2A', '#050E15'];
                    colorPalette = ["#12324A", "#143A55", "#17415F", "#19486A", "#305A79", "#476D88", "#5E7F97", "#7591A6", "#91A8B9"]
                    break;
                default:
                    // colorPalette = ['#FAD3D8', '#F5A7B1', '#EF7C89', '#EA5062', '#E5243B', '#B71D2F', '#891623', '#5C0E18', '#2E070C'];
                    colorPalette = ["#A01929", "#B71D2F", "#CE2035", "#E5243B", "#E83A4F", "#EA5062", "#ED6676", "#EF7C89", "#EDA2AA"];
                    break;
            }

            setColorPalette(colorPalette.reverse());
        }
    }, [props.activeN])

    let chartInstance = null;
    const defOption = {
        title: {
            show: true,
            textStyle: {
                color: "#333",
                fontSize: 14,
                fontFamily: "Mulish",
                fontWeight: "400",
            },
            text: "Loading...",
            left: "center",
            top: "center",
        },
        xAxis: {
            show: false,
        },
        yAxis: {
            show: false,
            axisLabel: {
                width: 140,
                lineHeight: 10,
                overflow: "breakAll"
            },
        },
        series: [],
    };
    const [optionD, setOptionD] = useState(defOption);
    const legendFormatter = (value) => {
        if (isNaN(value)) {
            return "No Data";
        }
        return value;
    };
    // echarts.use([
    //   TitleComponent,
    //   TooltipComponent,
    //   GridComponent,
    //   LegendComponent,
    //   BarChart,
    //   CanvasRenderer,
    // ]);

    const changeChartType = (type, id) => {
        let objectDt = chartTypes
        objectDt[id] = type;
        setType(type);

        setChartTpes(objectDt);
        setLabel(false)
        setLegend(false)


    }
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        let filteredData = props.chartData?.filter(x => source.includes(x.source));
        const allSubgroups = [...new Set(filteredData?.map(item => item?.subgroup))];
        setIsAllSelectedSub(selectedSub.length === allSubgroups.length);

        // const allTimeperiods = [...new Set(filteredData?.map(item => item?.timeperiod))];

        let tp = [];
        let sub = filteredData?.filter((x) => { return (selectedSub?.includes(x.subgroup)) });
        sub?.map((t) => {
            return (
                tp.push(t?.timeperiod)
            )
        });
        setIsAllSelectedTp(selectedTp?.filter((x) => x !== 'None Selected').length === [...new Set(tp)].length);

        if (selectedSub.includes('None Selected') && selectedTp.includes('None Selected')) {
            setDat([]);
            setIsAllSelectedSub(false);
            setIsAllSelectedTp(false);
        }
    }, [selectedSub, selectedTp]);

    useEffect(() => {

        let filteredData = props.chartData?.filter(x => source.includes(x.source));

        setDat(filteredData);
        let mainChartData = filteredData;
        setTable({ ...tableState, filteredData: mainChartData })

    }, [props.chartData, props.metaData, props.exData]);

    useEffect(() => {

        let srcList = [];
        // props.chartData.map((x) => {
        //   if (!srcList.includes(x.source)) {
        //     srcList.push(x.source);
        //   }
        // });
        // console.log("srcList data-", srcList)
        // setSourceList(srcList);
        // if (srcList?.length > 0 && !srcList.includes(source)) {
        //   setSource(srcList[0]);
        // }

        props.chartData.map((x) => {
            if (!srcList.includes(x.source)) {
                srcList.push(x.source);
            }
        });
        // setFlag(false)
        setSourceList(srcList);
        // if (srcList?.length > 0) {
        //   setFlag(false);
        //   let a = JSON.parse(JSON.stringify(srcList));
        //   a.push('*');
        //   setSource(a);
        // }
        // setDat(props.chartData?.filter(x => x.source == srcList[0]))
        renderChart(type);

    }, [dat, type])

    useEffect(() => {
        let sData = props.chartData?.filter((x) => source.includes(x.source));
        let data = sData;
        setSGMenuItems(generateSubgroupMenuItems(sData, selectedSub));
        setTPMenuItems(generateTimeperiodMenuItems(data, selectedTp, selectedSub));
    }, [backupDat, props.chartData, selectedTp, source]);

    useEffect(() => {

        // if (flag) {
        if (source.filter((x) => x !== '*').length == 0) {
            setSelectedSub(['None Selected']);
            setSelectedTp(['None Selected']);
            setSource(['None Selected']);
            setIsAllSelectedSub(false);
            setIsAllSelectedTp(false);
            setDat([]);
        } else {
            let filteredData = props.chartData.filter(x => source.includes(x.source));
            const allSubgroups = [...new Set(filteredData?.map(item => item?.subgroup))];
            if (allSubgroups?.length >= 5) {
                setSelectedSub(allSubgroups.slice(0, 5));
            } else {

                setSelectedSub(allSubgroups);
            }
            setIsAllSelectedSub(selectedSub.length === allSubgroups.length);

            const allTimeperiods = [...new Set(filteredData?.map(item => item?.timeperiod))]
                .sort((a, b) => parseInt(b) - parseInt(a)); // Sorting in descending order

            if (allTimeperiods?.length >= 5) {
                setSelectedTp(allTimeperiods.slice(0, 5)); // select the top 5 (most recent) time periods
            } else {
                setSelectedTp(allTimeperiods);
            }

            const selectedTpValue = allTimeperiods?.length >= 5 ? allTimeperiods.slice(0, 5) : allTimeperiods;

            setIsAllSelectedTp(selectedTpValue.length === allTimeperiods.length);

            let CheckData = filteredData?.filter((x) => allSubgroups?.length >= 5 ? allSubgroups.slice(0, 5)?.includes(x.subgroup) : allSubgroups?.includes(x.subgroup));
            const data = CheckData?.filter((x) => selectedTpValue.includes(x.timeperiod));
            setDat(data);


        }

        if (label) {
            setLabel(true)
        }

        if (legend) {
            setLegend(true)
        }


        // }

        // let filteredData = props?.chartData?.filter(x => source.includes(x.source));
        // setDat(filteredData);
        // setBakupDat(filteredData);

        // let tpData = [];
        // let subData = [];
        // filteredData?.map((item) => {
        //   tpData.push(item.timeperiod);
        //   subData.push(item.subgroup);
        // });
        // setSelectedSub([...new Set(subData)]);
        // setSelectedTp([...new Set(tpData)]);
        // console.log("isSourceChanged?", props.chartData?.filter(x => source.includes(x.source)))
        // console.log("isSourceChanged?222222", tpData, 'ss', subData)

    }, [source, label])

    useEffect(() => {
        if (props?.chartData?.length > 0) {
            let srcList = [];

            props.chartData.map((x) => {
                if (!srcList.includes(x.source)) {
                    srcList.push(x.source);
                }
            });
            setSourceList(srcList);
            if (srcList?.length > 0) {
                // setFlag(false);
                let a = JSON.parse(JSON.stringify(srcList));
                a.push('*');
                setSource(a);
            }
            let filteredData = props.chartData.filter(x => srcList.includes(x.source));
            const initialSelectionSub = [...new Set(filteredData?.map(item => item?.subgroup))];
            let dataSub = initialSelectionSub?.slice(0, 5)

            const CheckData = filteredData?.filter((x) => dataSub?.includes(x.subgroup));

            const initialSelectionTP = [...new Set(filteredData?.map(item => item?.timeperiod))];
            setSelectedTp(initialSelectionTP);
            setSelectedSub(dataSub);
            setIsAllSelectedSub(true);
            setIsAllSelectedTp(true);
            setBakupDat([]);
            setDat(CheckData);
        }
    }, [props.chartData]);





    const sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.indicator;
        const b = rowB?.indicator;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    const sortArea = (rowC, rowD) => {
        const a = rowC?.area;
        const b = rowD?.source;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    const sortSource = (rowC, rowD) => {
        const a = rowC?.source;
        const b = rowD?.source;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };



    const columns = [
        // {
        //     name: "Indicator",
        //     selector: row => <><Tooltip title={`${row.indicator} | ${row.unit} | ${row.subgroup}`} placement="top">
        //         <p>{row.indicator} <span className='dsrt_unit'>| {row.unit}</span> <span className='dsrt_unit'> | {row.subgroup}</span></p>
        //     </Tooltip></>,
        //     minWidth: "250px",
        //     sortFunction: sortWithToolTip,
        //     sortable: true,

        // },
        {
            name: "Area",
            selector: row => row.area,
            width: "200px",
            sortFunction: sortArea,
            sortable: true,
        },
        {
            name: "Subgroup",
            selector: row => row?.subgroup,
            width: "200px",
            sortFunction: sortSource,
            sortable: true,
        },
        {
            name: "Time Period",
            selector: row => row.timeperiod,
            width: "200px",
            sortable: true,
        },
        {
            name: "Data Value",
            selector: row => row.value,
            width: "150px",
            sortable: true,
        },
        {
            name: "Source",
            selector: row => <Tooltip title={ row.source } placement="top"><p>{ row.source }</p></Tooltip>,
            width: "200px",
            sortFunction: sortSource,
            sortable: true,
        },
    ];



    const onSearch = (val) => {
        let d = this.props.data;
        let fd = [];
        if (val == "") {
            fd = d;
        } else {
            fd = d.filter(
                (item) =>
                    (item.area && item.area.toLowerCase().includes(val.toLowerCase()))
                    || (item.indicator && item.indicator.toLowerCase().includes(val.toLowerCase()))
                    || (item.timeperiod && item.timeperiod.toLowerCase().includes(val.toLowerCase()))
                //   || (item.datavalue && item.datavalue.toLowerCase().includes(val.toLowerCase()))
            );
        }

        // setState({ filteredData: fd, searchText: val });
    };


    // for (const [key, value] of Object.entries(state)) {
    //   data.push({
    //     name: key,
    //     value:value? value:'',
    //   })
    // }

    const colors = {
        0: '#f4002b1f',
        1: '#f4002b4f',
        2: '#f4002b63',
        3: '#f4002b73',
        4: '#f4002b9c',
        5: '#f4002bc2',
        6: '#f4002bde',
        7: '#f4002b',
    };

    // const option = {
    //   title: {
    //     text: '',
    //   },

    //   grid: {
    //     show: false,
    //     containLabel: true,
    //     left: '2%',
    //     bottom: '3%',
    //     top: '3%'
    //   },
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'shadow',
    //     },
    //   },

    //   strokeWidth: 0,
    //   xAxis: {
    //     show: false,


    //     splitLine: {
    //       show: false
    //     }
    //   },
    //   yAxis: {
    //     type: 'category',
    //     symbol: 'none',
    //     lineThickness: 0,
    //     gridThickness: 0,
    //     tickLength: 0,
    //     splitLine: {
    //       show: false
    //     },
    //     axisTick: {
    //       show: false
    //     },
    //     axisLine: {
    //       show: false
    //     },

    //     data: data.sort((a, b) => { return a.value - b.value; }).map((item) => item.name),
    //   },
    //   series: [
    //     {
    //       type: 'bar',
    //       data: data.sort((a, b) => { return a.value - b.value; }).map((item) => item.value),
    //       itemStyle: {
    //         color: function (param) {

    //           return colors[param.dataIndex];
    //         },
    //       },
    //       label: {
    //         show: true,
    //         position: 'right',
    //         valueAnimation: true,
    //         fontSize: 16,
    //         fontFamily: 'Mulish',
    //         fontWeight: 500,
    //         color: '#666666',
    //       }
    //     },
    //   ],
    // }

    const onSwap = (temp) => {
        renderChart(type, temp);
        let image = document.getElementById("label");
        if (image) {
            image.src = "assets/lib/images/home/label_on.svg";
        }

    };

    const toggleCompass = () => {
        if (isCompass) {
            setIsCompass(false);
        } else {
            setIsCompass(true);
        }
    };

    const getUseCase = () => {
        let [ind, unit, subgroup, area, timeperiod] = ["", "", "", "", ""];
        let [cind, cunit, csubgroup, carea, ctimeperiod] = [0, 0, 0, 0, 0];
        let d = [];

        let inds = [];
        let newInds = [];
        let tperiods = {};
        let areas = [];
        let src = {};
        let filteredData = props.chartData.filter(x => source.includes(x.source));
        let mainDat = (dat && dat?.length > 0) ? dat : selectedTp?.includes('None Selected') || selectedSub?.includes('None Selected') ? [] : filteredData;

        for (let row of mainDat) {

            if (ind == "") {
                ind = row.indicator;
                cind += 1;
            } else if (ind != row.indicator) {
                cind = cind <= 1 ? cind + 1 : cind;
            }

            if (unit == "") {
                unit = row.unit;
                cunit += 1;
            } else if (unit != row.unit) {
                cunit = cunit <= 1 ? cunit + 1 : cunit;
            }

            if (subgroup == "") {
                subgroup = row.subgroup;
                csubgroup += 1;
            } else if (subgroup != row.subgroup) {
                csubgroup = csubgroup <= 1 ? csubgroup + 1 : csubgroup;
            }

            if (area == "") {
                area = row.area_code;
                carea += 1;
            } else if (area != row.area_code) {
                carea = carea <= 1 ? carea + 1 : carea;
            }

            if (timeperiod == "") {
                timeperiod = row.timeperiod;
                ctimeperiod += 1;
            } else if (timeperiod != row.timeperiod) {
                ctimeperiod = ctimeperiod <= 1 ? ctimeperiod + 1 : ctimeperiod;
            }

            d.push({
                area: row.area,
                area_code: row.area_code,
                area_level: row.area_level,
                parent_id: row.parent_id,
                indicator: row.indicator,
                unit: row.unit,
                subgroup: row.subgroup,
                timeperiod: row.timeperiod,
                value: row.value,
                source: row.source,
            });

            let y = areas?.filter((a) => {
                return a.area_code == row.area_code;
            });
            if (y.length == 0) {
                areas.push({
                    area_code: row.area_code,
                    area: row.area,
                });
            }

            // if (!dis?.includes(row.disaster)) {
            //   dis.push(row.disaster);
            //   forms[row.disaster] = [];
            // }
            // if (!forms[row.disaster]?.includes(row.stage)) {
            //   forms[row.disaster].push(row.stage);
            //   newInds[row.disaster+'-'+row.stage] = [];
            // }
            if (
                !inds?.includes(row.indicator + " | " + row.unit + " | " + row.subgroup)
            ) {
                inds.push(row.indicator + " | " + row.unit + " | " + row.subgroup);
            }
            let x = newInds?.filter(y => (y.indicator == row.indicator && y.unit == row.unit && y.subgroup == row.subgroup));
            if (x.length == 0) {
                newInds.push({
                    indicator: row.indicator,
                    unit: row.unit,
                    subgroup: row.subgroup,
                });
                tperiods[row.indicator + "-" + row.unit + "-" + row.subgroup] = [];
            }
            if (!tperiods[row.indicator + "-" + row.unit + "-" + row.subgroup]?.includes(row.timeperiod)) {
                tperiods[row.indicator + "-" + row.unit + "-" + row.subgroup].push(row.timeperiod);
                src[row.indicator + "-" + row.unit + "-" + row.subgroup + '-' + row.timeperiod] = [];
            }
            if (!src[row.indicator + "-" + row.unit + "-" + row.subgroup + '-' + row.timeperiod]?.includes(row.source)) {
                src[row.indicator + "-" + row.unit + "-" + row.subgroup + '-' + row.timeperiod].push(row.source);
            }

        }

        setShowData(d);
        // if (dat?.length == props.chartData?.length) {
        //   setMapState({
        //     uniIndicators: inds,
        //     uniNewIndicators: newInds,
        //     uniTP: tperiods,
        //     uniSrc: src,
        //   });
        // }
        // setIndicator(newInds?.[0]);
        // setTimeperiod(tperiods?.[newInds?.[0]?.indicator
        //   + '-' + newInds?.[0]?.unit
        //   + '-' + newInds?.[0]?.subgroup]?.[0]);
        // setSource(src[
        //   newInds?.[0]?.indicator
        //   + '-' + newInds?.[0]?.unit
        //   + '-' + newInds?.[0]?.subgroup
        //   + '-' + tperiods?.[newInds?.[0]?.indicator
        //   + '-' + newInds?.[0]?.unit
        //   + '-' + newInds?.[0]?.subgroup]?.[0]]?.[0]);

        if (cind && cunit && csubgroup && carea && ctimeperiod) {
            return useCaseMatrix[cind][cunit][csubgroup][carea][ctimeperiod];
        }
        return null;
    };

    const getTitle = (useCase, type) => {

        let title = "";
        if (
            type == "line" ||
            type == "bar" ||
            type == "column" ||
            type == "funnel"
        ) {
            switch (useCase) {
                case "UC1":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC2":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit;
                    break;
                case "UC3":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC4":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC5":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;

                case "UC6":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC7":
                    title = showData?.[0]?.indicator + " | " + showData?.[0]?.unit;
                    break;
                case "UC8":
                    title = showData?.[0]?.unit + " | " + showData?.[0]?.subgroup;
                    break;
                case "UC9":
                    title = showData?.[0]?.subgroup;
                    break;
                case "UC10":
                    title = "";
                    break;
                case "UC11":
                    title = showData?.[0]?.indicator + " | " + showData?.[0]?.subgroup;
                    break;
                case "UC12":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC13":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC14":
                    title =
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC15":
                    title =
                        showData?.[0]?.indicator + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC16":
                    title =
                        showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC17":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit;
                    break;
                case "UC18":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC19":
                    title =
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC20":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC21":
                    title = showData?.[0].indicator;
                    break;
                case "UC22":
                    title =
                        showData?.[0].indicator +
                        " | " +
                        showData?.[0].subgroup +
                        " (" +
                        showData?.[0].timeperiod +
                        ")";
                    break;
                case "UC23":
                    title = showData?.[0].area + " - " + showData?.[0].indicator;
                    break;
                case "UC24":
                    title =
                        showData?.[0].indicator + " (" + showData?.[0].timeperiod + ")";
                    break;
                case "UC25":
                    title = showData?.[0].unit + " (" + showData?.[0].timeperiod + ")";
                    break;
                case "UC26":
                    title = showData?.[0].unit;
                    break;
                case "UC27":
                    title = showData?.[0]?.area + " - " + showData?.[0]?.unit;
                    break;
                case "UC28":
                    title = showData?.[0]?.area + " - " + showData?.[0]?.subgroup;
                    break;
                case "UC29":
                    title =
                        showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC30":
                    title = showData?.[0]?.timeperiod;
                    break;
                case "UC31":
                    title = showData?.[0]?.timeperiod;
                    break;
                case "UC32":
                    title = showData?.[0]?.area;
                    break;
            }
        } else if (
            type == "pie" ||
            type == "doughnut" ||
            type == "treemap" ||
            type == "radar"
        ) {
            switch (useCase) {
                case "UC1":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC2":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC3":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC4":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC5":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.unit;
                    break;
                case "UC6":
                    title = "";
                    // title = showData?.[0]?.disaster + "-" + showData?.[0]?.stage;
                    break;
                case "UC7":
                    title = showData?.[0]?.indicator + " | " + showData?.[0]?.unit;
                    break;
                case "UC8":
                    title = showData?.[0]?.unit + " | " + showData?.[0]?.subgroup;
                    break;
                case "UC9":
                    title = showData?.[0]?.subgroup;
                    break;
                case "UC10":
                    title = "";
                    // title = showData?.[0]?.disaster + "-" + showData?.[0]?.stage;

                    break;
                case "UC11":
                    title = showData?.[0]?.indicator + " | " + showData?.[0]?.subgroup;
                    break;
                case "UC12":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC13":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC14":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC15":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.indicator +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC16":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC17":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.indicator;
                    break;
                case "UC18":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup;
                    break;
                case "UC19":
                    title =
                        showData?.[0]?.unit +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC20":
                    title =
                        showData?.[0]?.area +
                        " - " +
                        showData?.[0]?.unit +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC21":
                    title = showData?.[0]?.indicator;
                    break;
                case "UC22":
                    title =
                        showData?.[0]?.indicator +
                        " | " +
                        showData?.[0]?.subgroup +
                        " (" +
                        showData?.[0]?.timeperiod +
                        ")";
                    break;
                case "UC23":
                    title = showData?.[0]?.area + " - " + showData?.[0]?.indicator;
                    break;
                case "UC24":
                    title =
                        showData?.[0]?.indicator + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC25":
                    title = showData?.[0]?.unit + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC26":
                    title = showData?.[0]?.unit;
                    break;
                case "UC27":
                    title = showData?.[0]?.area + " - " + showData?.[0]?.unit;
                    break;
                case "UC28":
                    title = showData?.[0]?.area + " - " + showData?.[0]?.subgroup;
                    break;

                case "UC29":
                    title =
                        showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
                    break;
                case "UC30":
                    title = showData?.[0]?.area + " (" + showData?.[0]?.timeperiod + ")";
                    break;

                case "UC31":
                    title = showData?.[0]?.timeperiod;
                    break;
                case "UC32":
                    title = showData?.[0]?.area;
                    break;
            }
        }

        return title;
    };

    const renderChart = async (type, swap = false) => {
        if (type === 'table') return;

        setSwap(swap);
        let uCase = getUseCase();

        setUCase(uCase);
        setTitle(getTitle(uCase?.useCase, type));
    }

    useEffect(async () => {
        const renderInstance = echarts.getInstanceByDom(chartRefs?.current);
        if (renderInstance) {
            chartInstance = renderInstance;
        } else {
            chartInstance = echarts.init(chartRefs?.current);
        }

        let optionData = await getOptionData(uCase?.useCase, type, swap);
        setOptionD(optionData);

        let image = document.getElementById("label");
        if (image) {
            image.src = "assets/lib/images/home/label_on.svg";
        }

        chartInstance.clear();
        chartInstance.setOption(optionData);
    }, [showData, swap, type, zoomData, label]);

    const getOptionData = (useCase, type, swap) => {
        console.log("asdsssss", useCase)
        let labelData = label;

        let oData = {
            title: {
                show: true,
                textStyle: {
                    color: "#333",
                    fontSize: 14,
                    fontFamily: "Mulish",
                    fontWeight: "400",
                },

                // text: "No Data",
                text: 'No Data',
                left: "center",
                top: "center",
            },
            // legend: {
            //   data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
            //      bottom: '0%',
            // },
            xAxis: {
                show: false,
            },
            yAxis: {
                show: false,
                axisLabel: {
                    width: 140,
                    lineHeight: 10,
                    overflow: "breakAll"
                },
            },
            series: [],
            source: "",

        };

        if (type == "map") {
            // let dataToShow = showData?.filter(
            //   (x) =>
            //     indicator.indicator == x.indicator &&
            //     indicator.unit == x.unit &&
            //     indicator.subgroup == x.subgroup &&
            //     x.timeperiod == timeperiod &&
            //     x.source == source
            // );

            let mnDataMap = [];



            dat?.forEach(item => {
                mnDataMap.push({
                    "value": item?.value,
                    "name": item?.area,
                    "code": item?.area_code,
                    "level": item?.area_level
                })
            })

            let dataToShow = mnDataMap;
            let dataMap = [];
            let nameMap = {};
            let min = 0;
            let max = 0;
            let minLevel = 5;
            let maxLevel = 0;


            // for (let i = 0; i < dataToShow?.length; i++) {
            //   if (!Object.keys(nameMap).includes(dataToShow[i].area_code)) {
            //     if (minLevel > dataToShow[i].area_level) {
            //       minLevel = dataToShow[i].area_level;
            //     }
            //     if (maxLevel < dataToShow[i].area_level) {
            //       maxLevel = dataToShow[i].area_level;
            //     }

            //     dataMap.push({
            //       value: +dataToShow[i].value,
            //       name: dataToShow[i].area,
            //       code: dataToShow[i].area_code,
            //       level: dataToShow[i].area_level,
            //       // parent_id: dataToShow[i].parent_id,
            //     });
            //     nameMap[dataToShow[i].area_code] = dataToShow[i].area;
            //     if (max < +dataToShow[i].value) {
            //       max = +dataToShow[i].value;
            //     }
            //     if (min > +dataToShow[i].value) {
            //       min = +dataToShow[i].value;
            //     }
            //   }
            // }

            for (let i = 0; i < dataToShow?.length; i++) {
                if (!Object.keys(nameMap).includes(dataToShow[i].code)) {
                    if (minLevel > dataToShow[i].level) {
                        minLevel = dataToShow[i].level;
                    }
                    if (maxLevel < dataToShow[i].level) {
                        maxLevel = dataToShow[i].level;
                    }

                    if (dataToShow[i].level > 1) {
                        dataMap.push({
                            value: +dataToShow[i].value,
                            name: dataToShow[i].name,
                            code: dataToShow[i].code,
                            level: dataToShow[i].level,
                            // parent_id: dataToShow[i].parent_id,
                        });
                        nameMap[dataToShow[i].code] = dataToShow[i].name;
                        if (max < +dataToShow[i].value) {
                            max = +dataToShow[i].value;
                        }
                        if (min > +dataToShow[i].value) {
                            min = +dataToShow[i].value;
                        }
                    }


                }
            }

            if (dataMap?.length == 0) {
                setNoData(true);
                return oData;
            }
            setNoData(false);
            // if(minLevel == 1 && maxLevel == 1) {
            //   return {
            //     title: {
            //       show: true,
            //       textStyle: {
            //         color: "#333",
            //         fontSize: 14,
            //         fontFamily: "Mulish",
            //         fontWeight: "400",
            //       },
            //       text: "Please select sub-national areas to view map",
            //       left: "center",
            //       top: "center",
            //     },
            //     xAxis: {
            //       show: false,
            //     },
            //     yAxis: {
            //       show: false,
            //     },
            //     series: [],
            //     source: "",
            //   };
            // }





            // let d = getLegendData(dataMap);
            let d = getLegendData(dataMap);

            let geoJSONData = null;
            let newCoords = [];
            // if (minLevel < 3) {
            geoJSONData = JSON.parse(JSON.stringify(geoJSONData2));

            // let smallerregions3 = dataMap?.filter((x) => x.level == 3);
            // smallerregions3.map((item) => {
            //   let x = geoJSONData3?.features?.filter((geo) => {
            //     return item.code === geo?.properties?.["ID_"];
            //   });
            //   x.map((geo) => {
            //     newCoords.push(geo);
            //   });
            // });
            // let smallerregions4 = dataMap?.filter((x) => x.level == 4);
            // smallerregions4.map((item) => {
            //   let x = geoJSONData4?.features?.filter((geo) => {
            //     return item.code === geo?.properties?.["ID_"];
            //   });
            //   x.map((geo) => {
            //     newCoords.push(geo);
            //   });
            // });
            // geoJSONData.features.push(...newCoords);
            // } else {
            //   let newCoords = [];

            //   dataMap?.map((item) => {
            //     let x = null;
            //     if (item.level == 3) {
            //       x = geoJSONData3?.features?.filter((geo) => {
            //         return item.code === geo?.properties?.["ID_"];
            //       });
            //     } else {
            //       x = geoJSONData4?.features?.filter((geo) => {
            //         return item.code === geo?.properties?.["ID_"];
            //       });
            //     }
            //     x.map((geo) => {
            //       newCoords.push(geo);
            //     });
            //   });
            //   if (newCoords?.length == 0) {
            //     return oData;
            //   }
            //   geoJSONData = { type: "FeatureCollection", features: newCoords };
            // }

            echarts.registerMap("checkLabel", label);


            let maxVal = 1000000
            let minVal = 0
            return {
                // title: {
                //   text: title,
                // },
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {

                        return (

                            params.name +
                            "\n" +
                            (isNaN(params.value) ? " " : params.value)
                        );
                    },
                },

                visualMap: {
                    min: minVal,
                    max: maxVal,
                    type: 'piecewise',
                    pieces: d.legends?.length > 0 ? d.legends : [{ "color": "#e2e2e2", "label": "No Data", "max": 0, "min": 0 }],
                    textStyle: {
                        color: '#333'
                    },
                    orient: 'vertical',
                    bottom: 40,
                    right: 20,



                    // inRange: {
                    //   color: ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"]
                    // },
                },
                // geo: {
                //   map: "TTO",
                //   regions: [
                //     {
                //       name: "North Pole",
                //       itemStyle: {
                //         normal: {
                //           areaColor: "#ff0000", // Adjust the fill color of the North Pole symbol
                //         },
                //       },
                //       label: {
                //         show: true,
                //         position: "insideBottom",
                //         distance: 10,
                //         color: "#ffffff", // Adjust the label color of the North Pole symbol
                //       },
                //       value: 1,
                //     },
                //   ],
                // },
                series: [
                    {
                        name: "Trinidad and Tobago",
                        type: "map",
                        map: "TTO",
                        zoom: zoomData,

                        roam: 'move',
                        left: '0%',
                        top: '0',
                        label: {
                            show: label,
                            formatter: function (params) {

                                return params.name + '\n' + (isNaN(params.value) ? '' : params.value);
                            },
                        },
                        data: dataMap,
                        nameMap: nameMap,


                        emphasis: false,

                    },


                ]
            };
        }

        if (useCase == "UC1") {
            oData = {
                title: {
                    show: true,
                    textStyle: {
                        color: "#333", //'#B8372B',
                        fontSize: 50,
                        fontFamily: "Mulish",
                        fontWeight: "400",
                    },
                    text: (+showData?.[0]?.value).toLocaleString(), //(+result.data?.[0]?.new_value)?.toLocaleString(),
                    left: "center",
                    top: "center",
                },
                xAxis: {
                    show: false,
                },
                yAxis: {
                    show: false,
                    axisLabel: {
                        width: 140,
                        lineHeight: 10,
                        overflow: "breakAll"
                    },
                },
                series: [],
                source: "",
            };
            return oData;
        }

        if (type == "line" || type == "bar" || type == "funnel" || type == "column") {

            if (useCase == "UC2" || useCase == "UC12") {
                //TP(multiple) on x-axis and Subgroup on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;
                if (swap) {
                    if (type == 'bar' || type == 'line' || type == 'column') {
                        showData.forEach((row) => {
                            let sgName = row.timeperiod;

                            let tp = row.subgroup;
                            tp = tp?.replace("|", " ");
                            if (useCase == "UC12") {
                                tp = row.unit;
                            }

                            if (!tpArr.includes(tp)) {
                                tpArr.push(tp);
                            }

                            if (!sgNames.includes(sgName)) {
                                sgNames.push(sgName);
                            }

                            if (!(tp in d)) {
                                d[tp] = {};
                            }
                            d[tp][sgName] = row.value;
                        });
                        sgNames.sort((a, b) => {
                            const yearA = parseInt(a.substring(0, 4));
                            const yearB = parseInt(b.substring(0, 4));
                            return yearA - yearB;
                        })
                    }
                    else {
                        showData.forEach((row) => {
                            let sgName = row.timeperiod;

                            let tp = row.subgroup;
                            if (useCase == "UC12") {
                                tp = row.unit;
                            }

                            if (!tpArr.includes(tp)) {
                                tpArr.push(tp);
                            }

                            if (!sgNames.includes(sgName)) {
                                sgNames.push(sgName);
                            }

                            if (!(tp in d)) {
                                d[tp] = {};
                            }
                            d[tp][sgName] = row.value;
                        });
                    }
                } else {
                    showData.forEach((row) => {
                        let tp = row.timeperiod;

                        let sgName = row.subgroup;
                        sgName = sgName?.replace("|", " ");
                        if (useCase == "UC12") {
                            sgName = row.unit;
                        }
                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }
                if (type == 'bar' || type == 'line' || type == 'column') {
                    const sortedtpArr = tpArr.sort((a, b) => {
                        const yearA = parseInt(a.substring(0, 4)); // Assuming the year is at the beginning of SG name
                        const yearB = parseInt(b.substring(0, 4)); // Assuming the year is at the beginning of SG name
                        return yearA - yearB;
                    });
                    for (let t of sortedtpArr) {
                        for (let sg of sgNames) {
                            if (sg in vData) {
                                vData[sg].push(d[t][sg]);
                            } else {
                                vData[sg] = [d[t][sg]];
                            }
                        }
                    }
                } else {
                    for (let t of tpArr) {
                        for (let sg of sgNames) {
                            if (sg in vData) {
                                vData[sg].push(d[t][sg]);
                            } else {
                                vData[sg] = [d[t][sg]];
                            }
                        }
                    }
                }



                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;

                if (type == "line" || type == "bar") {
                    
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            colorBy: "data",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            symbol: 'circle',
                            lineStyle: {
                                color: currentColor,
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                            
                            // lineStyle: {
                            //   color: "#A6170A",
                            //   //color: this.colors[i],
                            //   width: 1,
                            // },
                            // itemStyle: {
                            //   color: colorPalette[i % colorPalette.length],
                            //   borderColor: colorPalette[i % colorPalette.length],
                            // },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];

                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: "bar",
                            colorBy: "data",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (
                useCase == "UC3" ||
                useCase == "UC7" ||
                useCase == "UC8" ||
                useCase == "UC9" ||
                useCase == "UC10" ||
                useCase == "UC11" ||
                useCase == "UC13"
            ) {
                //Area(multiple) on x-axis and others on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.area;
                        if (useCase != "UC3" && useCase != "UC13") {
                            sgName = sgName + "-" + row.timeperiod;
                        }

                        let tp;
                        if (useCase == "UC8") {
                            tp = row.indicator;
                        } else if (useCase == "UC9") {
                            tp = row.indicator + "-" + row.unit;
                        } else if (useCase == "UC10") {
                            tp = row.indicator + "-" + row.unit + "-" + row.subgroup;
                        } else if (useCase == "UC11" || useCase == "UC13") {
                            tp = row.unit;
                        } else {
                            tp = row.subgroup;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }

                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.area;
                        if (useCase != "UC3" && useCase != "UC13") {
                            tp = tp + "-" + row.timeperiod;
                        }

                        let sgName;
                        if (useCase == "UC8") {
                            sgName = row.indicator;
                        } else if (useCase == "UC9") {
                            sgName = row.indicator + "-" + row.unit;
                        } else if (useCase == "UC10") {
                            sgName = row.indicator + "-" + row.unit + "-" + row.subgroup;
                        } else if (useCase == "UC11" || useCase == "UC13") {
                            sgName = row.unit;
                        } else {
                            sgName = row.subgroup;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }

                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;

                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            colorBy: "data",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: "bar",
                            colorBy: "data",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (useCase == "UC4" || useCase == "UC5") {
                //TP(multiple) on x-axis and Area on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.timeperiod;

                        let tp = row.area;
                        if (useCase == 'UC5') {
                            sgName = row.subgroup;
                            sgName = sgName?.replace("|", " ");
                        }
                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.timeperiod;

                        let sgName = row.area;
                        if (useCase == 'UC5') {
                            tp = row.subgroup;
                            tp = tp?.replace("|", " ");
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;

                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            }
            // else if (useCase == "UC5") {
            //   //if subgroup is multiple and on x-axis and value on y-axis
            //   let sgArr = [];
            //   let vData = [];
            //   let legendData = [];
            //   let colorPalette = [
            //     "#A6170A",
            //     "#ED9C94",
            //     "#CF655B",
            //     "#B8372B",
            //     "#7D1B12",
            //     "#440A05",
            //   ];
            //   //let tpName = "";
            //   if (swap) {
            //     showData.forEach((row) => {
            //       sgArr.push(row.value);
            //       vData.push(row.subgroup);
            //     });
            //   } else {
            //     showData.forEach((row) => {
            //       sgArr.push(row.subgroup);
            //       vData.push(row.value);
            //     });
            //   }

            //   if (type == "line" || type == "bar") {
            //     oData = {
            //       legend: {
            //         orient: "vertical",
            //         top: "5%",
            //         right: 0,
            //         bottom: 0,
            //         itemGap: 14,
            //         type: "scroll",
            //         formatter: function (name) {
            //           return name.length > 17 ? name.substring(0, 17) + "..." : name;
            //         },
            //         show: false,
            //         textStyle: {
            //           color: "#333",
            //         },
            //       },
            //       tooltip: {
            //         trigger: "item",
            //         axisPointer: {
            //           type: "cross",
            //         },
            //       },
            //       grid: {
            //         containLabel: true,
            //         left: "0%",
            //         right: "1%",
            //         // bottom: "3%",
            //         top: "10%",
            //         y2: 30,
            //       },
            //       xAxis: [
            //         {
            //           type: "category",
            //           data: sgArr,
            //           axisLabel: {
            //             color: "#333",
            //             width: 140,
            //             lineHeight: 14,
            //             overflow: "truncate",
            //             rotate: -40,
            //             interval: 0
            //           },
            //           axisLine: {
            //             lineStyle: {
            //               color: "#333",
            //             },
            //           },
            //         },
            //       ],
            //       yAxis: [
            //         {
            //           type: "value",
            //           axisLabel: {
            //             color: "#333",
            //           },
            //           splitLine: {
            //             lineStyle: {
            //               color: "rgba(255, 255, 255, 0.7)",
            //               type: "dashed",
            //             },
            //           },
            //         },
            //       ],
            //       series: [
            //         {
            //           name: "Value",
            //           data: vData,
            //           colorBy: "data",
            //           type: type,
            //           label: {
            //             show: false,
            //             position: "top",
            //             formatter: function (params) {
            //               return (+params.value).toLocaleString();
            //             },
            //           },
            //           emphasis: {
            //             focus: "series",
            //           },
            //           lineStyle: {
            //             color: "#A6170A",
            //             width: 1,
            //           },
            //           itemStyle: {
            //             color: function (params) {
            //               var dataIndex = params.dataIndex;
            //               return colorPalette[dataIndex % colorPalette.length];
            //             },
            //             // borderColor: colorPalette[i]
            //           },
            //         },
            //       ],
            //     };
            //   } else if (type == "funnel") {
            //     oData = {
            //       tooltip: {
            //         trigger: "item",
            //         axisPointer: {
            //           type: "cross",
            //         },
            //       },
            //       legend: {
            //         data: sgArr,
            //         // orient: 'vertical',
            //         // top: 'center',
            //         // icon: 'rect',
            //         // right: 0,
            //         bottom: 0,
            //         // height: 'auto',
            //         type: "scroll",
            //         show: true,
            //         formatter: function (name) {
            //           return name.length > 17 ? name.substring(0, 17) + "..." : name;
            //         },
            //         tooltip: {
            //           show: true,
            //           trigger: "item",
            //         },
            //         textStyle: {
            //           color: "#333",
            //         },
            //       },
            //       grid: {
            //         containLabel: true,
            //         left: "10%",
            //         // height: 'auto',
            //         // bottom: '3%',
            //         top: "10%",
            //         y2: 30,
            //       },
            //       series: [
            //         {
            //           name: "Value",
            //           data: vData,
            //           type: type,
            //           left: "10%",
            //           top: 60,
            //           bottom: 60,
            //           width: "80%",
            //           // min: 0,
            //           // max: 100,
            //           minSize: "0%",
            //           maxSize: "100%",
            //           sort: "ascending",
            //           gap: 2,
            //           label: {
            //             show: false,
            //             position: "inside",
            //             formatter: function (params) {
            //               return (+params.value).toLocaleString();
            //             },
            //           },
            //           emphasis: {
            //             focus: "series",
            //           },
            //           lineStyle: {
            //             color: "#A6170A",
            //             //color: this.colors[i],
            //             width: 1,
            //           },
            //           itemStyle: {
            //             borderWidth: 1,
            //             borderColor: "#A6170A",
            //             color: "#A6170A",
            //             //borderColor: this.colors[i],
            //             //color: this.colors[i]
            //           },
            //         },
            //       ],
            //     };
            //   }
            // } 
            else if (useCase == "UC6") {
                //if subgroup is multiple, on x-axis and area on y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;
                if (swap) {
                    showData.forEach((row) => {
                        let tp = row.subgroup;
                        let sgName = row.area;

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let sgName = row.subgroup;
                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }
                        let tp = row.area;

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }
                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;

                if (type == "line" || type == "bar") {
                    tpArr.forEach((tp, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: tp,
                            data: vData[tp],
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: tpArr,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: sgNames,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    tpArr.forEach((tp, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: tp,
                            data: vData[tp],
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: tpArr,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: sgNames,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    tpArr.forEach((tp, i) => {
                        seri.push({
                            name: tp,
                            data: vData[tp],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: tpArr,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (
                useCase == "UC14" ||
                useCase == "UC15" ||
                useCase == "UC16" ||
                useCase == "UC22"
            ) {
                //tp(Indicator/US/IU multiple) on x-axis and sg(Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;
                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.indicator;
                        if (useCase == "UC14") {
                            sgName = row.indicator;
                        } else if (useCase == "UC15") {
                            sgName = row.unit + "-" + row.subgroup;
                        } else if (useCase == "UC16") {
                            sgName = row.indicator + "-" + row.unit;
                        } else if (useCase == "UC22") {
                            sgName = row.unit;
                        }

                        let tp = row.area;

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.indicator;
                        if (useCase == "UC14") {
                            tp = row.indicator;
                        } else if (useCase == "UC15") {
                            tp = row.unit + "-" + row.subgroup;
                        } else if (useCase == "UC16") {
                            tp = row.indicator + "-" + row.unit;
                        } else if (useCase == "UC22") {
                            tp = row.unit;
                        }

                        let sgName = row.area;
                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;

                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            color: colorPalette,
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            color: colorPalette,
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (
                useCase == "UC17" ||
                useCase == "UC18" ||
                useCase == "UC19" ||
                useCase == "UC20"
            ) {
                console.log("sdfsdfsdfsd entereddd")
                //tp(Subgroup/Indicator multiple) on x-axis and sg(timeperiod/Area/Subgroup) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.subgroup;
                        sgName = sgName?.replace("|", " ");
                        if (useCase !== "UC17") {
                            sgName = row.indicator;
                        }

                        let tp = row.timeperiod;
                        if (useCase === "UC19") {
                            tp = row.area;
                        } else if (useCase === "UC20") {
                            tp = row.subgroup;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });

                    // Sort tpArr based on the year in each time period
                    tpArr.sort((a, b) => {
                        const yearA = parseInt(a.substring(0, 4));
                        const yearB = parseInt(b.substring(0, 4));
                        return yearA - yearB;
                    });
                }
                else {
                    showData.forEach((row) => {
                        let tp = row.subgroup;
                        tp = tp?.replace("|", " ");
                        if (useCase != "UC17") {
                            tp = row.indicator;
                        }

                        let sgName = row.timeperiod;
                        if (useCase == "UC19") {
                            sgName = row.area;
                        } else if (useCase == "UC20") {
                            sgName = row.subgroup;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }
                const sortedsgnames = sgNames.sort((a, b) => {
                    const yearA = parseInt(a.substring(0, 4)); // Assuming the year is at the beginning of SG name
                    const yearB = parseInt(b.substring(0, 4)); // Assuming the year is at the beginning of SG name
                    return yearA - yearB;
                });
                for (let t of tpArr) {
                    for (let sg of sortedsgnames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;
                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            symbol: 'circle',
                            lineStyle: {
                                color: currentColor,
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "2%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 80,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (useCase == "UC21" || useCase == "UC23" || useCase == "UC24") {
                //tp(US multiple) on x-axis and sg(Area/TP) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.unit + "-" + row.subgroup;

                        let tp = row.area + "-" + row.timeperiod;
                        if (useCase == "UC23") {
                            tp = row.area;
                        } else if (useCase == "UC24") {
                            tp = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.unit + "-" + row.subgroup;

                        let sgName = row.area + "-" + row.timeperiod;
                        if (useCase == "UC23") {
                            sgName = row.area;
                        } else if (useCase == "UC24") {
                            sgName = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }
                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;
                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (
                useCase == "UC25" ||
                useCase == "UC26" ||
                useCase == "UC27" ||
                useCase == "UC28" ||
                useCase == "UC29"
            ) {
                //tp(IU multiple) on x-axis and sg(timeperiod/Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.indicator;
                        if (useCase == "UC25" || useCase == "UC26" || useCase == "UC27") {
                            sgName = sgName + "-" + row.subgroup;
                        } else if (useCase == "UC28" || useCase == "UC29") {
                            sgName = sgName + "-" + row.unit;
                        }

                        let tp = row.area;
                        if (useCase == "UC26") {
                            tp = tp + "-" + row.timeperiod;
                        } else if (useCase == "UC27" || useCase == "UC28") {
                            tp = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.indicator;
                        if (useCase == "UC25" || useCase == "UC26" || useCase == "UC27") {
                            tp = tp + "-" + row.subgroup;
                        } else if (useCase == "UC28" || useCase == "UC29") {
                            tp = tp + "-" + row.unit;
                        }

                        let sgName = row.area;
                        if (useCase == "UC26") {
                            sgName = sgName + "-" + row.timeperiod;
                        } else if (useCase == "UC27" || useCase == "UC28") {
                            sgName = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;
                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            } else if (useCase == "UC30" || useCase == "UC31" || useCase == "UC32") {
                //tp(IUS multiple) on x-axis and sg(timeperiod/Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                let colorPalette = colorPaletteState;

                if (swap) {
                    showData.forEach((row) => {
                        let sgName = row.indicator + "-" + row.unit + "-" + row.subgroup;

                        let tp = row.area;
                        if (useCase == "UC32") {
                            tp = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                } else {
                    showData.forEach((row) => {
                        let tp = row.indicator + "-" + row.unit + "-" + row.subgroup;

                        let sgName = row.area;
                        if (useCase == "UC32") {
                            sgName = row.timeperiod;
                        }

                        if (!sgNames.includes(sgName)) {
                            sgNames.push(sgName);
                        }

                        if (!tpArr.includes(tp)) {
                            tpArr.push(tp);
                        }
                        if (!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    });
                }

                for (let t of tpArr) {
                    for (let sg of sgNames) {
                        if (sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = [d[t][sg]];
                        }
                    }
                }

                let seri = [];
                const startColorIndex = colorPaletteState.length - sgNames.length;
                if (type == "line" || type == "bar") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: type,
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                } else if (type == "column") {
                    sgNames.forEach((sg, i) => {
                        const currentColor = colorPaletteState[startColorIndex + i] || colorPaletteState[i % colorPaletteState.length];
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            colorBy: "data",
                            type: "bar",
                            label: {
                                show: label,
                                position: "top",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                color: currentColor,
                                borderColor: currentColor,
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            orient: "horizontal",
                            // top: "5%",
                            // icon: 'rect',
                            right: 0,
                            bottom: 0,
                            // height: 'auto',
                            itemGap: 14,
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "0%",
                            right: "1%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 14,
                                overflow: "truncate",
                                rotate: -40,
                                interval: 0
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333",
                                },
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: tpArr,
                            axisLabel: {
                                color: "#333",
                                width: 140,
                                lineHeight: 10,
                                overflow: "breakAll"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed",
                                },
                            },
                        },
                        toolbox: {
                            show: false,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ["bar", "line", "column"] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: "Download" },
                            },
                        },
                        series: seri,
                    };
                }
                else if (type == "funnel") {
                    sgNames.forEach((sg, i) => {
                        seri.push({
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: "10%",
                            top: 60,
                            bottom: 60,
                            width: "80%",
                            // min: 0,
                            // max: 100,
                            minSize: "0%",
                            maxSize: "100%",
                            sort: "ascending",
                            gap: 2,
                            label: {
                                show: label,
                                position: "inside",
                                formatter: function (params) {
                                    return (+params.value).toLocaleString();
                                },
                            },
                            emphasis: {
                                focus: "series",
                            },
                            lineStyle: {
                                color: "#A6170A",
                                //color: this.colors[i],
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#A6170A",
                                color: "#A6170A",
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            },
                        });
                    });

                    oData = {
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                            },
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: "scroll",
                            show: legend,
                            formatter: function (name) {
                                return name.length > 17 ? name.substring(0, 17) + "..." : name;
                            },
                            tooltip: {
                                show: true,
                                trigger: "item",
                            },
                            textStyle: {
                                color: "#333",
                            },
                        },
                        grid: {
                            containLabel: true,
                            left: "10%",
                            // height: 'auto',
                            // bottom: '3%',
                            top: "10%",
                            y2: 30,
                        },
                        series: seri,
                    };
                }
            }
        } else if (type == "pie") {
            let colorPalette = colorPaletteState;
            if (
                useCase == "UC2" ||
                useCase == "UC3" ||
                useCase == "UC5" ||
                useCase == "UC14"
            ) {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                let legendData = [];
                let seri = []
                showData.forEach((row) => {
                    let tpName = row?.timeperiod;
                    let sgName = row?.subgroup;
                    if (useCase == "UC2") {
                        iusArr.push(row.timeperiod);
                        tpName = row?.timeperiod
                    } else if (useCase == "UC3") {
                        iusArr.push(row.area);
                        tpName = row?.area
                    } else if (useCase == "UC5") {
                        iusArr.push(row.subgroup);
                        sgName = row?.subgroup
                    } else if (useCase == "UC14") {
                        iusArr.push(row.indicator);
                        tpName = row?.indicator
                    }

                    vData.push(row.value);
                    legendData.push(sgName?.replace("|", " ") + "-" + tpName)
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + tpName,
                        value: row.value,
                    });
                });


                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        bottom: 0,
                        itemGap: 14,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            // radius: '75%',
                            center: ["40%", "50%"],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
            } else if (
                useCase == "UC4" ||
                useCase == "UC6" ||
                useCase == "UC12" ||
                useCase == "UC13" ||
                useCase == "UC15" ||
                useCase == "UC16" ||
                useCase == "UC17" ||
                useCase == "UC18" ||
                useCase == "UC19" ||
                useCase == "UC20" ||
                useCase == "UC22" ||
                useCase == "UC23" ||
                useCase == "UC24"
            ) {
                //2-dimensions are multiple

                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC6") {
                        sgName = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC12") {
                        sgName = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC13") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC15") {
                        sgName = row.subgroup;
                        tp = row.unit;
                    } else if (useCase == "UC16") {
                        sgName = row.indicator;
                        tp = row.unit;
                    } else if (useCase == "UC17") {
                        sgName = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC18") {
                        sgName = row.indicator;
                        tp = row.timeperiod;
                    } else if (useCase == "UC19") {
                        sgName = row.indicator;
                        tp = row.area;
                    } else if (useCase == "UC20") {
                        sgName = row.indicator;
                        tp = row.subgroup;
                    } else if (useCase == "UC22") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.area;
                    }
                    legendData.push(sgName?.replace("|", " ") + "-" + tp);
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        itemGap: 14,
                        bottom: 0,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            // radius: '55%',
                            center: ["40%", "50%"],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
            } else if (
                useCase == "UC7" ||
                useCase == "UC8" ||
                useCase == "UC9" ||
                useCase == "UC10" ||
                useCase == "UC11" ||
                useCase == "UC21" ||
                useCase == "UC25" ||
                useCase == "UC26" ||
                useCase == "UC27" ||
                useCase == "UC28" ||
                useCase == "UC29" ||
                useCase == "UC30" ||
                useCase == "UC31" ||
                useCase == "UC32"
            ) {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC8") {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC9") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC10") {
                        sgName = row.indicator + "|" + row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC11") {
                        sgName = row.area;
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC21") {
                        sgName = row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC25") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC26") {
                        sgName = row.indicator + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC27") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC28") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if (useCase == "UC29") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.area;
                    } else if (useCase == "UC30") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.subgroup;
                    } else if (useCase == "UC31") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC32") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    legendData.push(sgName?.replace("|", " ") + "-" + area + "-" + tp);
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + area + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        bottom: 0,
                        itemGap: 14,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            // radius: '55%',
                            center: ["40%", "50%"],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
            }
        } else if (type == "doughnut") {
            let colorPalette = colorPaletteState;
            if (
                useCase == "UC2" ||
                useCase == "UC3" ||
                useCase == "UC5" ||
                useCase == "UC14"
            ) {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                let legendData = [];
                let seri = []
                showData.forEach((row) => {
                    let tpName = row?.timeperiod;
                    let sgName = row?.subgroup;
                    if (useCase == "UC2") {
                        iusArr.push(row.timeperiod);
                        tpName = row?.timeperiod
                    } else if (useCase == "UC3") {
                        iusArr.push(row.area);
                        tpName = row?.area
                    } else if (useCase == "UC5") {
                        iusArr.push(row.subgroup);
                        sgName = row?.subgroup
                    } else if (useCase == "UC14") {
                        iusArr.push(row.indicator);
                        tpName = row?.indicator
                    }

                    vData.push(row.value);
                    legendData.push(sgName?.replace("|", " ") + "-" + tpName)
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + tpName,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        bottom: 0,
                        itemGap: 14,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            radius: ["40%", "70%"],
                            //   center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
            } else if (
                useCase == "UC4" ||
                useCase == "UC6" ||
                useCase == "UC12" ||
                useCase == "UC13" ||
                useCase == "UC15" ||
                useCase == "UC16" ||
                useCase == "UC17" ||
                useCase == "UC18" ||
                useCase == "UC19" ||
                useCase == "UC20" ||
                useCase == "UC22" ||
                useCase == "UC23" ||
                useCase == "UC24"
            ) {
                //2-dimensions are multiple

                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC6") {
                        sgName = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC12") {
                        sgName = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC13") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC15") {
                        sgName = row.subgroup;
                        tp = row.unit;
                    } else if (useCase == "UC16") {
                        sgName = row.indicator;
                        tp = row.unit;
                    } else if (useCase == "UC17") {
                        sgName = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC18") {
                        sgName = row.indicator;
                        tp = row.timeperiod;
                    } else if (useCase == "UC19") {
                        sgName = row.indicator;
                        tp = row.area;
                    } else if (useCase == "UC20") {
                        sgName = row.indicator;
                        tp = row.subgroup;
                    } else if (useCase == "UC22") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.area;
                    }
                    legendData.push(sgName?.replace("|", " ") + "-" + tp);
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        bottom: 0,
                        itemGap: 14,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            radius: ["40%", "70%"],
                            center: ['50%', '40%'],
                            // center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                    
                };
            } else if (
                useCase == "UC7" ||
                useCase == "UC8" ||
                useCase == "UC9" ||
                useCase == "UC10" ||
                useCase == "UC11" ||
                useCase == "UC21" ||
                useCase == "UC25" ||
                useCase == "UC26" ||
                useCase == "UC27" ||
                useCase == "UC28" ||
                useCase == "UC29" ||
                useCase == "UC30" ||
                useCase == "UC31" ||
                useCase == "UC32"
            ) {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC8") {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC9") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC10") {
                        sgName = row.indicator + "|" + row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC11") {
                        sgName = row.area;
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC21") {
                        sgName = row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC25") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC26") {
                        sgName = row.indicator + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC27") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC28") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if (useCase == "UC29") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.area;
                    } else if (useCase == "UC30") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.subgroup;
                    } else if (useCase == "UC31") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC32") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    legendData.push(sgName?.replace("|", " ") + "-" + area + "-" + tp);
                    seri.push({
                        name: sgName?.replace("|", " ") + "-" + area + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 10,
                        // top: 20,
                        bottom: 20,
                        itemGap: 14,
                        data: legendData,
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 34) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "pie",
                            radius: ["40%", "70%"],
                            // center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
            }
        } else if (type == "treemap") {
            let colorPalette = colorPaletteState;
            if (
                useCase == "UC2" ||
                useCase == "UC3" ||
                useCase == "UC5" ||
                useCase == "UC14"
            ) {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                // let legendData = [];
                let tpName = "";
                showData.forEach((row) => {
                    if (useCase == "UC2") {
                        tpName = row.timeperiod;
                    } else if (useCase == "UC3") {
                        tpName = row.area;
                    } else if (useCase == "UC5") {
                        tpName = row.subgroup;
                    } else if (useCase == "UC14") {
                        tpName = row.indicator;
                    }

                    vData.push({ name: tpName, value: row.value });
                    // tpName = row.time_period?.start_time_period;
                });

                oData = {
                    tooltip: {
                        formatter: function (info) {
                            var value = info.value;
                            var treePathInfo = info.treePathInfo;
                            var treePath = [];
                            for (var i = 1; i < treePathInfo.length; i++) {
                                treePath.push(treePathInfo[i].name);
                            }
                            return [
                                '<div class="tooltip-title">' +
                                formatUtil.encodeHTML(treePath.join("/")) +
                                "</div>",
                                formatUtil.addCommas(value),
                            ].join("");
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            label: {
                                show: label,
                                formatter: function (params) {
                                    let val = (+params.value).toLocaleString();
                                    return `${params.data.name}, ${val}`;
                                },
                            },
                            data: vData,
                            color: colorPalette,

                            breadcrumb: {
                                show: true,
                            },
                            levels: [
                                {
                                    itemStyle: {
                                        borderWidth: 0,
                                        gapWidth: 5,
                                    },
                                },
                                {
                                    itemStyle: {
                                        gapWidth: 1,
                                    },
                                },
                                {
                                    colorSaturation: [0.35, 0.5],
                                    itemStyle: {
                                        gapWidth: 1,
                                        borderColorSaturation: 0.6,
                                    },
                                },
                            ],
                        },
                    ],
                };
            } else if (
                useCase == "UC4" ||
                useCase == "UC6" ||
                useCase == "UC12" ||
                useCase == "UC13" ||
                useCase == "UC15" ||
                useCase == "UC16" ||
                useCase == "UC17" ||
                useCase == "UC18" ||
                useCase == "UC19" ||
                useCase == "UC20" ||
                useCase == "UC22" ||
                useCase == "UC23" ||
                useCase == "UC24"
            ) {
                //2-dimensions are multiple

                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC6") {
                        sgName = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC12") {
                        sgName = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC13") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC15") {
                        sgName = row.subgroup;
                        tp = row.unit;
                    } else if (useCase == "UC16") {
                        sgName = row.indicator;
                        tp = row.unit;
                    } else if (useCase == "UC17") {
                        sgName = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC18") {
                        sgName = row.indicator;
                        tp = row.timeperiod;
                    } else if (useCase == "UC19") {
                        sgName = row.indicator;
                        tp = row.area;
                    } else if (useCase == "UC20") {
                        sgName = row.indicator;
                        tp = row.subgroup;
                    } else if (useCase == "UC22") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC23") {
                        sgName = row.unit + "|" + row.subgroup;
                        tp = row.area;
                    }
                    // legendData.push(sgName+'-'+tp);
                    seri.push({
                        name: sgName + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        formatter: function (info) {
                            var value = info.value;
                            var treePathInfo = info.treePathInfo;
                            var treePath = [];
                            for (var i = 1; i < treePathInfo.length; i++) {
                                treePath.push(treePathInfo[i].name);
                            }
                            return [
                                '<div class="tooltip-title">' +
                                formatUtil.encodeHTML(treePath.join("/")) +
                                "</div>",
                                formatUtil.addCommas(value),
                            ].join("");
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            breadcrumb: {
                                show: true,
                            },
                        },
                    ],
                };
            } else if (
                useCase == "UC7" ||
                useCase == "UC8" ||
                useCase == "UC9" ||
                useCase == "UC10" ||
                useCase == "UC11" ||
                useCase == "UC21" ||
                useCase == "UC25" ||
                useCase == "UC26" ||
                useCase == "UC27" ||
                useCase == "UC28" ||
                useCase == "UC29" ||
                useCase == "UC30" ||
                useCase == "UC31" ||
                useCase == "UC32"
            ) {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach((row) => {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC8") {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC9") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC10") {
                        sgName = row.indicator + "|" + row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC11") {
                        sgName = row.area;
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC21") {
                        sgName = row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC25") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC26") {
                        sgName = row.indicator + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC27") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC28") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if (useCase == "UC29") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.area;
                    } else if (useCase == "UC30") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.subgroup;
                    } else if (useCase == "UC31") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC32") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    // legendData.push(sgName+'-'+area+'-'+tp);
                    seri.push({
                        name: sgName + "-" + area + "-" + tp,
                        value: row.value,
                    });
                });

                oData = {
                    tooltip: {
                        formatter: function (info) {
                            var value = info.value;
                            var treePathInfo = info.treePathInfo;
                            var treePath = [];
                            for (var i = 1; i < treePathInfo.length; i++) {
                                treePath.push(treePathInfo[i].name);
                            }
                            return [
                                '<div class="tooltip-title">' +
                                formatUtil.encodeHTML(treePath.join("/")) +
                                "</div>",
                                formatUtil.addCommas(value),
                            ].join("");
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: label,
                            },
                            breadcrumb: {
                                show: true,
                            },
                        },
                    ],
                };
            }
        } else if (type == "radar") {
            let colorPalette = colorPaletteState;
            if (
                useCase == "UC2" ||
                useCase == "UC3" ||
                useCase == "UC5" ||
                useCase == "UC13" ||
                useCase == "UC14"
            ) {
                //1-dimension multiple
                let legendData = [];
                let tpArr = [];
                let vData = {};
                let max = 0;
                let seri = [];
                let sgNames = [];
                showData.forEach((row) => {
                    let sgName = row.subgroup;
                    sgName = sgName?.replace("|", " ")
                    let area = row.area;
                    let tp = row.timeperiod;

                    if (useCase == "UC2") {
                        tp = row.timeperiod;
                    } else if (useCase == "UC3") {
                        area = row.area;
                    } else if (useCase == "UC5") {
                        sgName = row.subgroup;
                        sgName = sgName?.replace("|", " ");
                    } else if (useCase == "UC14") {
                        sgName = row.subgroup;
                    } else if (useCase == "UC13") {
                        tp = row.timeperiod;
                    }
                    // sgName = sgName;
                    max = +row.value > max ? +row.value : max;
                    if (!tpArr.includes(tp)) {
                        tpArr.push(tp);
                    }
                    if (!(sgName in vData)) {
                        vData[sgName] = {};
                        sgNames.push(sgName);
                    }
                    vData[sgName][tp] = +row.value;
                });

                legendData = tpArr.map((ele) => {
                    return { max: max, name: ele };
                });
                for (let sg in vData) {
                    let value = [];
                    for (let tp of tpArr) {
                        if (tp in vData[sg]) {
                            value.push(vData[sg][tp]);
                        } else {
                            value.push(null);
                        }
                    }
                    seri.push({
                        value: value,
                        name: sg
                    });
                }


                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    color: sgNames?.length == 1 ? colorPaletteState[5] : colorPalette,
                    radar: {
                        // shape: 'circle',
                        indicator: legendData,
                    },
                    legend: {
                        data: sgNames,
                        // data: sgNames,
                        orient: "horizontal",
                        // top: "5%",
                        // icon: 'rect',
                        right: 0,
                        bottom: 0,
                        // height: 'auto',
                        itemGap: 14,
                        type: "scroll",
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 17) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            label: {
                                show: label,
                            },
                            data: seri,
                        },
                    ],
                };
            } else if (
                useCase == "UC4" ||
                useCase == "UC6" ||
                useCase == "UC12" ||
                // useCase == "UC13" ||
                useCase == "UC15" ||
                useCase == "UC16" ||
                useCase == "UC17" ||
                useCase == "UC18" ||
                useCase == "UC19" ||
                useCase == "UC20" ||
                useCase == "UC22"

            ) {
                //2-dimensions are multiple
                let legendData = [];
                let tpArr = [];
                let vData = {};
                let max = 0;
                let seri = [];
                let sgNames = [];
                showData.forEach((row) => {
                    let sgName = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC6") {
                        sgName = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC12") {
                        sgName = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC13") {
                        sgName = row.area;
                        tp = row.unit;
                    } else if (useCase == "UC15") {
                        sgName = row.subgroup;
                        tp = row.unit;
                    } else if (useCase == "UC16") {
                        sgName = row.indicator;
                        tp = row.unit;
                    } else if (useCase == "UC17") {
                        sgName = row.subgroup;
                        sgName = sgName?.replace("|", " ")
                        tp = row.timeperiod;
                    } else if (useCase == "UC18") {
                        sgName = row.indicator;
                        tp = row.timeperiod;
                    } else if (useCase == "UC19") {
                        sgName = row.indicator;
                        tp = row.area;
                    } else if (useCase == "UC20") {
                        sgName = row.indicator;
                        tp = row.subgroup;
                    } else if (useCase == "UC22") {
                        sgName = row.area;
                        tp = row.unit;
                    }

                    max = +row.value > max ? +row.value : max;
                    if (!tpArr.includes(tp)) {
                        tpArr.push(tp);
                    }
                    if (!(sgName in vData)) {
                        vData[sgName] = {};
                        sgNames.push(sgName);
                    }
                    vData[sgName][tp] = +row.value;
                    // legendData.push({ name: sgName + "-" + tp });

                    // vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                legendData = tpArr.map((ele) => {
                    return { max: max, name: ele };
                });
                for (let sg in vData) {
                    let value = [];
                    for (let tp of tpArr) {
                        if (tp in vData[sg]) {
                            value.push(vData[sg][tp]);
                        } else {
                            value.push(null);
                        }
                    }
                    seri.push({
                        value: value,
                        name: sg
                    });
                }

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    color: sgNames?.length == 1 ? colorPaletteState[5] : colorPalette,
                    radar: {
                        // shape: 'circle',
                        indicator: legendData,
                    },
                    legend: {
                        data: sgNames,
                        // data: sgNames,
                        orient: "horizontal",
                        // top: "5%",
                        // icon: 'rect',
                        right: 0,
                        bottom: 0,
                        // height: 'auto',
                        itemGap: 14,
                        type: "scroll",
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 17) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            label: {
                                show: label,
                            },
                            data: seri,
                        },
                    ],
                };
            } else if (
                useCase == "UC7" ||
                useCase == "UC8" ||
                useCase == "UC9" ||
                useCase == "UC10" ||
                useCase == "UC11" ||
                useCase == "UC21" ||
                useCase == "UC25" ||
                useCase == "UC26" ||
                useCase == "UC27" ||
                useCase == "UC28" ||
                useCase == "UC29" ||
                useCase == "UC30" ||
                useCase == "UC31" ||
                useCase == "UC32" ||
                useCase == "UC23" ||
                useCase == "UC24"
            ) {
                //3+ dimensions are multiple
                let legendData = [];
                let tpArr = [];
                let vData = {};
                let max = 0;
                let seri = [];
                let sgNames = [];
                showData.forEach((row) => {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if (useCase == "UC8") {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC9") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC10") {
                        sgName = row.indicator + "|" + row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC11") {
                        sgName = row.area;
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if (useCase == "UC21") {
                        sgName = row.unit + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC25") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC26") {
                        sgName = row.indicator + "|" + row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if (useCase == "UC27") {
                        sgName = row.indicator;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC28") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if (useCase == "UC29") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.area;
                    } else if (useCase == "UC30") {
                        sgName = row.indicator;
                        area = row.unit;
                        tp = row.subgroup;
                    } else if (useCase == "UC31") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.area;
                    } else if (useCase == "UC32") {
                        sgName = row.indicator + "|" + row.unit;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC23") {
                        sgName = row.unit;
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if (useCase == "UC23") {
                        sgName = row.unit;
                        area = row.subgroup;
                        tp = row.area;
                    }
                    sgName = sgName + '-' + area;
                    max = +row.value > max ? +row.value : max;
                    if (!tpArr.includes(tp)) {
                        tpArr.push(tp);
                    }
                    if (!(sgName in vData)) {
                        vData[sgName] = {};
                        sgNames.push(sgName);
                    }
                    vData[sgName][tp] = +row.value;

                });

                legendData = tpArr.map((ele) => {
                    return { max: max, name: ele };
                });
                for (let sg in vData) {
                    let value = [];
                    for (let tp of tpArr) {
                        if (tp in vData[sg]) {
                            value.push(vData[sg][tp]);
                        } else {
                            value.push(null);
                        }
                    }
                    seri.push({
                        value: value,
                        name: sg
                    });
                }

                oData = {
                    tooltip: {
                        trigger: "item",
                    },
                    color: sgNames?.length == 1 ? colorPaletteState[5] : colorPalette,
                    radar: {
                        // shape: 'circle',
                        indicator: legendData,
                    },
                    legend: {
                        data: sgNames,
                        // data: sgNames,
                        orient: "horizontal",
                        // top: "5%",
                        // icon: 'rect',
                        right: 0,
                        bottom: 0,
                        // height: 'auto',
                        itemGap: 14,
                        type: "scroll",
                        show: legend,
                        formatter: function (name) {
                            return name.length > 17 ? name.substring(0, 17) + "..." : name;
                        },
                        tooltip: {
                            show: true,
                            trigger: "item",
                        },
                        textStyle: {
                            color: "#333",
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: type,
                            label: {
                                show: label,
                            },
                            data: seri,
                        },
                    ],
                };
            }
        }

        return oData;
    };

    const getLegendData = (mapData) => {




        if (mapData != null && mapData.length > 1 && mapData !== undefined) {
            // setCurrentArea(props.areaCode);
            let valueData = [];
            let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];


            mapData?.forEach((item) => {


                if (item.code !== 'TTO' && item?.value !== "") {
                    valueData.push(item.value);
                }
            });

            let s = new Set(valueData);
            let unicValData = [...s];
            unicValData.sort(function (a, b) {
                return a - b;
            });

            let unitVal = 1;

            var finalLegends = [];
            var collectionAreaData = [];


            if (unicValData?.length >= 4) {

                unitVal = Math.floor(unicValData?.length / 5);
            }
            let j = 0;
            let k = 0;
            let from = unicValData[0];
            let to = unicValData[0];

            for (let i = 0; i < unicValData.length; i++) {


                if (j++ >= unitVal && k < 3) {
                    j = 0;

                    let obj = {
                        color: colors[k++],
                        label: "" + from + " - " + to,
                        min: parseFloat(from),
                        max: parseFloat(to)
                    };



                    let decimalLengths = from = to?.toString().split('.')[1]?.length
                    if (decimalLengths === 1) {
                        let adon = +to + 0.1;
                        from = parseFloat(adon.toFixed(2))
                        to = +to + 1;
                        //  min = parseFloat(adon.toFixed(2))
                        //  max= parseFloat(to)

                    } else if (decimalLengths === 2) {
                        let adon = +to + 0.01
                        from = parseFloat(adon.toFixed(2))
                        //   min = parseFloat(adon.toFixed(2))
                        //   max= parseFloat(to)

                    }
                    else {
                        from = +to + 1
                    }



                    if (!isNaN(obj?.min) && !isNaN(obj?.max)) {
                        finalLegends.push(obj);
                    }
                }



                let valMapData = mapData?.filter(
                    (item) =>
                        item.value === unicValData[i] && item.code !== 'TTO'
                );
                // eslint-disable-next-line no-loop-func

                valMapData?.forEach((item) => {
                    if (item?.value !== "") {
                        let obj = {
                            color: colors[k],
                            value: item.value,
                            area: item?.area,
                            code: item.area_code,
                            level: item?.level,
                            source: item?.source
                        };
                        collectionAreaData.push(obj);
                    }
                });
                to = unicValData[i];



            }



            let obj = {
                color: colors[k++],
                label: "" + from + " - " + to,
                min: parseFloat(+from),
                max: parseFloat(+to)
            };




            if (!isNaN(obj?.min) && !isNaN(obj?.max)) {
                finalLegends.push(obj);
            }



            if (finalLegends?.length > 0) {
                finalLegends.push({ "color": "#e2e2e2", "label": "No Data", "max": 0, "min": 0 })
                // this.setState({ legends: finalLegends, collection: collectionAreaData })

            }
            else {
                // this.setState({ legends: [], collection: collectionAreaData })

            }

            return { legends: finalLegends, collection: collectionAreaData };
        }
        return { legends: [], collection: [] };
    };



    // return (
    //   <>
    //       <div className="timePeriod_box" style={{paddingTop: 0}}>
    //                                   <p>2015-16, 2019-21</p>
    //                  </div>
    //             {/* <ReactEcharts
    //               ref={(e) => {
    //                 let c = chartRefs;
    //                 c[props._id] = e;
    //                 setChartRefs(c);
    //               }}
    //               option={option}
    //               style={{
    //                 height: "300px",
    //                 left: 0,
    //                 top: 0,
    //                 width: "100%",
    //               }}
    //               opts={{ renderer: "svg" }}
    //             /> */}

    //             <div
    //               ref={chartRefs}
    //               style={{
    //                 // height: "calc(100% - 39px)",
    //                 // width: "calc(100vw - 110px)",

    //                   width: 'calc(100vw - 251px)',
    //                   height: 'calc(100vh - 344px)'

    //               }}
    //               className={type == "map" ? "chartsdsr chartdsrMap" : "chartsdsr"}
    //             />
    //             <div className="source_box">
    //                       <p>Source : Ministry of Health and Family Welfare</p>
    //                   </div>

    //   </>
    // );

    const ChartlabelNev = () => {
        // let image = document.getElementById("label");
        // if (image) {
        //   if (image.getAttribute("src") === "assets/lib/images/home/label_on.svg") {
        //     image.src = "assets/lib/images/home/label-off.svg";
        //     setLabel(false)
        //   } else {
        //     image.src = "assets/lib/images/home/label_on.svg";
        //     setLabel(false)
        //   }
        // }

        setLabel(!label);

        const chartInstance = echarts.getInstanceByDom(chartRefs.current);
        if (chartInstance) {
            let option = optionD;
            let newSeries = [];
            option?.series?.forEach((seri) => {
                seri.label = {
                    show: !seri.label.show,
                    position: type == "treemap" ? "center" : "top",
                    formatter: function (params) {
                        let val = (+params.value).toLocaleString();
                        if (type == "treemap") {
                            return `${params.data.name} \n ${val}`;
                        }
                        return val;
                    },
                };
                newSeries.push(seri);
            });
            option.series = newSeries;
            chartInstance.clear();
            setOptionD(option);
            chartInstance.setOption(option);
        }
    };

    const chartLegend = () => {
        // let image = document.getElementById("legend");

        // if (image) {
        //   if (image.getAttribute("src") === "assets/lib/images/home/label_on.svg") {
        //     image.src = "assets/lib/images/home/label-off.svg";
        //     setLegend(false)
        //   } else {
        //     image.src = "assets/lib/images/home/label_on.svg";
        //     setLegend(true)
        //   }
        // }

        setLegend(!legend)


        const chartInstance = echarts.getInstanceByDom(chartRefs.current);
        console.log("chartInstance check", chartInstance)
        if (chartInstance) {
            let option = optionD;
            option.legend.show = !option.legend.show;
            chartInstance.clear();
            setOptionD(option);
            chartInstance.setOption(option);
        }
    };


    const downloadChart = () => {

        setLoader(true);



        $("div#og").css({ 'height': 'auto', 'padding': '10px', 'width': '100%' });
        $(".drsIndicators_img").hide();
        $("p.sub-category-text, div.mapSource").removeClass('indDD')
        $(".dsrBarchart_list").hide();
        $(".legends, .filter, .content_arrow ").hide();
        $(".drs_sources").hide();

        $(".multiple-value.drsChartss").css('flex-direction', 'column')
        $(".dsrChart .maps_source").css('position', 'unset')
        $(".dsrDropdown").addClass("dsrdropDownload")
        $(".legendsNumber.legendsChart").css({ 'bottom': '40px', 'left': '10px' });
        // mapSource-hide

        // $(".dropdown-toggle").hide();

        let node1 = document.getElementById("og");
        let node2 = document.getElementById("copy");
        let mapSource = document.getElementById("downloadchartds");

        mapSource.style.display = "block !important";

        node2.innerHTML = node1.innerHTML;
        node2.style.display = "block";
        // $(".copy .secInd").removeClass('indDD')

        let div = $(".downloadChartBG");
        div.css("background-color : white");

        domtoimage.toBlob(node1).then((blob) => {
            saveFile(blob);
            $("p.sub-category-text,  div.mapSource").addClass('indDD')
            $(".dsrBarchart_list").show();
            $(".drsIndicators_img").show();
            $(".legends, .filter, .content_arrow ").show();
            $(".drs_sources").show();
            $(".multiple-value.drsChartss").css('flex-direction', '')
            $('.downloadchartds').css('display', 'block');

            $(".dsrChart .maps_source").css('position', 'absolute')
            $("div#og").css({ 'height': '100%', 'padding': '', 'width': '' });
            $(".dsrDropdown").removeClass("dsrdropDownload");
            $(".legendsNumber.legendsChart").css({ 'bottom': '20px', 'left': '0px' });
            setLoader(false);

            // $(".dropdown-toggle").show();
            // $('.countryName').css('display', 'none');
            node2.style.display = "none";
        });
    };

    const saveFile = async (blob) => {
        const a = document.createElement("a");
        const dt = Date.now();
        const modifiedTitle = title.replace("TTO -", "_");
        a.download = `SDG_${modifiedTitle}_${dt}.png`;
        a.href = URL.createObjectURL(blob);
        a.click();
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    };

    const zoomin = () => {
        if (zoomData < 5) {
            setZoomData(zoomData + 0.2);
        }
        renderChart(type)
    };

    const zoomout = () => {
        if (zoomData > 1) {
            setZoomData(zoomData - 0.2);
        }
        renderChart(type)
    };

    const reset = () => {
        setZoomData(1.25);
        renderChart(type)
    };


    const showLabel = () => {
        // if (label === true) {
        //   setLabel(true);

        // } else {
        //   setLabel(false);
        // }
        setLabel(!label)
        renderChart(type)
    };


    const showLegend = () => {
        setLegend(!label)
        renderChart(type)
    };


    const onChange = (val, key, e = null) => {
        let ind = '';
        let tp = '';
        switch (key) {
            case "indicator":
                setIndicator(val);
                tp = mapState.uniTP?.[val.indicator + '-' + val.unit + '-' + val.subgroup][0];
                setTimeperiod(tp);
                setSource(mapState.uniSrc?.[val.indicator + '-' + val.unit + '-' + val.subgroup + '-' + tp][0]);
                break;
            case "timeperiod":
                setTimeperiod(val);
                setSource(mapState.uniSrc?.[indicator.indicator + '-' + indicator.unit + '-' + indicator.subgroup + '-' + val][0]);
                break;
            case "src":
                // setFlag(true);
                let s = source;
                if (val === '*') {
                    if (e.target.checked) {
                        // Select All
                        s = srcList.filter((item) => item !== '*');
                        s.push(val);
                    } else {
                        // Deselect All
                        s = [];
                    }
                } else {
                    if (val !== '*' && e.target.checked) {
                        // Select an option
                        s.push(val);
                    } else {
                        // Deselect an option
                        s = s.filter((item) => item !== val);
                    }

                    // Check if 'Select All' should be deselected
                    if (s.includes('*') && s.length > 1) {
                        s = s.filter((item) => item !== '*');
                    }
                }
                setSource(s);
                break;
        }
    };


    const handleSubgroupChange = (e) => {
        const selectedSubgroups = [...new Set(e.target.value)];

        let filteredData = props.chartData?.filter(x => source.includes(x.source));

        if (selectedSubgroups.length === 0 || source.length == 0) {
            setSelectedSub(['None Selected']);
            setSelectedTp(['None Selected']);
            setIsAllSelectedSub(false);
            setIsAllSelectedTp(false);
            setDat([]);
            // setBakupDat([]);
            return;
        }

        let newDat = [];
        let tpData = new Set();

        filteredData.forEach(item => {
            if (selectedSubgroups.includes(item?.subgroup)) {
                newDat.push(item);
                tpData.add(item?.timeperiod);
            }
        });

        setSelectedSub(selectedSubgroups?.filter((subg) => subg !== 'None Selected'));
        setSelectedTp(Array.from(tpData)); // Convert the Set to an array of unique time periods

        // Filter subgroups based on the selected years
        const selectedYears = [...tpData].map(timeperiod => timeperiod.substring(0, 4));

        // Filter the data to include only items with selected years and subgroups
        const filteredDat = newDat.filter(item =>
            selectedYears.includes(item?.timeperiod.substring(0, 4)) &&
            selectedSubgroups.includes(item?.subgroup)
        );

        setDat(filteredDat);
        setBakupDat(filteredDat);
    };


    const handleTimeperiodChange = (e) => {
        const selectedTimeperiods = e.target.value;

        let filteredData = props.chartData?.filter(x => source.includes(x.source));

        if (selectedTimeperiods.length === 0 || source.length == 0) {
            // setSelectedSub(['None Selected']);
            setSelectedTp(['None Selected']);
            // setIsAllSelectedSub(false);
            setIsAllSelectedTp(false);
            setDat([]);
            setBakupDat([]);
            return;
        } else if (selectedTimeperiods.length === selectedTp.length) {
            // setIsAllSelectedSub(true);
            setIsAllSelectedTp(true);
        }

        let newDat = [];
        let subData = [];
        const data = backupDat?.length > 0 ? backupDat?.filter((x) => selectedSub?.includes(x?.subgroup)) : filteredData?.filter((x) => selectedSub?.includes(x?.subgroup));

        filteredData?.filter((x) => selectedSub?.includes(x?.subgroup)).map((item) => {
            if (selectedTimeperiods.includes(item.timeperiod)) {
                newDat.push(item);
                subData.push(item.subgroup);
            }
        });

        // const newDat = data.filter(item => selectedTimeperiods.includes(item.timeperiod));
        // const newSubData = selectedSub.filter(subgroup => {
        //   const correspondingTimeperiod = data.find(item => item.subgroup === subgroup);
        //   return selectedTimeperiods.includes(correspondingTimeperiod?.timeperiod);
        // });
        let tp = selectedTimeperiods?.filter((time) => time !== 'None Selected');
        setSelectedTp([...new Set(tp)]);
        // setSelectedSub([...new Set(subData)]);
        setDat(newDat);

        const selectedSubgroups = newDat.map(item => item.subgroup);
        // setIsAllSelectedSub(selectedSubgroups.length === filteredData.length);
        const uniqueTimeperiods = [...new Set(data.map(item => item.timeperiod))];
        setIsAllSelectedTp(tp.length === uniqueTimeperiods.length);


    };


    const handleSelectAllSub = (e) => {
        let checked = e.target.checked;

        let newDat = [];
        let tpData = new Set();

        if (checked) {
            setIsAllSelectedSub(true);

            let filteredData = props.chartData?.filter(x => source.includes(x.source));
            filteredData.forEach(sub => {
                newDat.push(sub);
                tpData.add(sub?.timeperiod);
            });


            setSelectedTp(Array.from(tpData));
            setSelectedSub([...new Set(filteredData.map(sub => sub?.subgroup))]);
            setDat(newDat);
        } else {
            setIsAllSelectedSub(false);
            setSelectedSub(['None Selected']);
            setIsAllSelectedTp(false);
            setSelectedTp(['None Selected']);
            setDat([]);
        }
    };






    const handleSelectAllTP = (e) => {
        const checked = e.target.checked;

        if (checked) {
            setIsAllSelectedTp(true);
            let filteredData = props.chartData?.filter(x => source.includes(x.source));
            const allSubgroups = [...new Set((filteredData).map(time => time.subgroup))];
            // const allTimeperiods = [...new Set((backupDat?.length > 0 ? backupDat : filteredData).map(time => time.timeperiod))];

            let allTimeperiods = filteredData?.filter((item) => {
                return (
                    selectedSub?.includes(item?.subgroup)
                )
            });
            let tp = [];
            allTimeperiods?.map((x) => {
                return (
                    tp.push(x?.timeperiod)
                )
            });

            setSelectedTp([...new Set(tp)]);
            // setSelectedSub(allSubgroups);
            setDat(filteredData?.filter((x) => selectedSub?.includes(x?.subgroup)));
        } else {
            setIsAllSelectedTp(false);
            // setIsAllSelectedSub(false);
            setSelectedTp(['None Selected']);
            // setSelectedSub(['None Selected']);
            setDat([]);
        }
    };





    const resetState = () => {
        let filteredData = props.chartData.filter(x => source.includes(x.source));
        setSelectedTp([]);
        setSelectedSub([]);
        setDat(filteredData)
        // setBakupDat(props.chartData)
    }




    const downloadCsvData = () => {
        let downloadData = dat.map((element) => {
            return (

                {
                    Area: element?.area,
                    Indicator: element?.indicator,
                    Unit: element?.unit,
                    Subgroup: element?.subgroup,
                    "Time period": element?.timeperiod,
                    "Data Value": element?.value,
                    Source: element?.source,
                }
            )
        })



        downloadFile(arrayToCSV(downloadData), `SDG_Data_${getDate()}`)
    }

    const arrayToCSV = (data) => {
        const csv = data.map(row => Object.values(row));
        csv.unshift(Object.keys(data[0]));
        return csv.join('\n');
    }

    const downloadFile = (data, filename = 'data') => {
        let csvData = data
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    const getDate = () => {
        let d = new Date();
        let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
            d.getHours() + ":" + d.getMinutes();
        return (datestring.replace(':', '.'))
    }


    const generateTimeperiodMenuItems = (data, selectedTp, selectedSub) => {
        const uniqueTimeperiod = new Set();
        const menuItems = [];

        let accordingToSubgroup = data?.filter((x) => {
            return (
                selectedSub?.includes(x?.subgroup)
            )
        })
        let selectedTimeperiod = selectedTp.filter((item) => item !== 'None Selected');
        let sortedData = accordingToSubgroup.slice().sort((a, b) => b?.timeperiod - a?.timeperiod);

        for (const item of sortedData) {
            if (!uniqueTimeperiod.has(item?.timeperiod)) {
                uniqueTimeperiod.add(item?.timeperiod);
                menuItems.push(
                    <MenuItem key={ item?.timeperiod } value={ item?.timeperiod }>
                        <label style={ { 'margin': '0', 'display': 'block' } }>
                            <Checkbox checked={ selectedTimeperiod.indexOf(item?.timeperiod) > -1 } />
                            { item?.timeperiod }
                        </label>
                    </MenuItem>
                );
            }
        }
        return menuItems;
    }

    const generateSubgroupMenuItems = (data, selectedSub) => {
        const uniqueSubgroup = new Set();
        const menuItems = [];
        let filteredData = data?.filter(x => source.includes(x.source));
        let sortedData = filteredData.slice().sort((a, b) => a?.subgroup_order - b?.subgroup_order);
        let selectedSubgroup = selectedSub.filter((item) => item !== 'None Selected');


        for (const item of sortedData) {
            if (!uniqueSubgroup.has(item?.subgroup)) {
                uniqueSubgroup.add(item?.subgroup);
                menuItems.push(
                    <MenuItem key={ item?.subgroup } value={ item?.subgroup }>
                        <label style={ { 'margin': '0', 'display': 'block' } }>
                            <Checkbox checked={ selectedSubgroup.indexOf(item?.subgroup) > -1 } />
                            { item?.subgroup.replace('|', " ") }
                        </label>
                    </MenuItem>
                );
            }
        }

        return menuItems;
    }

    const fullpage = () => {
        $('#analysis-modal').addClass('fullscreen');
        $('.expand_btn').hide();
        $('.collapse_btn').show();
    }

    const Exitfullpage = () => {
        $('#analysis-modal').removeClass('fullscreen');
        $('.collapse_btn').hide();
        $('.expand_btn').show();
    }

    return (
        <>

            <div id="downloadchartds">
                <div className='indicator-area'>
                    <div className='col-md-auto pr-0'>
                        <div className="charts_area_drop">
                            { route !== '/dashboard/overview' &&
                                <button data-toggle="modal" data-target="#metadata-modal"> <img src="assets/lib/images/new-home/meta.svg" alt="" /></button>
                            }
                            {/* {(type !== "map" && type != 'table') && <button disabled={dat?.length ? false : true} onClick={(e) => { ChartlabelNev(); }}> <img id="label" src="assets/lib/images/home/label_on.svg" /></button>}
                                {(type !== "map" &&
                                    type != 'table' &&
                                    type !== "pie" &&
                                    type !== "doughnut" &&
                                    type !== "radar" &&
                                    type !== "treemap"
                                ) && <button disabled={dat?.length ? false : true} onClick={(e) => { onSwap(!swap) }}> <img src="assets/lib/images/home/resets.svg" /></button>}
                                <button disabled={dat?.length ? false : true} onClick={(e) => { type !== 'table' ? downloadChart() : downloadCsvData() }}><img src="assets/lib/images/home/download.svg" /></button> */}
                            { dat?.length > 0 &&
                                <Dropdown alignRight disabled={ dat?.length ? false : true }>
                                    <Dropdown.Toggle variant="light" className="btn burger-menu" disabled={ dat?.length ? false : true } type="button" data-toggle="dropdown" aria-expanded="false">
                                        <img src="assets/lib/images/new-home/chart_hum.svg" alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item title="Table" className="dropdown-item" onClick={ () => changeChartType('table', props.dataId) }><img src="assets/lib/images/charts/icon-1.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Line" onClick={ () => changeChartType('line', props.dataId) }>   <img src="assets/lib/images/charts/icon-2.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Column" onClick={ () => changeChartType('bar', props.dataId) }>    <img src="assets/lib/images/charts/icon-3.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Bar" onClick={ () => changeChartType('column', props.dataId) }>    <img src="assets/lib/images/charts/icon-3.svg" style={ { transform: 'rotate(90deg)' } } /></Dropdown.Item>
                                        <Dropdown.Item title="Pie" onClick={ () => changeChartType('pie', props.dataId) }>    <img src="assets/lib/images/charts/icon-5.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Donut" onClick={ () => changeChartType('doughnut', props.dataId) }>    <img src="assets/lib/images/charts/icon-6.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Tree Map" onClick={ () => changeChartType('treemap', props.dataId) }>    <img src="assets/lib/images/charts/icon-11.svg" /></Dropdown.Item>
                                        <Dropdown.Item title="Radar" onClick={ () => changeChartType('radar', props.dataId) } className=''>    <img src="assets/lib/images/charts/icon-12.svg" /></Dropdown.Item>
                                        <hr className='m-0' />
                                        <li className='position-relative multiCollapseShare'>
                                            <a data-toggle="dropdown" className='action_icon z' title="Share">
                                                <img src="assets/lib/images/charts/share.svg" />
                                            </a>
                                            <div className='shareBtn'>
                                                <ul className="">
                                                    <li>
                                                        <button>
                                                            <FacebookShareButton
                                                                ref={ fbShareButton }
                                                                openShareDialogOnClick={ url !== "none" }
                                                                url={ url }
                                                                quote={ "title" }
                                                                onClick={ () => onClick('fb') }
                                                            >

                                                                {/* <svg viewBox="0 0 64 64" width="25" height="25"><rect width="64" height="64" rx="0" ry="0" fill="#3b5998"></rect><path d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z" fill="white"></path></svg> */ }

                                                                <FacebookIcon size={ 25 } />
                                                            </FacebookShareButton>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        {/* <button> */ }
                                                        <TwitterShareButton
                                                            ref={ twShareButton }
                                                            openShareDialogOnClick={ url !== "none" }
                                                            url={ url }
                                                            quote={ "title" }
                                                            onClick={ () => onClick('tw') }
                                                            className='twitter_icon'
                                                        >
                                                            {/* <svg viewBox="0 0 64 64" width="25" height="25"><rect width="64" height="64" rx="0" ry="0" fill="#00aced"></rect><path d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z" fill="white"></path></svg> */ }
                                                            {/* <TwitterIcon size={25} /> */ }
                                                            <img src="assets/images/twitter.svg" />
                                                        </TwitterShareButton>
                                                        {/* </button> */ }
                                                    </li>
                                                    <li>
                                                        <button>
                                                            <LinkedinShareButton
                                                                ref={ lnShareButton }
                                                                openShareDialogOnClick={ url !== "none" }
                                                                url={ url }
                                                                quote={ "title" }
                                                                onClick={ () => onClick('ln') }
                                                            >
                                                                {/* <svg viewBox="0 0 64 64" width="25" height="25"><rect width="64" height="64" rx="0" ry="0" fill="#007fb1"></rect><path d="M20.4,44h5.4V26.6h-5.4V44z M23.1,18c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1 c1.7,0,3.1-1.4,3.1-3.1C26.2,19.4,24.8,18,23.1,18z M39.5,26.2c-2.6,0-4.4,1.4-5.1,2.8h-0.1v-2.4h-5.2V44h5.4v-8.6 c0-2.3,0.4-4.5,3.2-4.5c2.8,0,2.8,2.6,2.8,4.6V44H46v-9.5C46,29.8,45,26.2,39.5,26.2z" fill="white"></path></svg> */ }
                                                                <LinkedinIcon size={ 25 } />
                                                            </LinkedinShareButton>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </li>
                                        { (type !== "map" && type !== 'table') && <button disabled={ dat?.length ? false : true } onClick={ (e) => { ChartlabelNev(); } }> { label ? <img title="Label off" src="assets/lib/images/home/label-off.svg" /> : <img title="Label on" src="assets/lib/images/home/label_on.svg" /> }</button> }
                                        { (type !== "map" && type !== 'table') && <button disabled={ dat?.length ? false : true } onClick={ (e) => { chartLegend(); } }> { legend ? <img title="Legend off" src="assets/lib/images/home/legend-off.svg" /> : <img title="Legend on" src="assets/lib/images/home/legend-on.svg" /> }</button> }
                                        { (type !== "map" &&
                                            type != 'table' &&
                                            type !== "pie" &&
                                            type !== "doughnut" &&
                                            type !== "radar" &&
                                            type !== "treemap"
                                        ) && <button title="Swap" disabled={ dat?.length ? false : true } onClick={ (e) => { onSwap(!swap) } }> <img src="assets/lib/images/home/resets.svg" /></button> }
                                        <button title="Download" disabled={ dat?.length ? false : true } onClick={ (e) => { type !== 'table' ? downloadChart() : downloadCsvData() } }><img src="assets/lib/images/home/download.svg" /></button>
                                        <button title="Full Screen" className='expand_btn' onClick={ () => { fullpage() } }><img src="assets/lib/images/home/icon_expand.svg" /></button>
                                        <button title="Collapse"  style={ { display: 'none' } } className='collapse_btn' onClick={ () => { Exitfullpage() } }><img src="assets/lib/images/home/icon_collapse.svg" /></button>

                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                    </div>
                </div>
                { props?.chartData?.length > 0 &&
                    <div className='mt-3 mb-3'>
                        <div className='analysisChart pl-0 px-3'>
                            <ul>
                                <li>
                                    {/* <label>Subgroup</label> */ }
                                    <Box>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Subgroup</InputLabel> */ }
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                value={ selectedSub }
                                                multiple
                                                label=""
                                                onChange={ (e) => { handleSubgroupChange(e) } }
                                                MenuProps={{autoFocus: false }}
                                                renderValue={ (selected) => {
                                                    if (selected.length === 1) {
                                                        return selected[0]; // Display name of the single selected item
                                                    } else if (!isAllSelectedSub && selected.length > 1) {
                                                        return `${selected.length} Selected`; // Display number of selected items
                                                    } else if (isAllSelectedSub) {
                                                        return 'All Selected';
                                                    }
                                                    const uniqueSelected = [...new Set(selected)]; // Convert selected to a Set and back to an array to remove duplicates
                                                    return uniqueSelected.join(', ');
                                                } }
                                            >
                                                <MenuItem>
                                                    <label style={ { 'margin': '0', 'display': 'block' } }>
                                                        <Checkbox
                                                            checked={ isAllSelectedSub }
                                                            // indeterminate={[...new Set(selectedSub)].length > 0 && selectedSub.length < (props.chartData).length}
                                                            onChange={ (e) => { handleSelectAllSub(e) } }
                                                        />
                                                        Select All
                                                    </label>
                                                </MenuItem>
                                                {/* {(props.chartData && props.chartData.length > 0) &&
                          props.chartData.map((item, index) => (
                            <MenuItem key={index} value={item?.subgroup}>
                              <Checkbox checked={selectedSub.indexOf(item?.subgroup) > -1} />
                              {item?.subgroup}
                            </MenuItem>
                          ))
                        } */}
                                                {
                                                    // const sortsubgroupOrder=props.chartData?.sort((a,b)=> a.subgroup_order - b?.subgroup_order)
                                                    // generateSubgroupMenuItems(props?.chartData?.sort((a, b) => a?.subgroup_order - b?.subgroup_order), selectedSub)
                                                }
                                                { sgMenuItems }
                                            </Select>

                                        </FormControl>
                                    </Box>
                                </li>
                                <li>
                                    {/* <label>Time Period</label> */ }
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                id="timeperiod-select"
                                                multiple
                                                value={ selectedTp }
                                                onChange={ (e) => { handleTimeperiodChange(e) } }
                                                renderValue={ (selected) => {
                                                    if (selected.length === 1) {
                                                        return selected[0]; // Display name of the single selected item
                                                    } else if (!isAllSelectedTp && selected.length > 1) {
                                                        return `${selected.length} Selected`; // Display number of selected items
                                                    } else if (isAllSelectedTp) {
                                                        return 'All Selected';
                                                    }
                                                    const uniqueSelected = [...new Set(selected)]; // Convert selected to a Set and back to an array to remove duplicates
                                                    return uniqueSelected.join(', ');
                                                } }
                                            >
                                                <MenuItem>
                                                    <label style={ { 'margin': '0', 'display': 'block' } }>
                                                        <Checkbox
                                                            checked={ isAllSelectedTp }
                                                            // indeterminate={selectedTp.length > 0 && selectedTp.length < (backupDat?.length > 0 ? backupDat : props.chartData).length}
                                                            onChange={ (e) => { handleSelectAllTP(e) } }
                                                        />
                                                        Select All
                                                    </label>
                                                </MenuItem>
                                                {/* {generateTimeperiodMenuItems(backupDat?.length > 0 ? backupDat : props.chartData?.filter((x) => source.includes(x.source)), selectedTp)} */ }
                                                { tpMenuItems }
                                            </Select>

                                        </FormControl>
                                    </Box>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                { type !== 'table' &&
                    <>
                        <div className='dsrChart'>
                            <div id="og" className={ type == "map" ? "position-relative" : "" }>
                                <p className="target_dis sub-category-text secInd indDD">{ props.chartData?.[0]?.indicator }  <span style={ { opacity: 0.6 } }>| { props.chartData?.[0]?.unit }</span></p>
                                <div className={ type == "map" ? "dsr_barchart dsr_Mapchart" : "dsr_barchart" }>
                                    {/* <div className="dsrBarchart_section">
                    <div className="dsrBarchart_list">
                      <ul>
                        {props.type !== "map" && (
                          <li
                            onClick={(e) => {
                              ChartlabelNev();
                            }}
                          >
                            <a href="javascript:void(0);">
                              <img
                                id="label"
                                src="assets/lib/images/home/label_on.svg"
                              />
                            </a>
                          </li>
                        )}
                        {props.type !== "map" &&
                          props.type !== "pie" &&
                          props.type !== "doughnut" &&
                          props.type !== "radar" &&
                          props.type !== "treemap" && (
                            <li
                              onClick={(e) => {
                                onSwap(!swap);
                              }}
                            >
                              <a href="javascript:void(0);">
                                <img src="assets/lib/images/home/resets.svg" />
                              </a>
                            </li>
                          )}
                        <li>
                  <a href="javascript:void(0);">
                    <img src="assets/lib/images/home/setting.svg" />
                  </a>
                </li>
                        <li
                          onClick={(e) => {
                            downloadChart();
                          }}
                        >
                          <a href="javascript:void(0);">
                            <img src="assets/lib/images/home/download.svg" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="indicator-area"><p>{props.type == "map" ? "" : title}</p></div> */}
                                </div>
                                <div className={legend ? "canvasFull": ""}>
                                <div
                                    className={
                                        showData?.length == 1
                                            ? "single-value drsChartss dsrChartBox"
                                            : "multiple-value drsChartss dsrChartBox"
                                    }
                                >
                                    <div
                                        ref={ chartRefs }
                                        style={ {
                                            //  width: '500px',
                                            //   height: '500px'
                                            width: props.width,
                                            height: props.height
                                        } }
                                        className={ type == "map" ? "chartsdsr chartdsrMap" : "chartsdsr" }
                                    />

                                    { type == "map" && (
                                        <>
                                            { isCompass == true && (
                                                <span class="comapss datasearchCompass">
                                                    <img src="assets/map/compass.svg" />
                                                </span>
                                            ) }
                                            <div className="legends">
                                                <ul>
                                                    <li>
                                                        <Tooltip title={ dataSearchResultLang?.["zoomIn"]?.['en'] } placement="right">
                                                            <a
                                                                style={ { cursor: "pointer" } }
                                                                to=""
                                                                onClick={ (e) => zoomin() }
                                                            >
                                                                <img src="assets/lib/images/home/zoom-in.svg" />
                                                            </a>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title={ dataSearchResultLang?.["zoomOut"]?.['en'] } placement="right">
                                                            <a
                                                                style={ { cursor: "pointer" } }
                                                                to=""
                                                                onClick={ (e) => zoomout() }
                                                            >
                                                                <img src="assets/lib/images/home/zoom-out.svg" />
                                                            </a>
                                                        </Tooltip>
                                                    </li>
                                                    <li className="wmtm_reset">
                                                        <Tooltip title={ dataSearchResultLang?.["reset"]?.['en'] } placement="right">
                                                            <a
                                                                style={ { cursor: "pointer" } }
                                                                to=""
                                                                onClick={ (e) => reset() }
                                                            >
                                                                <img src="assets/lib/images/home/reset.svg" />
                                                            </a>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        { label ? (
                                                            <Tooltip title={ dataSearchResultLang?.["labelOff"]?.['en'] } placement="right">
                                                                <a
                                                                    style={ { cursor: "pointer" } }
                                                                    onClick={ (e) => showLabel(e) }
                                                                >
                                                                    <img
                                                                        src="assets/lib/images/home/label_on.svg"
                                                                        //  src="assets/lib/images/home/label-off.svg"
                                                                        alt="onof"
                                                                        className="img-label"
                                                                    />
                                                                </a>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={ dataSearchResultLang?.["labelOn"]?.['en'] } placement="right">
                                                                <a
                                                                    style={ { cursor: "pointer" } }
                                                                    onClick={ (e) => showLabel(e) }
                                                                >
                                                                    <img
                                                                        // src="assets/lib/images/home/label-off.svg"
                                                                        src="assets/lib/images/home/label_on.svg"
                                                                        alt="on"
                                                                        className="img-label"
                                                                    />
                                                                </a>
                                                            </Tooltip>
                                                        ) }
                                                    </li>
                                                    <li>
                                                        { legend ? (
                                                            <Tooltip title={ dataSearchResultLang?.["labelOff"]?.['en'] } placement="right">
                                                                <a
                                                                    style={ { cursor: "pointer" } }
                                                                    onClick={ (e) => showLegend(e) }
                                                                >
                                                                    <img
                                                                        src="assets/lib/images/home/label_on.svg"
                                                                        //  src="assets/lib/images/home/label-off.svg"
                                                                        alt="onof"
                                                                        className="img-label"
                                                                    />
                                                                </a>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={ dataSearchResultLang?.["labelOn"]?.['en'] } placement="right">
                                                                <a
                                                                    style={ { cursor: "pointer" } }
                                                                    onClick={ (e) => showLegend(e) }
                                                                >
                                                                    <img
                                                                        // src="assets/lib/images/home/label-off.svg"
                                                                        src="assets/lib/images/home/label_on.svg"
                                                                        alt="on"
                                                                        className="img-label"
                                                                    />
                                                                </a>
                                                            </Tooltip>
                                                        ) }
                                                    </li>
                                                    <li>
                                                        <Tooltip title={ dataSearchResultLang?.["northPole"]?.['en'] } placement="right">
                                                            <a
                                                                to=""
                                                                style={ { cursor: "pointer" } }
                                                                onClick={ (e) => toggleCompass() }
                                                            >
                                                                <img src="assets/lib/images/home/north_pole.svg" />
                                                            </a>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="dis_area">
                                                <p class="disc_para">Disclaimer </p>
                                                <div className="disclaimer_content">
                                                    <p>Maps are for graphical purposes only. They do not represent a legal survey. The boundaries and the names shown and the designations used on these maps are for demonstration purposes. While every effort has been made to ensure that these data are accurate and reliable within the limits of the current state of the art, I-Tech Mission cannot assume liability for any damages caused by any errors or omissions in the data, nor as a result of the failure of the data to function on a particular system. Users of our maps and other analysis products are solely responsible for interpretations made from these products.</p>
                                                </div>
                                            </div>
                                            { !noData &&
                                                <div class="legendsNumber legendsChart">
                                                    <p> { indicator?.unit }</p>
                                                    <ul>
                                                        {
                                                            legend?.map((l, i) => {
                                                                return (<li>
                                                                    <span class={ "lnColor" + (i + 1) }>

                                                                    </span>{ l.label }</li>
                                                                )
                                                            })
                                                        }

                                                        <li>
                                                            <span class="lnColor5">
                                                                {/* No Data */ }

                                                            </span> { dataSearchResultLang.noData['en'] }
                                                        </li>
                                                    </ul>
                                                </div>
                                            }


                                            { (mapState?.uniSrc?.[indicator?.indicator + '-' + indicator?.unit + '-' + indicator?.subgroup + '-' + timeperiod]) && <div className="drs_sources">
                                                <span>Source</span>
                                                { dataSearchResultLang.Source['en'] }
                                                {/* <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {source}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {mapState?.uniSrc?.[indicator?.indicator + '-' + indicator?.unit + '-' + indicator?.subgroup + '-' + timeperiod]
                              ?.map((x) => {
                                return (
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      onChange(x, "src");
                                    }}
                                  >
                                    {x}
                                  </Dropdown.Item>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown> */}

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={ source }
                                                    multiple
                                                    label="Source"
                                                    renderValue={ (selected) => {
                                                        if (selected.length === 1) {
                                                            return selected[0]; // Display name of the single selected item
                                                        } else if (!source?.includes('*') && selected.length > 1) {
                                                            return `${selected.length} Selected`; // Display number of selected items
                                                        } else if (source?.includes('*')) {
                                                            return 'All Selected';
                                                        }
                                                    } }
                                                // onMouseDown={() => { src_change() }}
                                                >
                                                    <MenuItem>
                                                        <label style={ { 'margin': '0', 'display': 'block' } }>
                                                            <Checkbox
                                                                checked={ source?.includes('*') }
                                                                onChange={ (e) => {
                                                                    onChangeSrc('*', "src", e);
                                                                } }
                                                            />
                                                            Select All
                                                        </label>
                                                    </MenuItem>
                                                    { srcList?.filter(x => x !== '*')?.map((x) => (
                                                        <MenuItem key={ x }>
                                                            <label style={ { 'margin': '0', 'display': 'block' } }>
                                                                <Checkbox
                                                                    checked={ source?.includes(x) }
                                                                    onChange={ (e) => {
                                                                        onChangeSrc(x, "src", e);
                                                                    } }
                                                                />
                                                                { x }
                                                            </label>
                                                        </MenuItem>
                                                    )) }

                                                </Select>
                                            </div>
                                            }




                                        </>
                                    ) }

                                </div>
                                </div>
                                <div className="downloadchartds mt-4">
                                    {
                                        (srcList?.length > 0) &&
                                        <div className={ type == "map" ? "d-none" : "drs_sources chartSource" }>
                                            { dataSearchResultLang.Source['en'] }
                                            {/* <Dropdown> */ }
                                            {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {source}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {srcList?.map((x) => {
                            return (
                              <Dropdown.Item
                                onClick={(e) => {
                                  onChange(x, "src");
                                }}
                              >
                                {x}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu> */}
                                            {/* </Dropdown> */ }
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={ source }
                                                multiple
                                                label="Source"
                                                renderValue={ (selected) => {
                                                    if (selected.length === 1) {
                                                        return selected[0]; // Display name of the single selected item
                                                    } else if (!source?.includes('*') && selected.length > 1) {
                                                        return `${selected.length} Selected`; // Display number of selected items
                                                    }
                                                    else if (source?.includes('*') && source.filter(x => x !== '*').length === 1) {
                                                        return selected[0];
                                                    }
                                                    else if (source?.includes('*')) {
                                                        return 'All Selected';
                                                    }
                                                }
                                                }
                                            >
                                                <MenuItem>
                                                    <label style={ { 'margin': '0', 'display': 'block' } }>
                                                        <Checkbox
                                                            checked={ source.includes('*') }
                                                            onChange={ (e) => {
                                                                onChangeSrc('*', 'src', e);
                                                            } }
                                                        />
                                                        Select All
                                                    </label>
                                                </MenuItem>
                                                { srcList.map((x) => (
                                                    <MenuItem key={ x }>
                                                        <label style={ { 'margin': '0', 'display': 'block' } }>
                                                            <Checkbox
                                                                checked={ source.includes(x) }
                                                                onChange={ (e) => {
                                                                    onChangeSrc(x, 'src', e);
                                                                } }
                                                            />
                                                            { x }
                                                        </label>
                                                    </MenuItem>
                                                )) }
                                            </Select>
                                        </div>
                                    }
                                </div>


                                <div className="mapSource indDD">
                                    { (source !== '' && source !== null && source !== undefined) && <span> { dataSearchResultLang.Source['en'] }: { source.filter(x => x !== '*').join(', ') }</span> }

                                </div>
                            </div>
                        </div>
                    </>
                }


                { type === 'table' &&

                    <>

                        <div className="mainTableView position-relative">

                            <div className="dsrTablesearch">
                                <div className='w-100'>

                                </div>
                            </div>

                            <DataTable
                                columns={ columns }
                                data={ dat }
                                // selectableRows
                                // selectableRowsComponent={Checkbox}
                                // selectableRowsComponentProps={{ inkDisabled: true }}
                                // onSelectedRowsChange={(s) => selectedRowsChange(s)}
                                // clearSelectedRows={this.state.toggledClearRows}
                                sortIcon={ <ArrowDownwardIcon /> }
                                persistTableHead
                                className="dsrTableComponent"
                                noDataComponent="No Data"
                            // pagination
                            // paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            />

                        </div>
                        <div className='drs_sources chartSource align-items-baseline'>
                            <label>Source</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={ source }
                                multiple
                                label="Source"
                                renderValue={ (selected) => {
                                    console.log("Selected checkp", selected);
                                    if (selected.length === 1) {
                                        return selected[0]; // Display name of the single selected item
                                    } else if (!source?.includes('*') && selected.length > 1) {
                                        return `${selected.length} Selected`; // Display number of selected items
                                    }
                                    else if (source?.includes('*') && source.filter(x => x !== '*').length === 1) {
                                        return selected[0];
                                    }
                                    else if (source?.includes('*')) {
                                        return 'All Selected';
                                    }
                                }
                                }
                            >
                                <MenuItem>
                                    <label style={ { 'margin': '0', 'display': 'block' } }>
                                        <Checkbox
                                            checked={ source.includes('*') }
                                            onChange={ (e) => {
                                                onChangeSrc('*', 'src', e);
                                            } }
                                        />
                                        Select All
                                    </label>
                                </MenuItem>
                                { srcList.map((x) => (
                                    <MenuItem key={ x }>
                                        <label style={ { 'margin': '0', 'display': 'block' } }>
                                            <Checkbox
                                                checked={ source.includes(x) }
                                                onChange={ (e) => {
                                                    onChangeSrc(x, 'src', e);
                                                } }
                                            />
                                            { x }
                                        </label>
                                    </MenuItem>
                                )) }
                            </Select>
                        </div>

                    </> }

            </div>

            <div className="downloadChartBG">
                <div id="copy" className="" style={ { display: "none" } }></div>
            </div>


            <div
                className={ loader ? "loader text-center " : "loader text-center hide" }
            >
                <img
                    src="assets/images/Comp-2.gif"
                    style={ { marginTop: "12%", width: "30%" } }
                />
            </div>




            <div class="modal fade analysis-modal" id="metadata-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 className='modal-title'>Indicator Metadata</h5>
                            <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close" onClick={ () => { resetState(); Exitfullpage() } }>
                                <img src='assets/lib/images/close.svg' className='img-fluid' />
                            </button>
                        </div>
                        <div class="modal-body">
                            {/* <img src='assets/lib/images/analysis-img.png' className='img-fluid'/> */ }
                            <div className='row'>
                                <div className="col-md-12">

                                    <div className="targets_box">

                                        {/* <h2 className="target_head">Indicator</h2>
                    <p className="target_dis">{props.chartData?.[0]?.indicator}</p>

                    <h2 className="target_head">Goal</h2>
                    <p className="target_dis">{props.exData?.goal}</p> */}

                                        <h2 className="target_head">Target</h2>
                                        <p className="target_dis">{ props.exData?.target }</p>

                                        <h2 className="target_head">Indicator Definition</h2>
                                        <p className="target_dis">{ props.metaData?.mt1?.en }</p>

                                        <h2 className="target_head">Method of Computation</h2>
                                        <p className="target_dis">{ props.metaData?.mt2?.en }</p>

                                        <h2 className="target_head">Overview</h2>
                                        <p className="target_dis">{ props.metaData?.mt3?.en }</p>

                                        <h2 className="target_head">Comments and Limitations</h2>
                                        <p className="target_dis">{ props.metaData?.mt4?.en }</p>

                                        <h2 className="target_head">Data Collection for Global Monitoring</h2>
                                        <p className="target_dis">{ props.metaData?.mt5?.en }</p>

                                        <h2 className="target_head">Obtaining Data</h2>
                                        <p className="target_dis">{ props.metaData?.mt6?.en }</p>

                                        <h2 className="target_head">Data Availability</h2>
                                        <p className="target_dis">{ props.metaData?.mt7?.en }</p>

                                        <h2 className="target_head">Treatment of Missing Values</h2>
                                        <p className="target_dis">{ props.metaData?.mt8?.en }</p>

                                        <h2 className="target_head">Regional and Global Estimates</h2>
                                        <p className="target_dis">{ props.metaData?.mt9?.en }</p>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    );

};

const useCaseMatrix = {
    1: {
        //indicator
        1: {
            //unit
            1: {
                //subgroup
                1: {
                    //area
                    1: {
                        //timeperiod
                        useCase: "UC1",
                    },
                    2: {
                        useCase: "UC2",
                    },
                },
                2: {
                    1: {
                        useCase: "UC3",
                    },
                    2: {
                        useCase: "UC4",
                    },
                },
            },
            2: {
                1: {
                    1: {
                        useCase: "UC5",
                    },
                    2: {
                        useCase: "UC17",
                    },
                },
                2: {
                    1: {
                        useCase: "UC6",
                    },
                    2: {
                        useCase: "UC7",
                    },
                },
            },
        },
        2: {
            1: {
                1: {
                    1: {
                        useCase: "UC13",
                    },
                    2: {
                        useCase: "UC12",
                    },
                },
                2: {
                    1: {
                        useCase: "UC22",
                    },
                    2: {
                        useCase: "UC11",
                    },
                },
            },
            2: {
                1: {
                    1: {
                        useCase: "UC15",
                    },
                    2: {
                        useCase: "UC23",
                    },
                },
                2: {
                    1: {
                        useCase: "UC24",
                    },
                    2: {
                        useCase: "UC21",
                    },
                },
            },
        },
    },
    2: {
        1: {
            1: {
                1: {
                    1: {
                        useCase: "UC14",
                    },
                    2: {
                        useCase: "UC18",
                    },
                },
                2: {
                    1: {
                        useCase: "UC19",
                    },
                    2: {
                        useCase: "UC8",
                    },
                },
            },
            2: {
                1: {
                    1: {
                        useCase: "UC20",
                    },
                    2: {
                        useCase: "UC27",
                    },
                },
                2: {
                    1: {
                        useCase: "UC25",
                    },
                    2: {
                        useCase: "UC26",
                    },
                },
            },
        },
        2: {
            1: {
                1: {
                    1: {
                        useCase: "UC16",
                    },
                    2: {
                        useCase: "UC28",
                    },
                },
                2: {
                    1: {
                        useCase: "UC29",
                    },
                    2: {
                        useCase: "UC9",
                    },
                },
            },
            2: {
                1: {
                    1: {
                        useCase: "UC30",
                    },
                    2: {
                        useCase: "UC32",
                    },
                },
                2: {
                    1: {
                        useCase: "UC31",
                    },
                    2: {
                        useCase: "UC10",
                    },
                },
            },
        },
    },
};



export default AnalysisChart;
