import { Component } from "react";
import "./user.css";
import * as jsx from "./user.module.jsx";
import * as $ from "jquery";
import * as constants from "../../../Config/app.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import swal from "sweetalert";
import AdminService from "../../../services/admin.service";
import { connect } from "react-redux";

class User extends Component {
  constructor() {
    super();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let userRole = userInfo?.role;
    this.state = {
      filteredData: [],
      searchText: "",
      modal: false,
      data: [],
      adminEmail: [],
      isEdit: false,
      editModeId: null,
      areaList: [],
      parentCallback: [],
      areaData: [],
      loader: false,
      bulkDelete: [],
      noSupervisorData: [],
      userRole: userRole,
      toTop : 0
    };
  }

  editMode(event, row_id) {
    event.preventDefault();
    this.setState({ isEdit: true, modal: true });

    this.state.data.forEach((item) => {
      if (item._id == row_id) {
        this.setState({ editModeId: item });
      }
    });

    this.openEditModal();
  }

  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true, toTop: (this.state.toTop+1) });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null });
    }
    
  };

  openEditModal() {
    if ($("#EditAreaModal").hasClass("width350")) {
      $("#EditAreaModal").removeClass("width350");
      $("#EditAreaModal").addClass("width0");
      $(".overlay").removeClass("dblock");
    } else {
      $("#EditAreaModal").addClass("width350");
      $("#EditAreaModal").removeClass("width0");
      $(".overlay").addClass("dblock");
    }
  }

  // sortDataByDate = () => {
  //   const sortedData = [...data].sort((a, b) => {
  //     return new Date(b.date) - new Date(a.date);
  //   });
  //   setData(sortedData);
  // }


  columns = [
    // {
    //   name: "#",
    //   selector: (row, index) => index + 1,
    //   // sortable: true,
    //   // width: "50px"
    // },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row?.department?.department_name ? row?.department?.department_name : "" ,
      sortable: true,
    },
    {
      name: "Assigned Area",
      selector: (row) => (
        <>
          {row?.area_name?.length > 0 &&
            row?.area_name?.slice(0, 2).map((d, i) => {
              return (
                <>
                  <span className="badge">{d?.[0]?.name?.en} </span>
                </>
              );
            })}
          {row?.area_name?.length > 2 ? (
            <>
              <span>{row?.area_name?.length - 1}+</span>
            </>
          ) : (
            ""
          )}
        </>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const a = rowA?.area_name;
        const b = rowB?.area_name;

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      },
      
    },

    {
      name: "Role",
      selector: (row) => {
        if (row.role === 2) {
          return <span className="">Data Entry</span>;
        } else if (row.role === 4) {
          return <span className="">Data Approve</span>;
        }
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const a = rowA?.role;
        const b = rowB?.role;

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      },
      width: "150px",
    },
    {
      name: 'Action',
      width: "200px",
      cell: row => 
          <>
        
                <div className='action_btns'>
                 <button onClick={(e) => this.editMode(e, row._id)}> <img src="assets/lib/images/icon/edit_icon.svg" /></button>
                 <button onClick={(e)=> this.deleteEntry(row._id._id)}> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
          </div>
          </>,
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
  },
  ];

  makeid(length) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
  //==========add and edit api user=========///
  saveUserData = (saveData) => {
    console.log("saveData",saveData)
    if (this.state.isEdit && saveData.id != null) {
      AdminService.httpPut("/data-import/users/update/" + saveData.id, saveData)
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            let d = res.data;

            if (res.status != 0 && res.success == true) {
              this.setState({ loader: false });
              toast.success("User updated successfully");
              this.openAddModal();
              this.getData();
            } else if (res.status == 0) {
              this.setState({ loader: false });
              toast.error("Failed to update user");
            } else {
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          toast.error(err);
          this.setState({ loader: false });
          console.log(err);
        });
    } else {
      AdminService.httpPost("/data-import/users/add", saveData)
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            let d = res.getdata;
            if (res.success == true) {
              this.setState({ loader: false });
              toast.success("User added successfully");
              this.openAddModal();
              this.getData();
            } else {
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          console.log(err);
        });
    }
  };

  componentDidMount() {
    this.getData();
    this.getAllAreaData();
    this.getUserRoleData();
  }
  render() {
    return jsx.default.bind(this)();
  }

  ///============get api==========///

  getData = () => {
    this.setState({ loader: true, searchText: "" });

    let adminEmail = this.props.email;

    fetch(constants.server + "api/data-retrieval/users/all/" + adminEmail, {
      method: "get",
      headers: new Headers({
        Authorization: this.props.token,
        "Content-Type": "application/json",
      }),
      body: null,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ loader: false });

        let d = data.data.records;
        d.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
       
        let noSupervisorData = d;
        this.setState({
          data: d,
          filteredData: noSupervisorData,
          noSupervisorData: noSupervisorData,
        });
        
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  ///==========change status api=============

  changeStatus(event, id, status) {
    let changed = 0;
    if (status == 1) {
      changed = 0;
    } else {
      changed = 1;
    }
    let statusData = { userId: id, userStatus: changed };

    fetch(constants.server + "api/data-import/users/update-status", {
      method: "POST",
      body: JSON.stringify(statusData),
      headers: {
        Authorization: this.props.token,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //toastr
        toast.success("Status updated successfully");
        this.getData();
      })
      .catch((err) => {
        //toastr
        toast.error("Error in status update");
      });
  }
  ///////===========delete user api ============//////////

  deleteEntry = (id) => {
    swal({
      title: "Are you sure to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      
      if (willDelete) {
        console.log("Pooja id", id);
        const data = {
          selectedIds: id,
        };
        console.log("Pooja data", data);

        AdminService.httpPost("/data-import/users/delete", data)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res;

              console.log(res);

              if (res.success == true) {
                toast.success("User deleted successfully");
                this.getData();
              } else {
                toast.error("Failed to delete user");
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id._id);
    });

    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            selectedIds: ids.toString(),
          };
          AdminService.httpPost("/data-import/users/delete", data)
            .then((res) => {
              if (res) {
                this.setState({ loader: false });
                let d = res;

                console.log(res);

                if (res.success == true) {
                  toast.success("User deleted successfully");
                  this.getData();
                } else {
                  toast.error("Failed to delete user");
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });

      return false;
    }
  };

  getAllAreaData = async () => {
    try {
      const res = await AdminService.httpGet(
        "/data-retrieval/area/getAreaData"
      );
      if (res && res.data) {
        const { data } = res;
        this.setState({ areaData: data });
      }
    } catch (err) {}
  };



  // onSearch = (val) => {
  //   const { data } = this.state;
  //   const filteredData =
  //     val === ""
  //       ? data
  //       : data.filter(
  //           (item) =>
  //             (item.name &&
  //               item.name.toLowerCase().includes(val.toLowerCase())) 
  //               ||(item.email && item.email.toLowerCase().includes(val.toLowerCase())) 
  //               ||(item.department?.department_name  && item.department?.department_name .toLowerCase().includes(val.toLowerCase())) 
               
              
  //         );
  //   this.setState({ searchText: val, filteredData });
  // };
  onSearch = (val) => {
    const { data } = this.state;
    const filteredData = val === ""
      ? data
      : data.filter((item) => {
          const roleName = (item.role === 2) ? "Data Entry" : (item.role === 4) ? "Data Approve" : "";
          return (
            (item.name && item.name.toLowerCase().includes(val.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(val.toLowerCase())) ||
            (item.department?.department_name && item.department?.department_name.toLowerCase().includes(val.toLowerCase())) ||
            roleName.toLowerCase().includes(val.toLowerCase())
          );
        });
  
    this.setState({ searchText: val, filteredData });
  };
  

  ///////=======get facility ==========////////

  getUserRoleData = async () => {
    this.setState({ loader: true });

    AdminService.httpGet("/data-retrieval/data/masterData")
      .then((res) => {
        if (res) {
          let d = res[0].data;
          this.setState({ facilityData: d });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  email: state.login.userEmail,
});

export default connect(mapStateToProps)(User);
