// export const server = 'http://192.168.1.84/SDGDataPortel-T&T/deewar/public/'
// export const server = 'https://itmcloud.org/tandt_dev_apigateway/';

// export const resourceImagePath = "https://itmcloud.org/tnt-sdg-staging/resourceUploads/";
// export const facilityDocPath = "https://itmcloud.org/tnt-sdg-staging/facilityUploads/";
// export const logFilePath = "https://itmcloud.org/tnt-sdg-staging/logs/";
// export const uploadPath = "https://itmcloud.org/tnt-sdg-staging/importLog/";
// export const exportfile = "https://itmcloud.org/tnt-sdg-staging/exportwithoutdata/";
// export const host = "https://www.itmcloud.org/tnt-sdg-staging/";
// export const dataentryfile = "https://www.itmcloud.org/tnt-sdg-staging/uploadData/";

export const server = 'https://itmcloud.org/tandt_staging_apigateway/';

export const resourceImagePath = "https://itmcloud.org/tnt-sdg/resourceUploads/";
export const facilityDocPath = "https://itmcloud.org/tnt-sdg/facilityUploads/";
export const logFilePath = "https://itmcloud.org/tnt-sdg/logs/";
export const uploadPath = "https://itmcloud.org/tnt-sdg/importLog/";
export const exportfile = "https://itmcloud.org/tnt-sdg/exportwithoutdata/";
export const host = "https://www.itmcloud.org/tnt-sdg/";
export const dataentryfile = "https://www.itmcloud.org/tnt-sdg/uploadData/";

// export const server = 'http://localhost/SDGTandT_Project/deewar/public/';
// export const resourceImagePath = "http://localhost/SDGTandT_Project/resourceUploads/";
// export const facilityDocPath = "http://localhost/SDGTandT_Project/facilityDocs/";
// export const logFilePath = "http://localhost/SDGTandT_Project/download/withData/";
// export const uploadPath = "http://localhost/SDGTandT_Project/importLog/";

// export const resourceImagePath = "https://itmcloud.org/timor-lang/resourceUploads/";
// export const facilityDocPath = "https://itmcloud.org/timor-lang/facilityUploads/";
// export const logFilePath = "https://itmcloud.org/timor-lang/logs/";
// export const uploadPath = "https://itmcloud.org/timor-lang/importLog/";
// export const exportfile = "https://itmcloud.org/timor-lang/exportwithoutdata/";
// export const host = "https://www.itmcloud.org/timor-lang/";
// export const dataentryfile = "https://www.itmcloud.org/timor-lang/uploadData/";


// export const server = 'http://localhost/SDGDataPortal-TandT/deewar/public/';
// export const resourceImagePath = "http://localhost/SDGDataPortal-TandT/resourceUploads/";
// export const logFilePath = "http://localhost/SDGDataPortal-TandT/download/withData/";
// export const facilityDocPath = "https://itmcloud.org/tnt-sdg-staging/facilityDocs/";
// export const uploadPath = "http://localhost/SDGDataPortal-TandT/importLog/";
// // export const exportfile = "https://www.itmcloud.org/tnt-sdg-staging/exportwithoutdata/";
// export const exportfile = "http://localhost/SDGDataPortal-TandT/exportwithoutdata/";
// export const host = "https://www.itmcloud.org/tnt-sdg-staging/";
// export const dataentryfile = "https://www.itmcloud.org/tnt-sdg-staging/uploadData/";

export const shareUrl="https://itmcloud.org/tnt-sdg-staging/#/home"


export const actorByState = {
    Inactive: 0,
    Approved: 1,
    Pending: 2,
    Rejected: 3,
};

export const latitude = {
    min: -27.34584,
    max: -25.625111,
};

export const longitude = {
    min: 30.64892,
    max: 32.340815,
};

export const userRoles = [
    0, //guest
    1, //Admin
    5, //Actor
    2, //data Entry
    4, //data Approver
    6, //data Collector
    7, //Supervisor
];

export const masterList = [
    "Disaster Type",
    "Disaster State",
    "Necessity",
    "Destruction Level",
    "Housing Type",
    "Evalution Phase",
    "Infrastruture Damage Type",
    "Damage Type",
    "Damage Status",
    "Sickness Type",
    "Frequency",
    "Health facility",
    "Logistics",
    "Priority",
];


// export const masterListType = [
//     'Actors',
//     'Facility Type',
//     'Operating Temperature',
//     'Waste Type',
//     'Permission Status',
//     'Operating hours',
//     'Available Services',
//     'Frequency',
//     'Remaining Airspace',
//     'Collection Vehicle',
//     'Activity Type'
// ];

export const masterListType = [
    "Disaster Type",
    "Disaster State",
    "Necessity",
    "Housing Type",
    "Destruction Level",
    "Evalution Phase",
    "Infrastruture Damage Type",
    "Damage Type",
    "Damage Status",
    "Sickness Type",
    "Frequency",
    "Health facility",
    "Logistics",
    "Priority",
];





export const facilityPermissions = [
    {
        type: "Treatment of waste",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },
    {
        type: "Designated waste storage",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },
    {
        type: "Incinerator",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: false,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: true,
                multiple: false,
            },
        },
    },
    {
        type: "Recycling plant/facility",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: false,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },
    {
        type: "Waste transfer stations/facilities",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Composting facilities",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: false,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Remediation of contaminated land",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: false,
                multiple: false,
            },
            "Waste Type": {
                required: false,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: false,
                multiple: false,
            },
            "License Number": {
                required: false,
                multiple: false,
            },
            "License Awarded From": {
                required: false,
                multiple: false,
            },
            "License Awarded To": {
                required: false,
                multiple: false,
            },
            "Operating hours": {
                required: false,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Sanitary landfill",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Controlled dumpsite",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: true,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Wastewater treatment plant",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: true,
                multiple: false,
            },
            "Waste Type": {
                required: false,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: true,
                multiple: false,
            },
            "License Awarded From": {
                required: true,
                multiple: false,
            },
            "License Awarded To": {
                required: true,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },

    {
        type: "Buy-back centres/Drop-off points",
        data: {
            Name: {
                required: true,
                multiple: false,
            },
            Location: {
                required: true,
                multiple: false,
            },
            Capacity: {
                required: false,
                multiple: false,
            },
            "Waste Type": {
                required: false,
                multiple: true,
            },
            Coordinates: {
                required: true,
                multiple: false,
            },
            Picture: {
                required: true,
                multiple: false,
            },
            "Facility Type": {
                required: true,
                multiple: false,
            },
            "Permission Status": {
                required: true,
                multiple: false,
            },
            "License Number": {
                required: false,
                multiple: false,
            },
            "License Awarded From": {
                required: false,
                multiple: false,
            },
            "License Awarded To": {
                required: false,
                multiple: false,
            },
            "Operating hours": {
                required: true,
                multiple: false,
            },
            "Number of working days": {
                required: true,
                multiple: false,
            },
            "Available services": {
                required: true,
                multiple: false,
            },
            "Operating temperature": {
                required: false,
                multiple: false,
            },
        },
    },
];

export const actortype = {
    Generator: {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: true,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: false,
            multiple: false,
        },
        "Waste licence No.": {
            required: false,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: false,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: false,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    Collector: {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: true,
            multiple: false,
        },
        Capacity: {
            required: true,
            multiple: false,
        },
        Frequency: {
            required: true,
            multiple: false,
        },
        "Facility Type": {
            required: true,
            multiple: false,
        },
        "Waste licence No.": {
            required: true,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: true,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: true,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    "Recycler/reclaimer": {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: true,
            multiple: false,
        },
        "Waste licence No.": {
            required: true,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: true,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: true,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    Importer: {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: true,
            multiple: false,
        },
        "Waste licence No.": {
            required: true,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: true,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: true,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: true,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    Exporter: {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: true,
            multiple: false,
        },
        "Waste licence No.": {
            required: true,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: true,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: true,
            multiple: false,
        },
        Destination: {
            required: true,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    Disposer: {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: true,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: true,
            multiple: false,
        },
        "Type of waste": {
            required: true,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: true,
            multiple: false,
        },
        "Waste licence No.": {
            required: true,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: true,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: true,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },

    "Visitor/researcher": {
        Name: {
            required: true,
            multiple: false,
        },
        Location: {
            required: false,
            multiple: false,
        },
        "Phone Number": {
            required: true,
            multiple: false,
        },
        "Email ID": {
            required: false,
            multiple: false,
        },
        "Type of waste": {
            required: false,
            multiple: true,
        },
        "Generation rate (Tonnes/month)": {
            required: false,
            multiple: false,
        },
        "Collection Vehicle": {
            required: false,
            multiple: false,
        },
        Capacity: {
            required: false,
            multiple: false,
        },
        Frequency: {
            required: false,
            multiple: false,
        },
        "Facility Type": {
            required: false,
            multiple: false,
        },
        "Waste licence No.": {
            required: false,
            multiple: false,
        },
        "License Awarded From (Date)": {
            required: false,
            multiple: false,
        },
        "License Awarded To (Date)": {
            required: false,
            multiple: false,
        },
        Destination: {
            required: false,
            multiple: false,
        },
        Origin: {
            required: false,
            multiple: false,
        },
        "Date of registration": {
            required: true,
            multiple: false,
        },
    },
};

export const countryList = [
    "Angola	",
    "Burundi	",
    "Benin	",
    "Burkina Faso	",
    "Botswana	",
    "Central African Republic	",
    "Cote d’Ivoire	",
    "Cameroon	",
    "Congo, Dem. Rep.	",
    "Congo, Rep.	",
    "Comoros	",
    "Cabo Verde	",
    "Djibouti	",
    "Algeria	",
    "Egypt, Arab Rep.	",
    "Eritrea	",
    "Ethiopia	",
    "Gabon	",
    "Ghana	",
    "Guinea	",
    "Gambia, The	",
    "Guinea-Bissau	",
    "Equatorial Guinea	",
    "Kenya	",
    "Liberia	",
    "Libya	",
    "Lesotho	",
    "Morocco	",
    "Madagascar	",
    "Mali	",
    "Mozambique	",
    "Mauritania	",
    "Mauritius	",
    "Malawi	",
    "Namibia	",
    "Niger	",
    "Nigeria	",
    "Rwanda	",
    "Sudan	",
    "Senegal	",
    "Sierra Leone	",
    "Somalia	",
    "South Sudan	",
    "Sao Tome and Principe	",
    "Eswatini	",
    "Seychelles	",
    "Chad	",
    "Togo	",
    "Tunisia	",
    "Tanzania	",
    "Uganda	",
    "South Africa	",
    "Zambia	",
    "Zimbabwe	",
    "Afghanistan	",
    "United Arab Emirates	",
    "Armenia	",
    "Azerbaijan	",
    "Bangladesh	",
    "Bahrain	",
    "Brunei Darussalam	",
    "Bhutan	",
    "China	",
    "Cyprus	",
    "Georgia	",
    "Hong Kong SAR, China	",
    "Indonesia	",
    "India	",
    "Iran, Islamic Rep.	",
    "Iraq	",
    "Israel	",
    "Jordan	",
    "Japan	",
    "Kazakhstan	",
    "Kyrgyz Republic	",
    "Cambodia	",
    "Korea, Rep.	",
    "Kosovo	",
    "Kuwait	",
    "Lao PDR	",
    "Lebanon	",
    "Sri Lanka	",
    "Macao SAR, China	",
    "Maldives	",
    "Myanmar	",
    "Mongolia	",
    "Malaysia	",
    "Nepal	",
    "Oman	",
    "Pakistan	",
    "Philippines	",
    "Korea, Dem. People's Rep.	",
    "West Bank and Gaza	",
    "Qatar	",
    "Saudi Arabia	",
    "Singapore	",
    "Syrian Arab Republic	",
    "Thailand	",
    "Tajikistan	",
    "Turkmenistan	",
    "Timor-Leste	",
    "Turkey	",
    "Uzbekistan	",
    "Vietnam	",
    "Yemen, Rep.	",
    "Curacao	",
    "Channel Islands	",
    "Albania	",
    "Andorra	",
    "Austria	",
    "Belgium	",
    "Bulgaria	",
    "Bosnia and Herzegovina	",
    "Belarus	",
    "Switzerland	",
    "Czech Republic	",
    "Germany	",
    "Denmark	",
    "Spain	",
    "Estonia	",
    "Finland	",
    "France	",
    "Faroe Islands	",
    "United Kingdom	",
    "Gibraltar	",
    "Greece	",
    "Croatia	",
    "Hungary	",
    "Isle of Man	",
    "Ireland	",
    "Iceland	",
    "Italy	",
    "Liechtenstein	",
    "Lithuania	",
    "Luxembourg	",
    "Latvia	",
    "Monaco	",
    "Moldova	",
    "North Macedonia	",
    "Malta	",
    "Montenegro	",
    "Netherlands	",
    "Norway	",
    "Poland	",
    "Portugal	",
    "Romania	",
    "Russian Federation	",
    "San Marino	",
    "Serbia	",
    "Slovak Republic	",
    "Slovenia	",
    "Sweden	",
    "Ukraine	",
    "Aruba	",
    "Argentina	",
    "Antigua and Barbuda	",
    "Bahamas, The	",
    "Belize	",
    "Bolivia	",
    "Brazil	",
    "Barbados	",
    "Chile	",
    "Colombia	",
    "Costa Rica	",
    "Cuba	",
    "Cayman Islands	",
    "Dominica	",
    "Dominican Republic	",
    "Ecuador	",
    "Grenada	",
    "Guatemala	",
    "Guyana	",
    "Honduras	",
    "Haiti	",
    "Jamaica	",
    "St. Kitts and Nevis	",
    "St. Lucia	",
    "Mexico	",
    "Nicaragua	",
    "Panama	",
    "Peru	",
    "Puerto Rico	",
    "Paraguay	",
    "El Salvador	",
    "Suriname	",
    "Turks and Caicos Islands	",
    "Trinidad and Tobago	",
    "Uruguay	",
    "St. Vincent and the Grenadines	",
    "Venezuela, RB	",
    "British Virgin Islands	",
    "Virgin Islands (U.S.)	",
    "St. Martin (French part)	",
    "Bermuda	",
    "Canada	",
    "Greenland	",
    "United States	",
    "American Samoa	",
    "Australia	",
    "Fiji	",
    "Micronesia, Fed. Sts.	",
    "Guam	",
    "Kiribati	",
    "Marshall Islands	",
    "Northern Mariana Islands	",
    "New Caledonia	",
    "Nauru	",
    "New Zealand	",
    "Palau	",
    "Papua New Guinea	",
    "French Polynesia	",
    "Solomon Islands	",
    "Tonga	",
    "Tuvalu	",
    "Vanuatu	",
    "Samoa	",
    "Sint Maarten (Dutch part)",
];

// new add 
// const AboutJSonData = {
//     LOAD: "REQUEST_LANG_DATA",
//     LOAD_SUCCESS: "RECEIVE_LANG_DATA",
//   };

//   export default AboutJSonData;


export const AboutJSonData = [
    // add data
];
