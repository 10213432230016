import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
//import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as $ from 'jquery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import ExploreVisualiseModal from "./explore-visualise-modal";
const CodeListComponent = (props) => {
    const [allChecked,setAllChecked] = useState(false)
    const [selectedChecked,setSelectedCheked] = useState(false)
    const [dataChecked, setDataChecked] = useState(false);
    const [metaDataChecked,setMetaDataChecked] =  useState(false)
    const [dsdChecked,setDsdChecked] = useState(false)
    const [msdChecked,setMsdChecked] =useState(false)
    const [indicatorChecked,setIndicatorChecked] = useState(false)
    const [unitChecked,setUnitChecked] = useState(false)
    const [SubgroupChecked,setSubgroupChecked] = useState(false)
    const [subgroupDimensionChecked,setSubgroupDimensionChecked] = useState(false)
    const [areachecked,setAreaChecked] = useState(false)
    const [sourceChecked,setSourceChecked] = useState(false)
    const [timeperiodChecked,setTimeperiodChecked] = useState(false)

    const handleChange = (value) => {
        console.log('All')

        switch (value) {

            case 'All':
                console.log('All')
                setAllChecked(!allChecked);
                setSelectedCheked(false)
                setMetaDataChecked(true);
                setDsdChecked(true);
                setMsdChecked(true);
                setIndicatorChecked(true);
                setUnitChecked(true);
                setSubgroupChecked(true);
                setSubgroupDimensionChecked(true);
                setAreaChecked(true);
                setSourceChecked(true);
                setTimeperiodChecked(true);
                break;

                case 'Selected':
                setSelectedCheked(true);
                setMetaDataChecked(false);
                setDsdChecked(false);
                setMsdChecked(false);
                setIndicatorChecked(false);
                setUnitChecked(false);
                setSubgroupChecked(false);
                setSubgroupDimensionChecked(false);
                setAreaChecked(false);
                setSourceChecked(false);
                setTimeperiodChecked(false);
                setAllChecked(false);
                break;

            case 'data':
                setDataChecked(!dataChecked);
                break;

            case 'Metadata':
                setMetaDataChecked(!metaDataChecked);
                break;

            case 'dsd':
                setDsdChecked(!dsdChecked);
                break;

            case 'msd':
                setMsdChecked(!msdChecked);
                break;

                case 'Indicator':
                setIndicatorChecked(!indicatorChecked);
                break;

                case 'Unit':
                setUnitChecked(!unitChecked);
                break;

                case 'Subgroup':
                setSubgroupChecked(!SubgroupChecked);
                break;

                case 'SubgroupDimension':
                setSubgroupDimensionChecked(!subgroupDimensionChecked);
                break;

                case 'Area':
                setAreaChecked(!areachecked);
                break;

                case 'Source':
                setSourceChecked(!sourceChecked);
                break;

                case 'TimePeriod':
                setTimeperiodChecked(!timeperiodChecked);
                break;

            default:
                break;
        }

        
    };

    useEffect(()=>{
        props.func(dataChecked,
            allChecked,
            selectedChecked,
            metaDataChecked,
            dsdChecked,
            msdChecked,
            indicatorChecked,
            unitChecked,
            SubgroupChecked,
            subgroupDimensionChecked,
            areachecked,
            sourceChecked,
            timeperiodChecked)
    },[dataChecked,
        allChecked,
        selectedChecked,
        metaDataChecked,
        dsdChecked,
        msdChecked,
        indicatorChecked,
        unitChecked,
        SubgroupChecked,
        subgroupDimensionChecked,
        areachecked,
        sourceChecked,
        timeperiodChecked])

    const [age, setAge] = useState('');

  const handleAge = (event) => {
    setAge(event.target.value);
  };

  console.log('checked',dataChecked,
  metaDataChecked,
  dsdChecked,
  msdChecked,
  indicatorChecked,
  unitChecked,
  SubgroupChecked,
  subgroupDimensionChecked,
  areachecked,
  sourceChecked,
      timeperiodChecked)
  const [modal, setModal] = useState(false);
    const exploreVisualize = (e) => {
        $('#exploreVisualise').modal('show');
        // setModal(false)
    }

  useEffect(()=>{
    setAllChecked(false)
    setSelectedCheked(false)
    setDataChecked(false)
    setMetaDataChecked(false)
    setDsdChecked(false)
    setMsdChecked(false)
    setIndicatorChecked(false)
    setUnitChecked(false)
    setSubgroupChecked(false)
    setSubgroupDimensionChecked(false)
    setAreaChecked(false)
    setSourceChecked(false)
    setTimeperiodChecked(false)
  },props.uncheck)

  console.log('uncheck',props.uncheck)

    return (
        <>
        <div>
            <div className="row">
                <div className="col-md-4 border-select">
                    <div>
                        <FormGroup className="report-checkbox select-sdmx-checkbox">
                            <FormControlLabel control={<Checkbox checked={dataChecked} onChange={()=>handleChange('data')} />} label="data" />  
                            <FormControlLabel control={<Checkbox checked={metaDataChecked} onChange={()=>handleChange('Metadata')}/>} label="Metadata" />                          

                            <RadioGroup className="w-100"
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group">
                            </RadioGroup>

                            <FormControlLabel value="All" control={<Radio />} label="All" />

                            <FormControlLabel className="w-auto" value="Selected" control={<Radio checked={modal} onChange={(e) => exploreVisualize(e)} />} label="Selected" />   

                            <div className="select-icon" data-toggle="modal" data-target="#modal2">
                                <img src="../../assets/lib/images/information-icon.png"/>
                            </div>

                         
       

                            <FormControlLabel control={<Checkbox />} label="Use Mapping File" />     

                            <FormControl fullWidth>
                                <InputLabel className="select-age" id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleAge}
                                >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>                        
                        </FormGroup>
                    </div>

                </div>
                <div className="col-md-4 border-select">
                    <div>
                        <FormGroup className="report-checkbox">
                            <FormControlLabel control={<Checkbox checked={dsdChecked} onChange={()=>handleChange('dsd')} />} label="Data Structures Definition (DSD)" />
                            <FormControlLabel control={<Checkbox checked={msdChecked} onChange={()=>handleChange('msd')}/>} label="Metadata Structure Definition (MSD)" />                            
                        </FormGroup>                    
                    </div>
                </div>
                <div className="col-md-4">
                    <h6>Code List</h6>
                    <div>
                        <FormGroup className="report-checkbox">
                            <RadioGroup 
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group">
                                <FormControlLabel value="All" control={<Radio checked={allChecked} onChange={()=>handleChange('All')}/>} label="All" className="w-25" />
                                <FormControlLabel value="Selected" control={<Radio checked={selectedChecked} onChange={()=>handleChange('Selected')}/>} label="Selected" />                                
                            </RadioGroup>
                            <div className="code-list">
                                <FormControlLabel control={<Checkbox checked={indicatorChecked} onChange={()=>handleChange('Indicator')}/>} label="Indicator" />
                                <FormControlLabel control={<Checkbox checked={unitChecked} onChange={()=>handleChange('Unit')}/>} label="Unit" />                            
                                <FormControlLabel control={<Checkbox checked={SubgroupChecked} onChange={()=>handleChange('Subgroup')}/>} label="Subgroup" />                            
                                <FormControlLabel control={<Checkbox checked={subgroupDimensionChecked} onChange={()=>handleChange('SubgroupDimension')}/>} label="Subgroup Dimension" />                            
                                <FormControlLabel control={<Checkbox checked={areachecked} onChange={()=>handleChange('Area')}/>} label="Area" />                            
                                <FormControlLabel control={<Checkbox checked={sourceChecked} onChange={()=>handleChange('Source')}/>} label="Source" />                            
                                <FormControlLabel control={<Checkbox checked={timeperiodChecked} onChange={()=>handleChange('TimePeriod')}/>} label="Time Period" />   
                            </div>                         
                        </FormGroup>                    
                    </div>
                </div>
            </div>
        </div>


<div className="modal fade" id="modal2" tabIndex="-1" role="dialog">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">        
            <div class="modal-body p-4">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="assets/lib/images/cross.svg" alt="" />
                    </span>
                </button>
                <div className="row">
                    <div className="col-md-3">
                        <h5>IUS</h5>
                        <FormGroup className="report-checkbox">
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                        </FormGroup>
                    </div>
                    <div className="col-md-3">
                        <h5>Area</h5>
                        <FormGroup className="report-checkbox">
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                        </FormGroup>
                    </div>
                    <div className="col-md-3">
                        <h5>Time Period</h5>
                        <FormGroup className="report-checkbox">
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                        </FormGroup>
                    </div>
                    <div className="col-md-3">
                        <h5>Source</h5>
                        <FormGroup className="report-checkbox">
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                            <FormControlLabel control={<Checkbox />} label="Area" />      
                        </FormGroup>
                    </div>
                </div> 
                            
                        
            </div>       
        </div>
    </div>
</div>
<div class="modal fade exploreVisualise" id="exploreVisualise" tabindex="-1" aria-labelledby="DataLabel" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">                                
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt="" /></span>
                </button>
                <ExploreVisualiseModal />
            </div>                                   
        </div>
    </div>
</div>

        </>
    );

};

export default CodeListComponent;