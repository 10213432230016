import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { LatLng } from "leaflet";
// import GeoHash from '@jollie/geohash';
import { styled } from "@mui/material/styles";
import { pol, pol2, markers } from "./mapData";

import "leaflet/dist/leaflet.css";
import "./geo-data.css";
// import {cordData} from './SWZ_L03_2022'
import * as htmlToImage from "html-to-image";
import "leaflet-boundary-canvas";

import geoDataJSON from '../../../Config/geo-data.json'

import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation } from "react-router-dom";
import FaciliyModal from "../../../Common/components/modal/facility.modal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as $ from "jquery";
import eventBus from "../../../features/eventBus";
import { setAreaCode } from "../../../features/areaSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAreaName } from "../../../features/areaNameSlice";
import { setHeaderAreaCode, setHeaderAreaName, setHeaderDisasterCode, setHeaderDisasterName, setHeaderLevel } from "../../../features/formStateSlice";

function GeoData(props) {
  const headerLevel = useSelector((state) => state.formSlice.level)
  const location = useLocation();
  const route = location.pathname;
  const jsonFile = "assets/map/json/TLS_L02_2019.json";
  // const jsonFile2 = "assets/map/json/TLS_L03_2019.json";

  var exceptions = {
    Eswatini: [-8.93310440198275, 125.34997343166705],
    Eswatini: [-9.577847111130273, 124.36356441621412],
  };

  var southWest = L.latLng(-10.934071337478107, 122.49039867597014),
    northEast = L.latLng(-7.66263661277406, 128.34636052662762);
  var bounds = L.latLngBounds(southWest, northEast);
  const dispatch = useDispatch();
  // console.log(props?.mapData,"5000")


  // $(document).ready(function () {
  //   let ht = $('.wmt_chart').height()
  //   console.log('htheight', ht)
  //   $('.wmt_chart svg').attr('height', ht-20)
  //   $('.wmt_chart svg').css('margin-top', '50px')
  //   let width = $(window).width();


  //   if(width < 1950 && width > 1701) {
  //     map.options.minZoom = 8.49;
  //     map.options.setZoom = 8.49;
  //     map.options.zoom = 8.49;
  //   }
  //   else if (width < 1700 && width > 1490) {
  //     map.options.minZoom = 8.3;
  //     map.options.setZoom = 8.49;
  //     map.options.zoom = 8.49;
  //   }
  //   else if (width < 1400) {
  //     map.options.minZoom = 8;
  //     map.options.setZoom = 8;
  //     map.options.zoom = 8;
  //   }

  // })

  const [isLabel, setIsLabel] = useState(false);

  const [currentLevel, setCurrentLevel] = useState(1);
  const [legends, setLegendData] = useState([]);
  const [currentArea, setCurrentArea] = useState("ASITLS");
  const [colorArea, setColorArea] = useState([]);
  const [reset, setReset] = useState(false);
  const [mapObj, setMapObj] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCompass, setCompass] = useState(false);

  const [map, setMap] = useState(null);
  const [total, setTotal] = useState(0);
  const [dupTotal, setDupTotal] = useState();

  //  
  const seletedLangValue = useSelector((state) => state?.lang?.lang)
  const seletedDisaterNameTYpe = useSelector((state) => state?.areaName?.disaterName)


  dispatch(setAreaName(props?.areaUpdateName));

  // const addChildLayers = async (area) => {
  //   // console.log("vvv", area)

  //   if (area !== null) {
  //     setCurrentLevel(area?.level + 1);
  //     //  

  //     const response = await fetch(
  //       // `assets/map/json/TLS_L0${area?.level + 1}_2019.json`
  //       `assets/map/json/TLS_L0${ props.isLevel!==true?area?.level + 1:props?.currentLevelValue}_2019.json`
  //     );
  //     const mainGeoData = await response.json();
  //     //  
  //     //  
  //     // return
  //     const geoJSON = [];

  //     area?.children?.forEach((item) => {
  //       mainGeoData?.features?.forEach((geo) => {
  //         //  
  //         if (item.value == geo?.properties?.["ID_"]) {
  //           //  
  //           geoJSON.push(geo);
  //         }
  //       });
  //     });
  //     // return

  //     mainGeoData["features"] = geoJSON;


  //       //   

  //     fetchGeoJSON(mainGeoData);

  //     // if (area?.level > 1) {
  //       // map.setZoom(10)
  //       // map.fitBounds(mapObj.getBounds());
  //     // }
  //   } else {
  //     const response = await fetch(jsonFile);
  //     const mainGeoData = await response.json();

  //     const geoJSON = [];

  //     fetchGeoJSON(mainGeoData);

  //     //  map.setZoom(map.getZoom() + 0.4)
  //   }
  // };



  const addChildLayers = async (area, colorData) => {
    // dispatch(setLevel(data));


    if (area !== null) {
      setCurrentLevel(area?.level + 1);
      //  

      const response = await fetch(
        `assets/map/json/TLS_L0${headerLevel}_2019.json`

        // `assets/map/json/TLS_L0${area?.level > 4 ? 2 : area?.level + 1}_2019.json`

        // `assets/map/json/TLS_L0${area?.level + 1}_2019.json`
        // `assets/map/json/TLS_L0${ props?.currentLevelValue}_2019.json`
      );

      const responseLevel = await fetch(
        `assets/map/json/TLS_L0${headerLevel}_2019.json`
        // `assets/map/json/TLS_L0${ area?.level + 1}_2019.json`
      );
      const mainGeoData = await response.json();

      const levelGeoData = await responseLevel.json();
      //  
      //  
      // return

      const geoJSON = [];

      // if(props?.isLevel===false){



      props?.mapData?.forEach((item) => {

        if (props?.headerLevel > area?.level + 1) {

          levelGeoData?.features?.forEach((geo2) => {

            if (item.code == geo2?.properties?.["ID_"]) {
              geo2.properties["value"] = item?.value;
              geo2.properties["name1"] = item?.area;
              geoJSON.push(geo2);
              console.log(geo2,"mainGeoData13")
            }

          })




        } else {
          mainGeoData?.features?.forEach((geo) => {
            if (item.code == geo?.properties?.["ID_"]) {

              // geo?.properties?.['name1']= item?.area;
              // geo?.properties?.['value']= item?.value;
              geo.properties["value"] = item?.value;
              geo.properties["name1"] = item?.area;
              geoJSON.push(geo);
              // console.log(geo,"mainGeoData13")
            }


          });
        }

      });

      mainGeoData["features"] = geoJSON;
      // }


      // fetch next level data=================//


      // console.log('239',colorArea)
      fetchGeoJSON(mainGeoData, colorData);
      // console.log(props?.mapData,"mainGeoDatavvv")

      // if (area?.level > 1) {
      // map.setZoom(10)
      // map.fitBounds(mapObj.getBounds());
      // }
    } else {
      const response = await fetch(jsonFile);
      const mainGeoData = await response.json();

      const geoJSON = [];

      // console.log('253',colorArea)

      fetchGeoJSON(mainGeoData, colorData);

      //  map.setZoom(map.getZoom() + 0.4)
    }
  };


  const areaClick = async (area,forRestLevel = '') => {
    // console.log(forRestLevel,headerLevel,"30222")
    // console.log(area,"click id get")
    // props.areaData?.map((areaVal)=>{
    //   if(areaVal.parent_id == area){
    //     console.log(areaVal,"click id get")
    //   }
    // })

    
    // dispatch(setHeaderLevel(forRestLevel != '' ? forRestLevel : headerLevel))
    var testObj = {
      message: null,
      name: null,
      level: null,
      children: [],
    };

    // console.log('mohit',props.allAreaTree)
    // console.log(forRestLevel,headerLevel,"30222")

    props.allAreaTree?.map((item) => {
      if (item.value == area) {
        testObj["name"] = item.label;
        testObj["message"] = item.value;
        // testObj["level"] = item.level;
        testObj["level"] =forRestLevel != '' ? forRestLevel : headerLevel;

      }
    });

    props.allAreaTree?.map((item) => {
      if (item.parent_id == area) {
        let dtObj = {
          label: item.label,
          code: item.value,
          parent: item.parent,
          // level: item.level,
          level: forRestLevel != '' ? forRestLevel : headerLevel,

          value: 23,
        };
        testObj["children"].push(dtObj);
      }
    });
    console.log(testObj,forRestLevel,headerLevel,"30222")

    dispatch(setAreaCode(area));
    props.areaCallBack(testObj);
  };


  //  
  useEffect(() => {

    if (!map) return;
    // console.log('314 test', props.mapData, props.mapData != null, props.mapData.length > 1, props.mapData != undefined)
    if (props.mapData != null && props.mapData.length > 1 && props.mapData != undefined) {
      // console.log('314 test')
      setCurrentArea(props.areaCode);
      let valueData = [];
      let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];
      let areaObj = props.mapData?.filter((o) => {
        return o.code == props.areaCode?.message
      });

      setTotal(areaObj?.[0]);
      setDupTotal(areaObj?.[0]);
      // addChildLayers(props.areaCode);
      // return
      props.mapData?.filter((ele)=> ele?.source_lang?.en?.toLowerCase().includes(props?.sourceData?.toLowerCase()))?.forEach((item) => {
        if (item.code != props.areaCode?.message && item?.value !== "") {
          valueData.push(item.value);
        }
      });
      // return
      let s = new Set(valueData);

      let unicValData = [...s];


      unicValData.sort(function (a, b) {
        return a - b;
      });

      let unitVal = 1;

      var finalLegends = [];
      var collectionAreaData = [];
      if (unicValData?.length >= 4) {
        unitVal = Math.floor(unicValData?.length / 4);
      }
      let j = 0;
      let k = 0;
      let from = unicValData[0];
      let to = unicValData[0];
      for (let i = 0; i < unicValData.length; i++) {
        if (j++ >= unitVal && k < 3) {
          j = 0;

          let obj = {
            color: colors[k++],
            value: "" + from + " - " + to,
          };
          from = to + 1;

          finalLegends.push(obj);
        }

        let valMapData = props.mapData?.filter(
          (item) =>
            item.value == unicValData[i] && item.code != props.areaCode?.message
        );
        // eslint-disable-next-line no-loop-func
        valMapData?.filter((ele)=> ele?.source_lang?.en?.toLowerCase().includes(props?.sourceData?.toLowerCase())).forEach((item) => {
          console.log(item,"368")
          if (item?.value !== "") {
            let obj = {
              color: colors[k],
              value: item.value,
              area: item.area_all[seletedLangValue] ? item?.area_all[seletedLangValue] : item?.area_all?.en,
              code: item.code,
              source:item.source_lang
            };
            collectionAreaData.push(obj);
          }
        });
        to = unicValData[i];
      }

      let obj = {
        color: colors[k++],
        value: "" + from + " - " + to,
      };
      finalLegends.push(obj);

      setLegendData(finalLegends);
      //  
      props.rangeData(finalLegends);

      // eventBus.dispatch("triggerArea", { click: true, rangeArr: finalLegends });
      addChildLayers(props.areaCode, collectionAreaData);

      setColorArea(collectionAreaData);

    } else {
      setLegendData([]);
      setColorArea([]);
    }
    // addChildLayers(props.areaCode)
    setIsLabel(false)

    let width = $(window).width();
    if (width < 1950 && width > 1701) {
      map.options.minZoom = 8.5;
      map.options.setZoom = 8.5;
      map.options.zoom = 8.5;
    } else if (width < 1700 && width > 1490) {
      map.options.minZoom = 8.47;
      map.options.setZoom = 8.47;
      map.options.zoom = 8.47;
    } else if (width < 1400) {
      map.options.minZoom = 8.30;
      map.options.setZoom = 8.30;
      map.options.zoom = 8.30;
    }

    // console.log( map.options.minZoom," map.options.minZoom")
    console.log("when update")

  }, [props.areaData, props.areaCode, props.mapData, map, props.isLevel,headerLevel,props?.sourceData,props.currentLevel]);


  useEffect(() => {
    let ht = $('.wmt_chart').height()
    // console.log('htheight', ht)
    $('.wmt_chart svg').attr('height', ht - 20)
    $('.wmt_chart svg').css('margin-top', '50px')

    // console.log(ht,"height testinng")
  }, [])



  const mapZoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };
  const mapReset = async() => {
   await  dispatch(setHeaderLevel(2));
    dispatch(setHeaderAreaCode('ASITLS'))
    dispatch(setHeaderDisasterCode("D0001"))
    dispatch( setHeaderDisasterName('INUNDASAUN'))
    dispatch(setHeaderAreaName("Timor-Leste"))
    addChildLayers(null, null);
    map.fitBounds(mapObj.getBounds());
    setReset(false);
    areaClick('ASITLS',1)
  };

  const mapZoomIn = () => {
    map.setZoom(map.getZoom() + 1);
    // map.options.minZoom = map.getZoom() + 0.5;
    //  
  };

  const sourceData = () => {
    let d = []
    props.allData?.tableData?.map((element, index) => {
      if (element.source_lang != "") {
        console.log('sourcedata', d)
        d.push(element)
      }
    })
    return d

  }

  const center = [-8.657933501999876, 125.63539257200003];
  const position = [-26.55139426368066, 31.455374702908358];

  const fetchGeoJSON = async (geoJSON, colorData) => {
    // console.log('439',colorData)
    // showLabel()

    if (!map) {
      return;
    }
    map.eachLayer(function (layer) {
      if (!!layer.toGeoJSON) {
        map.removeLayer(layer);
      }
    });

    const mapObjc = new L.geoJSON(geoJSON, {
      style: countriesStyle,
      onEachFeature: onEachFeatures,
    }).addTo(map);

    function countriesStyle(feature) {
      return {
        fillColor: "#d2d2d2",
        fillOpacity: 1,
        color: "#fff",
        opacity: 1,
        weight: 1,
      };
    }

    function onEachFeatures(feature, layer) {
      // DO THIS FOR EACH COUNTRY
      // events
      // return
      // console.log(feature, layer,"mouse hover apii")

      colorData?.map((item) => {
        // console.log('484',item?.code , feature.properties.ID_)
        if (item?.code === feature.properties.ID_) {

          return layer.setStyle({
            fillColor: item.color,
          });
        }
      });


      // return

      layer.on({

        mouseover: function (e) {
          layer.setStyle({
            opacity: 0.1,
          });
          setTotal({
            // value: e.sourceTarget.feature.properties.hasOwnProperty('value') ? e.sourceTarget.feature.properties.value : "",
            value: e.sourceTarget.feature.properties.value,
            area: e.sourceTarget.feature.properties.hasOwnProperty('name1') ? e.sourceTarget.feature.properties.name1 : e.sourceTarget.feature.properties.NAME1_,
          });
        },
        mouseout: function () {
          layer.setStyle({
            opacity: 1,
          });
          setTotal(dupTotal);
        },
        click: function (e) {
          setTotal({
            // value: e.sourceTarget.feature.properties.hasOwnProperty('value') ? e.sourceTarget.feature.properties.value : "",
            value: e.sourceTarget.feature.properties.value,
            area: e.sourceTarget.feature.properties.hasOwnProperty('name1') ? e.sourceTarget.feature.properties.name1 : e.sourceTarget.feature.properties.NAME1_,
          });
          areaClick(feature.properties.ID_);
        },
      });

      // return

      var label = L.marker(
        exceptions[feature.properties.NAME1_] || layer.getBounds().getCenter(),
        {
          icon: L.divIcon({
            html: "<p >sddd" + feature.properties.NAME1_ + "</p>",
            iconSize: [0, 0],
          }),
        }
      ).addTo(map);

      label.bindTooltip(
        colorData?.length > - 0
          ? colorData
            ?.map((item) => {
              //  
              if (item?.code === feature.properties.ID_) {
                feature.properties.value = item.value;
                feature.properties.name1 = item.area;
                return (
                  "<div className='map_datavalues'>" +
                  // feature.properties.NAME1_.charAt(0).toUpperCase() +
                  // feature.properties.NAME1_.slice(1).toLowerCase() +
                  feature.properties.name1 +
                  " <br>" +
                  item?.value +
                  "</div>"
                );
              } else {
                return "";
              }
            })
            .join("")
          : "<div className='map_datavalues'>" +
          // feature.properties.NAME1_.charAt(0).toUpperCase() +
          // feature.properties.NAME1_.slice(1).toLowerCase() +
          feature.properties.NAME1_,
        {
          permanent: true,
          direction: "center",
          className: "mapchartlabel",
        }
      );
    }

    await map.fitBounds(mapObjc.getBounds());

    await setMapObj(mapObjc);

    // map.setView(new L.LatLng(-8.657933501999876, 125.63539257200003), 8);

    // if(geoJSON?.properties?.ID_ !== 'TLS'){
    //   center = geoJSON?.features?.[0]?.geometry?.coordinates[10];
    //  map.setView(new L.LatLng(center[1],center[1]), 8);

    // }

    // $(document).ready(function () {
    //   let width = $(window).width();

    //   //  
    //   if (width < 1950 && width > 1701) {
    //     map.options.minZoom = 8.5;
    //     map.options.setZoom = 8.5;
    //     map.options.zoom = 8.5;
    //   } else if (width < 1700 && width > 1490) {
    //     map.options.minZoom = 8.47;
    //     map.options.setZoom = 8.47;
    //     map.options.zoom = 8.47;
    //   } else if (width < 1400) {
    //     map.options.minZoom = 8.30;
    //     map.options.setZoom = 8.30;
    //     map.options.zoom = 8.30;
    //   }
    // });
  };

  const xyz = (e) => {
    //  
  };

  const showLabel = (e) => {
    if (isLabel) {
      setIsLabel(false);
      // $(".countryLabel").addClass("show");
      $(".leaflet-tooltip").removeClass("show1");
      // $(".img-label").attr("src", "assets/lib/images/home/label-off.svg");
    } else {
      setIsLabel(true);
      // $(".countryLabel").removeClass("show");
      $(".leaflet-tooltip").addClass("show1");
      // $(".img-label").attr("src", "assets/lib/images/home/label_on.svg");
    }
  };

  const downloadMap = () => {
    $("#mainDownload").css("background", "rgba(255, 255, 255)");
    $(".wmt_chart").css({ width: "1100px", height: "430px", marginTop: "-40px" });
    $(".legendsNumber").css({ position: "relative", bottom: "unset" });
    $("#mainDownload").css({
      width: "100%",
      height: "100%",
      padding: "10px",
    });
    $("#heading_info").css("color", "rgba(0, 0, 0)");
    $("#heading_info span.pipeMeta").css("color", "#ddd");
    $(".legendsNumber p, .legendsNumber ul li").css("color", "rgba(0, 0, 0)");
    $(".metaInfo").css("color", "rgba(0, 0, 0)");



    $(".legends, .filter, .content_arrow ").hide();
    $(".metaInfo").show();

    var node = document.getElementById("mainDownload");

    htmlToImage
      .toJpeg(document.getElementById("mainDownload"), {
        quality: 0.95,
        height: 650,
      })
      .then(function (dataUrl) {
        let dTime =
          new Date().toISOString().split("T")[0].replace(/-/g, "") +
          "_" +
          new Date().getHours() +
          "" +
          new Date().getMinutes() +
          "" +
          new Date().getSeconds();

        var a = document.createElement("a");
        a.download =
          "TERS_" +
          props.inddata.name +
          "_" +
          props.inddata.unit +
          "_" +
          props.allData.subGroupSelected.name +
          "_" +
          props.allData.selectedPeriod +
          "_" + "Map" + "_" +
          dTime +
          ".jpeg";
        a.href = dataUrl;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        $(".legends, .filter, .content_arrow ").show();
        $("#mainDownload").css("background", "transparent");
        $("#mainDownload").css({ width: "", height: "", padding: "" });
        $(".wmt_chart").css({ width: "", height: "" });
        $(".metaInfo").hide();
        $(".legendsNumber").css({ position: "absolute", bottom: "-24px" });
        $(".metaInfo p, .legendsNumber p, .legendsNumber ul li").css("color", "");
        setLoader(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <>
      {/* <div id="mainDownload" > */}
      <div className="mapData">
        {
          <h2>{total?.value === "" ? "-" : total?.value}</h2>
        }
        {/* {
        <h2>{total?.value}</h2>
       } */}
        <h4>{total?.area}</h4>
      </div>
      <div className="legends">
        <ul>
          <li
            data-toggle="modal"
            onClick={(e) => xyz(e)}
            data-target="#are_value_table"
          >
            <Tooltip title={geoDataJSON?.["table"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} >
                <img src="assets/lib/images/home/table.svg" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["zoomIn"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapZoomIn}>
                <img src="assets/lib/images/home/zoom-in.svg" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["zoomOut"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapZoomOut}>
                <img src="assets/lib/images/home/zoom-out.svg" />
              </a>
            </Tooltip>
          </li>
          <li className="wmtm_reset">
            <Tooltip title={geoDataJSON?.["reset"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapReset}>
                <img src="assets/lib/images/home/reset.svg" />
              </a>
            </Tooltip>
          </li>
          {/* <li className="hr_li position-relative"></li> */}
          <li>
            {
               isLabel ? <Tooltip title={geoDataJSON?.["labelOff"]?.[seletedLangValue]} placement="right"> 
               <a style={{ cursor: "pointer" }} onClick={(e) => showLabel(e)}>
                 <img
                //  src="assets/lib/images/home/label_on.svg"
                 src="assets/lib/images/home/label-off.svg" 
                 alt="onof"
                 className="img-label"
               />
               </a>
               </Tooltip>:<Tooltip title={geoDataJSON?.["labelOn"]?.[seletedLangValue]} placement="right"> 
                <a style={{ cursor: "pointer" }} onClick={(e) => showLabel(e)}>
               <img 
              //  src="assets/lib/images/home/label-off.svg" 
                src="assets/lib/images/home/label_on.svg"
               alt="on" 
               className="img-label"/>
                </a>
              </Tooltip>
            }
          </li>
          {/* <li ><span style={{cursor:'pointer'}} onClick={(e) => showLabel(e)}><img  src='assets/lib/images/home/label_on.svg' className='img-label'/></span> </li> */}
          <li>
            <Tooltip title={geoDataJSON?.["Download"]?.[seletedLangValue]} placement="right">
              <a to="" style={{ cursor: "pointer" }} onClick={downloadMap}>
                <img src="assets/lib/images/home/download.svg" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["northPole"]?.[seletedLangValue]} placement="right">
              <a
                to=""
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  isCompass == true ? setCompass(false) : setCompass(true);
                }}
              >
                <img src="assets/lib/images/home/north_pole.svg" />
              </a>
            </Tooltip>
          </li>
        </ul>
      </div>
      <div className="mapBox" id="mainDownload">
        <div
          className="metaInfo"
          style={{ display: "none", "font-size": "20px", color: "#ffffff" }}
        >
          <p id="heading_info">
            {seletedDisaterNameTYpe} <span className="pipeMeta">|</span> {props.inddata.name} <span> - {props.inddata.unit} <br /> {props.allData.subGroupSelected.name} <span className="pipeMeta">|</span> {props.allData.selectedPeriod}
            </span>
          </p>
        </div>

        <div className="wmt_chart">
          <MapContainer
            minZoom={20}
            maxBounds={bounds}
            center={center}
            maxZoom={20}
            whenCreated={setMap}
            scrollWheelZoom={false}
          ></MapContainer>
        </div>

        <div className="legendsNumber">
          <p> {props.inddata.unit}</p>
          <ul>
            {legends?.map((item, index) => {
              return (
                <>

                  {
                    item.value !== "undefined - undefined" ? <li key={index}>
                      <span className={"lnColor" + (index + 1)}></span>
                      {item.value}
                    </li> : ""
                  }

                </>
              );
            })}

            <li>
              <span className="lnColor5"></span>{geoDataJSON?.["No Data"]?.[seletedLangValue]}
            </li>
          </ul>
        </div>

        <div
          className="metaInfo"
          style={{ display: "none", "font-size": "12px", color: "#ffffff" }}
        >
          <p className="mb-2">Source: {sourceData()?.[0]?.source_lang?.pt && sourceData()?.[0]?.source_lang?.tet != undefined ? sourceData()?.[0]?.source_lang?.[props?.lang]: sourceData()?.[0]?.source_lang?.['en']}
          {/* {
          props.allData?.tableData?.map((data)=>{
            if(data.area == props?.areaCode?.name){
              if(data.source_lang != ""){
                if(data.source_lang.pt != undefined && data.source_lang.tet != undefined){
                  console.log('1111',data.source_lang[props?.lang])
                  return data.source_lang[props?.lang]
                }else{
                  console.log('1111',data.source_lang['en'])
                  return data.source_lang['en']
                }
              }else{
                console.log('1111','empty')
                return 'empty'
              }
            }
          })
          } */}
          </p> 
          <p>
          {/* Disclaimer */}
          {geoDataJSON['disclaimer'][seletedLangValue]}: {geoDataJSON['onDownDiscP'][props.lang]}</p>
        </div>

        {isCompass == true && (
          <span className="comapss">
            <img src="assets/map/compass.svg" align="right" />
          </span>
        )}
      </div>

      <div
        className={
          loader === true ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

      {/* </div> */}
    </>
  );
}

export default GeoData;
