import React, { useRef, useState } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import adminService from "../../../../services/admin.service";
import { toast } from "react-toastify";

const SenderModal = (props) => {
    console.log('senderfor',props)
    const [senderId,setSenderId] = useState('')
    const [senderName,setSenderName] = useState('')
    const [name,setName] = useState('')
    const [department,setDepartment] = useState('')
    const [role,setRole] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [fax,setFax] = useState('')
    const [loader,setLoader] = useState(false)
    const form = useRef()

    const handleSubmit =  () =>{
        // setLoader(true)
        let payload = {}

        if(senderId === '' && senderName === '' && name === '' && department === '' && role === '' && email === '' && phone === '' && fax=== '' ){
            toast.error('Please Fill All Fields')
            return
        }

        if(props.editData === '' || props.editData === undefined){
        payload.id =  null 
        payload.sender_contact_name = name 
        payload.sender_department =  department
        payload.sender_email = email
        payload.sender_fax = fax
        payload.sender_id = senderId
        payload.sender_name = senderName
        payload.sender_parent_name =  null
        payload.sender_parent_name_new =  null 
        payload.sender_role =  role 
        payload.sender_telephone =  phone
        }else{
        payload.id =  props.editData._id 
        payload.sender_contact_name = name === '' ? props.editData.contact_name : name 
        payload.sender_department = department === '' ?  props.editData.department : department
        payload.sender_email = email === '' ? props.editData.email : email
        payload.sender_fax = fax === '' ? props.editData.fax : fax
        payload.sender_id = senderId === '' ? props.editData.sender_id : senderId
        payload.sender_name = senderName === '' ? props.editData.sender_name : senderName
        payload.sender_parent_name = ''
        payload.sender_parent_name_new = ''
        payload.sender_role = role === '' ? props.editData.role : role 
        payload.sender_telephone = phone === '' ? props.editData.telephone : phone
        }

        adminService.httpPost(`/data-sdmx/sdmx/add-sender`,payload).then((res)=>{
            console.log(res)
            setSenderId('')
            setSenderName('')
            setName('')
            setDepartment('')
            setRole('')
            setEmail('')
            setPhone('')
            setFax('')
            setLoader(false)
            props.closeModal()
            props.getSenderReceiverData()
            toast.success(props?.senderForWhat === '' || props?.senderForWhat === undefined ? 'Added' : 'Updated')
        })
        .catch((err)=>{
            setLoader(false)
            setSenderId('')
            setSenderName('')
            setName('')
            setDepartment('')
            setRole('')
            setEmail('')
            setPhone('')
            setFax('')
            console.log(err)
            props.closeModal()
            toast.error(err.message)
        })
    }
    

   
    return (
        <>
         <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>
            <div className="area-form">
                <ValidatorForm className="main_react_form w-100" ref={form} >
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0">
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Sender ID</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? senderId : senderId === '' ? props.editData.sender_id : senderId}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setSenderId(e.target.value);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Sender Name</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdVersion"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? senderName : senderName === '' ? props.editData.sender_name : senderName}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setSenderName(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-tab-heading are_head sender-contact">
                                <h2 class="form-heading form-heading p-0">
                                    <span class="form-heading_he">
                                        <span class="ddf">Sender Contact Details</span>
                                    </span>
                                </h2>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Name</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="agencyID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? name : name === '' ? props.editData.contact_name : name}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setName(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Department</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="publisher"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? department : props.editData.department}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setDepartment(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Role</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? role : props.editData.role}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setRole(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Email</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? email : props.editData.email}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setEmail(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Phone</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                errorMessages={['This field is required']}    
                                                value={props.editData === undefined || props.editData === null ? phone : props.editData.telephone}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setPhone(e.target.value)}}                          
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">               
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Fax</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                errorMessages={['This field is required']}   
                                                value={props.editData === undefined || props.editData === null ? fax : props.editData.fax}
                                                onChange={(e)=> {console.log('ssss',e.target.value);setFax(e.target.value)}}                                             
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" type="submit" className="addbtn" onClick={handleSubmit}>
                                {props?.senderForWhat === '' || props?.senderForWhat === undefined ? 'Add' : props?.senderForWhat } <img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg"/>
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>
        </>
    );

};

export default SenderModal;