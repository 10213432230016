import React, { useRef, useState } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import adminService from "../../../../services/admin.service";
import { toast } from "react-toastify";

const ReceiverModal = (props) => {
    console.log('props',props)
    const [receiverId,setReceiverId] = useState('')
    const [receiverName,setReceiverName] = useState('')
    const [name,setName] = useState('')
    const [department,setDepartment] = useState('')
    const [role,setRole] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [fax,setFax] = useState('')
    const form = useRef()

    const handleSubmit = async () =>{

        if(receiverId === '' && receiverName === '' && name === '' && department === '' && role === '' && email === '' && phone === '' && fax=== '' ){
            toast.error('Please Fill All Fields')
            return
        }

        let payload = {}

        if(props.editData === '' || props.editData === undefined){
            payload.id = null
            payload.receiver_contact_name = name
            payload.receiver_department = department
            payload.receiver_email = email
            payload.receiver_fax = fax
            payload.receiver_id = receiverId
            payload.receiver_name = receiverName
            payload.receiver_parent_name = null
            payload.receiver_parent_name_new = null
            payload.receiver_role = role
            payload.receiver_telephone = phone
            }else{
            payload.id =  props.editData._id 
            payload.receiver_contact_name = name === '' ? props.editData.contact_name : name 
            payload.receiver_department = department === '' ?  props.editData.department : department
            payload.receiver_email = email === '' ? props.editData.email : email
            payload.receiver_fax = fax === '' ? props.editData.fax : fax
            payload.receiver_id = receiverId === '' ? props.editData.sender_id : receiverId
            payload.receiver_name = receiverName === '' ? props.editData.sender_name : receiverName
            payload.receiver_parent_name = ''
            payload.receiver_parent_name_new = ''
            payload.receiver_role = role === '' ? props.editData.role : role 
            payload.receiver_telephone = phone === '' ? props.editData.telephone : phone
            }



       
    
        adminService.httpPost(`/data-sdmx/sdmx/add-receiver`,payload).then((res)=>{
            console.log(res)
            setReceiverId('')
            setReceiverName('')
            setName('')
            setDepartment('')
            setRole('')
            setEmail('')
            setPhone('')
            setFax('')
            props.closeModal()
            props.getSenderReceiverData()
            toast.success(props?.receiverForWhat === '' || props?.receiverForWhat === undefined ? 'Added' : 'Updated')
        })
        .catch((err)=>{
            console.log(err)
            setReceiverId('')
            setReceiverName('')
            setName('')
            setDepartment('')
            setRole('')
            setEmail('')
            setPhone('')
            setFax('')
            props.closeModal()
            toast.error(err.message)
        })
    }

    return (
        <>
            <div className="area-form">
                <ValidatorForm className="main_react_form w-100" ref={form} >
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0">
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Receiver ID</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? receiverId : receiverId === '' ? props.editData.receiver_id : receiverId}
                                                onChange={(e)=> setReceiverId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Receiver Name</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="dsdVersion"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? receiverName : receiverName === '' ? props.editData.receiver_name : receiverName}
                                                onChange={(e)=> setReceiverName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-tab-heading are_head sender-contact">
                                <h2 class="form-heading form-heading p-0">
                                    <span class="form-heading_he">
                                        <span class="ddf">Receiver Contact Details</span>
                                    </span>
                                </h2>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Name</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="agencyID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? name : name === '' ? props.editData.contact_name : name}
                                                onChange={(e)=> setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Department</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="publisher"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? department : department === '' ? props.editData.department : department}
                                                onChange={(e)=> setDepartment(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Role</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdID"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? role : role === '' ? props.editData.role : role}
                                                onChange={(e)=> setRole(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Email</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? email : email === '' ? props.editData.email : email}
                                                onChange={(e)=> setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Phone</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? phone : phone === '' ? props.editData.telephone : phone}
                                                onChange={(e)=> setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="form_lable">Fax</label>
                                            </span>
                                            <TextValidator
                                                className="w-100"
                                                name="msdVersion"
                                                errorMessages={['This field is required']}
                                                value={props.editData === undefined || props.editData === null ? fax : fax === '' ? props.editData.fax : fax}
                                                onChange={(e)=> setFax(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" type="submit" className="addbtn" onClick={handleSubmit}>
                                {props?.receiverForWhat === '' || props?.receiverForWhat === undefined ? 'Add' : props?.receiverForWhat } <img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg" />
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>
        </>
    );

};

export default ReceiverModal;