import React, { useState } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import "../Layout/layout.css";
import "../../App.css";
import { logout } from "../../features/loginSlice";
import { useDispatch } from "react-redux";
import * as $ from "jquery";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import adminService from "../../services/admin.service";
import { toast } from "react-toastify";
import ChangePassword from "../Modals/changePassword.modal";
import { useLocation } from "react-router";

function Header() {

  const logClass = 'dropdown-menu log_out_specific logout_box open';

  const dispatch = useDispatch();
  const location = useLocation();
  const route = location.pathname;
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    // navigate("/login");
    navigate('/datamanager')
  };


  // ================================================================================

  const [state, setState] = useState({
    bottom: false,
    // new
    newPassword:'',
    confirmPassword:'',
    error: '',

  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDrawerClose = () => {
    setState(false);
  };

  const userInfo = localStorage.getItem("userInfo");
  var role = null;
  if (userInfo != null) {
    role = JSON.parse(userInfo);
  }

  // ================================ Password Change ==============================================

  

  // const handleChangePassword = (e) => {
  //   e.preventDefault();

  //   if (state.newPassword !== state.confirmPassword) {
  //     setState({ ...state, error: 'Passwords do not match' });
  //   } else if (state.newPassword.length <= 0) {
  //     setState({ ...state, error: 'Password is Required' });
  //   } else if (state.newPassword.length < 8) {
  //     setState({ ...state, error: 'Password should be at least 8 characters long' });
  //   } else if (!/[A-Z]/.test(state.newPassword)) {
  //     setState({ ...state, error: 'Password should contain at least one uppercase letter' });
  //   } else if (!/[!@#$%^&*]/.test(state.newPassword)) {
  //     setState({ ...state, error: 'Password should contain at least one special character (!@#$%^&*)' });
  //   }
  //   else {

  //     const data = {
  //       "new_password": state.newPassword,
  //       "confirm_password": state.confirmPassword
  //     }

  //     adminService.httpPost("/data-import/users/change-password", data).then((res)=>{
  //       if(res.success == true){
  //         toast.success("Password changed successfully!")
  //       } else{
  //         toast.error("Error occurred while changing the password")
  //       }
  //     })

  //     $("#change_password").modal("hide");
      
  //     setState({ ...state, newPassword: '',confirmPassword: '', error: '' });
  //   }
    
  // };

  // const handleInputChange = (e) => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };
  // ================================================================================
  return (
    <>
      <section className="admin_header">
        <div className="container-fluid h-100">
          <div className="row justify-content-between align-items-center h-100">
            <div className="col-md-4">
              <div className="adminLogo">
                <img
                  className="img-fluid"
                  src="assets/lib/images/new-home/tobago_logo.svg"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor} className="humburgerMenu">
                    <div
                      className="humburger"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img src="assets/lib/images/admin/humburger.svg" />
                    </div>
                    <SwipeableDrawer
                      className="hm_width "
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      <div className="show-menu">
                        <Button
                          variant="secondary"
                          className="hmClose hmbtnClose"
                          onClick={handleDrawerClose}
                        >
                          <img src="assets/lib/images/close.svg" />
                        </Button>
                        <Navbar expand="lg" className="hmMenu">
                          <Navbar.Collapse
                            id="basic-navbar-nav"
                            className="hmMenu navbar navbar-expand-lg navbar-light"
                          >
                            {/* <Nav className="me-auto">
                              <li><NavLink to='/home' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Home</NavLink></li>
                              <li><NavLink to='/information-kiosk' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Information Kiosk</NavLink></li>
                              <li><NavLink to='/about' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>About</NavLink></li>
                              <li><NavLink to='/dashboard/data-analysis' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Data Analysis</NavLink></li>
                              <li><NavLink to='/dashboard/data-search' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Data Search</NavLink></li>
                              <li><NavLink to='/contact' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Contact</NavLink></li>
                              
                            </Nav> */}
                            <div
                              class="navbar-collapse collapse"
                              id="basic-navbar-nav"
                            >
                              <ul className="me-auto navbar-nav">
                                <li>
                                  <Link to="/Home" className="nav-link">
                                    <span>Home</span>
                                    <p className="detail_para">
                                      Centralized SDG data hub of Trinidad and
                                      Tobago to measure, track and accelerate
                                      the 2030 SDGs agenda
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/information-kiosk"
                                    className="nav-link"
                                  >
                                    <span>Information Kiosk</span>
                                    <p className="detail_para">
                                      A valuable resource hub for promoting and
                                      advancing the United Nations' Sustainable
                                      Development Goals in Trinidad and Tobago
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about" className="nav-link">
                                    <span>About</span>
                                    <p className="detail_para">
                                      Working towards achieving the Sustainable
                                      Development Goals (SDGs) that addresses
                                      the major development challenges faced by
                                      people in Trinidad and Tobago
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/contact" className="nav-link">
                                    <span>Contact</span>
                                    <p className="detail_para">
                                      Connect with us to help achieve
                                      Sustainable Development Goals
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dashboard/data-analysis"
                                    className="nav-link"
                                  >
                                    <span>Data Analysis</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dashboard/data-search"
                                    className="nav-link"
                                  >
                                    <span>Search Data</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </Navbar.Collapse>
                        </Navbar>
                      </div>
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </>

              <Dropdown className="user_login">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src="assets/lib/images/admin/user_login.svg" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href='javascript:void(0);'><img src="assets/lib/images/icon/main_admin.svg" />View Profile</Dropdown.Item> */}
                  <Dropdown.Item
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#change_password"
                  >
                    <img src="assets/lib/images/icon/change_password.svg" />{" "}
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleLogout}
                    href="javascript:void(0);"
                  >
                    {" "}
                    <img src="assets/lib/images/icon/logout.svg" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </section>

        <ChangePassword />
      {/* <div
        className="modal fade"
        id="change_password"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src="assets/lib/images/cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  handleChangePassword(e);
                }}
              >
                <div className="form-group">
                  <div className="form-field">
                    <input
                      type="password"
                      name="newPassword"
                      value={state.newPassword}
                      className="form-control"
                      formcontrolname="password"
                      autoComplete="off"
                      placeholder="Enter New Password"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-field">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={state.confirmPassword}
                      className="form-control"
                      formcontrolname="password"
                      autoComplete="off"
                      placeholder="Re-type Password"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {state.error && <p style={{ color: "red" }}>{state.error}</p>}
                <div className="form-group">
                  <button type="submit">Change Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Header;
