import React from 'react'  
import './Footer.jsx'
import headerAndFooterJson from '../../Config/headerAndFooter.json'
import { useLocation } from 'react-router-dom'
import { store } from '../../store';
import { useSelector } from 'react-redux';
import {
  MDBFooter
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function Footer(){

 
  const currentYear = new Date().getFullYear(); // 2020

  const previousYear =  currentYear-1;
  const location = useLocation();
  const route = location.pathname;

  let progressState = store.getState();

let language = progressState?.lang?.lang;

let selectedLanguage = useSelector((state)=> state?.lang?.lang);

console.log("this is language change in footer--",selectedLanguage)
    return (
        <>

          <section className='footer_area'>
              <div className="container-fluid">
                   <div className="row align-items-center">
                       <div className="col-md-6 footer_left">
                         <p>{headerAndFooterJson?.copyright?.[selectedLanguage]} © {currentYear}. {headerAndFooterJson?.['all-rights-reserved']?.[selectedLanguage]}</p>
                       </div>
                       <div className="col-md-6 footer_right">
                        <p>{headerAndFooterJson?.poweredBy?.[selectedLanguage]} <span><a className='text-white' style={{'textDecoration':'none'}} href="http://www.itechmission.org/" target={'_blank'}><strong>iTech Mission</strong></a></span></p>
                       </div>
                   </div>
              </div>
          </section>

        
        {/* <MDBFooter className='bg-dark text-center text-white'>
          <div className="col-lg-12">
          <div className="row">
          <div className="col-lg-6 text-left">
          <div className='text-left pt-3 pr-3 pb-3 pl-5' style={{ 'backgroundColor': 'rgba(0, 0, 0, 0.2)', 'textAlign':'left','paddingLeft':'7rem'  }}>
          &copy; {new Date().getFullYear()} Copyright:{' '} All rights reserved

          </div>
          </div>

          <div className="col-lg-6 text-right">
          <div className='text-right p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', 'textAlign':'right' }}>

          <span><small>Developed by: </small><a className='text-white' style={{'textDecoration':'none'}} href="http://www.itechmission.org/" target={'_blank'}><strong>iTech Mission</strong></a></span>

          </div>
          </div>
          </div>
          </div>

        </MDBFooter> */}
        </>
    );
}


export default (Footer);