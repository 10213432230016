import { Component } from "react";
import "./data_approve.css";
import * as jsx from "./data_approve.module.jsx";
import { toast } from "react-toastify";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import AdminService from "../../../services/admin.service";
import Tooltip from "@mui/material/Tooltip";
class DataApprove extends Component {
    arr = ["dxgf"];

    constructor (props) {
        super(props);

        this.state = {
            data: [],
            filteredData: [],
            searchText: "",
            setScrollableModal: false,
            isEdit: false,
            scrollableModal: false,
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData: false,
            disscrollableModal: false,
            editModeId: null,
            modal: false,
            remarkdata: "",
            loader: false,
            pageNumber: 1,
            totalElements: 100,
            dtotalElements: 100,
            rowsPerPage: 100,
            dataLimit: 10,
            dataStatus: 2,
            bulkDelete: [],
            indicatorData: [],

            statusData: [
                { label: "Pending", value: 2 },
                { label: "Approved", value: 1 },
                { label: "Disapproved", value: 3 },
            ],
        };
    }

    sendStatus = (e) => {
        this.setState({ dataStatus: e });
        this.getData(e);
    };

    componentDidMount() {
        this.getData(this.state.dataStatus);
    }

    render() {
        return jsx.default.bind(this)();
    }

    // update data limit===================//

    updateDataLimit = (e) => {
        this.setState({ dataLimit: e.target.value });
        this.getData(this.state.dataStatus);
    };

    getDataByStatus = (e) => {
        const val = e.target.value;
        this.setState({ dataStatus: val });
        this.getData(e.target.value);
    };

    // filter table and get data====================//

    onSearch = (val, enterPressed = false) => {
        this.setState({ searchText: val });
        if (enterPressed) {
            let d = this.state.data;
            let fd = null;
            if (val === "") {
                fd = d;
                this.setState({
                    filteredData: fd,
                    totalElements: this.state.dtotalElements,
                });
            } else {
                this.getData(this.state.dataStatus, 1, val.toLowerCase());
            }
        }
        if (val?.length === 0) {
            this.getData(this.state.dataStatus, 1, val.toLowerCase());
        }
    };

    fnsortIUS = (rowA, rowB) => {
        const a = rowA?.disasterData?.[0]?.title?.en;
        const b = rowB?.indicator?.en;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    columns = [
        // {
        //     name: 'Disaster',
        //     selector: (row, index) => row.disasterCode === null ? 'None' :  row.disasterData?.[0]?.title?.en,
        //     sortable: true,
        //     sortFunction: this.fnsortIUS
        // },
        {
            name:<>
                    Indicator <span class="grey_pipe"> | </span>  Unit <span class="grey_pipe"> | </span> Subgroup
            </>,
            selector: (row, index) => (
                <Tooltip title={`${ row?.iusData?.[0]?.name } | ${ row.iusData?.[0]?.unit } | ${ row.iusData?.[0]?.subgroup?.name }`} placement="top" arrow>
                    <p className="mb-0 grey_text" >
                        <span>{ row?.iusData?.[0]?.name }</span> | <span className="text-mute padding_text">{ row.iusData?.[0]?.unit }</span> | <span className="text-mute padding_text">{ row.iusData?.[0]?.subgroup?.name }</span>
                    </p>
                </Tooltip>
            ),
            sortable: true,
            sortFunction: this.fnsortIUS,
        },

        {
            name: "Area",
            selector: (row) => row.areaData?.[0]?.name,
            sortable: true,
            width: "170px",
        },
        {
            name: "Time Period",
            selector: (row) => row.time_period?.start_time_period,
            sortable: true,
            width: "140px",
        },
        {
            name: "Data Value",
            selector: (row) => row.new_value,
            sortable: true,
            width: "100px",
        },
        {
            name: "Source",
            selector: (row) => (
                <Tooltip title={ row.source?.publisher } placement="top">
                    <p>{ row.source.publisher }</p>
                </Tooltip>
            ),
            sortable: true,
        },

        {
            name: "Action",
            width: "150px",
            cell: (row) => (
                <>
                    { this.state.dataStatus === 1 && (
                        <>
                            {/* <Dropdown className="table-action">
                <Dropdown.Toggle id="dropdown-basic">Select</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => this.deldata(row._id, row.value)}
                    href="javascript:;"
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}

                            <div className='action_btns'>
                                <button onClick={ (e) => this.deldata(row._id, row.value) }> <img src="assets/lib/images/icon/delete_icon.svg" /></button>
                            </div>
                        </>
                    ) }

                    { this.state.dataStatus === 2 && (
                        <>
                            {/* <Dropdown className="table-action">
                <Dropdown.Toggle id="dropdown-basic">Select</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => this.approveData(row._id, row.value)}
                    href="javascript:;"
                  >
                    Approve
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => this.cancelModal(row._id)}
                    href="javascript:;"
                  >
                    Disapprove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                            <div className='action_btns data-approve-btn'>
                                <button onClick={ (e) => this.approveData(row._id, row.value) }> <img src="assets/lib/images/new-home/approve-1.svg" /></button>
                                <button onClick={ (e) => this.cancelModal(row._id) }> <img src="assets/lib/images/new-home/disapprove-1.svg" /></button>
                            </div>
                        </>
                    ) }

                    { this.state.dataStatus !== 1 && this.state.dataStatus !== 2 && (
                        <>
                            {/* <Dropdown className="table-action">
                <Dropdown.Toggle id="dropdown-basic">Select</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => this.openViewModal(row.remark)}
                    href="javascript:;"
                  >
                    View
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                            <div className='action_btns'>
                                <button onClick={ (e) => this.openViewModal(row.remark) }> <img class="cursor-pointer" src="assets/lib/images/eyes-view.svg" /></button>
                            </div>
                        </>
                    ) }
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    openViewModal = async (e) => {
        swal({
            title: "Reason of Disapproval",
            // text: e,
            text: e === "" || e === null ? "No data" : e,
            className: "disapproval_modal",
        }); 
    };

    cancelModal = async (e) => {
        if (this.state.disaaprovest === false) {
            this.setState({ disaaprovest: true });
            this.setState({ remarkdata: e });
        } else {
            this.setState({ disaaprovest: false });
        }
    };

    publishAll = (e) => {
        if (this.state.data === "") {
            toast.error("No record found to approve");
        } else {
            if (this.state.publishModal === false) {
                this.setState({ publishModal: true });
                this.setState({ remarkdata: e });
            } else {
                this.setState({ publishModal: false });
            }
        }
    };

    deleteApproved = (e, value) => {
        if (this.state.deleteApprovedData === false) {
            this.setState({ deleteApprovedData: true });
            this.setState({ remarkdata: e });
        } else {
            this.setState({ deleteApprovedData: false });
        }
    };

    setPage = (page) => {
        this.getData(this.state.dataStatus, page);
    };

    getData = async (
        e,
        page = this.state.pageNumber,
        keyword = this.state.searchText
    ) => {
        if (keyword !== "" && keyword.length < 3) {
            this.setState({ searchText: keyword });
            return;
        }
        this.setState({ loader: true, searchText: keyword, pageNumber: page });
        // this.setState({ loader: true, searchText: "", pageNumber: page });
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        let userid = savedItem.id;
        const offset = (page - 1) * this.state.rowsPerPage;
        const dataToBeSend = {
            size: this.state.rowsPerPage,
            totalElements: 0,
            totalPages: 0,
            pageNumber: 0,
            filterKeyWord: keyword,
            dataStatus: "inactive",
            userId: userid,
            startOffset: offset,
            endOffset: 0,
            status: e === "" || e === null || e === undefined ? 2 : e,
        };

        console.log("get-approve-datadatatosend", e, dataToBeSend);

        AdminService.httpPost("/data-retrieval/data/get-approve-data", dataToBeSend)
            .then((res) => {
                if (res) {
                    let d = res.data;
                    let t = res.totalElements;

                    d.forEach((ele, index) => {
                        ele.index = index;
                    });
                    if (keyword !== "") {
                        this.setState({ filteredData: d, loader: false, totalElements: t });
                    } else {
                        this.setState({
                            filteredData: d,
                            data: d,
                            loader: false,
                            totalElements: t,
                            dtotalElements: t,
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
            });
    };

    //// all approve dataa api //////
    approveAllData = (e, value) => {
        this.setState({ loader: false });
        swal({
            title: "Are you sure you want to approve all data?",
            // text: "Once Approved, you will not be able to disapprove this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const dataToBeSend = {
                    value: value,
                    status: 1,
                };

                AdminService.httpPost("/data-import/data/publish-all", dataToBeSend)
                    .then((res) => {
                        if (res) {
                            this.setState({ loader: false });
                            if (res.status === true) {
                                toast.success("All data approved successfully");
                                // this.openAddModal();
                                this.getData(this.state.dataStatus);
                            } else {
                                toast.error("Failed to approve data");
                            }
                        }
                    })
                    .catch((err) => {
                        this.setState({ loader: false });
                    });
            }
        });
    };

    approveData = (e, id1) => {
        this.setState({ loader: false });
        swal({
            title: "Are you sure you want to approve this data?",
            // text: "Once Approved, you will not be able to disapprove this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const dataToBeSend = {
                    id: id1,
                    status: 1,
                };

                AdminService.httpPut("/data-import/data/approvedata/" + e, dataToBeSend)
                    .then((res) => {
                        if (res) {
                            this.setState({ loader: false });
                            let d = res.data;
                            this.setState({ filteredData: d, data: d });
                            this.getData(this.state.dataStatus);
                            this.getData();
                            if (res.success === true) {
                                toast.success("Data approved successfully");
                                // this.openAddModal();
                                // this.getData();
                            } else {
                                toast.error("Failed to approve data");
                            }
                        }
                    })
                    .catch((err) => {
                        this.setState({ loader: false });
                    });
            }
        });
    };

    publishAllData = (e) => {
        this.setState({ loader: true });

        AdminService.httpPost("/data-import/data/publish-all", "")
            .then((res) => {
                if (res) {
                    this.setState({ loader: false });
                    let d = res.data;
                    this.setState({ filteredData: d, data: d });
                    this.getData(this.state.dataStatus);
                    if (res.status === true) {
                        toast.success("All data approved successfully");
                    } else {
                        toast.error("Failed to approve data");
                    }
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
            });
    };

    disapproveData = (e, value) => {
        this.setState({ loader: true });
        const dataToBeSend = {
            data_id: e,
            remark: value,
            status: 3,
        };

        AdminService.httpPost(
            "/data-import/data/reject-row-data-approve",
            dataToBeSend
        )
            .then((res) => {
                if (res) {
                    this.setState({ loader: false });
                    let d = res.data;
                    this.setState({ filteredData: d, data: d });
                    this.getData(this.state.dataStatus);
                    if (res.status === 1) {
                        toast.success("Data disapproved successfully");
                        this.getData();
                    } else {
                        toast.error("Failed to disapprove data");
                    }
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
            });
    };

    enableDis = (e, value) => {
        if (value === 0) {
            value = 1;
        } else {
            value = 0;
        }

        const dataToBeSend = {
            show: value,
        };

        AdminService.httpPut("/data-import/data/approvedata/" + e, dataToBeSend)
            .then((res) => {
                if (res) {
                    this.setState({ loader: false });
                    this.getData(this.state.dataStatus);

                    if (res.message === "Status updated successfully") {
                        toast.success("Status updated successfully");
                    } else {
                        toast.error("Failed to update status ");
                    }
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
            });
    };

    deldata = (e, value) => {
        this.setState({ loader: false });
        swal({
            title: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const dataToBeSend = {
                    data_id: e,
                };

                AdminService.httpPost(
                    "/data-import/data/del-data-approved",
                    dataToBeSend
                )
                    .then((res) => {
                        if (res) {
                            this.setState({ loader: false });
                            let d = res.data;
                            this.setState({ filteredData: d, data: d });
                            this.getData(this.state.dataStatus);

                            if (res.message === "Data deleted") {
                                toast.success("Data deleted successfully");
                            } else {
                                toast.error("Failed to delete data");
                            }
                        }
                    })
                    .catch((err) => {
                        this.setState({ loader: false });
                    });
            }
        });
    };

    // open add modal=====================//

    openAddModal = () => {
        if (this.state.modal === false) {
            this.setState({ modal: true });
        } else {
            this.setState({ modal: false });
            this.setState({ isEdit: false });
            this.setState({ editModeId: null });
        }
    };

    ///multiple delete

    handleChange = ({ selectedRows }) => {
        this.setState({ bulkDelete: selectedRows });
    };

    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared });
    };

    deleteAllData = (e, value) => {
        this.setState({ loader: false });
        swal({
            title: "Are you sure you want to Delete all data?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                AdminService.httpPost("/data-import/data/deleteAll")
                    .then((res) => {
                        if (res) {
                            this.setState({ loader: false });
                            if (res.status === 1) {
                                toast.success("All data delete successfully");
                                this.getData(this.state.dataStatus === "approve");
                            } else {
                                toast.error("Failed to delete data");
                            }
                        }
                    })
                    .catch((err) => {
                        this.setState({ loader: false });
                    });
            }
        });
    };

    //////////=========checkbox delete============/////////

    deleteBulkData = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach((item) => {
            ids.push(item._id);
        });

        if (ids?.length > 0) {
            swal({
                title: "Are you sure you want to delete? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    const data = {
                        _id: ids.toString(),
                    };
                    AdminService.httpPost("/data-import/data/delete_multiple_data", data)
                        .then((res) => {
                            if (res) {
                                this.setState({ loader: false });

                                if (res.success === 1) {
                                    toast.success("Data deleted successfully");
                                    this.getData(this.state.dataStatus);
                                } else {
                                    toast.error("Failed to delete data");
                                }
                            }
                        })
                        .catch((err) => {
                            this.setState({ loader: false });
                        });
                }
            });
        } else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,
            });

            return false;
        }
    };
}

export default DataApprove;
