
import React, { useEffect, useRef, useState } from "react";
import './modal.css'

import "react-toastify/dist/ReactToastify.css";
import { isEmptyObject } from "jquery";
import $ from 'jquery';

import { omit } from 'lodash'
import Select from 'react-select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';




import Autocomplete from '@mui/material/Autocomplete';

function AddDepartmentModal(props) {
  const form = useRef()
  // const [error, setError] = useState('');

  const [state, setState] = useState({
    "departmentName": '',
  });
  const [dataID, setDataID] = useState(null);

  const [dataState, setDataState] = useState(null)
  const [departmentID, setDepartmentID] = useState('');

  const [depNameRetain, setDepNameRetain] = useState(false);

  const [editCountNodalIUS, setEditCountNodalIUS] = useState();
  const [editCountViewIUS, setEditCountViewIUS] = useState();

  useEffect(() => {

if(props.modal){
  setState({
    "departmentName": '',
  })
  setDataState({"viewIUS":[], "nodalIUS":[]})
}

    if(props.cross){
      setDepNameRetain(false)
    }

    if (props.isEdit) {
      console.log("rger", props?.editMode)
      // Set the state for the first modal
      setState({
        departmentName: props.editMode?.department_name
      });
      setDataID(props.editMode?._id)
      // setNodalIndicator(getValueIUS())
      // setConcernIndicator(getValueViewIUS())
      // setNodalIndicator((depNameRetain == false && props.isEdit == false )&& checkEditNodalIUS())
      // setConcernIndicator((depNameRetain == false && props.isEdit == false )&& checkEditViewIUS())
    }
      else if(depNameRetain == false && props.isEdit == false ){
        setState({
          "departmentName": '',
        })
      

      }

   

    else{
      setDataID(null)
    
      setDataState({"viewIUS":[], "nodalIUS":[]})
      
    }
    
    generateDepartmentNumber();
  }, [props.cross , props.editMode, props.isEdit, props.modal ]);

  // useEffect(()=>{
  //   if (props?.isEdit == false){
  //     setNodalIndicator(null)
  //     setConcernIndicator(null)
  //   }
  // }, [props.isEdit])



  useEffect(()=>{
console.log('inmodal',  props.viewIUS)

setDataState({"viewIUS":props.viewIUS, "nodalIUS":props.nodalIUS})
  }, [props.viewIUS, props.nodalIUS])


  const checkEditNodalIUS = ()=>{


      let data = props?.indicatorData?.filter((x)=> x.iu_id == props?.editMode?.nodalius?.map((n)=> n?.ius_id))

      console.log("this data represents-",data?.map((item)=> item?.name));
      return data?.map((item)=> item?.name).join(", ");


    // setNodalIndicator(p)
  }
  const checkEditViewIUS = ()=>{
    let data = props?.indicatorData?.filter((x)=> x.iu_id == props?.editMode?.viewius?.map((n)=> n?.ius_id))

    console.log("this data represents-",data?.map((item)=> item?.name));
    return data?.map((item)=> item?.name).join(", ");
    // setConcernIndicator(p)
  }

  // ====================================================== Form Validation Start

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: dataID,
      department_code: props.editMode?.department_code,
      department_name: state?.departmentName,
      nodalius: props.nodalIUS,
      viewius: props.viewIUS,
    };
    console.log("this submitted data-",data)
    props.parentCallback(data);


  }


  const handleChange = (e) => {
    const value = e.target.value;
    setState({ departmentName: value });
  };

  // ====================================================== Form Validation End



  // form submit function end============//



  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
    setDepNameRetain(false)
  }

  const generateDepartmentNumber = () => {


    // Below is the latest Department ID we have in our data table of department.
    let latestDepartmentNumber = props.departmentData[0]?.department_code ? props.departmentData[0]?.department_code.slice(3) : '000';

    // Convert the latest department number to a number
    let number = parseInt(latestDepartmentNumber, 10);

    // Increment the number
    number++;

    // Format the number with leading zeros (e.g., 001, 002, etc.)
    let formattedNumber = number.toString().padStart(3, '0');

    // Set the formatted department number to the respective state
    setDepartmentID(formattedNumber);
  };


  // const handleChangeNodalIUS = (e) => {
  //   const value = e.target.value;
  //   setNodalIndicator(value)
  // }
  // const handleChangeViewIUS = (e) => {
  //   const value = e.target.value;
  //   setConcernIndicator(value)
  // }

  const getValueIUS = () => {
  console.log("lets check the data coming-", props?.nodalIUS);
  let data = props?.indicatorData?.filter((x) => props?.nodalIUS?._id?.includes(x?.iu_id));
  console.log("data?.name", data);

  if (data?.length === 1) {
    return data[0]?.name;
  } else if (data?.length > 1) {
    return `${data[0]?.name} +${data?.length - 1}`;
  } else {
    return "";
  }
};

const getValueViewIUS = () => {
  let data = props?.indicatorData?.filter((x) => props?.viewIUS?._id?.includes(x?.iu_id));
  console.log("data?.name", data);

  if (data?.length === 1) {
    return data[0]?.name;
  } else if (data?.length > 1) {
    return `${data[0]?.name} +${data?.length - 1}`;
  } else {
    return "";
  }
};

const nodalIUS=(e)=>{
  props.isNodalIUS(e)
}

  return (
    <>
      <div className="user-form">
        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit} id="addUserForm">
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            {/* <form onSubmit={(e)=>updateData(e)} noValidate="" style={{ 'position': 'relative' }} id="addUserForm" className="ng-untouched ng-pristine ng-invalid" > */}
            <div className="gallery-collections mt-0 metadata_form">

              <div className="row mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Department Name</label>
                      </span>
                      <TextValidator name="department_name" className="w-100"
                        onChange={(e) => { handleChange(e) }} value={state?.departmentName}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-30">

                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Department ID</label>
                      </span>
                      <TextValidator autoComplete="off" name="department_code" className="w-100" id="department_code" value={props.isEdit == false ? `DEP${departmentID}` : props.editMode?.department_code}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        disabled
                      />

                    </div>
                  </div>
                </div>
              </div>

              <div className="subgroup_indicator subgrp_area ">

                <div className="row mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <div className="mat-form-field-wrapper">
                        <span className="mat-form-field-label-wrapper">
                          <label>Nodal Indicator</label>
                        </span>
                  
                 
                        <span>{dataState?.nodalIUS?.[0]?.name}</span>
                        {/* <TextValidator name="nodalius" className="w-100"
                        type="hidden"
                          value={dataState?.nodalIUS?.[0]?.name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        /> */}
                       
                        <div onClick={()=>{nodalIUS(true); setDepNameRetain(true)}} className="input-values" data-toggle="modal" data-target="#assignmodal">
                        <p className="input_content">{dataState?.nodalIUS?.length}</p>
                          <p className="input_arrow"><img src="assets/lib/images/icon/btn_arrow.svg" /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <div className="mat-form-field-wrapper">
                        <span className="mat-form-field-label-wrapper">
                          <label>Concern Indicator</label>
                        </span>
                  
                        <span>{dataState?.viewIUS?.[0]?.name}</span>
                       
                        {/* <TextValidator name="viewius" className="w-100"
                        type="hidden"
                     
                          value={dataState?.viewIUS?.[0]?.name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                        */}
                        <div onClick={()=>{nodalIUS(false); setDepNameRetain(true)}} className="input-values" data-toggle="modal" data-target="#assignmodal">
                        <p className="input_content">{dataState?.viewIUS?.length}</p>
                          <p className="input_arrow"><img src="assets/lib/images/icon/btn_arrow.svg" /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? 'Update ' : 'Add '}<img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg" />
                </Button>
              </div>
            </div>

            {/* <div className="gallery-sidebar-button text-center new_gallery-sidebar-button">
                <button type="submit"  className="btn btn-danger mdsubmitBtn" > {props.isEdit? 'Update': 'Add' }</button>
            
                
                  

                  
                </div> */}
            {/* </form>  */}
          </Stack>
        </ValidatorForm>

      </div>
    </>
  );

}
export default AddDepartmentModal;
