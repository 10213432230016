import React, { useEffect, useRef, useState } from "react";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import { isEmptyObject } from "jquery";
import $ from "jquery";
import { omit } from "lodash";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "@mui/material/Autocomplete";
import { MenuItem, Select } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";


function AddSchemeModal(props) {
  const form = useRef();
  const [error, setError] = useState("");
  const[departmentList,setDepartmentList] =useState([])
  const [dipartmentName,setDipartmentName]=useState([])
  const [shemeName,setShemeName]=useState("")
  const [shemeBudget,setShemeBudget]=useState("")
  const [overView,setOverView]=useState("")
  const [areaListData,setAreaListData] =useState({
    areaList: [],
  })
  const[id,setId]=useState("")
  const [callData, setParentAreaData] = useState([]);
  const [areaExpand, setAreaExpanded] = useState([]);
  const [areaChecked, setAreaChecked] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let userRole = userInfo?.role;
  let userArea = userInfo?.area;

  console.log(props,"changingvalue12")

  const getAreaList = (e) => {
    setAreaChecked(e);
    console.log("eeeeeee",e)
    var dataArr = [];
    e?.forEach((item) => {
      props.areaTree?.forEach((dt) => {
        if (dt.area_code == item) {
          dataArr.push( dt.area_code );
        }
      });
    });
    setAreaListData({ ...areaListData, areaList: dataArr });
  };

  useEffect(() => {

    if (props.isEdit) {

      let areaCodes = [];
      var dataArr = [];
      console.log("props.sdasda", props.editMode)
      // props.editMode?.area_name?.forEach((item) => {
      //   props.areaTree?.forEach((dt) => {
      //     if (dt.area_code == item[0].area_code) {
      //       dataArr.push({ code: dt.area_code, name: dt.name });
      //       areaCodes.push(dt.area_code);
      //     }
      //   });
      // });

      var dataArr = [];
    props?.editMode?.area?.forEach((item) => {
      console.log("itememte", item)
      props.areaTree?.forEach((dt) => {
        if (dt.area_code == item.area_code) {
          dataArr.push( dt.area_code );
        }
      });
    });
    

      setDipartmentName(props?.editMode?.departmentCode)
      setShemeName(props?.editMode?.name)
      setShemeBudget(props?.editMode?.budget)
      setOverView(props?.editMode?.overview)
      setAreaListData({ ...areaListData, areaList: dataArr });
      setAreaChecked(dataArr)
      setId(props?.editMode?._id)

    } else {

      setDipartmentName([])
      setShemeName("")
      setShemeBudget("")
      setOverView("")
      setAreaListData({
        areaListData:dataArr
      })
      setId("")
      setAreaChecked([]);
      setAreaExpanded([]);
    }

    let departmentarrayData = [];
    props?.departmentdata?.map(item => {
      const objData={
        value:item?.department_code,
        key:item?.department_name
      }
      departmentarrayData.push(objData)
    })
    setDepartmentList(departmentarrayData)


    if (
      props.areaTree != "" &&
      props.areaTree != null &&
      props.areaTree.length > 0
    ) {
      let d = props.areaTree;
      let flatLevel = [];
      let filter1 = props.areaTree.filter(
        (a) =>
          a.level == 1 ||
          userArea.includes(a.area_code) ||
          a.areaSubData?.filter(
            (b) =>
              userArea.includes(b.area_code) ||
              b.areaSubData?.filter(
                (c) =>
                  userArea.includes(c.area_code) ||
                  c.areaSubData?.filter((d) => userArea.includes(d.area_code))
                    .length > 0
              )
          ).length > 0
      );
      if (userRole == 7) {
        filter1?.forEach((item) => {
          var obj = {
            value: item.area_code,
            label: item.name,
            parent: item.parent_id,
            level: item.level,
          };
          flatLevel.push(obj);
        });

        const data = setLevelData(flatLevel);

        setParentAreaData(data);
      } else {
        d?.forEach((item) => {
          var obj = {
            value: item.area_code,
            label: item.name,
            parent: item.parent_id,
            level: item.level,
          };
          flatLevel.push(obj);
        });

        const data = setLevelData(flatLevel);
        console.log("flatLevel00", flatLevel)
        setParentAreaData(data);
      }
    }

  }, [props]);


  const setLevelData = (flat) => {
    var roots = [];
    var all = {};
    flat.forEach(function (item) {
      all[item.value] = item;
    });

    Object.keys(all).forEach(function (value) {
      var item = all[value];
      if (item.parent == null || item.parent === "") {
        roots.push(item);
      } else if (item.parent in all) {
        var p = all[item.parent];
        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });
    return roots;
  };

  


  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setDipartmentName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log(dipartmentName.join(",").toString(),"splitArray")

};

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id:id,
      schemeName: shemeName,
      schemeBudget: + shemeBudget,
      schemeDepartment:dipartmentName.join(",").toString(),
      schemeOverview:overView,
      schemeStatus:1,
      schemeIndicator:[],
      schemeArea:areaListData?.areaList.join(",").toString(),
      
    };
    console.log(areaListData?.areaList,"214List")
    props.parentCallback(data);
  };

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  };

  return (
    <>
      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={handleSubmit}
          id="addUserForm"
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            <div className="gallery-collections mt-0 metadata_form">
              <div className="row mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Department Name</label>
                      </span>
                      {/* <Autocomplete
                        disablePortal
                        id="dipartmentname"
                        defaultValue={dipartmentName}
                        options={props?.departmentdata.map((option)=> option?.department_code)}
                        onChange={(e,newValue) =>  setDipartmentName(newValue) }
                        value={error ? "" : dipartmentName}
                        renderInput={(params) => <TextField {...params}
                        // onChange={(e) =>  setDipartmentName(e.target.value) }
                        // value={error ? "" : dipartmentName}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        />}
                      />  */}
                      <Select
                       id="profileType"
                       name='profileType'
                       multiple
                       value={dipartmentName}
                       className='profilratee-select'
                       onChange={(e) => { handleMultiSelectChange(e) }}
                       >
                      {
                        departmentList?.map((departmentList)=>{
                          return(
                            <MenuItem
                              key={departmentList?.key}
                              value={departmentList?.value}
                              className="multiselectCheckbox"
                              >
                              {departmentList?.key}
                          </MenuItem>
                          )
                        })
                      }
                      </Select>

                    </div>
                  </div>
                </div>
              </div>

              <div className="subgroup_indicator subgrp_area">
                <div className="row mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <div className="mat-form-field-wrapper">
                        <span className="mat-form-field-label-wrapper">
                          <label>Scheme Name</label>
                        </span>
                        <TextValidator
                          name="shemeName"
                          className="w-100"
                          onChange={(e) =>  setShemeName(e.target.value) }
                          value={error ? "" : shemeName}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <div className="mat-form-field-wrapper">
                        <span className="mat-form-field-label-wrapper">
                          <label>Scheme Budget</label>
                        </span>
                        <TextValidator
                          name="shemeBudget"
                          className="w-100"
                          type="number"
                          onChange={(e) =>  setShemeBudget(e.target.value) }
                          value={error ? "" : shemeBudget}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style descriptionInput">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Overview</label>
                      </span>
                      <TextValidator
                        autoComplete="off"
                        name="overView"
                        multiline
                        rows={3}
                        className="w-100"
                        onChange={(e) =>  setOverView(e.target.value) }
                        id="overView"
                        value={error ? "" :overView}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Area</label>
                      </span>
                      {/* <Autocomplete
                        disablePortal
                        id="area"
                        options={optionsFilms.map((option)=> option.label)}
                        onChange={(e,newValue) =>  setArea(newValue) }
                        value={error ? "" : area}
                        defaultValue={dipartmentName}
                        renderInput={(params) => <TextField {...params}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        />}
                      /> */}


                      <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic" className="area-dropdown">
                          {areaListData?.areaList?.length > 0 &&
                            areaListData?.areaList.slice(0, 2).map((d, i) => {
                              return (
                                <>
                                  <span className="badge">{d.name} </span>
                                </>
                              );
                            })}
                          {areaListData?.areaList?.length > 2 ? (
                            <>
                              <span>{areaListData?.areaList?.length - 1}+</span>
                            </>
                          ) : (
                            ""
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="treeviewCheckbox">
                          <CheckboxTree
                            nodes={callData}
                            checkModel="all"
                            checked={areaChecked}
                            expanded={areaExpand}
                            onCheck={getAreaList}
                            onExpand={setAreaExpanded}
                          />
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? "Update " : "Add "}
                  <img
                    className="btn_arrow"
                    src="assets/lib/images/icon/btn_arrow.svg"
                    alt="btn-arrow"
                  />
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
}
export default AddSchemeModal;
