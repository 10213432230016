import React from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';



const dataapproveTemplate = function () {

  const  openCloseModal = async () => {
            this.setState({scrollableModal:false})
    }

    

    const  closeModalClose = async () => {
            this.setState({disaaprovest:false})
    }

    const  closeModalClosePublis = async () => {
          this.setState({publishModal:false}) 
  }

  const  closeDeleteModal = async () => {
        this.setState({deleteApprovedData:false})
}


console.log('filteredData',this.state.filteredData)

  return (
    <>

      

      <div className="main dataApprove" id="main">
        <div className='container-fluid areaCont dataApporve'>
          <section className='areaSection data_approve' id="areaSection">


        
          <div className='col-lg-12 col-md-12'>
            <div className="row header-div new-h-d pt-0 pl-0">
              
              <div className="col-md-12 pl-2">
              <div class="">    

                
                <subHeaderComponentMemo.default  parentCallBack={this.sendStatus} 
                                                 parentCallBackApprove={this.approveAllData} 
                                                 statusFilter = {this.state.statusData} 
                                                 onSearch={this.onSearch} 
                                                 PropDeleteBulk={this.deleteBulkData}
                                                 searchText={this.state.searchText} 
                                                 parentCallBackDelete ={this.deleteAllData}
                                                 value='41' 
                                                 checkData = {this.state.bulkDelete}
                                                 approveData = {this.state.filteredData}
                                                 nRows={this.state.filteredData?.length}
                                                  dataStatus={this.state.dataStatus}
                                                 /> {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}
         
                </div>
              </div>
            </div>
          </div>

            
              <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[100]}
              paginationServer
              paginationTotalRows={this.state.totalElements}
              paginationPerPage={this.state.rowsPerPage}
              paginationComponentOptions={{
                noRowsPerPage: false
              }}
              onChangePage={page => this.setPage(page)}
              style={{ display: "flex" }}
              selectableRows={this.state.dataStatus===1 ? true : false}
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              persistTableHead
              className='da_tables dataTableScroll'
              noDataComponent="No Data"
              fixedHeader
            />
          </section>
        </div>
      </div>






      

        <Modal show={this.state.scrollableModal} onHide={openCloseModal} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Reason of Disapproval</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.remarkdata}</Modal.Body>
        </Modal>

        <Modal centered show={this.state.disaaprovest} onHide={closeModalClose} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Reason of Disapproval</Modal.Title>
          </Modal.Header>
          <Modal.Body><textarea id="reasoncancel" placeholder='Enter Remarks:' className='form-control remarkText' /></Modal.Body>
          <Modal.Footer>              
              <button class="btn close_btn" onClick={(e)=>closeModalClose()}>Cancel</button>
              <button class="btn yes_btn" onClick={(e)=>{this.disapproveData(this.state.remarkdata,document.getElementById("reasoncancel").value); closeModalClose();}} >OK</button>
          </Modal.Footer>        
        </Modal>

        <Modal show={this.state.publishModal} onHide={closeModalClosePublis}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm to approve all data?</Modal.Title>
          </Modal.Header>
          
          <Modal.Footer className='justify-content-center'>
              <button onClick={(e)=>{this.publishAllData(); closeModalClosePublis();}} >Yes</button>
              <button onClick={(e)=>closeModalClosePublis()}>No</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.deleteApprovedData} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete?</Modal.Title>
          </Modal.Header>
          
          <Modal.Footer>
              <button onClick={(e)=>{this.deldata(this.state.remarkdata);closeDeleteModal();}}  >Yes</button>
              <button onClick={(e)=>closeDeleteModal()} >No</button>
          </Modal.Footer>       
        </Modal>
      







      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} alt=''/>
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>

    </>
  );
};

export default dataapproveTemplate;
