import React, { Component } from "react";
import "./data-analytics.css";
import * as jsx from "./data-analytics.module.jsx";
import * as constants from "../../../Config/app.constants";
import adminService from "../../../services/admin.service";
import eventBus from "../../../features/eventBus";
import "react-toastify/dist/ReactToastify.css";
import domtoimage from "dom-to-image";
import { connect } from "react-redux";
import * as $ from "jquery";
import types from "react-cropper";
import {setGlovalLevel} from "../../../features/formStateSlice"
import disasterProfileJSON from "../../../Config/disasterProfile.json";

class DataanalyticsPage extends Component {



 




}


export default connect()(DataanalyticsPage);


