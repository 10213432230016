import React from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';

const auditTrailTemplate = function () {

  return (
    <>
      <div className="main dataApprove audit_main" id="main">
        <div className='container-fluid areaCont dataApporve'>
          <section className='areaSection data_approve audit_trail_area' id="areaSection">



            <div className='col-lg-12 col-md-12'>
              <div className="row header-div new-h-d pt-0 pl-0">

                <div className="col-md-12 pl-2">
                  
                  <div className="d-flex audit_top_box">
                    <div className="grf_list">
                      <div className="form-group mb-0 inputform_style user_drop_btn">
                        <div className="mat-form-field-wrapper">
                          <span className="mat-form-field-label-wrapper">
                            <label>User Name</label>
                          </span>
                          <div className="MuiInput-root">

                          <ReactMultiSelectCheckboxes
                            options={
                              this.state.defOptions
                                ? [
                                  { label: "All", value: "*" },
                                  ...this.state.defOptions,
                                ]
                                : []
                            }
                            required
                            placeholderButtonLabel="Select Users"
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            value={this.state.selectedUsers}
                            id="selectUser"
                            hideSelectedOptions={false}
                            onChange={this.onChange}
                          />
                          </div>
                        </div>
                      </div>

                      {/* <div className="audit_inputs">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              value={this.state.fromDt}
                              id="dateFrom"
                              maxDate={moment().toDate()}
                              onChange={this.handleDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div> */}
                      <div className="form-group mb-0 inputform_style">
                        <div className="mat-form-field-wrapper">
                          <span className="mat-form-field-label-wrapper">
                            <label>Start Date</label>
                          </span>
                          <div className="MuiInput-root">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              value={this.state.fromDt}
                              id="dateFrom"
                              maxDate={moment().toDate()}
                              onChange={this.handleDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Select start date",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      {/* <div className="audit_inputs">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              id="dateTo"
                              required
                              value={this.state.fromTo}
                              maxDate={moment().toDate()}
                              onChange={this.handleDate1}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div> */}
                      <div className="form-group mb-0 inputform_style">
                        <div className="mat-form-field-wrapper">
                          <span className="mat-form-field-label-wrapper">
                            <label>End Date</label>
                          </span>
                          <div className="MuiInput-root">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              id="dateTo"
                              required
                              value={this.state.fromTo}
                              maxDate={moment().toDate()}
                              minDate={this.state.fromDt == ''? 0:this.state.fromDt}
                              disabled={this.state.fromDt == ''}
                              onChange={this.handleDate1}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Select end date",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                          </div>
                        </div>
                      </div>

                      <div className="grfBtn audit_search align-items-end">
                        <Button
                          variant="contained"
                          className="apply"
                          // disabled={this.state.disabledApply}
                          onClick={(e) => {
                            this.getData();
                          }}
                          
                        >
                          <img src="assets/lib/images/manage-form/search.svg"/> Search</Button>
                        {/* <Button
                          variant="contained"
                          className="apply"
                          disabled={this.state.disabledApply}
                          onClick={(e) => {
                            this.getData();
                          }}
                        >
                          Apply
                        </Button> */}

                        {/* <Export
                          disabledDownload={this.state.disabledDownload}
                          onExport={() => this.downloadCSVFromJson()}
                        /> */}
                      </div>
                    </div>
                  </div>
                  {this.state.data?.length > 0 &&
                  (
                    <div class="">


                      <subHeaderComponentMemo.default parentCallBack={this.sendStatus}
                        //  parentCallBackApprove={this.approveAllData} 
                        //  statusFilter = {this.state.statusData} 
                        onSearch={this.onSearch}
                        //  PropDeleteBulk={this.deleteBulkData}
                        searchText={this.state.searchText}
                        //  parentCallBackDelete ={this.deleteAllData}
                        // value='50'
                        //  checkData = {this.state.bulkDelete}
                        //  approveData = {this.state.filteredData}
                        nRows={this.state.filteredData?.length}
                        dataStatus={1}
                        download={this.downloadCSVFromJson}
                      />
                      
                    </div>
                  )
                  }

                </div>
              </div>
            </div>

            {this.state.data?.length > 0 &&
                  (

            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="650px"
              pagination
              paginationRowsPerPageOptions={[100]}
              paginationServer
              paginationTotalRows={this.state.totalElements}
              paginationPerPage={this.state.rowsPerPage}
              paginationComponentOptions={{
                noRowsPerPage: false
              }}
              onChangePage={page => this.setPage(page)}
              style={{ display: "flex" }}
              selectableRows={false}
              // onSelectedRowsChange={this.handleChange}
              // clearSelectedRows={this.handleChangeData}
              persistTableHead
              className='da_tables dataTableScroll'
              noDataComponent="No Data"
              fixedHeader
              responsive={true}
            />
                  )}
          </section>
        </div>
      </div>

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} alt='' />
      </div>

      <div className={this.state.modal ? 'overlay dblock' : 'overlay'} ></div>

    </>
  );
};

export default auditTrailTemplate;
