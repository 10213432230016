import React, { Component } from 'react';
import LanguageTab from './tabs';
import ExportwithoutData from '../../../Common/components/export/ExportWithoutData'
import ExportwithData from '../../../Common/components/export/ExportWithData'
import ImportData from '../../../Common/components/import/importData';
var languageTemplate = function() {
  
        var changevalue;

  return (
    <>
    <div className='main'>
      <LanguageTab getBackIndex={this.getIndex}/>  
    </div>

    <div className="modal fade export_popups " id="export" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Export</h5>
                    <button type="button"  className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="assets/lib/images/cross.svg" alt="" />
                    </span>
                    </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-md-12">
                              <div className="img_area text-center mt-4">
                                    {/* <img src="assets/lib/images/export.svg"/> */}
                                    
                                    <svg width="120" height="96" viewBox="0 0 188 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M177.757 85.7574C178.883 84.6321 180.409 84 182 84C183.591 84 185.117 84.6321 186.243 85.7574C187.368 86.8826 188 88.4087 188 90V150H0V90C0 88.4087 0.63214 86.8826 1.75736 85.7574C2.88258 84.6321 4.4087 84 6 84C7.5913 84 9.11742 84.6321 10.2426 85.7574C11.3679 86.8826 12 88.4087 12 90V136C12 136.53 12.2107 137.039 12.5858 137.414C12.9609 137.789 13.4696 138 14 138H174C174.53 138 175.039 137.789 175.414 137.414C175.789 137.039 176 136.53 176 136V90C176 88.4087 176.632 86.8826 177.757 85.7574Z" fill="#2A2B6A"/>
                                    <path className="jumping_arrow" d="M141.537 78.3592C141.227 79.0895 140.772 79.7496 140.2 80.3L98.2002 122.2C97.6585 122.769 97.0069 123.222 96.2849 123.531C95.563 123.84 94.7857 124 94.0002 124C93.2147 124 92.4374 123.84 91.7155 123.531C90.9935 123.222 90.3419 122.769 89.8002 122.2L47.8002 80.3C47.2286 79.7496 46.7738 79.0895 46.4632 78.3592C46.1527 77.629 45.9926 76.8436 45.9926 76.05C45.9926 75.2564 46.1527 74.471 46.4632 73.7408C46.7738 73.0105 47.2286 72.3504 47.8002 71.8C48.3419 71.2312 48.9935 70.7784 49.7155 70.469C50.4374 70.1596 51.2147 70 52.0002 70C52.7857 70 53.563 70.1596 54.2849 70.469C55.0069 70.7784 55.6585 71.2312 56.2002 71.8L88.0002 103.5L88.0002 6C88.0002 4.40871 88.6323 2.88258 89.7575 1.75736C90.8828 0.632145 92.4089 4.33608e-06 94.0002 4.19696e-06C95.5915 4.05784e-06 97.1176 0.632144 98.2428 1.75736C99.368 2.88258 100 4.4087 100 6L100 103.5L131.8 71.8C132.342 71.2312 132.993 70.7784 133.715 70.469C134.437 70.1595 135.215 70 136 70C136.786 70 137.563 70.1595 138.285 70.469C139.007 70.7784 139.658 71.2312 140.2 71.8C140.772 72.3504 141.227 73.0105 141.537 73.7408C141.848 74.471 142.008 75.2564 142.008 76.05C142.008 76.8436 141.848 77.629 141.537 78.3592Z" fill="#2A2B6A"/>
                                    </svg>
                                   
                                    {this.state.valueData=='0' &&
                                            <p>Export Classification Translate Template</p>
                                    }
                                    {this.state.valueData=='1' &&
                                            <p>Export Area Translate Template</p>
                                    }

                                   {this.state.valueData=='2' &&
                                            <p>Export Indicator Translate Template</p>
                                    }

                                        {this.state.valueData=='3' &&
                                            <p>Export Metadata Translate Template</p>
                                    }

                                   {this.state.valueData=='4' &&
                                            <p>Export Subgroup Translate Template</p>
                                    }

                                        {this.state.valueData=='5' &&
                                            <p>Export Unit Translate Template</p>
                                    }

                                   {this.state.valueData=='6' &&
                                            <p>Export Source Translate Template</p>
                                    }

                                        {this.state.valueData=='7' &&
                                            <p>Export Resource Translate Template</p>
                                    }
                                     {this.state.valueData=='8' &&
                                            <p>Export Disaster Translate Template</p>
                                    }

                                   {this.state.valueData=='9' &&
                                            <p>Export Option Translate Template</p>
                                    }

                                        {this.state.valueData=='10' &&
                                            <p>Export Section Translate Template</p>
                                    }
                                     {this.state.valueData=='11' &&
                                            <p>Export Question Translate Template</p>
                                    }
                                     {this.state.valueData=='12' &&
                                            <p>Export Form Translate Template</p>
                                    }
                                     {this.state.valueData=='13' &&
                                            <p>Export Sub-section Translate Template</p>
                                    }
                                    
                              </div>
                              <div className='d-inline-flex align-item-center w-100'>
                                    <div  className="button_box_3 text-center mb-40">
                                        {/* <button  className="btn btn_browse w-25 popup_btn" id="download_icius_master_template">Empty</button> */}
                                        <ExportwithoutData value={this.state.valueData+10} />
                                        {/* <button  className="btn btn_upload w-25 popup_btn" id="download_icius_master_template_with_data">With Data</button> */}
                                        <ExportwithData value={this.state.valueData+10} />
                                    </div>
                            </div>

                        </div>
                    </div>
                    </div>
                    </div>
                    </div>





      <div className="modal fade export_popups" id="import" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Import</h5>
                  <button type="button" id="importClose" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">   <img src="assets/lib/images/cross.svg" alt="" /></span>
                  </button>
              </div>
              <div className="modal-body">
                  <div className="col-md-12">
                          <div className="img_area text-center mt-4">
                                  {/* <img src="assets/lib/images/import.svg"/> */}
                                  
                                      <svg width="120" height="96" viewBox="0 0 188 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M177.757 85.7574C178.883 84.6321 180.409 84 182 84C183.591 84 185.117 84.6321 186.243 85.7574C187.368 86.8826 188 88.4087 188 90V150H0V90C0 88.4087 0.63214 86.8826 1.75736 85.7574C2.88258 84.6321 4.4087 84 6 84C7.5913 84 9.11742 84.6321 10.2426 85.7574C11.3679 86.8826 12 88.4087 12 90V136C12 136.53 12.2107 137.039 12.5858 137.414C12.9609 137.789 13.4696 138 14 138H174C174.53 138 175.039 137.789 175.414 137.414C175.789 137.039 176 136.53 176 136V90C176 88.4087 176.632 86.8826 177.757 85.7574Z" fill="#2A2B6A"/>
                                      <path className="jumping_arrow" d="M141.537 45.6408C141.227 44.9105 140.772 44.2504 140.2 43.7L98.2002 1.8C97.6585 1.2312 97.0069 0.77837 96.2849 0.46896C95.563 0.159549 94.7857 -4.12829e-06 94.0002 -4.19696e-06C93.2147 -4.26563e-06 92.4374 0.159549 91.7155 0.468959C90.9935 0.778369 90.3419 1.2312 89.8002 1.8L47.8002 43.7C47.2286 44.2504 46.7738 44.9105 46.4632 45.6408C46.1527 46.371 45.9926 47.1564 45.9926 47.95C45.9926 48.7436 46.1527 49.529 46.4632 50.2592C46.7738 50.9895 47.2286 51.6496 47.8002 52.2C48.3419 52.7688 48.9935 53.2216 49.7155 53.531C50.4374 53.8404 51.2147 54 52.0002 54C52.7857 54 53.563 53.8404 54.2849 53.531C55.0069 53.2216 55.6585 52.7688 56.2002 52.2L88.0002 20.5L88.0002 118C88.0002 119.591 88.6323 121.117 89.7575 122.243C90.8828 123.368 92.4089 124 94.0002 124C95.5915 124 97.1176 123.368 98.2428 122.243C99.368 121.117 100 119.591 100 118L100 20.5L131.8 52.2C132.342 52.7688 132.993 53.2216 133.715 53.531C134.437 53.8405 135.215 54 136 54C136.786 54 137.563 53.8405 138.285 53.531C139.007 53.2216 139.658 52.7688 140.2 52.2C140.772 51.6496 141.227 50.9895 141.537 50.2592C141.848 49.529 142.008 48.7436 142.008 47.95C142.008 47.1564 141.848 46.371 141.537 45.6408Z" fill="#2A2B6A"/>
                                      </svg>

                                  {console.log("this.state.valueData-----",this.state.valueData)}
                                     {this.state.valueData=='0' &&
                                            <p>Import Classification Translate Template</p>
                                    }
                                       {this.state.valueData=='1' &&
                                            
                                            <p>Import Area Translate Template</p>
                                            
                                    }
                                       {this.state.valueData=='2' &&
                                            <p>Import Indicator Translate Template</p>
                                    }
                                       {this.state.valueData=='3' &&
                                            <p>Import Metadata Translate Template</p>
                                    }
                                       {this.state.valueData=='4' &&
                                            <p>Import Subgroup Translate Template</p>
                                    }
                                       {this.state.valueData=='5' &&
                                            <p>Import Unit Translate Template</p>
                                    }
                                       {this.state.valueData=='6' &&
                                            <p>Import Source Translate Template</p>
                                    }
                                       {this.state.valueData=='7' &&
                                            <p>Import Resource Translate Template</p>
                                    }
                                    {this.state.valueData=='8' &&
                                            <p>Import Disaster Translate Template</p>
                                    }
                                    
                                       {this.state.valueData=='9' &&
                                            <p>Import Option Translate Template</p>
                                    }
                                    {this.state.valueData=='10' &&
                                            <p>Import Section Translate Template</p>
                                    }
                                    {this.state.valueData=='11' &&
                                            <p>Import Question Translate Template</p>
                                    }
                                    {this.state.valueData=='12' &&
                                            <p>Import Form Translate Template</p>
                                    }
                                    {this.state.valueData=='13' &&
                                            <p>Import Sub-section Translate Template</p>
                                    }
                          </div>
              
                      {/* <div  className="button_box_3 text-center mt-5 mb-40 d-flex justify-content-center">
                              <input type="file" name="file-input" id="file-input" className="file-input__input"/>
                              <label className="file-input__label popup_btn" for="file-input"><span> Browse File </span></label>
                              <button  className="btn btn_upload w-25 disable_bg mx-0" id="download_icius_master_template_with_data">Upload</button>
                          </div> */}
                          <ImportData value={this.state.valueData+10} />

                  </div>
              </div>
          </div>
      </div>
  </div>
  
  
</>
  );
   

};


export default languageTemplate;
