import { Component } from 'react'
import './contact.css';
import * as jsx from './contact.module.jsx';

import * as constants from '../../../Config/app.constants';

import "react-toastify/dist/ReactToastify.css";
import adminService from '../../../services/admin.service';

import { connect } from "react-redux";
import { toast } from 'react-toastify';

class ContactPage extends Component {
    constructor() {
        super()
        this.state = {
            value:0,
            loader:false,
            firstName: '',
            lastName: '',
            contactNumber: '',
            email: '',
            message: '',
        }
    }

    
    render() {
        return jsx.default.bind(this)();
    }
    
}

function mapStateToProps(state) {
    console.log('mapping state to props')
    return {
      lang: state.lang?.lang
    }
 }


export default connect(
   mapStateToProps
  )(ContactPage);