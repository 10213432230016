import React, { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const SourceAddModal = (props) => {
  const form = useRef();

  const datasourceRef = useRef();

  const [datasource, setDataSource] = useState("");
  const [dataID, setDataID] = useState(null);

  useEffect(() => {});

  useEffect(() => {
    if (props.isEdit == true) {
      getEditData(props.editData[0]);
    } else {
      setDataSource("");
      setDataID(null);
      return false;
    }
  }, [props.isEdit, props.editData]);

  const getEditData = (data) => {
    if (data != null) {
      setDataID(data["_id"]);

      setDataSource(data["datasource"]);
    }
  };

  const onDataSubmit = (e) => {
    e.preventDefault();

    let dataToSend = [];

    dataToSend = {
      id: dataID,
      data_source: datasource,
    };
    props.newDataEntry(dataToSend);
  };

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  };

  const handleChangeDataSource = (evenet) => {
    setDataSource(evenet.target.value);
  };

  return (
    <>
      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={(e) => {
            onDataSubmit(e);
          }}
          id="addUserForm"
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            <div className="gallery-collections mt-0">
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                    <span className="mat-form-field-label-wrapper">
                                        <label>Data Source</label>
                                    </span>
                      <TextValidator
                        name="datasource"
                        title="datasource"
                        className="w-100"
                        onChange={(e) => {
                          handleChangeDataSource(e);
                        }}
                        value={datasource}
                        ref={datasourceRef}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? "Update" : "Add"}<img className='btn_arrow' src="assets/lib/images/icon/btn_arrow.svg"/>
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
};

export default SourceAddModal;
