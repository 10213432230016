import React from "react";
export default function MetaDataindiactorGoal (props) {
    const goalTargetData = props?.data?.subgroup;
    
    return(
        <>
            <div className="modal fade export_popups indicatormetadata" id='indicatormetadata' tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button"  className="close position-relative" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <img src="assets/lib/images/cross.svg" alt="" />
                        </span>
                    </button>
                    <div className="modal-body">
                        <ul>
                            <li>
                                <h4>Goal</h4>
                                <p>{props.data.goal}</p>     
                            </li>
                            <li>
                                <h4>Target</h4>
                                <p>{props.data.target}</p>     
                            </li>
                            <li>
                                <h4>Sector</h4>
                                <p>{props.data.sector}</p>     
                            </li>
                            <li>
                                <h4>Sub-sector</h4>
                                <p>{props.data.subsector}</p>     
                            </li>
                        </ul>                                    
                    </div>              
                </div>
              </div>
            </div>
        </>
    );
}