import React, { useState, useRef, useEffect } from "react";
import { Link } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
// import * as htmlToImage from "html-to-image";
// import Radio from "@mui/material/Radio";
// import { CSVLink } from "react-csv";
// import * as $ from "jquery";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
// import { Dayjs } from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import ReactEcharts from "echarts-for-react";
// import Slider from "@mui/material/Slider";
// import { Label } from "@mui/icons-material";
// import FixedHeaderStory from "react-data-table-component";

// import Accordion from "react-bootstrap/Accordion";
// import MapView from "../mapview/mapview";
// import ProfileModal from "../../../Common/components/modal/profile.modal";


// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// import Dropdown from "react-bootstrap/Dropdown";
// import GeoData from "../geo-data/geo-data";
// import DropdownButton from "react-bootstrap/DropdownButton";

// import disasterProfileJSON from '../../../Config/disasterProfile.json';

// import * as constants from '../../../Config/app.constants';
// import { de } from "date-fns/locale";
import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import ChartView from "../../charts/chartView";
import CloseIcon from '@mui/icons-material/Close';
const trackerTemplate = function () {

    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },
    ];

    return (
        <>

            <div className={ this.state.loader ? 'loader text-center ' : 'loader text-center hide' }>
                <img src="assets/images/Comp-2.gif" style={ { 'margin-top': '12%', 'width': '30%' } } />
            </div>

            <section className="height_same" >
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-xl-3 col-lg-4 shadow position-relative">
                            <div className="navigate_sdgs ">
                                <div className="autocomplete_box">
                                    <label htmlFor="">Disaggregation View - Select Target </label>
                                    <Autocomplete
                                        clearIcon={ <CloseIcon onClick={ () => this.onClearTarget() } fontSize="small" /> }
                                        disablePortal
                                        disableClearable={ this.state.targetValue.targetName?.length > 0 ? false : true }
                                        freeSolo
                                        id="combo-box-demo"
                                        value={ { label: this.state.targetValue.targetName, targetId: this.state.targetValue.targetId } }
                                        onChange={ (e, v) => this.handleTargetChange(e, v) }
                                        options={
                                            this.state.selectedGoalData ? (
                                                this.state.selectedGoalData.reduce((acc, curr) => {
                                                    return acc.concat(
                                                        curr.target.map((targetItem) => {
                                                            return { label: targetItem.targetname, targetId: targetItem.targetid };
                                                        })
                                                    );
                                                }, [])
                                            ) : (
                                                []
                                            )
                                        }
                                        getOptionLabel={ (option) => option.label ? option.label : '' }
                                        filterSelectedOptions
                                        sx={ { width: 300 } }
                                        renderInput={ (params) => <TextField readOnly { ...params } placeholder="Select Target" /> }
                                    />

                                </div>
                                <div className="autocomplete_box autocomplete-2">
                                    <label htmlFor="">Geographic View - Select Indicator</label>
                                    <Autocomplete
                                        clearIcon={ <CloseIcon onClick={ () => this.onClearGeo() } fontSize="small" /> }
                                        disablePortal
                                        disabled={ this.state.indDisable || (this.state.targetValue.targetName === undefined
                                            || this.state.targetValue.targetName === '' || this.state.targetValue.targetName === null) ? true : false }
                                        disableClearable={ this.state.indicatorValue.indName?.length > 0 ? false : true }
                                        freeSolo
                                        id="combo-box-demo"
                                        onClear={ (e) => alert('s') }
                                        value={ { label: this.state.indicatorValue.indName, indId: this.state.indicatorValue.indId } }
                                        onChange={ (e, v) => this.handleIndicatorChange(e, v) }
                                        options={
                                            this.state.indicatorList ? (
                                                Array.from(this.state.indicatorList.map(iu => iu)).map(indName => {
                                                    const iuWithId = this.state.indicatorList.find(iu => iu.name === indName.name);
                                                    return { label: indName.name+' | '+indName.unit, indId: iuWithId?._id };
                                                })
                                            ) : (
                                                []
                                            )
                                        }
                                        getOptionLabel={ (option) => option.label ? option.label : '' }

                                        filterSelectedOptions
                                        sx={ { width: 300 } }
                                        renderInput={ (params) => <TextField readOnly { ...params }

                                            placeholder="Select Indicator" /> }
                                    />

                                </div>
                            </div>
                            <div className="fixed_autocomplete">
                                <FormControl fullWidth>
                                    <Select
                                        className="sgd-dropdown"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ this.state.sdgGoal }
                                        onMouseDown={ () => { this.classChange() } }
                                        onChange={ this.handleSdgGoal }
                                    >
                                        <MenuItem value={ 'Goal 1:' }><img src="assets/lib/images/icons/no_Poverty.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 2:' }><img src="assets/lib/images/icons/zero_hunger.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 3:' }><img src="assets/lib/images/icons/good_health_and_well_being.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 4:' }><img src="assets/lib/images/icons/quality_education.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 5:' }><img src="assets/lib/images/icons/gender_equality.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 6:' }><img src="assets/lib/images/icons/clean_water_and_senitation.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 7:' }><img src="assets/lib/images/icons/affordable_and_clean_energy.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 8:' }><img src="assets/lib/images/icons/decent_work_and_economic_growth.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 9:' }><img src="assets/lib/images/icons/industry_innovation_and_infrastructure.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 10:' }><img src="assets/lib/images/icons/reduced_inequalities.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 11:' }><img src="assets/lib/images/icons/sustainable_consumption_and_production.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 12:' }><img src="assets/lib/images/icons/responsible_consumption_and_production.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 13:' }><img src="assets/lib/images/icons/climate_action.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 14:' }><img src="assets/lib/images/icons/life_below_water.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 15:' }><img src="assets/lib/images/icons/life_on_land.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 16:' }><img src="assets/lib/images/icons/peace_justice_and_strong_institutions.svg" /></MenuItem>
                                        <MenuItem value={ 'Goal 17:' }><img src="assets/lib/images/icons/partnerships_for_the_goals.svg" /></MenuItem>

                                    </Select>
                                </FormControl>

                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 scroll_chart" id="divToDownload" ref={ this.reportTemplateRef }  >
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="chart_box">
                                        <div className="collapse show" id="collapseExample">
                                            <div className={ "head_box_main analysis" + this.state.activeN }>
                                                <div className="col-md-12 d-flex align-items-center">
                                                    <div className="sdgs_name">
                                                        <h4> { this.state.selectedGoalData?.[0]?.goalname?.split("Goal")?.[1]?.replace(':', '.') }</h4>
                                                    </div>
                                                    <div className="target_indi_area ml-auto mr-3">
                                                        <div className="autoCursor remove_cursor" > <span>Total Targets </span> <span>{ this.state.selectedGoalData?.[0]?.target?.length > 0? this.state.selectedGoalData?.[0]?.target?.length : 0 }</span></div>
                                                        <div className="autoCursor remove_cursor" > <span>Total Indicators </span> <span>{ this.state.totalIndicators?.length > 0 ? this.state.totalIndicators?.length: 0 }</span></div>
                                                    </div>
                                                    <div className="print_btn position-relative" >
                                                        <button className="btn" onClick={ () => this.downloadWidgets() }>
                                                            <img src="assets/lib/images/icons/printIcon.svg" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Data View ================================= */ }
                                            </div>
                                        </div>
                                        <button class="btn collapse-btn" onClick={ () => this.handleClick() }
                                            type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            { this.state.collapsed ? 'Collapse' : 'Expand' }
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
                                                <path d="M5.5 0L10.2631 6H0.73686L5.5 0Z" fill="#666666" />
                                            </svg>
                                        </button>
                                    </div>
                                    { this.state.DataView?.map(data => {
                                        return (<>
                                            <div className="map-card">
                                                <div className="target-detail">
                                                    <h5>{ data?.targetname }</h5>

                                                    {/* <h5>{data?.targetname.split("By")?.[0]}</h5> */ }
                                                    {/* <p> {data?.targetname.split("By")?.[1]?.length > 0 ? 'By' + data?.targetname.split("By")?.[1] : ''}</p> */ }
                                                </div>

                                                <div className="row">

                                                    { (data?.ius?.length > 0) ?

                                                        data?.ius?.map(iu => {
                                                            let isDissegregated = false;
                                                            let mainChartData = []



                                                            if (this.state.isGoal && this.state.targetValue.targetName == '') {
                                                                mainChartData = this.state.dataSet?.[iu?._id]
                                                                    .sort((a, b) => b.timeperiod - a.timeperiod);

                                                                let tpArr = mainChartData.map(x => x.timeperiod);
                                                                tpArr = [...(new Set(tpArr))].slice(0, 5);
                                                                mainChartData = mainChartData.filter(x => tpArr.includes(x.timeperiod));

                                                            }
                                                            else if (this.state.isGoal && this.state.targetValue.targetName !== '') {
                                                                mainChartData = this.state.dataSet?.[iu?._id]
                                                                    .sort((a, b) => b.timeperiod - a.timeperiod)
                                                                isDissegregated = true;

                                                            }


                                                            let cType = '';
                                                            // if(iu?._id in this.state.chartType) {
                                                            //   cType = this.state.chartType[iu?._id];
                                                            // } else {
                                                            //   cType = 'bar';
                                                            // }

                                                            let sourceMain = '';
                                                            let srcList = [];
                                                            let timpList = [];


                                                            let dfSubGrp = []

                                                            if (this.state.isGeo) {

                                                                mainChartData.map((x) => {
                                                                    if (!srcList.includes(x.source)) {
                                                                        srcList.push(x.source);
                                                                    }
                                                                });



                                                                mainChartData?.forEach(item => {


                                                                    timpList.push(item?.timeperiod);


                                                                })


                                                                mainChartData?.forEach(item => {

                                                                    if (item?.defaultSubgroup === 1) {
                                                                        dfSubGrp.push(item?.subgroup?.[0]);
                                                                    }

                                                                })
                                                            }

                                                            let UfinTime = [...new Set(timpList)]

                                                            let UfinSub = [...new Set(dfSubGrp)]

                                                            let mostRecentTp = [Math.max(...UfinTime)]

                                                            sourceMain = srcList[0]

                                                            console.log('mainChartData',  data?.ius)

                                                            return (<>
                                                                <div className={ this.state.isGeo ? "col-md-7 target-card isGeo bg-white" : "col-md-6 target-card" }>
                                                                    <div className="main_ind_view">
                                                                        <div className="chartBox indBox">
                                                                            <div className="main-chart-area">
                                                                                { <>
                                                                                    <div className="w-100 top_area">
                                                                                        <div className="indicator-area d-flex justify-content-between flex-column w-100">
                                                                                            <p>{ iu?.name }</p>
                                                                                            <p><span>{ iu?.unit }</span></p>
                                                                                        </div>
                                                                                        { this.route !== '/dashboard/overview' &&
                                                                                            <button data-toggle="modal" className="bg-transparent border-0" onClick={()=>this.showMetaData(data?.ius?.[0])}> <img src="assets/lib/images/new-home/meta.svg" alt="" /></button>
                                                                                        }

                                                                                    </div>
                                                                                </> }
                                                                                <div className="middle-area">

                                                                                </div>
                                                                            </div>
                                                                        
                                                                            <ChartView
                                                                                onlyTarget={ this.state.onlyTarget }
                                                                                isMapWidget={ this.state.isGeo ? true : false }
                                                                                downloadCsv={ this.state.downloadCsv }
                                                                                chartData={ mainChartData }
                                                                                metaData={ iu?.metaData }
                                                                                exData={ { "target": this.state.chartData?.[0]?.targetname, "goal": this.state.selectedGoalData?.[0]?.goalname } }
                                                                                targetState={ this.state.targetValue.targetName?.length > 0 ? false : true }
                                                                                dataId={ iu?._id }
                                                                                isGeo={ this.state.isGeo }
                                                                                height={ '450px' }
                                                                                width={ '100%' }
                                                                                chartType={ this.state.chartType }
                                                                                type={ (iu?._id in this.state.chartType) ? this.state.chartType[iu?._id] : 'bar' }
                                                                                isDissegregated={ isDissegregated }
                                                                                activeN={ this.state.activeN }
                                                                                widgetData={ this.state.widgetData }
                                                                                labelCallback={ this.labelCallback }
                                                                                mainIuData={ iu }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                { this.state.isGeo && <>
                                                                    <div className="col-md-5 target-card isGeo bg-white">
                                                                        <div className="main_ind_view">
                                                                            <div className="chartBox indBox">
                                                                                <ChartView
                                                                                    //  falserCSV = {this.falseCSV}
                                                                                    // afterDat = {this.state.afterDat}
                                                                                    // selectedSub={this.state.selectedSub}
                                                                                    // slectedTp={this.state.selectedTp}
                                                                                    onlyTarget={ false }
                                                                                    callBackMapData={ this.callBackMapData }
                                                                                    mapChart={ true }
                                                                                    isMapWidget={ false }
                                                                                    downloadCsv={ this.state.downloadCsv }
                                                                                    chartData={ mainChartData }
                                                                                    metaData={ iu?.metaData }
                                                                                    exData={ { "target": this.state.chartData?.[0]?.targetname, "goal": this.state.selectedGoalData?.[0]?.goalname } }
                                                                                    targetState={ this.state.targetValue.targetName?.length > 0 ? false : true }
                                                                                    dataId={ iu?._id }
                                                                                    isGeo={ this.state.isGeo }
                                                                                    height={ '450px' }
                                                                                    width={ '400px' }
                                                                                    chartType={ this.state.chartType }
                                                                                    type={ 'map' }
                                                                                    isDissegregated={ isDissegregated }
                                                                                    activeN={ this.state.activeN }
                                                                                    chartLabel={ this.state.chartLabel }
                                                                                    mainIuData={ iu }
                                                                                />

                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                </> }


                                                            </>)
                                                        })
                                                        :
                                                        <div className="col-md-12">
                                                            <h5 style={ { fontSize: '16px', display: "flex", alignItems: "center", justifyContent: "center", minHeight: "calc(100vh - 321px)", margin: 0, opacity: "0.6" } }>No Data</h5>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>)
                                    }) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <div class="modal fade analysis-modal" id="metadata-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 className='modal-title'>{ this.state.finalMetaData?.name } <span style={ { opacity: 0.6 } }>| { this.state.finalMetaData?.unit }</span></h5>
                                <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
                                    <img src='assets/lib/images/close.svg' className='img-fluid' />
                                </button>
                            </div>
                            <div class="modal-body">
                                {/* <img src='assets/lib/images/analysis-img.png' className='img-fluid'/> */ }
                                <div className='row'>
                                    <div className="col-md-12">

                                        <div className="targets_box">


                                            {/* <h2 className="target_head">Indicator</h2>
                          <p className="target_dis">{this.state.finalMetaData?.indicator}</p>

                          <h2 className="target_head">Goal</h2>
                          <p className="target_dis">{props.exData?.goal}</p> 

                          <h2 className="target_head">Target</h2>
                          <p className="target_dis">{props.exData?.target}</p> */}

                                            <h2 className="target_head">Indicator Definition</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt1?.en!==undefined && this.state.finalMetaData?.metaData?.mt1?.en!==''? this.state.finalMetaData?.metaData?.mt1?.en: '--' }</p>

                                            <h2 className="target_head">Method of Computation</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt2?.en!==undefined && this.state.finalMetaData?.metaData?.mt2?.en!==''? this.state.finalMetaData?.metaData?.mt2?.en: '--'}</p>

                                            <h2 className="target_head">Overview</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt3?.en!==undefined && this.state.finalMetaData?.metaData?.mt3?.en!==''? this.state.finalMetaData?.metaData?.mt3?.en: '--' }</p>

                                            <h2 className="target_head">Comments and Limitations</h2>
                                            <p className="target_dis">{this.state.finalMetaData?.metaData?.mt4?.en!==undefined && this.state.finalMetaData?.metaData?.mt4?.en!==''? this.state.finalMetaData?.metaData?.mt4?.en: '--'}</p>

                                            <h2 className="target_head">Data Collection for Global Monitoring</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt5?.en!==undefined && this.state.finalMetaData?.metaData?.mt5?.en!==''? this.state.finalMetaData?.metaData?.mt5?.en: '--' }</p>
                                            <h2 className="target_head">Obtaining Data</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt6?.en!==undefined && this.state.finalMetaData?.metaData?.mt6?.en!==''? this.state.finalMetaData?.metaData?.mt6?.en: '--' }</p>

                                            <h2 className="target_head">Data Availability</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt7?.en!==undefined && this.state.finalMetaData?.metaData?.mt7?.en!==''? this.state.finalMetaData?.metaData?.mt7?.en: '--' }</p>

                                            <h2 className="target_head">Treatment of Missing Values</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt8?.en!==undefined && this.state.finalMetaData?.metaData?.mt8?.en!==''? this.state.finalMetaData?.metaData?.mt8?.en: '--'}</p>

                                            <h2 className="target_head">Regional and Global Estimates</h2>
                                            <p className="target_dis">{ this.state.finalMetaData?.metaData?.mt9?.en!==undefined && this.state.finalMetaData?.metaData?.mt9?.en!==''? this.state.finalMetaData?.metaData?.mt9?.en: '--' }</p>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        </>
    );
};


export default trackerTemplate;

