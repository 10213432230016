import React, { useEffect, useRef, useState } from "react";

import Resources from "../pages/manage-resource/resources.js";

import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { faL } from "@fortawesome/free-solid-svg-icons";

const ResourceEntryModal = (props) => {
  const form = useRef();

  const formRef = useRef(null);
  const sourceRef = useRef();
  const titleRef = useRef();
  const typeRef = useRef();
  const cateRef = useRef();
  const documentRef = useRef();

  // const referenceMRef = useRef();

  const descriptionRef = useRef();
  const urlRef = useRef();
  const imageRef = useRef();
  const imageSideRef = useRef();

  let imageBackground = "";
  let documentBackground = "";
  let urlpath = "";

  const [subData, setSubGroup] = useState([]);
  const [areaTree, setAreaTree] = useState([]);
  const [image, setImage] = useState([]);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [typestate, setType] = useState("");
  const [category, setCategory] = useState("");


  const [documentState, setDocumentState] = useState([]);

  const [iddata, setIdData] = useState([]);
  const [urlState, setURLState] = useState([]);
  const [urlDataState, setURLData] = useState([]);
  const [docDataState, setDocDataState] = useState([]);

  const [formError, setError] = useState({ error: false, msg: '', input: '' });
  const [documentdataSate, setDocumentDataState] = useState();


  const [imagedatastate, setImageDataState] = useState();
  const [slectGroup, setSelectGroup] = useState();

  useEffect(() => {
    setAreaTree(props.areaTree);


  });

  useEffect(() => {
    console.log("this is error occurred-", category)
  }, [formError])

  useEffect(() => {
    if (props.isEdit == true) {

      getEditData(props.editData[0]);
    } else {
      setTitle("");
      setDescription("");

      setType("");
      setCategory("");

      // image.current.files[0].value = '';
      setURLState(false);
      setDocumentState(false);

      // new

      setDocDataState("");
      // new
      setImage("");
      setURLData("");
      setDocumentDataState("");


      setImageDataState("");

      return false;
    }
  }, [props.isEdit, props.editData]);

  const getEditData = (data) => {

    if (data != null) {
      setIdData(data["_id"]);
      setTitle(data["title"].en);

      setType(data["type"]);
      handleChange1(data["type"]);
      documentBackground = data["document"];
      imageBackground = data["image"];
      setImage(data["image"]);

      setURLData(data["url"]);
      urlpath = data["url"];


      if (data["type"] === "Document") {
        setDocumentState(true);
        setDocDataState(data["document"]);
      }
      setCategory(data["category"]);
      setDescription(data["description"].en);
    }
  };

  const document = false;
  const url = false;



  const handleChange = async (e) => {

    if (props.isEdit) {
      if (props.editData[0]["type"] === "Document") {
        setDocumentState(true);
        setDocDataState(props.editData[0]["document"]);
      } else if (props.editData[0]["type"] === "URL") {

        setURLState(true);
        setURLData(props.editData[0]["url"]);
      }
    }

    if (e.target.value == "Document") {
      setType("Document");
      setDocumentState(true);

      setURLState(false);
      setURLData("");
    } else {
      setType(e.target.value == "URL");
      setType("URL");
      setDocumentState(false);

      setURLState(true);
    }



    // if (e.target.title.value == null || e.target.title.value == "") {
    //   setError({ error: false, msg: "This field is required", input: "title" });
    //   return;
    // }
  };
  const handleChange1 = async (e) => {
    if (e === "Document") {
      setType("Document");
      setDocumentState(true);

      setURLState(false);
      setURLData("");
    } else if (e === "URL") {
      setType("URL");
      setDocumentState(false);

      setURLState(true);
      setDocDataState(false);
    }

    if (e.target?.title?.value == null || e.target?.title?.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };



  let path = "";

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };

    });
  };

  const getImage = async (event) => {
    let bfile = "";
    let files = imageRef.current.files[0];

    let file = files;

    let filename = files.name;

    if (files && file) {
      await getBase64(file).then((result) => {
        file["base64"] = result;
        let b = file.base64;


        bfile = b.split(",").pop();
      });

      path = filename + ";" + bfile;

      setImageDataState(path);
    }
    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };

  let docpath = "";
  const getDocument = async (event) => {

    setURLState(false);
    let bfile = "";
    let files = documentRef.current.files[0];
    setURLState(false);

    let file = files;
    let filename = files.name;



    if (files && file) {
      await getBase64(file).then((result) => {
        file["base64"] = result;
        let b = file.base64;
        bfile = b.split(",").pop();
      });
      docpath = filename + ";" + bfile;
      setDocumentDataState(docpath);
    }

    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };
  //  ---------------------new type add------------


  const getURL = async (event) => {
    setURLData(urlRef.current.value);

    urlpath = urlRef.current.value;
  };
  const getTitle = async (event) => {
    setTitle(titleRef.current.value);

    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };

  const getDescription = async (event) => {
    setDescription(descriptionRef.current.value);

    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };



  const onDataSubmit = (e) => {


    e.preventDefault();


    // formRef.current.reset();
    // debugger;
    let dataToSend = [];
    if (props.isEdit == true) {
      dataToSend = {
        id: iddata,
        description: e.target.description.value,
        document: documentdataSate,

        image: imagedatastate,
        title: e.target.title.value,
        type: typestate,
        url: urlDataState,
        category: category

      };
    } else {
      dataToSend = {
        description: e.target.description.value,
        document: documentdataSate,
        image: imagedatastate,
        title: e.target.title.value,
        type: typestate,
        url: urlDataState,
        category: category
      };
    }

    let doccheck = documentState;
    let urlcheck = urlState;

    if (e.target.title.value == null || e.target.title.value == "") {
      setError({ error: true, msg: "This field is required", input: "title" });
      return;
    } else if (
      e.target.description.value == null ||
      e.target.description.value == ""
    ) {
      setError({
        error: true,
        msg: "This field is required",
        input: "description",
      });
      return;
    } else if (typestate == "") {
      setError({ error: true, msg: "This field is required", input: "ptype" });
      return;
    } else if (image == "" && imagedatastate == "") {

      setError({ error: true, msg: "Please add an image ", input: "image" });
      return;
    } else if (doccheck && docDataState == "" && documentdataSate == "") {
      if (docDataState == "" && documentdataSate == "") {
        setError({
          error: true,
          msg: "Please add a document!",
          input: "document",
        });
        return;
      }
    } else if (urlcheck && urlDataState == "") {

      if (urlDataState == "") {
        setError({ error: true, msg: "Please enter url!", input: "url" });
        return;
      }
    }

    else {

      props.newDataEntry(dataToSend);

      e.target.reset();
      typeRef.current.value = "";
      cateRef.current.value = "";
    }
  };

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  };
  // ====================================================== Form Validation Start



  const changeCategory = (e) => {

    setCategory(e.target.value);
    if (e.target.value === '') {
      setError({ error: true, msg: "This field is required", input: "ptype" });
    } else {
      setError({ error: false, msg: "", input: "ptype" });
    }
  }

  // ====================================================== Form Validation End

  return (
    <>
      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={onDataSubmit}
          id="addUserForm"
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            {/* <form onSubmit={onDataSubmit} className="data_entry_form" id="dataEntryForm"> */}
            <div className="gallery-collections mt-0">
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Category</label>
                      </span>
                      <Select
                        ref={cateRef}
                        id="ptype"
                        name="ptype"
                        value={category}
                        onChange={changeCategory}
                        displayEmpty
                      // required
                      >
                        <MenuItem value="Publication">Publication</MenuItem>
                        <MenuItem value="Reports">Reports</MenuItem>
                      </Select>
                      <div className="inPuthidden ">
                        <TextValidator
                          type="hidden"
                          style={{ border: "0px", outline: 'none' }}
                          name="password"
                          autoComplete="off"
                          className=" w-100"
                          value={category}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                      {/* {formError.error && formError.input == "ptype" && (
                        <small class="text-danger"> {formError.msg}</small>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Title</label>
                      </span>
                      <TextValidator
                        name="title"
                        value={title}
                        onKeyUp={(e) => {
                          getTitle();
                        }}
                        onClick={getTitle}
                        onChange={getTitle}
                        autoComplete="off"
                        ref={titleRef}
                        className="w-100"
                      />

                      {formError.error && formError.input == "title" && (
                        <small class="text-danger"> {formError.msg}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Description</label>
                      </span>
                      <TextValidator
                        name="description"
                        ref={descriptionRef}
                        multiline
                        rows={4}
                        value={description}
                        onKeyUp={(e) => {
                          getDescription();
                        }}
                        onClick={getDescription}
                        onChange={getDescription}
                        autoComplete="off"
                        className="w-100"
                      />
                      {formError.error && formError.input == "description" && (
                        <small class="text-danger">
                          {/* <i className="fa fa-info-circle"></i> */}
                          {formError.msg}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Type</label>
                      </span>
                      <Select
                        ref={typeRef}
                        id="ptype"
                        name="ptype"
                        value={typestate}
                        onChange={handleChange}
                      >
                        <MenuItem
                          style={{ display: "none" }}
                          value=""
                          selected
                          disabled
                        ></MenuItem>
                        <MenuItem value="Document">Document</MenuItem>
                        <MenuItem value="URL">URL</MenuItem>
                      </Select>
                      {formError.error && formError.input == "ptype" && (
                        <small class="text-danger">
                          {/* <i className="fa fa-info-circle"></i>  */}
                          {formError.msg}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {documentState == true && (
                <>
                  <div className="row align-items-center mb-30">
                    <div className="col-md-12">
                      <div className="form-group mb-0 inputform_style">
                        <div className="mat-form-field-wrapper">
                          <span className="mat-form-field-label-wrapper">
                            <label>Document</label>
                          </span>
                          <input
                            className="w-100 form-control choose_file"
                            type="file"
                            ref={documentRef}
                            name="document"
                            onChange={getDocument}
                            id="document"
                          />
                          <p className="mt-2 mb-0">
                            <b>{docDataState}</b>
                          </p>
                          {formError.error && formError.input == "document" && (
                            <small class="text-danger">
                              {/* <i className="fa fa-info-circle">
                                                            </i> */}
                              {formError.msg}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {urlState == true && (
                <>
                  <div className="row align-items-center mb-30">
                    <div className="col-md-12">
                      <div className="form-group mb-0 inputform_style">
                        <div className="mat-form-field-wrapper">
                          <span className="mat-form-field-label-wrapper">
                            <label>URL</label>
                          </span>
                          <input
                            className="w-100 "
                            ref={urlRef}
                            name="url"
                            value={urlDataState}
                            onKeyUp={(e) => {
                              getURL();
                            }}
                            onClick={getURL}
                            onChange={getURL}
                            type="url"
                          />
                          {formError.error && formError.input == "url" && (
                            <small class="text-danger">
                              {/* <i className="fa fa-info-circle"></i>  */}
                              {formError.msg}
                            </small>
                          )}
                        </div>
                      </div>
                      <Tooltip
                        title="Ex:https://www.example.com Or http://www.example.com "
                        placement="right"
                      >
                        <a
                          style={{
                            float: "right",
                            margin: "-36px -30px 0 0",
                            cursor: "pointer",
                            color: "#0C6638",
                          }}
                          href="javascript:;"
                        >
                          <InfoIcon />
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}

              <div className="row align-items-center mb-30">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <span className="mat-form-field-label-wrapper">
                        <label>Image</label>
                      </span>
                      <input
                        className="w-100 form-control choose_file"
                        type="file"
                        name="image"
                        ref={imageRef}
                        id="image"
                        onChange={getImage}
                        accept="image/*"
                      />
                      <p
                        className="mt-2 mb-0"
                        name="imgbak"
                        id="imgbak"
                        value={image}
                      >
                        <b>{image}</b>
                      </p>
                      {formError.error && formError.input == "image" && (
                        <small class="text-danger">
                          {/* <i className="fa fa-info-circle"></i>  */}
                          {formError.msg}
                        </small>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? "Update" : "Add"}
                  <img
                    className="btn_arrow"
                    src="assets/lib/images/icon/btn_arrow.svg"
                  />
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
};

export default ResourceEntryModal;
